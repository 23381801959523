import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

export const useSessionStorage = () => {
  const saveSessionStorage = (keyLocal, dataLocal) => {
    const dataString = JSON.stringify(dataLocal);
    const encrypt = CryptoAES.encrypt(
      dataString,
      import.meta.env.VITE_KEY_LOCAL
    );
    sessionStorage.setItem(keyLocal, encrypt);
  };

  const getSessionStorage = (keyLocal) => {
    const getItem = sessionStorage.getItem(keyLocal);

    if (!getItem) {
      return null;
    }

    const decrypt = CryptoAES.decrypt(
      getItem.toString(),
      import.meta.env.VITE_KEY_LOCAL
    );

    if (decrypt) {
      const dataDecrypt = decrypt.toString(CryptoENC);
      return JSON.parse(dataDecrypt);
    }

    return null;
  };

  const removeSessionStorage = (keyLocal) => {
    sessionStorage.removeItem(keyLocal);
  };

  return { saveSessionStorage, getSessionStorage, removeSessionStorage };
};
