import { motion } from 'framer-motion';
import styled from 'styled-components';

export const HeaderItemDropDown = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.typography.baseText};
  padding: 0 5px 0 10px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  svg {
    margin-right: 0px;
    color: ${(props) => props.theme.colors.primary};
  }

  span {
    font-size: 0.95rem;
    font-weight: 500;
    width: 62%;
    margin-right: 6px;
    white-space: nowrap;

    /* @media (max-width: 1550px) {
      font-size: 0.84rem;
    } */

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  .arrow-btn {
    margin-left: 3px;
    font-size: 2rem;
    transition: all 0.2s ease;
  }
  .rotate-arrow {
    transform: rotate(180deg);
  }
  .activeTitleSubItem {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const SectionCollapsedItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: auto;
  padding: 0 16%;
  color: ${(props) => props.theme.colors.typography.baseText};

  .activeSubItem {
    background: ${(props) => props.theme.colors.bases.gradientBg};
    border-radius: 4px;
    color: ${(props) => props.theme.colors.typography.textHeaderTable};
    span,
    svg {
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
    }

    &:hover {
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
    }
  }

  span {
    margin: 6px 0;
    padding: 4px;
    font-size: 0.95rem;
    color: ${(props) => props.theme.colors.typography.baseText};
    cursor: pointer;
    width: 100%;

    /* @media (max-width: 1550px) {
      font-size: 0.8rem;
      margin: 2px 0;
    } */

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;
