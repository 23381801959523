import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ItemContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  color: ${(props) => props.theme.colors.typography.baseText};
  user-select: none;

  svg {
    margin-right: 10px;
    color: ${(props) => props.theme.colors.primary};
  }

  span {
    font-size: 0.95rem;
    cursor: pointer;
    width: 86%;
    white-space: nowrap;
    padding-top: 3px;

    user-select: none !important;

    /* @media (max-width: 1550px) {
      font-size: 0.84rem;
    } */

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;
