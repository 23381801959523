import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'stretch'};
  gap: ${(props) => props.gap || '0px'};
  padding: ${(props) =>
    props.padding ||
    `${props.paddingTop || '0px'} 
     ${props.paddingRight || '0px'} 
     ${props.paddingBottom || '0px'} 
     ${props.paddingLeft || '0px'}`};
  margin: ${(props) => props.margin || '0px'};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  min-width: ${(props) => props.minWidth || '0px'};
  min-height: ${(props) => props.minHeight || '0px'};
`;
