import { useEffect, useId } from 'react';
import { ContainerCallingEfforts } from './styled';
import { SearchCallingEfforts } from './SearchCallingEfforts';
import { useAtom } from 'jotai';
import { stageCallingEfforts } from '@/store/Reports/callingEffortsStore';
import { ListCallingEfforts } from './ListCallingEfforts';

export const CallingEfforts = () => {
  const [stateRender, setStateRender] = useAtom(stageCallingEfforts);

  const changeFlow = (stage) => setStateRender(stage);

  useEffect(() => {
    return () => setStateRender(0);
  }, []);

  const flowCallingEfforts = [
    <SearchCallingEfforts key={useId()} changeFlow={changeFlow} />,
    <ListCallingEfforts key={useId()} changeFlow={changeFlow} />,
  ];

  return (
    <ContainerCallingEfforts>
      {flowCallingEfforts[stateRender]}
    </ContainerCallingEfforts>
  );
};
