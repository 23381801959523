import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const FormMenuNode = styled(motion.form)`
  width: 320px;
  height: 100%;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr 40px;
  gap: 1rem;
`;

export const HeaderNode = styled.header``;

export const MainAddNode = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem 0 0 0.5rem;

  .content_vars {
    width: 100%;
  }

  .area_play_prompt {
    width: 100%;
    height: 120px;
  }

  .loading-btn {
    width: 30px;
    height: 30px;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 50px;
    svg {
      color: white;
    }
  }

  .itens_valid_input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-size: 0.7rem;
    gap: 4px;
    span {
      padding: 4px;
      width: 100%;
      background-color: #57525282;
    }
  }
`;

export const SystemVarItem = styled(ItemSelect)`
  color: #575757 !important;
  /* font-weight: 600 !important; */
  gap: 3px !important;
  font-size: 0.9ren;

  span {
    font-size: 0.8ren;
    font-style: italic;
    opacity: 0.6;
  }
`;

export const FieldAreaText = styled.div`
  width: 100%;
  position: relative;

  .btn_load_prompt {
    position: absolute;
    right: 6px;
    bottom: -14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;

    svg {
      color: #fff;
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.colors.primary};

      border-radius: 50px;
    }
  }

  .btn_play_prompt {
    position: absolute;
    right: 6px;
    bottom: -14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;

    .item-btn {
      background-color: white;
      border-radius: 50px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        svg {
          font-size: 1.1rem;
        }
      }
    }
  }

  .MuiInputBase-input {
    font-size: 14px;
  }
`;
