import { CardItemContent } from '@/components/layout/CardItem/styled';
import styled from 'styled-components';

export const CardStatusQueue = styled(CardItemContent)`
  min-height: 76px;
  .contentCard {
    gap: 0.3rem;
    .title-area-card {
      span {
        font-weight: bold;
        font-size: 0.9rem;
        opacity: 1;
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }
    div {
      svg {
        font-size: 0.8rem;
        color: ${(props) => props.theme.colors.defaultColors.blue};
      }
      span {
        padding-left: 3px;
        font-size: 0.8rem;
        opacity: 0.9;
        font-weight: 400;
        padding-top: 2px;
        color: ${(props) => props.theme.colors.typography.baseText}AA;
      }
    }
  }
`;
