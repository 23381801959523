import { errorToast } from '@/components/FormComponents/Toast';
import { createDispositionsDialing } from '@/services/dialControl/dispositionsDialing/createDispositionsDialing';
import { createUraDispositionsDialing } from '@/services/dialControl/dispositionsDialing/createUraDispositionsDialing';
import { deleteDispositionsDialing } from '@/services/dialControl/dispositionsDialing/deleteDispositionsDialing';
import { deleteUraDispositionsDialing } from '@/services/dialControl/dispositionsDialing/deleteUraDispositionsDialing';
import { getDispositionsDialing } from '@/services/dialControl/dispositionsDialing/getDispositionsDialing';
import { getUraDispositionsDialing } from '@/services/dialControl/dispositionsDialing/getUraDispositionsDialing';
import { updateDispositionsDialing } from '@/services/dialControl/dispositionsDialing/updateDispositionsDialing';
import { updateUraDispositionsDialing } from '@/services/dialControl/dispositionsDialing/updateUraDispositionsDialing';
import {
  dataDispositionsDialing,
  stageDispositions,
} from '@/store/DialingStore';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

export const useDispositionsDialing = () => {
  const { t } = useTranslation();
  const [stateRenderCodeLigs] = useAtom(stageDispositions);

  const [, setListsDispositions] = useAtom(dataDispositionsDialing);

  const [dispositions, setDispositions] = useState({
    mailing: [],
    ura: [],
  });

  const combinedDispositions = useMemo(() => {
    return [...dispositions.mailing, ...dispositions.ura];
  }, [dispositions]);

  useEffect(() => {
    setListsDispositions(combinedDispositions);
  }, [combinedDispositions]);

  const {
    isLoading: loadingDispositionsDialing,
    refetch: refetchMailingDisposition,
  } = useQuery(
    'getListDispositionsDialing',
    async () => {
      return await getDispositionsDialing();
    },
    {
      enabled: stateRenderCodeLigs === 0,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) =>
        setDispositions((prev) => ({
          ...prev,
          mailing: data.map((item) => ({
            ...item,
            origin: 'mailing',
          })),
        })),
      onError: () => errorToast(t('dispositions.home.mgs-error-disposition')),
    }
  );

  const {
    isLoading: loadingURADispositionsDialing,
    refetch: refetchUraDisposition,
  } = useQuery(
    'getListURADispositionsDialing',
    async () => {
      return await getUraDispositionsDialing();
    },
    {
      enabled: stateRenderCodeLigs === 0,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) =>
        setDispositions((prev) => ({
          ...prev,
          ura: data.map((item) => ({
            ...item,
            origin: 'ura',
          })),
        })),
      onError: () => errorToast(t('dispositions.home.mgs-error-disposition')),
    }
  );

  const {
    mutate: mutateCreateMailingDisposition,
    isLoading: loadingCreateMailingDisposition,
  } = useMutation({
    mutationFn: async (dataCreate) => {
      return await createDispositionsDialing(dataCreate);
    },
    onError: () => {
      return errorToast(t('dispositions.create.msg-error-created'));
    },
  });

  const {
    mutate: mutateCreateUraDisposition,
    isLoading: loadingCreateUraDisposition,
  } = useMutation({
    mutationFn: async (dataCreate) => {
      return await createUraDispositionsDialing(dataCreate);
    },
    onError: () => {
      return errorToast(t('dispositions.create.msg-error-created'));
    },
  });

  const {
    mutate: mutateUpdateMailingDisposition,
    isLoading: loadingUpdateMailingDisposition,
  } = useMutation({
    mutationFn: async (dataUpdate) => {
      return await updateDispositionsDialing(dataUpdate);
    },
    onError: () => {
      return errorToast(t('dispositions.edit.msg-error-update'));
    },
  });

  const {
    mutate: mutateUpdateUraDisposition,
    isLoading: loadingUpdateUraDisposition,
  } = useMutation({
    mutationFn: async (dataUpdate) => {
      return await updateUraDispositionsDialing(dataUpdate);
    },
    onError: () => {
      return errorToast(t('dispositions.edit.msg-error-update'));
    },
  });

  const {
    mutate: mutateDeleteUraDisposition,
    isLoading: loadingDeleteUraDisposition,
  } = useMutation({
    mutationFn: async (idDelete) => {
      return await deleteUraDispositionsDialing(idDelete);
    },
    onSuccess: () => {
      refetchMailingDisposition();
      refetchUraDisposition();
    },
    onError: () => {
      return errorToast(t('dispositions.remove.msg-error-delete'));
    },
  });

  const {
    mutate: mutateDeleteMailingDisposition,
    isLoading: loadingDeleteMailingDisposition,
  } = useMutation({
    mutationFn: async (idDelete) => {
      return await deleteDispositionsDialing(idDelete);
    },
    onSuccess: () => {
      refetchMailingDisposition();
      refetchUraDisposition();
    },
    onError: () => {
      return errorToast(t('dispositions.remove.msg-error-delete'));
    },
  });

  const loadingDataDisposition =
    loadingDispositionsDialing || loadingURADispositionsDialing;

  return {
    loadingDataDisposition,

    mutateUpdateMailingDisposition,
    loadingUpdateMailingDisposition,
    mutateUpdateUraDisposition,
    loadingUpdateUraDisposition,

    mutateCreateMailingDisposition,
    loadingCreateMailingDisposition,
    mutateCreateUraDisposition,
    loadingCreateUraDisposition,

    mutateDeleteUraDisposition,
    loadingDeleteUraDisposition,
    mutateDeleteMailingDisposition,
    loadingDeleteMailingDisposition,
  };
};
