import { apiData } from '@/services/api';

export async function getScreens(signal) {
  try {
    const { data } = await apiData.get('securityProfiles/listScreens', {
      signal,
    });
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
