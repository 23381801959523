import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerPropsQueue = styled(motion.div)`
  width: 100%;
  flex: 1;
`;

export const FormPropsQueues = styled.form`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: 1fr 30px;
  gap: 2rem;

  .main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.8rem;
  }
`;

export const PanelConfigQueue = styled(motion.div)`
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;

  .icon_handle_back {
    svg {
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }

  .itemPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
    span {
      text-transform: uppercase;
      padding-top: 1px;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }
`;
