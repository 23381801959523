import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useLineMonitoring } from '@/hooks/monitor/useLineMonitoring';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { ErrorComponentList } from '@components/layout/ErrorComponentList';
import { HeaderMonitoring } from './HeaderMonitoring';
import { ContentAgent } from './ContentAgent';
import { ContainerMonitor } from './styled';

export const LineMonitoring = () => {
  const { t } = useTranslation();
  const { dataLinesMonitoring, loadingDataLinesMonitoring } =
    useLineMonitoring();

  const [filterByQueue, setFilterByQueue] = useState('0');
  const [filterByStatus, setFilterByStatus] = useState('0');
  const [sortByQueue, setSortByQueue] = useState(false);

  useEffect(() => {
    setFilterByStatus('0');
  }, [filterByQueue]);

  const filterData = (dataList) => {
    if (dataList) {
      const filterList = dataList.filter(
        (infoQueue) => infoQueue.fila === filterByQueue
      );

      if (filterList.length > 0) {
        return filterList;
      } else {
        return dataList;
      }
    }
  };

  const filterStatusData = (dataList) => {
    if (dataList) {
      const filteredStatus = dataList.filter((infoQueue) => {
        switch (filterByStatus) {
          case '0':
            return infoQueue;
          case '1':
            return infoQueue.fila_estado === 'DISCANDO';
          case '2':
            return infoQueue.fila_estado === 'LIVRE';
          case '3':
            return infoQueue.fila_estado === 'PAUSA';
          default:
            return infoQueue;
        }
      });
      return filteredStatus;
    }
  };

  const handleOrderQueue = () => setSortByQueue(!sortByQueue);

  let orderQueue = !sortByQueue
    ? (a, b) => a.fila - b.fila
    : (a, b) => b.fila - a.fila;

  const filteredByQueue = filterData(dataLinesMonitoring);
  const filteredByStatus =
    filteredByQueue && filterStatusData(filteredByQueue).sort(orderQueue);

  const controlStates = {
    filterByQueue,
    setFilterByQueue,
    filterByStatus,
    setFilterByStatus,
    handleOrderQueue,
    sortByQueue,
  };

  return (
    <ContainerMonitor initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {loadingDataLinesMonitoring && <BackDropCircularLoading />}

      <HeaderMonitoring controlStates={controlStates} />

      <Stack>
        {!dataLinesMonitoring || filteredByStatus.length === 0 ? (
          <ErrorComponentList
            msg={t('monitorVirtualAgents.messages.noListAvailable')}
          />
        ) : (
          filteredByStatus.map((item, i) => {
            return (
              <ContentAgent
                key={i}
                queueName={item.fila}
                campaign={item.campanha}
                statusQueue={item.fila_estado}
                talking={item.falando}
                discing={item.discando}
                agents={item.array_ag}
              />
            );
          })
        )}
      </Stack>
    </ContainerMonitor>
  );
};
