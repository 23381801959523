import { motion } from 'framer-motion';
import styled from 'styled-components';

export const CardStatusQueue = styled(motion.div)`
  min-height: 68px;
  max-width: 400px;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  box-shadow: 1px 4px 10px -1px rgba(0, 0, 0, 0.4);
  padding: 4px;
  color: ${(props) => props.theme.colors.typography.baseText};
  border-radius: 4px;
  padding: 0.8rem 0.8rem 0.8rem 0.6rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  position: relative;
  transition: all 0.05s;

  @media (max-width: 715px) {
    min-width: 100%;
  }

  &:hover {
    box-shadow: 1px 4px 0px -1px rgba(0, 0, 0, 0.1);
    filter: grayscale(0.1);
  }

  .cardNull {
    color: ${(props) => props.theme.colors.primary} !important;
  }

  .cardClosed {
    color: ${(props) => props.theme.colors.secondary} !important;
  }

  .cardDiscing {
    color: ${(props) => props.theme.colors.success} !important;
  }

  .colorDiscing {
    color: ${(props) => props.theme.colors.success} !important;
  }

  .colorClosed {
    color: ${(props) => props.theme.colors.secondary}!important;
  }

  .colorNull {
    color: ${(props) => props.theme.colors.primary} !important;
  }

  .contentImage {
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    background-color: ${(props) => props.theme.colors.defaultColors.black};
  }

  .contentCard {
    flex: 1;
    padding: 0 0.2rem;
    display: flex;
    flex-direction: column;

    .title-area-card {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        font-size: 0.87rem;
      }
    }

    .status-area-card {
      display: flex;
      align-items: center;
      gap: 9px;
      padding-left: 2px;
      svg {
        font-size: 0.8rem;
      }
      span {
        font-size: 0.76rem;
        text-transform: uppercase;
        font-weight: 400;
        padding-top: 3px;
        opacity: 0.6;
      }
    }
  }
  span {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 0.2%;
  }
`;
