import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContentSectionRight = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10%;
  position: relative;
  gap: 1rem;

  #logoMobLogin {
    width: 100%;
    max-width: 320px;
    height: 80px;
    margin: 0 auto 60px auto;
    @media (min-width: 768px) {
      display: none;
    }
  }
`;

export const FormContainerLogin = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  p {
    width: 60%;
    font-size: 1.125rem;
    color: #000;

    @media (max-width: 460px) {
      width: 86%;
    }
  }

  form {
    margin-top: 50px;
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 460px) {
      width: 86%;
    }
  }

  .btn_forget_pass {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 460px) {
      width: 86%;
    }

    button {
      background-color: transparent !important;
      color: ${(props) => props.theme.colors.primary};
      box-shadow: none;
      padding: 0 4px;
    }
  }
`;

export const ForgetPassContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
