import styled from 'styled-components';

export const HeaderCardDetail = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  strong {
    font-size: 0.75rem;
    display: flex;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
    padding-top: 3px;
  }

  span {
    font-size: 0.75rem;
    display: flex;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    height: 12px;
    svg {
      color: ${(props) => props.theme.colors.typography.baseText}AA;
      font-size: 0.85rem;
    }
  }
`;
