import { Badge, Fab } from '@mui/material';
import { motion } from 'framer-motion';
import ObserverNavigation from './ObserverNavigation';
import { FaSave } from 'react-icons/fa';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { FaGear } from 'react-icons/fa6';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiExportFill } from 'react-icons/pi';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { ConfirmationToSave } from '../ConfirmationToSave';
import { useAtom } from 'jotai';
import { useSaveFlow } from '@/components/pages/FlowBuilder/hooks/useSaveFlow';
import { useHandleFlow } from '@/hooks/FlowBuilder/useHandlerFlow';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { ContainerFabControl } from './styled';
import { DetailsCurrentFlow } from '../DetailsCurrentFlow';
import { ConfigCurrentFlow } from '../ConfigCurrentFlow';
import { user } from '@/store/AuthenticatorStore';
import { ExportFlowToClientId } from '../ExportFlowToClientId';
import { usePermissionsFlow } from '@/components/pages/FlowBuilder/hooks/usePermissionsFlow';
import {
  hasModalOpen,
  isSavedFlow,
  templateSelected,
} from '@/store/FlowBuilder';

export const FloatNavigation = ({ dataNodes }) => {
  const { t } = useTranslation();
  const { permissionsCreate, permissionsEdit, isSysAdmin } =
    usePermissionsFlow();
  const { handleRenderNodes, checkErrorsNodes } = useSaveFlow(dataNodes);
  const { mutateSetStatusTemplate, mutateImportFlow } = useHandleFlow();
  const [showPanel, setShowPanel] = useState(false);
  const [currentUser] = useAtom(user);
  const [isSaved, setIsSaved] = useAtom(isSavedFlow);
  const [template] = useAtom(templateSelected);
  const [confirmationDialogSaveFlow, setConfirmationDialogSaveFlow] =
    useState(false);
  const [showDetailsFlow, setShowDetailsFlow] = useState(false);
  const [showExportFlow, setShowExportFlow] = useState(false);
  const [showConfigFlow, setShowConfigFlow] = useState(false);

  const [, setModalIsOpened] = useAtom(hasModalOpen);

  const variantsPanel = {
    hidden: { height: 0, opacity: 0 },
    show: {
      height: !showPanel ? 0 : 220,
      opacity: !showPanel ? 0 : 1,
    },
  };

  const confirmationSaveFlow = () => {
    const isNoNull = checkErrorsNodes();

    if (!isNoNull) {
      return;
    }
    setModalIsOpened(true);
    return setConfirmationDialogSaveFlow(true);
  };

  const handleDetailsFlow = () => {
    setModalIsOpened(true);
    setShowDetailsFlow(true);
  };

  const handleConfigFlow = () => {
    setModalIsOpened(true);
    setShowConfigFlow(true);
  };

  const handleExportFlow = () => {
    setModalIsOpened(true);
    setShowExportFlow(true);
  };

  const handlePublished = () => {
    const checkPersona = dataNodes.nodes.filter(
      (item) => item.type === 'dialplanVariables'
    );

    if (checkPersona.length === 0) {
      errorToast(t('flowBuilder.flow.msg-error-noPersona'));
      return;
    }

    const dataStatus = {
      id: template._id,
      nameIVR: template.nameIVR,
      last_user_published: currentUser.client_id,
    };

    mutateSetStatusTemplate(dataStatus, {
      onSuccess: () => {
        mutateImportFlow(template._id);
        successToast(t('flowBuilder.flow.msg-flow-published'));
        setIsSaved(true);
      },
    });
  };

  const handleOutsideClick = () => {
    setShowPanel(false);
  };

  const handleFilesControl = () => {
    setShowPanel(!showPanel);
  };

  const handleCloseModalConfig = () => {
    setShowConfigFlow(false);
    setModalIsOpened(false);
  };

  const handleCloseModalDetails = () => {
    setShowDetailsFlow(false);
    setModalIsOpened(false);
  };

  const handleCloseModalExport = () => {
    setShowExportFlow(false);
    setModalIsOpened(false);
  };

  const handleCloseModalSave = () => {
    setConfirmationDialogSaveFlow(false);
    setModalIsOpened(false);
  };

  const hasVoicceLabsClient =
    currentUser.client_id === '29904593-681a-4ad6-97c8-e26483468b73' &&
    isSysAdmin;

  const permissionsUser = permissionsCreate || permissionsEdit || isSysAdmin;

  return (
    <div className='list-buttons'>
      <ObserverNavigation onOutsideClick={handleOutsideClick}>
        <ContainerFabControl showPanel={showPanel}>
          <motion.div
            className='listButtons'
            variants={variantsPanel}
            initial='hidden'
            animate='show'
            transition={{ duration: 0.2 }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: showPanel ? 1 : 0 }}
              transition={{ duration: 0.2 }}
              className='subItemsControl'
            >
              {hasVoicceLabsClient && (
                <Fab
                  aria-label='Configs'
                  size={'small'}
                  className='configFlow'
                  title='Configs Flow'
                  onClick={handleConfigFlow}
                  disabled={!permissionsUser}
                >
                  <FaGear />
                </Fab>
              )}

              {hasVoicceLabsClient && (
                <Fab
                  aria-label='Save'
                  size={'small'}
                  className='saveBtn'
                  title='Save Flow'
                  onClick={confirmationSaveFlow}
                  disabled={isSaved || !permissionsUser}
                >
                  <Badge
                    color='error'
                    variant='dot'
                    invisible={isSaved}
                    badgeContent=''
                  >
                    <FaSave />
                  </Badge>
                </Fab>
              )}

              {hasVoicceLabsClient && (
                <Fab
                  aria-label='Publish'
                  size={'small'}
                  className='publishBtn'
                  title={t(
                    'flowBuilder.flow.navigationFlow.status-card-flow-publish'
                  )}
                  disabled={
                    template.published ||
                    !isSaved ||
                    dataNodes.edges.length === 0 ||
                    dataNodes.nodes.length <= 1 ||
                    !permissionsUser
                  }
                  onClick={handlePublished}
                >
                  <IoIosCheckmarkCircle />
                </Fab>
              )}

              {hasVoicceLabsClient && (
                <Fab
                  aria-label='Export Flow'
                  size={'small'}
                  className='exportBtn'
                  title={t('Export Flow')}
                  disabled={
                    dataNodes.edges.length === 0 || dataNodes.nodes.length <= 1
                  }
                  onClick={handleExportFlow}
                >
                  <PiExportFill />
                </Fab>
              )}

              <Fab
                aria-label='Details'
                size={'small'}
                className='detailsBtn'
                title='Details Flow'
                onClick={handleDetailsFlow}
              >
                <BsFillInfoCircleFill />
              </Fab>
            </motion.div>
          </motion.div>
          <Fab aria-label='add' size={'medium'} onClick={handleFilesControl}>
            <FaGear />
          </Fab>
        </ContainerFabControl>

        <DetailsCurrentFlow
          close={handleCloseModalDetails}
          open={showDetailsFlow}
        />

        <ExportFlowToClientId
          close={handleCloseModalExport}
          open={showExportFlow}
        />

        <ConfigCurrentFlow
          close={handleCloseModalConfig}
          open={showConfigFlow}
        />

        <ConfirmationToSave
          actionConfirm={handleRenderNodes}
          close={handleCloseModalSave}
          open={confirmationDialogSaveFlow}
        />
      </ObserverNavigation>
    </div>
  );
};
