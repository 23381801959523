import { Dialog, DialogContent } from '@mui/material';
import { useTheme } from 'styled-components';

export const DialogComponent = ({ variant = 'default', ...props }) => {
  const theme = useTheme();
  const renderColorBackground =
    variant === 'default'
      ? theme.colors.containers.colorCard
      : theme.colors.containers.colorCardSecondary;
  return (
    <Dialog {...props} disableEnforceFocus disableRestoreFocus>
      <DialogContent
        sx={{
          padding: '0px 1rem',
          background: `${renderColorBackground}`,
        }}
      >
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export const DialogMenu = ({ ...props }) => {
  return <Dialog {...props}>{props.children}</Dialog>;
};
