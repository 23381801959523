import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  dataInfoCampaign,
  dataInfoQueue,
  listOptionsCampaign,
  selectedQueue,
  stateRenderDialing,
  statusQueueSelected,
} from '@/store/DialingStore';

import { addToDial } from '@services/queue/addToDial';
import { cleanListQueue } from '@services/queue/cleanListQueue';
import { getInfoQueue } from '@services/queue/getInfoQueue';
import { replicateListQueue } from '@services/queue/replicateListQueue';
import { advanceListQueue } from '@services/queue/advanceListQueue';
import { setControlQueue } from '@services/queue/setControlQueue';

import { errorToast, successToast } from '@components/FormComponents/Toast';
import { getInfoManagement } from '@/services/queue/getInfoManagement';
import { user } from '@/store/AuthenticatorStore';
import { getDetailList } from '@/services/queue/getDetailList';

export const useDialingQueues = () => {
  const { t } = useTranslation();
  const [selectedCardQueue] = useAtom(selectedQueue);
  const [, setStatusQueueRadio] = useAtom(statusQueueSelected);
  const [stateRender, setStateRender] = useAtom(stateRenderDialing);
  const [, setListCampaign] = useAtom(listOptionsCampaign);
  const [, setDataInfoCampaign] = useAtom(dataInfoCampaign);
  const [, setDataQueue] = useAtom(dataInfoQueue);

  const [currentUser] = useAtom(user);
  const { isSysAdmin } = currentUser.userRole;

  const dataStatus = [
    {
      fila: selectedCardQueue && selectedCardQueue.fila,
      status: 'ABERTO',
    },
    {
      fila: selectedCardQueue && selectedCardQueue.fila,
      status: 'PARADO',
    },
    {
      fila: selectedCardQueue && selectedCardQueue.fila,
      status: 'MANUTENCAO',
    },
  ];

  const renderStatusQueue = (statusQueue) => {
    if (isSysAdmin) {
      switch (statusQueue) {
        case 'VAZIO':
          return setStatusQueueRadio('manutencao');
        case 'MANUTENCAO':
          return setStatusQueueRadio('manutencao');
        case 'FECHADO':
          return setStatusQueueRadio('fechar');
        case 'PARADO':
          return setStatusQueueRadio('fechar');
        case 'ABERTO':
          return setStatusQueueRadio('abrir');
        case 'DISCANDO':
          return setStatusQueueRadio('abrir');
        default:
          return setStatusQueueRadio('fechar');
      }
    }
    switch (statusQueue) {
      case 'VAZIO':
        return setStatusQueueRadio('fechar');
      case 'MANUTENCAO':
        return setStatusQueueRadio('fechar');
      case 'FECHADO':
        return setStatusQueueRadio('fechar');
      case 'PARADO':
        return setStatusQueueRadio('fechar');
      case 'ABERTO':
        return setStatusQueueRadio('abrir');
      case 'DISCANDO':
        return setStatusQueueRadio('abrir');
      default:
        return setStatusQueueRadio('fechar');
    }
  };

  const { mutate: mutateDataInfoQueue, isLoading: isLoadingDataQueue } =
    useMutation({
      mutationFn: async (dataQueue) => {
        return await getInfoQueue(dataQueue);
      },
      onSuccess: (data) => {
        if (data.queueInfo === undefined || data.positions === undefined) {
          errorToast(t('dialing.tableDiscingQueue.noDataShowing'));
          setStateRender(0);
        }
        setDataQueue(data);
        renderStatusQueue(data.queueInfo.estado);
      },
      onError: () => {
        errorToast(t('dialing.tableDiscingQueue.noDataShowing'));
        setStateRender(0);
      },
    });

  const { mutate: mutateChangeStatus } = useMutation({
    mutationFn: async (dataMutation) => {
      return await setControlQueue(dataMutation);
    },
    onError: () => {
      errorToast(t('dialing.messages.errorOnUpdateStatueQueue'));
    },
    onSuccess: () => {
      mutateDataInfoQueue(selectedCardQueue.fila);
      successToast(t('dialing.messages.successOnUpdateStatueQueue'));
    },
  });

  const { mutate: mutateNewQueue, isLoading: loadingNewList } = useMutation({
    mutationFn: async (dataNewList) => {
      return await addToDial(dataNewList);
    },
    onSuccess: () => {
      mutateDataInfoQueue(selectedCardQueue.fila);
    },
    onError: (error) => {
      if (error.message === 'The maximum allowed are 10 registers!') {
        errorToast(t('dialing.newListConfig.messages.errorRequesPositions'));
      } else {
        errorToast(t('dialing.newListConfig.messages.errorRequesStatus'));
      }
    },
  });

  const { mutate: mutateCleanList, isLoading: loadingCleanList } = useMutation({
    mutationFn: async (dataClean) => {
      return await cleanListQueue(dataClean);
    },
    onError: () => {
      errorToast(t('dialing.messages.errorCleanListQueue'));
      mutateDataInfoQueue(selectedCardQueue.fila);
    },
    onSuccess: () => {
      mutateDataInfoQueue(selectedCardQueue.fila);
      successToast(t('dialing.messages.successCleanListQueue'));
    },
  });

  const { mutate: mutateReplicateList, isLoading: loadingReplicateList } =
    useMutation({
      mutationFn: async (dataReplicate) => {
        return await replicateListQueue(dataReplicate);
      },
      onError: () => {
        errorToast(t('dialing.messages.errorReplicateListQueue'));
        mutateDataInfoQueue(selectedCardQueue.fila);
      },
      onSuccess: () => {
        mutateDataInfoQueue(selectedCardQueue.fila);
        successToast(t('dialing.messages.successReplicateListQueue'));
      },
    });

  const { mutate: mutateAdvanceList, isLoading: loadingAdvanceList } =
    useMutation({
      mutationFn: async (dataClean) => {
        return await advanceListQueue(dataClean);
      },
      onError: () => {
        errorToast(t('dialing.messages.errorAdvanceListQueue'));
        mutateDataInfoQueue(selectedCardQueue.fila);
      },
      onSuccess: () => {
        mutateDataInfoQueue(selectedCardQueue.fila);
        successToast(t('dialing.messages.successAdvanceListQueue'));
      },
    });

  const {
    data: dataCodLists,
    isLoading: isLoadingCodList,
    error: errorDataCodLists,
  } = useQuery(
    'managementInfoQueue',
    async () => {
      const dataRequest = {
        queueNumber: selectedCardQueue.fila,
        nameCampaign: selectedCardQueue.campanha,
      };
      return await getInfoManagement(dataRequest);
    },
    {
      retry: false,
      enabled: stateRender === 2,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setDataInfoCampaign(data);
        data.map((statusQueue) => {
          setListCampaign(statusQueue.listas);
        });
      },
      onError: () => {
        errorToast(t('dialing.newListConfig.messages.errorRequesStatus'));
        setStateRender(1);
      },
    }
  );

  const { mutate: mutateDetailList, isLoading: loadingDetailList } =
    useMutation({
      mutationFn: async (dataDetail) => {
        return await getDetailList(dataDetail);
      },
      onError: () => {
        errorToast(t('dialing.detailLineQueue.msg-error-detail'));
      },
    });

  return {
    dataStatus,

    mutateDataInfoQueue,
    isLoadingDataQueue,

    mutateChangeStatus,
    mutateCleanList,
    mutateNewQueue,
    mutateReplicateList,
    mutateAdvanceList,

    loadingNewList,
    loadingCleanList,
    loadingReplicateList,
    loadingAdvanceList,

    dataCodLists,
    isLoadingCodList,
    errorDataCodLists,

    mutateDetailList,
    loadingDetailList,
  };
};
