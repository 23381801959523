import { useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlay } from 'react-icons/fa6';
import { useAtom } from 'jotai';
import { Switch, Box } from '@mui/material';
import { HiOutlineVariable } from 'react-icons/hi';
import { BiWorld } from 'react-icons/bi';
import { AccordionComponent } from '@/components/layout/AccordionComponent';
import { useFlowBuilder } from '@/hooks/FlowBuilder/useFlowBuilder';
import { PlayerMessage } from './PlayerMessage';
import AudioVisualizer from './AudioVisualizer';
import { FieldDtmfMessage, LabelSwitch } from './styled';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { SelectComponent } from '@/components/FormComponents/SelectInput';

import {
  focusedFieldArea,
  selectedNode,
  templateSelected,
  variablesList,
} from '@/store/FlowBuilder';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import { FlexContainer } from '@/components/layout/FlexContainer/styled';

const label = { inputProps: { 'aria-label': 'Type Keys' } };

export const FieldAddDtmf = ({ dataDtmf }) => {
  const { t } = useTranslation();
  const { mutateRenderPrompt, loadingRenderPrompt } = useFlowBuilder();
  const [, setFocusedPromptArea] = useAtom(focusedFieldArea);
  const {
    messageKey,
    setMessageKey,
    typeKeyCapture,
    setTypeKeyCapture,
    renderFileName,
    selectVoicePrompt,
  } = dataDtmf;

  const [listVariables] = useAtom(variablesList);
  const [template] = useAtom(templateSelected);

  const [isPlayingMessage, setIsPlayingMessage] = useState(false);

  const globalVars = template.variablesGlobal;

  const [selected] = useAtom(selectedNode);
  const [hasVariables, setHasVariables] = useState(false);

  const switchId = useId();
  const toggleCaptureType = () => setTypeKeyCapture((prev) => !prev);

  const [sound, setSound] = useState(null);

  const [showListVariables, setShowListVariables] = useState(false);
  const [showGlobalVariables, setShowGlobalVariables] = useState(false);
  const [selectedVar, setSelectedVar] = useState('');
  const [variables, setVariables] = useState([]);
  const hasEmptyFlowVars =
    variables.filter((item) => !item.parent).length === 0;

  const selectRef = useRef(null);
  const selectGlobalRef = useRef(null);

  useEffect(() => {
    setSound(null);
    return () => setSound(null);
  }, [selected]);

  useEffect(() => {
    const checkVar = /\{\{([^{}]+)\}\}/g;
    const listCheckVar = messageKey.match(checkVar);

    if (listCheckVar) {
      return setHasVariables(true);
    }
    setHasVariables(false);
  }, [messageKey]);

  useEffect(() => {
    if (showGlobalVariables) {
      selectGlobalRef.current.focus();
    }
  }, [showGlobalVariables, selectGlobalRef]);

  useEffect(() => {
    if (showListVariables) {
      selectRef.current.focus();
    }
  }, [showListVariables, selectRef]);

  useEffect(() => {
    const mapVarsContext = listVariables
      ? listVariables.map((item) => {
          return { name: item.name, value: item.name, parent: false };
        })
      : [];

    const mapVarsGlobal = globalVars
      ? globalVars.map((item) => {
          return { name: item.name, value: item.value, parent: item.parent };
        })
      : [];

    setVariables(() => [...mapVarsContext, ...mapVarsGlobal]);
  }, [listVariables, globalVars]);

  const validateSelectedVar = (value) => {
    setMessageKey((labelTTSNode) => labelTTSNode + ` {{${value}}} `);
    setSelectedVar('0');
  };

  const validateSelectedGlobalVar = (value) => {
    setMessageKey((labelTTSNode) => labelTTSNode + ` {{${value}}} `);
    setShowGlobalVariables(false);
  };

  const handleVariablePrompt = (typeSelect) => {
    if (typeSelect === 'global') {
      return setShowGlobalVariables(true);
    }
    setShowListVariables(true);
  };

  const removeVariablesPrompt = (dataSelected) => {
    if (dataSelected.length > 0) {
      const replaceVars = dataSelected.replace('$', 'Valor ');
      return replaceVars;
    }
    return dataSelected;
  };

  const handlePlayPrompt = async () => {
    const dataRender = {
      prompt: removeVariablesPrompt(messageKey),
      audioName: renderFileName(),
      prosodyRate: 1,
      speechSynthesisVoiceName: selectVoicePrompt(),
    };

    mutateRenderPrompt(dataRender, {
      onSuccess: (data) => {
        const arrayBuffer = data;
        const audioUrl = URL.createObjectURL(new Blob([arrayBuffer]));
        setSound(audioUrl);
      },
    });
  };

  return (
    <>
      <FlexContainer width={'100%'} gap={'0.6rem'}>
        <AccordionComponent
          opemText={t('flowBuilder.flow.nodes.dtmf.menu.label-open-vars')}
          closeText={t('flowBuilder.flow.nodes.dtmf.menu.label-close-vars')}
          expandedContent={showListVariables || showGlobalVariables}
        >
          <SelectComponent
            defaultValue='0'
            labelSelect={t(
              'flowBuilder.flow.nodes.dtmf.menu.label-global-vars'
            )}
            inputRef={selectGlobalRef}
            open={showGlobalVariables}
            onClose={() => setShowGlobalVariables(false)}
            onOpen={() => setShowGlobalVariables(true)}
            value={selectedVar}
            onChange={(event) => validateSelectedGlobalVar(event.target.value)}
          >
            <ItemSelect value={'0'} disabled>
              {t('flowBuilder.flow.nodes.dtmf.menu.option-variable')}
            </ItemSelect>

            {variables.length > 0 &&
              variables.map((item, i) => {
                return (
                  item.parent && (
                    <ItemSelect key={i} value={item.value}>
                      {item.value}
                    </ItemSelect>
                  )
                );
              })}
          </SelectComponent>

          <SelectComponent
            defaultValue='0'
            labelSelect={t('flowBuilder.flow.nodes.dtmf.menu.label-flow-vars')}
            inputRef={selectRef}
            open={showListVariables}
            onClose={() => setShowListVariables(false)}
            onOpen={() => setShowListVariables(true)}
            value={selectedVar}
            disabled={hasEmptyFlowVars}
            onChange={(event) => validateSelectedVar(event.target.value)}
          >
            <ItemSelect value={'0'} disabled>
              {t('flowBuilder.flow.nodes.dtmf.menu.option-variable')}
            </ItemSelect>

            {variables.length > 0 &&
              variables.map((item, i) => {
                return (
                  !item.parent && (
                    <ItemSelect key={i} value={item.value}>
                      {item.name}
                    </ItemSelect>
                  )
                );
              })}
          </SelectComponent>
        </AccordionComponent>

        <FieldDtmfMessage>
          <InputComponent
            id={useId()}
            label={t('flowBuilder.flow.nodes.dtmf.menu.label-message')}
            multiline
            maxRows={3}
            minRows={3}
            value={messageKey}
            onChange={(e) => setMessageKey(e.target.value)}
            onFocus={() => setFocusedPromptArea(true)}
            onBlur={() => setFocusedPromptArea(false)}
          />

          <div className='btn_play_prompt'>
            <div className='item-btn'>
              <Button
                onClick={() => handleVariablePrompt('global')}
                title={t(
                  'flowBuilder.flow.nodes.dtmf.menu.title-btn-add-global'
                )}
                disabled={hasEmptyFlowVars}
                variantstyle='success'
                sx={{
                  width: '30px',
                  minWidth: '20px',
                  height: '30px',
                  borderRadius: '50px',
                }}
              >
                <BiWorld />
              </Button>
            </div>

            <div className='item-btn'>
              <Button
                onClick={() => handleVariablePrompt('flow')}
                title={t('flowBuilder.flow.nodes.dtmf.menu.title-btn-add-flow')}
                disabled={hasEmptyFlowVars}
                variant='secondary'
                sx={{
                  width: '30px',
                  minWidth: '20px',
                  height: '30px',
                  borderRadius: '50px',
                }}
              >
                <HiOutlineVariable />
              </Button>
            </div>

            <div className='item-btn'>
              <Button
                onClick={handlePlayPrompt}
                disabled={messageKey === ''}
                title={t('flowBuilder.flow.nodes.dtmf.menu.title-btn-play')}
                sx={{
                  width: '30px',
                  minWidth: '20px',
                  height: '30px',
                  borderRadius: '50px',
                  padding: '4px',
                }}
              >
                {isPlayingMessage ? <AudioVisualizer /> : <FaPlay size={12} />}
              </Button>
            </div>
          </div>
        </FieldDtmfMessage>
      </FlexContainer>

      {hasVariables && (
        <DescriptionInformation
          variant='secondary'
          textDescription={t(
            'flowBuilder.flow.nodes.dtmf.menu.description-vars'
          )}
        />
      )}

      {sound && !loadingRenderPrompt && (
        <PlayerMessage
          soundResponse={sound}
          setIsPlayingMessage={setIsPlayingMessage}
        />
      )}

      <Box display={'flex'} alignItems={'center'} gap={'8px'}>
        <Switch
          {...label}
          checked={typeKeyCapture}
          onChange={(e) => setTypeKeyCapture(e.target.checked)}
          size='small'
          id={switchId}
        />
        <LabelSwitch htmlFor={switchId} onClick={toggleCaptureType}>
          {typeKeyCapture
            ? t('flowBuilder.flow.nodes.dtmf.menu.label-automatic-capture')
            : t('flowBuilder.flow.nodes.dtmf.menu.label-custom-capture')}
        </LabelSwitch>
      </Box>
    </>
  );
};
