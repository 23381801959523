import axios from 'axios';

export async function getRenderPromptFile(dataCreatePrompt) {
  try {
    const { data } = await axios.post(
      `${import.meta.env.VITE_URL_FLOW}`,
      {
        prompt: dataCreatePrompt.prompt,
        audioName: dataCreatePrompt.audioName,
        path: '',
        prosodyRate: dataCreatePrompt.prosodyRate,
        speechSynthesisVoiceName: dataCreatePrompt.speechSynthesisVoiceName,
      },
      {
        responseType: 'arraybuffer',
      }
    );

    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
