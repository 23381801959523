import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const ContainerDetailsReports = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const ContainerItem = styled(motion.button)`
  all: unset;
  width: 100%;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  ${(props) =>
    props.typeFilter === 1
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0.5;
        `}

  .marker-item {
    background-color: ${(props) => props.theme.colors.defaultColors.orange};
    height: 100%;
    width: 4px;
  }

  &:nth-child(2) {
    .marker-item {
      background-color: ${(props) => props.theme.colors.defaultColors.blue};
    }
  }
  &:nth-child(3) {
    .marker-item {
      background-color: ${(props) => props.theme.colors.defaultColors.purple};
    }
  }
  &:nth-child(4) {
    .marker-item {
      background-color: ${(props) => props.theme.colors.defaultColors.green};
    }
  }

  .content-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.8rem;

    div {
      svg {
        color: ${(props) => props.theme.colors.typography.baseText}AA;
        padding-bottom: 1px;
      }
      span {
        color: ${(props) => props.theme.colors.typography.baseText}AA;
        font-size: 0.8rem;
        padding-top: 3px;
      }
    }

    span {
      color: ${(props) => props.theme.colors.typography.baseText};
      font-size: 1.2rem;
    }
  }
`;

export const ContentItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.8rem;

  div {
    svg {
      color: ${(props) =>
        props.colorItem
          ? props.theme.colors.defaultColors[props.colorItem]
          : props.theme.colors.typography.baseText}AA;

      padding-bottom: 1px;
    }
    span {
      color: ${(props) => props.theme.colors.typography.baseText}AA;
      font-size: 0.8rem;
      padding-top: 3px;
    }
  }

  span {
    color: ${(props) => props.theme.colors.typography.baseText};
    font-size: 1.2rem;
  }
`;
