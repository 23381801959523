import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import React, { memo, useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { BsChatSquareTextFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { shortNameRender } from '../../../../utils/shortNameRender';
import { NodeContent, NodeTTSContainer } from './styled';
import { FlexBoxColumn } from '../styled';
import { PanelNode } from '@/components/pages/FlowBuilder/components/PanelNode';

const PlayPromptNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();

  const [, setShowMenu] = useAtom(showMenuNodes);
  const [, setSelectedNode] = useAtom(selectedNode);

  const handleNode = useCallback(() => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  }, [id, data, setSelectedNode, setShowMenu]);

  return (
    <NodeTTSContainer
      selected={selected}
      errorConnect={data.errorConnect ? 1 : 0}
      onDoubleClick={() => handleNode()}
      handler={data.handler}
    >
      <PanelNode data={data} id={id} />

      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <BsChatSquareTextFill size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
            <span>{t('flowBuilder.flow.nodes.playPrompt.label')}</span>
          </div>
        </div>
        <div className='main'>
          <FlexBoxColumn>
            <span>
              <strong>
                {t('flowBuilder.flow.nodes.playPrompt.label-prompt')}:{' '}
              </strong>
            </span>
            <div className='field-prompt-render'>
              <span>
                {data?.prompt === ''
                  ? t('flowBuilder.flow.nodes.playPrompt.placeholder-prompt')
                  : data.prompt}
              </span>
            </div>
          </FlexBoxColumn>
        </div>
      </NodeContent>
    </NodeTTSContainer>
  );
};

export const PlayPromptNode = memo(PlayPromptNodeComponent);
