import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerMenuNode = styled(motion.div)`
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 99999;
  background-color: ${(props) => props.theme.colors.containers.colorCardThird};
`;

export const FormMenuNode = styled(motion.form)`
  width: 360px;
  height: 100%;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr 40px;
  gap: 1rem;
`;

export const HeaderNode = styled.header``;

export const MainAddNode = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem 0 0 0.5rem;

  .itens_valid_input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-size: 0.7rem;
    gap: 4px;
    span {
      padding: 4px;
      width: 100%;
      background-color: #57525282;
    }
  }
`;

export const FooterAddNode = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

export const ContainerValidInputs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  span {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .content-inputs {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;

    input {
      display: none !important;
    }

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: ${(props) => props.theme.colors.containers.baseContainer};
      cursor: pointer;
      color: ${(props) => props.theme.colors.typography.baseText};
      font-size: 0.8rem;
      border: 2px solid transparent;
      animation: all 0.3s ease-in-out;
      text-transform: uppercase;
    }

    .checkedInput {
      background: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
    }
  }
`;

export const FieldAreaText = styled.div`
  width: 100%;
  position: relative;

  .containerVariableText {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    padding: 0.5rem;
    background-color: #4a4a4a3d;

    .contentVariableText {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 3px;
      padding: 0.4rem;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;
    }
  }
`;
