import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerScreens = styled(motion.div)`
  width: 100%;
  height: 100%;

  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  user-select: none;

  /* overflow: auto; */

  /* @media (max-width: 1200px) {
    overflow: scroll;
  } */

  /* @media (max-height: 450px) {
    height: 85%;
  } */
`;
