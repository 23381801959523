import styled from 'styled-components';

export const TableRoutingProfile = styled.div`
  width: 100%;
  position: relative;
  flex: 1;
  max-height: calc(100vh - 330px);
  min-height: 200px;
  overflow-x: auto;

  .showScreensBtn {
    button {
      all: unset;
      cursor: pointer;
      color: ${(props) => props.theme.colors.primary};

      &:disabled {
        opacity: 0.6;
      }

      &:hover {
        text-decoration: underline;
        user-select: none;
      }
    }
  }

  table {
    width: 100%;

    tbody {
      tr {
        height: 48px;
        max-width: 200px;
        td {
          z-index: 1;
          padding: 0 0.8rem;

          &:nth-child(1) {
            min-width: 120px;
            width: 200px;
          }
          &:nth-child(2) {
            min-width: 120px;
          }

          &:nth-child(3) {
            min-width: 120px;
          }

          &:nth-child(4) {
            min-width: 200px;
          }

          &:last-child {
            width: 40px;
          }
        }
      }
    }

    @media (max-width: 1200px) {
      display: table;
    }
  }

  @media (max-width: 500px) {
    max-height: calc(100vh - 300px);
    table tbody,
    table tr,
    table td {
      display: block;
      width: 100%;
    }

    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          margin-bottom: 12px;
          width: 100%;
          min-width: 100%;
          height: auto;
          padding: 0.6rem 0;
          border-left: 3px solid ${(props) => props.theme.colors.primary};

          td {
            padding-left: 50%;
            text-align: left;
            position: relative;
            padding-bottom: 6px;

            span {
              font-size: 12px;
            }
            &::before {
              content: attr(data-label) ':';
              position: absolute;
              left: 0;
              width: 50%;
              padding-left: 15px;
              text-align: left;
              font-size: 12px;
              color: ${(props) => props.theme.colors.typography.baseText};
              line-height: 1.2;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;
