import 'reactflow/dist/style.css';
import { useCallback, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import ReactFlow, { Background, addEdge, useReactFlow, Panel } from 'reactflow';
import { nodeTypes } from '../nodes/nodeTypes';
import { useNodeFlow } from '@/components/pages/FlowBuilder/hooks/useNodeFlow';
import { edgeTypes } from '../edges/edgeTypes';
import { HeaderFlow } from './HeaderFlow';
import { errorToast } from '@/components/FormComponents/Toast';
import { usePermissionsFlow } from '@/components/pages/FlowBuilder/hooks/usePermissionsFlow';
import HelperLinesRenderer from '../../../utils/HelperLines';
import { ContainerFlowConstructor, ContentFlow } from './styled';

import { dataNodeErrorConnect, isSavedFlow } from '@/store/FlowBuilder';
import { PanelFlow } from './PanelFlow';
import { PanelSearch } from './PanelSearch';
import { useKeysFlowBuilder } from '../../../hooks/useKeysFlowBuilder';
import { useFitViewFlowBuilder } from '../../../hooks/useFitViewFlowBuilder';

export const FlowConstructor = ({ dataNodes }) => {
  const { t } = useTranslation();
  const { reactFlowInstance, setReactFlowInstance, handleFitView, fitView } =
    useFitViewFlowBuilder();
  const {
    handlerAddNodeByType,
    renderSubItensConditional,
    renderLabelLoop,
    renderLabelHangUp,
    checkTargetUsedNodes,
    renderEdge,
  } = useNodeFlow(dataNodes);
  const { zoomIn, zoomOut } = useReactFlow();
  const { permissionsCreate, permissionsEdit, isSysAdmin } =
    usePermissionsFlow();

  useKeysFlowBuilder(dataNodes);

  const theme = useTheme();

  const [, setNodeErrorConnect] = useAtom(dataNodeErrorConnect);
  const [, setIsSaved] = useAtom(isSavedFlow);
  const [statusPanelSearch, setStatusPanelSearch] = useState(true);
  const [showRules, setShowRules] = useState(true);

  const onConnect = useCallback(
    (params) => {
      const hasUsedTargetNode = checkTargetUsedNodes(params.source);

      if (hasUsedTargetNode) {
        return errorToast(t('flowBuilder.flow.msg-error-hasConnected'));
      }
      setIsSaved(false);

      const removeErrorNodeConnect = dataNodes.nodes.map((item) => {
        const filterSubNode = dataNodes.nodes
          .filter((item) => item.id === params.source)
          .map((item) => item.parentNode)
          .toString();

        const filterSuccessParentParams = params.source.replace(
          /sub_id_|_[^_ ]+$/g,
          ''
        );

        if (
          item.id === params.source ||
          item.id === filterSuccessParentParams ||
          item.id === filterSubNode
        ) {
          setNodeErrorConnect(null);
          return {
            ...item,
            data: {
              ...item.data,
              errorConnect: false,
            },
          };
        }

        return item;
      });

      dataNodes.setNodes(removeErrorNodeConnect);

      dataNodes.setEdges((eds) =>
        addEdge({ ...params, ...renderEdge(params?.source) }, eds)
      );
    },
    [dataNodes, setIsSaved]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const type = event.dataTransfer.getData('application/reactflow');
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNodeToAdd = handlerAddNodeByType(
        type,
        position,
        renderLabelHangUp(),
        renderLabelLoop()
      );
      dataNodes.setNodes((nds) => nds.concat(newNodeToAdd));

      const subItemsRender = renderSubItensConditional(
        newNodeToAdd.id,
        newNodeToAdd.type
      );

      if (type === 'dialplanVariables') {
        return subItemsRender.map((item) =>
          dataNodes.setNodes((nds) => nds.concat(item))
        );
      }

      if (type === 'apiData') {
        subItemsRender.map((item) =>
          dataNodes.setNodes((nds) => nds.concat(item))
        );
        return;
      }

      if (type === 'ttsVoicceLabs') {
        return subItemsRender.map((item) =>
          dataNodes.setNodes((nds) => nds.concat(item))
        );
      }
      if (type === 'conditionalNode') {
        return subItemsRender.map((item) =>
          dataNodes.setNodes((nds) => nds.concat(item))
        );
      }

      if (type === 'loop') {
        return subItemsRender.map((item) => {
          return dataNodes.setNodes((nds) => nds.concat(item));
        });
      }

      if (type === 'transferQueue') {
        return subItemsRender.map((item) => {
          return dataNodes.setNodes((nds) => nds.concat(item));
        });
      }

      if (type === 'dtmf') {
        return subItemsRender.map((item) => {
          return dataNodes.setNodes((nds) => nds.concat(item));
        });
      }
    },
    [reactFlowInstance, dataNodes]
  );

  const nodesPrepare = useMemo(() => dataNodes.nodes, [dataNodes.nodes]);
  const edgesPrepare = useMemo(() => dataNodes.edges, [dataNodes.edges]);

  const permissionsUser = permissionsCreate || permissionsEdit || isSysAdmin;

  return (
    <ContainerFlowConstructor>
      <HeaderFlow dataNodes={dataNodes} />
      <ContentFlow>
        <div className='bg-water-mark'></div>
        <ReactFlow
          nodes={nodesPrepare}
          edges={edgesPrepare}
          nodesDraggable={permissionsUser}
          elementsSelectable={permissionsUser}
          nodesConnectable={permissionsUser}
          onNodesChange={dataNodes.handleNodesChange}
          onEdgesChange={dataNodes.handleEdgesChange}
          onConnect={onConnect}
          onInit={setReactFlowInstance}
          onDrop={onDrop}
          onDragOver={onDragOver}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onSelectionChange={dataNodes.setSelectedNodes}
          deleteKeyCode={null}
          zoomOnDoubleClick={false}
          minZoom={0.1}
          selectNodesOnDrag={false}
          multiSelectionKeyCode='Shift'
        >
          <Background
            style={{
              background: `${theme.colors.containers.baseContainerFlow}`,
            }}
          />

          {showRules && (
            <HelperLinesRenderer
              horizontal={dataNodes.helperLineHorizontal}
              vertical={dataNodes.helperLineVertical}
            />
          )}

          <PanelSearch
            dataNodes={dataNodes}
            fitView={fitView}
            controlPanelSearch={{ statusPanelSearch, setStatusPanelSearch }}
          />

          <Panel position='bottom-left'>
            <PanelFlow
              handleFitView={handleFitView}
              setShowRules={setShowRules}
              showRules={showRules}
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              statusPanelSearch={statusPanelSearch}
              setStatusPanelSearch={setStatusPanelSearch}
            />
          </Panel>
        </ReactFlow>
      </ContentFlow>
    </ContainerFlowConstructor>
  );
};
