import { FlexContainer } from '@/components/layout/FlexContainer/styled';
import { Skeleton } from '@mui/material';

export const SkeletonLoadDash = () => {
  return (
    <FlexContainer
      width={'100%'}
      height={'100%'}
      direction={'column'}
      gap={'1.4rem'}
    >
      <FlexContainer
        width={'100%'}
        height={'100%'}
        direction={'row'}
        gap={'1.4rem'}
      >
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={'100%'}
          sx={{ borderRadius: '4px' }}
        />
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={'100%'}
          sx={{ borderRadius: '4px' }}
        />
      </FlexContainer>
      <FlexContainer
        width={'100%'}
        height={'100%'}
        direction={'row'}
        gap={'1.4rem'}
      >
        <Skeleton
          variant='rectangular'
          width={'75%'}
          height={'100%'}
          sx={{ borderRadius: '4px' }}
        />
        <Skeleton
          variant='rectangular'
          width={'25%'}
          height={'100%'}
          sx={{ borderRadius: '4px' }}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export const SkeletonLoadFieldDash = () => {
  return (
    <Skeleton
      variant='rectangular'
      width={'100%'}
      height={'40px'}
      sx={{ borderRadius: '4px' }}
    />
  );
};
