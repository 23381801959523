import useWindowDimensions from '@/hooks/useWidth';
import { dataNodeErrorConnect, templateSelected } from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useReactFlow } from 'reactflow';

export const useFitViewFlowBuilder = () => {
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { fitView } = useReactFlow();
  const [nodeErrorConnect] = useAtom(dataNodeErrorConnect);
  const [template] = useAtom(templateSelected);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (reactFlowInstance) {
      const timer = setTimeout(() => {
        fitView({
          maxZoom: 0.8,
          duration: 200,
        });
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [template, reactFlowInstance]);

  useEffect(() => {
    if (reactFlowInstance) {
      fitView({
        maxZoom: '0.8',
        duration: 200,
      });
    }
  }, [height, width]);

  const handleFitView = () => {
    fitView({
      maxZoom: '0.8',
      duration: 200,
    });
  };

  useEffect(() => {
    if (nodeErrorConnect) {
      fitView({
        nodes: [nodeErrorConnect],
        padding: 0.1,
        includeHiddenNodes: false,
        maxZoom: '0.9',
        duration: 200,
      });
    }
  }, [nodeErrorConnect]);

  return { reactFlowInstance, setReactFlowInstance, handleFitView, fitView };
};
