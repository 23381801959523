import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { currentFlowScreens } from '@/store/Screens';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { dataRoutesProfileSelected } from '@/store/UsersAgentsStore/userAgentsStore';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { successToast } from '@/components/FormComponents/Toast';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import {
  ContentImportScreens,
  DetailRelationshipSelected,
  FooterImportScreen,
  FormImportScreen,
  HeaderImportScreens,
} from './styled';

export const ImportScreen = () => {
  const { t } = useTranslation();
  const [, setStateRenderScreens] = useAtom(currentFlowScreens);
  const [dataRouteSelected] = useAtom(dataRoutesProfileSelected);

  const addNewClientSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    relationship: yup.string().required(),
  });

  const filterScreens = dataRouteSelected.screens;

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(addNewClientSchema),
  });

  const watchRelationship = watch('relationship', null);

  const filterScreeSelected =
    filterScreens.filter((item) => item.id === watchRelationship)[0] ?? null;

  const handleSubmitImportScreen = (data) => {
    console.log(data, filterScreeSelected);
    setStateRenderScreens(0);
    successToast('Success to import screen');
  };

  const renderNameType = (type) => {
    switch (type) {
      case 'menu':
        return 'Menu';
      case 'titleMenu':
        return 'Title';
      case 'submenu':
        return 'Sub menu';
      case 'iframe':
        return 'Menu';
      default:
        return '-';
    }
  };

  return (
    <ContentImportScreens initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <HeaderImportScreens>
        <motion.div
          whileTap={{ scale: 0.8 }}
          className='icon_handle_back'
          onClick={() => setStateRenderScreens(0)}
          type='button'
        >
          <FaArrowLeft />
        </motion.div>
        <TitleComponent textTitle={t('screens.importScreen.title')} />
      </HeaderImportScreens>
      <FormImportScreen onSubmit={handleSubmit(handleSubmitImportScreen)}>
        <DescriptionInformation
          textDescription={t('screens.importScreen.description')}
        />
        <Stack direction={'row'} alignItems={'center'} spacing={3}>
          <ControlInput
            control={control}
            nameControl={'name'}
            label={t('screens.addScreen.label-name')}
            placeholder={t('screens.addScreen.label-name')}
            autoComplete='off'
            error={Boolean(errors.name)}
            helperText={errors.name ? errors.name.message : ' '}
          />
          <ControlInput
            control={control}
            nameControl={'description'}
            label={t('screens.addScreen.label-description')}
            placeholder={t('screens.addScreen.label-description')}
            autoComplete='off'
            error={Boolean(errors.description)}
            helperText={errors.description ? errors.description.message : ' '}
          />
        </Stack>
        <ControlSelect
          control={control}
          nameControl={'relationship'}
          label={t('screens.addScreen.label-relationship')}
          error={Boolean(errors.relationship)}
          helperText={errors.relationship ? errors.relationship.message : '   '}
        >
          <ItemSelect value={'0'} disabled>
            {t('screens.addScreen.option-relationship')}
          </ItemSelect>
          {filterScreens.length > 0 &&
            filterScreens.map((item, i) => (
              <ItemSelect key={i} value={item.id}>
                {item.name}
              </ItemSelect>
            ))}
        </ControlSelect>

        {filterScreeSelected ? (
          <DetailRelationshipSelected>
            <div className='contentItem'>
              <Stack direction={'row'}>
                <div className='itemSelected'>
                  <span>{t('screens.table.th-name')}: </span>
                  <span>{filterScreeSelected.name ?? '-'}</span>
                </div>
                <div className='itemSelected'>
                  <span>{t('screens.table.th-description')}: </span>
                  <span>{filterScreeSelected.description ?? '-'}</span>
                </div>
              </Stack>
              <Stack direction={'row'}>
                <div className='itemSelected'>
                  <span>{t('screens.table.th-slug')}: </span>
                  <span>{renderNameType(filterScreeSelected.slug) ?? '-'}</span>
                </div>
                <div className='itemSelected'>
                  <span>{t('screens.table.th-area')}: </span>
                  <span>
                    {filterScreeSelected.area ? filterScreeSelected.area : '-'}
                  </span>
                </div>
              </Stack>
              <Stack direction={'row'}>
                <div className='itemSelected'>
                  <span>{t('screens.table.th-relationship')}: </span>
                  <span>
                    {filterScreeSelected.parent_id
                      ? filterScreeSelected.parent_id
                      : '-'}
                  </span>
                </div>
                <div className='itemSelected'>
                  <span>{t('screens.table.th-icon')}: </span>
                  <span>
                    {filterScreeSelected.icon ? filterScreeSelected.icon : '-'}
                  </span>
                </div>
              </Stack>
              <Stack direction={'row'}>
                <div className='itemSelected'>
                  <span>{t('screens.table.th-hierarchy')}: </span>
                  <span>
                    {filterScreeSelected.hierarchy
                      ? filterScreeSelected.hierarchy
                      : 'False'}
                  </span>
                </div>

                <div className='itemSelected'>
                  <span>{t('screens.table.th-sequence')}: </span>
                  <span>{filterScreeSelected.sequence ?? '-'}</span>
                </div>
              </Stack>
            </div>
          </DetailRelationshipSelected>
        ) : (
          <div></div>
        )}
        <FooterImportScreen>
          <Button
            typeText
            variant={'secondary'}
            onClick={() => setStateRenderScreens(0)}
          >
            {t('screens.addScreen.btn-cancel')}
          </Button>
          <Button typeText type='submit' size={'small'}>
            {t('screens.addScreen.btn-confirm')}
          </Button>
        </FooterImportScreen>
      </FormImportScreen>
    </ContentImportScreens>
  );
};
