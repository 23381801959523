import * as yup from 'yup';
import useFormControl from '@/hooks/useFormControl';

import { FormPanelControl } from './styled';
import { Grid, Stack, CircularProgress } from '@mui/material';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ControlDataPicker } from '@/components/FormComponents/DataPickerComponent';
import { sub } from 'date-fns';
import { MdSearch } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { ControlAutoComplete } from '@/components/FormComponents/AutoCompleteSelect';

export const PanelControl = ({ getListMutate, dataCampaigns, isLoading }) => {
  const { t } = useTranslation();

  const searchJourneySchema = yup.object().shape({
    campaign: yup.object().required(),
    typePeriod: yup.object().required(),
    initialDate: yup.date(),
    endedDate: yup.date(),
  });

  const { handleSubmit, control, errors } = useFormControl(searchJourneySchema);

  const handleSubmitSearch = (data) => {
    const dataSearch = {
      campaign: data.campaign.value,
      typePeriod: data.typePeriod.value,
      initialDate: data.initialDate,
      endedDate: data.endedDate,
    };
    getListMutate(dataSearch);
  };

  const renderOptionsCampaigns = dataCampaigns?.map((item) => {
    return {
      label: item.campanha,
      value: item.campanha,
    };
  });

  const renderOptionsTypePeriod = [
    {
      label: t('journey.option-period-between'),
      value: 'date_between',
    },
  ];

  const permissionsComponent = true;

  return (
    <FormPanelControl onSubmit={handleSubmit(handleSubmitSearch)}>
      <ControlAutoComplete
        control={control}
        nameControl={'campaign'}
        label={t('journey.label-selectCampaign')}
        optionsList={renderOptionsCampaigns}
        errorAutoComplete={Boolean(errors.campaign)}
      />

      <Grid container spacing={2}>
        <Grid item xs={4} sx={{ marginTop: '-8px' }}>
          <ControlAutoComplete
            control={control}
            nameControl={'typePeriod'}
            label={t('journey.label-period')}
            optionsList={renderOptionsTypePeriod}
            errorAutoComplete={Boolean(errors.typePeriod)}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlDataPicker
            label={t('journey.label-initialDate')}
            control={control}
            nameControl={'initialDate'}
            defaultValue={sub(new Date(), {
              days: 1,
            })}
          />
        </Grid>
        <Grid item xs={4}>
          <Stack direction={'row'} spacing={2}>
            <ControlDataPicker
              label={t('journey.label-endDate')}
              control={control}
              nameControl={'endedDate'}
              defaultValue={new Date()}
            />
          </Stack>
        </Grid>
      </Grid>

      <Stack justifyContent={'flex-end'} alignItems={'flex-end'}>
        <Button
          type='submit'
          disabled={isLoading || !permissionsComponent}
          title={t('journey.title-btn')}
        >
          {!isLoading ? (
            <MdSearch size={22} />
          ) : (
            <CircularProgress
              size={22}
              sx={{
                color: 'white',
              }}
            />
          )}
        </Button>
      </Stack>
    </FormPanelControl>
  );
};
