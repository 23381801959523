import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { formatTime } from '@/utils/formatsDateAndTime';
import { ContainerCurrentTime } from './styled';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { errorToast } from '@/components/FormComponents/Toast';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import { FlexContainer } from '@/components/layout/FlexContainer/styled';

export const DateConditions = ({ dataConditions }) => {
  const [conditionTimeItem, setConditionTimeItem] = useState('select-period');

  const { t } = useTranslation();

  const dataOptionsPeriod = [
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.label-period'),
      description: '',
      value: 'select-period',
      disabled: true,
      type: 'period',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.label-morning'),
      description: '06:00AM / 11:59AM',
      value: 'morning',
      disabled: false,
      type: 'period',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.label-afternoon'),
      description: '12:00PM / 17:59PM',
      value: 'afternoon',
      disabled: false,
      type: 'period',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.label-night'),
      description: '18:00PM / 11:59PM',
      value: 'night',
      disabled: false,
      type: 'period',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.label-groupDays'),
      description: '',
      value: 'groupDays',
      disabled: true,
      type: 'groupDays',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.weekdays.label-day-7'),
      description: '',
      value: '7',
      disabled: false,
      type: 'groupDays',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.weekdays.label-day-8'),
      description: '',
      value: '8',
      disabled: false,
      type: 'groupDays',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.label-weekdays'),
      description: '',
      value: 'weeksDay',
      disabled: true,
      type: 'weekdays',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.weekdays.label-day-0'),
      description: '',
      value: '0',
      disabled: false,
      type: 'weekdays',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.weekdays.label-day-1'),
      description: '',
      value: '1',
      disabled: false,
      type: 'weekdays',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.weekdays.label-day-2'),
      description: '',
      value: '2',
      disabled: false,
      type: 'weekdays',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.weekdays.label-day-3'),
      description: '',
      value: '3',
      disabled: false,
      type: 'weekdays',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.weekdays.label-day-4'),
      description: '',
      value: '4',
      disabled: false,
      type: 'weekdays',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.weekdays.label-day-5'),
      description: '',
      value: '5',
      disabled: false,
      type: 'weekdays',
    },
    {
      label: t('flowBuilder.flow.nodes.conditions.menu.weekdays.label-day-6'),
      description: '',
      value: '6',
      disabled: false,
      type: 'weekdays',
    },
  ];

  const renderCurrentTime = () => {
    const currentTime = formatTime(new Date());
    return currentTime;
  };

  const renderPeriod = () => {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 6 && hour < 12) {
      return t('flowBuilder.flow.nodes.conditions.menu.label-morning');
    } else if (hour >= 12 && hour < 18) {
      return t('flowBuilder.flow.nodes.conditions.menu.label-afternoon');
    } else {
      return t('flowBuilder.flow.nodes.conditions.menu.label-night');
    }
  };

  const handleNewConditionTime = () => {
    const { setConditionsList, setConditionSelected } = dataConditions;

    const checkExistCondition = dataConditions.conditionsList.filter(
      (item) => item.value === conditionTimeItem
    );

    if (checkExistCondition.length > 0) {
      return errorToast(
        t('flowBuilder.flow.nodes.conditions.menu.msg-condition-existing')
      );
    }

    const newCondition = {
      id: uuid(),
      condition:
        dataOptionsPeriod.filter((item) => item.value === conditionTimeItem)[0]
          ?.type ?? null,
      value: conditionTimeItem,
    };

    setConditionTimeItem('select-period');
    setConditionsList((items) => [...items, newCondition]);
    setConditionSelected({});
  };

  const hasDisabledButton =
    conditionTimeItem === 'select-period' ||
    conditionTimeItem === 'groupDays' ||
    conditionTimeItem === 'weeksDay';

  return (
    <ContainerCurrentTime>
      <div className='header-content-time'>
        <h4>
          {t('flowBuilder.flow.nodes.conditions.menu.title-areaConditions')}
        </h4>
        <FlexContainer direction={'row'} gap={'4px'} justify={'space-between'}>
          <span>
            {t('flowBuilder.flow.nodes.conditions.menu.label-hour')}:{' '}
            {renderCurrentTime()}
          </span>
          <span>
            {t('flowBuilder.flow.nodes.conditions.menu.label-period')}:{' '}
            {renderPeriod()}
          </span>
        </FlexContainer>
      </div>

      <SelectComponent
        defaultValue='select-period'
        value={conditionTimeItem}
        onChange={(e) => setConditionTimeItem(e.target.value)}
      >
        {dataOptionsPeriod.map((period) => (
          <ItemSelect
            key={period.value}
            value={period.value}
            disabled={period.disabled}
          >
            <FlexContainer direction={'row'} gap={'4px'} align={'flex-end'}>
              <span>{period.label}</span>
              <span
                style={{
                  fontSize: '12px',
                  opacity: 0.5,
                }}
              >
                {period.description}
              </span>
            </FlexContainer>
          </ItemSelect>
        ))}
      </SelectComponent>

      <FlexContainer
        direction={'row'}
        width={'100%'}
        justify={'flex-end'}
        gap={'16px'}
      >
        <Button
          typeText
          type='button'
          onClick={handleNewConditionTime}
          title={t('flowBuilder.flow.nodes.conditions.menu.btn-add')}
          disabled={hasDisabledButton}
        >
          {t('flowBuilder.flow.nodes.conditions.menu.btn-add')}
        </Button>
      </FlexContainer>
    </ContainerCurrentTime>
  );
};
