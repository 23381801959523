import { useAtom } from 'jotai';
import { currentFlowScreens } from '@/store/Screens';
import { ContainerScreens } from './styled';
import { useEffect, useId } from 'react';
import { HomeScreens } from './stages/HomeScreens';
import { CreateScreen } from './stages/CreateScreen';
import { EditScreen } from './stages/EditScreen';
import { ImportScreen } from './stages/ImportScreen';

export const Screens = ({ origin = false }) => {
  const [stateRenderScreens, setStateRenderScreens] =
    useAtom(currentFlowScreens);

  useEffect(() => {
    return () => {
      setStateRenderScreens(0);
    };
  }, []);

  const flowScreens = [
    <HomeScreens key={useId()} origin={origin} />,
    <CreateScreen key={useId()} />,
    <EditScreen key={useId()} />,
    <ImportScreen key={useId()} />,
  ];

  return <ContainerScreens>{flowScreens[stateRenderScreens]}</ContainerScreens>;
};
