import { useQuery, useMutation } from 'react-query';
import { useAtom } from 'jotai';
import { errorToast } from '@/components/FormComponents/Toast';
import { getClients } from '@/services/clients/getClients';
import { currentFlowClients, dataListClients } from '@/store/Clients';
import { createClient } from '@/services/clients/createClient';
import { updateClient } from '@/services/clients/updateClient';
import { useTranslation } from 'react-i18next';

export const useClients = (isSysAdmin = false) => {
  const { t } = useTranslation();
  const [, setDataList] = useAtom(dataListClients);
  const [stateRenderClients] = useAtom(currentFlowClients);

  const { isLoading: isLoadingDataClients } = useQuery(
    'dataListClients',
    async () => {
      return await getClients();
    },
    {
      retry: false,
      enabled: stateRenderClients === 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setDataList(data);
      },
      onError: () => {
        errorToast(t('clients.homeClients.messages.error-getList'));
      },
    }
  );

  const { mutate: mutateCreateClient, isLoading: loadingCreateClient } =
    useMutation({
      mutationFn: async (dataCreate) => {
        return await createClient(dataCreate);
      },
      onError: () => {
        errorToast(t('clients.createClient.messages.error-created'));
      },
    });

  const { mutate: mutateUpdateClients, isLoading: loadingUpdateClients } =
    useMutation({
      mutationFn: async (dataCreate) => {
        return await updateClient(dataCreate);
      },
      onError: () => {
        errorToast(t('clients.createClient.editClient.error-updated'));
      },
    });

  return {
    isLoadingDataClients,
    mutateCreateClient,
    loadingCreateClient,
    mutateUpdateClients,
    loadingUpdateClients,
  };
};
