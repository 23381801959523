import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerDetailQueueMonitor = styled(motion.div)`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .area-detail {
    grid-column: 1/3;
    @media (max-width: 710px) {
      grid-column: 1/4;
    }
  }

  .area-chart {
    grid-column: 3/4;
    @media (max-width: 710px) {
      grid-column: 1/4;
    }
  }

  @media (max-width: 960px) {
    grid-template-columns: repeat(1, 1fr);
  }

  padding: 2px;
  gap: 1rem;
`;

export const ChartQueueMonitor = styled(motion.div)`
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  border-radius: 3px;
  padding: 1rem 1rem 0rem 1rem;
  position: relative;
  overflow: hidden;

  @media (max-width: 710px) {
    padding: 1rem;
  }

  &::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

export const ContentDataDetail = styled(motion.div)`
  width: 100%;
  padding: 1rem 0 0 0;

  .detail-queues {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;

    @media (max-width: 570px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .item-detail {
      display: flex;
      flex-direction: column;
      gap: 3px;
      overflow: hidden;

      span {
        font-size: 0.72rem;
        display: flex;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.typography.baseText}AA;
      }

      strong {
        font-size: 0.75rem;
        display: flex;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }

    .total-grid {
      grid-column: 1/2;
      @media (max-width: 570px) {
        grid-column: 2/4;
        grid-row: 2/3;
      }
    }

    .operators-grid {
      grid-column: 3/4;
      @media (max-width: 570px) {
        grid-column: 1/2;
        grid-row: 2/3;
      }
    }

    .dispositions-grid {
      grid-column: 2/4;

      @media (max-width: 570px) {
        grid-column: 1/4;
      }
    }
  }
`;
