import WaveFlowDtmf from '@/components/Waveform/waveFlowDtmf';
import { ContainerPlayer } from './styles';

export const PlayerMessage = ({ soundResponse, setIsPlayingMessage }) => {
  return (
    <ContainerPlayer>
      <WaveFlowDtmf
        audio={soundResponse}
        setIsPlayingMessage={setIsPlayingMessage}
      />
    </ContainerPlayer>
  );
};
