import { lastUserUpdate, templateSelected } from '@/store/FlowBuilder';

import { renderDate } from '@/utils/formatsDateAndTime';
import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const DetailFlow = () => {
  const { t } = useTranslation();
  const [lastUserName] = useAtom(lastUserUpdate);
  const [template] = useAtom(templateSelected);

  const renderDescription =
    !template.descriptionIVR || template.descriptionIVR === ''
      ? t('flowBuilder.flow.navigationFlow.option-no-description')
      : template.descriptionIVR;

  return (
    <Stack direction={'column'} spacing={1}>
      <Stack direction={'row'} spacing={1}>
        <strong>
          {t('flowBuilder.flow.navigationFlow.label-description-save-flow')}:
        </strong>
        <span>{renderDescription}</span>
      </Stack>

      <Stack direction={'row'} spacing={1}>
        <strong>
          {t('flowBuilder.flow.navigationFlow.label-lastUpdate-flow')}:
        </strong>
        <span>{renderDate(template.lastUpdate)}</span>
      </Stack>

      <Stack direction={'row'} spacing={1}>
        <strong>{t('flowBuilder.flow.navigationFlow.label-user-flow')}:</strong>
        <span>{lastUserName}</span>
      </Stack>
    </Stack>
  );
};
