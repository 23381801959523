export async function getPauses(dataPause) {
  try {
    // const { queue } = dataPause;
    // const { data } = await newAgents.post('/agentes/getPausas', {
    //   fila: queue,
    // });

    return dataPause;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
