import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { ContainerQueueCampaign, PanelQueueCampaign } from './styled';
import { dataListCampaigns } from '@/store/Campaigns';
import { useQueueCampaign } from '@/hooks/dialControl/QueueCampaign/useQueueCampaign';
import { ListQueueCampaigns } from './stages/ListQueueCampaigns';
import { dataQueues } from '@/store/Queues';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const QueueCampaign = () => {
  const { t } = useTranslation();

  const {
    loadingDataQueues,
    loadingDataCampaigns,
    mutateQueueCampaign,
    loadingQueueCampaign,
  } = useQueueCampaign();

  const [listQueues] = useAtom(dataQueues);
  const [dataCampaign] = useAtom(dataListCampaigns);

  const [filterCampaign, setFilterCampaign] = useState(0);

  const filtered = (() => {
    if (filterCampaign === 0) {
      return listQueues.sort((a, b) => a.fila - b.fila);
    }
    const filterData = listQueues.filter(
      (data) => data.fila === filterCampaign
    );
    return filterData.sort((a, b) => a.fila - b.fila);
  })();

  const isLoading =
    loadingDataQueues || loadingDataCampaigns || loadingQueueCampaign;

  return (
    <ContainerQueueCampaign initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <PanelQueueCampaign>
        <SelectComponent
          labelSelect={t('dialControl.queueCampaign.label-filterQueues')}
          value={filterCampaign}
          onChange={(event) => setFilterCampaign(event.target.value)}
        >
          <ItemSelect value={0}>
            {t('dialControl.queueCampaign.option-filterCampaign')}
          </ItemSelect>
          {listQueues.length > 0 &&
            listQueues.map((item, i) => (
              <ItemSelect value={item.fila} key={i}>
                {item.fila}
              </ItemSelect>
            ))}
        </SelectComponent>
      </PanelQueueCampaign>

      <ListQueueCampaigns
        dataTable={filtered}
        campaigns={dataCampaign}
        mutateQueueCampaign={mutateQueueCampaign}
      />
    </ContainerQueueCampaign>
  );
};
