import styled from 'styled-components';

export const FormExportFlow = styled.form`
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.4rem;

  .heater_details {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    h4 {
      color: ${(props) => props.theme.colors.typography.baseText};
      font-weight: 400;
    }

    svg {
      font-size: 1.1rem;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const PanelExportFlow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;
