import { apiData } from '@/services/api';

export async function deleteCampaign(dataRemoveCampaign) {
  try {
    const { data } = await apiData.delete(
      `dialer/campaign/${dataRemoveCampaign}`
    );
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
