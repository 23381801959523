import { apiData } from '../api';

export async function cleanListQueue(queueClean) {
  try {
    const { queue } = queueClean;
    const { data } = await apiData.put(`dialer/cleanQueue/${queue}`);

    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
