import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ItemsFieldsHeader } from '../styled';
import { CheckBoxFlow } from '@/components/FormComponents/CheckboxComponent';
import { FlexContainer } from '@/components/layout/FlexContainer/styled';

export const SoundRateField = ({ soundRate, setSoundRate }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const renderColorTheme = `${theme.colors.containers.primaryToDark} !important`;

  return (
    <ItemsFieldsHeader>
      <div className='header_item'>
        <h4 style={{ fontSize: '0.72rem', fontWeight: 600 }}>
          {' '}
          {t('flowBuilder.flow.nodes.dataApi.menu.title-soundRate')}
        </h4>
        <FlexContainer width={'100%'} align={'flex-end'} padding={'0 0 0 6px'}>
          <CheckBoxFlow
            checked={soundRate}
            onChange={(e) => setSoundRate(e.target.checked)}
            size='small'
            sx={{ color: renderColorTheme }}
          />
        </FlexContainer>
      </div>
    </ItemsFieldsHeader>
  );
};
