import { apiData } from '@/services/api';

export async function deleteCallOperators(dataDelete) {
  try {
    const { data } = await apiData.delete(
      `dialer/deleteOperator/${dataDelete}`
    );
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
