export async function createNlus(dataCreateNlu) {
  try {
    console.log('🚀 -> dataCreateNlu:', dataCreateNlu);
    return new Promise((resolve) => {
      const data = { status: 'success' };
      setTimeout(() => resolve(data), 500);
    });
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
