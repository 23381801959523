import { apiFlow } from '../api';

export async function getFlowTemplatesByClientId(client_id) {
  try {
    const { data } = await apiFlow.get(`/dialplan-metadata/${client_id}`);
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
