import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { updateSystemQueueControl } from '@/services/dialControl/systemQueueControl/updateSystemQueue';
import { dataQueues, dataServersControl } from '@/store/Queues';
import { getQueuesCampaigns } from '@/services/dialControl/campaigns/getQueuesCampaigns';

export const useSystemQueueControl = () => {
  const { t } = useTranslation();
  const [, setDataQueues] = useAtom(dataQueues);

  const [serversControl] = useAtom(dataServersControl);

  const { isLoading: loadingDataQueues, refetch: refetchDataQueues } = useQuery(
    'getQueuesControl',
    async () => {
      const dataAllLists = [
        {
          first_queue: serversControl.first_queue,
          last_queue: serversControl.last_queue,
          isActive: true,
        },
        {
          first_queue: serversControl.first_queue,
          last_queue: serversControl.last_queue,
          isActive: false,
        },
      ];

      const requests = dataAllLists.map((data) => {
        return getQueuesCampaigns(data);
      });

      const results = await Promise.all(requests);
      return results.flat();
    },
    {
      enabled: !!serversControl,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setDataQueues(data),

      onError: () => {
        errorToast(t('dialing.newListConfig.messages.errorRequesStatus'));
      },
    }
  );

  const {
    mutate: mutateUpdateSystemQueue,
    isLoading: loadingUpdateSystemQueue,
  } = useMutation({
    mutationFn: async (dataUpdateStatus) => {
      return await updateSystemQueueControl(dataUpdateStatus);
    },
    onSuccess: () => {
      refetchDataQueues();
    },
    onError: () => {
      errorToast(t(`dialControl.systemQueueControl.msg-errorUpdate`));
    },
  });

  return {
    loadingDataQueues,
    mutateUpdateSystemQueue,
    loadingUpdateSystemQueue,
  };
};
