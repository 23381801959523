import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerAddNewRoute = styled(motion.div)`
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;

  @media (max-height: 1550px) {
    height: 92%;
  }

  @media (max-height: 450px) {
    height: 50%;
  }
`;

export const FormAddRoutingProfile = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ContentAddLayout = styled.div`
  width: 100%;
  height: 100%;
  min-height: 120px;
  display: grid;
  grid-template-rows: 1fr 28px;

  .txt_add_routing {
    color: ${(props) => props.theme.colors.typography.baseText};
    font-size: 0.9rem;
  }

  .content_add_layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
  }

  .btn_add_routing {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  .footerForm {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 0.4rem;
  }

  .btn-updated-routing {
  }
`;

export const ContentAddQueue = styled.div`
  width: 100%;
  height: 100%;
  min-height: 260px;
  display: grid;
  grid-template-rows: 30px 40px 1fr 36px;
  gap: 10px;

  .btn_add_routing {
    display: flex;
    justify-content: flex-end;
    max-height: 30px;
    gap: 1rem;
  }

  .footerForm {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    max-height: 30px;
  }
`;

export const ContentTableAddQueues = styled.div`
  width: 100%;
  height: 38vh;
  min-height: 100px;

  @media (max-width: 1600px) {
    height: 30vh;
  }

  overflow: hidden;
  overflow-y: auto;
`;
