import styled from 'styled-components';

export const ContainerChartAttendance = styled.div`
  width: 100%;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.containers.colorCard};
  border-radius: 6px;
  box-shadow: ${(props) => props.theme.colors.containers.baseShadows};
  grid-column: 2 / 3;
  @media (min-width: 2600px) {
    height: 100%;
  }
`;

export const HeaderChartAttendance = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.88rem;
    line-height: 120.69%;

    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: ${(props) => props.theme.colors.typography.baseText};
  }

  span {
    font-style: normal;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 132.19%;

    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;

export const ContentChartAttendance = styled.div`
  /*   width: 100%; */
  .apexcharts-legend-text {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
    padding-top: 4px;
  }
`;
