import { memo } from 'react';
import { MdEdit } from 'react-icons/md';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useAtom } from 'jotai';
import { usePermissionsFlow } from '../../hooks/usePermissionsFlow';
import { ContentPanelNode } from './styled';
import {
  idNodeToRemove,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';

const PanelNodeComponent = ({ id, data }) => {
  const [, setIdNode] = useAtom(idNodeToRemove);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [nodeSelected, setSelectedNode] = useAtom(selectedNode);

  const { permissionsEdit, permissionsDelete, isSysAdmin } =
    usePermissionsFlow();

  const permissionNodeEdit = permissionsEdit || isSysAdmin;
  const permissionNodeDelete = permissionsDelete || isSysAdmin;

  const handleRemoveNode = (idNode) => {
    setIdNode(idNode);
    idNode === nodeSelected?.id && setShowMenu(false);
  };

  const handleSelectedNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  return (
    <ContentPanelNode>
      {permissionNodeEdit && (
        <button onClick={() => handleSelectedNode()}>
          <MdEdit size={16} />
        </button>
      )}
      {permissionNodeDelete && (
        <button onClick={() => handleRemoveNode(id)}>
          <IoIosCloseCircleOutline />
        </button>
      )}
    </ContentPanelNode>
  );
};

export const PanelNode = memo(PanelNodeComponent);
