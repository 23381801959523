import { permissions, sysAdmin } from '@/store/AuthenticatorStore';
import { currentPage, idSub } from '@/store/NavigationDashboard';
import { useAtom } from 'jotai';

export const useSecurityProfileRender = () => {
  const [permissionsProfile] = useAtom(permissions);
  const [currentPageRender] = useAtom(currentPage);
  const [subIdPageRender] = useAtom(idSub);
  const [isSysAdmin] = useAtom(sysAdmin);

  const isSysAdm = !!isSysAdmin;

  const permissionStatus = (screenRender, hasSubId = false) => {
    if (permissionsProfile) {
      const { permissions } = permissionsProfile;

      if (!hasSubId) {
        const filterIdPermissions = permissions
          .filter((item) => item.id === screenRender.id)
          .map((item) => item.permissions);

        const truthPermissions = [];

        filterIdPermissions[0]?.forEach((item) => {
          if (item.value) {
            truthPermissions.push(item.name);
          }
        });
        return truthPermissions;
      }

      const filterIdSubPermissions = permissions
        .filter((item) => item.id === screenRender.id)
        .map((item) => item.subItem);

      if (filterIdSubPermissions.length > 0) {
        const filterPermission = filterIdSubPermissions[0];

        const filterIdPermissions = filterPermission
          .filter((item) => item.id === hasSubId.id)
          .map((item) => item.permissions);

        if (filterIdPermissions.length > 0) {
          const listaVerdadeiros = [];

          filterIdPermissions[0].forEach((item) => {
            if (item.value) {
              listaVerdadeiros.push(item.name);
            }
          });
          return listaVerdadeiros;
        }
        return [];
      }
    }
  };

  const permissionComponent = (typePermission = '') => {
    if (!permissionsProfile) {
      return false;
    }
    const { permissions } = permissionsProfile;
    let truthPermissions = [];

    if (!subIdPageRender) {
      // Filtrar permissões com base no id da página atual

      const currentPagePermissions = permissions.flatMap((item) =>
        item.id === currentPageRender ? item.permissions : []
      );

      truthPermissions = currentPagePermissions
        .map((item) => item.value && item.name)
        .filter((item) => item);
    } else {
      // Filtrar permissões com base no id da página e subItem
      const currentPageSubPermissions = permissions
        .flatMap((item) => (item.id === currentPageRender ? item.subItem : []))
        .flatMap((subItem) =>
          subItem.id === subIdPageRender ? subItem.permissions : []
        )
        .filter((item) => item.value);
      //   truthPermissions = currentPageSubPermissions.map((item) => item.name);
      truthPermissions = currentPageSubPermissions
        .map((item) => item.value && item.name)
        .filter((item) => item);
    }

    return truthPermissions.includes(typePermission);
  };

  return { permissionStatus, permissionComponent, isSysAdm };
};
