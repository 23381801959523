import styled from 'styled-components';

export const ContainerNavigationConfigQueue = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem 0;
  span {
    padding-top: 2px;
  }
  .inativeButton {
    background: gray !important;
  }
`;
