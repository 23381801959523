import { apiData } from '@/services/api';

export async function getRoutingProfileList(signal) {
  try {
    const { data } = await apiData.get('securityProfiles/listRoutingProfiles', {
      signal,
    });
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
