import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerFlowProjects = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 56px);

  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem 2rem;
  gap: 1rem;
  position: relative;
  overflow: scroll;

  .dividerSelectFlow {
    width: 1px;
    height: 100%;
    background-color: #80808063;
  }

  @media (max-width: 530px) {
    display: flex !important;
    flex-direction: column;
    overflow: scroll;
    padding-bottom: 1rem;

    .dividerSelectFlow {
      display: none;
    }
  }
`;
