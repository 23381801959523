import { useEffect, useId } from 'react';
import { ContainerCodeLigs } from './styled';
import { HomeDispositions } from './stages/HomeDispositions';
import { AddDisposition } from './stages/AddDispositions';
import { EditDispositions } from './stages/EditDispositions';
import { useAtom } from 'jotai';
import { stageDispositions } from '@/store/DialingStore';

export const Dispositions = () => {
  const [stateRenderCodeLigs, setStateRenderCodeLigs] =
    useAtom(stageDispositions);

  const handleCodeLigsStage = (stage) => {
    return setStateRenderCodeLigs(stage);
  };

  useEffect(() => {
    return () => {
      setStateRenderCodeLigs(0);
    };
  }, []);

  const flowCodeLigs = [
    <HomeDispositions key={useId()} changeStage={handleCodeLigsStage} />,
    <AddDisposition key={useId()} changeStage={handleCodeLigsStage} />,
    <EditDispositions key={useId()} changeStage={handleCodeLigsStage} />,
  ];

  return (
    <ContainerCodeLigs>{flowCodeLigs[stateRenderCodeLigs]}</ContainerCodeLigs>
  );
};
