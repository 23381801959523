import { useEffect, useLayoutEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FileAreaDrop } from './stages/FileAreaDrop';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { ContainerImportMailing, MainImportMailing } from './styled';
import { ListManagement } from './stages/ListManagement';
import { useLoadList } from '@/hooks/LoadList/useLoadList';
import { useRenderNodesMailing } from '../../hooks/useRenderNodesMailing';
import { useStageControlLists } from '../../hooks/useStageControlLists';
import { PanelImportList } from './components/PanelImportList';
import { ProgressUploadBar } from './components/ProgressUploadBar';
import {
  dataErrorsInCsv,
  dataPreviewImport,
  dataTemplateMailing,
  dataTotalRows,
  fileDataImport,
  hasSavedTemplateMailing,
  isLoadingChangeNewFlow,
  isLoadingRenderFlow,
  savedTemplateMailing,
  selectedTemplate,
  useHeaderCsv,
} from '@/store/LoadList';
import { errorToast } from '@/components/FormComponents/Toast';
import { confirmationOfExchange } from '@/store/NavigationDashboard';

export const ImportMailing = ({ worker }) => {
  const { t } = useTranslation();
  const {
    progress,
    previousProgress,
    setProgress,
    stateProgress,
    setCurrentStateMailing,
  } = useStageControlLists();

  const { mutateDataTemplate } = useLoadList(stateProgress === 1);

  const { renderLayoutNodes, layoutRender } = useRenderNodesMailing();
  const [file, setFile] = useAtom(fileDataImport);

  const [previewList, setPreviewList] = useAtom(dataPreviewImport);
  const [selectedLayout, setSelectedLayout] = useAtom(selectedTemplate);
  const [renderHeaderCsv, setRenderHeaderCsv] = useAtom(useHeaderCsv);
  const [errorsInCsv, setErrosInCsv] = useAtom(dataErrorsInCsv);
  const [showNodesNull, setShowNodesNull] = useState(true);
  const [isImporting, setIsImporting] = useState(false);
  const [useLayout, setUseLayout] = useState(false);
  const [listShowPhones, setListShowPhones] = useState([]);
  const [totalRows, setTotalRows] = useAtom(dataTotalRows);
  const [isLoadingFlow, setLoadingFlow] = useAtom(isLoadingRenderFlow);
  const [dataSavedTemplate, setSavedTemplate] = useAtom(savedTemplateMailing);
  const [, setDataTemplates] = useAtom(dataTemplateMailing);
  const [, setHasSavedTemplate] = useAtom(hasSavedTemplateMailing);
  const totalRowsAccept = 250001;

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [loadingChangeNewFlow] = useAtom(isLoadingChangeNewFlow);
  const [, setConfirmationExchange] = useAtom(confirmationOfExchange);

  useEffect(() => {
    return () => {
      setHasSavedTemplate(true);
      setConfirmationExchange(false);
    };
  }, []);

  useLayoutEffect(() => {
    const handleTouchMove = (event) => {
      event.preventDefault();
    };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  useEffect(() => {
    let timer;
    if (isLoadingFlow || loadingChangeNewFlow) {
      setIsLoadingData(true);
    } else {
      timer = setTimeout(() => {
        setIsLoadingData(false);
      }, 600);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isLoadingFlow, loadingChangeNewFlow]);

  useEffect(() => {
    setSelectedLayout('0');
    setSavedTemplate(null);
    setDataTemplates(null);
  }, [useLayout]);

  useEffect(() => {
    if (progress === 100) {
      setCurrentStateMailing(2);
    }
  }, [progress]);

  const handleNextStage = (dataNewCsv) => {
    setProgress(0);
    setIsImporting(true);

    worker.postMessage({
      type: 'renderNewCsv',
      dataNewCsv: dataNewCsv,
      dataFile: file,
      includeHeader: renderHeaderCsv,
      errorRows: errorsInCsv.map((error) => error.line),
    });
  };

  const handlePreviousStage = () => {
    if (file) {
      setFile(null);
      previousProgress();
      setProgress(0);
      setErrosInCsv([]);
      setRenderHeaderCsv(false);
      setPreviewList(null);
      setTotalRows(0);
      setLoadingFlow(false);
    }
    setLoadingFlow(false);
    setErrosInCsv([]);
    previousProgress();
    setCurrentStateMailing(0);
  };

  useEffect(() => {
    if (totalRows > totalRowsAccept) {
      handlePreviousStage();
      errorToast(t('listLoader.importMailing.msg-error-totalRows'));
    }
  }, [totalRows]);

  const sendNextStageImport = Boolean(!isImporting && previewList && file);

  useEffect(() => {
    if (sendNextStageImport) {
      renderLayoutNodes(layoutRender, useLayout);
    }
  }, [sendNextStageImport, dataSavedTemplate]);

  const controlNodes = {
    showNodesNull,
    setShowNodesNull,
    listShowPhones,
    setListShowPhones,
  };

  return (
    <ContainerImportMailing>
      {isLoadingData && <BackDropCircularLoading />}
      {isImporting && <ProgressUploadBar progress={progress} />}
      <MainImportMailing imported={file ? 1 : 0}>
        <div className='contentMain'>
          {!file && (
            <Stack width={'100%'} height={'100%'} spacing={4}>
              <PanelImportList
                useLayout={useLayout}
                setUseLayout={setUseLayout}
                mutateDataTemplate={mutateDataTemplate}
              />
              <FileAreaDrop
                hasLayoutSelected={useLayout && selectedLayout === '0'}
              />
            </Stack>
          )}

          {sendNextStageImport && (
            <ListManagement
              worker={worker}
              controlStage={{ handleNextStage, handlePreviousStage }}
              controlNodes={controlNodes}
              mutateDataTemplate={mutateDataTemplate}
            />
          )}
        </div>
      </MainImportMailing>
    </ContainerImportMailing>
  );
};
