import { apiData } from '../api';

export async function updateClient(dataCreate) {
  try {
    const { data } = await apiData.put(`clients/${dataCreate.id}`, dataCreate);
    return data;
  } catch (error) {
    throw new Error(error);
  }
}
