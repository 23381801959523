import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import styled from 'styled-components';

export const ContainerMenuCreate = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const SelectIconsInput = styled(SelectComponent)`
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 1rem !important;
  svg {
    display: none;
  }
`;

export const OptionsIcon = styled(ItemSelect)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  svg {
    color: ${(props) => props.theme.colors.primary} !important;
  }

  span {
    /* color: ${(props) => props.theme.colors.typography.baseText}; */
    font-size: 0.8rem;
  }
`;
