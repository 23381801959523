import { apiFlow } from '../api';

export async function setStatusPublish(dataStatus) {
  try {
    const { data } = await apiFlow.post(`/dialplan-publish/`, dataStatus);
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
