import { LinearProgress } from '@mui/material';
import { Stack } from '@mui/system';

export const ProgressUploadBar = ({ progress }) => {
  return (
    <Stack sx={{ paddingTop: '2rem' }}>
      <LinearProgress
        variant='buffer'
        value={progress}
        valueBuffer={progress + 5}
      />
    </Stack>
  );
};
