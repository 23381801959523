import { useLayoutEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { useAtom } from 'jotai';
import { useRenderNodesMailing } from './useRenderNodesMailing';
import { dataTemplateMailing, selectedTemplate } from '@/store/LoadList';
import useWindowDimensions from '@/hooks/useWidth';

export const useRenderPositionsNodes = (nodes, reactFlowMailingInstance) => {
  const { calculateBounds } = useRenderNodesMailing();
  const { height, width } = useWindowDimensions();
  const matches = useMediaQuery('(min-width:840px)');
  const [dataTemplates] = useAtom(dataTemplateMailing);
  const [layoutSelected] = useAtom(selectedTemplate);

  const bounds =
    nodes.length > 0
      ? calculateBounds(nodes)
      : { xMin: 0, xMax: 0, yMin: 0, yMax: 0 };

  useLayoutEffect(() => {
    if (reactFlowMailingInstance) {
      if (nodes.length > 0) {
        const bounds = calculateBounds(nodes);
        const centerX = (bounds.xMin + bounds.xMax) / 2;
        reactFlowMailingInstance.setCenter(centerX, matches ? 220 : 300, {
          zoom: matches ? 1 : 0.8,
        });
      }
    }
  }, [
    reactFlowMailingInstance,
    height,
    width,
    layoutSelected,
    dataTemplates,
    nodes,
    matches,
  ]);

  return { bounds };
};
