import { intervalToDuration, hoursToSeconds, minutesToSeconds } from 'date-fns';
import { useLocalStorage } from '@hooks/useLocalStorage';

export const useControlTimeAgent = () => {
  const { removeLocalStorage, saveLocalStorage, getLocalStorage } =
    useLocalStorage();

  const statusAgent = [
    'freeStatus',
    'pauseStatus',
    'availableStatus',
    'inCallStatus',
    'postCallStatus',
    'allStatus',
  ];

  const manegeStopWatch = (nameStatusCall) => {
    const timeLocalStorage = getLocalStorage(nameStatusCall);
    if (!timeLocalStorage) {
      const startTime = new Date();
      saveLocalStorage(nameStatusCall, startTime);
      return 0;
    } else {
      const timeNow = new Date();

      const interval = intervalToDuration({
        start: timeNow,
        end: new Date(timeLocalStorage),
      });

      const hourDiference = hoursToSeconds(interval.hours);
      const minutesDiference = minutesToSeconds(interval.minutes);
      const secondsDiference = interval.seconds;

      const diferenceSeconds =
        hourDiference + minutesDiference + secondsDiference;

      return diferenceSeconds;
    }
  };

  const manegeTimeToTab = (nameStatusCall) => {
    const timeLocalStorage = getLocalStorage(nameStatusCall);
    if (timeLocalStorage) {
      const timeNow = new Date();

      const interval = intervalToDuration({
        start: timeNow,
        end: new Date(timeLocalStorage),
      });

      const hourDiference = hoursToSeconds(interval.hours);
      const minutesDiference = minutesToSeconds(interval.minutes);
      const secondsDiference = interval.seconds;

      const diferenceSeconds =
        hourDiference + minutesDiference + secondsDiference;

      return diferenceSeconds;
    } else {
      return 0;
    }
  };

  const dataStatusAgent = (nameStatusCall) => {
    return statusAgent
      .filter((status) => status !== nameStatusCall)
      .map((statusMap) => removeLocalStorage(`@${statusMap}`));
  };

  const removeLocalStatus = (nameStatusCall) => {
    switch (nameStatusCall) {
      case 'freeStatus':
        dataStatusAgent('freeStatus');
        removeLocalStorage('@dc');
        break;
      case 'pauseStatus':
        removeLocalStorage('@dc');
        dataStatusAgent('pauseStatus');
        break;
      case 'availableStatus':
        removeLocalStorage('@dc');
        dataStatusAgent('availableStatus');
        break;
      case 'inCallStatus':
        dataStatusAgent('inCallStatus');
        break;
      case 'postCallStatus':
        dataStatusAgent('postCallStatus');
        break;
      default:
        dataStatusAgent('allStatus');
        removeLocalStorage('@dc');
        break;
    }
  };

  return {
    manegeStopWatch,
    removeLocalStatus,
    manegeTimeToTab,
  };
};
