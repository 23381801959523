import { Alert } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';

export const NoDataCharts = () => {
  const { t } = useTranslation();
  return (
    <Stack width={'100%'} justifyContent={'center'}>
      <Alert width={'100%'} severity='error'>
        {t('dashboardPage.messages.errorSelectQueue')}
      </Alert>
    </Stack>
  );
};
