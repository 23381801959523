import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { AgentMonitorGridContainer, Agent, Details } from './styled.js';

import { IoPerson, IoTime } from 'react-icons/io5';
import { MdSettingsPhone, MdPermPhoneMsg } from 'react-icons/md';
import { FaUserFriends } from 'react-icons/fa';
import {
  BsFillTelephoneOutboundFill,
  BsFillTelephoneInboundFill,
} from 'react-icons/bs';
import { AiFillMessage } from 'react-icons/ai';

export const AgentMonitorGrid = ({ itemAgent }) => {
  const { t } = useTranslation();

  const getStatus = (status) => {
    switch (status) {
      case 'EMLIG':
        return '#4c48ff';
      case 'DISCANDO':
        return '#0edd56';
      case 'LIVRE':
        return '#FF4C43';
      case 'EMPAUSA':
        return '#FFb839';
      default:
        return '';
    }
  };

  return (
    <AgentMonitorGridContainer borderStatus={getStatus(itemAgent.estado)}>
      <Agent borderStatus={getStatus(itemAgent.estado)}>
        <AiFillMessage />
        <Stack direction={'row'} justifyContent={'space-between'} width={'98%'}>
          <span>{itemAgent.agente ?? '-'}</span>
          <span>{itemAgent.lista ?? '-'}</span>
        </Stack>
      </Agent>

      <div className='separator' />

      <Details borderStatus={getStatus(itemAgent.estado)}>
        <div>
          <MdSettingsPhone />
          <span>{itemAgent.linha ?? '-'}</span>
        </div>
        <div>
          <MdPermPhoneMsg />
          <span>{itemAgent.ramal ?? '-'}</span>
        </div>
        <div>
          <IoTime />
          <span>{itemAgent.horaInicial ?? '-'}</span>
        </div>
        <div>
          <IoPerson />
          <span>
            {itemAgent.estado === 'EMLIG' && t('monitor.option-inCall')}
            {itemAgent.estado === 'DISCANDO' && t('monitor.option-discing')}
            {itemAgent.estado === 'LIVRE' && t('monitor.option-free')}
            {itemAgent.estado === 'EMPAUSA' && t('monitor.option-inPause')}
          </span>
        </div>
        <div>
          <FaUserFriends />
          <span>{itemAgent.fila ?? '-'}</span>
        </div>
        <div>
          {itemAgent.typeCall === 'PRED' ? (
            <BsFillTelephoneOutboundFill />
          ) : (
            <BsFillTelephoneInboundFill />
          )}
          <span>
            {itemAgent.lig_tipo === 'PRED' || itemAgent.lig_tipo === 'pred'
              ? 'PRED'
              : 'RCPT'}
          </span>
        </div>
        <div></div>
      </Details>
    </AgentMonitorGridContainer>
  );
};
