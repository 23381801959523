import styled from 'styled-components';

export const ContainerTable = styled.div`
  width: 100%;
  position: relative;
  flex: 1;
  max-height: calc(100vh - 330px);
  min-height: 200px;
  overflow-x: auto;

  .showScreensBtn {
    button {
      all: unset;
      cursor: pointer;
      color: ${(props) => props.theme.colors.primary};

      &:disabled {
        opacity: 0.6;
      }

      &:hover {
        text-decoration: underline;
        user-select: none;
      }
    }
  }

  table {
    tbody {
      tr {
        height: 44px;

        td {
          z-index: 1;
          padding: 0 0.8rem;
          min-width: 140px;
          &:nth-child(1) {
            min-width: 10px;
          }
          &:nth-child(4) {
            min-width: 160px;
          }
          &:nth-child(5) {
            min-width: 160px;
          }
          /* &:nth-child(6) {
            span {
              font-size: 0.75rem !important;
            }
          } */

          &:last-child {
            min-width: 10px;
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    margin-top: 0.675rem;
    max-height: calc(100vh - 300px);
    table tbody,
    table tr,
    table td {
      display: block;
      width: 100%;
    }

    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          margin-bottom: 12px;
          width: 100%;
          min-width: 100%;
          height: auto;
          padding: 1rem 0;
          border-left: 3px solid ${(props) => props.theme.colors.primary};

          td {
            padding-left: 50%;
            text-align: left;
            position: relative;
            padding-bottom: 6px;

            &:nth-child(2) {
              min-width: 100%;
              overflow: none;
              text-overflow: initial;
            }

            span {
              font-size: 12px;
            }
            &::before {
              content: attr(data-label) ':';
              position: absolute;
              left: 0;
              width: 50%;
              padding-left: 15px;
              text-align: left;
              font-size: 12px;
              color: ${(props) => props.theme.colors.typography.baseText};
              line-height: 1.2;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;

export const ContainerTableSecurity = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  min-width: 140px;
  padding: 2px 0;
`;

export const ItemSecurityRow = styled.span`
  background-color: ${(props) => props.theme.colors.containers.baseBackground};
  font-size: 0.68rem !important;
  padding: 4px;
  border-radius: 4px;
`;
