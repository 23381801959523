import { useAtom } from 'jotai';
import { motion } from 'framer-motion';
import { currentPageId } from '@store/NavigationDashboard';
import { DropMenuItem } from './DropMenuItem';
import { ItemMenu } from './ItemMenu';
import { SectionItems } from './styled';
import { useSecurityProfileRender } from '@/hooks/Navigator/useSecurityProfileRender';

export const SectionItemNavigation = ({
  screenSchema,
  stateItemSidebar,
  sidebarIsOpem,
  setScreenSchema,
  sectionMenuArea,
}) => {
  const [currentPage] = useAtom(currentPageId);
  const { permissionStatus } = useSecurityProfileRender();

  const renderMenuAreaItems = (() => {
    const dataScreens = !!screenSchema && screenSchema;

    switch (sectionMenuArea) {
      case 'products':
        return (
          dataScreens &&
          dataScreens
            .filter((item) => item.area === 'Products')
            .sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence))
        );

      case 'administrator':
        return (
          dataScreens &&
          dataScreens
            .filter((item) => item.area === 'Administrator')
            .sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence))
        );

      default:
        return dataScreens;
    }
  })();

  const openMenuDash = (idItem, isOpen) => {
    const renderCloseMenuItems = screenSchema.map((item) => {
      if (item.isOpenMenu) {
        return {
          ...item,
          isOpenMenu: false,
        };
      }
      return item;
    });

    const selected = renderCloseMenuItems.map((item) => {
      if (item.id === idItem) {
        if (isOpen) {
          return {
            ...item,
            isOpenMenu: false,
          };
        }
        return {
          ...item,
          isOpenMenu: true,
        };
      }
      return item;
    });
    setScreenSchema(selected);
  };

  const generateItems = (screens) => {
    return screens.map((item, i) => {
      const hasPermissions =
        permissionStatus(item).includes('all') ||
        permissionStatus(item).includes('display');

      if (item.subItem && item.subItem.length > 0) {
        return (
          <motion.li key={item.id}>
            <DropMenuItem itemMenuObj={item} openMenuDash={openMenuDash} />
          </motion.li>
        );
      }

      if (hasPermissions) {
        return (
          <li key={i} className={currentPage === item.id ? 'activeItem' : ''}>
            <ItemMenu
              itemId={item.id}
              itemIcon={item.icon}
              itemName={item.name}
              itemAlias={item.alias}
              haveSubItem={item.haveSubItem}
              link={item.link}
              hasExternalUrl={item.hasExternalUrl}
            />
          </li>
        );
      }
    });
  };

  return (
    <SectionItems
      initial={{ opacity: 0 }}
      animate={{ x: `${stateItemSidebar}px`, opacity: sidebarIsOpem }}
    >
      <ul>{screenSchema && generateItems(renderMenuAreaItems)}</ul>
    </SectionItems>
  );
};
