import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Stack } from '@mui/system';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { FaRegCircleDot } from 'react-icons/fa6';
import { MdSwapHorizontalCircle } from 'react-icons/md';
import AnimatedNumber from 'animated-number-react';
import { useTranslation } from 'react-i18next';
import { ImageComponent } from '@/components/layout/ImageComponent';
import LogoSidebarImg from '@/assets/imgs/sm_voiccelabs_black.webp';
import LogoWhiteSidebarImg from '@/assets/imgs/sm_voiccelabs_white.webp';
import { ContainerHeaderListFlow, DetailsList, ImgHeaderList } from './styled';

export const HeaderListFlow = ({ listTemplates }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [dataDetails, setDataDetails] = useState([]);

  const dataCount = {
    total: listTemplates.length,
    published: listTemplates.filter((item) => item.published).length,
    notPublished: listTemplates.filter((item) => !item.published).length,
  };

  useEffect(() => {
    setDataDetails([
      {
        id: '2f65218c-0dd3-471f-98af-29faf2c66129',
        name: t('flowBuilder.recentFlow.header.label-total'),
        value: dataCount.total,
        icon: <MdSwapHorizontalCircle className='color-total' />,
      },
      {
        id: 'b17f99a3-30c5-4ede-82d8-7b63ae508db1',
        name: t('flowBuilder.recentFlow.header.label-published'),
        value: dataCount.published,
        icon: <IoIosCheckmarkCircle className='color-public ' />,
      },
      {
        id: 'd96415c3-aeac-48d6-8d45-6896e82b3bff',
        name: t('flowBuilder.recentFlow.header.label-unpublished'),
        value: dataCount.notPublished,
        icon: <FaRegCircleDot className='color-not-public' size={14} />,
      },
    ]);
  }, [listTemplates]);

  const formatValue = (value) => (value ? value.toFixed(0) : 0);

  const logoToPreload =
    theme.title === 'Light' ? LogoSidebarImg : LogoWhiteSidebarImg;

  return (
    <ContainerHeaderListFlow>
      <DetailsList>
        <Stack direction={'row'} width={'100%'}>
          {dataDetails.length > 0 &&
            dataDetails.map((itemDetails) => (
              <div className='item-detail-header' key={itemDetails.id}>
                <span>{itemDetails.name}</span>
                <Stack
                  direction={'row'}
                  width={'100%'}
                  paddingLeft={'10px'}
                  alignItems={'center'}
                  spacing={'4px'}
                >
                  {itemDetails.icon}
                  <span>
                    <strong>
                      <AnimatedNumber
                        value={itemDetails.value}
                        formatValue={formatValue}
                        duration={400}
                      />
                    </strong>
                  </span>
                </Stack>
              </div>
            ))}
        </Stack>
      </DetailsList>
      <ImgHeaderList>
        <ImageComponent
          src={logoToPreload}
          width='180'
          alt='Logo Voicce Labs'
          loading='eager'
        />
      </ImgHeaderList>
    </ContainerHeaderListFlow>
  );
};
