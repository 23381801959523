import { Grid, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineWarning } from 'react-icons/ai';
import { Stack } from '@mui/system';
import { IoAddSharp } from 'react-icons/io5';
import { useAtom } from 'jotai';
import { motion } from 'framer-motion';
import { ContainerLatestList, ContainerNoData } from './styled';
import { useHandleFlow } from '@/hooks/FlowBuilder/useHandlerFlow';
import { CardFlow } from './CardFlow';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { errorToast } from '@/components/FormComponents/Toast';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { hiddenSidebar } from '@/store/NavigationDashboard';
import { usePermissionsFlow } from '@/components/pages/FlowBuilder/hooks/usePermissionsFlow';
import { useClients } from '@/hooks/Clients/useClients';
import { dataListClients } from '@/store/Clients';
import { adminClientIdSelected, listTemplates } from '@/store/FlowBuilder';
import { useTrunks } from '@/hooks/FlowBuilder/useTrunks';
import { HeaderListFlow } from './HeaderListFlow';
import { useDispositions } from '@/hooks/FlowBuilder/useDispositions';
import { AutoComplete } from '@/components/FormComponents/AutoCompleteSelect';
import { user } from '@/store/AuthenticatorStore';

export const LatestFlow = ({ changeStageFlow, changeStageTypeSelectFlow }) => {
  const { t } = useTranslation();
  const [currentUser] = useAtom(user);
  const { isLoading: isLoadingTrunks } = useTrunks();
  const { loadingDispositions } = useDispositions();
  const { permissionsCreate, isSysAdmin } = usePermissionsFlow();
  const [listClients] = useAtom(dataListClients);
  const [listTemplate] = useAtom(listTemplates);
  const [clientSelected, setClientSelected] = useAtom(adminClientIdSelected);

  const [fieldClientSelect, setFieldClientSelect] = useState(null);

  const { isLoadingDataClients } = useClients(isSysAdmin);

  const {
    mutateImportFlow,
    loadingFlowTemplate,
    loadingImportFlow,
    errorList,
    mutateGetTemplatesByClientId,
    loadingGetTemplatesByClientId,
  } = useHandleFlow();

  const [, setIsVisibleSidebar] = useAtom(hiddenSidebar);

  useEffect(() => {
    if (clientSelected !== '0') {
      mutateGetTemplatesByClientId(clientSelected);
    }
  }, [clientSelected, fieldClientSelect]);

  useEffect(() => {
    !loadingFlowTemplate &&
      errorList &&
      errorToast(t('flowBuilder.recentFlow.msg-error-list'));
  }, [loadingFlowTemplate, errorList]);

  const handleTemplate = (idTemplate) => {
    mutateImportFlow(idTemplate, {
      onSuccess: () => {
        changeStageFlow(1);
        setIsVisibleSidebar(false);
      },
    });
  };

  const handleChangeClient = (dataClient) => {
    if (!dataClient) {
      const filterClientDefault = listClients.filter(
        (client) => client.id === currentUser.client_id
      )[0];
      setFieldClientSelect(filterClientDefault.commercial_name);
      setClientSelected(filterClientDefault.id);
      return;
    }

    const filterClient = listClients.filter(
      (client) => client.commercial_name === dataClient
    )[0];

    if (filterClient) {
      setFieldClientSelect(filterClient.commercial_name);
      setClientSelected(filterClient.id);
      return;
    }
  };

  let singleItemsList = listTemplate.reduce((accumulator, currentItem) => {
    const existingItem = accumulator.find(
      (item) => item.nameIVR === currentItem.nameIVR
    );
    if (!existingItem) {
      accumulator.push(currentItem);
    } else if (!existingItem.published && currentItem.published) {
      accumulator[accumulator.indexOf(existingItem)] = currentItem;
    }
    return accumulator;
  }, []);

  const handleNewFlow = () => {
    changeStageTypeSelectFlow('newFlow');
  };

  const renderOptionsClients = listClients
    .map((client) => client.commercial_name)
    .sort();

  const isLoading =
    loadingFlowTemplate ||
    loadingImportFlow ||
    isLoadingDataClients ||
    loadingGetTemplatesByClientId ||
    isLoadingTrunks ||
    loadingDispositions;

  return (
    <ContainerLatestList initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      {isSysAdmin ? (
        <AutoComplete
          label={t('flowBuilder.recentFlow.label-filter-flow')}
          options={renderOptionsClients}
          onChange={(_, newValue) => handleChangeClient(newValue)}
          value={fieldClientSelect}
        />
      ) : (
        <HeaderListFlow listTemplates={singleItemsList} />
      )}

      {(isSysAdmin || permissionsCreate) && (
        <Stack justifyContent={'flex-end'} alignItems={'flex-end'}>
          <Button onClick={() => handleNewFlow()} className='btn-new-flow'>
            <Stack
              direction={'row'}
              gap={'06px'}
              justifyContent={'center'}
              alignItems={'center'}
              padding={'4px'}
            >
              <IoAddSharp size={16} />
              <span style={{ lineHeight: 0 }}>
                {t('flowBuilder.newFlow.title')}
              </span>
            </Stack>
          </Button>
        </Stack>
      )}

      {loadingFlowTemplate && (
        <>
          <Grid container spacing={2}>
            {Array(9)
              .fill(undefined)
              .map((_, i) => (
                <Grid key={i} item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Skeleton variant='rectangular' width={'100%'} height={76} />
                </Grid>
              ))}
          </Grid>
          <BackDropCircularLoading />
        </>
      )}
      {listTemplate.length > 0 ? (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <Grid container spacing={2}>
            {singleItemsList.length === 1
              ? singleItemsList.map((card, index) => (
                  <Grid item key={index} xs={12} md={12} lg={12} xl={6}>
                    <CardFlow dataCard={card} handleTemplate={handleTemplate} />
                  </Grid>
                ))
              : singleItemsList.map((card, index) => (
                  <Grid item key={index} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <CardFlow dataCard={card} handleTemplate={handleTemplate} />
                  </Grid>
                ))}
          </Grid>
        </motion.div>
      ) : (
        !loadingFlowTemplate && (
          <ContainerNoData
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: -10 }}
          >
            <AiOutlineWarning />
            <span>{t('flowBuilder.recentFlow.msg-no-dataFlow')}</span>
          </ContainerNoData>
        )
      )}
    </ContainerLatestList>
  );
};
