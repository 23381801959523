import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { StyledColumn, StyledList } from './styledItems';
import { Item } from './Item';
import { checkDisabled } from '../../utils/checkDisabledItem';

export const Column = ({
  col: { list, id },
  handleToSelectedList,
  disabledList = [],
  typeList,
}) => {
  const { t } = useTranslation();

  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <StyledColumn className={id}>
          <h2>
            {t(
              `dialControl.schedulesList.createSchedule.transferDetails.label-${id}`
            )}
            : {list.length}
          </h2>
          <StyledList {...provided.droppableProps} ref={provided.innerRef}>
            {list.map((text, index) => (
              <Item
                key={text.name}
                text={text.name}
                dataItem={text}
                index={index}
                colSelected={id}
                handleToSelectedList={handleToSelectedList}
                isDisabled={checkDisabled(disabledList, text.name)}
                typeList={typeList}
              />
            ))}
            {provided.placeholder}
          </StyledList>
        </StyledColumn>
      )}
    </Droppable>
  );
};
