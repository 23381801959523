import { apiData } from '../api';

export async function setBreaks(dataStateCall) {
  const { extension, queue, status } = dataStateCall;

  try {
    const { data } = await apiData.post('/agent/setBreaks', {
      extension,
      queue,
      status: status,
    });
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
