import { useEffect, useId, useState } from 'react';
import { useAtom } from 'jotai';

import { ContainerLineControl } from './styled';
import { ListCampaigns } from './stages/ListCampaigns';
import { SelectedLine } from './stages/SelectedLine';
import { dataQueues } from '@/store/Queues';

export const LineControl = () => {
  const [currentStage, setCurrentStage] = useState(0);
  const [, setListDataQueues] = useAtom(dataQueues);

  useEffect(() => {
    return () => setListDataQueues([]);
  }, []);

  const controlStage = {
    previousStage: () => setCurrentStage((currentStage) => currentStage - 1),
    nextStage: () => setCurrentStage((currentStage) => currentStage + 1),
    toStage: (stage) => setCurrentStage(stage),
  };

  const flowLineControl = [
    <ListCampaigns key={useId()} controlStage={controlStage} />,
    <SelectedLine key={useId()} controlStage={controlStage} />,
  ];

  return (
    <ContainerLineControl>{flowLineControl[currentStage]}</ContainerLineControl>
  );
};
