import styled from 'styled-components';

export const DetailTotalPhoneCode = styled.div`
  user-select: none;
  width: 100%;
  height: 100%;
  max-height: 500px;
  overflow: auto;
  table {
    width: 100%;
    thead {
      tr {
        th {
          &:last-child {
            height: 36px;
            width: 140px;
            div {
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          text-transform: uppercase;
        }
      }
    }
  }
`;
