import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerPanelActive = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
`;

export const FormActiveRecords = styled.form`
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr 34px;
  gap: 2rem;
`;

export const FooterPanel = styled.footer`
  display: flex;
  justify-content: flex-end;
`;
