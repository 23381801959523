import { useId, useState } from 'react';
import { PanelSearchActiveRecordings } from './stages/PanelSearchRecordings';
import { ListActiveRecording } from './stages/ListActiveRecording';
import { ContainerRecordingActive } from './styled';

export const ActiveRecording = () => {
  const [currentFlow, setCurrentFlow] = useState(0);

  const controlStage = {
    toPanel: () => setCurrentFlow(0),
    toList: () => setCurrentFlow(1),
  };

  const flowActiveRecording = [
    <PanelSearchActiveRecordings key={useId()} controlStage={controlStage} />,
    <ListActiveRecording key={useId()} controlStage={controlStage} />,
  ];

  return (
    <ContainerRecordingActive>
      {flowActiveRecording[currentFlow]}
    </ContainerRecordingActive>
  );
};
