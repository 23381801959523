import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { ChartQueueMonitor, ContainerDetailQueueMonitor } from './styled';
import { ChartStatus } from '../ChartStatus';
import { selectedQueueMonitor } from '@/store/QueueMonitor';
import { CardHeaderQueues } from './card/CardHeaderQueues';
import { CardAllQueues } from './card/CardAllQueues';
import { CardSelectedQueues } from './card/CardSelectedQueues';

export const DetailQueues = ({ dataList }) => {
  const [selectedQueue] = useAtom(selectedQueueMonitor);
  const [data, setData] = useState([]);
  const [details, setDetails] = useState({
    dispositions: 0,
    campaigns: 0,
    lists: 0,
    operators: 0,
    total: 0,
  });

  const renderValues = (data, typeCount) => {
    const total = data
      .map((item) => item[typeCount])
      .reduce((acc, row) => acc + row, 0);
    return total;
  };

  const renderTotal = (dataList, typeCount = '') => {
    if (typeCount === 'dispositions') {
      const totalDispositions = dataList
        .map((item) => item.titulo.replace('CODLIG USADOS: ', '').trim().length)
        .reduce((acc, row) => acc + row, 0);
      return totalDispositions;
    }
    const total = dataList.map((item) => item[typeCount]);
    const withoutDuplicates = [...new Set(total)];
    return withoutDuplicates.length;
  };

  useEffect(() => {
    if (dataList) {
      const valueDialing = renderValues(dataList, 'discando');
      const valueTalking =
        valueDialing >= 10
          ? renderValues(dataList, 'falando_ura') * 10
          : renderValues(dataList, 'falando_ura');

      setDetails({
        dispositions: renderTotal(dataList, 'dispositions'),
        campaigns: renderTotal(dataList, 'campanha_descricao'),
        lists: renderTotal(dataList, 'lista'),
        operators: renderTotal(dataList, 'operadora'),
        total:
          renderValues(dataList, 'discando') +
          renderValues(dataList, 'falando_ura'),
      });

      if (!selectedQueue) {
        return setData([
          {
            data: [valueDialing, valueTalking],
          },
        ]);
      }

      const filterSelected = dataList.filter(
        (item) => item.fila === selectedQueue.fila
      );

      if (filterSelected.length > 0) {
        const dataTalking =
          filterSelected[0].discando >= 10
            ? filterSelected[0].falando_ura * 10
            : filterSelected[0].falando_ura;

        return setData([
          {
            data: [filterSelected[0].discando, dataTalking],
          },
        ]);
      }

      setData([
        {
          data: [0, 0],
        },
      ]);
    }
  }, [dataList]);

  return (
    <ContainerDetailQueueMonitor>
      <ChartQueueMonitor className='area-detail'>
        <CardHeaderQueues />
        {!selectedQueue ? (
          <CardAllQueues details={details} />
        ) : (
          <CardSelectedQueues dataList={dataList} />
        )}
      </ChartQueueMonitor>
      <ChartQueueMonitor className='area-chart'>
        <CardHeaderQueues />
        <ChartStatus data={data} height={100} />
      </ChartQueueMonitor>
    </ContainerDetailQueueMonitor>
  );
};
