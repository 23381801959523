import { apiData } from '@/services/api';

export async function deleteRenitency(dataDelete) {
  try {
    const { data } = await apiData.delete(
      `dialingRules/deleteRulesRenitency/${dataDelete}`
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
