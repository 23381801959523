import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerAddTenant = styled(motion.div)`
  width: 100%;
  height: auto;
  overflow-y: auto;
  padding: 2.5rem 2rem 1rem 2rem;
`;

export const FormAddTenant = styled.form`
  width: 100%;
  height: calc(100vh - 130px);
  display: grid;
  grid-template-rows: auto 1fr 30px;
  gap: 1rem;

  .mainFormTenants {
    padding: 3rem 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
  }

  .footerAddNewTenant {
    width: 100%;
    height: 100%;
    min-height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.4rem;

    @media (max-height: 768px) {
      padding-bottom: 10px;
    }
  }
`;
