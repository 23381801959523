import { apiData } from '../api';

export async function createListMailing(dataListCreate) {
  try {
    const { data } = await apiData.post('/dialer/importLists', dataListCreate, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
