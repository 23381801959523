import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { createFlow } from '@/services/flowBuilder/createFlow';
import { getFlowTemplates } from '@/services/flowBuilder/getFlowTemplates';
import { getFlowTemplatesById } from '@/services/flowBuilder/getFlowTemplatesById';
import {
  adminClientIdSelected,
  listTemplates,
  stageFlow,
  templateSelected,
} from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { setStatusPublish } from '@/services/flowBuilder/setStatusPublish';
import { sysAdmin, user } from '@/store/AuthenticatorStore';
import { getFlowTemplatesByClientId } from '@/services/flowBuilder/getFlowTemplatesByClientId';

export const useHandleFlow = () => {
  const { t } = useTranslation();
  const [, setTemplate] = useAtom(templateSelected);
  const [, setListTemplate] = useAtom(listTemplates);
  const [currentStage] = useAtom(stageFlow);
  const [currentUser] = useAtom(user);
  const [isSysAdmin] = useAtom(sysAdmin);

  const [clientSelected] = useAtom(adminClientIdSelected);

  const { mutate: mutateHandleFlow, isLoading: loadingHandleFlow } =
    useMutation({
      mutationFn: async (dataCreateFlow) => {
        return await createFlow(dataCreateFlow);
      },

      onError: () => {
        errorToast(t('flowBuilder.flow.msg-error-save-flow'));
      },
    });

  const { mutate: mutateImportFlow, isLoading: loadingImportFlow } =
    useMutation({
      mutationFn: async (dataCreateFlow) => {
        return await getFlowTemplatesById(dataCreateFlow);
      },
      onSuccess: (data) => setTemplate(data),
      onError: () => {
        errorToast(t('flowBuilder.recentFlow.msg-error-select'));
      },
    });

  const {
    isLoading: loadingFlowTemplate,
    error: errorList,
    refetch,
  } = useQuery(
    'dataFlowTemplate',
    async ({ signal }) => {
      const currentUserSearch =
        clientSelected !== '0' && isSysAdmin
          ? clientSelected
          : currentUser.client_id;
      return await getFlowTemplates(signal, currentUserSearch);
    },
    {
      enabled: currentStage === 0,
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setListTemplate(data),
    }
  );

  const {
    mutate: mutateGetTemplatesByClientId,
    isLoading: loadingGetTemplatesByClientId,
  } = useMutation({
    mutationFn: async (dataClientId) => {
      return await getFlowTemplatesByClientId(dataClientId);
    },
    onSuccess: (data) => setListTemplate(data),
    onError: () => {
      errorToast(t('flowBuilder.recentFlow.msg-error-select'));
    },
  });

  const { mutate: mutateSetStatusTemplate, isLoading: loadingSetStatus } =
    useMutation({
      mutationFn: async (dataStatus) => {
        return await setStatusPublish(dataStatus);
      },
      onSuccess: () => refetch(),
      onError: () => {
        errorToast(t('flowBuilder.recentFlow.msg-error-select'));
      },
    });

  return {
    loadingFlowTemplate,
    mutateImportFlow,
    loadingImportFlow,
    mutateHandleFlow,
    loadingHandleFlow,
    mutateSetStatusTemplate,
    loadingSetStatus,
    refetch,
    errorList,
    loadingGetTemplatesByClientId,
    mutateGetTemplatesByClientId,
  };
};
