import styled, { css } from 'styled-components';
import { ContainerNodeWrapper, ContentNodeWrapper } from '../styled';

export const NodeConditionContainer = styled(ContainerNodeWrapper)`
  position: relative;
  height: ${(props) => props.heighNode};
  background: ${(props) => props.theme.colors.containers.colorSecondNode};

  .react-flow__handle {
    &::after {
      background: #ef2a64;
    }
  }
`;

export const NodeContent = styled(ContentNodeWrapper)`
  width: 220px;

  .header {
    background: #ef2a64;
  }
`;

export const SubNodeLoopContainer = styled.div`
  padding: 5px;
  width: 220px;
  border-radius: 2px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  ${(props) =>
    props.typeHandler === 'loopingVoicce' &&
    css`
      background: #ef2a64;
    `}

  ${(props) =>
    props.typeHandler === 'completeVoicce' &&
    css`
      background: #5794ff;
    `} 


  .react-flow__handle {
    background: #ef2a64;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    border: 2px solid ${(props) => props.theme.colors.containers.colorCard};
    right: 4px;

    ${(props) =>
      props.typeHandler === 'loopingVoicce' &&
      css`
        background: #ef2a64;
      `}

    ${(props) =>
      props.typeHandler === 'completeVoicce' &&
      css`
        background: #5794ff;
      `}
  }
  .headerNode {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    font-size: 0.6rem;

    span {
      font-size: 0.66rem;
      padding-top: 2px;
      font-weight: 600;
      text-transform: uppercase;
      color: #ffff;
    }
  }
`;
