import { Stack } from '@mui/system';
import { FaGear } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { useAtom } from 'jotai';
import { BiTransferAlt } from 'react-icons/bi';
import { FiTrash2 } from 'react-icons/fi';
import { TbVariableMinus } from 'react-icons/tb';
import useFormControl from '@/hooks/useFormControl';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { variablesGlobal } from '@/store/FlowBuilder';
import {
  ContentConfig,
  FooterConfig,
} from '../../../FlowBuilderComponent/NavigationFlow/ConfigCurrentFlow/styled';

import {
  ButtonRemoveVar,
  DividerConfig,
  FormAddGlobalVars,
  ListGlobalVars,
  MainManagerGlobalVars,
} from './styled';

export const ManagementGlobalVariables = ({ controlStage }) => {
  const { t } = useTranslation();
  const [globalVars, setGlobalVars] = useAtom(variablesGlobal);

  const globalVarsSchema = yup.object().shape({
    nameGlobalVar: yup
      .string()
      .required(t('flowBuilder.configs.globalVars.msg-requiredVar'))
      .matches(
        /^[\w]+$/,
        t('flowBuilder.configs.globalVars.msg-globalVars-chars')
      )
      .test(
        'unique-name-var',
        t('flowBuilder.configs.globalVars.msg-globalVars-exists'),
        function (value) {
          const isDuplicate = globalVars.some(
            (globalVar) =>
              globalVar.value.toLowerCase() === value?.toLowerCase()
          );
          return !isDuplicate;
        }
      ),
  });

  const { handleSubmit, control, reset, errors } =
    useFormControl(globalVarsSchema);

  const handlerSubmitNewGLobalVar = (data) => {
    const dataCreate = {
      id: uuid(),
      name: data.nameGlobalVar,
      value: data.nameGlobalVar,
      parent: 'global-var',
      referNode: false,
    };
    setGlobalVars((previousGlobalVars) => [dataCreate, ...previousGlobalVars]);
    reset();
  };

  const handleRemoveGlobalVar = (dataVar) => {
    const filterGlobalVar = globalVars.filter(
      (globalVar) => globalVar.value !== dataVar.value
    );
    return setGlobalVars(filterGlobalVar);
  };

  return (
    <ContentConfig>
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4> {t('flowBuilder.configs.globalVars.btn-manage-globalVars')}</h4>
      </Stack>

      <MainManagerGlobalVars initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <FormAddGlobalVars onSubmit={handleSubmit(handlerSubmitNewGLobalVar)}>
          <ControlInput
            label={t('flowBuilder.configs.globalVars.label-name-globalVars')}
            control={control}
            nameControl={'nameGlobalVar'}
            error={Boolean(errors.nameGlobalVar)}
            helperText={
              errors.nameGlobalVar ? errors.nameGlobalVar.message : ' '
            }
          />
          <Stack
            width={'100%'}
            justifyContent={'flex-end'}
            alignItems={'flex-end'}
          >
            <Button typeText type='submit'>
              {t('flowBuilder.configs.btn-add')}
            </Button>
          </Stack>
        </FormAddGlobalVars>
        <DividerConfig />
        <ListGlobalVars>
          <ul>
            {globalVars.length > 0 ? (
              globalVars.map((globalVar, i) => {
                return (
                  <li key={i}>
                    <Stack direction={'row'} spacing={1}>
                      <TbVariableMinus />
                      <span>{globalVar.value}</span>
                    </Stack>
                    <ButtonRemoveVar
                      onClick={() => handleRemoveGlobalVar(globalVar)}
                      whileTap={{ scale: 0.95 }}
                    >
                      <FiTrash2 />
                    </ButtonRemoveVar>
                  </li>
                );
              })
            ) : (
              <li>
                <Stack direction={'row'} spacing={1}>
                  <BiTransferAlt />
                  <span>
                    {t('flowBuilder.configs.globalVars.label-noDataGlobalVars')}
                  </span>
                </Stack>
              </li>
            )}
          </ul>
        </ListGlobalVars>
      </MainManagerGlobalVars>

      <FooterConfig>
        <div className=''></div>
        <Stack direction={'row'} spacing={1}>
          <Button typeText variant='secondary' onClick={controlStage}>
            {t('flowBuilder.configs.btn-back')}
          </Button>
        </Stack>
      </FooterConfig>
    </ContentConfig>
  );
};
