import { ContainerPanelCallEfforts } from './styled';
import { motion } from 'framer-motion';
import { Stack } from '@mui/system';
import { FaArrowLeft } from 'react-icons/fa';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { progressDownload } from '@/store/workersStore';
import CircularWithValueLabel from '@/components/FormComponents/CircularWithValueLabel';
import { CheckedAnimation } from '@/components/layout/CheckedAnimation';
// import { MdOutlineDateRange } from 'react-icons/md';
// import { formatDate } from '@/utils/formatsDateAndTime';

export const PanelCallingEfforts = ({ handleBack, dataCallingEfforts }) => {
  const handleBackStage = () => {
    handleBack();
  };

  const [progress, setProgress] = useAtom(progressDownload);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => setProgress(0), 1000);
    }
  }, [progress]);

  const variantsChecked = {
    show: { opacity: 1, display: 'block' },
    hidden: {
      opacity: 0,
      transition: { delay: 0 },
      transitionEnd: {
        display: 'none',
      },
    },
  };

  //   const replacedDate = (dateRender) => {
  //     if (dateRender) {
  //       const [day, month, year] = dateRender.split('-');
  //       const dateObject = new Date(year, month - 1, day);
  //       return formatDate(dateObject);
  //     }
  //     return formatDate(new Date());
  //   };

  const renderTitlePage = dataCallingEfforts.campaignSearch
    ? `${dataCallingEfforts.campaignSearch}`
    : `${dataCallingEfforts.listSearch} `;

  return (
    <ContainerPanelCallEfforts>
      <Stack direction='column' spacing={2} width={'100%'}>
        <Stack
          direction='row'
          spacing={2}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Stack direction='row' spacing={2}>
            <motion.div
              whileTap={{ scale: 0.8 }}
              className='icon_handle_back'
              onClick={handleBackStage}
            >
              <FaArrowLeft onClick={() => handleBackStage(0)} size='16' />
            </motion.div>
            <div className='itemPanel'>
              <span>
                <strong>{renderTitlePage}</strong>
              </span>
            </div>
          </Stack>
          <Stack direction='row' spacing={2}>
            {progress > 0 && progress < 100 && (
              <CircularWithValueLabel value={progress} />
            )}

            <motion.div
              variants={variantsChecked}
              initial={'hidden'}
              animate={progress === 100 ? 'show' : 'hidden'}
            >
              <CheckedAnimation />
            </motion.div>
            {/* {dataCallingEfforts.data_inicio === dataCallingEfforts.data_fim ? (
              <div className='date-panel-item'>
                <MdOutlineDateRange />
                <span>{replacedDate(dataCallingEfforts.data_inicio)}</span>
              </div>
            ) : (
              <>
                <div className='date-panel-item'>
                  <MdOutlineDateRange />
                  <span>{replacedDate(dataCallingEfforts.data_inicio)}</span>
                </div>
                <span>-</span>
                <div className='date-panel-item'>
                  <MdOutlineDateRange />
                  <span>{replacedDate(dataCallingEfforts.data_fim)}</span>
                </div>
              </>
            )} */}
          </Stack>
        </Stack>
      </Stack>
    </ContainerPanelCallEfforts>
  );
};
