import { Button } from '@/components/FormComponents/ButtonComponent';
import { MdArrowBack } from 'react-icons/md';
import { ContainerControlList } from './styledItems';

export const ControlList = ({ controls, listSelected }) => {
  return (
    <ContainerControlList>
      <Button
        onClick={controls.handleUnselectAllValues}
        disabled={listSelected && listSelected.length === 0}
      >
        <MdArrowBack size={20} />
      </Button>
    </ContainerControlList>
  );
};
