import { useTranslation } from 'react-i18next';
import { ContainerNoData } from './styled';
import { IoWarningOutline } from 'react-icons/io5';
import { usePermissionsTenants } from '../../hooks/usePermissionsTenants';

export const NoDataTenants = ({ handleStage, typeData = 'tenants' }) => {
  const { t } = useTranslation();
  const { permissionsCreate, isSysAdmin } = usePermissionsTenants();

  return (
    <ContainerNoData
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className='detail-content'>
        <IoWarningOutline />
        {typeData === 'tenants' ? (
          <span>{t('tenants.no-data-tenants.label-tenants')}</span>
        ) : (
          <span>{t('tenants.no-data-tenants.label-matchTenants')}</span>
        )}
      </div>
      {isSysAdmin && permissionsCreate && (
        <div className='btn-add-scheduler'>
          <button onClick={handleStage}>
            <span>{t('tenants.no-data-tenants.btn-click')}</span>
          </button>
          <span>
            ,{' '}
            {typeData === 'tenants'
              ? t('tenants.no-data-tenants.spell-tenants')
              : t('tenants.no-data-tenants.spell-matchTenants')}
          </span>
        </div>
      )}
    </ContainerNoData>
  );
};
