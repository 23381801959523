import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerPanelListActive = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
`;

export const TitlePanelActiveRecords = styled(motion.div)`
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  .details-panel {
    display: flex;
    gap: 1rem;
    svg {
      color: ${(props) => props.theme.colors.typography.baseText};
    }

    span {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: bolder !important;
      color: ${(props) => props.theme.colors.typography.baseText};
      padding-top: 1px;
    }

    button {
      all: unset;
    }
  }

  .time-panel {
    span {
      font-size: 0.85rem;
      text-transform: uppercase;
      font-weight: 400;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }
`;
