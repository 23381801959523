import { useEffect, useId } from 'react';
import { ContainerTenants } from './styled';
import { HomeTenants } from './stages/HomeTenants';
import { AddTenant } from './stages/AddTenant';
import { EditTenant } from './stages/EditTenant';
import { MatchTenants } from './stages/MatchTenants';
import { useAtom } from 'jotai';
import { stageTenants } from '@/store/TenantsStore';

export const Tenants = () => {
  const [stateRenderTenants, setStateRenderTenants] = useAtom(stageTenants);

  const handleTenantStage = (stage) => {
    return setStateRenderTenants(stage);
  };

  useEffect(() => {
    return () => {
      setStateRenderTenants(0);
    };
  }, []);

  const flowTenants = [
    <HomeTenants key={useId()} changeStage={handleTenantStage} />,
    <MatchTenants key={useId()} changeStage={handleTenantStage} />,
    <AddTenant key={useId()} changeStage={handleTenantStage} />,
    <EditTenant key={useId()} changeStage={handleTenantStage} />,
  ];

  return <ContainerTenants>{flowTenants[stateRenderTenants]}</ContainerTenants>;
};
