import { InputAdornment } from '@mui/material';
import { useAtom } from 'jotai';
import { useDeferredValue, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSearch } from 'react-icons/bi';
import { dataTableSecurityProfiles } from '@store/UsersAgentsStore/securityProfiles';
import { InputComponent } from '@components/FormComponents/InputComponent';
import { ContainerHomeSecurityProfile } from './styled';
import { TableSecurityProfile } from './TableSecurityProfile';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';

import { useSecurityProfile } from '@hooks/UserAgents/useSecurityProfile';
import { TitleComponent } from '@components/layout/TitleComponent';
import { useUsersAgents } from '@/hooks/UserAgents/useUsersAgents';
import { usePermissionsSecurity } from '../../hooks/usePermissionsSecurity';
import { NoPermissionCard } from '@/components/layout/NoPermissionCard';

export const HomeSecurityProfile = () => {
  const { t } = useTranslation();
  const { isLoadingDataClients } = useUsersAgents();
  const { isSysAdmin } = usePermissionsSecurity();

  const { isLoadingSecurityProfile } = useSecurityProfile();
  const [searchSecurityProfile, setSearchSecurityProfile] = useState('');
  const deferredSearchSecurityProfile = useDeferredValue(searchSecurityProfile);

  const [dataTableSecurity] = useAtom(dataTableSecurityProfiles);

  const filterSecurityProfile = dataTableSecurity.filter((item) =>
    item.name
      .toLowerCase()
      .includes(deferredSearchSecurityProfile.toLowerCase())
  );

  const isLoading = isLoadingSecurityProfile || isLoadingDataClients;

  return (
    <ContainerHomeSecurityProfile
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {isLoading && <BackDropCircularLoading />}
      <TitleComponent
        textTitle={t('securityProfile.homeSecurityProfiles.title')}
        iconTitle='securityProfile'
      />
      {!isSysAdmin ? (
        <NoPermissionCard
          message={t('routingProfile.homeRoutingProfiles.table.noData')}
        />
      ) : (
        <>
          <InputComponent
            value={searchSecurityProfile}
            onChange={(e) => setSearchSecurityProfile(e.target.value)}
            placeholder={t(
              'securityProfile.homeSecurityProfiles.placeholder-searchField'
            )}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <BiSearch />
                </InputAdornment>
              ),
            }}
          />
          <div className='content_btn_new_security_profile'></div>
          <TableSecurityProfile
            dataSecurityTable={filterSecurityProfile}
            isLoading={isLoading}
          />
        </>
      )}
    </ContainerHomeSecurityProfile>
  );
};
