import { Handle, Position } from 'reactflow';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SubNodeContainer } from './styled';

const SubNodeComponent = ({ data }) => {
  const { t } = useTranslation();

  const renderLabelTimeCondition = (dataLabel = '', dataValue = '') => {
    switch (dataLabel) {
      case 'period':
        return (
          <span>
            {t(`flowBuilder.flow.nodes.conditions.menu.label-${dataValue}`)}
          </span>
        );

      case 'weekdays':
        return (
          <span>
            {t(
              `flowBuilder.flow.nodes.conditions.menu.weekdays.label-day-${dataValue}`
            )}
          </span>
        );

      case 'groupDays':
        return (
          <span>
            {t(
              `flowBuilder.flow.nodes.conditions.menu.weekdays.label-day-${dataValue}`
            )}
          </span>
        );

      default:
        return <span>{dataValue}</span>;
    }
  };

  const renderLabelSubNode =
    data.label === 'Success'
      ? t('flowBuilder.flow.nodes.subNode.success')
      : t('flowBuilder.flow.nodes.subNode.error');

  if (data.label === 'timeout') {
    return (
      <SubNodeContainer typeHandler={'timeOutCondition'}>
        <div className='headerNode'>
          <span>{t('flowBuilder.flow.nodes.recVoz.label-timeOutNode')}</span>
        </div>
        <Handle type='source' position={Position.Right} />
      </SubNodeContainer>
    );
  }

  if (
    data.label === 'period' ||
    data.label === 'weekdays' ||
    data.label === 'groupDays'
  ) {
    return (
      <SubNodeContainer typeHandler={data.label}>
        <div className='headerNode'>
          {renderLabelTimeCondition(data.label, data.value)}
        </div>
        <Handle type='source' position={Position.Right} />
      </SubNodeContainer>
    );
  }

  return (
    <SubNodeContainer typeHandler={data.handler}>
      <div className='headerNode'>
        <span>
          {!data.value ? renderLabelSubNode : data.label}{' '}
          {`${data?.value ?? ''}`}
        </span>
      </div>
      <Handle type='source' position={Position.Right} />
    </SubNodeContainer>
  );
};

export const SubNode = memo(SubNodeComponent);
