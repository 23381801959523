import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import {
  ContainerChartAttendance,
  ContentChartAttendance,
  HeaderChartAttendance,
} from './styled';
import { Stack } from '@mui/system';
import { formatDate } from '@/utils/formatsDateAndTime';
import { parse } from 'date-fns';

const initialDataChart = [
  {
    total: '10',
    cod_lig: '',
    descricao: '',
  },
  {
    total: '10',
    cod_lig: '',
    descricao: '',
  },
  {
    total: '10',
    cod_lig: '',
    descricao: '',
  },
];

export const MailingAnalysis = ({ dataChartMailing, selectedQueue }) => {
  const { t } = useTranslation();
  const matches = useMediaQuery('(max-width:1550px)');
  const [heightChart, setHeightChart] = useState(238);

  const dataMailing =
    dataChartMailing.length === 0 ? initialDataChart : dataChartMailing;

  const checkDataRender = dataChartMailing.length > 0;

  useEffect(() => {
    if (matches) {
      return setHeightChart(257);
    }
    setHeightChart(270);
  }, [matches]);

  const [dataAtd, setDataAtd] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [dataLabelsAtd, setDataLabelsAtd] = useState(null);

  useEffect(() => {
    if (dataMailing.length > 0) {
      const mapValuesMailing = dataMailing.map((item) => Number(item.total));
      const mapLabelsMailing = dataMailing.map((item) => item.cod_lig);
      setDataLabelsAtd(mapLabelsMailing);
      setDataAtd(mapValuesMailing);
    }

    return () => {
      setDataAtd([]);
      setDataLabelsAtd(null);
    };
  }, [dataMailing]);

  if (!dataLabelsAtd) {
    return '';
  }

  const options = {
    chart: {
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 200,
        animateGradually: {
          enabled: true,
          delay: 200,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 200,
        },
      },
    },

    fill: {
      type: 'gradient',
    },
    stroke: {
      show: true,
    },
    colors: [
      '#FF4C43',
      '#4c48ff',
      '#FFb839',
      '#a03ffc',
      '#4c48ff',
      '#0cc04b',
      '#4c48ff',
      '#a03ffc',
      '#FFb839',
      '#0cc04b',
      '#4c48ff',
      '#672e93',
      '#ff57AB',
      '#FFb839',
      '#43ff86',
      '#ff57AB',
      '#672e93',
      '#0cc04b',
      '#4c48ff',
      '#FFb839',
      '#43ff86',
      '#ff57AB',
      '#672e93',
      '#0cc04b',
      '#4c48ff',
      '#FFb839',
      '#43ff86',
      '#ff57AB',
      '#43ff86',
    ],

    dataLabels: {
      enabled: checkDataRender,
    },

    tooltip: {
      enabled: checkDataRender,
    },

    legend: {
      show: true,
      position: 'bottom',
      fontSize: '9px',
    },

    labels: dataLabelsAtd,
  };

  const renderSelectedQueue = selectedQueue.map((item, i) => {
    const totalLength = selectedQueue.length - 1;
    if (totalLength === i) {
      return <span key={i}>{item.queue}</span>;
    }
    return <span key={i}>{item.queue},</span>;
  });

  const renderDataSelected = () => {
    if (selectedQueue.length > 0) {
      const firstDate = parse(
        selectedQueue[0].first_date,
        'yyyy-MM-dd',
        new Date()
      );

      const endDate = parse(
        selectedQueue[0].last_date,
        'yyyy-MM-dd',
        new Date()
      );

      if (formatDate(firstDate) === formatDate(endDate)) {
        return formatDate(firstDate);
      }

      return `${formatDate(firstDate)} - ${formatDate(endDate)}`;
    }

    const currentDate = new Date();
    return formatDate(currentDate);
  };

  return (
    <ContainerChartAttendance>
      <HeaderChartAttendance>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <h2>{t('dashboardPage.title-chartMailing')}</h2>
          <span>{renderDataSelected()}</span>
        </Stack>

        <Stack direction={'row'} spacing={'4px'}>
          {renderSelectedQueue}
        </Stack>
      </HeaderChartAttendance>
      <ContentChartAttendance>
        <Chart
          options={options}
          series={dataAtd}
          type='pie'
          width={'100%'}
          height={heightChart}
        />
      </ContentChartAttendance>
    </ContainerChartAttendance>
  );
};
