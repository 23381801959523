import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FormMenuNode = styled(motion.form)`
  width: 320px;
  height: 100%;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr 40px;
  gap: 0.6rem;
`;

export const MainAddNode = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem 0 0 0.5rem;

  .dividerItem {
    width: 100%;
    height: 1px;
    background-color: #d1d1d136;
  }

  .itens_valid_input {
    width: 100%;
    height: 100%;
    max-height: calc(32vh - 10px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;

    h4 {
      font-size: 0.7rem;
      font-weight: 400;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.typography.baseText};
      margin-bottom: 10px;
    }

    .contentItemsRender {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 4px;
    }

    .contentMessageList {
      user-select: none;
      background-color: ${(props) =>
        props.theme.colors.containers.baseBackground};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      width: 100%;
      border-radius: 3px;

      span {
        text-transform: uppercase;
        font-size: 0.8rem;
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }
  }
`;
