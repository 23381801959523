import { dataListClients } from '@/store/Clients';
import { dataTenants } from '@/store/TenantsStore';
import { useAtom } from 'jotai';

export const useRenderNameTenants = () => {
  const [listClients] = useAtom(dataListClients);
  const [listTenants] = useAtom(dataTenants);

  const renderClientName = (idClient) => {
    const filterClient = listClients
      .filter((client) => client.id === idClient)
      .map((clientMap) => clientMap.commercial_name)[0];

    if (!filterClient) {
      return idClient;
    }
    return filterClient;
  };

  const renderHostName = (idHost) => {
    const filterHostName = listTenants
      .filter((server) => server.id === idHost)
      .map((serverMap) => serverMap.name)[0];

    if (!filterHostName) {
      return idHost;
    }
    return filterHostName;
  };

  return { renderClientName, renderHostName };
};
