import { useTranslation } from 'react-i18next';
import { DetailPositionTable } from './styled';
import { SimpleTable } from '@/components/Tables/SimpleTable';

export const TableDetailPosition = ({ dataTable }) => {
  const { t } = useTranslation();

  const dataTablePosition = dataTable;

  const columnsDetailsPosition = [
    {
      Header: t('dialing.detailLineQueue.thPositionOne'),
      accessor: 'posicao_phone',
    },
    {
      Header: t('dialing.detailLineQueue.thPositionTwo'),
      accessor: 'quantidade',
    },
    {
      Header: t('dialing.detailLineQueue.thPositionThree'),
      accessor: 'invalidos',
    },
  ];

  return (
    <DetailPositionTable>
      <SimpleTable
        dataColumns={columnsDetailsPosition}
        dataTable={dataTablePosition}
        textNoData={t('dialing.detailLineQueue.noDataTable')}
      />
    </DetailPositionTable>
  );
};
