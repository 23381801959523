import { apiData } from '@/services/api';

export async function createCampaign(dataCreateCampaign) {
  try {
    const { data } = await apiData.post('dialer/campaign', dataCreateCampaign);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
