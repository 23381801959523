import { IoHeadsetSharp } from 'react-icons/io5';
import {
  TbActivity,
  TbMicrophone,
  TbRoute,
  TbServerCog,
  TbUsers,
} from 'react-icons/tb';
import {
  MdOutlineAttachEmail,
  MdOutlineFeaturedPlayList,
  MdOutlineScreenShare,
} from 'react-icons/md';
import {
  RiDashboard3Line,
  RiChatVoiceLine,
  RiSoundModuleLine,
} from 'react-icons/ri';
import { AiOutlineHome } from 'react-icons/ai';
import { MdMonitor } from 'react-icons/md';
import { FiPieChart } from 'react-icons/fi';
import { FaRegPlayCircle } from 'react-icons/fa';
import { BsCreditCard2Back } from 'react-icons/bs';
import { BiNetworkChart } from 'react-icons/bi';
import { BsBoxes } from 'react-icons/bs';
import { PiFrameCorners } from 'react-icons/pi';

export const listIconsSelect = [
  {
    name: 'Home',
    icon: <AiOutlineHome />,
    lib: 'react-icons/ai',
  },
  {
    name: 'Monitor',
    icon: <MdMonitor />,
    lib: 'react-icons/md',
  },
  {
    name: 'Dialing',
    icon: <TbMicrophone />,
    lib: 'react-icons/tb',
  },
  {
    name: 'Mailing',
    icon: <MdOutlineFeaturedPlayList />,
    lib: 'react-icons/md',
  },
  {
    name: 'Dashboard',
    icon: <RiDashboard3Line />,
    lib: 'react-icons/',
  },
  {
    name: 'Control Dialing',
    icon: <AiOutlineHome />,
    lib: 'react-icons/ai',
  },
  {
    name: 'Mailing Penetration',
    icon: <MdOutlineAttachEmail />,
    lib: 'react-icons/md',
  },
  {
    name: 'Records',
    icon: <IoHeadsetSharp />,
    lib: 'react-icons/io',
  },
  {
    name: 'Voice',
    icon: <RiChatVoiceLine />,
    lib: 'react-icons/ri',
  },
  {
    name: 'User',
    icon: <TbUsers />,
    lib: 'react-icons/tb',
  },
  {
    name: 'Control',
    icon: <RiSoundModuleLine />,
    lib: 'react-icons/ri',
  },
  {
    name: 'Reports',
    icon: <FiPieChart />,
    lib: 'react-icons/fi',
  },
  {
    name: 'Play',
    icon: <FaRegPlayCircle />,
    lib: 'react-icons/fa',
  },
  {
    name: 'Clients',
    icon: <BsCreditCard2Back />,
    lib: 'react-icons/bs',
  },
  {
    name: 'Screens',
    icon: <MdOutlineScreenShare />,
    lib: 'react-icons/md',
  },
  {
    name: 'RoutingProfile',
    icon: <TbRoute />,
    lib: 'react-icons/tb',
  },
  {
    name: 'Journey',
    icon: <BiNetworkChart />,
    lib: 'react-icons/bi',
  },
  {
    name: 'Flow',
    icon: <BsBoxes />,
    lib: 'react-icons/bs',
  },
  {
    name: 'Iframe',
    icon: <PiFrameCorners />,
    lib: 'react-icons/pi',
  },
  {
    name: 'Tenants',
    icon: <TbServerCog />,
    lib: 'react-icons/tb',
  },
  {
    name: 'Activity Log',
    icon: <TbActivity />,
    lib: 'react-icons/tb',
  },
];

export const verifyIcon = (iconNameReq) => {
  const filterIcon = listIconsSelect
    .filter((dataIcon) => dataIcon.name === iconNameReq)
    .map((item) => item.icon);

  if (!filterIcon || filterIcon.length === 0) {
    return null;
  }
  return filterIcon[0];
};
