import {
  dataConfigSelected,
  dataSelectedReportsCount,
} from '@/store/Reports/countInListStore';
import { useAtom } from 'jotai';
import { PanelCountReports } from './PanelCountReports';

import { ContainerCountReports } from './styled';
import { DetailsCountReports } from './DetailsCountReports';
import { ListCountReports } from './ListCountReports';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { InputAdornment } from '@mui/material';
import { BiSearch } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { progressDownload } from '@/store/workersStore';
import { useCountInList } from '@/hooks/reports/mailingPenetration/useCountInList';

export const CountReports = ({ changeFlow }) => {
  const { t } = useTranslation();
  const { mutateGetDataDownloadList, loadingGetDataDownloadList } =
    useCountInList();
  const [configSelected, setConfigReportsCounts] = useAtom(dataConfigSelected);
  const [selectedReportsCounts, setSelectedReportsCounts] = useAtom(
    dataSelectedReportsCount
  );

  const [, setProgress] = useAtom(progressDownload);

  const handleBackStage = () => {
    changeFlow(0);
    setSelectedReportsCounts(null);
    setConfigReportsCounts(null);
    setProgress(0);
  };

  useEffect(() => {
    return () => {
      handleBackStage;
    };
  }, []);

  const [filterDispositions, setFilterDispositions] = useState('');
  const [filterByType, setFilterByType] = useState('total');

  return (
    <ContainerCountReports>
      <PanelCountReports
        dataCountReport={configSelected}
        handleBackStage={handleBackStage}
        loadingGetDataDownloadList={loadingGetDataDownloadList}
      />
      <InputComponent
        placeholder={t(
          'reports.countInList.countReports.placeholder-dispositions'
        )}
        value={filterDispositions}
        onChange={(e) => setFilterDispositions(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <BiSearch />
            </InputAdornment>
          ),
        }}
      />
      <DetailsCountReports
        dataCountReport={selectedReportsCounts}
        filterByType={filterByType}
        setFilterByType={setFilterByType}
      />
      <ListCountReports
        dataCountReport={selectedReportsCounts}
        filterDispositions={filterDispositions}
        filterByType={filterByType}
        mutateGetDataDownloadList={mutateGetDataDownloadList}
      />
    </ContainerCountReports>
  );
};
