import { useId, useState } from 'react';
import { QueueSchedule } from './QueueSchedule';
import { FlowAddSchedule } from '../../components/FlowAddSchedule';
import { ContainerNewSchedule } from './styled';
import { SchedulesDetails } from './SchedulesDetails';
import { TransferDetails } from './TransferDetails';
import { CreatedSchedule } from './CreatedSchedule';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useQueues } from '@/hooks/dialControl/Queues/useQueues';
import { useDispositionsDialing } from '@/hooks/Dispositions/useDispositionsDialing';
import { useListsByClient } from '@/hooks/dialControl/ListsDialing/useListsByClient';

export const AddSchedule = ({ controlStage }) => {
  const { loadingListsByClient } = useListsByClient();
  const { loadingDataQueues } = useQueues();
  const { loadingDataDisposition } = useDispositionsDialing();
  const [stateProgress, setStageProgress] = useState(0);

  const controlStageNewSchedule = (toStage) => {
    setStageProgress(toStage);
  };

  const flowNewSchedule = [
    <QueueSchedule
      key={useId()}
      controlAddStage={controlStageNewSchedule}
      controlMainStage={controlStage}
    />,
    <SchedulesDetails key={useId()} controlStage={controlStageNewSchedule} />,
    <TransferDetails key={useId()} controlStage={controlStageNewSchedule} />,
    <CreatedSchedule
      key={useId()}
      controlStages={{
        toNewSchedule: controlStageNewSchedule,
        toListSchedule: () => controlStage.toStage(1),
      }}
    />,
  ];

  const isLoading =
    loadingListsByClient || loadingDataQueues || loadingDataDisposition;

  return (
    <>
      {isLoading && <BackDropCircularLoading />}
      <FlowAddSchedule stageFlow={stateProgress} isImportStage={false} />
      <ContainerNewSchedule>
        {flowNewSchedule[stateProgress]}
      </ContainerNewSchedule>
    </>
  );
};
