import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerActivityLog = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 46px);
  padding: 3rem 2rem;
  position: relative;
  overflow: hidden;

  display: grid;
  grid-template-rows: auto 1fr;
  gap: 2rem;

  @media (max-height: 400px) {
    height: calc(100vh - 60px);
  }
`;
