import { apiData } from '@/services/api';

export async function createScreenRoutingProfile(dataCreate) {
  try {
    const { data } = await apiData.post(
      `securityProfiles/createSecurityProfilesRoles`,
      dataCreate
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
}
