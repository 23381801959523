import { atom } from 'jotai';

export const isLoading = atom(false);
export const user = atom(null);
export const sysAdmin = atom(false);
export const admin = atom(false);
export const permissions = atom(null);
export const token = atom(null);
export const isIdle = atom(false);
export const showForgotPass = atom(false);
export const schemaScreens = atom(null);

export const triggerReloadApp = atom(false);
