import { AccordionDetails, AccordionSummary } from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { TablePermissions } from '../../../TablePermissions';
import {
  AccordionsStyled,
  ContentListPermissions,
  TitleAccordion,
} from './styled';

export const ListPermissionsEdit = ({
  dataScreensList,
  setDataScreensList,
}) => {
  const { t } = useTranslation();

  function isEmptyObject(obj) {
    return !!Object.keys(obj).length;
  }

  function renderTranslateItem(item) {
    const translatedItem = t(`dashboard.${item}`);

    return translatedItem === `dashboard.${item}` ? item : translatedItem;
  }

  return (
    <ContentListPermissions initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isEmptyObject(dataScreensList) &&
        dataScreensList?.permissions?.map((item, i) => {
          return (
            <AccordionsStyled key={i}>
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls={item.name}
                id={item.name}
              >
                <TitleAccordion>
                  {renderTranslateItem(item.name)}
                </TitleAccordion>
              </AccordionSummary>
              <AccordionDetails>
                <TablePermissions
                  dataPermission={item}
                  dataScreensList={dataScreensList}
                  setDataScreensList={setDataScreensList}
                />
              </AccordionDetails>
            </AccordionsStyled>
          );
        })}
    </ContentListPermissions>
  );
};
