import { errorToast } from '@/components/FormComponents/Toast';
import {
  errorFieldsDataApi,
  templateSelected,
  variablesList,
} from '@/store/FlowBuilder';
import axios from 'axios';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

export const useApiDataFlow = () => {
  const [loadingApiData, setLoadingApiData] = useState(false);
  const [errorFields, setErrorFields] = useAtom(errorFieldsDataApi);
  const [listAllVars, setListAllVars] = useState([]);

  const [template] = useAtom(templateSelected);
  const globalVars = template.variablesGlobal;

  // AQUI RENDER $VAR SYSTEM
  const [listVariables] = useAtom(variablesList);

  useEffect(() => {
    setListAllVars([...listVariables, ...globalVars]);
  }, [template, listVariables, globalVars]);

  const checkFieldHaveValue = (stateCheck) => {
    const filterState = stateCheck
      .filter((item) => item.value === '0' && !item.disabled)
      .map((item) => setErrorFields([...errorFields, item.idSelect]));

    if (!filterState || filterState.length > 0) {
      return true;
    }
    return false;
  };

  const renderParamUrlReplace = (string) => {
    const removedChar = string.replace('$', '');
    return removedChar;
  };

  const generateURLWithParams = (dataParams, dataUrl) => {
    const filterDisabledParams = dataParams.filter((item) => !item.disabled);

    let queryString = '';
    let queryStringValue = '';

    filterDisabledParams.forEach((param) => {
      const filterVarValue = listAllVars.filter(
        (item) => item.id === param.value
      );

      if (filterVarValue.length === 0) {
        return errorToast('Set parameters request');
      }

      queryStringValue += `${decodeURIComponent(
        param.name
      )}=${decodeURIComponent(
        renderParamUrlReplace(filterVarValue[0].value)
      )}&`;

      queryString += `${decodeURIComponent(param.name)}={{${decodeURIComponent(
        renderParamUrlReplace(filterVarValue[0].name)
      )}}}&`;
    });

    queryString = queryString.slice(0, -1);
    queryStringValue = queryStringValue.slice(0, -1);

    const urlResultValue = `${dataUrl}${queryStringValue}`;
    const urlResult = `${dataUrl}${queryString}`;

    return {
      urlResult,
      urlResultValue,
    };
  };

  const generateHeadersRequest = (dataHeaders) => {
    const mapData = dataHeaders.map((item) => {
      const filterVarValue = listAllVars.filter(
        (itemVar) => itemVar.id === item.value
      );

      if (filterVarValue.length > 0) {
        return {
          key: item.name,
          value: filterVarValue[0].value,
          disabled: item.disabled,
        };
      }
      return {
        disabled: true,
      };
    });

    let headersObject = {
      'Content-Type': 'application/json',
    };
    mapData.forEach((header) => {
      if (header.disabled) {
        return;
      }
      headersObject[header.key] = header.value;
    });

    return headersObject;
  };

  const generateBodyRequest = (dataBody) => {
    const mapData = dataBody.map((item) => {
      const filterVarValue = listAllVars.filter(
        (itemVar) => itemVar.id === item.value
      );

      if (filterVarValue.length > 0) {
        return {
          key: item.name,
          value: filterVarValue[0].value,
          disabled: item.disabled,
        };
      }
      return {
        disabled: true,
      };
    });

    const filterDisabledParams = mapData.filter((item) => !item.disabled);

    const objetoResultante = filterDisabledParams.reduce((acc, item) => {
      acc[item.key] = `${item.value}`;
      return acc;
    }, {});
    return objetoResultante;
  };

  const handleApiData = async (dataURI) => {
    setLoadingApiData(true);

    const configPostRequest = {
      url: dataURI.url,
      method: dataURI.method,
      headers: dataURI.headers,
      data: JSON.stringify(dataURI.body),
    };

    const configGetRequest = {
      url: dataURI.url,
      method: dataURI.method,
      headers: dataURI.headers,
    };

    const config =
      dataURI.method === 'POST' ? configPostRequest : configGetRequest;

    try {
      const response = await axios(config);
      setLoadingApiData(false);
      return response;
    } catch (err) {
      setLoadingApiData(false);
      if (!err.response) {
        return {
          status: 'Error',
          data: err.code,
        };
      }

      return {
        status: 'Error',
        statusCode: err.response.status,
        data: err.response.data.error,
      };
    }
  };

  return {
    handleApiData,
    loadingApiData,
    generateURLWithParams,
    generateHeadersRequest,
    generateBodyRequest,
    checkFieldHaveValue,
    listAllVars,
  };
};
