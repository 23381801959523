import { useTranslation } from 'react-i18next';
import { DetailTotalPhoneCode } from './styled';
import { SimpleTable } from '@/components/Tables/SimpleTable';

export const TableTotalPhoneCode = ({ dataTable }) => {
  const { t } = useTranslation();

  const dataTablePhoneCode = dataTable;

  const columnsPhoneCode = [
    {
      Header: t('dialing.detailLineQueue.thPhoneCodeOne'),
      accessor: 'cod_lig',
    },
    {
      Header: t('dialing.detailLineQueue.thPhoneCodeTwo'),
      accessor: 'quantidade',
    },
  ];

  return (
    <DetailTotalPhoneCode>
      <SimpleTable
        dataColumns={columnsPhoneCode}
        dataTable={dataTablePhoneCode}
        textNoData={t('dialing.detailLineQueue.noDataTable')}
      />
    </DetailTotalPhoneCode>
  );
};
