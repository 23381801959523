import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Typography } from '@mui/material';
import { useEffect, useId, useState } from 'react';
import { MdArrowForwardIos } from 'react-icons/md';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: 'transparent',
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<MdArrowForwardIos sx={{ fontSize: '0.7rem' }} />}
    {...props}
  />
))(() => ({
  flexDirection: 'row',
  padding: '0px 0px 0px 0px',
  minHeight: '28px',

  '& .MuiTypography-root': {
    color: 'gray',
    fontSize: '0.9rem',
    width: '100%',
    textAlign: 'start',
    paddingRight: '8px',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '10px 0',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

export const AccordionComponent = ({
  children,
  opemText = 'Busca Avançada',
  closeText = 'Fechar Busca',
  expandedContent = false,
  alignText = 'start',
  actionOnClose = false,
}) => {
  const [expanded, setExpanded] = useState('');

  useEffect(() => {
    expandedContent ? setExpanded('panel1') : setExpanded(false);
  }, [expandedContent]);

  const handleChange = (panel) => (event, newExpanded) => {
    if (!newExpanded) {
      if (actionOnClose) {
        actionOnClose();
      }
      return setExpanded(false);
    }
    return setExpanded(panel);
  };
  return (
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <AccordionSummary
        aria-controls={`panel-${useId()}`}
        id={`panel-header-${useId()}`}
      >
        <Typography
          style={{
            textAlign: alignText,
          }}
        >
          {!expanded ? opemText : closeText}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
