import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { ContainerLegend } from './styled';
import { CgEditBlackPoint } from 'react-icons/cg';
import { HiPhoneArrowDownLeft, HiPhoneArrowUpRight } from 'react-icons/hi2';
import { BiGitBranch } from 'react-icons/bi';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { FaLock } from 'react-icons/fa6';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

export default function LegendPopover() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'legendPopover' : undefined;

  const dataRender = [
    {
      description: t('journey.chartTree.legend.label-node'),
      icon: <CgEditBlackPoint />,
    },
    {
      description: t('journey.chartTree.legend.label-totalCalls'),
      icon: <HiPhoneArrowUpRight color='#009e6c' />,
    },
    {
      description: t('journey.chartTree.legend.label-closeCalls'),
      icon: <HiPhoneArrowDownLeft color='#d94608' />,
    },
    {
      description: t('journey.chartTree.legend.label-changeLine'),
      icon: <BiGitBranch />,
    },
    {
      description: t('journey.chartTree.legend.label-zoomIn'),
      icon: <FiZoomIn />,
    },
    {
      description: t('journey.chartTree.legend.label-zoomOut'),
      icon: <FiZoomOut />,
    },
    {
      description: t('journey.chartTree.legend.label-unlock'),
      icon: <FaLock />,
    },
  ];

  return (
    <div>
      <Button
        aria-describedby={id}
        variant='contained'
        onClick={handleClick}
        title={t('journey.chartTree.btn-legend')}
      >
        <HiOutlineInformationCircle />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: theme.colors.containers.colorCardSecondary,
          },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ContainerLegend>
          <h2>{t('journey.chartTree.btn-legend')}</h2>
          {dataRender &&
            dataRender.map((item, i) => (
              <div className={`item`} key={i}>
                {item.icon}
                <span>{item.description}</span>
              </div>
            ))}
        </ContainerLegend>
      </Popover>
    </div>
  );
}
