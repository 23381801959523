import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { getCampaigns } from '@/services/dialControl/campaigns/getCampaigns';
import { dataListCampaigns } from '@/store/Campaigns';
import { updateListCampaign } from '@/services/dialControl/ListCampaign/updateListCampaign';
import { getListSelected } from '@/services/dialControl/ListCampaign/getListSelected';
import { responseSelectedList } from '@/store/ListCampaigns';
import { deleteListSelected } from '@/services/dialControl/ListCampaign/deleteListSelected';
import { dataListsDialing, stageListCampaign } from '@/store/DialingStore';
import { getListsDialing } from '@/services/dialControl/lists/getListsDialing';
import { user } from '@/store/AuthenticatorStore';
import { getCampaignsByClient } from '@/services/dialControl/campaigns/getCampaignsByClient';

export const useListCampaign = () => {
  const { t } = useTranslation();
  const [, setDataCampaigns] = useAtom(dataListCampaigns);
  const [, resSelectedList] = useAtom(responseSelectedList);
  const [currentStage] = useAtom(stageListCampaign);
  const [currentUser] = useAtom(user);
  const [, setListsDialing] = useAtom(dataListsDialing);

  const { isLoading: loadingListsDialing, refetch } = useQuery(
    'getListsDialingDialControl',
    async () => {
      return await getListsDialing();
    },
    {
      enabled: currentStage === 0,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        const countCampaign = data.reduce((acc, obj) => {
          if (!acc[obj.campanha]) {
            acc[obj.campanha] = { name: obj.campanha, total: 0, parents: [] };
          }
          acc[obj.campanha].total += 1;
          acc[obj.campanha].parents.push(obj);
          return acc;
        }, {});
        const amountListByCampaign = Object.values(countCampaign);
        setListsDialing(amountListByCampaign);
      },
      onError: () =>
        errorToast(t('dialControl.listsDialing.msg-error-getLists')),
    }
  );

  const { isLoading: loadingDataCampaigns } = useQuery(
    'getCampaigns',
    async () => {
      return await getCampaignsByClient();
    },
    {
      enabled: currentStage === 1,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        // const filterClientIdCampaigns = currentUser.userRole.isSysAdmin
        //   ? data
        //   : data.filter((item) => item.id_cliente === currentUser.client_id);
        setDataCampaigns(data);
      },
      onError: () => errorToast(t('dialControl.listCampaigns.error-getLists')),
    }
  );

  const {
    mutate: mutateUpdateListCampaign,
    isLoading: loadingUpdateListCampaign,
  } = useMutation({
    mutationFn: async (dataUpdateList) => {
      return await updateListCampaign(dataUpdateList);
    },
    onSuccess: () => {
      refetch();
    },
  });

  const { mutate: mutateGetListCampaign, isLoading: loadingGetListCampaign } =
    useMutation({
      mutationFn: async (dataGetList) => {
        return await getListSelected(dataGetList);
      },
      onSuccess: (data) => {
        resSelectedList(data);
      },
    });

  const {
    mutate: mutateDeleteListCampaign,
    isLoading: loadingDeleteListCampaign,
  } = useMutation({
    mutationFn: async (dataGetList) => {
      return await deleteListSelected(dataGetList);
    },
    onSuccess: (data) => {
      resSelectedList(data);
    },
  });

  return {
    loadingListsDialing,
    loadingDataCampaigns,

    mutateUpdateListCampaign,
    loadingUpdateListCampaign,

    mutateGetListCampaign,
    loadingGetListCampaign,

    mutateDeleteListCampaign,
    loadingDeleteListCampaign,
  };
};
