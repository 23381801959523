import styled, { css } from 'styled-components';

export const SubNodeContainer = styled.div`
  padding: 5px;
  width: 220px;
  border-radius: 2px;
  background: #005af5;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  ${(props) =>
    props.typeHandler === 'successCondition' &&
    css`
      background: #4c48ff;
    `}

  ${(props) =>
    props.typeHandler === 'dtmfCondition' &&
    css`
      background: #39264c;
    `}

  ${(props) =>
    props.typeHandler === 'timeOutCondition' &&
    css`
      background: #ff57ab;
    `}

    ${(props) =>
    props.typeHandler === 'period' &&
    css`
      background: #005af5;
    `}

    ${(props) =>
    props.typeHandler === 'weekdays' &&
    css`
      background: #4c19f5;
    `}

    ${(props) =>
    props.typeHandler === 'groupDays' &&
    css`
      background: #3d13f4;
    `}

  ${(props) =>
    props.typeHandler === 'errorCondition' &&
    css`
      background: #ff4c43;
    `} 


  .react-flow__handle {
    background: transparent;
    width: 30px;
    height: 100%;
    border-radius: 0;
    border: none;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 10px;
      width: 8px;
      height: 8px;
      left: 10px;
      border: 2px solid ${(props) => props.theme.colors.containers.colorCard};
      ${(props) =>
        props.typeHandler === 'successCondition' &&
        css`
          background: ${(props) => props.theme.colors.primary};
        `}

      ${(props) =>
        props.typeHandler === 'timeOutCondition' &&
        css`
          background: #ff57ab;
        `}

      ${(props) =>
        props.typeHandler === 'timeCondition' &&
        css`
          background: ${(props) => props.theme.colors.primary};
        `}

    ${(props) =>
        props.typeHandler === 'errorCondition' &&
        css`
          background: ${(props) => props.theme.colors.secondary};
        `}
    }
  }

  .headerNode {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    font-size: 0.6rem;

    span {
      font-size: 0.66rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #ffff;
      line-height: 10px;
      padding-top: 1px;
    }
  }
`;
