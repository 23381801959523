import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { getSecurityProfileList } from '@services/userAgents/securityProfile/getSecurityProfileList';
import { dataTableSecurityProfiles } from '@store/UsersAgentsStore/securityProfiles';
import { createSecurityProfile } from '@services/userAgents/securityProfile/createSecurityProfile';
import { updateSecurityProfile } from '@services/userAgents/securityProfile/updateSecurityProfile';
import { deleteSecurityProfile } from '@services/userAgents/securityProfile/deleteSecurityProfile';

import { errorToast } from '@components/FormComponents/Toast';

export const useSecurityProfile = () => {
  const { t } = useTranslation();

  const [, setDataSecurityTable] = useAtom(dataTableSecurityProfiles);

  const { isLoading: isLoadingSecurityProfile } = useQuery(
    'dataSecurityProfile',
    async () => {
      return await getSecurityProfileList();
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setDataSecurityTable(data);
      },
      onError: (error) => {
        const errorMessage = error.message || JSON.stringify(error);
        const toastMessage =
          errorMessage === 'User is not SysAdmin!'
            ? null
            : errorToast(
                t(
                  'securityProfile.homeSecurityProfiles.messages.errorGetListSecurityProfiles'
                )
              );
        return toastMessage;
      },
    }
  );

  const {
    mutate: mutateCreateSecurityProfile,
    isLoading: loadingCreateSecurityProfile,
  } = useMutation({
    mutationFn: async (dataCreate) => {
      return await createSecurityProfile(dataCreate);
    },
    onError: () => {
      errorToast(
        t('securityProfile.addSecurityProfiles.messages.errorSaveProfile')
      );
    },
  });

  const {
    mutate: mutateUpdateRoleSecurityProfile,
    isLoading: loadingUpdateRoleSecurityProfile,
  } = useMutation({
    mutationFn: async (dataUpdate) => {
      return await updateSecurityProfile(dataUpdate);
    },
    onError: () => {
      errorToast(
        t('securityProfile.editSecurityProfiles.messages.errorEditProfile')
      );
    },
  });

  const {
    mutate: mutateDeleteSecurityProfile,
    isLoading: loadingDeleteSecurityProfile,
  } = useMutation({
    mutationFn: async (dataDelete) => {
      return await deleteSecurityProfile(dataDelete);
    },
    onError: () => {
      errorToast(
        t('securityProfile.removeSecurityProfiles.messages.errorRemoveProfile')
      );
    },
  });

  return {
    isLoadingSecurityProfile,

    mutateCreateSecurityProfile,
    loadingCreateSecurityProfile,

    mutateDeleteSecurityProfile,
    loadingDeleteSecurityProfile,

    mutateUpdateRoleSecurityProfile,
    loadingUpdateRoleSecurityProfile,
  };
};
