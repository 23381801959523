import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContentImportScreens = styled(motion.div)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  min-height: 200px;

  .content_btn_add_screen {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
`;

export const HeaderImportScreens = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.7rem;
  margin-top: -8px;

  svg {
    color: ${(props) => props.theme.colors.primary};
    font-size: 0.85rem;
  }
`;

export const FormImportScreen = styled.form`
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-rows: auto auto auto 1fr auto;
  gap: 2rem;

  @media (max-width: 1650px) {
    height: 86%;
  }
`;

export const DetailRelationshipSelected = styled.div`
  width: 100%;

  .contentItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 3px;
  }

  .itemSelected {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.4rem;
    span {
      font-size: 0.8rem;
      &:nth-child(1) {
        font-weight: 600;
        color: ${(props) => props.theme.colors.primary};
      }
      color: gray;
    }
  }
`;

export const FooterImportScreen = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.4rem;

  @media (max-height: 768px) {
    padding-bottom: 10px;
  }
`;
