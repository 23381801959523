import { motion } from 'framer-motion';
import styled from 'styled-components';

export const AgentsMonitorContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ContentGrid = styled(motion.div)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  overflow: auto;
  position: relative;

  @media (max-width: 1350px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 930px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const NoDataGrid = styled.div`
  user-select: none;
  position: absolute;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.typography.baseText};
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  gap: 1rem;

  svg {
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.secondary};
  }
`;
