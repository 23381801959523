import styled from 'styled-components';
import { ContainerNodeWrapper } from '../styled';

export const NodeInitContainer = styled(ContainerNodeWrapper)`
  background: ${(props) => props.theme.colors.containers.colorSecondNode};

  .react-flow__handle-right {
    right: -8px;
  }
`;

export const MainNodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.typography.baseTitle};
    font-weight: 600;
    text-transform: uppercase;
  }
`;
