import { useState } from 'react';
import { useAtom } from 'jotai';
import { Stack } from '@mui/system';
import { FaGear } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { dataDispositions, templateSelected } from '@/store/FlowBuilder';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ContentConfig, FooterConfig } from '../../styled';
import { successToast } from '@/components/FormComponents/Toast';
import { DragTransferListConfigs } from '@/components/pages/FlowBuilder/components/DragTransferListConfigs';

export const ListDispositions = ({
  listDispositions,
  handleClose,
  changeFlowConfigState,
  usedDisposition,
}) => {
  const { t } = useTranslation();
  const [dispositions] = useAtom(dataDispositions);
  const [, setTemplate] = useAtom(templateSelected);
  const [dataDispositionsSelected, setDataDispositionsSelected] = useState([]);

  const renderAvailableList = () => {
    const availableList = dispositions
      .map((element) => {
        const filterUsedDispositions = listDispositions.filter(
          (item) => item.name === element.name
        );
        if (filterUsedDispositions.length > 0) {
          return null;
        }
        return element;
      })
      .filter((item) => Boolean(item));

    return availableList;
  };

  const handleUpdateDispositions = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        codeLigs: dataDispositionsSelected,
      };
    });
    successToast(
      t('flowBuilder.configs.dispositions.msg-updated-addedDispositions')
    );
    handleClose();
    return;
  };

  return (
    <ContentConfig>
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4>{t('flowBuilder.configs.dispositions.title-dispositions')}</h4>
      </Stack>
      {dispositions.length > 0 ? (
        <DragTransferListConfigs
          codeList={renderAvailableList()}
          changeDataCodeList={setDataDispositionsSelected}
          dataSelected={listDispositions}
          checkRemoveData={usedDisposition}
          typeList={'dispositions'}
        />
      ) : (
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={'100%'}
          sx={{ opacity: 0.4 }}
        />
      )}
      <FooterConfig>
        <Button typeText onClick={() => changeFlowConfigState(1)}>
          {t('flowBuilder.configs.dispositions.btn-add-dispositions')}
        </Button>
        <Stack direction={'row'} spacing={1}>
          <Button typeText variant='secondary' onClick={handleClose}>
            {t('flowBuilder.configs.btn-cancel')}
          </Button>
          <Button typeText onClick={handleUpdateDispositions}>
            {t('flowBuilder.configs.btn-update')}
          </Button>
        </Stack>
      </FooterConfig>
    </ContentConfig>
  );
};
