import { useTranslation } from 'react-i18next';
import { DialogComponent } from '../../DialogComponent';
import { Button } from '../../FormComponents/ButtonComponent';
import { ContainerConfirmation, PanelConfirmation } from './styled';
import { BackDropCircularLoading } from '../LoadingComponent';

export const ConfirmationComponent = ({
  open,
  close,
  txtBtnClose = false,
  txtBtnSuccess = false,
  messageConfirm,
  actionConfirm,
  actionClose = false,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    actionConfirm();
    !isLoading && close(false);
  };

  const handleClose = () => {
    actionClose && actionClose();
    close(false);
  };

  return (
    <DialogComponent
      open={open}
      onClose={() => close(false)}
      fullWidth={true}
      variant='secondary'
    >
      {isLoading && <BackDropCircularLoading />}
      <ContainerConfirmation>
        <p>{messageConfirm}</p>
        <PanelConfirmation>
          <Button typeText variant='secondary' onClick={handleClose}>
            {!txtBtnClose ? t('dialing.btn-confirmationCancel') : txtBtnClose}
          </Button>
          <Button typeText onClick={handleConfirm}>
            {!txtBtnSuccess
              ? t('dialing.btn-confirmationAccept')
              : txtBtnSuccess}
          </Button>
        </PanelConfirmation>
      </ContainerConfirmation>
    </DialogComponent>
  );
};
