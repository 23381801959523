import styled from 'styled-components';
import { motion } from 'framer-motion';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const ContainerCard = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
  margin-right: 40px;
  margin-top: 6px;
  font-size: 0.75rem;
  text-align: center;
  border-radius: 3px;
  transition: all 0.15s ease;
  z-index: 1000;
  user-select: none;
`;

export const HeaderNode = styled.header`
  width: 100%;
  text-align: start;
  padding: 6px 6px;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.colors.primary};

  border-top-right-radius: 3px;
  border-top-left-radius: 3px;

  div {
    span {
      font-size: 0.75rem;
      text-transform: uppercase;

      font-weight: bold;
    }
  }

  h2 {
    font-size: 0.7rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.bases.baseWhite};
    font-weight: 500;
  }
`;

export const MainNode = styled.main`
  width: 100%;
  padding: 6px 8px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid ${(props) => props.theme.colors.typography.baseText}AA;
  background: ${(props) => props.theme.colors.containers.colorCard};

  .percentage-data {
    opacity: 0.6;
    font-size: 0.7rem;
    font-weight: regular;
    display: ${isSafari ? 'none' : 'block'};
  }
  .first-item {
    span {
      font-size: 0.8rem;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.typography.baseText};
      font-weight: bold;
    }

    svg {
      color: ${(props) => props.theme.colors.typography.baseText};
      display: ${isSafari ? 'none' : 'block'};
    }
  }

  .second-item {
    span {
      font-size: 0.8rem;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.success};
      font-weight: bold;
    }

    svg {
      color: ${(props) => props.theme.colors.success};
      display: ${isSafari ? 'none' : 'block'};
    }
  }

  span {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.secondary};
    font-weight: bold;
  }

  svg {
    opacity: 0.7;
    color: ${(props) => props.theme.colors.secondary};
    display: ${isSafari ? 'none' : 'block'};
  }
`;
