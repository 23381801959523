import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import { IoMdTrash } from 'react-icons/io';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { TimePickerComponent } from '@/components/FormComponents/TimePicker';
import { Button } from '@/components/FormComponents/ButtonComponent';
import {
  MdAddCircleOutline,
  MdClose,
  MdEdit,
  MdOutlineCheck,
} from 'react-icons/md';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { dataUfManagerSelected } from '@/store/DialingStore';
import { DialogComponent } from '@/components/DialogComponent';
import { RemoveSchedulerRow } from './RemoveSchedulerRow';
import { ufList } from './ufList';
import { useUFManager } from '@/hooks/dialControl/UFManager/useUfManager';
import { useMappingUfRows } from '../../../hooks/useMappingUfRows';
import {
  TableContainer,
  StyledTable,
  NoDataTable,
  BtnEdit,
  BtnCancel,
  BtnConfirm,
  TextTable,
} from './styled';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const TableUF = ({ dataRows, isLoadingData }) => {
  const { t } = useTranslation();
  const { mappingRows } = useMappingUfRows();

  const {
    mutateUpdatedUF,
    loadingUpdatedUF,
    mutateCreateScheduleUF,
    loadingCreateScheduleUF,
    mutateDeleteScheduleUF,
    loadingDeleteScheduleUF,
    mutateGetDataUF,
    loadingGetDataUF,
  } = useUFManager();

  const [selectedUfManager] = useAtom(dataUfManagerSelected);

  const [dialogRemoveRow, setDialogRemoveRow] = useState(false);
  const [isNewRow, setIsNewRow] = useState(false);

  const renderText = (spell) => {
    return t(`dialControl.ufManager.create.table.${spell}`);
  };

  const { rows, setRows, selected, setSelected, errorRow, setErrorRow } =
    dataRows;

  function checkErrors(dataRow) {
    const errors = [];

    if (dataRow.uf === '0') {
      errors.push('uf');
    }

    if (dataRow.week > dataRow.weekEnd) {
      errors.push('week', 'weekEnd');
    }

    if (dataRow.saturday > dataRow.saturdayEnd) {
      errors.push('saturday', 'saturdayEnd');
    }

    if (dataRow.sunday > dataRow.sundayEnd) {
      errors.push('sunday', 'sundayEnd');
    }

    if (errors.length > 0) {
      const dataError = {
        id: dataRow.id,
        inputsError: errors,
      };
      errorToast(renderText('msg-error-add-row'));
      return dataError;
    }

    return null;
  }

  const confirmEdit = (dataRow) => {
    const hasError = checkErrors(dataRow);

    if (hasError) {
      setErrorRow(hasError);

      return;
    }

    if (isNewRow) {
      const formatDataCreateRow = {
        cod_uf_bloq: selectedUfManager.name.toString(),
        uf: dataRow.uf,
        hora_comeca_sabado: dataRow.saturday,
        hora_termina_sabado: dataRow.saturdayEnd,
        hora_comeca_domingo: dataRow.sunday,
        hora_termina_domingo: dataRow.sundayEnd,
        hora_comeca: dataRow.week,
        hora_termina: dataRow.weekEnd,
      };

      return mutateCreateScheduleUF(formatDataCreateRow, {
        onSuccess: () => {
          mutateGetDataUF(selectedUfManager.name, {
            onSuccess: (data) => {
              setRows(mappingRows(data));
              setIsNewRow(false);
              setErrorRow(null);
              setSelected(null);
            },
          });
        },
      });
    }

    const formatDataRow = {
      id: dataRow.id,
      cod_uf_bloq: selectedUfManager.name.toString(),
      uf: dataRow.uf,
      hora_comeca_sabado: dataRow.saturday,
      hora_termina_sabado: dataRow.saturdayEnd,
      hora_comeca_domingo: dataRow.sunday,
      hora_termina_domingo: dataRow.sundayEnd,
      hora_comeca: dataRow.week,
      hora_termina: dataRow.weekEnd,
    };

    return mutateUpdatedUF(formatDataRow, {
      onSuccess: () => {
        mutateGetDataUF(selectedUfManager.name, {
          onSuccess: (data) => {
            setRows(mappingRows(data));
            setErrorRow(null);
            setSelected(null);
            successToast(
              t(`dialControl.ufManager.edit.msg-success-updatedScheduler`)
            );
          },
        });
      },
    });
  };

  const handlerCancelEdit = (idCancel) => {
    if (isNewRow) {
      const filterNewRow = rows.filter((row) => row.id !== idCancel);
      setRows(filterNewRow);
      setIsNewRow(false);
      setSelected(null);
    }
    setSelected(null);
  };

  const addRow = () => {
    setIsNewRow(true);
    const newRow = {
      id: uuid(),
      uf: '0',
      week: '00:00:00',
      saturday: '00:00:00',
      sunday: '00:00:00',
      weekEnd: '00:00:00',
      saturdayEnd: '00:00:00',
      sundayEnd: '00:00:00',
    };
    setSelected(newRow);
    setRows([...rows, newRow]);
  };

  const deleteRow = (id) => {
    setSelected(id);
    setDialogRemoveRow(true);
  };

  const requestDeleteRow = () => {
    mutateDeleteScheduleUF(selected, {
      onSuccess: () => {
        mutateGetDataUF(selectedUfManager.name, {
          onSuccess: (data) => {
            setRows(mappingRows(data));
            setErrorRow(null);
            setDialogRemoveRow(false);
            setSelected(null);
          },
        });
      },
    });
  };

  const editRow = (id) => {
    id && setSelected(rows.filter((row) => row.id === id)[0]);
  };

  const handleInputChange = (id, field, value) => {
    setRows(
      rows.map((row) =>
        row.id === id ? { ...row, [field]: format(value, 'HH:mm:ss') } : row
      )
    );
  };

  const handleUfChange = (id, value) => {
    setRows(rows.map((row) => (row.id === id ? { ...row, uf: value } : row)));
  };

  const handleCloseDialogRemoveRow = () => {
    setSelected(null);
    setDialogRemoveRow(false);
  };

  const formattedTimeList = (time) => {
    if (time) {
      const separateTime = time.split(':');
      const [hour, minutes] = separateTime;
      return new Date().setHours(hour, minutes, 0, 0);
    }
  };

  const isLoading = isLoadingData || loadingGetDataUF;

  return (
    <>
      {isLoading && <BackDropCircularLoading />}
      <Stack alignItems={'flex-end'} width={'100%'}>
        <Button
          typeText
          onClick={addRow}
          disabled={Boolean(errorRow) || Boolean(selected)}
        >
          {t(`dialControl.ufManager.create.btn-add-row`)}
        </Button>
      </Stack>
      <TableContainer>
        <StyledTable>
          <thead>
            <tr>
              <th>{renderText('th-uf')}</th>
              <th>{renderText('th-initWeek')}</th>
              <th>{renderText('th-initSaturday')}</th>
              <th>{renderText('th-initSunday')}</th>
              <th>{renderText('th-endWeek')}</th>
              <th>{renderText('th-endSaturday')}</th>
              <th>{renderText('th-endSunday')}</th>
              <th>{renderText('th-action')}</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              return selected?.id === row.id ? (
                <motion.tr
                  key={row.id}
                  onDoubleClick={() => editRow(row.id)}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <td>
                    <SelectComponent
                      defaultValue='0'
                      value={row.uf}
                      onChange={(e) => handleUfChange(row.id, e.target.value)}
                      error={
                        errorRow &&
                        errorRow.id === row.id &&
                        errorRow.inputsError.includes('uf')
                      }
                      disableHelper
                    >
                      <ItemSelect value={'0'} disabled>
                        UF
                      </ItemSelect>
                      {ufList.map((uf) => (
                        <ItemSelect key={uf} value={uf}>
                          {uf}
                        </ItemSelect>
                      ))}
                    </SelectComponent>
                  </td>
                  <td>
                    <TimePickerComponent
                      value={formattedTimeList(row.week)}
                      onChange={(e) => handleInputChange(row.id, 'week', e)}
                      error={
                        errorRow &&
                        errorRow.id === row.id &&
                        errorRow.inputsError.includes('week')
                      }
                    />
                  </td>
                  <td>
                    <TimePickerComponent
                      value={formattedTimeList(row.saturday)}
                      onChange={(e) => handleInputChange(row.id, 'saturday', e)}
                      error={
                        errorRow &&
                        errorRow.id === row.id &&
                        errorRow.inputsError.includes('saturday')
                      }
                    />
                  </td>
                  <td>
                    <TimePickerComponent
                      value={formattedTimeList(row.sunday)}
                      onChange={(e) => handleInputChange(row.id, 'sunday', e)}
                      error={
                        errorRow &&
                        errorRow.id === row.id &&
                        errorRow.inputsError.includes('sunday')
                      }
                    />
                  </td>
                  <td>
                    <TimePickerComponent
                      value={formattedTimeList(row.weekEnd)}
                      onChange={(e) => handleInputChange(row.id, 'weekEnd', e)}
                      error={
                        errorRow &&
                        errorRow.id === row.id &&
                        errorRow.inputsError.includes('weekEnd')
                      }
                    />
                  </td>
                  <td>
                    <TimePickerComponent
                      value={formattedTimeList(row.saturdayEnd)}
                      onChange={(e) =>
                        handleInputChange(row.id, 'saturdayEnd', e)
                      }
                      error={
                        errorRow &&
                        errorRow.id === row.id &&
                        errorRow.inputsError.includes('saturdayEnd')
                      }
                    />
                  </td>
                  <td>
                    <TimePickerComponent
                      value={formattedTimeList(row.sundayEnd)}
                      onChange={(e) =>
                        handleInputChange(row.id, 'sundayEnd', e)
                      }
                      error={
                        errorRow &&
                        errorRow.id === row.id &&
                        errorRow.inputsError.includes('sundayEnd')
                      }
                    />
                  </td>
                  <td>
                    <div className={'actionTable'}>
                      <BtnConfirm
                        onClick={() => confirmEdit(row)}
                        type='button'
                      >
                        {loadingCreateScheduleUF || loadingUpdatedUF ? (
                          <CircularProgress color='inherit' size={14} />
                        ) : (
                          <MdOutlineCheck />
                        )}
                      </BtnConfirm>
                      <BtnCancel
                        onClick={() => handlerCancelEdit(row.id)}
                        type='button'
                      >
                        <MdClose />
                      </BtnCancel>
                    </div>
                  </td>
                </motion.tr>
              ) : (
                <motion.tr
                  key={row.id}
                  onDoubleClick={() => editRow(row.id)}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <td>
                    <TextTable selected={selected ? 1 : 0}>{row.uf}</TextTable>
                  </td>
                  <td>
                    <TextTable selected={selected ? 1 : 0}>
                      {row.week}
                    </TextTable>
                  </td>
                  <td>
                    <TextTable selected={selected ? 1 : 0}>
                      {row.saturday}
                    </TextTable>
                  </td>
                  <td>
                    <TextTable selected={selected ? 1 : 0}>
                      {row.sunday}
                    </TextTable>
                  </td>
                  <td>
                    <TextTable selected={selected ? 1 : 0}>
                      {row.weekEnd}
                    </TextTable>
                  </td>
                  <td>
                    <TextTable selected={selected ? 1 : 0}>
                      {row.saturdayEnd}
                    </TextTable>
                  </td>
                  <td>
                    <TextTable selected={selected ? 1 : 0}>
                      {row.sundayEnd}
                    </TextTable>
                  </td>
                  <td>
                    <div className={'actionTable'}>
                      <BtnEdit
                        onClick={() => editRow(row.id)}
                        type='button'
                        disabled={selected}
                      >
                        <MdEdit />
                      </BtnEdit>

                      <BtnCancel
                        onClick={() => deleteRow(row.id)}
                        disabled={selected}
                        type='button'
                      >
                        <IoMdTrash />
                      </BtnCancel>
                    </div>
                  </td>
                </motion.tr>
              );
            })}
          </tbody>
        </StyledTable>
        {rows.length === 0 && (
          <NoDataTable initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <button onClick={addRow} type='button'>
              <MdAddCircleOutline />
              <span>{renderText('msg-add-row')}</span>
            </button>
          </NoDataTable>
        )}
      </TableContainer>

      <DialogComponent
        open={dialogRemoveRow}
        onClose={handleCloseDialogRemoveRow}
        fullWidth={true}
      >
        <RemoveSchedulerRow
          closeDialog={handleCloseDialogRemoveRow}
          handleDeleteItem={requestDeleteRow}
          isLoading={loadingDeleteScheduleUF}
        />
      </DialogComponent>
    </>
  );
};
