import styled from 'styled-components';

export const Pre = styled.pre`
  text-align: left;
  padding: 0.5em;
  overflow: scroll;
  white-space: pre-wrap;
  word-wrap: break-word;
  height: ${(props) => (props.index === 1 ? `280px` : `200px`)};
  font-size: 0.84rem;
  background-color: ${(props) => props.theme.colors.containers.baseContainer};
`;

export const Line = styled.div`
  display: table-row;
`;

export const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`;

export const LineContent = styled.span`
  display: table-cell;
  .string,
  .number {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }

  .string-property {
    color: ${(props) => props.theme.colors.typography.baseTitle} !important;
  }

  .punctuation {
    color: ${(props) => props.theme.colors.defaultColors.purple} !important;
  }

  .plain,
  .operator {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }
`;
