import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerSummary = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 60px);
  padding: 3rem 2rem;
  gap: 2rem;
  display: grid;
  grid-template-rows: 60px 1fr;
`;
