import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerHeaderFlow = styled(motion.div)`
  grid-column: 1/3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  .disabled-cursor {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: not-allowed;
    z-index: 9999;
    width: 100%;
  }

  @media (max-width: 830px) {
    justify-content: flex-start;
  }

  .container-title {
    svg {
      color: ${(props) => props.theme.colors.typography.baseText}AA;
      font-size: 0.9rem;
      @media (max-width: 1125px) {
        display: none;
      }
    }

    button {
      padding: 0;
      min-width: 30px;
      svg {
        font-size: 1.2rem;
      }
    }
  }

  .contentTitleHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;

    h4 {
      padding: 1rem 0;
      font-size: 0.9rem;
      color: ${(props) => props.theme.colors.typography.baseText}AE;
      font-weight: 600;
    }

    span {
      font-size: 0.7rem;
      color: ${(props) => props.theme.colors.typography.baseText}AA;
      text-transform: uppercase;
    }

    svg {
      font-size: 0.7rem;
    }

    @media (max-width: 1030px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0rem;

      h4 {
        padding: 0rem 0;
        font-size: 0.9rem;
      }

      span {
        font-size: 10px;
      }
    }

    @media (max-width: 830px) {
      display: none;
    }
  }

  .content-status-flow {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
      props.status === 'published' ? '#05c205' : '#ff8a14'};
    padding: 4px 8px;
    border-radius: 30px;

    @media (max-width: 1125px) {
      display: none;
    }

    span {
      font-size: 12px;
      color: white;
      text-transform: uppercase;
      padding-top: 2px;
    }
  }

  .btn-current-flow {
    display: flex;
    align-items: center;
    gap: 6px;

    @media (max-width: 630px) {
      display: none;
    }

    button {
      height: 30px;
      margin-top: -2px;
      box-shadow: none;
      &:nth-child(1) {
        min-width: 10px;

        svg {
          color: white;
        }
      }
    }

    .btn-publish {
      background-color: ${(props) =>
        props.theme.colors.listNodes.bgBtn} !important;
    }
  }
`;

export const SelectNav = styled(SelectComponent)`
  background: transparent !important;

  .MuiInputBase-input {
    padding-top: 5px !important;
  }

  .MuiOutlinedInput-input {
    padding: 4px 14px !important;
  }

  @media (max-width: 630px) {
    display: none !important;
  }
`;

export const MenuItemPublished = styled(ItemSelect)`
  width: 100%;
  max-width: 400px;
  display: flex !important;
  align-items: center !important;
  gap: 4px;

  svg {
    color: #05c205;
    font-size: 0.8rem;
  }

  span {
    color: #05c205;
    font-weight: 600;
    font-size: 0.8rem;
    background-color: transparent;
    &:nth-child(2) {
      font-style: italic;
      font-size: 0.75rem;
    }
  }
`;

export const MenuItemHistory = styled(ItemSelect)`
  width: 100%;
  max-width: 400px;
  display: flex !important;
  gap: 4px;

  span {
    font-size: 0.8rem;
    &:nth-child(2) {
      font-style: italic;
      font-size: 0.75rem;
      color: ${(props) => props.theme.colors.typography.baseText}AA;
    }
  }
`;
