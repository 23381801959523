import { getUserById } from '@/services/userAgents/usersList/getUserById';
import { lastUserUpdate } from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';

export function useUser() {
  const [, setLastUserName] = useAtom(lastUserUpdate);

  const { mutate: mutateUserById } = useMutation({
    mutationFn: async (idUser) => {
      return await getUserById(idUser);
    },
  });

  const renderLastUpdatedUserFlow = (idUser) => {
    if (!idUser) {
      return setLastUserName('-');
    }

    mutateUserById(idUser, {
      onSuccess: (data) => setLastUserName(data.name),
      onError: () => setLastUserName(idUser ? idUser : '-'),
    });
  };

  return { renderLastUpdatedUserFlow };
}
