import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { getCampaignsByClient } from '@/services/dialControl/campaigns/getCampaignsByClient';
import { useAtom } from 'jotai';
import { dataCampaignsByClient } from '@/store/Campaigns';

export const useCampaignByClient = () => {
  const { t } = useTranslation();
  const [, setDataCampaignByClient] = useAtom(dataCampaignsByClient);

  const { isLoading: loadingDataCampaignsByClient } = useQuery(
    'getCampaignsByClient',
    async () => {
      return await getCampaignsByClient();
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setDataCampaignByClient(data);
      },
      onError: () =>
        errorToast(t('dialControl.campaigns.listCampaigns.error-getLists')),
    }
  );

  return {
    loadingDataCampaignsByClient,
  };
};
