import styled from 'styled-components';

export const ContainerHeader = styled.div`
  margin: auto;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export const ContainerPaginator = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const PageItem = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.colors.containers.baseContainer};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 2;
`;

export const LineMarque = styled.div`
  height: 4px;
  width: 99%;
  background-color: #d9d9d9;
  position: absolute;
`;

export const ProgressBar = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.primary};
  height: 4px;
  width: ${(props) => props.statusProgress};
  transition: all 0.3s ease-in-out;
`;

export const SpanText = styled.span`
  color: ${(props) => props.theme.colors.typography.baseText};
  font-size: 0.7rem;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const CheckedStage = styled.div`
  color: #fff;
  font-size: 0.7rem;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
`;

export const CurrentTextPage = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 0.7rem;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.typography.baseText};
  margin-top: 10px;
`;
