import { useEffect, useState } from 'react';
import {
  ContainerPaginator,
  PageItem,
  LineMarque,
  SpanText,
  ProgressBar,
  CurrentTextPage,
  ContainerHeader,
} from './styled';

import { HiCheckCircle } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

function RoutingPagination({ stageFlow }) {
  const { t } = useTranslation();
  function currentStage() {
    if (stageFlow === 1) {
      return '50%';
    } else if (stageFlow === 2) {
      return '100%';
    } else {
      return '0';
    }
  }

  const [statusProgress, setStatusProgress] = useState('0');

  const [textStatus] = useState([
    t('routingProfile.addRoutingProfile.detailsAddRouting.title'),
    t('routingProfile.addRoutingProfile.createScreens.title'),
    t('routingProfile.addRoutingProfile.successCreatedRouting.title'),
  ]);

  useEffect(() => {
    setStatusProgress(currentStage);
  }, [stageFlow]);

  return (
    <ContainerHeader>
      <ContainerPaginator>
        <PageItem isActive>
          <SpanText isActive>{stageFlow >= 1 ? <HiCheckCircle /> : 1}</SpanText>
        </PageItem>
        <PageItem isActive={stageFlow >= 1 && true}>
          <SpanText isActive={stageFlow >= 1 && true}>
            {stageFlow >= 2 ? <HiCheckCircle /> : 2}
          </SpanText>
        </PageItem>
        <PageItem isActive={stageFlow >= 2 && true}>
          <SpanText isActive={stageFlow >= 2 && true}>
            {stageFlow >= 2 ? <HiCheckCircle /> : 3}
          </SpanText>
        </PageItem>
        <LineMarque>
          <ProgressBar statusProgress={statusProgress} />
        </LineMarque>
      </ContainerPaginator>
      <CurrentTextPage>{textStatus[stageFlow]}</CurrentTextPage>
    </ContainerHeader>
  );
}

export { RoutingPagination };
