// globalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    &::-webkit-scrollbar {
    width: 8px;
    font-family: 'Keymer Radius' !important;
    -webkit-font-smoothing: antialiased;
    font-weight: 500 !important;
    translate: no;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar:horizontal{
    height: 8px;
    }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.containers.dividerColor};
    border-radius: 8px;
    border: 2px solid ${(props) =>
      props.theme.colors.containers.baseBackground};
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

}
  body {
    margin: 0;
    padding: 0;
    font-family: 'Keymer Radius'  !important;
    font-weight: 500 !important;
  }

  h1, h2, h3{
    font-family: 'Keymer Radius' !important;
    font-weight: bold !important; 
  }

  span, p, input, label,button{
    font-family: 'Keymer Radius'  !important;
    font-weight: 500 !important;
  }

  button{
  cursor: pointer;
  outline: none;
     font-family: 'Keymer Radius'  !important;
    font-weight: 500 !important; 
  }

  select, option, input{
    outline: none;
    font-family: 'Keymer Radius' !important;
    font-weight: 500 !important;
  }


  a{
    font-family: 'Keymer Radius' !important;
    font-weight: 500 !important;
    text-decoration: none;
    color: inherit;
  }
  
  @media(max-width: 1080px){
  html{
    font-size: 93.75%;
  }
}
  @media(max-width: 720px){
    html{
      font-size: 87.5%;
    }
  }

  .MuiFormHelperText-root.Mui-error{
    font-size: 0.8rem !important;
    
  }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .MuiListItemIcon-root {
    max-width: 1px !important;
  }

  .LazyLoad {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.is-visible {
  opacity: 1;
}

.MuiAutocomplete-option {
    font-size: 0.87rem !important;
    padding: 8px 14px !important;
  }
`;

export default GlobalStyle;
