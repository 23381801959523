import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormSchedules = styled(motion.form)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;

  .main {
    padding-top: 1rem;
    width: 100%;
    height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: flex-star;
    gap: 2.4rem;

    @media (max-width: 1440px) {
      gap: 1.8rem;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 0.4rem;
  }
`;
