import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormEditScheduleQueue = styled(motion.form)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 30px;
  gap: 1rem;
`;

export const ContentMain = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;

  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
  }
`;
export const ContentFooter = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.4rem;
`;
