import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerSecurityProfile = styled(motion.div)`
  width: 100%;
  flex: 1;
  padding: 3rem 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  user-select: none;

  height: auto;
  overflow: auto;

  @media (max-width: 1200px) {
    overflow: scroll;
  }
`;
