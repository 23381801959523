import { TitleComponent } from '@/components/layout/TitleComponent';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { useEffect } from 'react';
import { ContainerEditCallOperator, FormCallOperator } from './styled';
import {
  ControlInput,
  ControlInputMask,
} from '@/components/FormComponents/InputComponent';
import { Stack } from '@mui/system';
import { InputAdornment } from '@mui/material';
import { selectedCallOperators } from '@/store/CallOperator';
import { useAtom } from 'jotai';
import { useCallOperators } from '@/hooks/CallOperators';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { successToast } from '@/components/FormComponents/Toast';

export const EditCallOperator = ({ changeStage }) => {
  const { t } = useTranslation();

  const renderText = (spell) => {
    return t(`callOperators.edit.${spell}`);
  };

  const [selectedCallOperator, setSelectedCallOperator] = useAtom(
    selectedCallOperators
  );

  const { mutateUpdateCallOperators, loadingUpdateCallOperators } =
    useCallOperators();

  const editCallOperatorSchema = yup.object().shape({
    cod_operadora: yup
      .string()
      .required(renderText('msgs.msg-required-codOperator')),
    nome_operadora: yup.string().required(renderText('msgs.msg-required-nome')),
    tarifa_local_fixo: yup
      .string()
      .required(renderText('msgs.msg-required-tarifaFixo')),
    tarifa_ldn_fixo: yup
      .string()
      .required(renderText('msgs.msg-required-tarifaLdn')),
    tarifa_vc1: yup
      .string()
      .required(renderText('msgs.msg-required-tarifaVc1')),
    tarifa_vc2: yup
      .string()
      .required(renderText('msgs.msg-required-tarifaVc2')),
    tarifa_vc3: yup
      .string()
      .required(renderText('msgs.msg-required-tarifaVc3')),
    cadencia_min: yup
      .string()
      .required(renderText('msgs.msg-required-cadMinuto')),
    cadencia: yup.string().required(renderText('msgs.msg-required-cadencia')),
    anatel_segundos: yup
      .string()
      .required(renderText('msgs.msg-required-segAnatel'))
      .min(0),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(editCallOperatorSchema),
  });

  const handleBack = () => {
    changeStage(0);
    setSelectedCallOperator({});
    reset();
  };

  const handleSubmitCallOperator = (data) => {
    mutateUpdateCallOperators(data, {
      onSuccess: () => {
        successToast(renderText('msgs.msg-success-update'));
        handleBack();
      },
    });
  };

  useEffect(() => {
    return () => handleBack();
  }, []);

  const renderCurrentValue = (value) => {
    if (value) {
      let toNumber = Number(value);
      let formattedNumber = toNumber.toFixed(4);
      let slices = formattedNumber.split('.');
      let fullInt = slices[0].padStart(2, '0');
      formattedNumber = fullInt + '.' + slices[1];
      return formattedNumber;
    }
    return '00.0000';
  };

  const isLoading = loadingUpdateCallOperators;

  return (
    <ContainerEditCallOperator
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {isLoading && <BackDropCircularLoading />}
      <FormCallOperator onSubmit={handleSubmit(handleSubmitCallOperator)}>
        <TitleComponent
          textTitle={renderText('title')}
          iconTitle='callOperator'
        />

        <div className='mainCallOperator'>
          <ControlInput
            control={control}
            defaultValue={selectedCallOperator.cod_operadora}
            nameControl={'cod_operadora'}
            label={renderText('label-codOperator')}
            error={Boolean(errors.cod_operadora)}
            helperText={
              errors.cod_operadora ? errors.cod_operadora.message : ' '
            }
          />
          <ControlInput
            control={control}
            defaultValue={selectedCallOperator.nome_operadora}
            nameControl={'nome_operadora'}
            label={renderText('label-nome')}
            error={Boolean(errors.nome_operadora)}
            helperText={
              errors.nome_operadora ? errors.nome_operadora.message : ' '
            }
          />

          <Stack direction={'row'} spacing={2}>
            <ControlInputMask
              control={control}
              nameControl={'tarifa_local_fixo'}
              defaultValue={renderCurrentValue(
                selectedCallOperator.tarifa_local_fixo
              )}
              label={renderText('label-tarifaFixo')}
              mask='99.9999'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>R$</InputAdornment>
                ),
              }}
              error={Boolean(errors.tarifa_local_fixo)}
              helperText={
                errors.tarifa_local_fixo
                  ? errors.tarifa_local_fixo.message
                  : ' '
              }
            />
            <ControlInputMask
              control={control}
              nameControl={'tarifa_ldn_fixo'}
              defaultValue={renderCurrentValue(
                selectedCallOperator.tarifa_ldn_fixo
              )}
              label={renderText('label-tarifaLdn')}
              mask='99.9999'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>R$</InputAdornment>
                ),
              }}
              error={Boolean(errors.tarifa_ldn_fixo)}
              helperText={
                errors.tarifa_ldn_fixo ? errors.tarifa_ldn_fixo.message : ' '
              }
            />
          </Stack>
          <Stack direction={'row'} spacing={2}>
            <ControlInputMask
              control={control}
              nameControl={'tarifa_vc1'}
              defaultValue={renderCurrentValue(selectedCallOperator.tarifa_vc1)}
              label={renderText('label-tarifaVc1')}
              mask='99.9999'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>R$</InputAdornment>
                ),
              }}
              error={Boolean(errors.tarifa_vc1)}
              helperText={errors.tarifa_vc1 ? errors.tarifa_vc1.message : ' '}
            />
            <ControlInputMask
              control={control}
              nameControl={'tarifa_vc2'}
              defaultValue={renderCurrentValue(selectedCallOperator.tarifa_vc2)}
              label={renderText('label-tarifaVc2')}
              mask='99.9999'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>R$</InputAdornment>
                ),
              }}
              error={Boolean(errors.tarifa_vc2)}
              helperText={errors.tarifa_vc2 ? errors.tarifa_vc2.message : ' '}
            />
            <ControlInputMask
              control={control}
              nameControl={'tarifa_vc3'}
              defaultValue={renderCurrentValue(selectedCallOperator.tarifa_vc3)}
              label={renderText('label-tarifaVc3')}
              mask='99.9999'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>R$</InputAdornment>
                ),
              }}
              error={Boolean(errors.tarifa_vc3)}
              helperText={errors.tarifa_vc3 ? errors.tarifa_vc3.message : ' '}
            />
          </Stack>

          <Stack direction={'row'} spacing={2}>
            <ControlInputMask
              control={control}
              nameControl={'cadencia_min'}
              defaultValue={selectedCallOperator.cadencia_min}
              label={renderText('label-cadMinuto')}
              mask='9.99'
              error={Boolean(errors.cadencia_min)}
              helperText={
                errors.cadencia_min ? errors.cadencia_min.message : ' '
              }
            />
            <ControlInputMask
              control={control}
              nameControl={'cadencia'}
              defaultValue={selectedCallOperator.cadencia}
              label={renderText('label-cadencia')}
              mask='9.99'
              error={Boolean(errors.cadencia)}
              helperText={errors.cadencia ? errors.cadencia.message : ' '}
            />
          </Stack>

          <ControlInput
            control={control}
            nameControl={'anatel_segundos'}
            defaultValue={selectedCallOperator.anatel_segundos}
            label={renderText('label-segAnatel')}
            type='number'
            inputProps={{ min: 0 }}
            error={Boolean(errors.anatel_segundos)}
            helperText={
              errors.anatel_segundos ? errors.anatel_segundos.message : ' '
            }
          />
        </div>

        <div className='footerAddNewCallOperator'>
          <Button typeText variant={'secondary'} onClick={handleBack}>
            {renderText('btn-cancel')}
          </Button>
          <Button typeText type='submit'>
            {renderText('btn-update')}
          </Button>
        </div>
      </FormCallOperator>
    </ContainerEditCallOperator>
  );
};
