import styled from 'styled-components';

export const ContainerTableSummary = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  user-select: none;

  table {
    tbody {
      tr {
        height: 50px;
        td {
          z-index: 1;
          padding: 0 0.8rem;

          &:nth-child(2) {
            svg {
              color: ${(props) => props.theme.colors.secondary};
              margin-right: 8px;
            }
          }

          &:nth-child(3) {
            width: 180px;
            svg {
              color: ${(props) => props.theme.colors.primary};
              margin-right: 8px;
            }
          }

          &:nth-child(5) {
            min-width: 100px;
          }
        }
      }
    }
  }

  @media (max-width: 1300px) {
    table {
      tbody {
        tr {
          height: 54px;
        }
      }
    }
  }
`;
