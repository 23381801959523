import styled from 'styled-components';
import { ContainerNodeWrapper, ContentNodeWrapper } from '../styled';

export const NodeHangUpContainer = styled(ContainerNodeWrapper)`
  position: relative;
  background: ${(props) => props.theme.colors.containers.colorSecondNode};

  .react-flow__handle {
    &::after {
      background: #ff0000;
    }
  }
`;

export const NodeContent = styled(ContentNodeWrapper)`
  min-width: 160px;
  .header {
    background: #ff0000;
  }
`;
