import { useReactFlow } from 'reactflow';
import { useAtom } from 'jotai';
import {
  dataTemplateMailing,
  hasSavedTemplateMailing,
  showEmptyColumnsImported,
  useHeaderCsv,
} from '@/store/LoadList';

export const useSaveTemplateLoadList = () => {
  const { getNodes, getEdges } = useReactFlow();

  const [renderHeaderCsv] = useAtom(useHeaderCsv);
  const [dataTemplates, setDataTemplates] = useAtom(dataTemplateMailing);
  const [showNodesNulls] = useAtom(showEmptyColumnsImported);
  const [, setSaveFlowMailing] = useAtom(hasSavedTemplateMailing);

  const updateEdgesToSave = (dataEdges) => {
    const changeTypeToSystemEdges = dataEdges.map((edge) => ({
      ...edge,
      type: 'systemEdge',
    }));

    return changeTypeToSystemEdges;
  };

  const handleSaveTemplateLoadList = (dataLabel = '', dataDescription = '') => {
    const currentNodes = getNodes();
    const currentEdges = getEdges();
    const filterLayout = dataTemplates;

    const resetResultFiltersToSave = (dataFilters = []) => {
      if (dataFilters.length === 0) {
        return [];
      }
      const updateResultFilters = dataFilters.map((filterData) => {
        return {
          ...filterData,
          result: '',
        };
      });
      return updateResultFilters;
    };

    const updatedNodes = currentNodes.map((node) => {
      if (node.type === 'nodeMailing') {
        return {
          ...node,
          data: {
            ...node.data,
            hasColumnInCsv: false,
            totalEmptyValues: 0,
            filters: resetResultFiltersToSave(node.data.filters),
          },
        };
      }
      return {
        ...node,
        itemPreview: '',
        data: {
          ...node.data,
          hasColumnInCsv: false,
          totalEmptyValues: 0,
        },
      };
    });

    const dataCreate = {
      flow: {
        nodes: updatedNodes,
        edges: updateEdgesToSave(currentEdges),
      },
      metadata: {
        campaign: filterLayout.metadata.campaign,
        id: filterLayout.metadata.id, // !REMOVER ID
        clientId: filterLayout.metadata.clientId,
        description: dataDescription,
        name: dataLabel,
        useHeaderCsv: renderHeaderCsv,
        showNodesNulls,
      },
    };

    return dataCreate;
  };

  const handleChangeHeaderCsv = () => {
    const currentNodes = getNodes();
    const currentEdges = getEdges();
    const { metadata } = dataTemplates;
    const resetNodeData = (node) => ({
      ...node,
      itemPreview: '',
      data: {
        ...node.data,
        hasColumnInCsv: false,
        totalEmptyValues: 0,
      },
    });
    const updatedNodes = currentNodes.map(resetNodeData);

    const dataCreate = {
      flow: {
        nodes: updatedNodes,
        edges: updateEdgesToSave(currentEdges),
      },
      metadata: {
        ...metadata,
        useHeaderCsv: !metadata.useHeaderCsv,
      },
    };
    setSaveFlowMailing(false);
    return setDataTemplates(dataCreate);
  };

  return { handleSaveTemplateLoadList, handleChangeHeaderCsv };
};
