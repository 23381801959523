import { apiData } from '@/services/api';

export async function editRoutingProfile(dataEdit) {
  try {
    const { id } = dataEdit;

    const { data } = await apiData.put(
      `securityProfiles/updateRoutingProfile/${id}`,
      dataEdit
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
}
