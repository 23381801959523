import { apiData } from '../api';

export async function getInDialer(dataStateCall) {
  try {
    const { extension, agentLogin, username } = dataStateCall;
    const { data } = await apiData.post('/agent/getInDialer', {
      extension,
      agentLogin: 'teste',
      username: 'teste',
    });
    return data.data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
