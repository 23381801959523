import styled, { css } from 'styled-components';

export const ContainerFileArea = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: 1fr 40px;
`;

export const FileArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .areaImportCsv {
    width: 100%;
    height: 60%;
  }

  ${(props) =>
    props.hasFile &&
    css`
      height: 0px;
    `}

  label {
    max-width: 100%;
    padding: 1rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.containers.colorCard};
    border: 2px dashed ${(props) => props.theme.colors.active};
    border-radius: 0.3rem;
    transition: all 0.02s;
    user-select: none;
    cursor: pointer;

    .file-card {
      position: relative;
      background-color: ${(props) => props.theme.colors.containers.colorCard};
      padding: 1rem;
      box-shadow: 2px 2px 4px rgba(39, 57, 94, 0.25);
      border-radius: 6px;
      border: 2px solid transparent;
      transition: all 0.1s ease;
      cursor: defaults;
      svg {
        color: ${(props) => props.theme.colors.primary} !important;
      }

      .fileFields {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2rem;

        span {
          font-size: 0.8rem;
          color: ${(props) => props.theme.colors.typography.baseText};
          strong {
            padding-left: 0.4rem;
          }
        }
      }

      .file-icon {
        background-color: ${(props) => props.theme.colors.primary};
        position: absolute;
        right: -12px;
        cursor: pointer;
        display: flex;
        opacity: 0;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        transition: all 0.1s ease;
        svg {
          font-size: 1.6rem;
          color: ${(props) =>
            props.theme.colors.typography.textHeaderTable} !important;
        }
      }

      &:hover {
        border: 1px solid ${(props) => props.theme.colors.primary};
        .file-icon {
          opacity: 1;
        }
      }
    }

    .description-content {
      display: flex;
      height: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      svg {
        font-size: 2.4rem;
        color: ${(props) => props.theme.colors.active};

        @media (max-width: 700px) {
          display: none;
        }
      }

      .description-area-text {
        p {
          font-size: 1.2rem;
          color: ${(props) => props.theme.colors.typography.baseText};

          @media (max-width: 700px) {
            font-size: 1rem;
          }
        }

        span {
          font-size: 0.84rem;
          font-style: italic;
          color: ${(props) => props.theme.colors.typography.baseText};
          opacity: 0.7;

          @media (max-width: 700px) {
            font-size: 0.8rem;
          }
        }
      }

      .description-import-file {
        span {
          color: ${(props) => props.theme.colors.active};
          font-size: 0.75rem;
        }
      }
    }

    &:hover {
      border: 2px dashed ${(props) => props.theme.colors.primary};
      svg {
        color: ${(props) => props.theme.colors.primary};
      }

      .unused_columns_content {
        svg {
          color: ${(props) => props.theme.colors.active};
        }
      }
    }

    ${(props) =>
      props.hasFile &&
      css`
        background-color: transparent;
        cursor: default;
        border: 0px solid ${(props) => props.theme.colors.primary};
        justify-content: flex-start;
        padding: 0;

        &:hover {
          border: 0px solid ${(props) => props.theme.colors.primary};
        }
      `}
  }
`;
