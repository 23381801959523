import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerConfig = styled(motion.div)`
  display: grid;
  grid-template-rows: calc(100vh - 288px) 30px;
  gap: 1rem;

  @media (max-height: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

export const MainConfigForm = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: auto;
  min-height: 300px;
`;

export const ContainerGlobalVars = styled.div`
  overflow: hidden;
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h4 {
    font-size: 0.8rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.typography.baseTitle};
  }
`;

export const FooterConfigForm = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  min-height: 10px;
`;
