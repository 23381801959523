import styled, { css } from 'styled-components';

export const ContainerImportMailing = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 1rem;
`;

export const MainImportMailing = styled.div`
  position: relative;
  height: 100%;
  display: grid;
  gap: 1rem;

  .content-template-select {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  ${(props) =>
    props.imported
      ? css`
          grid-template-rows: 1fr;
          gap: 3rem;
          .headerMain {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 3rem;
          }
        `
      : css`
          grid-template-rows: 1fr;
          .headerMain {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 2rem;
            div {
              button {
                margin-top: -4px !important;
                height: 86%;
                svg {
                  font-size: 1.2rem;
                }
              }
            }
          }
        `}

  /* .headerMain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  } */

  .contentMain {
    display: flex;
    align-items: flex-start;
    flex: 1;
  }

  .load_progress_content {
    width: 100%;
    height: 96%;
    z-index: 1000;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1rem;
  }
`;
