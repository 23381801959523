import { useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { getTrunks } from '@/services/flowBuilder/trunks/getTrunks';
import { dataTrunks } from '@/store/FlowBuilder';

export const useTrunks = () => {
  const { t } = useTranslation();
  const [, setTrunks] = useAtom(dataTrunks);

  const {
    isLoading: loadingTrunks,
    isFetching: isFetchingTrunks,
    refetch: refetchTrunks,
  } = useQuery(
    'trunksFlow',
    async () => {
      return await getTrunks();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setTrunks(data);
      },
      onError: () =>
        errorToast(t('flowBuilder.configs.trunks.msg-error-getTrunks')),
    }
  );

  const isLoading = loadingTrunks;

  return {
    isLoading,
    refetchTrunks,
  };
};
