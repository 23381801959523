import { apiData } from '../api';

export async function forgotPassword(email) {
  try {
    const { data } = await apiData.post('/password/forgot/', {
      email,
    });

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}

export async function changePassword(dataChange) {
  const { idForgot, newPass } = dataChange;
  try {
    const request = await apiData.post(`password/reset?token=${idForgot}`, {
      password: newPass,
    });
    return request.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
