import { apiData } from '../api';

export async function agentAuth(dataAuthSocket) {
  try {
    const { data } = await apiData.post('/agent/agentAuth', {
      extension: dataAuthSocket.extension,
      agentLogin: 'teste',
      password: 'teste',
    });

    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
