import styled from 'styled-components';

export const ContainerTableUfs = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  user-select: none;
  padding-top: 20px;

  table {
    position: relative;
    thead {
      tr {
        th {
          &:first-child {
            width: 16% !important;
          }
          &:nth-child(4) {
            width: 40px !important;
          }
        }
      }
    }
    tbody {
      tr {
        height: 42px;
        td {
          z-index: 1;
          padding: 0 0.8rem;
          text-transform: uppercase;

          &:nth-child(4) {
            width: 40px !important;
          }
        }
      }
    }
  }
`;
