import styled from 'styled-components';

export const ContainerItemConditional = styled.div`
  background: ${(props) => props.theme.colors.bases.primary};
  box-shadow: 6px 4px 5px -3px rgba(0, 0, 0, 0.24);

  width: 100%;
  padding: 4px;
  border-radius: 3px;
  display: grid;
  grid-template-columns: 1fr 40px;
  .description {
    display: flex;
    align-items: center;
    gap: 4px;
    svg {
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
    }
    span {
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
      font-weight: 600;
      padding-top: 2px;
    }
  }

  .panel-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 4px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;

      svg {
        font-size: 0.9rem;
        color: ${(props) => props.theme.colors.bases.baseWhite};
      }
    }
  }
`;
