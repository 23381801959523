import { useState } from 'react';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { FormNewList } from './styled';
import { useSchedulesList } from '@/hooks/dialControl/Schedules/useSchedulesList';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { newScheduleList } from '@/store/ScheduleStore/SchedulerList';
import { dataDispositionsDialing } from '@/store/DialingStore';
import { DragTransferListComponent } from '../../../components/DragTransferList';
import { Alert } from '@mui/material';
import { format } from 'date-fns';

export const TransferDetails = ({ controlStage }) => {
  const { t } = useTranslation();

  const [newSchedule, setNewSchedule] = useAtom(newScheduleList);
  const { mutateCreateSchedule, loadingCreateSchedule } = useSchedulesList();
  const [listsDispositions] = useAtom(dataDispositionsDialing);
  const [dataCodList, setDataCodList] = useState([]);

  const renderTxt = (word) =>
    t(`dialControl.schedulesList.createSchedule.transferDetails.${word}`);

  const renderDispositions = (listDispositions, position) => {
    if (listDispositions[position]) {
      const [disposition] = listDispositions[position].split('-');
      return disposition ? disposition.trim() : null;
    }
    return null;
  };

  const formatTime = (timestamp) => {
    return format(new Date(timestamp), 'HH:mm:00');
  };

  let renderEndWeekDay = (weekDays) =>
    weekDays.reduce((a, b) => Math.max(a, b));

  const handleNextStage = () => {
    const dataCreate = {
      executado_dt: null,
      encerrado_dt: null,
      dia_semana: newSchedule.dayOfTheWeek,
      horario: formatTime(newSchedule.initTime),
      dia_semana_termino: renderEndWeekDay(newSchedule.dayOfTheWeek),
      horario_termino: formatTime(newSchedule.endTime),
      fila: Number(newSchedule.queue),
      posicao: Number(newSchedule.position),
      id_tabela_lista: Number(newSchedule.list),
      cod_lig1: renderDispositions(dataCodList, 0),
      cod_lig2: renderDispositions(dataCodList, 1),
      cod_lig3: renderDispositions(dataCodList, 2),
      cod_lig4: renderDispositions(dataCodList, 3),
      cod_lig5: renderDispositions(dataCodList, 4),
      cod_lig6: renderDispositions(dataCodList, 5),
      cod_lig7: renderDispositions(dataCodList, 6),
      cod_lig8: renderDispositions(dataCodList, 7),
      cod_lig9: renderDispositions(dataCodList, 8),
      cod_lig10: renderDispositions(dataCodList, 9),
      fone_pos: Number(newSchedule.positionPhone),
    };

    mutateCreateSchedule(dataCreate, {
      onSuccess: () => {
        setNewSchedule(dataCreate);
        controlStage(3);
      },
    });
  };

  const handlePreviousStage = () => {
    controlStage(1);
  };

  function removeDuplicateIds(arr) {
    const ids = new Set();
    return arr.filter((item) => {
      if (ids.has(item.cod_lig)) {
        return false;
      } else {
        ids.add(item.cod_lig);
        return true;
      }
    });
  }

  const isLoading = loadingCreateSchedule;
  const isDisabled = dataCodList.length === 0;

  return (
    <FormNewList initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <div className='main'>
        <DescriptionInformation textDescription={renderTxt('description')} />
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: dataCodList.length === 0 ? 1 : 0, y: 0 }}
        >
          <Alert severity='error'>
            {renderTxt('msg-erro-no-dispositions')}
          </Alert>
        </motion.div>
        <DragTransferListComponent
          codeList={removeDuplicateIds(listsDispositions)}
          changeDataCodeList={setDataCodList}
        />
      </div>

      <div className='footer'>
        <Button typeText variantstyle='secondary' onClick={handlePreviousStage}>
          {renderTxt('btn-cancel')}
        </Button>
        <Button
          typeText
          type='button'
          onClick={handleNextStage}
          disabled={isDisabled}
        >
          {renderTxt('btn-next')}
        </Button>
      </div>
    </FormNewList>
  );
};
