import { useQuery, useMutation } from 'react-query';
import { useState } from 'react';
import { getJourneys } from '@/services/journey/getJourneys';
import { errorToast } from '@/components/FormComponents/Toast';
import { getCampaignsByClient } from '@/services/dialControl/campaigns/getCampaignsByClient';

export const useJourney = () => {
  const [dataList, setDataList] = useState({});
  const [dataOptionsCampaigns, setDataOptionsCampaigns] = useState([]);

  const { isLoading: loadingOptionsCampaigns } = useQuery(
    'listOptionsCampaigns',
    async () => {
      return await getCampaignsByClient();
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setDataOptionsCampaigns(data),
      onError: () => errorToast('Error'),
    }
  );

  const { isLoading, isFetching } = useQuery(
    'listHome',
    async () => {
      return await getJourneys();
    },
    {
      retry: false,
      onSuccess: (data) => setDataList(data),
      onError: () => errorToast('Error'),
    }
  );

  const { mutate: getListMutate, isLoading: loadingMutate } = useMutation(
    getJourneys,
    {
      onSuccess: (data) => setDataList(data),
      onError: () => errorToast('Error'),
    }
  );

  const dataCampaigns = {
    dataOptionsCampaigns,
    loadingOptionsCampaigns,
  };

  const dataListTree = {
    dataList,
    isLoading,
    isFetching,
    getListMutate,
    loadingMutate,
  };

  return {
    dataCampaigns,
    dataListTree,
  };
};
