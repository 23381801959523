import { motion } from 'framer-motion';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import useWindowDimensions from '@/hooks/useWidth';
import { TablePagination } from '@/components/Tables/TablePagination';
import { ContainerTable } from './styled';
import { MdClose, MdEdit } from 'react-icons/md';
import { DialogComponent } from '@/components/DialogComponent';
import { RemoveCallOperator } from './RemoveCallOperator';
import { useAtom } from 'jotai';
import { selectedCallOperators } from '@/store/CallOperator';
import { useCallOperators } from '@/hooks/CallOperators';
import { successToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import { useCallOperatorsPermissions } from '../../../hooks/useCallOperatorsPermissions';

export const TableCallOperator = ({ changeStage, dataTable }) => {
  const { t } = useTranslation();
  const { permissionsCreate, permissionsDelete, permissionsEdit } =
    useCallOperatorsPermissions();
  const renderText = (spell) => {
    return t(`callOperators.list.table.${spell}`);
  };

  const tableCallOperatorRef = useRef(null);
  const { height, width } = useWindowDimensions();
  const { mutateDeleteCallOperators, loadingDeleteCallOperators } =
    useCallOperators();

  const [heightTable, setHeightTable] = useState(0);
  const [dialogRemoveCallOperator, setDialogRemoveCallOperator] =
    useState(false);

  const [selectedCallOperator, setSelectedCallOperator] = useAtom(
    selectedCallOperators
  );

  const dataCallOperators = useMemo(() => dataTable, [dataTable]);

  useLayoutEffect(() => {
    if (tableCallOperatorRef) {
      const tableMaxWidth = tableCallOperatorRef.current.offsetHeight;
      const cellMinWidth = width < 500 ? 200 : 54;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, width]);

  const handleRemoveItem = (dataRemove) => {
    setSelectedCallOperator(dataRemove);
    setDialogRemoveCallOperator(true);
  };

  const handleCloseDialogRemoveCallOperator = () => {
    setSelectedCallOperator({});
    setDialogRemoveCallOperator(false);
  };

  const handleEditItem = (dataSelected) => {
    setSelectedCallOperator(dataSelected);
    changeStage(2);
  };

  const deleteItemSelected = () => {
    mutateDeleteCallOperators(selectedCallOperator.cod_operadora, {
      onSuccess: () => {
        successToast(renderText('msg-success-removed'));
        handleCloseDialogRemoveCallOperator();
      },
    });
  };

  const columnsCallOperators = [
    {
      Header: renderText('th-codOperator'),
      accessor: 'cod_operadora',
    },
    {
      Header: renderText('th-nome'),
      accessor: 'nome_operadora',
    },
    {
      Header: renderText('th-tarifaFixo'),
      accessor: 'tarifa_local_fixo',
    },
    {
      Header: renderText('th-tarifaLdn'),
      accessor: 'tarifa_ldn_fixo',
    },
    {
      Header: renderText('th-tarifaVc1'),
      accessor: 'tarifa_vc1',
    },
    {
      Header: renderText('th-tarifaVc2'),
      accessor: 'tarifa_vc2',
    },
    {
      Header: renderText('th-tarifaVc3'),
      accessor: 'tarifa_vc3',
    },
    {
      Header: renderText('th-cadMinuto'),
      accessor: 'cadencia_min',
    },
    {
      Header: renderText('th-cadencia'),
      accessor: 'cadencia',
    },
    {
      Header: renderText('th-segAnatel'),
      accessor: 'anatel_segundos',
    },
    {
      Header: renderText('th-action'),
      Cell: ({ row }) => (
        <div className='editRow'>
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => handleRemoveItem(row.original)}
            title={renderText('btn-remove')}
            disabled={!permissionsDelete || !permissionsCreate}
          >
            <MdClose />
          </motion.button>
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => handleEditItem(row.original)}
            title={renderText('btn-edit')}
            disabled={!permissionsEdit || !permissionsCreate}
          >
            <MdEdit />
          </motion.button>
        </div>
      ),
    },
  ];

  return (
    <ContainerTable ref={tableCallOperatorRef}>
      <TablePagination
        dataColumns={columnsCallOperators}
        dataTable={dataCallOperators}
        textNoData={renderText('no-data')}
        rowsPerPageDisplay={heightTable}
      />

      <DialogComponent
        open={dialogRemoveCallOperator}
        onClose={handleCloseDialogRemoveCallOperator}
        fullWidth={true}
      >
        <RemoveCallOperator
          closeDialog={handleCloseDialogRemoveCallOperator}
          handleDeleteItem={deleteItemSelected}
          loadingDeleteOperator={loadingDeleteCallOperators}
        />
      </DialogComponent>
    </ContainerTable>
  );
};
