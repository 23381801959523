import { useAtom } from 'jotai';
import { v4 as uuid } from 'uuid';
import { user } from '@/store/AuthenticatorStore';
import {
  dataMailing,
  dataPreviewImport,
  dataTemplateMailing,
  hasSavedTemplateMailing,
  listTemplateMetadata,
  savedTemplateMailing,
  selectedTemplate,
  useHeaderCsv,
} from '@/store/LoadList';

export const useRenderNodesMailing = () => {
  const [previewList] = useAtom(dataPreviewImport);
  const [dataTemplates, setDataTemplates] = useAtom(dataTemplateMailing);
  const [currentUser] = useAtom(user);
  const [layoutSelected, setSelectedLayout] = useAtom(selectedTemplate);
  const [, setHasSavedTemplate] = useAtom(hasSavedTemplateMailing);
  const [mailingData] = useAtom(dataMailing);
  const [renderHeaderCsv, setRenderHeaderCsv] = useAtom(useHeaderCsv);
  const [dataSavedTemplate] = useAtom(savedTemplateMailing);

  const layoutRender = [
    {
      name: 'nome_cliente',
      hidden: false,
    },
    {
      name: 'cpf',
      hidden: false,
    },
    {
      name: 'atraso',
      hidden: false,
    },
    {
      name: 'uf',
      hidden: false,
    },
    {
      name: 'contrato',
      hidden: false,
    },
    {
      name: 'valor',
      hidden: false,
    },
    {
      name: 'ddd1',
      hidden: false,
    },
    {
      name: 'fone1',
      hidden: false,
    },
    {
      name: 'ddd2',
      hidden: true,
    },
    {
      name: 'fone2',
      hidden: true,
    },
    {
      name: 'ddd3',
      hidden: true,
    },
    {
      name: 'fone3',
      hidden: true,
    },
    {
      name: 'ddd4',
      hidden: true,
    },
    {
      name: 'fone4',
      hidden: true,
    },
    {
      name: 'ddd5',
      hidden: true,
    },
    {
      name: 'fone5',
      hidden: true,
    },
    {
      name: 'ddd6',
      hidden: true,
    },
    {
      name: 'fone6',
      hidden: true,
    },
    {
      name: 'ddd7',
      hidden: true,
    },
    {
      name: 'fone7',
      hidden: true,
    },
    {
      name: 'ddd8',
      hidden: true,
    },
    {
      name: 'fone8',
      hidden: true,
    },
    {
      name: 'ddd9',
      hidden: true,
    },
    {
      name: 'fone9',
      hidden: true,
    },
    {
      name: 'ddd10',
      hidden: true,
    },
    {
      name: 'fone10',
      hidden: true,
    },
    {
      name: 'coringa_1',
      hidden: false,
    },
    {
      name: 'coringa_2',
      hidden: true,
    },
    {
      name: 'coringa_3',
      hidden: true,
    },
    {
      name: 'coringa_4',
      hidden: true,
    },
    {
      name: 'coringa_5',
      hidden: true,
    },
    {
      name: 'coringa_6',
      hidden: true,
    },
    {
      name: 'coringa_7',
      hidden: true,
    },
    {
      name: 'coringa_8',
      hidden: true,
    },
    {
      name: 'coringa_9',
      hidden: true,
    },
    {
      name: 'coringa_10',
      hidden: true,
    },
    {
      name: 'cod_link_char',
      hidden: false,
    },
  ];

  const [metadataTemplates, setMetadataTemplates] =
    useAtom(listTemplateMetadata);

  const calculateBounds = (nodes) => {
    const nodePositions = nodes.map((node) => node.position);
    const minX = Math.min(...nodePositions.map((pos) => pos.x)) - 100;
    const maxX = Math.max(...nodePositions.map((pos) => pos.x)) + 100;
    const minY = Math.min(...nodePositions.map((pos) => pos.y)) - 100;
    const maxY = Math.max(...nodePositions.map((pos) => pos.y)) + 100;

    return {
      xMin: minX + 100,
      xMax: maxX + 100,
      yMin: minY,
      yMax: maxY,
    };
  };

  const renderRelationshipNodes = (idNode, nodes, edges) => {
    const filterEdgeTarget = edges.filter(
      (edge) => edge.target === idNode && edge.type !== 'errorEdge'
    );

    if (filterEdgeTarget.length === 0) {
      return null;
    }
    const filterNodeFromCsvName = nodes.filter(
      (node) => node.id === filterEdgeTarget[0].source
    );
    if (filterNodeFromCsvName.length === 0) {
      return null;
    }

    return filterNodeFromCsvName[0].data.label;
  };

  const renderRelationshipColumn = (idNode, nodes, edges) => {
    const filterEdgeTarget = edges.filter((edge) => edge.target === idNode);
    if (filterEdgeTarget.length === 0) {
      return null;
    }
    const filterNodeFromCsvName = nodes.filter(
      (node) => node.id === filterEdgeTarget[0].source
    );
    if (filterNodeFromCsvName.length === 0) {
      return null;
    }
    return filterNodeFromCsvName[0].data.column;
  };

  const createNode = (item, index, currentYPosition) => {
    const nodeId = uuid();

    return {
      id: `id_file_${nodeId}`,
      type: 'nodeFromCsv',
      data: {
        column: index,
      },
      position: { x: 0, y: currentYPosition },
    };
  };

  const checkDisabledNext = (edges) => {
    if (edges.length === 0 || !edges) {
      return true;
    }

    const filterEdges = edges.filter(
      (edge) => edge.type === 'customEdge' || edge.type === 'systemEdge'
    );

    if (filterEdges.length === 0) {
      return true;
    }

    return false;
  };

  const renderLayoutNodes = (layoutRender, useLayout) => {
    if (previewList.length === 0) return [];
    let currentYPosition = 5;

    const fileNodes = previewList[0].map((item, index) => {
      const node = createNode(item, index, currentYPosition);
      currentYPosition += 60;
      return node;
    });

    // ? RENDER NEW SCHEMA
    if (!useLayout && !dataTemplates) {
      const templatedNodes = layoutRender.map((item, index) => ({
        id: `id_${index}`,
        type: 'nodeMailing',
        data: {
          label: item.name,
          column: index,
          filters: [],
          hasColumnInCsv: false,
        },
        position: { x: 450, y: 5 + index * 60 },
        hidden: item.hidden,
      }));

      const dataNodes = [...fileNodes, ...templatedNodes];

      const newSchemaFlow = {
        metadata: {
          id: uuid(),
          name: mailingData.nameFileCsv,
          campaign: mailingData.campaign,
          clientId: currentUser.client_id,
          description: mailingData.description,
          createdAt: new Date().toISOString(),
          useHeaderCsv: renderHeaderCsv,
          showNodesNulls: false,
          hasImported: false,
        },
        flow: {
          nodes: dataNodes,
          edges: [],
        },
      };

      const hasExistIdTemplate = metadataTemplates.filter(
        (template) => template.id === newSchemaFlow.metadata.id
      );

      if (hasExistIdTemplate.length > 0) {
        return;
      }
      setMetadataTemplates((previous) => [newSchemaFlow.metadata, ...previous]);
      setHasSavedTemplate(false);
      setSelectedLayout(newSchemaFlow.metadata.id);
      setDataTemplates(newSchemaFlow);
      return;
    }

    // ! RENDER TEMPLATE

    if (dataSavedTemplate) {
      setRenderHeaderCsv(dataSavedTemplate.metadata.useHeaderCsv);

      const checkValuesFromCsv = dataSavedTemplate.flow.nodes
        .map((node) => {
          return {
            ...node,
            data: {
              ...node.data,
            },
          };
        })
        .filter((node) => node.type !== 'nodeFromCsv');

      const renderColumnsNodesFromSavedTemplate =
        dataSavedTemplate.flow.nodes.filter(
          (node) => node.type === 'nodeFromCsv'
        );

      const checkHasNodeInSavedTemplate = () => {
        if (fileNodes.length < renderColumnsNodesFromSavedTemplate.length) {
          return renderColumnsNodesFromSavedTemplate.map((node) => {
            const filterFileColumn = fileNodes.filter(
              (nodeFromFile) => nodeFromFile.data.column === node.data.column
            );

            const hasColumnFromSaved = filterFileColumn.length > 0;

            if (hasColumnFromSaved) {
              return {
                ...node,
                data: {
                  ...node.data,
                  hasColumnFromSave: true,
                },
              };
            }
            return {
              ...node,
              data: {
                ...node.data,
                hasColumnFromSave: false,
              },
            };
          });
        }
        return fileNodes.map((node) => {
          const filterSavedColumn = renderColumnsNodesFromSavedTemplate.filter(
            (nodeFromSaved) => nodeFromSaved.data.column === node.data.column
          );
          if (filterSavedColumn.length > 0) {
            return {
              ...filterSavedColumn[0],
              data: {
                ...filterSavedColumn[0].data,
                hasColumnFromSave: true,
              },
            };
          }
          return {
            ...node,
            data: {
              ...node.data,
              hasColumnFromSave: false,
            },
          };
        });
      };

      const dataNodesWithSavedTemplate = [
        ...checkHasNodeInSavedTemplate(),
        ...checkValuesFromCsv,
      ];

      const dataFlowSelected = {
        ...dataSavedTemplate,
        metadata: {
          ...dataSavedTemplate.metadata,
        },
        flow: {
          ...dataSavedTemplate.flow,
          nodes: dataNodesWithSavedTemplate,
          edges: dataSavedTemplate.flow.edges,
        },
      };

      setHasSavedTemplate(true);
      setDataTemplates(dataFlowSelected);

      return;
    }
  };

  const renderPreviewNodes = async (worker, setNodes, setEdges) => {
    if (layoutSelected === '0' || !dataTemplates || dataTemplates.length === 0)
      return;

    const { nodes, edges } = dataTemplates.flow;
    const { useHeaderCsv } = dataTemplates.metadata;
    setRenderHeaderCsv(useHeaderCsv);

    await worker.postMessage({
      type: 'renderLayout',
      nodes,
      edges,
      useHeaderCsv,
      previewList,
    });

    worker.onmessage = function (e) {
      const { type, result } = e.data;
      if (type === 'renderSelectedLayout') {
        const { nodes, edges } = result;
        setNodes(nodes);
        setEdges(edges);
      }
    };
  };

  return {
    layoutRender,
    renderLayoutNodes,
    calculateBounds,
    renderRelationshipNodes,
    renderRelationshipColumn,
    renderPreviewNodes,
    checkDisabledNext,
  };
};
