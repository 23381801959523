import { getOperators } from '@/services/dialControl/operatorControl/getOperators';
import { getFlowTemplates } from '@/services/flowBuilder/getFlowTemplates';
import { user } from '@/store/AuthenticatorStore';
import { dataFlowOptions, dataQueueOperators } from '@/store/DialingStore';
import { useAtom } from 'jotai';
import { useQuery } from 'react-query';

export const useQueueManagement = () => {
  const [currentUser] = useAtom(user);
  const [, setListFlows] = useAtom(dataFlowOptions);
  const [, setListOperators] = useAtom(dataQueueOperators);

  const { isLoading: loadingFlowQueue } = useQuery(
    'dataFlowQueue',
    async ({ signal }) => {
      return await getFlowTemplates(signal, currentUser.client_id);
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const publishedFlows = data.filter((item) => item.published);
        setListFlows(publishedFlows);
      },
    }
  );

  const { isLoading: loadingDataOperators } = useQuery(
    'dataOperatorsQueue',
    async ({ signal }) => {
      return await getOperators(signal);
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setListOperators(data),
    }
  );

  return { loadingFlowQueue, loadingDataOperators };
};
