import { atom } from 'jotai';

export const dataRoutingProfile = atom([]);
export const stateRenderRoutingProfiles = atom({ current: 0, previous: 0 });
export const isShowEditQueue = atom(false);
export const dataQueuesRoutingProfile = atom([]);
export const dataScreensRoutingProfile = atom([]);
export const dataDefaultRoutingProfile = atom([]);
export const dataByClientRoutingProfile = atom([]);

export const currentStageEditFlow = atom(0);
