import { InputComponent } from '@/components/FormComponents/InputComponent';
import styled, { css } from 'styled-components';

export const ContainerPanelSearch = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 100;
  padding-top: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  .contentFieldSearch {
    width: 50%;
    padding: 0rem;
    background-color: ${(props) =>
      props.theme.colors.containers.baseContainerFlow};
  }
`;
export const ButtonControlSearch = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  svg {
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
`;

export const InputSearchFlowNodes = styled(InputComponent)`
  span {
    padding-top: 2px;
  }

  .MuiInputBase-input {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    ${(props) =>
      props.errors
        ? css`
            border-color: ${(props) =>
              props.theme.colors.defaultColors.orange} !important;
          `
        : css`
            border-color: ${(props) =>
              props.theme.colors.typography.baseText}AA;
          `}
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    ${(props) =>
      props.errors
        ? css`
            border-color: ${(props) =>
              props.theme.colors.defaultColors.orange} !important;
          `
        : css`
            border-color: ${(props) =>
              props.theme.colors.typography.baseText}AA;
          `}
  }

  border-radius: 5px;
  .total-text-search {
    color: ${(props) => props.theme.colors.typography.baseText}AA;
    font-size: 0.85rem;
    padding-top: 1px;
  }

  .icon-voicce {
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
