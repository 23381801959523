import { HiSortDescending, HiSortAscending } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { useLineMonitoring } from '@/hooks/monitor/useLineMonitoring';
import { ContainerHeaderMonitor } from './styled';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const HeaderMonitoring = ({ controlStates }) => {
  const { t } = useTranslation();
  const { dataLinesMonitoring } = useLineMonitoring();

  const dataOptionQueue = dataLinesMonitoring?.map((item) => item.fila) ?? [];

  return (
    <ContainerHeaderMonitor>
      <SelectComponent
        labelSelect={t('summaryMonitor.label-queues')}
        value={controlStates.filterByQueue}
        onChange={(event) => controlStates.setFilterByQueue(event.target.value)}
      >
        <ItemSelect value={'0'}>
          {t('summaryMonitor.option-allQueues')}
        </ItemSelect>
        {dataOptionQueue.length > 0 &&
          dataOptionQueue.map((item, i) => {
            return (
              <ItemSelect key={i} value={item}>
                {item}
              </ItemSelect>
            );
          })}
      </SelectComponent>
      <div className='second_part_control'>
        <SelectComponent
          labelSelect={t('summaryMonitor.label-status')}
          value={controlStates.filterByStatus}
          onChange={(event) =>
            controlStates.setFilterByStatus(event.target.value)
          }
        >
          <ItemSelect value={'0'}>
            {t('summaryMonitor.option-allStatus')}
          </ItemSelect>
          <ItemSelect value={'1'}>
            {t('summaryMonitor.option-discing')}
          </ItemSelect>
          <ItemSelect value={'2'}>{t('summaryMonitor.option-free')}</ItemSelect>
          <ItemSelect value={'3'}>
            {t('summaryMonitor.option-paused')}
          </ItemSelect>
        </SelectComponent>

        <Button
          onClick={() => controlStates.handleOrderQueue()}
          title={t('summaryMonitor.aria-btnOrder')}
        >
          {controlStates.sortByQueue ? (
            <HiSortAscending />
          ) : (
            <HiSortDescending />
          )}
        </Button>
      </div>
    </ContainerHeaderMonitor>
  );
};
