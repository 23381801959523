import { atom } from 'jotai';

export const isAllowedMicrophone = atom(false);
export const isRegisteredSocket = atom(false);
export const isLoadingComponent = atom(true);

export const currentStateAgentsDial = atom(null);
export const dataCallInformation = atom({});
export const dataTimeToTab = atom(null);

export const pauseDialog = atom(false);

export const playerAudioCall = atom(null);
export const sessionStateAgent = atom(null);
export const newMessagesFromSip = atom(null);
export const exitDialler = atom(false);

export const currentUserAgent = atom(null);

export const dataCallClient = atom({});
export const initCallState = atom('');
export const durationCallState = atom('');

export const dataScheduleSelected = atom(null);
