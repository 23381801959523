import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormNewList = styled(motion.form)`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: 1fr;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    height: 100%;

    justify-content: space-between;
    min-height: 400px;
  }

  .main {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-star;
    gap: 2rem;
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
`;

export const ContainerTransferList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 300px;
`;
