export async function getNlus() {
  try {
    const data = [
      {
        id: '60a31a00-f438-4a2c-afd0-2ef968b78d96',
        name: 'localiza_pessoa',
        type: 'localiza_pessoa',
        data: [
          'localizacao_positiva',
          'nao_quer_contato',
          'duvida',
          'nao_existe',
          'nao_se_encontra',
          'localizacao_negativa',
          'conhecido_sem_contato',
          'faleceu',
          'palavra_ofensiva',
          'fora_de_escopo',
        ],
      },
      {
        id: '73c74207-1e69-46bf-8caf-039bc6509596',
        name: 'voicce_negocia',
        type: 'voicce_negocia',
        data: [
          'positivo',
          'salario',
          'negociou',
          'negativo',
          'desempregado',
          'doente',
          'alega_pagamento',
          'maior_prazo',
          'nao_reconhece',
          'falar_com_humano',
          'fora_de_escopo',
          'repetir',
        ],
      },
      {
        id: '0613da4a-a5d8-44ad-900c-8c1ade65e994',
        name: 'validar_cpf',
        type: 'validar_cpf',
        data: [
          'cpf_capturado',
          'precisa_buscar',
          'nao_sabe',
          'duvida',
          'out_of_scope',
        ],
      },
      {
        id: '74471e27-1fcf-404e-92b1-e35f31717608',
        name: 'conhece',
        type: 'conhece',
        data: [
          'positivo',
          'nao_existe',
          'faleceu',
          'conhecido_sem_contato',
          'fora_de_escopo',
        ],
      },
      {
        id: '8fb62cb6-dfa0-4bf5-8bb5-243623064600',
        name: 'data_pagamento',
        type: 'data_pagamento',
        data: ['maior_prazo', 'fora_de_escopo'],
      },
      {
        id: '40963ef5-5412-4946-ae48-7b6f04094693',
        name: 'voicce_sim_nao',
        type: 'voicce_sim_nao',
        data: ['positivo', 'negativo', 'out_of_scope'],
      },

      {
        id: 'eab2bec4-0607-477d-a564-17836b58c138',
        name: 'voicce_preventivo',
        type: 'voicce_preventivo',
        data: [
          'alega_pagamento',
          'segunda_via',
          'negativo',
          'positivo',
          'duvida',
          'out_of_scope',
        ],
      },
      {
        id: '29dcb166-3c21-4db3-820d-30f7c800035e',
        name: 'voicce_segunda_via',
        type: 'voicce_segunda_via',
        data: ['alega_pagamento', 'segunda_via', 'negativo', 'out_of_scope'],
      },
      {
        id: '02989fc7-540d-47bf-bf1f-9ebb5f97a785',
        name: 'voicce_coleta_celular',
        type: 'voicce_coleta_celular',
        data: [
          'celular_capturado',
          'precisa_buscar',
          'nao_sabe',
          'out_of_scope',
        ],
      },
      {
        id: 'bbed4188-61b5-4f21-81ba-f02ef14ad712',
        name: 'voicce_captura_nome',
        type: 'voicce_captura_nome',
        data: ['nome_capturado', 'out_of_scope'],
      },
      {
        id: '374ed188-aee6-428d-918a-9775718ed456',
        name: 'voicce_captura_persona',
        type: 'voicce_captura_persona',
        data: ['persona_masc', 'persona_fem', 'out_of_scope'],
      },
      {
        id: '7d3b93e8-5f5c-4b02-a067-94347b506e13',
        name: 'voicce_qtde_parcelas',
        type: 'voicce_qtde_parcelas',
        data: ['parcelamento', 'falar_com_humano', 'negativo', 'out_of_scope'],
      },
      {
        id: '1db158ce-cfc5-4485-a08d-541a689cb57e',
        name: 'voicce_forma_pagamento',
        type: 'voicce_forma_pagamento',
        data: [
          'cartao',
          'boleto',
          'falar_com_humano',
          'negativo',
          'out_of_scope',
        ],
      },
      {
        id: '2b958e02-3b7d-4109-843f-9dd8fd940acd',
        name: 'voicce_tipo_pagamento',
        type: 'voicce_tipo_pagamento',
        data: [
          'pix',
          'cartao',
          'boleto',
          'nenhum',
          'falar_com_humano',
          'fora_de_escopo',
        ],
      },
    ];

    return new Promise((resolve) => {
      setTimeout(() => resolve(data), 500);
    });
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
