import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { BiPlus } from 'react-icons/bi';
import { FaRegTrashAlt } from 'react-icons/fa';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { CheckBoxFlow } from '@/components/FormComponents/CheckboxComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { errorFieldsDataApi, selectedNode } from '@/store/FlowBuilder';
import { ItemsFieldsHeader } from '../styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import { FlexContainer } from '@/components/layout/FlexContainer/styled';

export const ParameterField = ({
  parametersInputs,
  setParametersInputs,
  setFocusedArea,
  listVariables,
}) => {
  const { t } = useTranslation();

  const [selected] = useAtom(selectedNode);
  const [errorFields, setErrorFields] = useAtom(errorFieldsDataApi);
  const { id } = selected;

  const handleCheckboxChange = (id) => {
    setParametersInputs(
      parametersInputs.map((input) => {
        if (input.idSelect === id) {
          return { ...input, disabled: !input.disabled };
        }
        return input;
      })
    );
  };

  const filterUsedVariablesInNode = listVariables.filter(
    (item) => item.referNode !== id
  );

  const addParametersPair = () => {
    setParametersInputs([
      ...parametersInputs,
      { name: '', value: '0', idSelect: uuid(), disabled: false },
    ]);
  };

  const removeParametersPair = (index) => {
    const newKeyValuePairs = [...parametersInputs];
    newKeyValuePairs.splice(index, 1);
    setParametersInputs(newKeyValuePairs);
  };

  // AQUI ALTERA SELECT PARAMS
  const handleParametersInputsChange = (index, event, idField) => {
    const newKeyValuePairs = [...parametersInputs];
    newKeyValuePairs[index]['value'] = event.target.value;
    setParametersInputs(newKeyValuePairs);
  };

  const handleNameParametersInputsChange = (index, event, idField) => {
    const newValue = event.target.value;
    const newKeyValuePairs = [...parametersInputs];
    newKeyValuePairs[index]['name'] = newValue;
    setParametersInputs(newKeyValuePairs);
  };

  const label = { inputProps: { 'aria-label': 'Params field' } };

  const renderParametersInputs = () => {
    return parametersInputs.map((pair, index) => (
      <motion.div
        className='item-content'
        key={index}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
      >
        <FlexContainer gap={'0.6rem'} width={'100%'} direction={'column'}>
          <InputComponent
            label={t('flowBuilder.flow.nodes.dataApi.menu.label-name')}
            size={'small'}
            value={pair.name}
            onChange={(event) =>
              handleNameParametersInputsChange(index, event, pair.idSelect)
            }
            error={errorFields.includes(pair.idSelect)}
            disabled={pair.disabled}
            onFocus={() => setFocusedArea(true)}
            onBlur={() => setFocusedArea(false)}
          />
          <SelectComponent
            value={pair.value}
            onChange={(event) => handleParametersInputsChange(index, event)}
            disabled={pair.disabled}
            onFocus={() => setFocusedArea(true)}
            onBlur={() => setFocusedArea(false)}
          >
            <ItemSelect value={'0'} disabled>
              {t('flowBuilder.flow.nodes.dataApi.menu.option-variable')}
            </ItemSelect>
            {filterUsedVariablesInNode.length > 0 &&
              filterUsedVariablesInNode.map((item) => {
                return (
                  <ItemSelect key={item.id} value={item.id}>
                    {item.name}
                  </ItemSelect>
                );
              })}
          </SelectComponent>
        </FlexContainer>
        <CheckBoxFlow
          checked={!pair.disabled}
          onChange={() => handleCheckboxChange(pair.idSelect)}
          {...label}
          size='small'
        />
        <Button onClick={() => removeParametersPair(index)} type='button'>
          <FaRegTrashAlt />
        </Button>
      </motion.div>
    ));
  };

  return (
    <ItemsFieldsHeader>
      <div className='header_item'>
        <h4>{t('flowBuilder.flow.nodes.dataApi.menu.title-parameters')}</h4>
        <Button
          onClick={addParametersPair}
          type='button'
          title={t('flowBuilder.flow.nodes.dataApi.menu.title-btn-add')}
        >
          <BiPlus size={20} />
        </Button>
      </div>
      <div className='content_item'>{renderParametersInputs()}</div>
    </ItemsFieldsHeader>
  );
};
