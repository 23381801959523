import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import {
  ButtonAudio,
  ContainerTimersAudio,
  ContainerWavesurfer,
  PlayerTimes,
} from './styled';
import {
  IoPauseCircleOutline,
  IoPlayCircleOutline,
  IoReloadCircleOutline,
  IoStopCircleOutline,
} from 'react-icons/io5';
import { Stack } from '@mui/system';
import { Box, Slider } from '@mui/material';
import { useTheme } from 'styled-components';
import {
  BsFillVolumeMuteFill,
  BsFillVolumeUpFill,
  BsVolumeDownFill,
} from 'react-icons/bs';
import { HiDocumentDownload } from 'react-icons/hi';
import { ImageComponent } from '@/components/layout/ImageComponent';

import LogoSidebarImg from '@/assets/imgs/sm_voiccelabs_black.webp';
import LogoWhiteSidebarImg from '@/assets/imgs/sm_voiccelabs_white.webp';

const WaveRecordings = ({ audio }) => {
  const theme = useTheme();
  const containerRef = useRef();
  const waveSurferRef = useRef({
    isPlaying: () => false,
  });
  const volumeInputAudio = useRef();
  const [isPlaying, toggleIsPlaying] = useState(false);
  const [showPanel, toggleShowPanel] = useState(false);
  const [volumeAudio, setVolumeAudio] = useState(75);
  const [audioDuration, setAudioDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const handleChange = useCallback(
    (event, newValue) => {
      setVolumeAudio(newValue);
    },
    [volumeAudio]
  );

  useEffect(() => {
    const linGrad = document
      .createElement('canvas')
      .getContext('2d')
      .createLinearGradient(0, 0, 1000, 128);
    linGrad.addColorStop(0, '#9C3EFF');
    linGrad.addColorStop(0.2, '#DA4ECA');
    linGrad.addColorStop(0.4, '#FF6098');
    linGrad.addColorStop(1, '#FF8150');

    const linGradProgress = document
      .createElement('canvas')
      .getContext('2d')
      .createLinearGradient(0, 0, 1000, 128);
    linGradProgress.addColorStop(0, '#4c48ff');
    linGradProgress.addColorStop(0.5, ' #43ff86');
    linGradProgress.addColorStop(1, '#DA4ECA');

    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      waveColor: linGrad,
      height: 40,
      progressColor: linGradProgress,
      cursorColor: theme.colors.primary,
      barWidth: 3,
      barGap: 1,
      barRadius: 2,
    });

    waveSurfer.load(audio);

    waveSurfer.on('ready', () => {
      waveSurferRef.current = waveSurfer;
      toggleIsPlaying(true);
      toggleShowPanel(true);
      setAudioDuration(waveSurfer.getDuration());
      waveSurfer.play();
    });

    waveSurfer.on('audioprocess', () => {
      setCurrentTime(waveSurfer.getCurrentTime());
    });

    waveSurfer.on('finish', () => {
      toggleIsPlaying(false);
      waveSurfer.seekTo(0);
    });
    return () => {
      waveSurfer.destroy();
    };
  }, []);

  useEffect(() => {
    const checkRef = Object.keys(waveSurferRef.current).length > 2;
    if (checkRef) {
      waveSurferRef.current.setVolume(volumeAudio / 100);
    }
  }, [volumeAudio, waveSurferRef]);

  const renderVolumeIcon = (levelVolume) => {
    if (!levelVolume || levelVolume === 0) {
      return (
        <BsFillVolumeMuteFill
          size={30}
          color={theme.colors.typography.baseText}
        />
      );
    }
    if (levelVolume <= 35) {
      return (
        <BsVolumeDownFill size={30} color={theme.colors.typography.baseText} />
      );
    }
    return (
      <BsFillVolumeUpFill size={30} color={theme.colors.typography.baseText} />
    );
  };

  return (
    <Stack spacing={2} direction='column'>
      <div ref={waveSurferRef}></div>
      <div ref={containerRef} />

      {showPanel && (
        <>
          <br />
          <PlayerTimes>
            <ContainerTimersAudio>
              <span>{formatTime(currentTime)} </span>
              <Box display={'flex'} gap={'4px'} alignItems={'center'}>
                <ButtonAudio
                  whileTap={{ scale: 0.95 }}
                  type='button'
                  onClick={() => {
                    waveSurferRef.current.seekTo(0);
                  }}
                >
                  <IoReloadCircleOutline />
                </ButtonAudio>

                <ButtonAudio
                  styledType='play'
                  whileTap={{ scale: 0.95 }}
                  type='button'
                  onClick={() => {
                    waveSurferRef.current.playPause();
                    toggleIsPlaying(waveSurferRef.current.isPlaying());
                  }}
                >
                  {isPlaying ? (
                    <IoPauseCircleOutline />
                  ) : (
                    <IoPlayCircleOutline />
                  )}
                </ButtonAudio>

                <ButtonAudio
                  whileTap={{ scale: 0.95 }}
                  disabled={!isPlaying}
                  type='button'
                  onClick={() => {
                    waveSurferRef.current.stop();
                    waveSurferRef.current.seekTo(0);
                  }}
                >
                  <IoStopCircleOutline />
                </ButtonAudio>
              </Box>
              <span>{formatTime(audioDuration)} </span>
            </ContainerTimersAudio>
          </PlayerTimes>
          <ContainerWavesurfer>
            {theme.title === 'Light' ? (
              <ImageComponent
                src={LogoSidebarImg}
                width={'146px'}
                height={'40px'}
                alt={'Logo Voicce Labs'}
                loading='eager'
              />
            ) : (
              <ImageComponent
                src={LogoWhiteSidebarImg}
                width={'146px'}
                height={'40px'}
                alt={'Logo Voicce Labs'}
                loading='eager'
              />
            )}
            <Box
              display={'flex'}
              alignItems={'center'}
              className='panel-volume'
              gap={'12px'}
              width={'80px'}
            >
              {renderVolumeIcon(volumeAudio)}
              <Slider
                aria-label='Volume'
                size='small'
                value={volumeAudio}
                onChange={handleChange}
                ref={volumeInputAudio}
                sx={{
                  '& .MuiSlider-thumb': {
                    color: theme.colors.typography.baseText,
                  },
                  '& .MuiSlider-track': {
                    color: theme.colors.typography.baseText,
                  },
                  '& .MuiSlider-rail': {
                    color: theme.colors.containers.dividerColor,
                  },
                }}
              />
            </Box>
          </ContainerWavesurfer>
        </>
      )}
    </Stack>
  );
};

WaveRecordings.propTypes = {
  audio: PropTypes.string.isRequired,
};

export default WaveRecordings;
