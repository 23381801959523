import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/FormComponents/ButtonComponent';
import { ContainerRemoveTenant, FormRemoveTenant } from './styled';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { successToast } from '@components/FormComponents/Toast';
import { TitleComponent } from '@components/layout/TitleComponent';
import { dataSelectedTenants, dataTenants } from '@/store/TenantsStore';
import { useTenants } from '@/hooks/Tenants/useTenants';

export const RemoveTenant = ({ closeDialog }) => {
  const { t } = useTranslation();
  const [listTenants] = useAtom(dataTenants);
  const [tenantSelected] = useAtom(dataSelectedTenants);

  const { mutateDeleteTenant, loadingDeleteTenant } = useTenants();

  const renderHostName = (idHost) => {
    const filterHostName = listTenants
      .filter((server) => server.id === idHost)
      .map((serverMap) => serverMap.name)[0];

    if (!filterHostName) {
      return idHost;
    }
    return filterHostName;
  };

  const handleRemoveRoutingProfile = (e) => {
    e.preventDefault();
    mutateDeleteTenant(tenantSelected?.id, {
      onSuccess: () => {
        closeDialog();
        successToast(t('tenants.home.table.mgs-success-remove-tenant'));
      },
    });
  };

  return (
    <ContainerRemoveTenant>
      {loadingDeleteTenant && <BackDropCircularLoading />}

      <TitleComponent
        textTitle={t('tenants.home.table.title-remove-confirmation')}
        iconTitle='tenants'
      />

      <FormRemoveTenant onSubmit={handleRemoveRoutingProfile}>
        <p>
          {t('tenants.home.table.text-remove-confirmation')}
          <strong>{renderHostName(tenantSelected?.id)}</strong>
        </p>
        <Stack direction={`row`} gap={1} justifyContent={'flex-end'}>
          <Button typeText variant={'secondary'} onClick={closeDialog}>
            {t('tenants.home.table.btn-cancel')}
          </Button>
          <Button typeText type='submit'>
            {t('tenants.home.table.btn-confirm')}
          </Button>
        </Stack>
      </FormRemoveTenant>
    </ContainerRemoveTenant>
  );
};
