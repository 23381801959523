import { useId, useState } from 'react';
import { ContainerAddNewRoute } from './styled';
import { RoutingPagination } from '@/components/FormComponents/RoutingPagination';
import { DetailNewRouting } from './StagesNewRouting/DetailNewRouting';
import { CreateFirstScreen } from './StagesNewRouting/CreateFirstScreen';
import { CompleteNewRouting } from './StagesNewRouting/CompleteNewRouting';

export const AddRoutingProfile = ({ handleBackRouting }) => {
  const [stageNewRouting, setStageNewRouting] = useState(0);

  const handleStageAdd = (stage) => {
    setStageNewRouting(stage);
  };

  const flowNewRouting = [
    <DetailNewRouting
      key={useId()}
      handleBackRouting={handleBackRouting}
      handleNextStage={handleStageAdd}
    />,
    <CreateFirstScreen key={useId()} handleStage={handleStageAdd} />,
    <CompleteNewRouting
      key={useId()}
      handleStage={handleStageAdd}
      handleBackRouting={handleBackRouting}
    />,
  ];

  return (
    <ContainerAddNewRoute initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <RoutingPagination stageFlow={stageNewRouting} />
      {flowNewRouting[stageNewRouting]}
    </ContainerAddNewRoute>
  );
};
