import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';

import { getSchedulesQueue } from '@/services/dialControl/schedules/scheduleQueue/getSchedulesQueue';
import {
  dataQueueSelected,
  dataScheduleQueueSelected,
  listSchedulesQueue,
} from '@/store/ScheduleStore/SchedulerQueue';
import { deleteScheduleQueue } from '@/services/dialControl/schedules/scheduleQueue/deleteScheduleQueue';
import { createScheduleQueue } from '@/services/dialControl/schedules/scheduleQueue/createSchedulesQueue';
import { updateScheduleQueue } from '@/services/dialControl/schedules/scheduleQueue/updateScheduleQueue';

export const useSchedulesQueue = () => {
  const { t } = useTranslation();
  const [, setScheduleQueue] = useAtom(listSchedulesQueue);
  const [queueScheduleSelected] = useAtom(dataQueueSelected);
  const [dataSelected] = useAtom(dataScheduleQueueSelected);

  const {
    mutate: mutateDetailsScheduleQueue,
    isLoading: loadingDetailsScheduleQueue,
  } = useMutation({
    mutationFn: async (dataDetailSchedule) => {
      return await getSchedulesQueue(dataDetailSchedule);
    },
    onSuccess: (data) => setScheduleQueue(data),
    onError: () =>
      errorToast(
        t('dialControl.schedulesQueue.listSchedules.errorMessageList')
      ),
  });

  const {
    mutate: mutateCreateScheduleQueue,
    isLoading: loadingCreateScheduleQueue,
  } = useMutation({
    mutationFn: async (dataCreateSchedule) => {
      return await createScheduleQueue(dataCreateSchedule);
    },
    onSuccess: () => {
      mutateDetailsScheduleQueue(queueScheduleSelected.fila);
    },
  });

  const {
    mutate: mutateUpdateScheduleQueue,
    isLoading: loadingUpdateScheduleQueue,
  } = useMutation({
    mutationFn: async (dataUpdateSchedule) => {
      return await updateScheduleQueue(dataUpdateSchedule);
    },
    onSuccess: () => {
      mutateDetailsScheduleQueue(queueScheduleSelected.fila);
    },
  });

  const {
    mutate: mutateDeleteScheduleQueue,
    isLoading: loadingDeleteScheduleQueue,
  } = useMutation({
    mutationFn: async (dataDeleteSchedule) => {
      return await deleteScheduleQueue(dataDeleteSchedule);
    },
    onSuccess: () => {
      mutateDetailsScheduleQueue(dataSelected.fila);
    },
  });

  return {
    mutateDetailsScheduleQueue,
    loadingDetailsScheduleQueue,

    mutateDeleteScheduleQueue,
    loadingDeleteScheduleQueue,
    mutateCreateScheduleQueue,
    loadingCreateScheduleQueue,
    mutateUpdateScheduleQueue,
    loadingUpdateScheduleQueue,
  };
};
