import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerListQueues = styled(motion.div)`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: 100px 1fr;
  justify-items: flex-start;
  gap: 1rem;

  @media (max-width: 500px) {
    grid-template-rows: 80px 1fr;
  }
`;

export const ContentPanelQueues = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    transition: all 0.04s !important;
    background-color: ${(props) =>
      props.theme.colors.containers.primaryToDark} !important;
  }
`;

export const ContentStatusQueues = styled(motion.div)`
  width: 100%;
  flex: 1;
  /*  min-height: 600px; */

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: space-evenly;
  align-content: flex-start;
  align-items: center;
  gap: 1rem;
`;
