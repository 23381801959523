import { useEffect } from 'react';
import * as yup from 'yup';
import { useAtom } from 'jotai';
import { Divider, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ContentFooter, ContentMain, FormEditScheduleQueue } from './styled';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import useFormControl from '@/hooks/useFormControl';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { ControlTimePicker } from '@/components/FormComponents/TimePicker';
import { WeekDaysSelector } from '@/components/FormComponents/WeekDaysSelector';
import { dataScheduleQueueSelected } from '@/store/ScheduleStore/SchedulerQueue';
import { useSchedulesQueue } from '@/hooks/dialControl/Schedules/useSchedulesQueue';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useTranslation } from 'react-i18next';
import { format, isValid, parse } from 'date-fns';

export const EditScheduleQueue = ({ controlStage }) => {
  const { t } = useTranslation();
  const [dataSelected, setDataSelected] = useAtom(dataScheduleQueueSelected);
  const { mutateUpdateScheduleQueue, loadingUpdateScheduleQueue } =
    useSchedulesQueue();

  const renderTxt = (word) => {
    return t(`dialControl.schedulesQueue.editSchedules.${word}`);
  };

  const editScheduleSchema = yup.object().shape({
    queue: yup.string(),
    timeInit: yup
      .date()
      .test(
        'is-smaller',
        'Inicio agendamento deve ser menor que Final do Agendamento',
        function (value) {
          const { timeEnd } = this.parent;
          return !timeEnd || !value || value < timeEnd;
        }
      ),
    timeEnd: yup
      .date()
      .test(
        'is-greater',
        'Final agendamento deve ser maior que Inicio do Agendamento',
        function (value) {
          const { timeInit } = this.parent;
          return !timeInit || !value || value > timeInit;
        }
      ),
    pauseInit: yup
      .date()
      .test(
        'is-smaller',
        'Inicio pausa deve ser menor que Final do pausa',
        function (value) {
          const { pauseEnd } = this.parent;
          return !pauseEnd || !value || value <= pauseEnd;
        }
      ),
    pauseEnd: yup
      .date()
      .test(
        'is-greater',
        'Final agendamento deve ser maior que Inicio do Agendamento',
        function (value) {
          const { pauseInit } = this.parent;
          return !pauseInit || !value || value >= pauseInit;
        }
      ),
    dayOfWeek: yup.array(),
    //   .test('is-greater', '  um dia da semana', function (value) {
    //     return value.length > 0;
    //   }),
  });

  const { handleSubmit, control, errors, reset } =
    useFormControl(editScheduleSchema);

  const handleBackStage = () => {
    controlStage.toStage(1);
    reset();
    setDataSelected(null);
  };

  useEffect(() => {
    if (!dataSelected) {
      errorToast(renderTxt('msg-error'));
      return handleBackStage();
    }
  }, [dataSelected]);

  useEffect(() => {
    errors && Object.keys(errors).length > 0 && errorToast('error');
  }, [errors]);

  const formatTime = (timestamp) => {
    return format(new Date(timestamp), 'HH:mm:00');
  };

  const handleEditScheduleQueue = (data) => {
    const dataEdited = {
      id: dataSelected.id,
      fila: data.queue,
      abre: formatTime(data.timeInit),
      fecha: formatTime(data.timeEnd),
      pausa_ini: formatTime(data.pauseInit),
      pausa_fim: formatTime(data.pauseEnd),
      dia_semana: data?.dayOfWeek.toString(),
      principal_agendamento: '',
      agenda_lista: '',
    };

    mutateUpdateScheduleQueue(dataEdited, {
      onError: () => errorToast(renderTxt('msg-error')),
      onSuccess: () => {
        successToast(renderTxt('msg-success'));
        handleBackStage();
      },
    });
  };

  const formatDateRende = (dateEdit) => {
    if (!dateEdit) {
      return new Date();
    }
    const date = parse(dateEdit, 'HH:mm:ss', new Date());
    if (!isValid(date)) {
      return new Date();
    }
    return date;
  };

  const formateDaysWeek = (listDays) => {
    if (!listDays) {
      return [];
    }
    if (typeof listDays === 'string') {
      return [Number(listDays)];
    }
    return listDays;
  };

  return (
    <FormEditScheduleQueue
      onSubmit={handleSubmit(handleEditScheduleQueue)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingUpdateScheduleQueue && <BackDropCircularLoading />}
      <ContentMain>
        <TitleComponent textTitle={renderTxt('title-addSchedule')} />
        <DescriptionInformation textDescription={renderTxt('description')} />
        <ControlInput
          label={renderTxt('label-queue')}
          control={control}
          nameControl={'queue'}
          defaultValue={dataSelected?.fila ?? '-'}
          disabled
        />

        <Divider />

        <Stack direction={'row'} spacing={2}>
          <ControlTimePicker
            label={renderTxt('label-openQueue')}
            control={control}
            nameControl={'timeInit'}
            defaultValue={formatDateRende(dataSelected.abre)}
            error={Boolean(errors.timeInit)}
          />
          <ControlTimePicker
            label={renderTxt('label-closeQueue')}
            control={control}
            nameControl={'timeEnd'}
            defaultValue={formatDateRende(dataSelected.fecha)}
            error={Boolean(errors.timeEnd)}
          />
        </Stack>
        <Stack direction={'row'} spacing={2}>
          <ControlTimePicker
            label={renderTxt('label-initPause')}
            control={control}
            nameControl={'pauseInit'}
            defaultValue={formatDateRende(dataSelected.pausa_ini)}
            error={Boolean(errors.pauseInit)}
          />
          <ControlTimePicker
            label={renderTxt('label-endPause')}
            control={control}
            nameControl={'pauseEnd'}
            defaultValue={formatDateRende(dataSelected.pausa_fim)}
            error={Boolean(errors.pauseEnd)}
          />
        </Stack>

        <Divider />

        <Controller
          control={control}
          defaultValue={[formateDaysWeek(dataSelected.dia_semana)]}
          name={'dayOfWeek'}
          render={({ field: { onChange, value } }) => (
            <WeekDaysSelector
              selectedDays={value}
              setSelectedDays={onChange}
              errorSelectedDays={Boolean(errors.dayOfWeek)}
              hasEditorScheduler
              currentDay={dataSelected.dia_semana}
            />
          )}
        />
      </ContentMain>

      <ContentFooter>
        <Button typeText variant={'secondary'} onClick={handleBackStage}>
          {renderTxt('btn-cancel')}
        </Button>
        <Button
          typeText
          type='submit'
          disabled={errors && Object.keys(errors).length > 0}
        >
          {renderTxt('btn-update')}
        </Button>
      </ContentFooter>
    </FormEditScheduleQueue>
  );
};
