import { apiData } from '../api';

export async function getInfoQueue(queueNumber) {
  try {
    const { data } = await apiData.get(`dialer/queueInfo/${queueNumber}`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
}
