import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerNoData = styled(motion.div)`
  width: 100%;
  height: 100px;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 1px 2px 4px -1px rgba(0, 0, 0, 0.2);
  gap: 4px;

  .detail-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    svg {
      color: ${(props) => props.theme.colors.defaultColors.orange};
    }

    span {
      padding-top: 1px;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }

  .btn-add-scheduler {
    span {
      font-size: 0.85rem;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
    button {
      all: unset;
      cursor: pointer;
      &:hover {
        span {
          text-decoration: underline;
          color: ${(props) => props.theme.colors.defaultColors.blue};
        }
      }
    }
  }
`;
