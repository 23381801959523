import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ContainerReplace } from './styled';
import { Stack } from '@mui/system';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { useTranslation } from 'react-i18next';

export const ReplaceFunction = ({
  errorFunc,
  setErrorFunc,
  idFilterRender,
  itemPreview,
  listFilter,
  setListFilter,
}) => {
  const { t } = useTranslation();
  const [resultText, setResultText] = useState('');
  const [displayedText, setDisplayedText] = useState('');
  const [typeCase, setTypeCase] = useState('');

  const [wordReplace, setWordReplace] = useState('');
  const [newWord, setNewWord] = useState('');

  const filterDataRender = listFilter.filter(
    (list) => list.id === idFilterRender
  );

  useEffect(() => {
    if (filterDataRender.length > 0) {
      if (filterDataRender[0].sequence === 1) {
        return setResultText(itemPreview);
      }
      const currentSequence = filterDataRender[0].sequence;
      const sequenceList = listFilter
        .map((list) => list.sequence)
        .filter((seq) => seq !== currentSequence)
        .sort((a, b) => a - b);
      const previousClosest = sequenceList
        .filter((seq) => seq < currentSequence)
        .reduce((prev, curr) => (curr > prev ? curr : prev), -Infinity);
      const getPreviousValue = listFilter.filter(
        (list) => list.sequence === previousClosest
      );
      if (getPreviousValue.length > 0) {
        setResultText(getPreviousValue[0].result);
      }
    }
  }, [filterDataRender, listFilter]);

  useEffect(() => {
    if (filterDataRender.length > 0) {
      setWordReplace(filterDataRender[0].data[1] ?? 0);
      setNewWord(filterDataRender[0].data[2] ?? 0);
      setTypeCase(filterDataRender[0].data[3] ?? 0);
    }
  }, []);

  useEffect(() => {
    if (displayedText === '') {
      if (!errorFunc.includes(idFilterRender)) {
        setErrorFunc((previous) => [...previous, idFilterRender]);
      }
    } else {
      if (errorFunc.includes(idFilterRender)) {
        setErrorFunc((previous) =>
          previous.filter((error) => error !== idFilterRender)
        );
      }
    }
  }, [displayedText, errorFunc, idFilterRender]);

  const rendeCaseSensitive = (typeCase, dataChangeCase) => {
    switch (typeCase) {
      case 'uppercase':
        return dataChangeCase.toUpperCase();

      case 'lowercase':
        return dataChangeCase.toLowerCase();

      case 'capitalize':
        return dataChangeCase
          .split(' ')
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(' ');

      default:
        return dataChangeCase;
    }
  };

  const changeCaseSensitive = (typeCaseSelected) => {
    if (typeCaseSelected === typeCase) {
      return setTypeCase('');
    }
    setTypeCase(typeCaseSelected);
  };

  useEffect(() => {
    const output = resultText.replaceAll(wordReplace, newWord);
    setDisplayedText(rendeCaseSensitive(typeCase, output));

    const updatedList = listFilter.map((list) => {
      if (list.id === idFilterRender) {
        return {
          ...list,
          data: ['replace', wordReplace, newWord, typeCase],
          result: rendeCaseSensitive(typeCase, output),
        };
      }
      return list;
    });
    setListFilter(updatedList);
  }, [resultText, wordReplace, newWord, typeCase]);

  const hasError = errorFunc.includes(idFilterRender) ? 1 : 0;

  return (
    <ContainerReplace errorString={hasError}>
      <div className='case-content'>
        <motion.button
          whileTap={{ scale: 0.9 }}
          className={typeCase === 'uppercase' ? 'active-case' : ''}
          onClick={() => changeCaseSensitive('uppercase')}
          title='Uppercase'
        >
          <span>AA</span>
        </motion.button>
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={() => changeCaseSensitive('capitalize')}
          className={typeCase === 'capitalize' ? 'active-case' : ''}
          title='Capitalize'
        >
          <span>Aa</span>
        </motion.button>
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={() => changeCaseSensitive('lowercase')}
          className={typeCase === 'lowercase' ? 'active-case' : ''}
          title='LowerCase'
        >
          <span>aa</span>
        </motion.button>
      </div>
      <div className='input-content'>
        <span>{t('listLoader.importMailing.flow.label-input')}:</span>
        <span>
          <strong style={{ whiteSpace: 'pre' }}>{resultText}</strong>
        </span>
      </div>
      <Stack direction={'row'} spacing={2}>
        <InputComponent
          label={t('listLoader.importMailing.flow.label-word')}
          value={wordReplace}
          onChange={(e) => setWordReplace(e.target.value)}
          autoComplete='off'
        />
        <InputComponent
          label={t('listLoader.importMailing.flow.label-new-word')}
          value={newWord}
          onChange={(e) => setNewWord(e.target.value)}
          autoComplete='off'
        />
      </Stack>
      <div className='result-content'>
        <span>{t('listLoader.importMailing.flow.label-output')}:</span>
        <span>
          <strong style={{ whiteSpace: 'pre' }}>{displayedText}</strong>
        </span>
      </div>
    </ContainerReplace>
  );
};
