import { useState } from 'react';
import { ContainerIframe, ErrorMessage, Iframe } from './styled';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

export const IframePage = ({ codeIframe }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  function isValidUrl(url) {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(url);
  }

  const renderUrl = (urlIframe) => {
    const isValid = isValidUrl(urlIframe);

    if (isValid) {
      return urlIframe;
    }
    return null;
  };

  const handleLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    setError(true);
    setLoading(false);
  };

  return (
    <ContainerIframe>
      {loading && <BackDropCircularLoading />}
      {(!renderUrl(codeIframe) || error) && (
        <ErrorMessage>Erro ao carregar o conteúdo do iframe.</ErrorMessage>
      )}
      <Iframe
        src={renderUrl(codeIframe)}
        frameborder='0'
        allowfullscreen
        isLoading={loading}
        onLoad={handleLoad}
        onError={handleError}
      />
    </ContainerIframe>
  );
};
