import styled from 'styled-components';

export const ContainerPanelActivityLog = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  .fieldTypeSearch {
    grid-column: 1/4;
  }
  .fieldTypeDate {
    grid-column: 1/4;

    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      input {
        padding-top: 20px;
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }
  }
`;
