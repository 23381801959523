import { useLayoutEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { MdEdit } from 'react-icons/md';

import { useAtom } from 'jotai';
import {
  dataOptionsClients,
  dataSelectedUser,
  stateRenderUsers,
} from '@/store/UsersAgentsStore/userAgentsStore';
import { DialogComponent } from '@/components/DialogComponent';
import { RemoveUserAgent } from './RemoveUserAgent';
import { ContainerTable } from './styled';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '@/hooks/useWidth';
import { TablePagination } from '@/components/Tables/TablePagination';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter ';
import { renderDate } from '@/utils/formatsDateAndTime';

import { useSecurityProfileRender } from '@/hooks/Navigator/useSecurityProfileRender';
import { StatusDataRow } from '@/components/Tables/StatusDataRow';

export const TableUsersAgents = ({ dataTable, mutateRoutingById }) => {
  const { t } = useTranslation();
  const { permissionComponent } = useSecurityProfileRender();

  const [, setDataSelected] = useAtom(dataSelectedUser);
  const [, setStateRenderUsersAgents] = useAtom(stateRenderUsers);
  const [dialogRemoveUser, setDialogRemoveUser] = useState(false);
  const [optionsClients] = useAtom(dataOptionsClients);

  const renderClientFilter = (idClient) => {
    const filterClient = optionsClients
      .filter((item) => item.id === idClient)
      .map((item) => item.commercial_name)
      .toString();
    return filterClient ?? '-';
  };

  const { height, width } = useWindowDimensions();
  const tableUsersRef = useRef(null);

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableUsersRef) {
      const tableMaxWidth = tableUsersRef.current.offsetHeight;
      const cellMinWidth = width < 500 ? 200 : 54;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, width]);

  const handleOpenDialogEditUser = (dataRow) => {
    mutateRoutingById(dataRow.id, {
      onSuccess: (data) => {
        const addRoutingProfile = {
          ...dataRow,
          routingProfile: data[0].id,
        };
        setDataSelected(addRoutingProfile);
        setStateRenderUsersAgents(2);
      },
      onError: () => {
        const addRoutingProfile = {
          ...dataRow,
          routingProfile: '0',
        };
        setDataSelected(addRoutingProfile);
        setStateRenderUsersAgents(2);
      },
    });
  };

  const columnsUserAgents = [
    {
      Header: t('usersAgentsList.table.th-status'),
      accessor: 'isActive',
      Cell: ({ row }) => <StatusDataRow status={row.original.isActive} />,
    },
    {
      Header: t('usersAgentsList.table.th-name'),
      accessor: 'name',
      Cell: ({ row }) =>
        row.original.name ? capitalizeFirstLetter(row.original.name) : '-',
    },
    {
      Header: t('usersAgentsList.table.th-email'),
      accessor: 'email',
      Cell: ({ row }) => (row.original.email ? row.original.email : '-'),
    },
    {
      Header: t('usersAgentsList.table.th-client'),
      accessor: 'client_id',
      Cell: ({ row }) =>
        row.original.client_id
          ? renderClientFilter(row.original.client_id)
          : '-',
    },

    {
      Header: t('usersAgentsList.table.th-lastAccess'),
      accessor: 'last_access',
      Cell: ({ row }) =>
        row.original.last_access ? renderDate(row.original.last_access) : '-',
    },

    {
      Header: t('usersAgentsList.table.th-fifth'),
      accessor: 'created_at',
      Cell: ({ row }) =>
        row.original.created_at ? renderDate(row.original.created_at) : '-',
    },

    {
      Header: t('usersAgentsList.table.th-sixth'),
      disabled: true,
      Cell: ({ row }) => (
        <div className='editRow'>
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => handleOpenDialogEditUser(row.original)}
            title={t('usersAgentsList.table.th-sixth')}
            disabled={
              !permissionComponent('update') || !permissionComponent('create')
            }
          >
            <MdEdit />
          </motion.button>
        </div>
      ),
    },
  ];

  return (
    <ContainerTable ref={tableUsersRef}>
      <TablePagination
        dataColumns={columnsUserAgents}
        dataTable={dataTable}
        textNoData={t('usersAgentsList.table.noData')}
        rowsPerPageDisplay={heightTable}
      />
      <DialogComponent
        open={dialogRemoveUser}
        onClose={() => setDialogRemoveUser(false)}
        fullWidth={true}
      >
        <RemoveUserAgent closeDialog={() => setDialogRemoveUser(false)} />
      </DialogComponent>
    </ContainerTable>
  );
};
