import { FormControl } from '@mui/material';
import { Radio, RadioGroup } from '@mui/material';
import { StyledFormControlLabel, StyledFormLabel, StyledRadio } from './styled';
import { Controller } from 'react-hook-form';
import { useId } from 'react';

export const RadioComponent = ({
  labelRadio,
  valueRadio,
  setValueRadio,
  labelValue1,
  labelValue2,
  withGap = false,
}) => {
  const handleChange = (event) => {
    setValueRadio(event.target.value);
  };

  return (
    <>
      <StyledFormLabel id={labelRadio}>{labelRadio}</StyledFormLabel>
      <RadioGroup
        row
        aria-labelledby={labelRadio}
        name={labelRadio}
        value={valueRadio}
        onChange={handleChange}
        sx={{
          gap: withGap ? '4rem' : '',
        }}
      >
        <StyledFormControlLabel
          value='0'
          control={<StyledRadio size='small' />}
          label={!labelValue1 ? 'Não' : labelValue1}
        />
        <StyledFormControlLabel
          value='1'
          control={<StyledRadio size='small' />}
          label={!labelValue2 ? 'Sim' : labelValue2}
        />
      </RadioGroup>
    </>
  );
};

export const RadioGroupComponent = ({
  labelRadio,
  valeuRadio,
  changeRadio,
  labelValue1,
  labelValue2,
  id1,
  id2,
  withGap = false,
  defaultValue = false,
}) => {
  const generateIdSelect = useId();
  const handleChange = (event) => {
    changeRadio(event.target.value);
  };

  return (
    <FormControl>
      <StyledFormLabel> {labelRadio ?? ''}</StyledFormLabel>
      <RadioGroup
        aria-labelledby={`uncontrolled-native-label.${generateIdSelect}`}
        value={valeuRadio}
        onChange={handleChange}
        row
        defaultValue={defaultValue ? defaultValue : '1'}
        sx={{
          gap: withGap ? '4rem' : '',
        }}
      >
        <StyledFormControlLabel
          value={!labelValue1 ? '1' : labelValue1}
          control={<Radio size='small' id={id1} />}
          label={
            <label htmlFor={id1}>{!labelValue1 ? 'Não' : labelValue1}</label>
          }
        />
        <StyledFormControlLabel
          value={!labelValue2 ? '0' : labelValue2}
          control={<Radio size='small' id={id2} />}
          label={
            <label htmlFor={id2}>{!labelValue2 ? 'Sim' : labelValue2}</label>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

export const ControlRadio = ({
  control,
  defaultValue = '',
  nameControl,
  labelRadio,
  labelValue1 = '',
  labelValue2 = '',
}) => {
  const uniqueId1 = useId(); // ID único para o primeiro radio
  const uniqueId2 = useId(); // ID único para o segundo radio

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={nameControl}
      render={({ field: { onChange, value } }) => (
        <RadioGroupComponent
          labelRadio={labelRadio}
          valeuRadio={value}
          changeRadio={onChange}
          labelValue1={labelValue1}
          labelValue2={labelValue2}
          id1={uniqueId1} // Passa o ID único para o primeiro radio
          id2={uniqueId2} // Passa o ID único para o segundo radio
        />
      )}
    />
  );
};
