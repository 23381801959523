import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/FormComponents/ButtonComponent';
import { ContainerRemoveTenant, FormRemoveTenant } from './styled';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { successToast } from '@components/FormComponents/Toast';
import { TitleComponent } from '@components/layout/TitleComponent';
import { dataSelectedMatchTenants, dataTenants } from '@/store/TenantsStore';
import { useTenants } from '@/hooks/Tenants/useTenants';
import { dataListClients } from '@/store/Clients';

export const RemoveMatchTenant = ({ closeDialog }) => {
  const { t } = useTranslation();
  const [selectedData] = useAtom(dataSelectedMatchTenants);
  const [listClients] = useAtom(dataListClients);
  const [listTenants] = useAtom(dataTenants);
  const { mutateDeleteMatch, loadingDeleteMatch } = useTenants();

  const renderClientName = (idClient) => {
    const filterClient = listClients
      .filter((client) => client.id === idClient)
      .map((clientMap) => clientMap.commercial_name)[0];

    if (!filterClient) {
      return idClient;
    }
    return filterClient;
  };

  const renderHostName = (idHost) => {
    const filterHostName = listTenants
      .filter((server) => server.id === idHost)
      .map((serverMap) => serverMap.name)[0];

    if (!filterHostName) {
      return idHost;
    }
    return filterHostName;
  };

  const handleRemoveRoutingProfile = (e) => {
    e.preventDefault();
    mutateDeleteMatch(selectedData.id, {
      onSuccess: () => {
        successToast(
          t('tenants.addMatchTenant.table.mgs-success-remove-match')
        );
        closeDialog();
      },
    });
  };

  return (
    <ContainerRemoveTenant>
      {loadingDeleteMatch && <BackDropCircularLoading />}

      <TitleComponent
        textTitle={t('tenants.home.table.title-remove-confirmation')}
        iconTitle='tenants'
      />

      <FormRemoveTenant onSubmit={handleRemoveRoutingProfile}>
        <p>
          {t('tenants.home.table.text-remove-confirmation')}
          <strong>
            {renderHostName(selectedData?.server_id) ?? ''} -{' '}
            {renderClientName(selectedData?.client_id) ?? ''}
          </strong>
        </p>
        <Stack direction={`row`} gap={1} justifyContent={'flex-end'}>
          <Button typeText variant={'secondary'} onClick={closeDialog}>
            {t('tenants.home.table.btn-cancel')}
          </Button>
          <Button typeText type='submit'>
            {t('tenants.home.table.btn-confirm')}
          </Button>
        </Stack>
      </FormRemoveTenant>
    </ContainerRemoveTenant>
  );
};
