import styled from 'styled-components';

export const ContainerErrorsList = styled.div`
  button {
    all: unset;
    svg {
      padding-top: 3px;
      color: red;
      font-size: 1.9rem;
    }
    span {
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
    }
  }
`;

export const ContainerDialogErrorsRows = styled.div`
  display: grid;
  grid-template-rows: auto 400px 30px;
  gap: 2rem;
  padding: 2rem;

  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow: auto;

    table {
      padding: 0;
      position: relative;
      thead {
        position: sticky;
        top: 0;
        z-index: 10;
        tr {
          z-index: 1;
          &::after {
            z-index: -1;
            border-radius: 3px;
          }
          th {
            padding: 8px 8px 6px 8px;
            color: ${(props) => props.theme.colors.tables.tableRow};
            font-size: 0.75rem;
            text-align: start;
          }
        }
      }
      tbody {
        tr {
          height: 26px !important;
          position: relative;
          z-index: 1;

          &:nth-child(even)::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            border-radius: 2px;
            background-color: #d6d6d6b0; /* Cor cinza para o fundo */
          }

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            border-radius: 3px;
          }
          td {
            padding: 8px 8px 6px 8px;
            text-align: start;
            div {
              svg {
                color: ${(props) => props.theme.colors.defaultColors.orange};
              }
              span {
                font-size: 0.75rem;
                padding-top: 3px;
              }
            }
            span {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
  }
`;
