import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContentSchedule = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 2rem;

  /* .filtersContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  } */

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
  }
`;
