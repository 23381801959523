import styled from 'styled-components';

export const ContainerPhoneInputs = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  h4 {
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;

export const ContainerBoardDtmf = styled.div`
  width: 100%;
  height: 170px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: ${(props) =>
    props.theme.colors.containers.colorCardSecondary}AA;
  padding: 0.9rem;
  border-radius: 4px;
`;

export const ItemDtmf = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .usedKey-disabled {
    background: gray !important;
  }

  .usedKey-selector {
    background: ${(props) => props.theme.colors.nodesFlow.dtmf} !important;
  }

  button {
    span {
      font-size: 0.95rem;
    }
  }
`;
