import { apiData } from '@/services/api';

export async function updateCampaign(dataUpdateCampaign) {
  try {
    const { data } = await apiData.put('dialer/campaign', dataUpdateCampaign);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
