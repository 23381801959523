import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { createCallOperators } from '@/services/callOperators/createCallOperators';
import { deleteCallOperators } from '@/services/callOperators/deleteCallOperators';
import { getCallOperators } from '@/services/callOperators/getCallOperators';
import { updateCallOperators } from '@/services/callOperators/updateCallOperators';
import { dataCallOperators, stageCallOperators } from '@/store/CallOperator';

export const useCallOperators = () => {
  const { t } = useTranslation();
  const [, setListCallOperators] = useAtom(dataCallOperators);
  const [stateRenderCallOperators] = useAtom(stageCallOperators);

  const { isLoading: loadingDataOperators, refetch } = useQuery(
    'dataCallOperators',
    async ({ signal }) => {
      return await getCallOperators(signal);
    },
    {
      enabled: stateRenderCallOperators === 0,
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setListCallOperators(data),
    }
  );

  const {
    mutate: mutateCreateCallOperators,
    isLoading: loadingCreateCallOperators,
  } = useMutation({
    mutationFn: async (dataCreateCallOperators) => {
      return await createCallOperators(dataCreateCallOperators);
    },
    onError: () => {
      errorToast(t(`callOperators.create.msgs.msg-error-create`));
    },
  });

  const {
    mutate: mutateUpdateCallOperators,
    isLoading: loadingUpdateCallOperators,
  } = useMutation({
    mutationFn: async (dataUpdateCallOperators) => {
      return await updateCallOperators(dataUpdateCallOperators);
    },
    onError: () => {
      errorToast(t(`callOperators.edit.msgs.msg-error-update`));
    },
  });

  const {
    mutate: mutateDeleteCallOperators,
    isLoading: loadingDeleteCallOperators,
  } = useMutation({
    mutationFn: async (dataDeleteCallOperators) => {
      return await deleteCallOperators(dataDeleteCallOperators);
    },
    onSuccess: () => refetch(),
    onError: () => {
      errorToast(t(`callOperators.list.table.msg-error-removed`));
    },
  });

  return {
    loadingDataOperators,
    mutateCreateCallOperators,
    loadingCreateCallOperators,
    mutateUpdateCallOperators,
    loadingUpdateCallOperators,
    mutateDeleteCallOperators,
    loadingDeleteCallOperators,
  };
};
