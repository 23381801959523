import styled from 'styled-components';
import { motion } from 'framer-motion';

export const CardLatestList = styled(motion.div)`
  width: 99.5%;
  height: 70px;
  background: ${(props) => props.theme.colors.containers.colorCard};
  box-shadow: 2px 2px 2px 0px rgba(56, 56, 56, 0.35);
  border-radius: 4px;
  user-select: none;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.1s ease-in-out;
  outline: 1px solid transparent;
  transition: all 0.1s ease !important;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: black;
  }

  &:hover {
    box-shadow: 1px 1px 1px 1px rgba(56, 56, 56, 0.1);
    outline: 1px solid ${(props) => props.theme.colors.typography.baseText}AA;
  }

  h2 {
    color: ${(props) => props.theme.colors.typography.baseText};
    text-transform: uppercase;
    font-size: 0.94rem;
    width: 100%;
  }

  svg {
    font-size: 0.8rem;
    color: green;
  }

  span {
    color: ${(props) => props.theme.colors.typography.baseText}AA;
    text-transform: uppercase;
    font-size: 0.7rem;

    @media (max-width: 380px) {
      display: none;
    }
  }

  .content_render_data {
    min-width: 100px;

    @media (max-width: 380px) {
      display: none;
    }
  }

  .description-card {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      color: ${(props) => props.theme.colors.typography.baseText}AA;
      font-size: 0.75rem;
      word-wrap: break-all;
    }

    .publish {
      display: flex;
      align-items: center;
      gap: 4px;
      border: 1px solid #008504;
      border-radius: 4px;
      padding: 1px 6px;

      svg {
        font-size: 0.9rem;
        color: #008504;
      }

      span {
        color: ${(props) =>
          props.theme.colors.nodesFlow.statusTemplatePublished};
      }
    }

    .not_publish {
      display: flex;
      align-items: center;
      gap: 4px;
      border: 1px solid #e60b00;
      border-radius: 4px;
      padding: 3px 6px;

      svg {
        font-size: 0.7rem;
        color: #e60b00;
      }

      span {
        color: ${(props) =>
          props.theme.colors.nodesFlow.statusTemplateNotPublished};
      }
    }

    span {
      display: block;
      font-size: 0.7rem;
      padding-top: 2px;
    }
  }
`;
