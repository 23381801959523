import styled, { css } from 'styled-components';

export const ContainerCurrentTime = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;

  .header-content-time {
    display: flex;
    flex-direction: column;
    gap: 14px;
    h4 {
      font-size: 0.7rem;
      font-weight: 400;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.typography.baseText};
    }

    span {
      font-size: 0.9rem;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }

  button {
    font-size: 0.7rem;
    padding: 2px 4px;
  }
`;
