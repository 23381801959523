import styled from 'styled-components';

export const ContentChangeClienteControl = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 1rem 1rem;
  display: flex;
  flex-direction: column;

  .main {
    padding: 2rem 0;

    form {
      display: flex;
      flex-direction: column;
      gap: 1.4rem;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 0.4rem;
  }
`;
