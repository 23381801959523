import { useEffect, useId, useState } from 'react';
import { ContainerSchedule } from './styled';
import { Schedules } from './stages/Schedules';
import { AddSchedule } from './stages/AddSchedule';
import { ListQueueSchedulesByList } from './stages/ListQueueSchedulesByList';
import { EditSchedule } from './stages/EditSchedule';

export const SchedulerList = () => {
  const [currentStageSchedule, setCurrentStageSchedule] = useState(0);

  useEffect(() => {
    return () => setCurrentStageSchedule(0);
  }, []);

  const controlStage = {
    previousStage: () =>
      setCurrentStageSchedule(
        (currentStageSchedule) => currentStageSchedule - 1
      ),
    nextStage: () =>
      setCurrentStageSchedule(
        (currentStageSchedule) => currentStageSchedule + 1
      ),
    toStage: (stage) => setCurrentStageSchedule(stage),
  };

  const flowSchedules = [
    <ListQueueSchedulesByList key={useId()} controlStage={controlStage} />,
    <Schedules key={useId()} controlStage={controlStage} />,
    <AddSchedule key={useId()} controlStage={controlStage} />,
    <EditSchedule key={useId()} controlStage={controlStage} />,
  ];

  return (
    <ContainerSchedule>{flowSchedules[currentStageSchedule]}</ContainerSchedule>
  );
};
