import { apiData } from '@/services/api';

export async function createRenitency(dataCreate) {
  try {
    const { data } = await apiData.post(
      'dialingRules/createRulesRenitency',
      dataCreate
    );
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
