import styled from 'styled-components';

export const PanelLineControl = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 1px 4px 10px -1px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 0.5rem;
  position: relative;

  .no_lines_available {
    strong {
      color: ${(props) => props.theme.colors.secondary} !important;
    }
  }

  .status_queue {
    span {
      color: ${(props) => props.theme.colors.typography.baseText};
      font-size: 0.8rem;
    }

    strong {
      color: ${(props) => props.theme.colors.primary};
      font-size: 0.8rem;
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  .icon_handle_back {
    svg {
      color: ${(props) => props.theme.colors.typography.baseTitle};
    }
  }

  .itemPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
    span {
      text-transform: uppercase;
      font-weight: 600;
      color: ${(props) => props.theme.colors.typography.baseTitle};
    }
  }

  .detail_queue {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.4em;

    @media (max-width: 900px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    span {
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.baseText};
      opacity: 0.6;
    }
  }

  .indicators_panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-left: 3rem;
  }
`;
