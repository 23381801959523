import { useAtom } from 'jotai';
import { useEffect, useId, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { FaPlay } from 'react-icons/fa';
import { HiOutlineVariable } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { BiWorld } from 'react-icons/bi';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { FieldAreaText, FormMenuNode, MainAddNode } from './styled';
import { PlayerPromptMessage } from './PlayerPromptMessage';
import { useFlowBuilder } from '@/hooks/FlowBuilder/useFlowBuilder';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { AccordionComponent } from '@/components/layout/AccordionComponent';
import { errorToast } from '@/components/FormComponents/Toast';
import { checkLabelExistiNode } from '@/components/pages/FlowBuilder/utils/checkNameNodes';
import { TitleMenuNode } from '../TitleMenuNode';
import { FooterNode } from '@/components/pages/FlowBuilder/components/FooterNode';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import { FlexContainer } from '@/components/layout/FlexContainer/styled';
import {
  focusedFieldArea,
  isSavedFlow,
  selectedNode,
  showMenuNodes,
  templateSelected,
  variablesList,
  voicesOptions,
} from '@/store/FlowBuilder';

export const PlayPromptForm = ({ dataNodes }) => {
  const { t } = useTranslation();
  const selectRef = useRef(null);
  const selectGlobalRef = useRef(null);
  const [, setFocusedPromptArea] = useAtom(focusedFieldArea);
  const { mutateRenderPrompt, loadingRenderPrompt } = useFlowBuilder();
  const [template] = useAtom(templateSelected);
  const [listVariables] = useAtom(variablesList);
  const [listVoices] = useAtom(voicesOptions);
  const [, setIsSaved] = useAtom(isSavedFlow);
  const globalVars = template.variablesGlobal;
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [selected, setSelected] = useAtom(selectedNode);
  const [promptTTS, setPromptTTS] = useState('');
  const [errorPrompt, setErrorPrompt] = useState('');
  const [labelNode, setLabelNode] = useState('');
  const [labelNodeError, setLabelNodeError] = useState('');
  const [sound, setSound] = useState(null);
  const [showListVariables, setShowListVariables] = useState(false);
  const [showGlobalVariables, setShowGlobalVariables] = useState(false);
  const [selectedVar, setSelectedVar] = useState('');
  const [hasVariables, setHasVariables] = useState(false);
  const [variables, setVariables] = useState([]);

  useEffect(() => {
    setSound(null);
    return () => setSound(null);
  }, [selected]);

  useEffect(() => {
    if (showGlobalVariables) {
      selectGlobalRef.current.focus();
    }
  }, [showGlobalVariables, selectGlobalRef]);

  useEffect(() => {
    if (showListVariables) {
      selectRef.current.focus();
    }
  }, [showListVariables, selectRef]);

  useEffect(() => {
    if (showGlobalVariables) {
      selectGlobalRef.current.focus();
    }
  }, [showGlobalVariables, selectGlobalRef]);

  useEffect(() => {
    const mapVarsContext = listVariables
      ? listVariables.map((item) => {
          return { name: item.name, value: item.name, parent: false };
        })
      : [];

    const mapVarsGlobal = globalVars
      ? globalVars.map((item) => {
          return { name: item.name, value: item.value, parent: item.parent };
        })
      : [];

    setVariables(() => [...mapVarsContext, ...mapVarsGlobal]);
  }, [listVariables, globalVars]);

  useEffect(() => {
    if (selected) {
      setLabelNode(selected.data.label);
    }
  }, [selected]);

  useEffect(() => {
    const checkVar = /\{\{([^{}]+)\}\}/g;
    const listCheckVar = promptTTS.match(checkVar);

    if (listCheckVar) {
      return setHasVariables(true);
    }
    setHasVariables(false);
  }, [promptTTS, sound]);

  const { nodes, setNodes } = dataNodes;
  const { id, data } = selected;

  const validateLabelNode = (value) => {
    if (!value.trim()) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.playPrompt.menu.msg-name-required')
      );
    } else if (value.length > 50) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.playPrompt.menu.msg-name-length', {
          length: '50',
        })
      );
    } else if (!/^[_a-zA-Z0-9]*$/.test(value)) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.playPrompt.menu.msg-name-letters')
      );
    } else {
      setLabelNodeError('');
      setErrorPrompt('');
    }
    setLabelNode(value);
  };

  const validateSelectedVar = (value) => {
    setPromptTTS((labelTTSNode) => labelTTSNode + ` {{${value}}} `);
    setSelectedVar('0');
  };

  const validateSelectedGlobalVar = (value) => {
    setPromptTTS((labelTTSNode) => labelTTSNode + ` {{${value}}} `);
    setShowGlobalVariables(false);
  };

  useEffect(() => {
    if (selected) {
      setPromptTTS(data.prompt);
    }
  }, [selected]);

  const selectVoicePrompt = () => {
    const { nodes } = dataNodes;

    const voiceSelectedPersona =
      nodes
        .filter((item) => item.data?.handler === 'dialplanVariables')
        .map((item) => item.data?.voice)[0] ?? '';

    const voiceSelectedPersonaString = voiceSelectedPersona.toString();

    const filterValueVoice = listVoices
      .filter((item) => item.name === voiceSelectedPersonaString)
      .map((item) => item.value)
      .toString();

    if (!filterValueVoice || filterValueVoice === '') {
      return 'pt-BR-FranciscaNeural';
    }
    return filterValueVoice;
  };

  const handleAddVariablePrompt = (event) => {
    const newPrompt = event.target.value;
    setPromptTTS(newPrompt);
  };

  const closeMenu = () => {
    setShowMenu(false);
    setPromptTTS('');
    setSelected(null);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const renderVersionPrompt = (oldPrompt, currentPrompt, nameFile) => {
    if (!oldPrompt || nameFile === '') {
      return 'v1';
    }
    const separateVersionNameFile = nameFile.replace('v', '').split('-');

    if (!separateVersionNameFile || separateVersionNameFile[0].length !== 1) {
      return 'v1';
    }

    const convertToNumberVersion = Number(separateVersionNameFile[0]);
    const updatedVersion = convertToNumberVersion + 1;

    if (oldPrompt === currentPrompt) {
      return `v${convertToNumberVersion}`;
    }

    return `v${updatedVersion}`;
  };

  const renderFileName = () => {
    const firstVarUsed = /{{(.*?)}}/g;
    const matches = promptTTS.match(firstVarUsed);

    const versionFileName = renderVersionPrompt(
      data.prompt,
      promptTTS,
      data.fileName
    );

    if (matches?.length > 0) {
      const concatVars = matches.join('-');
      const nameFile = `${versionFileName}-${labelNode}-${concatVars}-${selectVoicePrompt()}`;
      return nameFile;
    }

    return `${versionFileName}-${labelNode}-${selectVoicePrompt()}`;
  };

  const handleSubmitNewNode = (e) => {
    e.preventDefault();

    const checkLabel = checkLabelExistiNode(id, labelNode, nodes);

    if (checkLabel) {
      return errorToast('Name of the block already used');
    }

    const filteredNode = nodes.map((node) =>
      node.id === id
        ? {
            ...node,
            data: {
              ...node.data,
              label: labelNode,
              prompt: promptTTS,
              validInput: [],
              allowSkip: false,
              fileName: renderFileName(),
              errorConnect: false,
            },
          }
        : node
    );
    setNodes(filteredNode);
    setIsSaved(false);
    closeMenu();
  };

  const removeVariablesPrompt = (dataSelected) => {
    if (dataSelected.length > 0) {
      const replaceVars = dataSelected.replace('$', 'Valor ');
      return replaceVars;
    }
    return dataSelected;
  };

  const handlePlayPrompt = async () => {
    if (labelNode === '') {
      setErrorPrompt(
        t('flowBuilder.flow.nodes.playPrompt.menu.msg-name-required')
      );
      return;
    }

    const dataRender = {
      prompt: removeVariablesPrompt(promptTTS),
      audioName: renderFileName(),
      prosodyRate: 1,
      speechSynthesisVoiceName: selectVoicePrompt(),
    };

    mutateRenderPrompt(dataRender, {
      onSuccess: (data) => {
        const arrayBuffer = data;
        const audioUrl = URL.createObjectURL(new Blob([arrayBuffer]));
        setSound(audioUrl);
      },
    });
  };

  const handleVariablePrompt = (typeSelect) => {
    if (typeSelect === 'global') {
      return setShowGlobalVariables(true);
    }
    setShowListVariables(true);
  };

  const hasEmptyFlowVars =
    variables.filter((item) => !item.parent).length === 0;

  return (
    <FormMenuNode
      onClick={handleChildClick}
      onSubmit={handleSubmitNewNode}
      id='playPrompt'
      name='playPrompt'
    >
      <MainAddNode>
        <TitleMenuNode
          title={t('flowBuilder.flow.nodes.playPrompt.menu.title')}
          description={t('flowBuilder.flow.nodes.playPrompt.description')}
        />

        <InputComponent
          label={t('flowBuilder.flow.nodes.playPrompt.menu.label-name')}
          value={labelNode}
          onChange={(e) => validateLabelNode(e.target.value)}
          error={Boolean(errorPrompt.length)}
          helperText={errorPrompt}
          onFocus={() => setFocusedPromptArea(true)}
          onBlur={() => setFocusedPromptArea(false)}
        />
        {labelNodeError && (
          <span
            style={{
              color: errorPrompt.length ? 'red' : 'gray',
              fontSize: '13px',
              marginTop: '-20px',
              marginLeft: '10px',
            }}
          >
            {labelNodeError}
          </span>
        )}
        <FlexContainer direction={'column'} width={'100%'} gap={'1rem'}>
          <div className='content_vars'>
            <AccordionComponent
              opemText={t(
                'flowBuilder.flow.nodes.playPrompt.menu.label-panel-variables-open'
              )}
              closeText={t(
                'flowBuilder.flow.nodes.playPrompt.menu.label-panel-variables-close'
              )}
              expandedContent={showListVariables || showGlobalVariables}
            >
              <SelectComponent
                defaultValue='0'
                labelSelect={t(
                  'flowBuilder.flow.nodes.playPrompt.menu.label-global-vars'
                )}
                inputRef={selectGlobalRef}
                open={showGlobalVariables}
                onClose={() => setShowGlobalVariables(false)}
                onOpen={() => setShowGlobalVariables(true)}
                value={selectedVar}
                onChange={(event) =>
                  validateSelectedGlobalVar(event.target.value)
                }
              >
                <ItemSelect value={'0'} disabled>
                  {t('flowBuilder.flow.nodes.playPrompt.menu.option-variable')}
                </ItemSelect>

                {variables.length > 0 &&
                  variables.map((item, i) => {
                    return (
                      item.parent && (
                        <ItemSelect key={i} value={item.value}>
                          {item.value}
                        </ItemSelect>
                      )
                    );
                  })}
              </SelectComponent>

              <SelectComponent
                defaultValue='0'
                labelSelect={t(
                  'flowBuilder.flow.nodes.playPrompt.menu.label-flow-vars'
                )}
                inputRef={selectRef}
                open={showListVariables}
                onClose={() => setShowListVariables(false)}
                onOpen={() => setShowListVariables(true)}
                value={selectedVar}
                disabled={hasEmptyFlowVars}
                onChange={(event) => validateSelectedVar(event.target.value)}
              >
                <ItemSelect value={'0'} disabled>
                  {t('flowBuilder.flow.nodes.playPrompt.menu.option-variable')}
                </ItemSelect>

                {variables.length > 0 &&
                  variables.map((item, i) => {
                    return (
                      !item.parent && (
                        <ItemSelect key={i} value={item.value}>
                          {item.name}
                        </ItemSelect>
                      )
                    );
                  })}
              </SelectComponent>
            </AccordionComponent>
          </div>

          <FieldAreaText>
            <InputComponent
              label={t('flowBuilder.flow.nodes.playPrompt.menu.label-prompt')}
              multiline
              maxRows={8}
              minRows={8}
              value={promptTTS}
              onChange={handleAddVariablePrompt}
              error={Boolean(errorPrompt.length)}
              onFocus={() => setFocusedPromptArea(true)}
              onBlur={() => setFocusedPromptArea(false)}
              id={useId()}
              name={`textArea${useId()}`}
            />

            <div className='btn_play_prompt'>
              <div className='item-btn'>
                <Button
                  onClick={() => handleVariablePrompt('global')}
                  title={t(
                    'flowBuilder.flow.nodes.playPrompt.menu.title-btn-add-global'
                  )}
                  variantstyle='success'
                  sx={{
                    width: '30px',
                    minWidth: '20px',
                    height: '30px',
                    borderRadius: '50px',
                  }}
                >
                  <BiWorld />
                </Button>
              </div>

              <div className='item-btn'>
                <Button
                  onClick={() => handleVariablePrompt('flow')}
                  title={t(
                    'flowBuilder.flow.nodes.playPrompt.menu.title-btn-add-flow'
                  )}
                  disabled={hasEmptyFlowVars}
                  variant='secondary'
                  sx={{
                    width: '30px',
                    minWidth: '20px',
                    height: '30px',
                    borderRadius: '50px',
                  }}
                >
                  <HiOutlineVariable />
                </Button>
              </div>
              <div className='item-btn'>
                {loadingRenderPrompt ? (
                  <div className='loading-btn'>
                    <CircularProgress size={16} />
                  </div>
                ) : (
                  <Button
                    onClick={handlePlayPrompt}
                    title={t(
                      'flowBuilder.flow.nodes.playPrompt.menu.title-btn-play'
                    )}
                    disabled={promptTTS.length === 0}
                    sx={{
                      width: '30px',
                      minWidth: '20px',
                      height: '30px',
                      borderRadius: '50px',
                      padding: '4px',
                    }}
                  >
                    <FaPlay size={12} />
                  </Button>
                )}
              </div>
            </div>
          </FieldAreaText>
        </FlexContainer>

        {hasVariables && (
          <DescriptionInformation
            variant='secondary'
            textDescription={t(
              'flowBuilder.flow.nodes.playPrompt.menu.description'
            )}
          />
        )}

        {sound && !loadingRenderPrompt && (
          <div className='area_play_prompt'>
            <PlayerPromptMessage soundResponse={sound} />
          </div>
        )}
      </MainAddNode>

      <FooterNode
        closeMenu={closeMenu}
        disabledSubmitBtn={
          Boolean(labelNodeError) || labelNode === '' || promptTTS === ''
        }
      />
    </FormMenuNode>
  );
};
