import { useAtom } from 'jotai';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import useFormControl from '@/hooks/useFormControl';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { SwitchControlled } from '@/components/FormComponents/SwitchComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ContentControlsDisposition } from './styled';

import { dataDispositions } from '@/store/FlowBuilder';

export const FormManageDispositions = ({
  selected,
  setSelected,
  handleCreate,
  handleUpdate,
}) => {
  const { t } = useTranslation();
  const [codeLigs] = useAtom(dataDispositions);

  const [stageFormDisposition, setStageFormDisposition] = useState(0);

  const dispositionsSchema = yup.object().shape({
    name: yup
      .string()
      .transform((value) => (value ? value.toUpperCase() : ''))
      .required(t('flowBuilder.configs.dispositions.msg-disposition-required'))
      .matches(
        /^[\w]+$/,
        t('flowBuilder.configs.dispositions.msg-disposition-chars')
      )
      .max(4, t('flowBuilder.configs.dispositions.msg-disposition-totalChars')),
    description: yup.string(),
    finaliza_contato: yup.boolean(),
    finaliza_fone: yup.boolean(),
    contato_positivo: yup.boolean(),
    contato_efetivo: yup.boolean(),
    padrao_sim_nao: yup.boolean(),
    tela_agente_sn: yup.boolean(),
    sistemico_sn: yup.boolean(),
    condicao: yup.boolean(),
    recusa: yup.boolean(),
    transferencia: yup.boolean(),
    interrompido: yup.boolean(),
    contato_sem_interacao: yup.boolean(),
    anti_flooding: yup.boolean(),
    transferido: yup.boolean(),
    uraConnectionCode: yup.boolean(),
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    errors,
    watch,
    setError,
    clearErrors,
  } = useFormControl(dispositionsSchema);

  useEffect(() => {
    if (selected) {
      setStageFormDisposition(1);
      setValue('name', selected.name);
      setValue('description', selected.descricao);
      setValue('finaliza_contato', selected.finaliza_contato === 1 && true);
      setValue('finaliza_fone', selected.finaliza_fone === 1 && true);
      setValue('contato_positivo', selected.contato_positivo === 1 && true);
      setValue('contato_efetivo', selected.contato_efetivo === 1 && true);
      setValue('padrao_sim_nao', selected.padrao_sim_nao === 1 && true);
      setValue('tela_agente_sn', selected.tela_agente_sn === 1 && true);
      setValue('sistemico_sn', selected.sistemico_sn === 1 && true);
      setValue('condicao', selected.condicao === 1 && true);
      setValue('recusa', selected.recusa === 1 && true);
      setValue('transferencia', selected.transferencia === 1 && true);
      setValue('interrompido', selected.interrompido === 1 && true);
      setValue(
        'contato_sem_interacao',
        selected.contato_sem_interacao === 1 && true
      );
      setValue('anti_flooding', selected.anti_flooding === 1 && true);
      setValue('transferido', selected.transferido === 1 && true);
      setValue('uraConnectionCode', selected.uraConnectionCode === 1 && true);
    }
  }, [selected, setValue]);

  const nameDisposition = watch('name', '');

  const cancelManagementDisposition = () => {
    setStageFormDisposition(0);
    setSelected(null);
    reset();
  };

  const handleSubmitDisposition = (data) => {
    const dataNewDisposition = {
      cod_lig: data.name,
      descricao: data.description,
      finaliza_contato: data.finaliza_contato ? 1 : 0,
      finaliza_fone: data.finaliza_fone ? 1 : 0,
      contato_positivo: data.contato_positivo ? 1 : 0,
      contato_efetivo: data.contato_efetivo ? 1 : 0,
      padrao_sim_nao: data.padrao_sim_nao ? 1 : 0,
      tela_agente_sn: data.tela_agente_sn ? 1 : 0,
      sistemico_sn: data.sistemico_sn ? 1 : 0,
      condicao: data.condicao ? 1 : 0,
      recusa: data.recusa ? 1 : 0,
      transferencia: data.transferencia ? 1 : 0,
      interrompido: data.interrompido ? 1 : 0,
      contato_sem_interacao: data.contato_sem_interacao ? 1 : 0,
      anti_flooding: data.anti_flooding ? 1 : 0,
      transferido: data.transferido ? 1 : 0,
    };
    handleCreate(dataNewDisposition, {
      onSuccess: () => {
        cancelManagementDisposition();
      },
    });
  };

  const handleUpdateDisposition = (data) => {
    const dataUpdateDisposition = {
      cod_lig: data.name,
      descricao: data.description,
      finaliza_contato: data.finaliza_contato ? 1 : 0,
      finaliza_fone: data.finaliza_fone ? 1 : 0,
      contato_positivo: data.contato_positivo ? 1 : 0,
      contato_efetivo: data.contato_efetivo ? 1 : 0,
      padrao_sim_nao: data.padrao_sim_nao ? 1 : 0,
      tela_agente_sn: data.tela_agente_sn ? 1 : 0,
      sistemico_sn: data.sistemico_sn ? 1 : 0,
      condicao: data.condicao ? 1 : 0,
      recusa: data.recusa ? 1 : 0,
      transferencia: data.transferencia ? 1 : 0,
      interrompido: data.interrompido ? 1 : 0,
      contato_sem_interacao: data.contato_sem_interacao ? 1 : 0,
      anti_flooding: data.anti_flooding ? 1 : 0,
      transferido: data.transferido ? 1 : 0,
    };
    handleUpdate(dataUpdateDisposition, {
      onSuccess: () => {
        cancelManagementDisposition();
      },
    });
  };

  const controlManagementDisposition = () => {
    const regex = /^[\w]+$/;
    if (!regex.test(nameDisposition)) {
      setError('name', {
        type: 'manual',
        message: t('flowBuilder.configs.endpoints.msg-endPoint-chars'),
      });
      return;
    }

    const checkAlreadyExists = codeLigs.some(
      (disposition) => disposition.cod_lig === nameDisposition
    );

    if (checkAlreadyExists) {
      setError('name', {
        type: 'manual',
        message: t('flowBuilder.configs.endpoints.msg-disposition-existing'),
      });
      return;
    }

    clearErrors();
    setStageFormDisposition(1);
    return;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      controlManagementDisposition();
    }
  };

  const hasDisabledNextStage = nameDisposition === '';

  const renderSubmitAction = selected
    ? handleSubmit(handleUpdateDisposition)
    : handleSubmit(handleSubmitDisposition);

  return (
    <form onSubmit={renderSubmitAction}>
      <ControlInput
        label={t('flowBuilder.configs.dispositions.label-name')}
        control={control}
        nameControl={'name'}
        error={Boolean(errors.name)}
        helperText={errors.name?.message}
        inputProps={{
          maxLength: 4,
          style: { textTransform: 'uppercase' },
        }}
        onKeyDown={(e) => handleKeyDown(e)}
        disabled={stageFormDisposition > 0}
      />
      <ControlInput
        label={t('flowBuilder.configs.dispositions.label-description')}
        control={control}
        nameControl={'description'}
        disabled={stageFormDisposition > 0}
        onKeyDown={(e) => handleKeyDown(e)}
      />

      {stageFormDisposition === 0 && (
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <Button
            typeText
            type='button'
            onClick={controlManagementDisposition}
            disabled={hasDisabledNextStage}
          >
            {t('flowBuilder.configs.btn-add')}
          </Button>
        </Stack>
      )}

      {stageFormDisposition === 1 && (
        <>
          <ContentControlsDisposition
            initial={{ opacity: 0 }}
            animate={{ opacity: stageFormDisposition === 1 ? 1 : 0 }}
          >
            <Grid container spacing={2} padding={'8px'}>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'uraConnectionCode'}
                  activeLabel={t('dispositions.create.label-uraConnection')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'finaliza_contato'}
                  activeLabel={t('dispositions.create.label-ends')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'finaliza_fone'}
                  activeLabel={t('dispositions.create.label-endCall')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'contato_positivo'}
                  activeLabel={t('dispositions.create.label-positive')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'contato_efetivo'}
                  activeLabel={t('dispositions.create.label-effective')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'padrao_sim_nao'}
                  activeLabel={t('dispositions.create.label-sn')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'tela_agente_sn'}
                  activeLabel={t('dispositions.create.label-agentScreenSn')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'sistemico_sn'}
                  activeLabel={t('dispositions.create.label-sysSn')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'condicao'}
                  activeLabel={t('dispositions.create.label-condition')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'recusa'}
                  activeLabel={t('dispositions.create.label-refusal')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'transferencia'}
                  activeLabel={t('dispositions.create.label-transfer')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'interrompido'}
                  activeLabel={t('dispositions.create.label-interrupted')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'contato_sem_interacao'}
                  activeLabel={t('dispositions.create.label-noInteraction')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'anti_flooding'}
                  activeLabel={t('dispositions.create.label-antFlooding')}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <SwitchControlled
                  control={control}
                  nameControl={'transferido'}
                  activeLabel={t('dispositions.create.label-transferred')}
                />
              </Grid>
            </Grid>
          </ContentControlsDisposition>

          <Stack
            width={'100%'}
            justifyContent={'flex-end'}
            alignItems={'flex-end'}
            direction={'row'}
            spacing={1}
          >
            <Button
              typeText
              type='button'
              variant='secondary'
              onClick={cancelManagementDisposition}
            >
              {t('flowBuilder.configs.btn-cancel')}
            </Button>
            <Button typeText type='submit'>
              {selected
                ? t('flowBuilder.configs.btn-update')
                : t('flowBuilder.configs.btn-add')}
            </Button>
          </Stack>
        </>
      )}
    </form>
  );
};
