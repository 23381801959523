import styled from 'styled-components';

export const ContainerHeaderListFlow = styled.header`
  width: 100%;
  height: 78px;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  box-shadow: 2px 2px 2px 0px rgba(56, 56, 56, 0.35);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 240px;
  @media (max-width: 876px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 520px) {
    display: none;
  }
`;

export const DetailsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 0 0.8rem;

  .item-detail-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-left: 6px;
    position: relative;
    border-radius: 3px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      left: 0;
      background-color: ${(props) => props.theme.colors.typography.baseText};
    }

    span {
      width: 120px;
      font-size: 0.8rem;
      padding-left: 10px;
      padding-top: 3px;

      color: ${(props) => props.theme.colors.typography.baseText}AA;
      &:last-child {
        font-size: 1.2rem;
        color: ${(props) => props.theme.colors.typography.baseText};
        padding-left: 0px;
      }
    }

    .color-public {
      color: ${(props) => props.theme.colors.success};
    }

    .color-not-public {
      color: ${(props) => props.theme.colors.defaultColors.orange};
    }

    .color-total {
      color: ${(props) => props.theme.colors.defaultColors.purple};
    }
  }
`;

export const ImgHeaderList = styled.div`
  padding: 0 0.6rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 876px) {
    display: none;
  }
`;
