import styled from 'styled-components';

export const ContainerConfigMailing = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.6rem;

  padding: 1rem;

  .no-animation-detail {
    &:hover {
      svg {
        animation: none;
      }
    }
  }

  .no-animation {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: -3px;
      top: -3px;
      width: 9px;
      height: 9px;
      border-radius: 30px;
      background-color: ${(props) => props.theme.colors.defaultColors.orange};
    }

    &:hover {
      svg {
        animation: none;
      }
    }
  }

  button {
    min-width: 6px;
    padding: 0.4rem 0.6rem;
    box-shadow: none;
    background-color: ${(props) =>
      props.theme.colors.listNodes.bgBtn} !important;
    svg {
      color: ${(props) => props.theme.colors.defaultColors.white};
      font-size: 1rem;
      transition: transform 5s ease-in-out;
    }

    &:hover {
      svg {
        animation: rotateInfinite 5s ease-in-out infinite;
      }
    }

    @keyframes rotateInfinite {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
