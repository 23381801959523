import { useReactFlow } from 'reactflow';
import { useEffect, useId, useState } from 'react';
import { ListNlus } from './ListNlus';
import { AddNlus } from './AddNlus';

export const ConfigNlus = ({ dataNlus: listNlus, controlStage }) => {
  const { getNodes } = useReactFlow();
  const [stageNlusFLow, setStageNlusFlow] = useState(0);
  const [usedEndpoints, setUsedEndpoints] = useState([]);

  const changeFlowConfigState = (stage) => setStageNlusFlow(stage);

  const hasUsedEndpoints = (usedNodes) => {
    const listNodes = usedNodes ? usedNodes : [];
    const mapListEndpoints = listNlus
      .map((itemSelected) => {
        const dispositionsUsed = listNodes.filter(
          (item) => item.data.nluEndpoint === itemSelected.name
        );
        if (dispositionsUsed.length > 0) {
          return dispositionsUsed[0];
        }
        return false;
      })
      .filter((item) => item)
      .map((item) => item.data.nluEndpoint);

    return mapListEndpoints;
  };

  useEffect(() => {
    setUsedEndpoints(hasUsedEndpoints(getNodes()));
  }, [getNodes]);

  const flowNlus = [
    <ListNlus
      key={useId()}
      dataNlus={listNlus}
      handleClose={controlStage.closeConfig}
      changeFlowConfigState={changeFlowConfigState}
      usedEndpoints={usedEndpoints}
    />,
    <AddNlus
      key={useId()}
      changeFlowConfigState={changeFlowConfigState}
      usedEndpoints={usedEndpoints}
    />,
  ];

  return flowNlus[stageNlusFLow];
};
