import { apiData } from '@/services/api';

export async function getRoutingProfileByClient(dataListRoutingUser) {
  try {
    const { data } = await apiData.get(
      `securityProfiles/listRoutingProfilesByClient/${dataListRoutingUser}`
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
}
