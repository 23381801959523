import styled, { css, keyframes } from 'styled-components';

const borderTimePosition = keyframes`
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
`;

export const applyBorderAnimation = () => css`
  animation: ${borderTimePosition} 26s linear infinite;
  background-size: 400%;
`;

const isSafariClient = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

const getColorBorderHandler = (handler, isSafari) => {
  if (handler === 'dbQuery') {
    return css`
      background: linear-gradient(
        45deg,
        #4c48ff,
        #ff57ab,
        #ff4c43,
        #ffb839,
        #43ff86,
        #4c48ff
      );
    `;
  }
  if (handler === 'dialplanVariables') {
    return css`
      background: ${isSafari
        ? '#4c48ff'
        : 'linear-gradient(45deg, #4c48ff, #9997f7, #2825d0)'};
    `;
  }
  if (handler === 'ttsVoicceLabs') {
    return css`
      background: ${isSafari
        ? '#00ab07'
        : ' linear-gradient(45deg, #00ab07, #02c054, #27fc83)'};
    `;
  }
  if (handler === 'recVoz') {
    return css`
      background: ${isSafari
        ? '#672e93'
        : 'linear-gradient(45deg, #672e93, #9619f5, #a176c1)'};
    `;
  }
  if (handler === 'dtmf') {
    return css`
      background: ${isSafari
        ? '#39264c'
        : 'linear-gradient(45deg, #39264c, #b28bda, #4f2877)'};
    `;
  }
  if (handler === 'hangUp') {
    return css`
      background: ${isSafari
        ? '#ff0000'
        : 'linear-gradient(45deg,  #ff0000, #f22c2c, #ffa857)'};
    `;
  }
  if (handler === 'conditionalNode') {
    return css`
      background: ${isSafari
        ? '#1266f8'
        : 'linear-gradient(45deg,  #1266f8, #3f85fd, #9bbdf8)'};
    `;
  }
  if (handler === 'disposition') {
    return css`
      background: ${isSafari
        ? '#ffb839'
        : 'linear-gradient(45deg,  #ffb839, #ffe14d, #fda50d)'};
    `;
  }
  if (handler === 'setVariables') {
    return css`
      background: ${isSafari
        ? '#3250b3'
        : 'linear-gradient(45deg, #3250b3, #1743d3, #8294ce)'};
    `;
  }
  if (handler === 'apiData') {
    return css`
      background: ${isSafari
        ? '#2bb495'
        : 'linear-gradient(45deg,#2bb495, #00bd65, #2effce)'};
    `;
  }
  if (handler === 'loop') {
    return css`
      background: ${isSafari
        ? '#ef2a64'
        : 'linear-gradient(45deg, #ef2a64, #eb6b91, #ef2a64)'};
    `;
  }
  if (handler === 'transferQueue') {
    return css`
      background: ${isSafari
        ? '#8cbe37'
        : 'linear-gradient(45deg, #8cbe37, #27fc83, #02c054)'};
    `;
  }
  if (handler === 'deepCenter') {
    return css`
      background: linear-gradient(45deg, #40719f, #1d5181, #a1bcca);
    `;
  }
  if (handler === 'functionHandler') {
    return css`
      background: ${isSafari
        ? '#696969'
        : 'linear-gradient(45deg, #696969, #787878, #c2c2c2)'};
    `;
  }
  if (handler === 'monestIa') {
    return css`
      background: ${isSafari
        ? ' rgb(102, 0, 204)'
        : ' linear-gradient(45deg, rgb(102, 0, 204),rgb(168, 113, 224),rgb(69, 29, 109))'};
    `;
  }
  return css`
    background: linear-gradient(45deg, #4c48ff, #9997f7, #2825d0);
  `;
};

export const ContainerNodeWrapper = styled.div`
  border-radius: 4px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 4px;
    left: -2px;
    top: -2px;

    ${applyBorderAnimation}
    ${({ handler }) => getColorBorderHandler(handler, isSafariClient)}

    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
  }

  ${({ selected, errorConnect, theme }) => {
    if (selected) {
      return css`
        background: #00c8f0;
        &::before,
        &::after {
          left: -4px;
          top: -4px;
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          background: #00c8f0;
        }
      `;
    }

    if (errorConnect) {
      return css`
        background: ${theme.colors.defaultColors.orange};
        &::before,
        &::after {
          left: -4px;
          top: -4px;
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          background: ${theme.colors.defaultColors.orange};
        }
      `;
    }
  }}
  .react-flow__handle {
    background: transparent;
    width: 14px;
    height: 100%;
    border-radius: 0;
    border: none;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 10px;
      width: 8px;
      height: 8px;
      border: 2px solid ${(props) => props.theme.colors.containers.colorCard};
      background: ${(props) => props.theme.colors.typography.baseTitle};
    }
  }

  .react-flow__handle-left {
    left: -7px;
  }

  .react-flow__handle-right {
    right: -6px;
  }

  .headerNode {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    font-size: 0.6rem;
  }
`;

export const ContentNodeWrapper = styled.div`
  display: grid;
  grid-template-rows: 40px 1fr;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 3px;
    gap: 0.6rem;
    padding-left: 0.6rem;
    svg {
      color: #ffff;
      font-size: 1.4rem;
    }
    .titleNode {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      h4 {
        color: #ffff;
        text-transform: uppercase;
        font-size: 0.8rem;
      }
    }

    span {
      font-size: 0.6rem;
      text-transform: uppercase;
      color: #ffff;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    gap: 0.6rem;

    span {
      font-size: 0.74rem;
      color: ${(props) => props.theme.colors.typography.baseText};
    }

    .field-prompt-render {
      width: 100%;
      height: 56px;
      word-break: break-all;
      background-color: ${(props) =>
        props.theme.colors.containers.colorCardSecondary};
      padding: 4px;
      border-radius: 2px;
      overflow: auto;
    }

    .dividerNode {
      width: 100%;
      height: 1px;
      background-color: #d1d1d136;
    }
  }
`;

export const FlexBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
`;

export const FlexBoxColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  max-width: 220px;
`;

export const FlexBoxCl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;
