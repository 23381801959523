import { Handle, Position } from 'reactflow';
import { useAtom } from 'jotai';
import React, { memo, useCallback, useMemo } from 'react';
import { RiRecordMailLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { shortNameRender } from '../../../../utils/shortNameRender';
import { FlexBoxRow } from '../styled';
import { NodeContent, NodeRecVozContainer } from './styled';
import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { PanelNode } from '@/components/pages/FlowBuilder/components/PanelNode';

const RecVozNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();

  const [, setShowMenu] = useAtom(showMenuNodes);
  const [, setSelectedNode] = useAtom(selectedNode);

  const handleNode = useCallback(() => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  }, [id, data, setSelectedNode, setShowMenu]);

  const renderHeightNode = useMemo(() => {
    const totalItems = data?.validInput?.length ?? 0;
    const heightItem = 34;
    const totalHeight = totalItems === 0 ? 130 : 130 + totalItems * heightItem;
    return `${totalHeight}px`;
  }, [data?.validInput]);

  return (
    <NodeRecVozContainer
      selected={selected}
      onDoubleClick={() => handleNode()}
      heighNode={renderHeightNode}
      errorConnect={data.errorConnect ? 1 : 0}
      handler={data.handler}
    >
      <PanelNode data={data} id={id} />

      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <RiRecordMailLine />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
            <span>{t('flowBuilder.flow.nodes.recVoz.label')}</span>
          </div>
        </div>
        <div className='main'>
          <FlexBoxRow>
            <span>{t('flowBuilder.flow.nodes.recVoz.label-endpoint')}: </span>
            <span>
              {data.nluEndpoint === 'Transcribe'
                ? t('flowBuilder.flow.nodes.recVoz.label-transcribe')
                : data.nluEndpoint}
            </span>
          </FlexBoxRow>
          <div className='dividerNode'></div>
          <FlexBoxRow>
            <span>{t('flowBuilder.flow.nodes.recVoz.label-timeOut')}: </span>
            <span>{data.timeout}</span>
          </FlexBoxRow>
          <div className='dividerNode'></div>
          <FlexBoxRow>
            <span>{t('flowBuilder.flow.nodes.recVoz.label-attempts')}: </span>
            <span>{data.qtdRepesca}</span>
          </FlexBoxRow>
        </div>
      </NodeContent>
    </NodeRecVozContainer>
  );
};

export const RecVozNode = memo(RecVozNodeComponent);
