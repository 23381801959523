import { apiData } from '../api';

export async function getTemplatesMailingById(idTemplate, signal) {
  try {
    const data = await apiData.get(`dialer/selectFlow/${idTemplate}`, {
      signal,
    });
    return data.data;
  } catch (error) {
    throw new Error(error);
  }
}
