import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerCountReports = styled(motion.div)`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: 30px auto auto 1fr;
  gap: 2rem;

  @media (max-width: 635px) {
    min-height: auto;
  }
`;
