import { apiData } from '@/services/api';

export async function getCampaignsCountInListService() {
  try {
    const { data } = await apiData.get('reports/listCampaignsClients');
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
