import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useId } from 'react';
import { InputComponent } from '../InputComponent';
import { StyledAutoComplete, StyledPaper } from './styled';

import { ItemSelect } from '../SelectInput/ItemSelect';

export const AutoCompleteSelect = ({
  valueAutoComplete,
  optionsList,
  labelAutoComplete,
  helperText = '',
  errorAutoComplete,
  changeAutoComplete,
  readOnlyAutoComplete = false,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <StyledAutoComplete
      value={valueAutoComplete ?? null}
      onChange={(event, newValue) => changeAutoComplete(newValue)}
      readOnly={readOnlyAutoComplete}
      size='small'
      id={`autocomplete_${useId()}`}
      options={optionsList}
      getOptionLabel={(option) => option.label ?? ''}
      isOptionEqualToValue={(option, value) => option.value == value?.value}
      noOptionsText={t('components.autoCompleteInput.noData')}
      disabled={disabled}
      PaperComponent={(props) => <StyledPaper {...props} />}
      renderOption={(props, option) => (
        <ItemSelect {...props} dense disabled={option.value === '0'}>
          {option.label}
        </ItemSelect>
      )}
      renderInput={(params) => (
        <InputComponent
          label={labelAutoComplete ?? ''}
          {...params}
          helperText={helperText}
          error={errorAutoComplete}
          margin='dense'
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

export const ControlAutoComplete = ({
  control,
  nameControl,
  optionsList,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={nameControl}
      render={({ field: { onChange, value } }) => (
        <AutoCompleteSelect
          {...rest}
          valueAutoComplete={value}
          changeAutoComplete={onChange}
          optionsList={optionsList}
          labelAutoComplete={rest.label}
        />
      )}
    />
  );
};

export const AutoComplete = ({ ...rest }) => {
  const { t } = useTranslation();

  return (
    <StyledAutoComplete
      {...rest}
      size='small'
      id={`autocomplete_${useId()}`}
      getOptionLabel={(option) => option ?? ''}
      isOptionEqualToValue={(option, value) => option == value}
      noOptionsText={t('components.autoCompleteInput.noData')}
      PaperComponent={(props) => <StyledPaper {...props} />}
      renderOption={(props, option) => (
        <ItemSelect {...props} dense disabled={option === '0'}>
          {option}
        </ItemSelect>
      )}
      renderInput={(params) => (
        <InputComponent
          label={rest.label ?? ''}
          {...params}
          margin='dense'
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};
