import { IoIosCloseCircleOutline } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import { RiDraggable } from 'react-icons/ri';
import { PiArrowBendDownRightBold } from 'react-icons/pi';
import { shortNameRender } from '@/components/pages/FlowBuilder/utils/shortNameRender';
import { ContainerItem } from './styled';

export const ItemVariable = ({ itemVariable, handleRemove, handleEdit }) => {
  const { name, value } = itemVariable;

  const renderVarItemString = shortNameRender(`${name}: ${value}`, 24);

  return (
    <ContainerItem title={`${name} - ${value}`}>
      <div className='description'>
        <RiDraggable />
        <PiArrowBendDownRightBold />
        <span>{renderVarItemString}</span>
      </div>
      <div className='panel-item'>
        <button onClick={handleEdit} type='button'>
          <MdEdit />
        </button>
        <button onClick={handleRemove} type='button'>
          <IoIosCloseCircleOutline size={'1.05rem'} />
        </button>
      </div>
    </ContainerItem>
  );
};
