import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerLatestList = styled(motion.div)`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  padding-top: 1rem;

  h4 {
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseTitle};
    margin-bottom: 10px;
  }

  .btn-new-flow {
    transition: all 0.05s ease;
    background-color: ${(props) =>
      props.theme.colors.listNodes.bgBtn} !important;
  }
`;

export const ContentMainLatestList = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContainerNoData = styled(motion.div)`
  width: 100%;
  height: 100px;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  border-radius: 3px;

  svg {
    color: ${(props) => props.theme.colors.secondary};
    font-size: 1.4rem;
  }

  span {
    color: ${(props) => props.theme.colors.typography.baseText}AA;
    font-weight: 400;
  }
`;
