import React from 'react';
import ReactDOM from 'react-dom/client';
import { queryClient } from './services/queryClient';
import App from './App';
import './libs/i18n/i18n';
import { QueryClientProvider } from 'react-query';
import './styles/fonts.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  //   <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
  //   </React.StrictMode>
);
