import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { updateClientUser } from '@/services/userAgents/usersList/updateClientUser';
import { useAtom } from 'jotai';
import { triggerReloadApp } from '@/store/AuthenticatorStore';
import { idSubItem } from '@/store/NavigationDashboard';
import { useLocalStorage } from '../useLocalStorage';
import useAuth from '../Authenticator/useAuth';

export const useControlClient = () => {
  const { t } = useTranslation();
  const { getLocalStorage, saveLocalStorage } = useLocalStorage();
  const { logOut } = useAuth();

  const [reloadApp, setReloadApp] = useAtom(triggerReloadApp);
  const [, setSubId] = useAtom(idSubItem);

  const changeDataUser = async (newClientId) => {
    try {
      const dataUserLocal = await getLocalStorage('@s');
      const updatedCurrentUser = {
        ...dataUserLocal,
        user: {
          ...dataUserLocal.user,
          client_id: newClientId.client_id,
        },
      };
      return saveLocalStorage('@s', updatedCurrentUser);
    } catch {
      return logOut();
    }
  };

  const { mutate: mutateUpdateClientUser, isLoading: loadingUpdateClientUser } =
    useMutation({
      mutationFn: async (dataUpdate) => {
        return await updateClientUser(dataUpdate);
      },
      onSuccess: (data) => {
        changeDataUser(data);
        setSubId(false);
        setReloadApp(!reloadApp);
      },
      onError: () => {
        errorToast(t('usersAgentsList.editUser.messages.errorMessage'));
      },
    });

  return { mutateUpdateClientUser, loadingUpdateClientUser };
};
