import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/FormComponents/ButtonComponent';
import {
  ContainerRemoveRoutingProfile,
  FormRemoveRoutingProfile,
} from './styled';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { errorToast, successToast } from '@components/FormComponents/Toast';
import { TitleComponent } from '@components/layout/TitleComponent';
import { dataRoutesProfileSelected } from '@/store/UsersAgentsStore/userAgentsStore';
import { useState } from 'react';

export const RemoveRoutingProfile = ({
  closeDialog,
  handleDeleteItem,
  loadingDeleteItem,
  goToScreens,
}) => {
  const { t } = useTranslation();
  const [dataRouteSelected, setRouteSelected] = useAtom(
    dataRoutesProfileSelected
  );

  const [hasExistingRoutes, setHasExistingRoutes] = useState(false);

  const handleRemoveRoutingProfile = async (e) => {
    e.preventDefault();

    const { id } = dataRouteSelected;

    handleDeleteItem(id, {
      onSuccess: () => {
        successToast(
          t('routingProfile.removeRoutingProfile.messages.successRemoveProfile')
        );
        closeDialog();
        setRouteSelected(null);
      },
      onError: (err) => {
        if (
          err.response.data.message === 'There is an existing relationship!'
        ) {
          return setHasExistingRoutes(true);
        }
        return errorToast(
          t('routingProfile.removeRoutingProfile.messages.errorRemoveProfile')
        );
      },
    });
  };

  const titleRemove = !hasExistingRoutes
    ? t('routingProfile.removeRoutingProfile.title')
    : t('routingProfile.removeRoutingProfile.title-error-remove');

  return (
    <ContainerRemoveRoutingProfile>
      {loadingDeleteItem && <BackDropCircularLoading />}

      <TitleComponent textTitle={titleRemove} iconTitle='routingProfile' />
      {!hasExistingRoutes ? (
        <FormRemoveRoutingProfile onSubmit={handleRemoveRoutingProfile}>
          <p>
            {t('routingProfile.removeRoutingProfile.removeSentence')}{' '}
            <strong>{dataRouteSelected?.name ?? ''}</strong>
          </p>
          <Stack direction={`row`} gap={1} justifyContent={'flex-end'}>
            <Button typeText variant={'secondary'} onClick={closeDialog}>
              {t('routingProfile.removeRoutingProfile.btn-cancel')}
            </Button>
            <Button typeText type='submit'>
              {t('routingProfile.removeRoutingProfile.btn-confirm')}
            </Button>
          </Stack>
        </FormRemoveRoutingProfile>
      ) : (
        <FormRemoveRoutingProfile
          onSubmit={handleRemoveRoutingProfile}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <p>{t('routingProfile.removeRoutingProfile.msg-remove-screens')}</p>
          <Stack direction={`row`} gap={1} justifyContent={'space-between'}>
            <Button typeText onClick={() => goToScreens(dataRouteSelected)}>
              {t('routingProfile.removeRoutingProfile.btn-toScreens')}
            </Button>

            <Button typeText variant={'secondary'} onClick={closeDialog}>
              OK
            </Button>
          </Stack>
        </FormRemoveRoutingProfile>
      )}
    </ContainerRemoveRoutingProfile>
  );
};
