import styled from 'styled-components';

export const ContainerTableCallingEfforts = styled.div`
  height: 100%;
  flex: 1;
  overflow: auto;
  table {
    width: 100%;
    tbody {
      tr {
        td {
          height: 40px;
          padding-left: 10px;

          &:nth-child(1) {
            min-width: 200px;
            width: 20%;
          }

          &:nth-child(2) {
            min-width: 80px;
          }

          &:nth-child(3) {
            min-width: 80px;
          }

          &:nth-child(4) {
            min-width: 80px;
          }

          &:nth-child(5) {
            min-width: 80px;
            width: 8%;
          }

          &:nth-child(6) {
            min-width: 200px;
            width: 20%;
          }

          &:nth-child(7) {
            width: 8%;
          }

          &:nth-child(8) {
            min-width: 140px;
            width: 14%;
          }
        }
      }
    }
  }
`;
