import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { RiDraggable } from 'react-icons/ri';
import { ContainerItemTimeConditional } from './styled';

export const Item = ({
  typeCondition,
  valueCondition,
  handleRemoveCondition,
}) => {
  const { t } = useTranslation();
  const renderLabelItemCondition = (typeItem, labelItem) => {
    let translate = '';
    if (typeItem === 'period') {
      translate += t(
        `flowBuilder.flow.nodes.conditions.menu.label-${labelItem}`
      );
      let hasErrorTranslate = translate.includes('flowBuilder.flow.nodes.');
      return <span>{hasErrorTranslate ? labelItem : translate}</span>;
    }

    translate += t(
      `flowBuilder.flow.nodes.conditions.menu.weekdays.label-day-${labelItem}`
    );
    let hasErrorTranslate = translate.includes('flowBuilder.flow.nodes.');
    return <span>{hasErrorTranslate ? labelItem : translate}</span>;
  };

  return (
    <ContainerItemTimeConditional typeHandler={typeCondition}>
      <div className='descriptionTime'>
        <RiDraggable />
        {renderLabelItemCondition(typeCondition, valueCondition)}
      </div>
      <div className='panel-item'>
        <button
          onClick={handleRemoveCondition}
          whileTap={{ scale: 0.85 }}
          type='button'
          title={t('flowBuilder.flow.nodes.conditions.menu.title-btn-remove')}
        >
          <IoIosCloseCircleOutline size={'17px'} />
        </button>
      </div>
    </ContainerItemTimeConditional>
  );
};
