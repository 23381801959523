import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const TableContentExpanded = styled.table`
  width: 100%;
  user-select: text;

  @media (max-width: 600px) {
    width: 300px;
  }

  thead {
    tr {
      user-select: none;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 40px;
        background-color: ${(props) => props.theme.colors.tables.tableHead};
        border-radius: 3px;
        pointer-events: none;
      }

      th {
        div {
          position: relative;
          z-index: 2;
          min-width: 60px;
          text-align: initial;

          font-size: 0.8rem;
          font-weight: 500;
          text-transform: uppercase;
          color: ${(props) => props.theme.colors.typography.textHeaderTable};

          &:nth-child(1) {
            padding-left: 10px;
          }

          @media (max-width: 1300px) {
            font-size: 0.8rem;
            padding: 15px 5px;

            &:nth-child(1),
            &:nth-child(2) {
              min-width: 40px;
            }
          }

          @media (max-width: 799px) {
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  .content_th {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.7rem 0px 6px 0px;
    svg {
      font-size: 1rem;
    }
  }
`;

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const Tr = styled.tr`
  position: relative;

  ${isSafari
    ? css`
        td {
          position: relative;
          z-index: 1;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: ${(props) => props.theme.colors.tables.tableRow};
            box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.15);
            pointer-events: none;
            z-index: -1;
          }
          &:first-child::before {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child::before {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      `
    : css`
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: ${(props) => props.theme.colors.tables.tableRow};
          border-radius: 5px;
          box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.15);
          pointer-events: none;
        }
      `}
`;

export const Td = styled.td`
  position: relative;
  z-index: 1;
  min-width: 100px;
  padding: 10px;
  text-transform: lowercase;

  .content-details-log {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }

  &:nth-child(3) {
    text-transform: capitalize;
  }

  span {
    font-size: 0.8rem;
    font-weight: normal;
    word-wrap: normal;
    color: ${(props) => props.theme.colors.typography.baseText};

    @media (max-width: 799px) {
      font-size: 0.7rem;
    }
  }

  .editRow {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      color: ${(props) => props.theme.colors.bases.baseWhite};
      padding: 4px;
      border-radius: 4px;

      &:first-child {
        background-color: ${(props) => props.theme.colors.error};
      }
      &:last-child {
        background-color: ${(props) => props.theme.colors.bases.primary};
      }
    }
  }

  .flex_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      color: ${(props) => props.theme.colors.secondary};
      font-size: 1.1rem;
      margin-right: 10px;
    }
  }
`;

export const TrExpanded = styled.tr`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(232, 232, 232, 0.09);

    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.15);
    z-index: -1;
  }

  /* &:hover:after {
    background-color: ${(props) =>
    props.theme.colors.containers.colorCardSecondary}AA;
  } */
`;

export const ExpandedContainerTable = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
  padding: 1rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: -3px;
    top: 0px;
    width: 3px;
    height: 92%;
    background-color: ${(props) => props.theme.colors.defaultColors.blue};
  }

  .item-expanded {
    display: flex;
    flex-direction: row;
    gap: 4px;

    span {
      font-size: 0.8rem;
      font-weight: normal;
      word-wrap: normal;
      color: ${(props) => props.theme.colors.typography.baseText};

      &:nth-child(1) {
        font-weight: bold !important;
      }
    }
  }
`;

export const TdNoData = styled.td`
  background-color: ${(props) => props.theme.colors.tables.tableRow};
  border-radius: 3px;
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    svg {
      color: ${(props) => props.theme.colors.defaultColors.orange};
    }

    span {
      color: ${(props) => props.theme.colors.typography.baseText};
      font-size: 0.9rem;
      padding-top: 3px;
    }
  }
`;
