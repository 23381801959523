import { apiData } from '@/services/api';

export async function createGroupDDD(dataCreate) {
  try {
    const { data } = await apiData.post(
      'dialingRules/createDDDManagerMaster',
      dataCreate
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
