import { memo } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { MdRadioButtonChecked } from 'react-icons/md';
import { IoLockClosed } from 'react-icons/io5';
import { AiFillTool } from 'react-icons/ai';
import { BiSolidCheckCircle } from 'react-icons/bi';
import { selectedQueue } from '@/store/DialingStore';
import { CardStatusQueue } from './styled';
import smLogoVoice from '@/assets/imgs/icon-sm-voicce.svg';

export const CardStatusComponent = ({
  dataQueue,
  changeFlowDialing,
  mutateDataInfoQueue,
}) => {
  const { t } = useTranslation();
  const [, setSelectedQueue] = useAtom(selectedQueue);

  const renderStatusQueue = (statusQueue) => {
    switch (statusQueue) {
      case 'VAZIO':
        return 0;
      case 'MANUTENCAO':
        return 0;
      case 'FECHADO':
        return 1;
      case 'PARADO':
        return 1;
      case 'ABERTO':
        return 2;
      case 'DISCANDO':
        return 2;
      default:
        return 0;
    }
  };

  const generatorText = (text) => {
    switch (text) {
      case 'VAZIO':
        return 'option-empty';
      case 'MANUTENCAO':
        return 'option-empty';
      case 'FECHADO':
        return 'option-closed';
      case 'PARADO':
        return 'option-closed';
      case 'ABERTO':
        return 'option-discing';
      case 'DISCANDO':
        return 'option-discing';
      default:
        return 'option-empty';
    }
  };

  const generatorIconStatus = (statusQueue) => {
    switch (statusQueue) {
      case 'VAZIO':
        return <MdRadioButtonChecked className={'colorNull'} />;
      case 'MANUTENCAO':
        return <AiFillTool className={'colorNull'} />;
      case 'FECHADO':
        return <IoLockClosed className={'colorClosed'} />;
      case 'PARADO':
        return <IoLockClosed className={'colorClosed'} />;
      case 'ABERTO':
        return <BiSolidCheckCircle className={'colorDiscing'} />;
      case 'DISCANDO':
        return <BiSolidCheckCircle className={'colorDiscing'} />;
      default:
        return <MdRadioButtonChecked className={'colorNull'} />;
    }
  };

  const handleDescriptionQueue = (queue) => {
    mutateDataInfoQueue(queue.fila, {
      onSuccess: () => {
        setSelectedQueue(queue);
        changeFlowDialing(1);
      },
    });
  };

  return (
    <CardStatusQueue
      whileTap={{ scale: 0.99 }}
      onClick={() => handleDescriptionQueue(dataQueue)}
      statusQueue={renderStatusQueue(dataQueue.estado)}
    >
      <div className='contentImage'></div>

      <div className='contentCard'>
        <div className='title-area-card'>
          <img src={smLogoVoice} alt='voicce' width={'20px'} height={'20px'} />
          <span>
            <strong>{dataQueue.campanha}</strong>
          </span>
        </div>
        <div className='status-area-card'>
          {generatorIconStatus(dataQueue.estado)}
          <span>{t(`dialing.${generatorText(dataQueue.estado)}`)}</span>
        </div>
      </div>
      <span>{dataQueue.fila}</span>
    </CardStatusQueue>
  );
};

export const CardStatus = memo(CardStatusComponent);
