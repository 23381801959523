import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import { ContentHeaderCard } from './styled';
import { useEffect, useRef, useState } from 'react';
import { BiSolidPhoneOutgoing } from 'react-icons/bi';
import { RiMessage3Fill } from 'react-icons/ri';

export const HeaderCard = ({
  nameQueue,
  campaign,
  talking,
  discing,
  statusQueue,
}) => {
  const { t } = useTranslation();
  const refStatus = useRef(null);
  const [statusQueueRender, setStatusQueueRender] = useState(0);

  useEffect(() => {
    if (statusQueue) {
      switch (statusQueue) {
        case 'DISCANDO':
          setStatusQueueRender(0);
          break;
        case 'LIVRE':
          setStatusQueueRender(1);
          break;
        case 'PAUSA':
          setStatusQueueRender(2);
          break;
      }
    }
  }, [statusQueue]);

  const generateStatus = (status) => {
    switch (status) {
      case 'DISCANDO':
        return t('monitorVirtualAgents.queueDialing').toUpperCase();
      case 'LIVRE':
        return t('monitorVirtualAgents.queueFree').toUpperCase();
      case 'PAUSA':
        return t('monitorVirtualAgents.queuePaused').toUpperCase();
    }
  };

  return (
    <ContentHeaderCard status={statusQueueRender}>
      <div className='left-info'>
        <span className='title_row'>
          <strong>{nameQueue}</strong> - {campaign}
        </span>
        <div className='status-header'>
          <BsCircleFill />
          <span ref={refStatus}>
            {t('monitorVirtualAgents.title-statusQueue')}{' '}
            {generateStatus(statusQueue)}
          </span>
        </div>
      </div>
      <div className='right-info'>
        <div className='inf-agent'>
          <div className='talking-header'>
            <RiMessage3Fill />
            <span>
              {t('monitorVirtualAgents.agentsTalking')}:
              <strong> {talking < 10 ? `0${talking}` : talking}</strong>
            </span>
          </div>
          <div className='dialing-header'>
            <BiSolidPhoneOutgoing />
            <span>
              {t('monitorVirtualAgents.agentsDiscing')}:
              <strong> {discing < 10 ? `0${discing}` : discing}</strong>
            </span>
          </div>
        </div>
      </div>
    </ContentHeaderCard>
  );
};
