import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { DialogComponent } from '@/components/DialogComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ContainerConfirmationToExchange } from './styled';

export const ConfirmationToChangePage = ({
  hasOpem,
  handleClose,
  confirmCallback,
}) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    confirmCallback();
    handleClose();
  };

  return (
    <DialogComponent open={hasOpem} onClose={handleClose} fullWidth={true}>
      <ContainerConfirmationToExchange>
        <Stack direction={'column'} spacing={4} padding={'2rem 1rem'}>
          <span className='text-change'>
            {t('dashboard.confirmationExchange.label-confirmationToChangePage')}
          </span>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
            <Button typeText variant={'secondary'} onClick={handleClose}>
              {t('dashboard.confirmationExchange.btn-cancel')}
            </Button>
            <Button typeText onClick={handleConfirm}>
              {t('dashboard.confirmationExchange.btn-next')}
            </Button>
          </Stack>
        </Stack>
      </ContainerConfirmationToExchange>
    </DialogComponent>
  );
};
