import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { useAtom } from 'jotai';
import {
  dataCampaignsReports,
  dataListsReports,
  dataSelectedReportsCount,
  stageCountInList,
} from '@/store/Reports/countInListStore';
import { getCountListByListService } from '@/services/reports/countInList/getCountListByListService';
import { useTranslation } from 'react-i18next';
import { getListsCountInListService } from '@/services/reports/countInList/getListsCountInListService';
import { getCampaignsCountInListService } from '@/services/reports/countInList/getCampaignsCountInListService';
import { getCountListByCampaignsService } from '@/services/reports/countInList/getCountListByCampaignsService';
import { getListDispositionDownload } from '@/services/reports/countInList/getListDispositionDownload';

export const useCountInList = () => {
  const { t } = useTranslation();
  const [stage] = useAtom(stageCountInList);
  const [, setListReports] = useAtom(dataListsReports);
  const [, setCampaignsReports] = useAtom(dataCampaignsReports);
  const [, setSelectedReportsCounts] = useAtom(dataSelectedReportsCount);

  const { isLoading: loadingCampaignsReports } = useQuery(
    'getCampaignsReports',
    async () => {
      return await getCampaignsCountInListService();
    },
    {
      enabled: stage === 0,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setCampaignsReports(data);
      },
      onError: () => errorToast(t('reports.countInList.msg-error-getCampaign')),
    }
  );

  const { isLoading: loadingListsReports } = useQuery(
    'getListsReports',
    async () => {
      return await getListsCountInListService();
    },
    {
      enabled: stage === 0,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setListReports(data);
      },
      onError: () => errorToast(t('reports.countInList.msg-error-getLists')),
    }
  );

  const {
    mutate: mutateListDataCountList,
    isLoading: loadingListDataCountList,
  } = useMutation({
    mutationFn: async (dataCountList) => {
      return await getCountListByListService(dataCountList);
    },
    onSuccess: (data) => setSelectedReportsCounts(data),
    onError: () => {
      return errorToast(t('reports.countInList.msg-error-getCountReports'));
    },
  });

  const {
    mutate: mutateCampaignDataCountList,
    isLoading: loadingCampaignDataCountList,
  } = useMutation({
    mutationFn: async (dataCountList) => {
      return await getCountListByCampaignsService(dataCountList);
    },
    onSuccess: (data) => setSelectedReportsCounts(data),
    onError: () => {
      return errorToast(t('reports.countInList.msg-error-getCountReports'));
    },
  });

  const {
    mutate: mutateGetDataDownloadList,
    isLoading: loadingGetDataDownloadList,
  } = useMutation({
    mutationFn: async (dispositionToDownload) => {
      return await getListDispositionDownload(dispositionToDownload);
    },
    onError: () => {
      return errorToast(t('reports.countInList.msg-error-getLists'));
    },
  });

  return {
    loadingCampaignsReports,
    loadingListsReports,
    mutateListDataCountList,
    loadingListDataCountList,
    mutateGetDataDownloadList,
    loadingGetDataDownloadList,
    mutateCampaignDataCountList,
    loadingCampaignDataCountList,
  };
};
