import styled from 'styled-components';
import { motion } from 'framer-motion';

export const MainContainer = styled(motion.div)`
  flex: 1;
  height: 100%;
  z-index: 0;
  background-color: ${(props) => props.theme.colors.containers.baseBackground};
  position: relative;
`;
