import { Handle, Position } from 'reactflow';
import { useAtom } from 'jotai';
import React, { memo, useCallback, useMemo } from 'react';
import { MdPhonelinkSetup } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { FlexBoxCl } from '../styled';
import { NodeContent, NodeDtmfContainer } from './styled';
import { shortNameRender } from '../../../../utils/shortNameRender';
import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { PanelNode } from '@/components/pages/FlowBuilder/components/PanelNode';

const DtmfNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [, setSelectedNode] = useAtom(selectedNode);

  const handleNode = useCallback(() => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  }, [id, data, setSelectedNode, setShowMenu]);

  const renderHeightNode = useMemo(() => {
    const totalItems = data?.listDtmf?.length ?? 0;
    const heightItem = 28;
    const extraHeight = totalItems > 5 ? 20 : 0;
    const totalHeight =
      totalItems <= 1 ? 200 : 174 + totalItems * heightItem + extraHeight;
    return `${totalHeight}px`;
  }, []);

  return (
    <NodeDtmfContainer
      selected={selected}
      onDoubleClick={() => handleNode()}
      errorConnect={data.errorConnect ? 1 : 0}
      heighNode={renderHeightNode}
      handler={data.handler}
    >
      <PanelNode data={data} id={id} />
      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <MdPhonelinkSetup size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 8)}</h4>
            <span>DTMF</span>
          </div>
        </div>
        <div className='main'>
          <FlexBoxCl>
            <span>
              <strong>
                {t('flowBuilder.flow.nodes.dtmf.menu.label-message')}:
              </strong>
            </span>
            <div className='field-prompt-render'>
              <span>
                {data.prompt
                  ? data.prompt
                  : `${t(
                      'flowBuilder.flow.nodes.dtmf.menu.placeholder-message'
                    )}:`}
              </span>
            </div>
          </FlexBoxCl>
        </div>
      </NodeContent>
    </NodeDtmfContainer>
  );
};

export const DtmfNode = memo(DtmfNodeComponent);
