import styled from 'styled-components';
import { ContainerNodeWrapper, ContentNodeWrapper } from '../styled';

export const NodeTabulationContainer = styled(ContainerNodeWrapper)`
  position: relative;
  background: ${(props) => props.theme.colors.containers.colorSecondNode};

  .react-flow__handle {
    &::after {
      background: #3250b3;
    }
  }

  .react-flow__handle-left {
    left: -7px;
  }

  .react-flow__handle-right {
    right: -9px;
  }
`;

export const NodeContent = styled(ContentNodeWrapper)`
  width: 220px;

  .header {
    background: ${(props) => props.theme.colors.nodesFlow.setVariables};
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    gap: 0.6rem;

    .no-variables-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
      border: 1px solid #80808052;
      border-radius: 3px;
      padding: 2px;
      svg {
        font-size: 1rem;
        color: ${(props) => props.theme.colors.nodesFlow.setVariables};
      }
      span {
        font-size: 0.75rem;
        color: #808080;
        font-weight: 600;
      }
    }

    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.4rem;

      li {
        width: 100%;
        background-color: ${(props) =>
          props.theme.colors.containers.colorCardSecondary};
        border-radius: 2px;
        padding: 4px 4px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.2rem;

        list-style: none;

        svg {
          color: green;
          font-size: 0.78rem;
        }

        span {
          font-size: 0.78rem;
          color: ${(props) => props.theme.colors.typography.baseTitle};
          font-weight: 600;
          padding-top: 4px;
        }

        .responseValueVar {
          color: gray;
          font-weight: 400;
          font-style: italic;
          font-size: 0.65rem;
        }
      }
    }
  }
`;
