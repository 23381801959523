import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

export const NodeFromCsvContainer = styled.div`
  background: ${(props) => props.theme.colors.listNodes.bgCard};
  width: 200px;
  height: 40px;
  border-radius: 3px;

  border: 1px solid ${(props) => props.theme.colors.defaultColors.black};
  .react-flow__handle {
    background: transparent;
    width: 6%;
    height: 100%;
    border-radius: 0;
    border: none;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -1px;
      transform: translateY(-50%);
      border-radius: 10px;
      width: 8px;
      height: 8px;
      border: 2px solid ${(props) => props.theme.colors.defaultColors.white};
      background: ${(props) =>
        props.connected
          ? props.theme.colors.defaultColors.black
          : props.theme.colors.active};
    }
  }
`;

export const ContentNode = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: ${(props) =>
      props.imported
        ? props.theme.colors.defaultColors.black
        : props.theme.colors.active};
  }

  .mainContent {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;

    h4 {
      font-size: 0.74rem;
      color: ${(props) => props.theme.colors.typography.baseText};
      overflow: hidden;
      width: 90%;
      text-wrap: nowrap;
    }

    div {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2px;
      overflow: hidden;

      svg {
        font-size: 0.2rem;
        color: ${(props) => props.theme.colors.typography.baseText}AA;
      }
      span {
        font-size: 0.7rem;
        color: ${(props) => props.theme.colors.typography.baseText}AA;
        text-wrap: nowrap;
      }
    }

    .not-found-column {
      color: ${(props) => props.theme.colors.defaultColors.orange}AA;
    }
  }

  .indicatorConnect {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
