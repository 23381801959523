import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormNewQueue = styled(motion.form)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 30px;
  gap: 1rem;

  .main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.8rem;
  }
`;
