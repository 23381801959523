import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerListReports = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  table {
    position: relative !important;
    thead {
      position: sticky;
      top: 0;
      z-index: 99999;
    }

    tbody {
      tr {
        height: 40px;

        td {
          z-index: 1;
          padding: 0 0.8rem;

          &:first-child {
            width: 5px;
          }

          &:nth-child(2) {
            min-width: 200px;
            width: 10%;
          }
          &:nth-child(3) {
            min-width: 200px;
            width: 40%;
          }
          &:nth-child(4) {
            min-width: 200px;
            width: 20%;
          }
          &:nth-child(5) {
            min-width: 200px;
            width: 20%;
          }

          &:last-child {
            width: 10%;
            div {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              svg {
                color: white;
              }
            }
          }
        }
      }
    }

    tfoot {
      position: sticky;
      bottom: 0;
      z-index: 99999;

      .content-download-all {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 4px;
        padding-right: 8px;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          color: ${(props) => props.theme.colors.bases.baseWhite};
          padding: 4px;
          border-radius: 4px;

          &:first-child {
            background-color: ${(props) => props.theme.colors.error};
          }
          &:last-child {
            background-color: ${(props) => props.theme.colors.bases.primary};
          }
        }
      }

      @media (max-width: 500px) {
        position: relative;
        tr {
          &::after {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    margin-top: 0.675rem;
    max-height: calc(100vh - 300px);
    table tbody,
    table tr,
    table td {
      display: block;
      width: 100%;
    }

    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          margin-bottom: 12px;
          width: 100%;
          min-width: 100%;
          height: auto;
          padding: 1rem 0;
          border-left: 3px solid ${(props) => props.theme.colors.primary};

          td {
            padding-left: 50%;
            text-align: left;
            position: relative;
            padding-bottom: 6px;

            &:nth-child(2) {
              min-width: 100%;
              overflow: none;
              text-overflow: initial;
            }

            &:last-child {
              padding-left: 54%;
            }

            span {
              font-size: 12px;
            }

            &:first-child {
              &::before {
                content: '';
                position: absolute;
                left: 0;
                padding-left: 15px;
                text-align: left;
                font-size: 12px;
                color: ${(props) => props.theme.colors.typography.baseText};
                line-height: 1.2;
                font-weight: bold;
              }
            }

            &::before {
              content: attr(data-label) ':';
              position: absolute;
              left: 0;
              padding-left: 15px;
              text-align: left;
              font-size: 12px;
              color: ${(props) => props.theme.colors.typography.baseText};
              line-height: 1.2;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;
