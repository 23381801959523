import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import {
  ContainerChartAttendance,
  ContentChartAttendance,
  HeaderChartAttendance,
} from './styled';
import { useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import { formatDate } from '@/utils/formatsDateAndTime';
import { parse } from 'date-fns';

const initialData = {
  total: 0,
  contato_positivo: 0,
  contato_efetivo: 0,
};

export const FunnelAttendance = ({ selectedQueue, dataStatusQueues }) => {
  const { t } = useTranslation();
  const matches = useMediaQuery('(max-width:1550px)');
  const [heightChart, setHeightChart] = useState(200);

  const isEmpty = Object.keys(dataStatusQueues).length === 0;

  const listaOrdenada = Object.entries(isEmpty ? initialData : dataStatusQueues)
    .sort((a, b) => b[1] - a[1])
    .map(([label, value]) => ({ label, value }));

  const filterLabels = listaOrdenada.map((item) => {
    switch (item.label) {
      case 'total':
        return t('dashboardPage.chartFunnel.label-total');
      case 'contato_efetivo':
        return t('dashboardPage.chartFunnel.label-contato_efetivo');
      case 'contato_positivo':
        return t('dashboardPage.chartFunnel.label-contato_positivo');

      default:
        return item.label;
    }
  });

  const filterValuesBarChart = listaOrdenada.map((item, i) => {
    switch (i) {
      case 0:
        return 500;
      case 1:
        return 300;
      case 2:
        return 140;
      default:
        return 140;
    }
  });

  const filterLabel = (dataLabel) =>
    listaOrdenada
      .filter((item) => item.label === dataLabel)
      .map((item) => item.value)[0];

  const renderValueLabel = (dataLabel) => {
    switch (dataLabel) {
      case t('dashboardPage.chartFunnel.label-total'):
        return `${dataLabel}: ${
          filterLabel('total') ? filterLabel('total') : 0
        }`;
      case t('dashboardPage.chartFunnel.label-contato_efetivo'):
        return `${dataLabel}: ${
          filterLabel('contato_efetivo') ? filterLabel('contato_efetivo') : 0
        }`;
      case t('dashboardPage.chartFunnel.label-contato_positivo'):
        return `${dataLabel}: ${
          filterLabel('contato_positivo') ? filterLabel('contato_positivo') : 0
        }`;
      default:
        return `${dataLabel}: ${
          filterLabel(dataLabel) ? filterLabel(dataLabel) : 0
        }`;
    }
  };

  const renderToolTip = (dataLabel, label) => {
    return listaOrdenada[label.dataPointIndex].value;
  };

  useEffect(() => {
    if (matches) {
      return setHeightChart(210);
    }
    setHeightChart(222);
  }, [matches]);

  const configChart = {
    series: [
      {
        name: '',
        data: filterValuesBarChart,
      },
    ],

    options: {
      chart: {
        type: 'bar',
        height: heightChart,
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 200,
          animateGradually: {
            enabled: true,
            delay: 200,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 200,
          },
        },
      },
      colors: ['#4c48ff', '#6351f7', '#7a5bef'],
      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: true,
          distributed: true,
          barHeight: '90%',
          isFunnel: true,
        },
      },
      tooltip: {
        marker: {
          show: true,
        },
        y: {
          formatter: function (data, label) {
            return renderToolTip(data, label);
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val, opt) =>
          renderValueLabel(opt.w.globals.labels[opt.dataPointIndex]),

        dropShadow: {
          enabled: false,
        },
      },

      xaxis: {
        categories: filterLabels,
      },
    },
  };

  const renderSelectedQueue = selectedQueue.map((item, i) => {
    const totalLength = selectedQueue.length - 1;
    if (totalLength === i) {
      return <span key={i}>{item.queue}</span>;
    }
    return <span key={i}>{item.queue},</span>;
  });

  const renderDataSelected = () => {
    if (selectedQueue.length > 0) {
      const firstDate = parse(
        selectedQueue[0].first_date,
        'yyyy-MM-dd',
        new Date()
      );

      const endDate = parse(
        selectedQueue[0].last_date,
        'yyyy-MM-dd',
        new Date()
      );

      if (formatDate(firstDate) === formatDate(endDate)) {
        return formatDate(firstDate);
      }

      return `${formatDate(firstDate)} - ${formatDate(endDate)}`;
    }

    const currentDate = new Date();
    return formatDate(currentDate);
  };

  return (
    <ContainerChartAttendance>
      <HeaderChartAttendance>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <h2>{t('dashboardPage.title-chartFunnel')}</h2>
          <span>{renderDataSelected()}</span>
        </Stack>
        <Stack direction={'row'} spacing={'4px'}>
          {renderSelectedQueue}
        </Stack>
      </HeaderChartAttendance>
      <ContentChartAttendance>
        <Chart
          options={configChart.options}
          series={configChart.series}
          width={'100%'}
          height={heightChart}
          type='bar'
        />
      </ContentChartAttendance>
    </ContainerChartAttendance>
  );
};
