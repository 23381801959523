import { DatePicker } from '@mui/x-date-pickers';
import styled from 'styled-components';

export const StyledDataPicker = styled(DatePicker)`
  width: 100%;

  input {
    padding-top: 22px;
  }

  label {
    color: ${(props) => props.theme.colors.typography.baseText};
    /* font-size: 1rem; */
    font-size: 0.9rem;
    top: -7px;
  }

  .MuiInputLabel-shrink {
    top: 2px;
  }

  .MuiButtonBase-root .MuiMenuItem-gutters {
    /* color: ${(props) => props.theme.colors.typography.baseText}; */
  }

  .MuiInputBase-input {
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .Mui-focused {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }

  &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.typography.baseText};
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.error
        ? props.theme.colors.defaultColors.orange
        : props.theme.colors.typography.baseText};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  svg {
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .MuiOutlinedInput-root {
    max-height: 40px !important;
  }
`;
