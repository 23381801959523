import { apiData } from '@/services/api';

export async function getSecurityProfileList() {
  try {
    const { data } = await apiData.get('securityProfiles/listSecurityProfiles');
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
