import { apiData } from '@/services/api';

export async function getListDispositionDownload(dataDisposition) {
  try {
    const { data } = await apiData.post(
      '/reports/listCountDownload',
      dataDisposition
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
