import { apiData } from '../api';

export async function getMetadataTemplates(campaignName, signal) {
  try {
    const data = await apiData.get(`dialer/listBuilderLists/${campaignName}`, {
      signal,
    });
    return data.data;
  } catch (error) {
    throw new Error(error);
  }
}
