import { Accordion } from '@mui/material';
import styled from 'styled-components';

export const ContentListPermissions = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const AccordionsStyled = styled(Accordion)`
  background-color: ${(props) =>
    props.theme.colors.containers.colorCard} !important;

  svg {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }
`;

export const TitleAccordion = styled.h3`
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors.typography.baseText};
`;
