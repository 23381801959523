import { apiData } from '../api';

export async function convertGsmToWav(urlData) {
  try {
    const { data } = await apiData.post('dialer/convertRecording', urlData, {
      responseType: 'arraybuffer',
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
