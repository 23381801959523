import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FormMenuNode = styled(motion.form)`
  width: 320px;
  height: 100%;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr 40px;
  gap: 1rem;
  overflow: auto;
`;

export const MainAddNode = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem 0 0 0.5rem;

  h4 {
    font-size: 0.7rem;
    font-weight: 400;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
  }
  .itens_valid_input {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;

    .contentMessageList {
      user-select: none;
      background-color: ${(props) =>
        props.theme.colors.containers.baseBackground};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      width: 100%;
      border-radius: 3px;

      span {
        text-transform: uppercase;
        font-size: 0.8rem;
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }
  }
`;

export const ContainerItem = styled.div`
  background: #607a9d;
  box-shadow: 6px 4px 5px -3px rgba(0, 0, 0, 0.24);

  width: 100%;
  padding: 4px;
  border-radius: 3px;
  display: grid;
  grid-template-columns: 1fr 40px;
  .description {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    svg {
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
      font-size: 0.85rem;
    }
    span {
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
      font-weight: 600;
      padding-top: 3px;
    }
  }

  .panel-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 4px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;

      svg {
        font-size: 0.9rem;
        color: ${(props) => props.theme.colors.bases.baseWhite};
      }
    }
  }
`;
