import { apiData } from '@/services/api';

export async function updateServerControl(dataUpdateControl) {
  try {
    const { id, dataUpdated } = dataUpdateControl;
    const { data } = await apiData.post(
      `servers/updateServer/${id}`,
      dataUpdated
    );
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
