import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { ControlInput } from '@components/FormComponents/InputComponent';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { dataQueueOperators } from '@/store/DialingStore';
import { ConfigQueueTable } from './styled';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const TableConfigQueue = ({ dataQueueSelected, control, errors }) => {
  const { t } = useTranslation();
  const [operatorsCampaign] = useAtom(dataQueueOperators);

  return (
    <ConfigQueueTable>
      <ControlInput
        control={control}
        nameControl={'totalLinesAvailable'}
        label={t('dialing.configQueue.tdConfigFirst')}
        defaultValue={dataQueueSelected.maxLinhasTotal}
        type={'number'}
        inputProps={{ min: 0 }}
        error={Boolean(errors.totalLinesAvailable)}
      />
      <ControlInput
        control={control}
        nameControl={'usedLines'}
        label={t('dialing.configQueue.tdConfigSecond')}
        defaultValue={dataQueueSelected.max_linhas}
        type={'number'}
        inputProps={{ min: 0 }}
        error={Boolean(errors.usedLines)}
      />
      <ControlInput
        control={control}
        nameControl={'maximumLinesByAgent'}
        label={t('dialing.configQueue.tdConfigThird')}
        defaultValue={dataQueueSelected.max_linha_por_agente}
        type={'number'}
        inputProps={{ min: 0 }}
        error={Boolean(errors.maximumLinesByAgent)}
      />

      {operatorsCampaign.length > 0 && (
        <ControlSelect
          control={control}
          nameControl={'landline'}
          label={t('dialing.configQueue.tdConfigFourth')}
          defaultValue={dataQueueSelected.grupo_master_linhas ?? '0'}
          error={Boolean(errors.landline)}
        >
          <ItemSelect value={'0'} disabled>
            {t('dialing.configQueue.label-operator')}
          </ItemSelect>
          {operatorsCampaign.length > 0 &&
            operatorsCampaign.map((item, i) => (
              <ItemSelect value={item.grupo_master} key={i}>
                {item.grupo_master} - {item.nome_operadora}
              </ItemSelect>
            ))}
        </ControlSelect>
      )}

      {operatorsCampaign.length > 0 && (
        <ControlSelect
          control={control}
          nameControl={'mobilePhone'}
          label={t('dialing.configQueue.tdConfigFifth')}
          defaultValue={dataQueueSelected.grupo_master_linhas_2 ?? '0'}
          error={Boolean(errors.mobilePhone)}
        >
          <ItemSelect value={'0'} disabled>
            {t('dialing.configQueue.label-operator')}
          </ItemSelect>
          {operatorsCampaign.length > 0 &&
            operatorsCampaign.map((item, i) => (
              <ItemSelect value={item.grupo_master} key={i}>
                {item.grupo_master} - {item.nome_operadora}
              </ItemSelect>
            ))}
        </ControlSelect>
      )}
    </ConfigQueueTable>
  );
};
