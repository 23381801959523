import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';

import {
  schedulesList,
  selectedScheduleQueue,
} from '@/store/ScheduleStore/SchedulerList';
import { getSchedulesByList } from '@/services/dialControl/schedules/scheduleList/getSchedulesByList';
import { createSchedulesList } from '@/services/dialControl/schedules/scheduleList/createSchedulesList';
import { editSchedulesList } from '@/services/dialControl/schedules/scheduleList/editSchedulesList';
import { deleteSchedulesList } from '@/services/dialControl/schedules/scheduleList/deleteSchedulesList';

export const useSchedulesList = () => {
  const { t } = useTranslation();
  const [, setScheduleList] = useAtom(schedulesList);
  const [selectedSchedule] = useAtom(selectedScheduleQueue);

  const {
    mutate: mutateDetailsScheduleList,
    isLoading: loadingDetailsScheduleQueue,
  } = useMutation({
    mutationFn: async (dataDetailSchedule) => {
      return await getSchedulesByList(dataDetailSchedule);
    },
    onSuccess: (data) => setScheduleList(data),
    onError: () =>
      errorToast(
        t('dialControl.schedulesQueue.listSchedules.errorMessageList')
      ),
  });

  const { mutate: mutateCreateSchedule, isLoading: loadingCreateSchedule } =
    useMutation({
      mutationFn: async (dataCreateSchedule) => {
        return await createSchedulesList(dataCreateSchedule);
      },
      onSuccess: (data) => {
        mutateDetailsScheduleList(data[0].fila);
      },
      onError: () =>
        errorToast(t('dialControl.schedulesList.createSchedule.error-create')),
    });

  const { mutate: mutateEditSchedule, isLoading: loadingEditSchedule } =
    useMutation({
      mutationFn: async (dataEditSchedule) => {
        return editSchedulesList(dataEditSchedule);
      },
      onSuccess: (data) => {
        mutateDetailsScheduleList(data.fila);
      },
      onError: () =>
        errorToast(t('dialControl.schedulesList.editSchedule.error-update')),
    });

  const { mutate: mutateDeleteSchedule, isLoading: loadingDeleteSchedule } =
    useMutation({
      mutationFn: async (dataDeleteSchedule) => {
        return deleteSchedulesList(dataDeleteSchedule);
      },
      onSuccess: () => {
        mutateDetailsScheduleList(selectedSchedule.fila);
      },
      onError: () =>
        errorToast(t('dialControl.schedulesList.editSchedule.error-remove')),
    });

  return {
    mutateDetailsScheduleList,
    loadingDetailsScheduleQueue,
    mutateCreateSchedule,
    loadingCreateSchedule,
    mutateEditSchedule,
    loadingEditSchedule,
    mutateDeleteSchedule,
    loadingDeleteSchedule,
  };
};
