import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerAddNewRoute = styled(motion.div)`
  width: 100%;
  height: 95%;
  display: grid;
  grid-template-rows: auto 1fr;

  @media (max-height: 1550px) {
    height: 92%;
  }
`;
