import { useAtom } from 'jotai';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { openDialogInfoStatus } from '@/store/DialingStore';
import { DialogComponent } from '@components/DialogComponent';
import { DetailQueue } from './DetailListQueue';
import { ContainerTable } from './styled';
import { TablePagination } from '@/components/Tables/TablePagination';
import { useDialingQueues } from '@/hooks/Dialing/useDialingQueues';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

export const TableQueue = ({ dataInfoQueue }) => {
  const { t } = useTranslation();
  const { mutateDetailList, loadingDetailList } = useDialingQueues();

  const [openDialog, setOpenDialog] = useAtom(openDialogInfoStatus);
  const [dataInfoStatus, setDataInfoStatus] = useState({});

  const tableQueueRef = useRef(null);

  const handleDialogInfo = (dataRowSelected) => {
    const dataDetails = {
      queue: dataInfoQueue.queueInfo.fila,
      list: dataRowSelected.id_tabela_lista,
      campaign: dataInfoQueue.queueInfo.campanha,
      position: dataRowSelected.posicao,
    };

    mutateDetailList(dataDetails, {
      onSuccess: (data) => {
        setOpenDialog(true);
        setDataInfoStatus(data);
      },
    });
  };

  const headerColumnDataTable = [
    {
      Header: t('dialing.tableDiscingQueue.headerFirst'),
      accessor: 'fila',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: t('dialing.tableDiscingQueue.headerSecond'),
      accessor: 'posicao',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: t('dialing.tableDiscingQueue.headerThird'),
      accessor: 'id_tabela_lista',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: '1',
      accessor: 'cod_lig1',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: '2',
      accessor: 'cod_lig2',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: '3',
      accessor: 'cod_lig3',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: '4',
      accessor: 'cod_lig4',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: '5',
      accessor: 'cod_lig5',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: '6',
      accessor: 'cod_lig6',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: '7',
      accessor: 'cod_lig7',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: '8',
      accessor: 'cod_lig8',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: '9',
      accessor: 'cod_lig9',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: '10',
      accessor: 'cod_lig10',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
    {
      Header: t('dialing.tableDiscingQueue.headerFourteenth'),
      accessor: 'fone_pos',
      Cell: (values) => (
        <div onClick={() => handleDialogInfo(values.cell.row.values)}>
          {values.value ?? '-'}
        </div>
      ),
    },
  ];

  return (
    <ContainerTable ref={tableQueueRef}>
      {loadingDetailList && <BackDropCircularLoading />}
      <TablePagination
        dataColumns={headerColumnDataTable}
        dataTable={dataInfoQueue?.positions}
        textNoData={t('usersAgentsList.table.noData')}
        rowsPerPageDisplay={11}
        pagination={false}
      />

      <DialogComponent
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth={true}
      >
        <DetailQueue selectedList={dataInfoStatus} />
      </DialogComponent>
    </ContainerTable>
  );
};
