import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { getUFManager } from '@/services/dialControl/UFManager/getUFManager';
import { useAtom } from 'jotai';
import { dataUFManager, stageUFManager } from '@/store/DialingStore';
import { createGroupUF } from '@/services/dialControl/UFManager/createGroupUF';
import { deleteGroupUF } from '@/services/dialControl/UFManager/deleteGroupUF';
import { getSelectedUFManager } from '@/services/dialControl/UFManager/getSelectedUFManager';
import { updateUFManager } from '@/services/dialControl/UFManager/updateUFManager';
import { createScheduleUF } from '@/services/dialControl/UFManager/createScheduleUF';
import { deleteSelectedUFManager } from '@/services/dialControl/UFManager/deleteSelectedUFManager';
import { updateUFGroup } from '@/services/dialControl/UFManager/updateUFGroup';

export const useUFManager = () => {
  const { t } = useTranslation();
  const [, setListUFManager] = useAtom(dataUFManager);
  const [currentStageUf] = useAtom(stageUFManager);

  const {
    isLoading: loadingDataUFs,
    refetch: refetchUFs,
    isFetching: fetchingUFs,
  } = useQuery(
    'getUFsList',
    async () => {
      return await getUFManager();
    },
    {
      enabled: currentStageUf === 0,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => setListUFManager(data),
      onError: () =>
        errorToast(t(`dialControl.ufManager.list.msg-error-getLists`)),
    }
  );

  const { mutate: mutateCreateGroupUF, isLoading: loadingCreateGroupUF } =
    useMutation({
      mutationFn: async (dataSchedule) => {
        return await createGroupUF(dataSchedule);
      },
      onSuccess: () => {
        refetchUFs();
      },
      onError: (error) => {
        const errorMessage = error.message || JSON.stringify(error);
        const toastMessage =
          errorMessage === 'This UF manager already exists.'
            ? t('dialControl.ufManager.create.msg-error-alreadyExists')
            : t(`dialControl.ufManager.create.msg-error-created`);
        return errorToast(toastMessage);
      },
    });

  const { mutate: mutateUpdateGroupUF, isLoading: loadingUpdateGroupUF } =
    useMutation({
      mutationFn: async (dataGroup) => {
        return await updateUFGroup(dataGroup);
      },
      onSuccess: () => {
        refetchUFs();
      },
      onError: () => {
        errorToast(t(`dialControl.dddManager.edit.msg-error-updatedGroup`));
      },
    });

  const { mutate: mutateDeleteGroupUF, isLoading: loadingDeleteGroupUF } =
    useMutation({
      mutationFn: async (idGroupDelete) => {
        return await deleteGroupUF(idGroupDelete);
      },
      onSuccess: () => {
        refetchUFs();
      },
      onError: () => {
        errorToast(t(`dialControl.ufManager.list.msg-error-delete`));
      },
    });

  const { mutate: mutateGetDataUF, isLoading: loadingGetDataUF } = useMutation({
    mutationFn: async (dataOperator) => {
      return await getSelectedUFManager(dataOperator);
    },
    onError: () => {
      errorToast(t(`dialControl.ufManager.list.msg-error-getLists`));
    },
  });

  const { mutate: mutateCreateScheduleUF, isLoading: loadingCreateScheduleUF } =
    useMutation({
      mutationFn: async (dataSchedule) => {
        return await createScheduleUF(dataSchedule);
      },
      onError: () => {
        errorToast(
          t(`dialControl.ufManager.create.msg-error-createdScheduler`)
        );
      },
    });

  const { mutate: mutateUpdatedUF, isLoading: loadingUpdatedUF } = useMutation({
    mutationFn: async (dataUpdate) => {
      return await updateUFManager(dataUpdate);
    },
    onError: () => {
      errorToast(t(`dialControl.ufManager.edit.msg-error-updatedScheduler`));
    },
  });

  const { mutate: mutateDeleteScheduleUF, isLoading: loadingDeleteScheduleUF } =
    useMutation({
      mutationFn: async (dataSchedule) => {
        return await deleteSelectedUFManager(dataSchedule);
      },
      onError: () => {
        errorToast(t(`dialControl.ufManager.create.msg-error-deleteScheduler`));
      },
    });

  return {
    loadingDataUFs,
    fetchingUFs,
    mutateCreateGroupUF,
    loadingCreateGroupUF,
    mutateDeleteGroupUF,
    loadingDeleteGroupUF,
    mutateGetDataUF,
    loadingGetDataUF,
    mutateCreateScheduleUF,
    loadingCreateScheduleUF,
    mutateUpdatedUF,
    loadingUpdatedUF,
    mutateDeleteScheduleUF,
    loadingDeleteScheduleUF,
    mutateUpdateGroupUF,
    loadingUpdateGroupUF,
  };
};
