import styled from 'styled-components';
import { ContainerNodeWrapper, ContentNodeWrapper } from '../styled';

export const NodeConditionContainer = styled(ContainerNodeWrapper)`
  position: relative;
  background: ${(props) => props.theme.colors.containers.colorSecondNode};
  height: ${(props) => props.heighNode};

  .react-flow__handle {
    &::after {
      background: #1266f8;
    }
  }
`;

export const NodeContent = styled(ContentNodeWrapper)`
  min-width: 220px;

  .header {
    background: #1266f8;
  }
`;
