import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContentTableSystemQueue = styled(motion.div)`
  width: 100%;
  height: 100%;
  min-height: 200px;
  user-select: none;
  padding-top: 20px;

  .item-status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    svg {
      font-size: 0.9rem;
      color: ${(props) => props.theme.colors.primary};
    }
  }

  .unselected-row {
    span {
      color: ${(props) => props.theme.colors.typography.baseText}AA;
      opacity: 0.8;
    }
  }

  .selected-row {
    height: 58px;
    background-color: ${(props) =>
      props.theme.colors.containers.baseContainer}AA;
    span {
      color: ${(props) => props.theme.colors.typography.baseText};
      font-weight: 600 !important;
    }
  }

  .disabled-item {
    svg {
      font-size: 0.95rem;
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  table {
    tbody {
      tr {
        height: 42px;
        transition: all 0.1s ease;

        td {
          z-index: 1;
          padding: 0 0.8rem;

          &:nth-child(1) {
            width: 31%;
          }

          &:nth-child(2) {
            width: 31%;
          }

          &:nth-child(3) {
            width: 32%;
          }

          &:last-child {
            width: 7%;
            button {
              border-radius: 3px;
              padding: 0;
              height: 20px;
              width: 20px;
              min-width: 20px !important;
            }
          }
        }
      }
    }
  }
`;
