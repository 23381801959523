import styled, { css } from 'styled-components';

export const ContainerListActivityLog = styled.div`
  width: 100%;
  height: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;

  .content-details-log {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      all: unset;
      width: 24px;
      height: 24px;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
    }
  }

  table {
    thead {
      position: sticky;
      top: 0;
      z-index: 100;
      tr {
        user-select: none;
        position: relative;
      }
    }
    tbody {
      tr {
        height: 44px;
        td {
          z-index: 1;
          padding: 0 0.8rem;

          :nth-child(1) {
            min-width: 200px;
          }

          :nth-child(5) {
            min-width: 200px;
          }

          :last-child {
            width: 10px;
          }
        }
      }
    }
  }
`;

export const ContentHttpCode = styled.span`
  text-transform: uppercase;
  color: white !important;
  line-height: 10px;
  padding: 4px 3px 2px 3px;
  border-radius: 2px;
  font-size: 0.75rem !important;

  ${(props) =>
    props.typeCode === 'POST' &&
    css`
      background-color: ${props.theme.colors.defaultColors.blue};
    `}

  ${(props) =>
    props.typeCode === 'GET' &&
    css`
      background-color: ${props.theme.colors.success};
    `}

    ${(props) =>
    props.typeCode === 'PUT' &&
    css`
      background-color: ${props.theme.colors.defaultColors.yellow};
    `}

    ${(props) =>
    props.typeCode === 'DELETE' &&
    css`
      background-color: ${props.theme.colors.defaultColors.orange};
    `}
`;
