import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './locale/en.json';
import ptBRTranslations from './locale/pt.json';

const dataLocalLanguage = localStorage.getItem('lang');

i18next.use(initReactI18next).init({
  resources: {
    en: { ...enTranslations },
    pt: { ...ptBRTranslations },
  },
  lng: dataLocalLanguage
    ? dataLocalLanguage
    : import.meta.env.VITE_LANGUAGE_DEFAULT ?? 'pt',
});
