import { useState } from 'react';
import { useAtom } from 'jotai';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { dataQueues } from '@/store/Queues';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { useQueues } from '@/hooks/dialControl/Queues/useQueues';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { NoDataStatus } from './NoDataStatus';
import { CardStatus } from './CardStatus';
import { useSchedulesQueue } from '@/hooks/dialControl/Schedules/useSchedulesQueue';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import {
  ContainerCampaignSchedules,
  ContentPanelCampaigns,
  ContentStatusCampaigns,
} from './styled';

export const ListQueueSchedules = ({ controlStage }) => {
  const { t } = useTranslation();
  const { loadingDataQueues } = useQueues();
  const { loadingDetailsScheduleQueue, mutateDetailsScheduleQueue } =
    useSchedulesQueue();
  const [listQueues] = useAtom(dataQueues);

  const [filterQueue, setFilterQueue] = useState('');

  const renderTxt = (word) => {
    return t(`dialControl.schedulesQueue.listQueues.${word}`);
  };

  const filterByQueue =
    listQueues && listQueues.filter((q) => q.fila === filterQueue);

  const renderQueues =
    listQueues && filterByQueue.length === 0 ? listQueues : filterByQueue;

  const isLoading = loadingDetailsScheduleQueue || loadingDataQueues;

  return (
    <ContainerCampaignSchedules
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {isLoading && <BackDropCircularLoading />}
      <ContentPanelCampaigns>
        <Box width={'100%'}>
          <SelectComponent
            labelSelect={renderTxt('label-queue')}
            value={filterQueue}
            onChange={(event) => setFilterQueue(event.target.value)}
          >
            <ItemSelect value={'0'}>
              {renderTxt('options-all-queue')}
            </ItemSelect>
            {listQueues &&
              listQueues.map((item, i) => (
                <ItemSelect value={item.fila} key={i}>
                  {item.fila} - {item.campanha}
                </ItemSelect>
              ))}
          </SelectComponent>
        </Box>
      </ContentPanelCampaigns>
      <ContentStatusCampaigns
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
      >
        {listQueues && renderQueues.length > 0 ? (
          renderQueues.map((item, i) => (
            <CardStatus
              key={i}
              dataQueue={item}
              changeFlowDialing={controlStage.nextStage}
              handleDetailScheduler={mutateDetailsScheduleQueue}
            />
          ))
        ) : (
          <NoDataStatus />
        )}
      </ContentStatusCampaigns>
    </ContainerCampaignSchedules>
  );
};
