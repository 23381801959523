import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import { ButtonAudio, ContainerWavesurfer, PlayerTimes } from './styled';
import {
  IoPauseCircleOutline,
  IoPlayCircleOutline,
  IoReloadCircleOutline,
  IoStopCircleOutline,
} from 'react-icons/io5';
import { Stack } from '@mui/system';
import { Box, Slider } from '@mui/material';
import { useTheme } from 'styled-components';
import {
  BsFillVolumeMuteFill,
  BsFillVolumeUpFill,
  BsVolumeDownFill,
} from 'react-icons/bs';

const Waveform = ({ audio }) => {
  const theme = useTheme();
  const containerRef = useRef();
  const waveSurferRef = useRef({
    isPlaying: () => false,
  });
  const volumeInputAudio = useRef();
  const [isPlaying, toggleIsPlaying] = useState(false);
  const [showPanel, toggleShowPanel] = useState(false);
  const [volumeAudio, setVolumeAudio] = useState(75);

  const handleChange = useCallback(
    (event, newValue) => {
      setVolumeAudio(newValue);
    },
    [volumeAudio]
  );

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      waveColor: theme.colors.containers.dividerColor,
      height: 30,
      progressColor: theme.colors.primary,
      cursorColor: theme.colors.primary,
      barWidth: 2,
      barGap: 1,
      barRadius: 2,
    });

    waveSurfer.load(audio);

    waveSurfer.on('ready', () => {
      waveSurferRef.current = waveSurfer;
      toggleIsPlaying(true);
      toggleShowPanel(true);
      waveSurfer.play();
    });

    waveSurfer.on('finish', () => {
      toggleIsPlaying(false);
      waveSurfer.seekTo(0);
    });
    return () => {
      waveSurfer.destroy();
    };
  }, []);

  useEffect(() => {
    const checkRef = Object.keys(waveSurferRef.current).length > 2;
    if (checkRef) {
      waveSurferRef.current.setVolume(volumeAudio / 100);
    }
  }, [volumeAudio, waveSurferRef]);

  const renderVolumeIcon = (levelVolume) => {
    if (!levelVolume || levelVolume === 0) {
      return <BsFillVolumeMuteFill size={30} color={theme.colors.primary} />;
    }
    if (levelVolume <= 35) {
      return <BsVolumeDownFill size={30} color={theme.colors.primary} />;
    }
    return <BsFillVolumeUpFill size={30} color={theme.colors.primary} />;
  };

  return (
    <Stack spacing={2} direction='column'>
      <div ref={waveSurferRef}></div>
      <div ref={containerRef} />

      {showPanel && (
        <PlayerTimes>
          <Stack
            direction={'row'}
            width={'100%'}
            justifyContent={'space-between'}
          >
            <ContainerWavesurfer>
              <Box
                display={'flex'}
                alignItems={'center'}
                className='panel-volume'
                gap={'12px'}
                width={'80px'}
              >
                {renderVolumeIcon(volumeAudio)}
                <Slider
                  aria-label='Volume'
                  size='small'
                  value={volumeAudio}
                  onChange={handleChange}
                  ref={volumeInputAudio}
                  sx={{
                    '& .MuiSlider-thumb': {
                      color: theme.colors.primary,
                    },
                    '& .MuiSlider-track': {
                      color: theme.colors.primary,
                    },
                    '& .MuiSlider-rail': {
                      color: theme.colors.containers.dividerColor,
                    },
                  }}
                />
              </Box>
            </ContainerWavesurfer>

            <Box display={'flex'}>
              <ButtonAudio
                whileTap={{ scale: 0.95 }}
                type='button'
                onClick={() => {
                  waveSurferRef.current.seekTo(0);
                }}
              >
                <IoReloadCircleOutline />
              </ButtonAudio>

              <ButtonAudio
                styledType='play'
                whileTap={{ scale: 0.95 }}
                type='button'
                onClick={() => {
                  waveSurferRef.current.playPause();
                  toggleIsPlaying(waveSurferRef.current.isPlaying());
                }}
              >
                {isPlaying ? <IoPauseCircleOutline /> : <IoPlayCircleOutline />}
              </ButtonAudio>

              <ButtonAudio
                whileTap={{ scale: 0.95 }}
                disabled={!isPlaying}
                type='button'
                onClick={() => {
                  waveSurferRef.current.stop();
                  waveSurferRef.current.seekTo(0);
                }}
              >
                <IoStopCircleOutline />
              </ButtonAudio>
            </Box>
          </Stack>
        </PlayerTimes>
      )}
    </Stack>
  );
};

Waveform.propTypes = {
  audio: PropTypes.string.isRequired,
};

export default Waveform;
