import { useState } from 'react';
import { useAtom } from 'jotai';
import { Stack } from '@mui/system';
import { FaGear } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { ContentConfig, FooterConfig } from '../../styled';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { dataNlus, templateSelected } from '@/store/FlowBuilder';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useEndpoints } from '@/hooks/FlowBuilder/useEndpoints';
import { DragTransferListConfigs } from '@/components/pages/FlowBuilder/components/DragTransferListConfigs';

export const ListNlus = ({
  dataNlus: listNlus,
  handleClose,
  changeFlowConfigState,
  usedEndpoints,
}) => {
  const { t } = useTranslation();
  const { loadingNlus, isFetchingNlus } = useEndpoints();
  const [nlus] = useAtom(dataNlus);
  const [, setTemplate] = useAtom(templateSelected);
  const [dataNlusSelected, setDatNlusSelected] = useState([]);

  const renderAvailableList = () => {
    const availableList = nlus
      .map((element) => {
        const filterUsedVars = listNlus.filter(
          (item) => item.name === element.name
        );
        if (filterUsedVars.length > 0) {
          return null;
        }
        return element;
      })
      .filter((item) => Boolean(item));

    return availableList;
  };

  const handleUpdateEndpoints = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        nlus: dataNlusSelected,
      };
    });

    handleClose();
    return;
  };

  const isLoading = isFetchingNlus || loadingNlus;

  return (
    <ContentConfig>
      {isLoading && <BackDropCircularLoading />}
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4>{t('flowBuilder.configs.endpoints.title-endPoint')}</h4>
      </Stack>
      {nlus.length > 0 ? (
        <DragTransferListConfigs
          codeList={renderAvailableList()}
          changeDataCodeList={setDatNlusSelected}
          dataSelected={listNlus}
          checkRemoveData={usedEndpoints}
          typeList={'Endpoints'}
        />
      ) : (
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={'100%'}
          sx={{ opacity: 0.4 }}
        />
      )}
      <FooterConfig>
        <Button typeText onClick={() => changeFlowConfigState(1)}>
          {t('flowBuilder.configs.endpoints.btn-add-endPoint')}
        </Button>
        <Stack direction={'row'} spacing={1}>
          <Button typeText variant='secondary' onClick={handleClose}>
            {t('flowBuilder.configs.btn-cancel')}
          </Button>
          <Button typeText onClick={handleUpdateEndpoints}>
            {t('flowBuilder.configs.btn-update')}
          </Button>
        </Stack>
      </FooterConfig>
    </ContentConfig>
  );
};
