import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerIframe = styled(motion.div)`
  width: 100%;
  height: 100%;

  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  user-select: none;
  padding: 2rem;
  overflow: auto;
  position: relative;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 92.6%;
  border: none;
  padding: 1rem !important;
`;

export const ErrorMessage = styled.div`
  width: 100%;
  color: red;
  text-align: center;
`;
