import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerFlowMailing = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 2rem;
  grid-template-rows: 20px 1fr 40px;
`;

export const ContentListCsv = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 3px;

  .react-flow__attribution {
    display: none;
  }

  .react-flow__handle {
    background: transparent;
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
  }

  .edgebutton {
    width: 26px;
    height: 26px;
    background: ${(props) => props.theme.colors.containers.colorNode};
    border: 2px solid gray;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
    opacity: 0;
  }

  .edgebutton:hover {
    opacity: 1;
  }

  .errorAlert {
    position: relative;
    z-index: 100;
  }
`;
