import styled from 'styled-components';
import BgFlow from '@/assets/imgs/bg-flow.webp';

export const ContainerFlowConstructor = styled.div`
  width: 100%;

  display: grid;
  grid-template-rows: 30px 1fr;
  gap: 0.4rem;

  .panel-control-flow {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    button {
      all: unset;
      display: flex;
      background-color: ${(props) => props.theme.colors.primary};
      padding: 3px;
      border-radius: 4px;
      svg {
        color: white;
        font-size: 1.2rem;
      }

      :disabled {
        opacity: 0.5;
      }
    }
  }
`;

export const ContentFlow = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  border: 1px solid ${(props) => props.theme.colors.containers.baseContainer};
  border-radius: 4px;
  position: relative;
  grid-column: 1/3;
  overflow: hidden;

  .bg-water-mark {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    background-image: url(${BgFlow});
    background-size: cover;
  }
`;
