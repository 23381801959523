import { useCallback, useState } from 'react';
import { PanelList } from './PanelList';
import { ContainerListActive } from './styled';

import { TableActiveRecordings } from './TableActiveRecordings';
import { dataQueueRecordings } from '@/store/Recordings/PanelRecords';
import { useAtom } from 'jotai';

export const ListActiveRecording = ({ controlStage }) => {
  const [dataRecording] = useAtom(dataQueueRecordings);
  const [searchField, setSearchField] = useState('');

  const usedCampaignList =
    dataRecording.length > 0 ? dataRecording[0].campanha : '-';

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  const filterDataListActive = useCallback(
    (dataFilter) => {
      if (dataFilter) {
        const filterByName = dataFilter.filter((data) =>
          data.nome_cliente.toLowerCase().includes(searchField.toLowerCase())
        );

        const filterByDisposition = dataFilter.filter((data) =>
          data.cod_lig.toLowerCase().includes(searchField.toLowerCase())
        );
        const filterByPhone = dataFilter.filter((data) =>
          data.fone.toLowerCase().includes(searchField.toLowerCase())
        );

        const filterByCPF = dataFilter.filter((data) =>
          data.cpf.toLowerCase().includes(searchField.toLowerCase())
        );

        const newArrFiltered = [
          ...filterByName,
          ...filterByDisposition,
          ...filterByCPF,
          ...filterByPhone,
        ];
        return removeDuplicates(newArrFiltered).sort(
          (a, b) => new Date(b.atende_dt) - new Date(a.atende_dt)
        );
      }
    },
    [searchField]
  );

  const filteredData = filterDataListActive(dataRecording);

  return (
    <ContainerListActive initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <PanelList
        dataFilters={{ searchField, setSearchField }}
        backStage={controlStage.toPanel}
        usedCampaignList={usedCampaignList}
      />
      <TableActiveRecordings dataTable={filteredData} />
    </ContainerListActive>
  );
};
