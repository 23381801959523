import { useId, useState } from 'react';
import { ContainerQueueManagement } from './styled';
import { PropsQueue } from './stages/PropsQueue';
import { ListQueuesManagement } from './stages/ListQueuesManagement';
import { NewQueue } from './stages/NewQueue';

export const QueueManagement = () => {
  const [currentStageManagement, setCurrentStageManagement] = useState(0);

  const controlStage = {
    toStage: (stage) => setCurrentStageManagement(stage),
  };

  const flowQueueManagement = [
    <ListQueuesManagement key={useId()} controlStage={controlStage} />,
    <PropsQueue key={useId()} controlStage={controlStage} />,
    <NewQueue key={useId()} controlStage={controlStage} />,
  ];

  return (
    <ContainerQueueManagement>
      {flowQueueManagement[currentStageManagement]}
    </ContainerQueueManagement>
  );
};
