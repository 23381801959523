import { useTranslation } from 'react-i18next';
import { BsGearFill } from 'react-icons/bs';
import { MdControlPointDuplicate } from 'react-icons/md';
import { RiAddCircleLine, RiIndeterminateCircleLine } from 'react-icons/ri';
import { Button } from '@components/FormComponents/ButtonComponent';
import { ControlBtnsTable } from './styled';
import { useAtom } from 'jotai';
import { sysAdmin } from '@/store/AuthenticatorStore';
import { dataInfoQueue } from '@/store/DialingStore';

export const ControlsDataQueue = ({
  setOpenRemoveConfirm,
  mutateReplicateList,
  mutateAdvanceList,
  changeFlowDialing,
}) => {
  const { t } = useTranslation();
  const [infoDataQueue] = useAtom(dataInfoQueue);

  const [isSysAdmin] = useAtom(sysAdmin);

  const handleBackList = () => {
    changeFlowDialing(2);
  };

  return (
    <ControlBtnsTable>
      <div className='management_queue'>
        <Button
          disabled={!isSysAdmin}
          onClick={handleBackList}
          title={t('dialing.btn-managementQueue')}
        >
          <BsGearFill size={20} />
        </Button>
      </div>
      <div className='management_table'>
        <Button
          disabled={!isSysAdmin}
          title={t('dialing.btn-addList')}
          onClick={() =>
            mutateAdvanceList({
              queue: infoDataQueue.queueInfo.fila,
            })
          }
        >
          <RiAddCircleLine size={20} />
        </Button>
        <Button
          disabled={!isSysAdmin}
          title={t('dialing.btn-replicateList')}
          onClick={() =>
            mutateReplicateList({
              queue: infoDataQueue.queueInfo.fila,
            })
          }
        >
          <MdControlPointDuplicate size={20} />
        </Button>
        <Button
          disabled={!isSysAdmin}
          variant={'secondary'}
          title={t('dialing.btn-clearList')}
          onClick={() => setOpenRemoveConfirm(true)}
        >
          <RiIndeterminateCircleLine size={20} />
        </Button>
      </div>
    </ControlBtnsTable>
  );
};
