import styled from 'styled-components';

export const ContainerLineControl = styled.div`
  width: 100%;
  height: 100%;

  flex: 1;

  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 116px 1fr;
  gap: 2rem;
  user-select: none;

  overflow: auto;

  @media (max-width: 1200px) {
    overflow: scroll;
  }

  @media (max-height: 450px) {
    height: 85%;
  }
`;

export const MainLineControl = styled.div`
  width: 100%;
  flex: 1;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`;
