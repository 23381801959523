import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerPanelQueueMonitor = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  .content-time {
    display: flex;
    justify-content: flex-end;
    span {
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.baseText}AA;
    }
  }
`;
