import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { getCampaigns } from '@/services/dialControl/campaigns/getCampaigns';
import { getListsDialing } from '@/services/dialControl/lists/getListsDialing';
import { useAtom } from 'jotai';

import { user } from '@/store/AuthenticatorStore';
import { useTranslation } from 'react-i18next';
import {
  dataCampaignsCallingEfforts,
  dataListsCallingEfforts,
  stageCallingEfforts,
} from '@/store/Reports/callingEffortsStore';
import { geCallingEffortsService } from '@/services/reports/mailingPenetration/geCallingEffortsService';

export const useCallingEfforts = () => {
  const { t } = useTranslation();
  const [stage] = useAtom(stageCallingEfforts);
  const [currentUser] = useAtom(user);
  const [, setListCallingEfforts] = useAtom(dataListsCallingEfforts);
  const [, setCampaignsCallingEfforts] = useAtom(dataCampaignsCallingEfforts);

  const { isLoading: loadingCampaignsReports } = useQuery(
    'getCampaignsReports',
    async () => {
      return await getCampaigns();
    },
    {
      enabled: stage === 0,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        const filterClientIdCampaigns = currentUser.userRole.isSysAdmin
          ? data
          : data.filter((item) => item.id_cliente === currentUser.client_id);
        setCampaignsCallingEfforts(filterClientIdCampaigns);
      },
      onError: () =>
        errorToast(
          t(`reports.callingEfforts.searchCallingEfforts.msg-erro-getCampaigns`)
        ),
    }
  );

  const { isLoading: loadingListsReports } = useQuery(
    'getListsReports',
    async () => {
      return await getListsDialing();
    },
    {
      enabled: stage === 0,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setListCallingEfforts(data);
      },
      onError: () =>
        t(`reports.callingEfforts.searchCallingEfforts.msg-erro-getLists`),
    }
  );

  const {
    mutate: mutateCallingEffortsList,
    isLoading: loadingCallingEffortsList,
  } = useMutation({
    mutationFn: async (dataCountList) => {
      return await geCallingEffortsService(dataCountList);
    },

    onError: () => {
      return errorToast(t('reports.countInList.msg-erro-reports'));
    },
  });

  return {
    loadingCampaignsReports,
    loadingListsReports,
    mutateCallingEffortsList,
    loadingCallingEffortsList,
  };
};
