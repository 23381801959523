import { memo } from 'react';
import { NoDataTableRow } from './styled';
import { BiErrorCircle } from 'react-icons/bi';

const NoDataTableComponent = ({ textContent = '' }) => {
  return (
    <NoDataTableRow initial={{ opacity: '0' }} animate={{ opacity: '1' }}>
      <td>
        <div className='contentNoData'>
          <BiErrorCircle />
          <span>{textContent}</span>
        </div>
      </td>
    </NoDataTableRow>
  );
};

export const NoDataTable = memo(NoDataTableComponent);
