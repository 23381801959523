import { apiData } from '../api';

export async function advanceListQueue(listReplicate) {
  try {
    const { queue } = listReplicate;
    const { data } = await apiData.put(`dialer/jumpToNextList/${queue}`);
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
