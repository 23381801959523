import { io } from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { user } from '@/store/AuthenticatorStore';
import { errorToast } from '@/components/FormComponents/Toast';

export const useSocketQueueMonitor = () => {
  const { t } = useTranslation();
  const [, setSocket] = useState(null);
  const [dataQueueMonitor, setDataQueueMonitor] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [hasErrorSocket, setHasErrorSocket] = useState(false);
  const [currentUser] = useAtom(user);
  const [hasErrorSocketConnect, setHasErrorSocketConnect] = useState(false);

  const url = import.meta.env.VITE_BASE_URL;
  const timeCheckErros = 6000;

  useEffect(() => {
    if (hasErrorSocketConnect) {
      setHasErrorSocket(true);
      errorToast(t('queueMonitor.msg-error-socket'));
    }
  }, [hasErrorSocketConnect]);

  useEffect(() => {
    if (!hasErrorSocket) {
      const timeoutId = setTimeout(() => {
        if (dataQueueMonitor.length === 0) {
          setHasErrorSocket(true);
        }
      }, timeCheckErros);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [dataQueueMonitor, hasErrorSocket]);

  useEffect(() => {
    const newSocket = io(url, {
      query: {
        client_id: currentUser.client_id,
      },
      reconnectionAttempts: 1,
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      setIsConnected(true);
    });

    newSocket.on('connect_error', (err) => {
      return setHasErrorSocketConnect(true);
    });

    newSocket.on('error', (err) => {
      return setHasErrorSocketConnect(true);
    });

    newSocket.on('summaryUpdate', (message) => {
      try {
        if (message?.erro === 1) {
          throw new Error('Error in message data');
        }
        setDataQueueMonitor(message);
      } catch (error) {
        setHasErrorSocket(true);
      }
    });

    newSocket.on('disconnect', () => {
      setIsConnected(false);
    });

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, [url]);

  return {
    dataQueueMonitor,
    isConnected,
    hasErrorSocket,
    setHasErrorSocket,
  };
};
