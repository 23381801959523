import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useAtom } from 'jotai';
import { Alert, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TbPlugConnected } from 'react-icons/tb';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { SubstringFunction } from './SubstringFunction';
import { ReplaceFunction } from './ReplaceFunction';
import { AccordionComponent } from '@/components/layout/AccordionComponent';
import { CustomFunction } from './CustomFunction';
import { IncludeFunction } from './IncludeFunction';
import { SplitFunction } from './SplitFunction';
import { ContainerMenuNode, FooterMenu, HeaderMenu, MainMenu } from './styled';
import {
  hasSavedTemplateMailing,
  isLoadingRenderFlow,
  openMenuNodeMailing,
  selectedNodeConfig,
} from '@/store/LoadList';

import {
  includePreview,
  replacePreview,
  subStringPreview,
} from './renderFuncsPreview';
import { DialogComponent } from '@/components/DialogComponent';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const MenuNodeTemplate = ({ nodes, setNodes }) => {
  const [openMenu, setOpenMenu] = useAtom(openMenuNodeMailing);
  const [, setSelectedConfigNode] = useAtom(selectedNodeConfig);
  const [, setIsLoadingChangeHeaders] = useAtom(isLoadingRenderFlow);

  const handleCloseMenuNode = useCallback(() => {
    setOpenMenu(false);
    setSelectedConfigNode(null);
    setIsLoadingChangeHeaders(false);
  }, [setOpenMenu, setSelectedConfigNode, setIsLoadingChangeHeaders]);

  return (
    <DialogComponent
      open={openMenu}
      onClose={handleCloseMenuNode}
      fullWidth={true}
    >
      <MenuNode
        handleClose={handleCloseMenuNode}
        nodes={nodes}
        setNodes={setNodes}
      />
    </DialogComponent>
  );
};

const MenuNode = ({ handleClose, nodes, setNodes }) => {
  const { t } = useTranslation();
  const [selectedConfigNode] = useAtom(selectedNodeConfig);
  const [errorFunc, setErrorFunc] = useState([]);
  const [itemPreview, setItemPreview] = useState('');
  const [nameColumn, setNameColumn] = useState('');

  const [totalEmptyValues, setTotalEmptyValues] = useState(0);

  const [, setSaveFlowMailing] = useAtom(hasSavedTemplateMailing);

  const [expandedAccordion, setExpandedAccordion] = useState({});

  const [listFilter, setListFilter] = useState([]);

  const handleAccordionToggle = (id) => {
    setExpandedAccordion((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };

  const hasEmptyFilter = listFilter.filter(
    (item) =>
      Array.isArray(item.data) &&
      item.data.length === 4 &&
      item.data[0] === '0' &&
      item.data[1] === '0' &&
      item.data[2] === '0' &&
      item.data[3] === ''
  );

  const renderResultFilters = (dataFilters = [], previewResult = '') => {
    const generateResult = (dataFilter) => {
      switch (dataFilter[0]) {
        case 'replace':
          return replacePreview(
            previewResult,
            dataFilter[1],
            dataFilter[2],
            dataFilter[3]
          );

        case 'sub':
          return subStringPreview(
            previewResult,
            dataFilter[1],
            dataFilter[2],
            dataFilter[3]
          );

        case 'include':
          return includePreview(
            previewResult,
            dataFilter[1],
            dataFilter[2],
            dataFilter[3],
            dataFilter[4]
          );

        case 'split':
          return subStringPreview(
            previewResult,
            dataFilter[1],
            dataFilter[2],
            dataFilter[3]
          );

        default:
          return previewResult;
      }
    };

    const updatedResult = dataFilters.map((filterNode) => {
      return {
        ...filterNode,
        result: generateResult(filterNode.data),
      };
    });

    return updatedResult;
  };

  useEffect(() => {
    if (selectedConfigNode) {
      const filterNodeSource = nodes.filter(
        (node) => node.id === selectedConfigNode.idNodeConnected
      );

      setNameColumn(
        filterNodeSource.length > 0
          ? filterNodeSource[0].data.columnName !== ''
            ? filterNodeSource[0].data.columnName ||
              !filterNodeSource[0].data.columnName
            : t('listLoader.importMailing.flow.title-menu-filter', {
                nameColumn: filterNodeSource[0].data.column,
              })
          : ''
      );

      if (
        selectedConfigNode.filters.length > 0 &&
        filterNodeSource.length > 0
      ) {
        setListFilter(
          renderResultFilters(
            selectedConfigNode.filters,
            filterNodeSource[0].itemPreview
          )
        );
      }

      if (filterNodeSource.length > 0) {
        const nodeSource = filterNodeSource[0];
        setItemPreview(nodeSource.itemPreview ? nodeSource.itemPreview : '');
        setTotalEmptyValues(nodeSource.data?.totalEmptyValues || 0);
      }
    }
  }, [selectedConfigNode]);

  const handleAddFilter = () => {
    const newFilter = {
      id: uuid(),
      data: ['0', '0', '0', ''],
      sequence: listFilter.length + 1,
    };
    setListFilter((previousState) => [...previousState, newFilter]);
  };

  const renderSelectedFunc = (funcSelected, idFilter) => {
    switch (funcSelected) {
      case 'sub':
        return (
          <SubstringFunction
            errorFunc={errorFunc}
            setErrorFunc={setErrorFunc}
            itemPreview={itemPreview}
            listFilter={listFilter}
            setListFilter={setListFilter}
            idFilterRender={idFilter}
          />
        );
      case 'replace':
        return (
          <ReplaceFunction
            errorFunc={errorFunc}
            setErrorFunc={setErrorFunc}
            itemPreview={itemPreview}
            listFilter={listFilter}
            setListFilter={setListFilter}
            idFilterRender={idFilter}
          />
        );

      case 'custom':
        return (
          <CustomFunction
            errorFunc={errorFunc}
            setErrorFunc={setErrorFunc}
            itemPreview={itemPreview}
            listFilter={listFilter}
            setListFilter={setListFilter}
            idFilterRender={idFilter}
          />
        );
      case 'include':
        return (
          <IncludeFunction
            errorFunc={errorFunc}
            setErrorFunc={setErrorFunc}
            itemPreview={itemPreview}
            listFilter={listFilter}
            setListFilter={setListFilter}
            idFilterRender={idFilter}
          />
        );
      case 'split':
        return (
          <SplitFunction
            errorFunc={errorFunc}
            setErrorFunc={setErrorFunc}
            itemPreview={itemPreview}
            listFilter={listFilter}
            setListFilter={setListFilter}
            idFilterRender={idFilter}
          />
        );
      default:
        return <></>;
    }
  };

  const handleSelectChange = (id, value) => {
    setListFilter((prevListFilter) =>
      prevListFilter.map((filter) => {
        if (filter.id === id) {
          switch (value) {
            case 'sub':
              return {
                ...filter,
                data: [value, 0, itemPreview.length, ''],
              };

            case 'replace':
              return {
                ...filter,
                data: [value, '', '', ''],
              };
            case 'custom':
              return {
                ...filter,
                data: [value, '', '', ''],
              };
            case 'include':
              return {
                ...filter,
                data: [value, null, '#', 'right', ''],
              };
            case 'split':
              return {
                ...filter,
                data: [value, '#', 0, ''],
              };
            default:
              return {
                ...filter,
                data: [value, '', '', ''],
              };
          }
        }
        return filter;
      })
    );
  };

  const handleSaveNode = useCallback(() => {
    if (!selectedConfigNode) return;
    const { id } = selectedConfigNode;
    const updateNodes = nodes.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          data: {
            ...node.data,
            filters: listFilter.length > 0 ? listFilter : [],
          },
        };
      }
      return node;
    });

    setNodes(updateNodes);
    setSaveFlowMailing(false);
    handleClose();
  }, [
    selectedConfigNode,
    nodes,
    listFilter,
    setNodes,
    setSaveFlowMailing,
    handleClose,
  ]);

  const handleRemoveFilter = (idToRemove) => {
    const filteredList = listFilter
      .filter((list) => list.id !== idToRemove)
      .map((filter, i) => {
        return {
          ...filter,
          sequence: i + 1,
        };
      });
    const filteredError = errorFunc.filter((err) => err !== idToRemove);
    setErrorFunc(filteredError);
    setListFilter(filteredList);
  };

  const renderTitleFilter = (typeFilter) => {
    switch (typeFilter) {
      case '0':
        return t('listLoader.importMailing.flow.label-select-function');
      case 'sub':
        return t('listLoader.importMailing.flow.option-substring');
      case 'replace':
        return t('listLoader.importMailing.flow.option-replace');
      case 'include':
        return 'Include';
      case 'split':
        return 'Split';
      default:
        return '-';
    }
  };

  return (
    <ContainerMenuNode>
      <HeaderMenu>
        <Stack direction={'row'} alignItems={'center'} gap={3}>
          <TitleComponent
            iconTitle='csvFile'
            textTitle={nameColumn}
            style={{ width: 'auto' }}
          />
          <div className='connect-icon'>
            <TbPlugConnected size={20} />
          </div>

          <TitleComponent
            iconTitle='mailing'
            textTitle={selectedConfigNode?.label}
            style={{ width: 'auto' }}
          />
        </Stack>

        {totalEmptyValues > 0 && (
          <Alert severity='warning'>
            {t('listLoader.importMailing.flow.msg-tooltip-node', {
              totalEmpty: totalEmptyValues,
            })}
          </Alert>
        )}
      </HeaderMenu>
      <MainMenu>
        {listFilter?.map((filterType, i) => {
          const isExpanded = expandedAccordion[filterType.id] || false;
          const selectedFunc = filterType.data[0];

          return (
            <Stack key={i}>
              <AccordionComponent
                opemText={renderTitleFilter(filterType.data[0])}
                closeText={renderTitleFilter(filterType.data[0])}
                expandedContent={true}
                isExpanded={isExpanded}
                onClick={() => handleAccordionToggle(filterType.id)}
              >
                <SelectComponent
                  labelSelect={t(
                    'listLoader.importMailing.flow.label-function'
                  )}
                  value={selectedFunc}
                  onChange={(e) =>
                    handleSelectChange(filterType.id, e.target.value)
                  }
                >
                  <ItemSelect value='0' disabled>
                    {t('listLoader.importMailing.flow.option-function')}
                  </ItemSelect>
                  <ItemSelect value='sub'>
                    {t('listLoader.importMailing.flow.option-substring')}
                  </ItemSelect>
                  <ItemSelect value='replace'>
                    {t('listLoader.importMailing.flow.option-replace')}
                  </ItemSelect>
                  <ItemSelect value='include'>Include</ItemSelect>
                  <ItemSelect value='split'>Split</ItemSelect>
                </SelectComponent>
                {renderSelectedFunc(selectedFunc, filterType.id)}
                <Stack direction={'row'} justifyContent={'flex-end'}>
                  <Button
                    typeText
                    onClick={() => handleRemoveFilter(filterType.id)}
                  >
                    {t('listLoader.importMailing.flow.btn-remove-filter')}
                  </Button>
                </Stack>
              </AccordionComponent>
              <Divider />
            </Stack>
          );
        })}
        <Stack direction={'row'}>
          <Button
            typeText
            onClick={handleAddFilter}
            disabled={Boolean(errorFunc.length) || hasEmptyFilter.length > 0}
          >
            {t('listLoader.importMailing.flow.btn-add-filter')}
          </Button>
        </Stack>
      </MainMenu>
      <FooterMenu>
        <Button typeText variant='secondary' onClick={handleClose}>
          {t('listLoader.importMailing.flow.btn-cancel')}
        </Button>
        <Button
          typeText
          disabled={Boolean(errorFunc.length)}
          onClick={handleSaveNode}
        >
          {t('listLoader.importMailing.flow.btn-save')}
        </Button>
      </FooterMenu>
    </ContainerMenuNode>
  );
};
