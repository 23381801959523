import { useAtom } from 'jotai';
import { useMutation, useQuery } from 'react-query';
import { getCampaigns } from '@/services/dialControl/campaigns/getCampaigns';
import { dataLinesCampaigns } from '@/store/LineControl';
import { updateCurrentLines } from '@/services/dialControl/lineControl/updateCurrentLines';
import { releaseLines } from '@/services/dialControl/lineControl/releaseLines';

export const useLineControl = () => {
  const [, setDataCampaigns] = useAtom(dataLinesCampaigns);

  const { isLoading: loadingDataCampaigns } = useQuery(
    'getLineCampaigns',
    async () => {
      return await getCampaigns();
    },
    {
      refetchInterval: 1000 * 50,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => setDataCampaigns(data),
    }
  );

  const {
    mutate: mutateUpdateCurrentLines,
    isLoading: loadingUpdateCurrentLines,
  } = useMutation({
    mutationFn: async (dataCreateSchedule) => {
      return await updateCurrentLines(dataCreateSchedule);
    },
  });

  const { mutate: mutateReleaseLines, isLoading: loadingReleaseLines } =
    useMutation({
      mutationFn: async (dataCreateSchedule) => {
        return await releaseLines(dataCreateSchedule);
      },
    });

  return {
    loadingDataCampaigns,
    mutateUpdateCurrentLines,
    loadingUpdateCurrentLines,
    mutateReleaseLines,
    loadingReleaseLines,
  };
};
