import { t } from 'i18next';
import { ContentDataDetail } from '../../styled';

export const CardAllQueues = ({ details }) => {
  return (
    <ContentDataDetail initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div className='detail-queues'>
        <div className='item-detail'>
          <span>{t('queueMonitor.table.th-campaign')}: </span>
          <strong>
            {details.campaigns} {t('queueMonitor.table.th-campaigns')}
          </strong>
        </div>
        <div className='item-detail'>
          <span>{t('queueMonitor.table.th-list')}: </span>
          <strong>
            {details.lists} {t('queueMonitor.table.th-lists')}
          </strong>
        </div>
        <div className='item-detail operators-grid'>
          <span>Telecom: </span>
          <strong>
            {details.operators} {t('queueMonitor.table.th-operator')}
          </strong>
        </div>
        <div className='item-detail total-grid'>
          <span>Total: </span>
          <strong>
            {details.total} {t('queueMonitor.table.th-attendances')}
          </strong>
        </div>
        <div className='item-detail dispositions-grid'>
          <span>{t('queueMonitor.table.th-dispositions')}: </span>
          <strong>-</strong>
        </div>
      </div>
    </ContentDataDetail>
  );
};
