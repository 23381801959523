import { InputComponent } from '../InputComponent';
import { TimePickerStyled } from './styled';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR, enUS } from 'date-fns/locale';
import { Controller } from 'react-hook-form';

export const TimePickerComponent = ({ ...rest }) => {
  const getLang = window.localStorage.getItem('lang');

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={getLang === 'en' ? enUS : ptBR}
    >
      <TimePickerStyled
        {...rest}
        ampm={false}
        slotProps={{
          textField: {
            error: Boolean(rest.error),
          },
        }}
        slots={{
          actionBar: <InputComponent size='small' />,
        }}
      />
    </LocalizationProvider>
  );
};

export const ControlTimePicker = ({
  control,
  nameControl,
  defaultValue = '',
  ...rest
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={nameControl}
      render={({ field: { onChange, value } }) => (
        <TimePickerComponent
          label={rest.label}
          value={value}
          onChange={onChange}
          slotProps={{
            textField: {
              error: Boolean(rest.error),
              helperText: rest.helperText,
            },
          }}
          {...rest}
        />
      )}
    />
  );
};
