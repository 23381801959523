import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)`
  ${(props) =>
    props.active
      ? css`
          background-color: ${props.theme.colors.active} !important;
        `
      : css`
          background-color: ${props.theme.colors[
            props.variantstyle
          ]} !important;
        `}

  &:disabled {
    opacity: 0.6;
    color: ${(props) => props.theme.colors.bases.baseWhite} !important;
  }
`;
export const ContainerButton = styled(Button)`
  padding: 0.6rem;
  height: 26px;
  box-shadow: none;
  span {
    padding-top: 2px;
    line-height: 10px;
    font-size: 0.75rem;
  }

  &:disabled {
    opacity: 0.6;
    color: ${(props) => props.theme.colors.bases.baseWhite} !important;
  }

  ${(props) => {
    return props.variantstyle === 'primary'
      ? css`
          background-color: ${props.theme.colors.containers
            .primaryToDark} !important;
        `
      : css`
          background-color: ${props.theme.colors[
            props.variantstyle
          ]} !important;
        `;
  }}

  ${(props) =>
    props.active &&
    css`
      background-color: ${props.theme.colors.active} !important;
    `}
`;
