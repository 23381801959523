import { removeDuplicates } from '@/utils/removeDuplicateItemsArr';
import { useCallback } from 'react';

export const useQueuesMonitor = () => {
  const filterQueuesMonitor = useCallback((dataFilter, fieldFilter) => {
    if (dataFilter) {
      const filterByQueue = dataFilter.filter((data) =>
        data.fila.toString().includes(fieldFilter.toString())
      );

      const filterByTitle = dataFilter.filter((data) =>
        data.titulo.toLowerCase().includes(fieldFilter.toLowerCase())
      );
      const filterByCampaign = dataFilter.filter((data) =>
        data.campanha_descricao
          .toLowerCase()
          .includes(fieldFilter.toLowerCase())
      );
      const filterByList = dataFilter.filter((data) =>
        data.lista.toLowerCase().includes(fieldFilter.toLowerCase())
      );

      const filterByOperator = dataFilter.filter((data) =>
        data.operadora.toLowerCase().includes(fieldFilter.toLowerCase())
      );

      const newArrFiltered = [
        ...filterByQueue,
        ...filterByTitle,
        ...filterByCampaign,
        ...filterByList,
        ...filterByOperator,
      ];
      return removeDuplicates(newArrFiltered);
    }
  }, []);

  return { filterQueuesMonitor };
};
