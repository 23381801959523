import { TitleComponent } from '@/components/layout/TitleComponent';
import styled from 'styled-components';

export const ContainerTitleMenuNode = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    min-width: 30px;

    svg {
      color: ${(props) => props.theme.colors.typography.baseText} !important;
      font-size: 1.2rem;
    }
  }
`;

export const TitleMenuNode = ({ title = 'Title' }) => {
  return (
    <ContainerTitleMenuNode>
      <TitleComponent textTitle={title} />
    </ContainerTitleMenuNode>
  );
};
