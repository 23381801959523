import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';

import useWindowDimensions from '@/hooks/useWidth';
import { TablePagination } from '@/components/Tables/TablePagination';
import { ContainerTable } from './styled';
import { MdClose, MdEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { StatusIndicator } from '@/components/Tables/StatusDataRow';
import { DialogComponent } from '@/components/DialogComponent';
import { RemoveDisposition } from './RemoveDisposition';
import { useAtom } from 'jotai';
import { dataSelectedDisposition } from '@/store/DialingStore';
import { useDispositionsDialing } from '@/hooks/Dispositions/useDispositionsDialing';
import { usePermissionsDispositions } from '../../../hooks/usePermissionsDispositions';

export const TableDispositions = ({ changeStage, dataTable }) => {
  const { t } = useTranslation();
  const { permissionsDelete, permissionsEdit } = usePermissionsDispositions();
  const tableCodLigsRef = useRef(null);
  const [, setDispositionSelected] = useAtom(dataSelectedDisposition);
  const { height, width } = useWindowDimensions();
  const [heightTable, setHeightTable] = useState(0);
  const [dialogRemoveDispositions, setDialogRemoveDispositions] =
    useState(false);

  const {
    mutateDeleteUraDisposition,
    loadingDeleteUraDisposition,
    mutateDeleteMailingDisposition,
    loadingDeleteMailingDisposition,
  } = useDispositionsDialing();

  const dataRemove = {
    mutateDeleteUraDisposition,
    loadingDeleteUraDisposition,
    mutateDeleteMailingDisposition,
    loadingDeleteMailingDisposition,
  };

  const dataCodLigs = useMemo(() => dataTable, [dataTable]);

  useLayoutEffect(() => {
    if (tableCodLigsRef) {
      const tableMaxWidth = tableCodLigsRef.current.offsetHeight;
      const cellMinWidth = width < 500 ? 200 : 56;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, width]);

  const handleRemoveItem = (dataRemove) => {
    setDispositionSelected(dataRemove);
    setDialogRemoveDispositions(true);
  };

  const handleCloseDialogRemove = () => {
    setDispositionSelected(null);
    setDialogRemoveDispositions(false);
  };

  const handleEditDisposition = (dataDisposition) => {
    setDispositionSelected(dataDisposition);
    changeStage(2);
  };

  const columnsCodLigs = [
    {
      Header: t('dispositions.home.table.th-disposition'),
      accessor: 'cod_lig',
    },
    {
      Header: t('dispositions.home.table.th-description'),
      accessor: 'descricao',
    },
    {
      Header: t('dispositions.home.table.th-endCall'),
      accessor: 'endCall',
      Cell: ({ row }) => {
        return (
          <StatusIndicator
            nameLabel={t('dispositions.home.table.label-endCall')}
            status={row.original.finaliza_fone}
          />
        );
      },
    },
    {
      Header: t('dispositions.home.table.th-ends'),
      accessor: 'ends',
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-ends')}
          status={row.original.finaliza_contato}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-positive'),
      accessor: 'positive',
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-positive')}
          status={row.original.contato_positivo}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-effective'),
      accessor: 'effective',
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-effective')}
          status={row.original.contato_efetivo}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-noInteraction'),
      accessor: 'noInteraction',
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-noInteraction')}
          status={row.original.contato_sem_interacao}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-transfer'),
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-transfer')}
          status={row.original.transferencia}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-sn'),
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-sn')}
          status={row.original.padrao_sim_nao}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-agentScreenSn'),
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-agentScreenSn')}
          status={row.original.tela_agente_sn}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-sysSn'),
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-sysSn')}
          status={row.original.sistemico_sn}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-condition'),
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-condition')}
          status={row.original.condicao}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-refusal'),
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-refusal')}
          status={row.original.recusa}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-interrupted'),
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-interrupted')}
          status={row.original.interrompido}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-antFlooding'),
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-antFlooding')}
          status={row.original.anti_flooding}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-transferred'),
      Cell: ({ row }) => (
        <StatusIndicator
          nameLabel={t('dispositions.home.table.label-transferred')}
          status={row.original.transferido}
        />
      ),
    },
    {
      Header: t('dispositions.home.table.th-action'),
      Cell: ({ row }) => (
        <div className='editRow'>
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => handleRemoveItem(row.original)}
            title={t('dispositions.home.table.btn-title-remove')}
            disabled={!permissionsDelete}
          >
            <MdClose />
          </motion.button>
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => handleEditDisposition(row.original)}
            title={t('dispositions.home.table.btn-title-edit')}
            disabled={!permissionsEdit}
          >
            <MdEdit />
          </motion.button>
        </div>
      ),
    },
  ];

  return (
    <ContainerTable ref={tableCodLigsRef}>
      <TablePagination
        dataColumns={columnsCodLigs}
        dataTable={dataCodLigs}
        textNoData={t('dispositions.home.table.no-data')}
        rowsPerPageDisplay={heightTable}
      />
      <DialogComponent
        open={dialogRemoveDispositions}
        onClose={handleCloseDialogRemove}
        fullWidth={true}
      >
        <RemoveDisposition
          closeDialog={handleCloseDialogRemove}
          dataRemove={dataRemove}
        />
      </DialogComponent>
    </ContainerTable>
  );
};
