import styled from 'styled-components';

export const ContainerPanelFlow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  button {
    all: unset;
    display: flex;
    background-color: ${(props) => props.theme.colors.listNodes.bgBtnDark};
    padding: 3px;
    border-radius: 4px;
    svg {
      color: white;
      font-size: 1.2rem;
    }
  }
`;
