import {
  focusedFieldArea,
  hasModalOpen,
  isSavedFlow,
  showMenuNodes,
} from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import useCopyPaste from './useCopyPaste';

export const useKeysFlowBuilder = (dataNodes) => {
  const [isFocusedField] = useAtom(focusedFieldArea);
  const [showMenu] = useAtom(showMenuNodes);
  const [modalIsOpened] = useAtom(hasModalOpen);
  const [, setIsSaved] = useAtom(isSavedFlow);

  const { copy, paste } = useCopyPaste();

  const onKeyDown = (event) => {
    if (
      (event.key === 'Delete' || event.key === 'Backspace') &&
      !isFocusedField &&
      !showMenu &&
      !modalIsOpened
    ) {
      const { nodes, edges } = dataNodes.selectedNodes;
      if (edges.length > 0) {
        for (let i = 0; i < edges.length; i++) {
          //   takeSnapshot();
          dataNodes.removeEdge(edges[i].id);
          setIsSaved(false);
        }
      } else if (nodes.length > 0) {
        for (let i = 0; i < nodes.length; i++) {
          //   takeSnapshot();
          dataNodes.removeNode(nodes[i].id);
          setIsSaved(false);
        }
      }
    } else if (
      (event.ctrlKey || event.metaKey) &&
      event.key === 'c' &&
      !isFocusedField &&
      !showMenu &&
      !modalIsOpened
    ) {
      //   takeSnapshot();
      copy();
    } else if (
      (event.ctrlKey || event.metaKey) &&
      event.key === 'v' &&
      !isFocusedField &&
      !showMenu &&
      !modalIsOpened
    ) {
      //   takeSnapshot();
      paste();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
};
