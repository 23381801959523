import { errorToast } from '@/components/FormComponents/Toast';
// import { getOperators } from '@/services/dialControl/operatorControl/getOperators';
import { updateOperators } from '@/services/dialControl/operatorControl/updateOperators';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

export const useOperatorControl = () => {
  const { t } = useTranslation;
  const { mutate: mutateUpdateOperators, isLoading: loadingUpdateOperators } =
    useMutation({
      mutationFn: async (dataCreateSchedule) => {
        return await updateOperators(dataCreateSchedule);
      },
      onError: () =>
        errorToast(
          t(`dialControl.operatorControl.campaignOperator.msg-errorUpdate`)
        ),
    });

  return {
    mutateUpdateOperators,
    loadingUpdateOperators,
  };
};
