import { useAtom } from 'jotai';
import { ListMatchTenants } from './stages/ListMatchTenants';
import { dataMatchTenants } from '@/store/TenantsStore';

export const MatchTenants = ({ changeStage }) => {
  const [listMatchTenants] = useAtom(dataMatchTenants);
  return (
    <ListMatchTenants data={listMatchTenants} changeMainStage={changeStage} />
  );
};
