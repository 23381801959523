import { atom } from 'jotai';

export const stateLists = atom(0);
export const stateProgressBar = atom(0);
export const stateMailing = atom(0);

export const dataMailing = atom({});
export const listTemplateMetadata = atom([]);
export const openMenuNodeMailing = atom(false);
export const selectedNodeConfig = atom(null);
export const hasSavedTemplateMailing = atom(true);
export const dataTemplateMailing = atom(null);
export const savedTemplateMailing = atom(null);
export const showEmptyColumnsImported = atom(false);
export const selectedTemplate = atom('0');
export const useHeaderCsv = atom(false);
export const isLoadingRenderFlow = atom(false);
export const isLoadingChangeNewFlow = atom(false);
export const fileDataImport = atom(null);
export const dataPreviewImport = atom(null);
export const dataErrorsInCsv = atom([]);
export const dataTotalRows = atom(0);
export const dataConfirmationToSave = atom({
  open: false,
  next: null,
});
