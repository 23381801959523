import { apiData } from '@/services/api';

export async function createMatchTenant(dataCreate) {
  try {
    const { data } = await apiData.post(
      `servers/aggregateClientServer`,
      dataCreate
    );
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
