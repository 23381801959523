import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import { TableCampaigns } from './TableCampaigns';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { useCampaigns } from '@/hooks/dialControl/Campaigns/useCampaigns';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { dataListCampaigns } from '@/store/Campaigns';
import { ContainerListCampaigns } from './styled';
import { user } from '@/store/AuthenticatorStore';
import { useClients } from '@/hooks/Clients/useClients';
import { useDispositionsDialing } from '@/hooks/Dispositions/useDispositionsDialing';
import { usePermissionsCampaigns } from '../../hooks/usePermissionsCampaigns';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const ListCampaigns = ({ controlStage }) => {
  const { t } = useTranslation();
  const { permissionsCreate, isSysAdmin } = usePermissionsCampaigns();
  const { currentStage, nextStage } = controlStage;
  const { loadingDataDisposition } = useDispositionsDialing();
  const { loadingDataCampaigns } = useCampaigns(currentStage);
  const { isLoadingDataClients } = useClients();
  const [filterCampaign, setFilterCampaign] = useState('0');
  const [dataCampaigns] = useAtom(dataListCampaigns);

  const [currentUser] = useAtom(user);

  const filterClientIdCampaigns = isSysAdmin
    ? dataCampaigns
    : dataCampaigns.filter((item) => item.id_cliente === currentUser.client_id);

  const handleCreateCampaign = () => {
    nextStage();
  };

  const isLoading =
    isLoadingDataClients || loadingDataCampaigns || loadingDataDisposition;

  return (
    <ContainerListCampaigns initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <Stack direction={'column'} spacing={2} justifyContent={'space-between'}>
        <Box>
          <SelectComponent
            labelSelect={t(
              'dialControl.campaigns.listCampaigns.labelFilterCampaign'
            )}
            value={filterCampaign}
            onChange={(event) => setFilterCampaign(event.target.value)}
          >
            <ItemSelect value={'off'} disabled>
              {t('dialControl.campaigns.listCampaigns.optionsFilterCampaign')}
            </ItemSelect>
            <ItemSelect value={'0'}>
              {t('dialControl.campaigns.listCampaigns.optionsAllCampaigns')}
            </ItemSelect>
            {filterClientIdCampaigns.length > 0 &&
              filterClientIdCampaigns.map((item, i) => (
                <ItemSelect value={item.id} key={i}>
                  {item.id} - {item.campanha}
                </ItemSelect>
              ))}
          </SelectComponent>
        </Box>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <Button
            typeText
            variant='primary'
            onClick={handleCreateCampaign}
            disabled={!permissionsCreate}
            sx={{
              padding: '10px',
            }}
          >
            {t('dialControl.campaigns.listCampaigns.btn-add-campaign')}
          </Button>
        </Stack>
      </Stack>
      <TableCampaigns
        filterCampaign={filterCampaign}
        filterClientIdCampaigns={filterClientIdCampaigns}
        controlStage={controlStage}
      />
    </ContainerListCampaigns>
  );
};
