import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import styled from 'styled-components';

export const ContainerPlayer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 460px;
  padding: 2rem;
`;

export const HeaderPlayer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;

  button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 1rem;
    }
  }
`;

export const MainPlayer = styled.main``;

export const ContainerDetailPlayer = styled.div`
  border-radius: 3px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .containerItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 2px;
    border-radius: 3px;

    .itemDetail {
      display: flex;
      flex-direction: column;
      gap: 2px;
      overflow: hidden;
      user-select: none;

      strong {
        font-size: 0.65rem;
        color: ${(props) => props.theme.colors.typography.baseText};
      }
      span {
        font-size: 0.75rem;
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }
    button {
      all: unset;
      opacity: 0;
      display: flex;
      justify-content: center;

      svg {
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }

    &:hover {
      background-color: #8080801c;
      button {
        opacity: 1;
      }
    }
  }

  .content-download-detail {
    width: 100%;
    padding-top: 0.8rem;
    display: flex;
    justify-content: flex-start;
    user-select: none;
    button {
      /* border: 1px solid ${(props) =>
        props.theme.colors.defaultColors.orange}; */
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 0.65rem;
      border-radius: 3px !important;
      background-color: transparent;
      color: ${(props) => props.theme.colors.typography.baseText};
      font-size: 0.8rem;
      padding-top: 5px !important;

      svg {
        color: ${(props) => props.theme.colors.typography.baseText};
        font-size: 0.9rem;
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.defaultColors.orange};
        transition: background-color 0.05s ease-in-out;
      }
    }
  }
`;

export const AccordionDetailPlayer = styled(Accordion)`
  box-shadow: none !important;
  position: static !important;
  background-color: transparent !important;
`;

export const AccordionDetailSummary = styled(AccordionSummary)`
  padding: 1rem 0 0 0 !important;
  color: ${(props) => props.theme.colors.typography.baseText} !important;
  font-size: 0.8rem;
  font-weight: 600;

  svg {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }
`;
export const AccordionDetailContent = styled(AccordionDetails)`
  padding: 8px !important;
  background-color: #dbdbdb57;
  border-radius: 4px;
`;
