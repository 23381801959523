import { getSummaryMonitor } from '@/services/monitor/getSummaryMonitor';
import { useQuery } from 'react-query';

export const useSummaryMonitor = () => {
  const { data: dataSummaryMonitor, isLoading: loadingSummaryMonitor } =
    useQuery('dataSummaryMonitor', async () => {
      return await getSummaryMonitor();
    });

  return { dataSummaryMonitor, loadingSummaryMonitor };
};
