export async function getJourneys() {
  try {
    const data = {
      name: 'Inicio',
      totalValues: Math.floor(Math.random() * 5000),
      inCall: Math.floor(5000 - Math.random() * 5000),
      percentage: `${Math.floor(Math.random() * 100)}`,
      closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,
      children: [
        {
          name: 'é cliente',
          totalValues: Math.floor(Math.random() * 5000),
          inCall: Math.floor(5000 - Math.random() * 5000),
          percentage: `${Math.floor(Math.random() * 100)}`,
          closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,

          children: [
            {
              name: 'Validou CPF',
              totalValues: Math.floor(Math.random() * 5000),
              inCall: Math.floor(5000 - Math.random() * 5000),
              percentage: `${Math.floor(Math.random() * 100)}`,
              closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,
              children: [
                {
                  name: 'Erro WS',
                  totalValues: Math.floor(Math.random() * 5000),
                  inCall: Math.floor(5000 - Math.random() * 5000),
                  percentage: `${Math.floor(Math.random() * 100)}`,
                  closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,
                },
                {
                  name: 'Alega Pagamento',
                  totalValues: Math.floor(Math.random() * 5000),
                  inCall: Math.floor(5000 - Math.random() * 5000),
                  percentage: `${Math.floor(Math.random() * 100)}`,
                  closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,
                },
                {
                  name: 'Não Pagou',
                  totalValues: Math.floor(Math.random() * 5000),
                  inCall: Math.floor(5000 - Math.random() * 5000),
                  percentage: `${Math.floor(Math.random() * 100)}`,
                  closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,
                  children: [
                    {
                      name: 'Aceita Primeira Proposta',
                      totalValues: Math.floor(Math.random() * 5000),
                      inCall: Math.floor(5000 - Math.random() * 5000),
                      percentage: `${Math.floor(Math.random() * 100)}`,
                      closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,
                      children: [
                        {
                          name: 'Acordo formalizado',
                          totalValues: Math.floor(Math.random() * 5000),
                          inCall: Math.floor(5000 - Math.random() * 5000),
                          percentage: `${Math.floor(Math.random() * 100)}`,
                          closeCall: `${Math.floor(
                            5000 - Math.random() * 2000
                          )}`,
                        },
                        {
                          name: 'Erro Ws',
                          totalValues: Math.floor(Math.random() * 5000),
                          inCall: Math.floor(5000 - Math.random() * 5000),
                          percentage: `${Math.floor(Math.random() * 100)}`,
                          closeCall: `${Math.floor(
                            5000 - Math.random() * 2000
                          )}`,
                        },
                      ],
                    },
                    {
                      name: 'Aceita Segunda Proposta',
                      totalValues: Math.floor(Math.random() * 5000),
                      inCall: Math.floor(5000 - Math.random() * 5000),
                      percentage: `${Math.floor(Math.random() * 100)}`,
                      closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,
                      children: [
                        {
                          name: 'Acordo formalizado 2',
                          totalValues: Math.floor(Math.random() * 5000),
                          inCall: Math.floor(5000 - Math.random() * 5000),
                          percentage: `${Math.floor(Math.random() * 100)}`,
                          closeCall: `${Math.floor(
                            5000 - Math.random() * 2000
                          )}`,
                        },
                      ],
                    },
                    {
                      name: 'Aceita Terceira Proposta',
                      totalValues: 27382,
                      inCall: 1000,
                      percentage: `${Math.floor(Math.random() * 100)}`,
                      closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,
                      children: [
                        {
                          name: 'Acordo formalizado 1',
                          totalValues: Math.floor(Math.random() * 5000),
                          inCall: Math.floor(5000 - Math.random() * 5000),
                          percentage: `${Math.floor(Math.random() * 100)}`,
                          closeCall: `${Math.floor(
                            5000 - Math.random() * 2000
                          )}`,
                        },
                        {
                          name: 'Erro Ws',
                          totalValues: Math.floor(Math.random() * 5000),
                          inCall: Math.floor(5000 - Math.random() * 5000),
                          percentage: `${Math.floor(Math.random() * 100)}`,
                          closeCall: `${Math.floor(
                            5000 - Math.random() * 2000
                          )}`,
                        },
                      ],
                    },
                    {
                      name: 'Não Aceita nenhuma opção',
                      totalValues: Math.floor(Math.random() * 5000),
                      inCall: Math.floor(5000 - Math.random() * 5000),
                      percentage: `${Math.floor(Math.random() * 100)}`,
                      closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,
                    },
                  ],
                },
              ],
            },
            {
              name: 'Não Validou CPF',
              totalValues: Math.floor(Math.random() * 5000),
              inCall: Math.floor(5000 - Math.random() * 5000),
              percentage: `${Math.floor(Math.random() * 100)}`,
              closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,
            },
          ],
        },

        {
          name: 'Sem Interação',
          totalValues: Math.floor(Math.random() * 5000),
          inCall: Math.floor(5000 - Math.random() * 5000),
          percentage: `${Math.floor(Math.random() * 100)}`,
          closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,
        },

        {
          name: 'Não Conhece',
          totalValues: Math.floor(Math.random() * 5000),
          inCall: Math.floor(5000 - Math.random() * 5000),
          percentage: `${Math.floor(Math.random() * 100)}`,
          closeCall: `${Math.floor(5000 - Math.random() * 2000)}`,
        },
      ],
    };

    return new Promise((resolve) => {
      setTimeout(() => resolve(data), 400);
    });
  } catch (error) {
    throw new Error(error);
  }
}
