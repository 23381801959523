import { apiData } from '@/services/api';

export async function updateDispositionsDialing(dataUpdate) {
  try {
    const { data } = await apiData.put(
      'dialer/updateMasterCallingCode',
      dataUpdate
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
