import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerListCampaigns = styled.div`
  width: 100%;
  height: 100%;

  gap: 2rem;
  display: grid;
  grid-template-rows: 80px 1fr;
`;

export const ContentPanelQueues = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContentCampaigns = styled(motion.div)`
  width: 100%;
  flex: 1;
  /*  min-height: 600px; */

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: space-evenly;
  align-content: flex-start;
  align-items: center;
  gap: 1rem;
`;
