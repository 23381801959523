import { TitleComponent } from '@/components/layout/TitleComponent';
import { motion } from 'framer-motion';
import { ContainerPlayer, HeaderPlayer, MainPlayer } from './styles';
import WaveRecordings from './WaveRecordings';
import { IoMdClose } from 'react-icons/io';
import { DetailPlayer } from './DetailPlayer';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useSessionStorage } from '@/hooks/useSessionStorage';

export const PlayerRecordings = ({ dataSelected, onClose }) => {
  const { t } = useTranslation();
  const { saveSessionStorage, getSessionStorage } = useSessionStorage();

  const addedRecordingWasPlayed = () => {
    if (dataSelected) {
      const { fone, atende_dt } = dataSelected;
      const idPlayed = `${fone}${atende_dt}`;
      const hasPlayed = getSessionStorage('@p');
      if (!hasPlayed) {
        return saveSessionStorage(`@p`, [idPlayed]);
      }
      const hasIndex = hasPlayed.includes(idPlayed);
      if (!hasIndex) {
        saveSessionStorage(`@p`, [...hasPlayed, idPlayed]);
      }
    }
  };

  useEffect(() => {
    addedRecordingWasPlayed();
  }, []);

  const handleClose = () => {
    onClose();
  };

  return (
    <ContainerPlayer>
      <HeaderPlayer>
        <TitleComponent
          textTitle={t(`activeRecordings.listActiveRecords.player.title`)}
        />
        <motion.button whileTap={{ scale: 1.05 }} onClick={handleClose}>
          <IoMdClose />
        </motion.button>
      </HeaderPlayer>
      <MainPlayer>
        {dataSelected?.blobWave && (
          <WaveRecordings audio={dataSelected?.blobWave} />
        )}
        <DetailPlayer dataDetail={dataSelected} />
      </MainPlayer>
    </ContainerPlayer>
  );
};
