import { apiData } from '@/services/api';

export async function getSchedulesByList(queue) {
  try {
    const { data } = await apiData.get(
      `dialerSchedule/listScheduleListByQueue/${queue}`
    );
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
