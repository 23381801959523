import styled from 'styled-components';

export const ConfigQueueTable = styled.div`
  padding: 1rem 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
