import styled, { css } from 'styled-components';
import { ContainerNodeWrapper, ContentNodeWrapper } from '../styled';

export const NodeTransferContainer = styled(ContainerNodeWrapper)`
  position: relative;
  background: ${(props) => props.theme.colors.containers.colorSecondNode};
  min-height: 165px;

  .react-flow__handle {
    &::after {
      background: ${(props) => props.theme.colors.agentColors.AGLIG};
    }
  }

  .react-flow__handle-right {
    right: -8px;
  }
`;

export const NodeContent = styled(ContentNodeWrapper)`
  width: 220px;

  .header {
    background: #8cbe37;
  }

  .main {
    ${(props) =>
      props.typetransfer === 1 &&
      css`
        gap: 0.35rem;
      `}
  }
`;
