import { useId, useState } from 'react';
import { ContainerLineControl } from './styled';
import { ListCampaigns } from './stages/ListCampaigns';
import { CampaignOperator } from './stages/CampaignOperator';

export const OperatorControl = () => {
  const [currentStage, setCurrentStage] = useState(0);

  const controlStage = {
    previousStage: () => setCurrentStage((currentStage) => currentStage - 1),
    nextStage: () => setCurrentStage((currentStage) => currentStage + 1),
    toStage: (stage) => setCurrentStage(stage),
  };

  const flowOperatorControl = [
    <ListCampaigns key={useId()} controlStage={controlStage} />,
    <CampaignOperator key={useId()} controlStage={controlStage} />,
  ];

  return (
    <ContainerLineControl>
      {flowOperatorControl[currentStage]}
    </ContainerLineControl>
  );
};
