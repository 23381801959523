import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { StyledItem } from './styledItems';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import { RiDraggable } from 'react-icons/ri';

export const Item = ({
  text,
  dataItem,
  index,
  handleToSelectedList,
  colSelected,
  typeList,
}) => {
  return (
    <Draggable draggableId={text} index={index}>
      {(provided) => (
        <StyledItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onDoubleClick={() => handleToSelectedList(dataItem, colSelected)}
        >
          <RiDraggable />
          <span>
            {text} {typeList === 'dispositions' && `- ${dataItem.descricao}`}
          </span>

          <div className='icon-item'>
            {colSelected === 'available' ? <HiArrowRight /> : <HiArrowLeft />}
          </div>
        </StyledItem>
      )}
    </Draggable>
  );
};
