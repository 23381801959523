import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { FormMenuNode, MainAddNode } from './styled';
import { TitleMenuNode } from '../TitleMenuNode';
import { FooterNode } from '@/components/pages/FlowBuilder/components/FooterNode';
import { PhoneCapture } from './PhoneCapture';
import { FieldAddDtmf } from './FieldAddDtmf';
import { CustomCapture } from './CustomCapture';
import { errorToast } from '@/components/FormComponents/Toast';
import { checkLabelExistiNode } from '@/components/pages/FlowBuilder/utils/checkNameNodes';
import { renderPositionSubNode } from '@/components/pages/FlowBuilder/utils/renderPositionNodes';
import {
  focusedFieldArea,
  isSavedFlow,
  selectedNode,
  showMenuNodes,
  variablesList,
  voicesOptions,
} from '@/store/FlowBuilder';

export const DtmfMenu = ({ dataNodes }) => {
  const { t } = useTranslation();
  const [, setFocusedArea] = useAtom(focusedFieldArea);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [selected, setSelected] = useAtom(selectedNode);
  const [labelNode, setLabelNode] = useState('');
  const [labelNodeError, setLabelNodeError] = useState('');
  const [, setIsSaved] = useAtom(isSavedFlow);
  const [listVariable, setListVariables] = useAtom(variablesList);
  const [listVoices] = useAtom(voicesOptions);

  const { nodes, setNodes, edges, setEdges } = dataNodes;
  const { id, data } = selected;

  const [messageKey, setMessageKey] = useState('');
  const [totalDigits, setTotalDigits] = useState(0);
  const [timeOut, setTimeOut] = useState(1);
  const [listDtmf, setListDtmf] = useState([]);
  const [typeKeyCapture, setTypeKeyCapture] = useState(false);

  useEffect(() => {
    if (selected) {
      setLabelNode(data.label);
      setMessageKey(data.prompt);
      setListDtmf(data.listDtmf);
      setTotalDigits(data.totalDigits);
      setTypeKeyCapture(data.typeCapture === 'AUTO' ? true : false);
      setTimeOut(data.timeout);
    }
  }, [selected]);

  const validateLabelNode = (value) => {
    if (!value.trim()) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.dtmf.menu.msg-name-required')
      );
    } else if (value.length > 50) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.dtmf.menu.msg-name-length', {
          length: '50',
        })
      );
    } else if (!/^[_a-zA-Z0-9]*$/.test(value)) {
      setLabelNodeError(t('flowBuilder.flow.nodes.dtmf.menu.msg-name-letters'));
    } else {
      setLabelNodeError('');
    }
    setLabelNode(value);
  };

  const closeMenu = () => {
    setShowMenu(false);
    setLabelNode('');
    setSelected(null);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const handleAddToVarsList = (labelNode) => {
    const newVarUtterance = {
      id: uuid(),
      name: `$${labelNode}_utterance`,
      value: `$${labelNode}_utterance`,
      parent: '',
      referNode: false,
    };

    const hasExistUtterance = listVariable.filter(
      (item) => item.name === newVarUtterance.name
    ).length;

    if (hasExistUtterance === 0) {
      setListVariables((previousState) => [...previousState, newVarUtterance]);
    }
  };

  const renderVersionPrompt = (oldPrompt, currentPrompt, nameFile = '') => {
    if (!oldPrompt || nameFile === '') {
      return 'v1';
    }
    const separateVersionNameFile = nameFile.replace('v', '').split('-');

    if (!separateVersionNameFile || separateVersionNameFile[0].length !== 1) {
      return 'v1';
    }

    const convertToNumberVersion = Number(separateVersionNameFile[0]);
    const updatedVersion = convertToNumberVersion + 1;

    if (oldPrompt === currentPrompt) {
      return `v${convertToNumberVersion}`;
    }

    return `v${updatedVersion}`;
  };

  const selectVoicePrompt = () => {
    const { nodes } = dataNodes;

    const voiceSelectedPersona =
      nodes
        .filter((item) => item.data?.handler === 'dialplanVariables')
        .map((item) => item.data?.voice)[0] ?? '';

    const voiceSelectedPersonaString = voiceSelectedPersona.toString();

    const filterValueVoice = listVoices
      .filter((item) => item.name === voiceSelectedPersonaString)
      .map((item) => item.value)
      .toString();

    if (!filterValueVoice || filterValueVoice === '') {
      return 'pt-BR-FranciscaNeural';
    }
    return filterValueVoice;
  };

  const renderFileName = () => {
    const firstVarUsed = /{{(.*?)}}/g;
    const matches = messageKey.match(firstVarUsed);

    const versionFileName = renderVersionPrompt(
      data.prompt,
      messageKey,
      data.fileName
    );

    if (matches?.length > 0) {
      const concatVars = matches.join('-');
      const nameFile = `${versionFileName}-${labelNode}-${concatVars}-${selectVoicePrompt()}`;
      return nameFile;
    }

    return `${versionFileName}-${labelNode}-${selectVoicePrompt()}`;
  };

  const handleSubmitNewNode = (e) => {
    e.preventDefault();

    const checkLabel = checkLabelExistiNode(id, labelNode, nodes);

    if (checkLabel) {
      return errorToast(t('flowBuilder.flow.nodes.hangUp.menu.msg-name-used'));
    }

    const renderDataListsByType = (list = [], typeKeyCapture = false) => {
      if (typeKeyCapture) {
        return [{ key: 'auto' }, { key: 'timeout' }];
      }
      const timeoutIndex = list.findIndex((item) => item.key === 'timeout');

      if (timeoutIndex === -1) {
        return [...list, { key: 'timeout' }];
      }

      const listWithoutTimeout = [
        ...list.slice(0, timeoutIndex),
        ...list.slice(timeoutIndex + 1),
      ];

      return [...listWithoutTimeout, list[timeoutIndex]].sort(
        (a, b) => a.key - b.key
      );
    };

    const filteredNode = nodes.map((node) =>
      node.id === id
        ? {
            ...node,
            data: {
              ...node.data,
              label: labelNode,
              prompt: messageKey,
              typeCapture: typeKeyCapture ? 'AUTO' : 'CUSTOM',
              totalDigits: totalDigits,
              listDtmf: renderDataListsByType(listDtmf, typeKeyCapture),
              timeout: timeOut,
              errorConnect: false,
              fileName: renderFileName(),
            },
          }
        : node
    );

    const filteredSubNode = filteredNode.map((node) =>
      node.parentNode === id ? null : node
    );

    handleAddToVarsList(labelNode);

    setNodes(filteredSubNode.filter(Boolean));

    const new_Id = new Date().getTime();

    const dataRender = renderDataListsByType(listDtmf, typeKeyCapture);

    if (dataRender.length > 0) {
      const dataSubItemsConditions = dataRender.map((item, i) => {
        const filteredDataSubNode = nodes.filter(
          (node) => node.parentNode === id
        );

        const checkSubNodes = filteredDataSubNode.filter(
          (itemCheck) => itemCheck.data.label === item.key
        );

        const subNode = {
          id:
            checkSubNodes.length === 0
              ? `sub_id_${id}_${new_Id * i}`
              : checkSubNodes[0].id,
          type: 'itemDtmf',
          data: {
            value: item.key,
            label: item.key,
            handler: 'dtmfCondition',
          },
          position: renderPositionSubNode(i, 10, 140),
          parentNode: id,
          extent: 'parent',
          className: 'nodrag',
          selectable: false,
        };

        return subNode;
      });

      const checkErrorSubNodes = filteredNode.filter(
        (item) => item.data.label === 'Error' && item.parentNode === id
      );

      const errorSub = {
        ...checkErrorSubNodes[0],
        position: renderPositionSubNode(0, 10, 140 + dataRender.length * 30),
      };

      dataSubItemsConditions.push(errorSub);

      const oldListItems = filteredNode
        .filter((node) => node.parentNode === id)
        .map((node) => node.id);
      const newList = dataSubItemsConditions.map((item) => item.id);

      const removedItemsId = oldListItems.filter(
        (item) => !newList.includes(item)
      );

      const renderEdges = edges
        .map((edge) => {
          const hasEdgeNotUsed = removedItemsId.includes(edge.source);
          if (hasEdgeNotUsed) {
            return null;
          }
          return edge;
        })
        .filter((edge) => edge);

      setEdges(renderEdges);

      dataSubItemsConditions.map((item) => {
        return dataNodes.setNodes((nds) => nds.concat(item));
      });
    }

    setIsSaved(false);
    closeMenu();
    return;
  };

  const checkData = typeKeyCapture ? !totalDigits : !listDtmf.length;
  const disabledButton =
    Boolean(labelNodeError) || !labelNode.length || checkData;

  return (
    <FormMenuNode
      onClick={handleChildClick}
      onSubmit={handleSubmitNewNode}
      id='DtmfMenuForm'
      name='DtmfMenuForm'
    >
      <MainAddNode>
        <TitleMenuNode
          title={t('flowBuilder.flow.nodes.dtmf.menu.title')}
          description={t('flowBuilder.flow.nodes.dtmf.menu.description-dtmf')}
        />

        <InputComponent
          label={t('flowBuilder.flow.nodes.dtmf.menu.label-name')}
          value={labelNode}
          onChange={(e) => validateLabelNode(e.target.value)}
          inputProps={{ maxLength: 51 }}
          helperText={labelNodeError}
          error={Boolean(labelNodeError)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />

        <FieldAddDtmf
          dataDtmf={{
            messageKey,
            setMessageKey,
            typeKeyCapture,
            setTypeKeyCapture,
            renderFileName,
            selectVoicePrompt,
          }}
        />
        {!typeKeyCapture ? (
          <PhoneCapture
            dataDtmf={{
              listDtmf,
              setListDtmf,
              timeOut,
              setTimeOut,
              typeKeyCapture,
            }}
          />
        ) : (
          <CustomCapture
            dataDtmf={{
              listDtmf,
              setListDtmf,
              totalDigits,
              setTotalDigits,
              timeOut,
              setTimeOut,
            }}
          />
        )}
      </MainAddNode>

      <FooterNode closeMenu={closeMenu} disabledSubmitBtn={disabledButton} />
    </FormMenuNode>
  );
};
