import styled from 'styled-components';

export const ContainerTable = styled.div`
  width: 100%;
  position: relative;
  flex: 1;
  max-height: calc(100vh - 330px);
  min-height: 200px;
  overflow-x: auto;
  table {
    tbody {
      tr {
        height: 44px;
        td {
          z-index: 1;
          padding: 0 0.8rem;

          @media (max-width: 1200px) {
            min-width: 140px;
          }

          &:nth-child(1) {
            width: 140px;
          }

          &:nth-child(2) {
            width: 200px;
          }

          &:nth-child(11) {
            width: 40px;
            min-width: 40px;
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    margin-top: 0.675rem;
    max-height: calc(100vh - 300px);
    table tbody,
    table tr,
    table td {
      display: block;
      width: 100%;
    }

    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          margin-bottom: 12px;
          width: 100%;
          min-width: 100%;
          height: auto;
          padding: 1rem 0;
          border-left: 3px solid ${(props) => props.theme.colors.primary};

          td {
            padding-left: 50%;
            text-align: left;
            position: relative;
            padding-bottom: 6px;

            span {
              font-size: 12px;
            }
            &::before {
              content: attr(data-label) ':';
              position: absolute;
              left: 0;
              width: 50%;
              padding-left: 15px;
              text-align: left;
              font-size: 12px;
              color: ${(props) => props.theme.colors.typography.baseText};
              line-height: 1.2;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;
