import { useState } from 'react';
import { motion } from 'framer-motion';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { ContainerFileArea, FileArea } from './styled';
import { SlCloudUpload } from 'react-icons/sl';
import { FooterImport } from '../../components/FooterImport';
import { Alert } from '@mui/material';
import { errorToast } from '@/components/FormComponents/Toast';
import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { fileDataImport } from '@/store/LoadList';
import { useStageControlLists } from '@/components/pages/LoadList/hooks/useStageControlLists';

export const FileAreaDrop = ({ hasLayoutSelected }) => {
  const { t } = useTranslation();

  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useAtom(fileDataImport);
  const { previousProgress, setCurrentStateMailing } = useStageControlLists();

  const handleChange = (file) => {
    setFile(file);
  };

  const handleBackStage = () => {
    previousProgress();
    setCurrentStateMailing(0);
  };

  return (
    <ContainerFileArea>
      <FileArea hasFile={!!file}>
        {hasLayoutSelected && (
          <div className=''>
            <Alert severity='error'>
              {t('listLoader.importMailing.error-select-layout')}
            </Alert>
          </div>
        )}

        <motion.div
          className='areaImportCsv'
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <FileUploader
            disabled={file || hasLayoutSelected}
            multiple={false}
            handleChange={handleChange}
            types={['CSV']}
            onDraggingStateChange={(dragging) => setIsDragging(dragging)}
            maxSize={160}
            onSizeError={() =>
              errorToast(t('listLoader.importMailing.error-max-size-file'))
            }
          >
            {!isDragging ? (
              file ? (
                <motion.div
                  className='content_management'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                ></motion.div>
              ) : (
                <motion.div
                  className='description-content'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <SlCloudUpload />
                  <Stack spacing={1}>
                    <div className='description-area-text'>
                      <p>{t('listLoader.importMailing.firstSentence')}</p>
                      <span>
                        {t('listLoader.importMailing.secondSentence')}
                      </span>
                    </div>
                    <div className='description-area-text description-import-file'>
                      <span>
                        {t('listLoader.importMailing.description-import-file')}
                      </span>
                    </div>
                  </Stack>
                </motion.div>
              )
            ) : (
              <div>{''}</div>
            )}
          </FileUploader>
        </motion.div>
      </FileArea>
      <FooterImport showNext={false} handlePreviousStage={handleBackStage} />
    </ContainerFileArea>
  );
};
