import styled from 'styled-components';

export const ContainerCountInList = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 3rem;
  padding: 3rem 2rem;
  position: relative;
  overflow: scroll;

  @media (max-height: 400px) {
    height: calc(100vh - 60px);
  }
`;
