import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerMainHomeFlow = styled(motion.div)`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
