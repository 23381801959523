import { useEffect, useId, useState } from 'react';
import { useReactFlow } from 'reactflow';
import { useTrunks } from '@/hooks/FlowBuilder/useTrunks';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { ListTrunks } from './ListTrunks';
import { ContentConfig } from '../../styled';
import { AddTrunks } from './AddTrunk';

export const ConfigTrunks = ({ dataTrunks, controlStage }) => {
  const { isLoading } = useTrunks();
  const { getNodes } = useReactFlow();
  const [stageConfigTrunks, setStageConfigTrunks] = useState(0);
  const [usedTrunks, setUsedTrunks] = useState([]);

  const handleControlStages = {
    toAddTrunk: () => setStageConfigTrunks(1),
    toList: () => setStageConfigTrunks(0),
    handleClose: () => controlStage.closeConfig(),
  };

  const hasUsedTrunks = (usedNodes) => {
    const listNodes = usedNodes ? usedNodes : [];

    const trunksUsed = listNodes
      .filter((item) => item.data.handler === 'transferQueue')
      .map((item) => item.data.transferTo);

    const mapListTrunks = dataTrunks.map((itemSelected) => {
      const hasUsedTrunkInNode = trunksUsed.includes(itemSelected.value);
      if (hasUsedTrunkInNode) {
        return itemSelected.value;
      }
      return null;
    });
    return mapListTrunks;
  };

  useEffect(() => {
    setUsedTrunks(hasUsedTrunks(getNodes()));
  }, [getNodes]);

  const flowTrunks = [
    <ListTrunks
      key={useId()}
      trunks={{ listTrunks: dataTrunks, usedTrunks }}
      handleControlStages={handleControlStages}
    />,
    <AddTrunks
      key={useId()}
      usedTrunks={usedTrunks}
      handleControlStages={handleControlStages}
    />,
  ];

  return (
    <ContentConfig>
      {isLoading && <BackDropCircularLoading />}
      {flowTrunks[stageConfigTrunks]}
    </ContentConfig>
  );
};
