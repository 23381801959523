import styled from 'styled-components';

export const ContainerChartMap = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.containers.colorCard};
  border-radius: 6px;
  box-shadow: ${(props) => props.theme.colors.containers.baseShadows};
  grid-column: 2 / 3;

  @media (max-width: 1550px) {
    height: 96%;
  }
`;

export const HeaderChartMap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.88rem;
    line-height: 120.69%;

    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: ${(props) => props.theme.colors.typography.baseText};
  }

  span {
    font-style: normal;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 132.19%;

    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;

export const ContentChartMap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 1rem;
  gap: 1rem;

  @media (max-width: 540px) {
    flex-direction: column;
  }

  .dividerData {
    width: 100%;
    height: 1px;
    background-color: gray;
    opacity: 0.4;
  }

  .infoHoverMap {
    width: 160px;
    height: 80%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 540px) {
      width: 100%;
    }
    h3 {
      font-size: 0.75rem;
      color: ${(props) => props.theme.colors.typography.baseTitle};
    }

    span {
      font-size: 0.85rem;
      color: ${(props) => props.theme.colors.typography.baseText};
      font-weight: 600;
    }
  }

  #svg-map path {
    fill: ${(props) => props.theme.colors.defaultColors.blue};
  }

  #svg-map text {
    fill: #fff;
    font: 12px Arial-BoldMT, sans-serif;
    cursor: pointer;
  }

  #svg-map a {
    text-decoration: none;
  }

  #svg-map a:hover {
    cursor: pointer;
    text-decoration: none;
  }

  #svg-map a:hover path {
    fill: ${(props) => props.theme.colors.primaryDark}!important;
  }

  #svg-map .circle {
    fill: ${(props) => props.theme.colors.primaryLight};
  }

  #svg-map a:hover .circle {
    fill: ${(props) => props.theme.colors.primaryDark}!important;
    cursor: pointer;
  }
`;
