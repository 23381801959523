import React from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  getSmoothStepPath,
  useReactFlow,
} from 'reactflow';
import { useTheme } from 'styled-components';

export default function EdgedColor({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  selected,
}) {
  const theme = useTheme();
  const { setEdges } = useReactFlow();
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onEdgeClick = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  };

  const renderColorEdge = () => {
    const separateTypeList = markerEnd.split('&');
    const separateType = separateTypeList[3].replace('typeEdge=', '');

    return theme.colors.nodesFlow[separateType];
  };

  const colorEdgeTheme = theme.colors.nodesFlow.baseEdge;

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          ...style,
          stroke: selected ? renderColorEdge() : colorEdgeTheme,
          strokeWidth: selected ? 2 : 1,
        }}
      ></BaseEdge>
      {selected && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              pointerEvents: 'all',
            }}
            className='nodrag nopan'
          >
            <button
              className='edgebutton'
              onClick={onEdgeClick}
              style={{ borderColor: renderColorEdge() }}
            >
              <span style={{ color: renderColorEdge() }}>x</span>
            </button>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}
