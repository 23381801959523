import { apiData } from '@/services/api';

export async function updateClientUser(dataUserUpdate) {
  try {
    const { userId, client_id } = dataUserUpdate;

    await apiData.put(`users/updateClientid/${userId}`, {
      client_id,
    });

    return { client_id };
  } catch (error) {
    throw new Error(error);
  }
}
