import styled from 'styled-components';

export const ContainerHeaderMonitor = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  .second_part_control {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    button {
      height: 2.2rem;

      margin-top: -6px;

      svg {
        font-size: 1.2rem;
      }
    }
  }
`;
