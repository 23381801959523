export const renderPositionSubNode = (index, x = 10, y = 140) => {
  if (index === 0) {
    return {
      x,
      y,
    };
  }
  return {
    x,
    y: y + index * 30,
  };
};
