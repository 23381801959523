import { apiData } from '@/services/api';

export async function createUserAgent(dataCreate) {
  try {
    const { data } = await apiData.post(`users`, dataCreate);
    return data;
  } catch (error) {
    if (error.response.data.message) {
      return {
        status: 'Error',
        message: error.response.data.message,
      };
    }
    throw new Error(error);
  }
}
