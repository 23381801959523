import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerTable = styled(motion.div)`
  user-select: none;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: scroll;

  table {
    tbody {
      tr {
        height: 34px;
        td {
          z-index: 1;
          padding: 0 0.8rem;
        }
      }
    }
  }
`;
