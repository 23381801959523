import { atom } from 'jotai';

const themeLocal = localStorage.getItem('theme');
export const currentThemeAtom = atom(themeLocal ? themeLocal : 'light');

export const setCurrentTheme = atom(
  (get) => get(currentThemeAtom),
  (get, set) =>
    get(currentThemeAtom) !== 'light'
      ? (set(currentThemeAtom, 'light'), localStorage.setItem('theme', 'light'))
      : (set(currentThemeAtom, 'dark'), localStorage.setItem('theme', 'dark'))
);
