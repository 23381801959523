import { DialogComponent } from '@/components/DialogComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { useEffect, useState } from 'react';
import { FaGear } from 'react-icons/fa6';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { Stack } from '@mui/system';
import { Grid } from '@mui/material';
import { useAtom } from 'jotai';
import {
  hasSavedTemplateMailing,
  showEmptyColumnsImported,
} from '@/store/LoadList';
import { SwitchComponent } from '@/components/FormComponents/SwitchComponent';
import { useTranslation } from 'react-i18next';
import { ContentConfigMailing, MainConfigContent } from './styled';

export const ConfigFlow = ({ dataNodes }) => {
  const { t } = useTranslation();
  const [hasOpemConfig, setHasOpemConfig] = useState(false);
  const [showEmptyColumns, setShowEmptyColumns] = useState(false);
  const [listShowPhonesNodes, setListShowPhonesNodes] = useState([
    { name: 'fone2', value: false },
    { name: 'fone3', value: false },
    { name: 'fone4', value: false },
    { name: 'fone5', value: false },
    { name: 'fone6', value: false },
    { name: 'fone7', value: false },
    { name: 'fone8', value: false },
    { name: 'fone9', value: false },
    { name: 'fone10', value: false },
  ]);
  const [listShowJokersNodes, setListShowJokersNodes] = useState([
    { name: 'coringa_2', value: false },
    { name: 'coringa_3', value: false },
    { name: 'coringa_4', value: false },
    { name: 'coringa_5', value: false },
    { name: 'coringa_6', value: false },
    { name: 'coringa_7', value: false },
    { name: 'coringa_8', value: false },
    { name: 'coringa_9', value: false },
    { name: 'coringa_10', value: false },
  ]);

  const [, setHasSavedTemplate] = useAtom(hasSavedTemplateMailing);

  const [showNodesNulls, setShowNodesNulls] = useAtom(showEmptyColumnsImported);

  useEffect(() => {
    setShowEmptyColumns(showNodesNulls);
  }, [showNodesNulls]);

  useEffect(() => {
    const filterNodes = dataNodes.nodes.filter(
      (node) => node.type === 'nodeMailing'
    );

    const updateValuePhones = listShowPhonesNodes.map((phoneState) => {
      const getCurrentValue = filterNodes.filter(
        (currentPhone) => currentPhone.data.label === phoneState.name
      );

      if (getCurrentValue.length === 0) {
        return phoneState;
      }

      return {
        ...phoneState,
        value: !getCurrentValue[0].hidden,
      };
    });

    setListShowPhonesNodes(updateValuePhones);
  }, [dataNodes]);

  const handleOpenConfig = () => {
    setHasOpemConfig(true);
  };

  const handleCloseConfig = () => {
    setHasOpemConfig(false);
  };

  const updatedEdges = (listEdges, listNodes, currentNodes) => {
    const filterNodesSystem = listNodes.filter(
      (nodes) => nodes.type === 'nodeMailing'
    );

    const filterCurrentNodes = currentNodes.filter(
      (nodes) => nodes.type === 'nodeMailing'
    );

    const filterNodesFromTemplate = listNodes
      .filter((nodes) => nodes.type === 'nodeFromCsv' && nodes.hidden)
      .map((item) => item.id);

    const changedHiddenToTrueIds = filterNodesSystem
      .filter(
        (newItem, index) =>
          filterCurrentNodes[index].hidden === false && newItem.hidden === true
      )
      .map((item) => item.id);

    const filteredEdges = listEdges.filter(
      (edge) => !changedHiddenToTrueIds.includes(edge.target)
    );

    const filterEdgeCsvSource = filteredEdges.filter(
      (edge) => !filterNodesFromTemplate.includes(edge.source)
    );

    return filterEdgeCsvSource;
  };

  const updateVisibleNodes = (listNodes) => {
    const mappingList = listShowPhonesNodes.map((item) => {
      const renderNameDDD = item.name.replace('fone', 'ddd');
      const dataCreate = [
        {
          ...item,
        },
        {
          name: renderNameDDD,
          value: item.value,
        },
      ];

      return dataCreate;
    });

    const dataNodesShow = [...mappingList.flat(), ...listShowJokersNodes];

    let lastVisiblePositionCsv = 0;
    let lastVisiblePositionMailing = 0;

    const updatedNodes = listNodes.map((node, index) => {
      const filterNodePhone = dataNodesShow.filter(
        (grouPhone) => grouPhone.name === node.data.label
      );

      const isHidden =
        filterNodePhone.length === 0 ? node.hidden : !filterNodePhone[0].value;

      const isNodeFromCsv = node.type === 'nodeFromCsv';
      const isNodeMailing = node.type === 'nodeMailing';

      let lastVisiblePosition = isNodeFromCsv
        ? lastVisiblePositionCsv
        : lastVisiblePositionMailing;

      if (!isHidden) {
        if (index === 0 || lastVisiblePosition === 0) {
          lastVisiblePosition = node.position.y;
        } else {
          const newPosition = lastVisiblePosition + 60;
          lastVisiblePosition = newPosition;

          if (isNodeFromCsv) {
            lastVisiblePositionCsv = lastVisiblePosition;
          } else if (isNodeMailing) {
            lastVisiblePositionMailing = lastVisiblePosition;
          }

          return {
            ...node,
            hidden: false,
            position: {
              ...node.position,
              y: newPosition - 56,
            },
          };
        }

        if (isNodeFromCsv) {
          lastVisiblePositionCsv = node.position.y + 56;
        } else if (isNodeMailing) {
          lastVisiblePositionMailing = node.position.y + 56;
        }

        return {
          ...node,
          position: { ...node.position },
        };
      }

      return {
        ...node,
        hidden: true,
        position: { ...node.position },
      };
    });
    let currentYPosition = 4;

    const showEmptyFromCsv = updatedNodes.map((nodeFormCsv) => {
      if (nodeFormCsv.type === 'nodeFromCsv') {
        const isHidden = Boolean(!nodeFormCsv.data.label);

        const newPosition = {
          x: nodeFormCsv.position.x,
          y: isHidden ? nodeFormCsv.position.y : currentYPosition,
        };

        if (!isHidden) {
          currentYPosition += 60;
        }

        if (showEmptyColumns) {
          return {
            ...nodeFormCsv,
            hidden: isHidden,
            position: newPosition,
          };
        }
        return {
          ...nodeFormCsv,
          hidden: false,
          position: newPosition,
        };
      }

      return nodeFormCsv;
    });
    return showEmptyFromCsv;
  };

  const toggleListPhones = (name) => {
    setListShowPhonesNodes((prevSwitches) =>
      prevSwitches.map((phone) =>
        phone.name === name ? { ...phone, value: !phone.value } : phone
      )
    );
  };

  const toggleListJokers = (name) => {
    setListShowJokersNodes((prevSwitches) =>
      prevSwitches.map((joker) =>
        joker.name === name ? { ...joker, value: !joker.value } : joker
      )
    );
  };

  const handleSaveConfig = () => {
    const { nodes, setNodes, setEdges, edges } = dataNodes;
    setNodes(updateVisibleNodes(nodes));
    setEdges(updatedEdges(edges, updateVisibleNodes(nodes), nodes));
    setHasSavedTemplate(false);
    setShowNodesNulls(showEmptyColumns);
    handleCloseConfig();
  };

  const labelHide = t('listLoader.importMailing.flow.configs.label-hide');
  const labelShow = t('listLoader.importMailing.flow.configs.label-show');
  return (
    <>
      <Button
        onClick={handleOpenConfig}
        title={t('listLoader.importMailing.flow.title-config')}
      >
        <FaGear />
      </Button>

      <DialogComponent
        open={hasOpemConfig}
        onClose={handleCloseConfig}
        fullWidth={true}
      >
        <ContentConfigMailing>
          <TitleComponent
            iconTitle='config'
            textTitle={t('listLoader.importMailing.flow.title-config')}
          />
          <MainConfigContent
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Stack spacing={1}>
              <TitleComponent
                textTitle={t(
                  'listLoader.importMailing.flow.configs.title-phones'
                )}
              />
              <Grid container spacing={1} style={{ paddingLeft: '4px' }}>
                {listShowPhonesNodes.map((nodePhone, i) => (
                  <Grid item xs={4} key={i}>
                    <SwitchComponent
                      label={
                        nodePhone.value
                          ? `${labelHide} ${nodePhone.name}`
                          : `${labelShow} ${nodePhone.name}`
                      }
                      value={nodePhone.value}
                      onChange={() => toggleListPhones(nodePhone.name)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Stack>

            <Stack spacing={1}>
              <TitleComponent
                textTitle={t(
                  'listLoader.importMailing.flow.configs.title-jokers'
                )}
              />
              <Grid container spacing={1}>
                {listShowJokersNodes.map((nodeJoker, i) => (
                  <Grid item xs={4} key={i}>
                    <SwitchComponent
                      label={
                        nodeJoker.value
                          ? `${labelHide} ${nodeJoker.name}`
                          : `${labelShow} ${nodeJoker.name}`
                      }
                      value={nodeJoker.value}
                      onChange={() => toggleListJokers(nodeJoker.name)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Stack>

            <Stack spacing={1}>
              <TitleComponent
                textTitle={t(
                  'listLoader.importMailing.flow.configs.title-nulls'
                )}
              />
              <Grid container spacing={1} style={{ paddingLeft: '4px' }}>
                <Grid item xs={4}>
                  <SwitchComponent
                    label={
                      showEmptyColumns
                        ? `${labelHide} ${t(
                            'listLoader.importMailing.flow.configs.title-nulls'
                          )}`
                        : `${labelShow} ${t(
                            'listLoader.importMailing.flow.configs.title-nulls'
                          )}`
                    }
                    value={showEmptyColumns}
                    onChange={setShowEmptyColumns}
                  />
                </Grid>
              </Grid>
            </Stack>
          </MainConfigContent>

          <div className='footerConfig'>
            <Button
              typeText
              variant='secondary'
              size='small'
              onClick={handleCloseConfig}
            >
              {t('listLoader.importMailing.flow.btn-cancel')}
            </Button>
            <Button typeText onClick={handleSaveConfig}>
              {t('listLoader.importMailing.flow.btn-save')}
            </Button>
          </div>
        </ContentConfigMailing>
      </DialogComponent>
    </>
  );
};
