import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { motion } from 'framer-motion';
import { Grid } from '@mui/material';
import { Stack } from '@mui/system';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ContainerCreatedSchedule } from './styled';
import { useTranslation } from 'react-i18next';
import { newScheduleList } from '@/store/ScheduleStore/SchedulerList';
import { useDaysOfWeek } from '../../../hooks/useDaysOfWeek';

export const CreatedSchedule = ({ controlStages }) => {
  const { t } = useTranslation();
  const { daysOfWeek } = useDaysOfWeek();
  const [newSchedule, setNewSchedule] = useAtom(newScheduleList);

  const renderTxt = (word) => {
    return t(
      `dialControl.schedulesList.createSchedule.scheduleCreated.${word}`
    );
  };

  useEffect(() => {
    return () => setNewSchedule(null);
  }, []);

  const handleNewSchedule = () => {
    controlStages.toNewSchedule(0);
    setNewSchedule(null);
  };

  const handleListSchedule = () => {
    controlStages.toListSchedule();
    setNewSchedule(null);
  };

  const renderDispositions = (dataSchedule) => {
    if (dataSchedule) {
      const used = [];
      for (let i = 1; i <= 10; i++) {
        const codDisposition = dataSchedule[`cod_lig${i}`];
        codDisposition && used.push(codDisposition);
      }
      return used.length > 0 ? (
        <strong>{used.join(', ')}</strong>
      ) : (
        <strong>-</strong>
      );
    }
    return <strong>-</strong>;
  };

  const renderWeekDays = (listWeek) => {
    if (!listWeek || listWeek.length === 0) {
      return <strong>-</strong>;
    }
    const days = [];
    for (let i = 0; i < listWeek.length; i++) {
      const filterDay = daysOfWeek.filter(
        (weekDay) => weekDay.numberDay === listWeek[i]
      )[0];
      if (!filterDay) return;
      days.push(filterDay.title);
    }
    return days.length > 0 ? (
      <strong>{days.join(', ')}</strong>
    ) : (
      <strong>-</strong>
    );
  };

  return (
    <ContainerCreatedSchedule>
      <div className='main'>
        <motion.div
          className='success-checkmark'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className='check-icon'>
            <span className='icon-line line-tip'></span>
            <span className='icon-line line-long'></span>
            <div className='icon-circle'></div>
            <div className='icon-fix'></div>
          </div>
        </motion.div>
        <div className='detail-created'>
          <p>{renderTxt('title')}</p>

          <div className='description-schedule'>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <span>
                  {renderTxt('label-queue')}:{' '}
                  <strong>{newSchedule?.fila ?? '-'}</strong>
                </span>
              </Grid>
              <Grid item xs={12} lg={6}>
                <span>
                  {renderTxt('label-list')}:{' '}
                  <strong> {newSchedule?.id_tabela_lista ?? '-'}</strong>
                </span>
              </Grid>
              <Grid item xs={12} lg={6}>
                <span>
                  {renderTxt('label-timeInit')}:{' '}
                  <strong>{newSchedule?.horario ?? '-'}</strong>
                </span>
              </Grid>
              <Grid item xs={12} lg={6}>
                <span>
                  {renderTxt('label-timeEnd')}:{' '}
                  <strong>{newSchedule?.horario_termino ?? '-'}</strong>
                </span>
              </Grid>
              <Grid item xs={12} lg={6}>
                <span>
                  {renderTxt('label-positionList')}:{' '}
                  <strong> {newSchedule?.posicao ?? '-'}</strong>
                </span>
              </Grid>
              <Grid item xs={12} lg={6}>
                <span>
                  {renderTxt('label-positionQueue')}:{' '}
                  <strong> {newSchedule?.fone_pos ?? '-'}</strong>
                </span>
              </Grid>
              <Grid item xs={12} lg={12}>
                <span>{renderTxt('label-weekDays')}: </span>
                <strong> {renderWeekDays(newSchedule.dia_semana)}</strong>
              </Grid>
              <Grid item xs={12} lg={12}>
                <span>{renderTxt('label-codeLigs')}: </span>
                {renderDispositions(newSchedule)}
              </Grid>
            </Grid>
          </div>

          <Stack alignItems={'center'} spacing={1}>
            <Button variant='text' onClick={handleNewSchedule}>
              {renderTxt('btn-newSchedule')}
            </Button>
            <span>or </span>
            <Button onClick={handleListSchedule}>
              {renderTxt('btn-allSchedules')}
            </Button>
          </Stack>
        </div>
      </div>
    </ContainerCreatedSchedule>
  );
};
