import { useMutation, useQuery } from 'react-query';
import { useAtom } from 'jotai';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { createScreen } from '@/services/userAgents/screens/createScreen';
import { updateScreen } from '@/services/userAgents/screens/updateScreen';
import { getScreens } from '@/services/userAgents/screens/getScreens';
import {
  currentFlowScreens,
  dataScreens,
  dataSelectedScreens,
} from '@/store/Screens';
import { deleteScreen } from '@/services/userAgents/screens/deleteScreen';
import { useTranslation } from 'react-i18next';

export const useScreens = () => {
  const { t } = useTranslation();
  const [, setListScreens] = useAtom(dataScreens);
  const [stateRenderScreens] = useAtom(currentFlowScreens);
  const [, setScreenSelected] = useAtom(dataSelectedScreens);

  const {
    isLoading: isLoadingDataScreen,
    isFetching: isFetchingCreate,
    refetch: refetchScreensList,
  } = useQuery(
    'dataScreenList',
    async ({ signal }) => {
      return await getScreens(signal);
    },
    {
      refetchOnWindowFocus: false,
      enabled: stateRenderScreens === 0,
      retry: 0,
      onSuccess: (data) => setListScreens(data),
      onError: (error) => {
        const errorMessage = error.message || JSON.stringify(error);
        const toastMessage =
          errorMessage === 'User is not SysAdmin!'
            ? null
            : errorToast(
                t(
                  'securityProfile.homeSecurityProfiles.messages.msg-error-getScreens'
                )
              );
        return toastMessage;
      },
    }
  );

  const { mutate: mutateCreateScreen, isLoading: loadingCreateScreen } =
    useMutation({
      mutationFn: async (dataCreate) => {
        return await createScreen(dataCreate);
      },
      onError: () => {
        errorToast(
          t(
            'securityProfile.homeSecurityProfiles.messages.msg-error-CreateScreens'
          )
        );
      },
    });

  const { mutate: mutateUpdateScreen, isLoading: loadingUpdateScreen } =
    useMutation({
      mutationFn: async (dataUpdate) => {
        return await updateScreen(dataUpdate);
      },
      onError: () => {
        errorToast(
          t(
            'securityProfile.homeSecurityProfiles.messages.msg-error-EditScreens'
          )
        );
      },
    });

  const { mutate: mutateDeleteScreen, isLoading: loadingDeleteScreen } =
    useMutation({
      mutationFn: async (dataDelete) => {
        return await deleteScreen(dataDelete);
      },
      onSuccess: (data) => {
        if (data === 'There is an existing relationship!') {
          return errorToast(
            t(
              'securityProfile.homeSecurityProfiles.messages.msg-error-relationship'
            )
          );
        }
        successToast(
          t(
            'securityProfile.homeSecurityProfiles.messages.msg-success-deleteScreens'
          )
        );
        setScreenSelected(null);
        refetchScreensList();
      },
      onError: () => {
        return errorToast(
          t(
            'securityProfile.homeSecurityProfiles.messages.msg-error-deleteScreens'
          )
        );
      },
    });

  return {
    mutateCreateScreen,
    loadingCreateScreen,
    isFetchingCreate,
    mutateUpdateScreen,
    loadingUpdateScreen,
    isLoadingDataScreen,
    refetchScreensList,

    mutateDeleteScreen,
    loadingDeleteScreen,
  };
};
