import { AddNewUserAgent } from './stages/AddNewUserAgent';
import { HomeUsers } from './stages/HomeUsers';
import { useAtom } from 'jotai';
import { stateRenderUsers } from '@/store/UsersAgentsStore/userAgentsStore';
import { ContainerListUsers } from './styled';
import { EditUser } from './stages/EditUser';
import { useEffect, useId } from 'react';
import { Screens } from '../screens';
import { EditSecurityProfile } from '../SecurityProfile/stages/EditSecurityProfile';

export const ListUsers = () => {
  const [stateRenderUsersAgents, setStateRenderUsersAgents] =
    useAtom(stateRenderUsers);

  useEffect(() => {
    return () => setStateRenderUsersAgents(0);
  }, []);

  const flowUsersAgents = [
    <HomeUsers key={useId()} />,
    <AddNewUserAgent key={useId()} />,
    <EditUser key={useId()} />,
    <Screens
      origin={'userAgents'}
      controlState={setStateRenderUsersAgents}
      key={useId()}
    />,
    <EditSecurityProfile
      key={useId()}
      origin={'userAgents'}
      controlState={setStateRenderUsersAgents}
    />,
  ];

  return (
    <ContainerListUsers>
      {flowUsersAgents[stateRenderUsersAgents]}
    </ContainerListUsers>
  );
};
