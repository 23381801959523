import { apiData } from '@/services/api';

export async function getCountListByListService(dataSearch) {
  try {
    const { data } = await apiData.post('/reports/listCount', dataSearch);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
