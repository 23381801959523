import { MdOutlineAppBlocking, MdOutlineBlock } from 'react-icons/md';
import { ContainerRestrictions } from './styled';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ItemRestrict = ({ itemName }) => {
  const { t } = useTranslation();
  const { name, isRestrict } = itemName;
  return (
    isRestrict && (
      <Stack direction={'column'} sx={{ paddingLeft: '0.75rem' }} spacing={0.5}>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <MdOutlineBlock />
          <span>
            {t('dialing.label-restrict')} {name}
          </span>
        </Stack>
      </Stack>
    )
  );
};

export const PanelRestrictions = () => {
  const { t } = useTranslation();

  const dataRestrictions = [
    {
      name: 'UF',
      isRestrict: true,
      listRestrict: ['Horários Permitidos'],
    },
    {
      name: 'DDD',
      isRestrict: true,
      listRestrict: ['Horários Permitidos'],
    },
  ];

  return (
    <ContainerRestrictions>
      <Stack spacing={1} justifyContent={'center'} height={'100%'}>
        <div className='header'>
          <MdOutlineAppBlocking />
          <h2>{t('dialing.title-restrictions')}:</h2>
        </div>
        <div className='main'>
          {dataRestrictions &&
            dataRestrictions.map((item, i) => (
              <ItemRestrict key={i} itemName={item} />
            ))}
        </div>
      </Stack>
    </ContainerRestrictions>
  );
};
