import { useTranslation } from 'react-i18next';
import { Button } from '@components/FormComponents/ButtonComponent';

import { ContainerResetPassword } from './styled';
import { TitleUsers } from '../styled';

import { AiOutlineUser } from 'react-icons/ai';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { CircularProgress } from '@mui/material';
import { forgotPassword } from '@/services/sessions/forgotPassword';

export const ResetPasswordUser = ({ closeDialog, emailUser }) => {
  const { t } = useTranslation();

  const [handleConfirmationReset, setHandleConfirmationReset] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: (email) => {
      return forgotPassword(email);
    },
    retry: false,
    onSuccess: () => {
      setHandleConfirmationReset(true);
    },
    onError: () => {
      errorToast(t('profileSettings.messages.errorUpdate'));
    },
  });

  return (
    <ContainerResetPassword initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <TitleUsers>
        <AiOutlineUser />
        <h2>{t('usersAgentsList.addUser.title-passwordSteps')}</h2>
      </TitleUsers>
      {!handleConfirmationReset ? (
        <>
          <div className='mainContent'>
            <p>{t('usersAgentsList.editUser.sentenceConfirmation')}</p>
            <span> </span>
          </div>
          <div className='footerContent'>
            <Button typeText variant={'secondary'} onClick={closeDialog}>
              {t('usersAgentsList.editUser.btn-cancel')}
            </Button>
            <Button onClick={() => mutate(emailUser)}>
              {isLoading ? (
                <CircularProgress size={22} />
              ) : (
                t('usersAgentsList.editUser.btn-reset')
              )}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className='mainContent'>
            <p>{t('usersAgentsList.editUser.sentenceResetPassword')}</p>
            <span>
              <strong>{emailUser}</strong>
            </span>
          </div>
          <div className='footerContent'>
            <Button typeText onClick={closeDialog}>
              Ok
            </Button>
          </div>
        </>
      )}
    </ContainerResetPassword>
  );
};
