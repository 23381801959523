import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerRoutingProfile = styled(motion.div)`
  width: 100%;
  height: 98%;
  min-height: 400px;

  ${(props) =>
    props.stageRender
      ? css`
          padding: 0;
        `
      : css`
          padding: 3rem 2rem 2rem 2rem;
        `}

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  overflow: auto;
  margin-bottom: 1rem;
  user-select: none;

  @media (max-height: 500px) {
    height: 80%;
    min-height: 100px;
  }

  .content_btn_new_route {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;
