import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import { motion } from 'framer-motion';
import { Stack } from '@mui/material';
import { FaArrowRightToBracket, FaGear } from 'react-icons/fa6';
import { TbSquareRounded } from 'react-icons/tb';
import { FiTrash2 } from 'react-icons/fi';
import { useEndpoints } from '@/hooks/FlowBuilder/useEndpoints';
import useFormControl from '@/hooks/useFormControl';
import { dataNlus, templateSelected } from '@/store/FlowBuilder';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ItemIntent } from './ItemIntent';
import { FooterConfig } from '../../../FlowBuilderComponent/NavigationFlow/ConfigCurrentFlow/styled';
import {
  ContainerFormIntents,
  ContainerManagementEndpoints,
  ContentIntents,
  ContentManagementEndpoints,
  FormAddEndpoint,
  PanelIntents,
} from './styled';

export const ManageEndpoints = ({ controlStage }) => {
  const { t } = useTranslation();
  const { loadingCreateEndpoint } = useEndpoints();
  const [nlus, setNlus] = useAtom(dataNlus);
  const [stageFormNlu, setStageFormNlu] = useState(0);
  const [template] = useAtom(templateSelected);
  const [usedNlus, setUsedNlus] = useState([]);
  const inputNewIntent = useRef(null);

  const hasUsedEndpoints = () => {
    const { nodes } = template;
    const listNodes = nodes ? nodes : [];
    const dispositionsUsed = listNodes
      .filter((item) => item.data.nluEndpoint)
      .map((node) => node.data.nluEndpoint);

    return dispositionsUsed;
  };

  useEffect(() => {
    setUsedNlus(hasUsedEndpoints());
  }, []);

  useEffect(() => {
    if (inputNewIntent.current && stageFormNlu === 1) {
      inputNewIntent.current.focus();
    }
  }, [stageFormNlu]);

  const [selectedNlu, setSelectedNlu] = useState(null);

  const nluSchema = yup.object().shape({
    nameEndpoint: yup
      .string()
      .required()
      .matches(
        /^[\w]+$/,
        t('flowBuilder.configs.endpoints.msg-endPoint-chars')
      ),
    newIntent: yup.string(),
    intents: yup
      .array()
      .of(yup.string().required())
      .required()
      .min(1, t('flowBuilder.configs.endpoints.msg-intent-required')),
  });

  const defaultValues = {
    nameEndpoint: '',
    newIntent: '',
    intents: [],
  };

  const {
    handleSubmit,
    control,
    reset,
    resetField,
    errors,
    clearErrors,
    watch,
    setValue,
    setError,
  } = useFormControl(nluSchema, defaultValues);

  const listIntents = watch('intents');
  const nameEndpoint = watch('nameEndpoint');

  const validateString = (str) => {
    const regex = /^[\w]+$/;
    if (regex.test(str)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmitNewEndpoint = (data) => {
    const newEndpoint = {
      id: uuid(),
      name: data.nameEndpoint,
      type: data.nameEndpoint,
      data: data.intents,
    };
    setNlus((previewsNlus) => [newEndpoint, ...previewsNlus]);
    setStageFormNlu(0);
    reset();
  };

  const handleNewIntent = (newIntent) => {
    if (newIntent.trim()) {
      const hasIntent = listIntents.includes(newIntent);
      const hasValidate = validateString(newIntent);

      if (!hasValidate) {
        setError('newIntent', {
          type: 'manual',
          message: t('flowBuilder.configs.endpoints.msg-endPoint-chars'),
        });
        return;
      }

      if (hasIntent) {
        setError('newIntent', {
          type: 'manual',
          message: t('flowBuilder.configs.endpoints.msg-intent-exists'),
        });
        return;
      }
      setValue('intents', [newIntent, ...listIntents]);
      resetField('newIntent');
    }
  };

  const handleRemoveNewIntent = (index) => {
    const updatedIntents = listIntents.filter((_, i) => i !== index);
    setValue('intents', updatedIntents);
    resetField('newIntent');
  };

  const handleClearData = () => {
    reset(defaultValues);
    setSelectedNlu(null);
    setStageFormNlu(0);
  };

  const handleUpdateNlu = (nlu) => {
    setSelectedNlu(nlu);
    setStageFormNlu(1);
    reset({
      nameEndpoint: nlu.name,
      newIntent: '',
      intents: nlu.data,
    });
  };

  const updateNlu = (data) => {
    const updatedNluList = nlus.map((nlu) => {
      if (nlu.id === selectedNlu.id) {
        return {
          ...nlu,
          name: data.nameEndpoint,
          type: data.nameEndpoint,
          data: data.intents,
        };
      }
      return nlu;
    });
    setNlus(updatedNluList);
    handleClearData();
  };

  const handleRemoveNlu = () => {
    const updatedNluList = nlus.filter((nlu) => nlu.id !== selectedNlu.id);
    setNlus(updatedNluList);
    handleClearData();
  };

  const nextStageForm = () => {
    const hasNlusName = nlus.some((nlu) => nlu.name === nameEndpoint);
    const isValidName = validateString(nameEndpoint);

    if (hasNlusName) {
      setError('existisEndpoint', {
        type: 'manual',
        message: t(''),
      });
      return;
    }

    if (!isValidName) {
      setError('nameEndpoint', {
        type: 'manual',
        message: t('flowBuilder.configs.endpoints.msg-endPoint-chars'),
      });
      return;
    }

    clearErrors();
    setStageFormNlu((previewStage) => previewStage + 1);
    return;
  };

  const handleKeyDown = (event, typeSubmit) => {
    if (event.key === 'Enter') {
      switch (typeSubmit) {
        case 'newEndpoint':
          return nameEndpoint.length > 0 && nextStageForm();
        case 'newIntent':
          event.preventDefault();
          return (
            watch('newIntent').length > 0 && handleNewIntent(watch('newIntent'))
          );
        default:
          return;
      }
    }
  };

  const renderSubmit = selectedNlu ? updateNlu : handleSubmitNewEndpoint;

  return (
    <div className=''>
      <ContainerManagementEndpoints
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {loadingCreateEndpoint && <BackDropCircularLoading />}
        <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
          className={'titleConfig'}
        >
          <FaGear />
          <h4> {t('flowBuilder.configs.endpoints.btn-add-endPoint')}</h4>
        </Stack>

        <ContentManagementEndpoints>
          <FormAddEndpoint onSubmit={handleSubmit(renderSubmit)}>
            <ControlInput
              label={t('flowBuilder.configs.endpoints.label-name-newEndpoint')}
              control={control}
              nameControl={'nameEndpoint'}
              error={Boolean(errors.nameEndpoint)}
              onKeyDown={(e) => handleKeyDown(e, 'newEndpoint')}
              disabled={stageFormNlu > 0}
            />
            {stageFormNlu === 0 && (
              <Stack
                width={'100%'}
                justifyContent={'flex-end'}
                alignItems={'flex-end'}
              >
                <Button
                  typeText
                  type='button'
                  onClick={nextStageForm}
                  disabled={nameEndpoint === ''}
                >
                  {t('flowBuilder.configs.btn-add')}
                </Button>
              </Stack>
            )}

            {stageFormNlu > 0 && (
              <PanelIntents
                initial={{ opacity: 0 }}
                animate={{ opacity: stageFormNlu > 0 ? 1 : 0 }}
              >
                <ControlInput
                  label={t('flowBuilder.configs.endpoints.label-name-intent')}
                  control={control}
                  nameControl={'newIntent'}
                  inputRef={inputNewIntent}
                  error={Boolean(errors.newIntent)}
                  helperText={errors.newIntent?.message}
                  onKeyDown={(e) => handleKeyDown(e, 'newIntent')}
                  InputProps={{
                    endAdornment: (
                      <Stack
                        direction={'row'}
                        spacing={'2px'}
                        className='panel-btn-control-intent'
                      >
                        <Button
                          typeText
                          type='button'
                          onClick={() => handleNewIntent(watch('newIntent'))}
                        >
                          <FaArrowRightToBracket />
                        </Button>
                      </Stack>
                    ),
                  }}
                />
              </PanelIntents>
            )}

            <ContainerFormIntents>
              <ul>
                {listIntents.map((intent, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    <div>
                      <TbSquareRounded />
                      <span>{intent}</span>
                    </div>
                    <motion.button
                      type='button'
                      onClick={() => handleRemoveNewIntent(index)}
                      whileTap={{ scale: 0.95 }}
                    >
                      <FiTrash2 />
                    </motion.button>
                  </motion.li>
                ))}
              </ul>
            </ContainerFormIntents>
            {stageFormNlu > 0 && (
              <Stack
                width={'100%'}
                justifyContent={'space-between'}
                alignItems={'center'}
                direction={'row'}
                spacing={1}
              >
                {selectedNlu && (
                  <Button
                    typeText
                    type='button'
                    variant='secondary'
                    onClick={handleRemoveNlu}
                    sx={{
                      minWidth: '20px',
                    }}
                  >
                    <FiTrash2 size={14} />
                  </Button>
                )}

                <Stack
                  width={'100%'}
                  justifyContent={'flex-end'}
                  alignItems={'flex-end'}
                  direction={'row'}
                  spacing={1}
                >
                  <Button
                    typeText
                    type='button'
                    variant='secondary'
                    onClick={handleClearData}
                  >
                    {t('flowBuilder.configs.btn-cancel')}
                  </Button>
                  <Button
                    typeText
                    type='submit'
                    disabled={listIntents.length === 0}
                  >
                    {selectedNlu
                      ? t('flowBuilder.configs.btn-update')
                      : t('flowBuilder.configs.btn-add')}
                  </Button>
                </Stack>
              </Stack>
            )}
          </FormAddEndpoint>
          <div className='divider'></div>
          <ContentIntents>
            <ul>
              {nlus.length > 0 &&
                nlus.map((intents, i) => (
                  <li key={i}>
                    <ItemIntent
                      intent={intents}
                      selected={selectedNlu}
                      removeIntent={handleRemoveNlu}
                      updateIntent={handleUpdateNlu}
                      usedNlus={usedNlus}
                    />
                  </li>
                ))}
            </ul>
          </ContentIntents>
        </ContentManagementEndpoints>

        <FooterConfig>
          <div className=''></div>
          <Stack direction={'row'} spacing={1}>
            <Button typeText variant='secondary' onClick={controlStage}>
              {t('flowBuilder.configs.btn-back')}
            </Button>
          </Stack>
        </FooterConfig>
      </ContainerManagementEndpoints>
    </div>
  );
};
