import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { ItemContainer } from './styled';
import { verifyIcon } from '../iconVerify';
import {
  confirmationOfExchange,
  currentPageId,
  hasSubItem,
  idSubItem,
  isCloseItem,
  isReload,
} from '@store/NavigationDashboard';
import { useState } from 'react';
import { ConfirmationToChangePage } from '../ConfirmationToChangePage';
import { isSavedFlow } from '@/store/FlowBuilder';

export const ItemMenu = ({
  itemId,
  itemIcon,
  itemName,
  itemAlias,
  link,
  hasExternalUrl,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:1020px)');

  const [currentPage, setCurrentId] = useAtom(currentPageId);
  const [, setSubItemId] = useAtom(idSubItem);
  const [, setHaveSubItem] = useAtom(hasSubItem);
  const [, setIsReload] = useAtom(isReload);
  const [, setIsClosed] = useAtom(isCloseItem);
  const [hasConfirmationExchange, setConfirmationExchange] = useAtom(
    confirmationOfExchange
  );

  const [hasSavedFlow, setSavedFlow] = useAtom(isSavedFlow);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleNavigate = () => {
    navigate(link);
  };

  const reloadPage = async () => {
    setIsReload(true);
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(setIsReload(false));
      }, 5);
    });
  };

  const confirmationChange = (hasConfirmation = false) => {
    if (hasConfirmation) {
      setOpenConfirmation(true);
      return false;
    }
    return true;
  };

  const confirmationFlowBuilder = (hasConfirmation) => {
    if (!hasConfirmation) {
      setOpenConfirmation(true);
      return false;
    }
    return true;
  };

  const handleNextConfirmation = (itemId) => {
    if (itemId === currentPage) {
      setConfirmationExchange(false);
      setSavedFlow(true);
      return reloadPage();
    }
    setSavedFlow(true);
    setConfirmationExchange(false);
    setCurrentId(itemId);
    setHaveSubItem(false);
    setSubItemId(false);
    if (matches) {
      setIsClosed(true);
    }
  };

  const handlePageMainDashboard = (itemId) => {
    if (itemId === currentPage) {
      const confirmationChangeLoadLists = confirmationChange(
        hasConfirmationExchange
      );

      const confirmationChangeFlowBuilder =
        confirmationFlowBuilder(hasSavedFlow);

      if (!confirmationChangeLoadLists || !confirmationChangeFlowBuilder) {
        return;
      }
      return reloadPage();
    }

    const confirmationChangeLoadLists = confirmationChange(
      hasConfirmationExchange
    );

    const confirmationChangeFlowBuilder = confirmationFlowBuilder(hasSavedFlow);

    if (!confirmationChangeLoadLists || !confirmationChangeFlowBuilder) {
      return;
    }
    setCurrentId(itemId);
    setHaveSubItem(false);
    setSubItemId(false);
    if (matches) {
      setIsClosed(true);
    }
  };

  const renderNameItem = (hasExternalUrl, itemName, itemAlias) => {
    const renderName = t(`dashboard.${itemName}`);

    if (renderName === `dashboard.${itemName}`) {
      return <span>{itemName}</span>;
    }

    const checkName = renderName.split('.');
    if (hasExternalUrl) {
      return <span>{itemName}</span>;
    }
    if (checkName.length === 2) {
      return <span>{itemAlias}</span>;
    }

    if (renderName === `dashboard.${itemName}`) {
      return itemName;
    }

    return <span>{renderName}</span>;
  };

  if (!link) {
    return (
      <>
        <ItemContainer
          onClick={() => handlePageMainDashboard(itemId)}
          aria-label={itemName}
          tabIndex={0}
        >
          {verifyIcon(itemIcon)}
          {renderNameItem(hasExternalUrl, itemName, itemAlias)}
        </ItemContainer>
        <ConfirmationToChangePage
          hasOpem={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          confirmCallback={() => handleNextConfirmation(itemId)}
        />
      </>
    );
  } else {
    return (
      <>
        <ItemContainer
          onClick={handleNavigate}
          aria-label={itemName}
          tabIndex={0}
        >
          {verifyIcon(itemIcon)}
          {renderNameItem(hasExternalUrl, itemName, itemAlias)}
        </ItemContainer>
      </>
    );
  }
};
