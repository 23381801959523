import { Handle, Position } from 'reactflow';
import { useAtom } from 'jotai';
import React, { memo, useCallback } from 'react';
import { AiFillApi } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { shortNameRender } from '../../../../utils/shortNameRender';
import { NodeConditionContainer, NodeContent } from './styled';
import { FlexBoxRow } from '../styled';
import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { PanelNode } from '@/components/pages/FlowBuilder/components/PanelNode';

const DataApiNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [, setSelectedNode] = useAtom(selectedNode);

  const handleNode = useCallback(() => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  }, [id, data, setSelectedNode, setShowMenu]);

  return (
    <NodeConditionContainer
      selected={selected}
      errorConnect={data.errorConnect ? 1 : 0}
      onDoubleClick={() => handleNode()}
      handler={data.handler}
    >
      <PanelNode data={data} id={id} />
      <Handle type='target' position={Position.Left} />

      <NodeContent>
        <div className='header'>
          <AiFillApi size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 10)}</h4>
            <span>{t('flowBuilder.flow.nodes.dataApi.label')}</span>
          </div>
        </div>
        <div className='main'>
          <FlexBoxRow>
            <span>{t('flowBuilder.flow.nodes.dataApi.label-url')}: </span>
            <span>
              <strong>
                {shortNameRender(
                  data.url
                    ? data.url
                    : t('flowBuilder.flow.nodes.dataApi.label-define-url'),
                  26
                )}
              </strong>
            </span>
          </FlexBoxRow>
          <FlexBoxRow>
            <span>{t('flowBuilder.flow.nodes.dataApi.label-method')}: </span>
            <span>
              <strong>
                {data.method
                  ? data.method
                  : t('flowBuilder.flow.nodes.dataApi.label-define-method')}
              </strong>
            </span>
          </FlexBoxRow>
        </div>
      </NodeContent>
    </NodeConditionContainer>
  );
};

export const DataApiNode = memo(DataApiNodeComponent);
