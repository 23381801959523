import { useAtom } from 'jotai';
import { currentFlowClients } from '@/store/Clients';
import { HomeClients } from './stages/HomeClients';
import { ContainerListClients } from './styled';
import { CreateClient } from './stages/CreateClient';
import { useEffect, useId } from 'react';
import { EditClient } from './stages/EditClient';

export const ListClients = () => {
  const [stateRenderClients, setStateRenderClients] =
    useAtom(currentFlowClients);

  useEffect(() => {
    return () => {
      setStateRenderClients(0);
    };
  }, []);

  const flowClients = [
    <HomeClients key={useId()} />,
    <CreateClient key={useId()} />,
    <EditClient key={useId()} />,
  ];

  return (
    <ContainerListClients>
      {flowClients[stateRenderClients]}
    </ContainerListClients>
  );
};
