import { useState } from 'react';
import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiPlusCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { FooterForm, FormAddNewScreen, MainForm } from './styled';
import { dataScreens } from '@/store/Screens';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { TableScreens } from './TableScreens';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useRoutingProfile } from '@/hooks/UserAgents/useRoutingProfile';
import { dataRoutesProfileSelected } from '@/store/UsersAgentsStore/userAgentsStore';
import { useScreens } from '@/hooks/UserAgents/useScreens';
import { useRenderNamesRoutingProfile } from '../../../hooks/useRenderNamesRoutingProfile';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const AddScreenRouting = ({ handleChange, origin }) => {
  const { t } = useTranslation();
  const { renderNameItem } = useRenderNamesRoutingProfile();
  const { mutateCreateScreenRouting, loadingCreateScreenRouting } =
    useRoutingProfile();

  const { isLoadingDataScreen } = useScreens();

  const [dataListScreens] = useAtom(dataScreens);
  const [dataRouteSelected] = useAtom(dataRoutesProfileSelected);
  const [dataNewScreens, setDataNewScreens] = useState([]);

  const addNewClientSchema = yup.object().shape({
    screen: yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(addNewClientSchema),
  });

  const screenSelected = watch('screen');

  const renderListScreensToAdd = () => {
    const { screens } = dataRouteSelected;
    let listScreens = dataListScreens;
    let usedIds = screens.map((itemScreens) => itemScreens.id);

    usedIds.forEach((id) => {
      let indexToRemove = listScreens.findIndex((item) => item.id === id);
      if (indexToRemove !== -1) {
        listScreens.splice(indexToRemove, 1);
      }
    });
    return listScreens;
  };

  const filterScreenSelected = renderListScreensToAdd().filter(
    (item) => item.id === screenSelected
  )[0];

  const filterExistentScreen = (id) => {
    const isExistentScreen = dataNewScreens.filter((item) => item.id === id);
    if (isExistentScreen.length > 0) {
      return true;
    }
    return false;
  };

  const handlerRemoveScreen = (id) => {
    const listRemovedScreen = dataNewScreens.filter((item) => item.id !== id);
    return setDataNewScreens(listRemovedScreen);
  };

  const handleAddScreen = () => {
    const isExistentScreen = filterExistentScreen(filterScreenSelected.id);

    if (isExistentScreen) {
      return errorToast(
        t(
          'routingProfile.addRoutingProfile.createScreens.messages.msg-alreadyScreen'
        )
      );
    }

    setDataNewScreens((previousState) => [
      ...previousState,
      filterScreenSelected,
    ]);
    reset();
  };

  const handleNext = () => {
    handleChange(0);
    setDataNewScreens([]);
    reset();
  };

  const handleBack = () => {
    handleChange(0);
    setDataNewScreens([]);
    reset();
  };

  const handleCreateScreen = () => {
    const { id: idRoutingSelected } = dataRouteSelected;

    const mappingNewScreens = dataNewScreens.map((item) => {
      return {
        security_profiles_id: item.id,
        routing_id: idRoutingSelected,
      };
    });

    const newScreensSchema = {
      roles: mappingNewScreens,
    };

    mutateCreateScreenRouting(newScreensSchema, {
      onSuccess: () => {
        successToast('Screen was created!');
        handleNext();
      },
    });
  };

  const isLoadingDataRouting =
    isLoadingDataScreen || loadingCreateScreenRouting;

  return (
    <FormAddNewScreen
      onSubmit={handleSubmit(handleCreateScreen)}
      origin={origin ? 1 : 0}
    >
      {isLoadingDataRouting && <BackDropCircularLoading />}
      <TitleComponent textTitle={'Add new screens'} />
      <MainForm>
        <Stack spacing={2} direction={'row'}>
          <ControlSelect
            control={control}
            nameControl={'screen'}
            label={t(
              'routingProfile.addRoutingProfile.createScreens.label-screen'
            )}
            error={Boolean(errors.screen)}
            helperText={errors.screen ? errors.screen.message : '   '}
          >
            <ItemSelect value={'0'} disabled>
              {t('routingProfile.addRoutingProfile.createScreens.label-screen')}
            </ItemSelect>
            {dataListScreens.length > 0 &&
              dataListScreens.map(
                (item) =>
                  !filterExistentScreen(item.id) && (
                    <ItemSelect value={item.id} key={item.id}>
                      {renderNameItem(item.name)}
                    </ItemSelect>
                  )
              )}
          </ControlSelect>
          <Button
            onClick={handleAddScreen}
            style={{ height: '41px' }}
            disabled={!screenSelected || screenSelected === '0'}
          >
            <FiPlusCircle size={22} />
          </Button>
        </Stack>

        <Stack alignItems={'flex-end'}></Stack>

        <TableScreens
          dataTable={dataNewScreens}
          handlerRemoveScreen={handlerRemoveScreen}
        />
      </MainForm>
      <FooterForm>
        <Button typeText variant={'secondary'} onClick={handleBack}>
          {t('routingProfile.addRoutingProfile.createScreens.btn-cancel')}
        </Button>
        <Button typeText type='submit' disabled={!dataNewScreens.length}>
          {t('routingProfile.addRoutingProfile.createScreens.btn-confirm')}
        </Button>
      </FooterForm>
    </FormAddNewScreen>
  );
};
