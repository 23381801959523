import { apiData } from '@/services/api';

export async function updateScreen(dataUpdate) {
  try {
    const { id } = dataUpdate;
    const { data } = await apiData.put(
      `securityProfiles/updateSecurityProfiles/${id}`,
      dataUpdate
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
}
