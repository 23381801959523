import { AiFillApi } from 'react-icons/ai';
import { BiSolidUserPin } from 'react-icons/bi';
import { BsChatSquareTextFill } from 'react-icons/bs';
import { FaDatabase } from 'react-icons/fa';
import { FaGears } from 'react-icons/fa6';
import { MdPhonelinkSetup, MdShapeLine } from 'react-icons/md';
import { PiMicrophoneSlashFill, PiPhoneCallFill } from 'react-icons/pi';
import { RiExchangeFill, RiRecordMailLine } from 'react-icons/ri';
import { TbVariableMinus } from 'react-icons/tb';
import { TiArrowLoop } from 'react-icons/ti';

export const renderIconsFlow = (iconNameReq) => {
  const iconMap = {
    dialplanVariables: <BiSolidUserPin size={16} />,
    ttsVoicceLabs: <BsChatSquareTextFill size={16} />,
    recVoz: <RiRecordMailLine size={16} />,
    hangUp: <PiMicrophoneSlashFill size={16} />,
    conditionalNode: <MdShapeLine size={16} />,
    disposition: <FaDatabase size={16} />,
    setVariables: <TbVariableMinus size={17} />,
    dial: <PiPhoneCallFill size={17} />,
    apiData: <AiFillApi size={17} />,
    transferQueue: <RiExchangeFill size={17} />,
    functionHandler: <FaGears size={17} />,
    loop: <TiArrowLoop size={16} />,
    dtmf: <MdPhonelinkSetup size={16} />,
  };

  return iconMap[iconNameReq] || null;
};
