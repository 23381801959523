export const dark = {
  title: 'Dark',
  colors: {
    defaultColors: {
      black: '#000000',
      white: '#FFFFFF',
      gray: '#e5e5e5',
      pink: '#ff57AB',
      green: '#43ff86',
      blue: '#4c48ff',
      yellow: '#FFb839',
      orange: '#FF4C43',
      purple: '#a03ffc',
    },

    primary: '#4c48ff',
    secondary: '#FF4C43',
    primaryLight: '#9997f7',
    primaryDark: '#2825d0',
    active: '#787878',
    success: '#0cc04b',
    error: '#ED1414',

    nodesFlow: {
      dialplanVariables: '#4c48ff',
      ttsVoicceLabs: '#026806',
      recVoz: '#2825d0',
      hangUp: '#ff0000',
      conditionalNode: '#9997f7',
      disposition: '#d04809',
      baseEdge: '#FFF',
      statusTemplatePublished: '#FFF',
      statusTemplateNotPublished: '#FFF',

      initNode: '#009905',
      itemRecVoicceNode: '#672e93',
      setVariables: '#31307f',
      dial: '#f01985',
      transferQueue: '#8cbe37',
      apiData: '#2bb495',
      functionHandler: '#696969',
      monestIa: 'rgb(102, 0, 204)',
      deepCenter: '#40719F',
      loop: '#ff57AB',
      dtmf: '#39264c',
      itemDtmf: '#39264c',

      successEdge: '#4c48ff',
      errorEdge: '#FF4C43',
    },

    listNodes: {
      bgCard: '#1f1f1f',
      bgBtn: '#1f1f1f',
      bgBtnDark: '#131111',
      bgCustomEdge: 'rgb(34 37 42)',
      bgSystemEdge: 'rgb(155 138 255)',
      bgErrorEdge: '#FF4C43',
    },

    agentColors: {
      PAUSA: '#FFb839',
      AGLIG: '#0edd56',
      EMLIG: '#4c48ff',
      POSLIG: '#FF4C43',
      LIVRE: '#101010',
      DISCONNECTED: '#9997f7',
    },

    bases: {
      primary: '#4c48ff',
      secondary: '#FF7A00',
      primaryLight: '#8381f8',
      primaryDark: '#0400ff',
      gradientBg: 'linear-gradient( 272.02deg, #4c48ff 0.94%, #4c48ff 102.07%)',
      baseWhite: '#FFFFFF',
    },

    tables: {
      tableHead: '#4c48ff',
      tableRow: '#262626',
    },

    containers: {
      baseContainer: '#262626',
      baseContainerFlow: '#050505d4',
      baseBackground: '#4f4f4f',
      shadowToContainers: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      baseShadows: '2px 2px 4px rgba(39, 57, 94, 0.25)',
      colorCard: '#262626',
      colorNode: '#262626',
      colorSecondNode: '#1f1f1f',
      colorCardSecondary: '#4f4f4f',
      colorCardThird: '#4f4f4f',
      dividerColor: '#5b5b5b',
      primaryToDark: '#262626',
    },

    typography: {
      baseTitle: '#FFFFFF',
      baseText: '#FFFFFF',
      textHeaderTable: '#FFFFFF',
      activeTextSidebar: '#FFFFFF',
      titleSidebarAgent: '#262626',
    },
  },
};
