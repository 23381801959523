import { Handle, Position } from 'reactflow';
import { useAtom } from 'jotai';
import React, { memo, useCallback } from 'react';
import { FaDatabase } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { NodeContent, NodeTabulationContainer } from './styled';
import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { PanelNode } from '@/components/pages/FlowBuilder/components/PanelNode';

const DispositionNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();

  const [, setShowMenu] = useAtom(showMenuNodes);
  const [, setSelectedNode] = useAtom(selectedNode);

  const handleNode = useCallback(() => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  }, [id, data, setSelectedNode, setShowMenu]);

  return (
    <NodeTabulationContainer
      selected={selected}
      errorConnect={data.errorConnect ? 1 : 0}
      onDoubleClick={() => handleNode()}
      handler={data.handler}
    >
      <PanelNode data={data} id={id} />

      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <FaDatabase size={16} />
          <div className='titleNode'>
            <h4>{data.label}</h4>
            <span>{t('flowBuilder.flow.nodes.dispositions.label')}</span>
          </div>
        </div>
      </NodeContent>
      <Handle type='source' position={Position.Right} />
    </NodeTabulationContainer>
  );
};

export const DispositionNode = memo(DispositionNodeComponent);
