import { ContainerFlowProjects } from './styled';
import { useEffect, useState } from 'react';
import { MainHomeFlow } from './MainHomeFlow';
import { CircularAbsoluteLoading } from '@/components/layout/LoadingComponent';
import { useHandleFlow } from '@/hooks/FlowBuilder/useHandlerFlow';
import { useAtom } from 'jotai';
import { adminClientIdSelected } from '@/store/FlowBuilder';

export const FlowSelectProject = ({ changeStageFlow }) => {
  const { loadingFlowTemplate, loadingImportFlow } = useHandleFlow();
  const [selectedItem, setSelectedItem] = useState('recentFlow');
  const [, setClientSelected] = useAtom(adminClientIdSelected);

  useEffect(() => {
    return () => setClientSelected('0');
  }, []);

  const changeStageTypeSelectFlow = (stage) => {
    setSelectedItem(stage);
  };

  const isLoading = loadingFlowTemplate || loadingImportFlow;

  return (
    <ContainerFlowProjects
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.1 }}
    >
      {isLoading && <CircularAbsoluteLoading />}

      <MainHomeFlow
        selectedItem={selectedItem}
        changeStageFlow={changeStageFlow}
        changeStageTypeSelectFlow={changeStageTypeSelectFlow}
      />
    </ContainerFlowProjects>
  );
};
