import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Box } from '@mui/material';
import { useAtom } from 'jotai';
import { ContainerListUf } from './styled';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { TableListUfs } from './TableListUfs';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useUFManager } from '@/hooks/dialControl/UFManager/useUfManager';
import { dataUFManager } from '@/store/DialingStore';
import { useClients } from '@/hooks/Clients/useClients';
import { DialogComponent } from '@/components/DialogComponent';
import { usePermissionsUFManager } from '../../hooks/usePermissionsUFManager';
import { CreateGroupUf } from '../CreateGroupUF';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const ListUfs = ({ controlStage }) => {
  const { t } = useTranslation();
  const { permissionsCreate } = usePermissionsUFManager();
  const { isLoadingDataClients } = useClients();
  const {
    loadingDataUFs,
    mutateCreateGroupUF,
    loadingCreateGroupUF,
    loadingDeleteGroupUF,
    mutateDeleteGroupUF,
    mutateGetDataUF,
    loadingGetDataUF,
  } = useUFManager();

  const [listUFManager] = useAtom(dataUFManager);
  const [filterGroup, setFilterGroup] = useState('0');

  const [openCreateGroup, setOpenGroup] = useState(false);

  const controlStageCreate = {
    handleCreate: () => setOpenGroup(true),
    handleClose: () => setOpenGroup(false),
  };

  const filterUFManager = listUFManager.filter(
    (data) => data.cod_uf_bloq === filterGroup
  );

  const filteredData =
    filterUFManager.length === 0 || filterGroup === '0'
      ? listUFManager
      : filterUFManager;

  const renderText = (spell) => {
    return t(`dialControl.ufManager.list.${spell}`);
  };

  const isLoading = loadingDataUFs || isLoadingDataClients || loadingGetDataUF;

  return (
    <ContainerListUf initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <Stack direction={'column'} spacing={2} justifyContent={'space-between'}>
        <Box>
          <SelectComponent
            labelSelect={renderText('label-filter-uf')}
            value={filterGroup}
            onChange={(e) => setFilterGroup(e.target.value)}
          >
            <ItemSelect value={'0'}>
              {renderText('option-filter-uf')}
            </ItemSelect>
            {listUFManager.length > 0 &&
              listUFManager.map((item, i) => (
                <ItemSelect value={item.cod_uf_bloq} key={i}>
                  {item.cod_uf_bloq} - {item.descricao}
                </ItemSelect>
              ))}
          </SelectComponent>
        </Box>

        <Stack
          direction={'row'}
          spacing={1}
          justifyContent={'flex-end'}
          height={'100%'}
        >
          <Button
            typeText
            onClick={controlStageCreate.handleCreate}
            title={renderText('btn-add-uf')}
            disabled={!permissionsCreate}
            sx={{ padding: '10px' }}
          >
            {renderText('btn-add-uf')}
          </Button>
        </Stack>
      </Stack>
      <TableListUfs
        dataTable={filteredData}
        controlStage={controlStage}
        mutateGetDataUF={mutateGetDataUF}
        loadingDeleteGroupUF={loadingDeleteGroupUF}
        mutateDeleteGroupUF={mutateDeleteGroupUF}
      />

      <DialogComponent
        open={openCreateGroup}
        onClose={controlStageCreate.handleClose}
        fullWidth={true}
      >
        <CreateGroupUf
          controlStage={controlStageCreate}
          dataReqCreate={{
            mutateCreateGroupUF,
            loadingCreateGroupUF,
          }}
        />
      </DialogComponent>
    </ContainerListUf>
  );
};
