import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerDashboard = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: relative;
  max-width: 3840px;
  max-height: 2160px;
  margin: 0 auto;
`;

export const ContentDashboard = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  .box-verify-click {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
  }
`;
