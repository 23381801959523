import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ContainerInclude } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { MdOutlineTextRotationNone } from 'react-icons/md';

export const IncludeFunction = ({
  errorFunc,
  setErrorFunc,
  idFilterRender,
  itemPreview,
  listFilter,
  setListFilter,
}) => {
  const { t } = useTranslation();

  const [resultText, setResultText] = useState('');
  const [displayedText, setDisplayedText] = useState('');

  const [typeCase, setTypeCase] = useState('');

  const [totalLength, setTotalLength] = useState(itemPreview.length);
  const [characterInclude, setCharacterInclude] = useState('#');
  const [directionInclude, setDirectionInclude] = useState('left');

  const handleChangeDirection = () => {
    if (directionInclude === 'left') {
      return setDirectionInclude('right');
    }
    return setDirectionInclude('left');
  };

  const filterDataRender = listFilter.filter(
    (list) => list.id === idFilterRender
  );

  function padWithChars(str, length, charReplace, direction = 'right') {
    str = String(str);

    while (str.length < length) {
      if (direction === 'left') {
        str = charReplace + str;
      } else if (direction === 'right') {
        str = str + charReplace;
      } else {
        throw new Error();
      }
    }
    return str;
  }

  useEffect(() => {
    if (filterDataRender.length > 0) {
      if (filterDataRender[0].sequence === 1) {
        return setResultText(itemPreview);
      }
      const currentSequence = filterDataRender[0].sequence;
      const sequenceList = listFilter
        .map((list) => list.sequence)
        .filter((seq) => seq !== currentSequence)
        .sort((a, b) => a - b);
      const previousClosest = sequenceList
        .filter((seq) => seq < currentSequence)
        .reduce((prev, curr) => (curr > prev ? curr : prev), -Infinity);
      const getPreviousValue = listFilter.filter(
        (list) => list.sequence === previousClosest
      );
      if (getPreviousValue.length > 0) {
        setResultText(getPreviousValue[0].result);
      }
    }
  }, [filterDataRender]);

  useEffect(() => {
    if (filterDataRender.length > 0) {
      setTotalLength(filterDataRender[0].data[1] ?? itemPreview.length);
      setCharacterInclude(filterDataRender[0].data[2] ?? 0);
      setDirectionInclude(filterDataRender[0].data[3] ?? 'right');
      setTypeCase(filterDataRender[0].data[4] ?? '');
    }
  }, []);

  useEffect(() => {
    if (displayedText === '') {
      if (!errorFunc.includes(idFilterRender)) {
        setErrorFunc((previous) => [...previous, idFilterRender]);
      }
    } else {
      if (errorFunc.includes(idFilterRender)) {
        setErrorFunc((previous) =>
          previous.filter((error) => error !== idFilterRender)
        );
      }
    }
  }, [displayedText, errorFunc, idFilterRender]);

  const rendeCaseSensitive = (typeCase, dataChangeCase) => {
    switch (typeCase) {
      case 'uppercase':
        return dataChangeCase.toUpperCase();

      case 'lowercase':
        return dataChangeCase.toLowerCase();

      case 'capitalize':
        return dataChangeCase
          .split(' ')
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(' ');

      default:
        return dataChangeCase;
    }
  };

  const changeCaseSensitive = (typeCaseSelected) => {
    if (typeCaseSelected === typeCase) {
      return setTypeCase('');
    }
    setTypeCase(typeCaseSelected);
  };

  useEffect(() => {
    const renderString = padWithChars(
      resultText,
      totalLength,
      characterInclude,
      directionInclude
    );
    setDisplayedText(rendeCaseSensitive(typeCase, renderString));

    const updatedList = listFilter.map((list) => {
      if (list.id === idFilterRender) {
        return {
          ...list,
          data: [
            'include',
            totalLength,
            characterInclude,
            directionInclude,
            typeCase,
          ],
          result: rendeCaseSensitive(typeCase, renderString),
        };
      }
      return list;
    });
    setListFilter(updatedList);
  }, [
    resultText,
    totalLength,
    characterInclude,
    typeCase,
    displayedText,
    directionInclude,
  ]);

  const hasError = errorFunc.includes(idFilterRender) ? 1 : 0;

  return (
    <ContainerInclude errorString={hasError}>
      <div className='case-content'>
        <motion.button
          whileTap={{ scale: 0.9 }}
          className={typeCase === 'uppercase' ? 'active-case' : ''}
          onClick={() => changeCaseSensitive('uppercase')}
          title='Uppercase'
        >
          <span>AA</span>
        </motion.button>
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={() => changeCaseSensitive('capitalize')}
          className={typeCase === 'capitalize' ? 'active-case' : ''}
          title='Capitalize'
        >
          <span>Aa</span>
        </motion.button>
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={() => changeCaseSensitive('lowercase')}
          className={typeCase === 'lowercase' ? 'active-case' : ''}
          title='LowerCase'
        >
          <span>aa</span>
        </motion.button>
      </div>
      <div className='input-content'>
        <span>{t('listLoader.importMailing.flow.label-input')}:</span>
        <span>
          <strong style={{ whiteSpace: 'pre' }}>{resultText}</strong>
        </span>
      </div>
      <Stack direction={'row'} spacing={2}>
        <Button onClick={handleChangeDirection} style={{ height: '40px' }}>
          {directionInclude === 'left' ? (
            <MdOutlineTextRotationNone
              size={22}
              style={{ transform: 'scaleX(-1)' }}
            />
          ) : (
            <MdOutlineTextRotationNone size={22} />
          )}
        </Button>
        <InputComponent
          label={t('listLoader.importMailing.flow.label-total-length')}
          type='number'
          value={totalLength}
          onChange={(e) => setTotalLength(Number(e.target.value))}
          autoComplete='off'
          InputProps={{
            inputProps: {
              min: resultText.length,
            },
          }}
        />

        <InputComponent
          label={t('listLoader.importMailing.flow.label-include-character')}
          value={characterInclude}
          onChange={(e) => {
            const newValue = e.target.value;
            if (newValue !== ';') {
              setCharacterInclude(newValue);
            }
          }}
          autoComplete='off'
          inputProps={{ maxLength: 1 }}
        />
      </Stack>

      <div className='result-content'>
        <span>{t('listLoader.importMailing.flow.label-output')}:</span>
        <span>
          <strong style={{ whiteSpace: 'pre' }}>{displayedText}</strong>
        </span>
      </div>
    </ContainerInclude>
  );
};
