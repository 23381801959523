import { useEffect, useState } from 'react';
import { TabMonitor } from './TabMonitor';
import { AgentsMonitor } from './AgentsMonitor';
import { ContainerMonitor } from './styled';
import { useMonitorLines } from '@/hooks/monitor/useMonitorLines';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

export const Monitor = () => {
  const { dataMonitorLines, loadingDataMonitor } = useMonitorLines();

  const [ordinationType, setOrdinationType] = useState('layoutBox');
  const [filterQueue, setFilterQueue] = useState('0');
  const [filterStatus, setFilterStatus] = useState('0');

  useEffect(() => {
    setFilterStatus('0');
  }, [filterQueue]);

  const filterData = (dataList) => {
    if (dataList) {
      const filterList = dataList.filter(
        (infoQueue) => infoQueue.fila === filterQueue
      );

      if (filterList.length > 0) {
        return filterList;
      }

      return dataList;
    }
  };

  const filterStatusData = (dataList) => {
    if (dataList) {
      const filterByStatus = dataList.filter((infoQueue) => {
        switch (filterStatus) {
          case '0':
            return infoQueue;
          case '1':
            return infoQueue.estado === 'LIVRE';
          case '2':
            return infoQueue.estado === 'DISCANDO';
          case '3':
            return infoQueue.estado === 'EMLIG';
          case '4':
            return infoQueue.estado === 'EMPAUSA';
          default:
            return infoQueue;
        }
      });
      return filterByStatus;
    }
  };

  const dataFiltered = filterStatusData(filterData(dataMonitorLines));

  return (
    <ContainerMonitor>
      {loadingDataMonitor && <BackDropCircularLoading />}
      <TabMonitor
        ordination={{ ordinationType, setOrdinationType }}
        dataList={dataMonitorLines}
        queueFilter={{ filterQueue, setFilterQueue }}
        statusFilter={{ filterStatus, setFilterStatus }}
      />
      <AgentsMonitor
        dataList={dataFiltered}
        ordination={{
          ordinationType,
          setOrdinationType,
        }}
      />
    </ContainerMonitor>
  );
};
