function caseSensitivePreview(typeCase, dataTransform) {
  switch (typeCase) {
    case 'uppercase':
      return dataTransform.toUpperCase().trim();
    case 'lowercase':
      return dataTransform.toLowerCase().trim();
    case 'capitalize':
      return dataTransform
        .split(' ')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ')
        .trim();
    default:
      return dataTransform.trim();
  }
}

export function subStringPreview(
  resultPreview,
  initIndex = 0,
  endIndex = 0,
  typeCase = ''
) {
  const processFunc = resultPreview.substring(initIndex, endIndex);
  return caseSensitivePreview(typeCase, processFunc);
}

export function replacePreview(
  resultPreview = '',
  charReplace = '',
  newCharReplace = '',
  typeCase = ''
) {
  const processFunc = resultPreview.replaceAll(charReplace, newCharReplace);
  return caseSensitivePreview(typeCase, processFunc);
}

export function includePreview(
  str,
  length,
  charReplace,
  direction = 'right',
  typeCase = ''
) {
  str = String(str);
  while (str.length < length) {
    if (direction === 'left') {
      str = charReplace + str;
    } else if (direction === 'right') {
      str = str + charReplace;
    } else {
      throw new Error('Direção inválida. Use "left" ou "right".');
    }
  }
  return caseSensitivePreview(typeCase, str);
}

export function splitPreview(strSplit, index, resultText, typeCase = '') {
  if (typeof resultText !== 'string' || !strSplit) {
    return '';
  }
  const splitValue = resultText.split(strSplit);

  if (index < 0 || index >= splitValue.length) {
    return '';
  }
  return caseSensitivePreview(typeCase, String(splitValue[index] || ''));
}
