import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const ContainerDataQueue = styled(motion.div)`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 1.2rem;

  @media (max-width: 635px) {
    min-height: auto;
  }
`;

export const PanelDetailQueue = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 1px 4px 10px -1px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 635px) {
    grid-template-columns: 1fr;
  }

  .svgBackstage {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }

  .itemPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;

    span {
      text-transform: uppercase;
      padding-top: 1px;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }

  .detail_queue {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.4em;

    @media (max-width: 900px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    span {
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.baseText};
      opacity: 0.6;
    }
  }

  .indicators_panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-left: 3rem;
  }
`;

export const ContainerStatusQueue = styled.div`
  margin-left: -5px;

  .whitBorder {
    ${(props) => {
      if (props.currentState === 'abrir') {
        return css`
          border: 1px solid ${(props) => props.theme.colors.success};
          color: ${(props) => props.theme.colors.success};
        `;
      } else if (props.currentState === 'fechar') {
        return css`
          border: 1px solid ${(props) => props.theme.colors.secondary};
          color: ${(props) => props.theme.colors.secondary};
        `;
      } else {
        return css`
          border: 1px solid ${(props) => props.theme.colors.primary};
          color: ${(props) => props.theme.colors.primary};
        `;
      }
    }}
  }

  span {
    box-shadow: none !important;
    border: none;
    color: ${(props) => props.theme.colors.typography.baseText};

    padding: 5px;
    font-size: 0.8rem;
    border-radius: 4px;
  }
`;

export const TableDetailQueue = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  overflow-x: scroll;
`;

export const ContainerStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 635px) {
    justify-content: flex-start;
  }
`;
