import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormNewListConfig = styled(motion.form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
`;

export const ContainerTransferList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FooterNewListConfig = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;
