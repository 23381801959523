import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { RiDraggable } from 'react-icons/ri';
import { ContainerItemConditional } from './styled';

export const Item = ({
  typeCondition,
  valueCondition,
  handleRemoveCondition,
}) => {
  const { t } = useTranslation();

  return (
    <ContainerItemConditional>
      <div className='description'>
        <RiDraggable />
        <span>
          {typeCondition} {valueCondition}
        </span>
      </div>
      <div className='panel-item'>
        <button
          onClick={handleRemoveCondition}
          type='button'
          title={t('flowBuilder.flow.nodes.conditions.menu.title-btn-remove')}
        >
          <IoIosCloseCircleOutline size={'17px'} />
        </button>
      </div>
    </ContainerItemConditional>
  );
};
