import { useTranslation } from 'react-i18next';

export const useRenderNamesRoutingProfile = () => {
  const { t } = useTranslation();

  const renderNameItem = (itemName) => {
    const removeSpaces = itemName.replace(/ /g, '');
    const renderName = t(`dashboard.${removeSpaces}`);
    if (renderName === `dashboard.${removeSpaces}`) {
      return <span>{itemName}</span>;
    }
    if (renderName === `dashboard.${itemName}`) {
      return <span>{itemName}</span>;
    }
    return <span>{renderName}</span>;
  };

  return { renderNameItem };
};
