import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSearch } from 'react-icons/bi';
import { TbServerCog } from 'react-icons/tb';
import { Alert, InputAdornment, Stack } from '@mui/material';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { ContainerHomeTenants } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { TableTenants } from './TableTenants';
import { useTenants } from '@/hooks/Tenants/useTenants';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useClients } from '@/hooks/Clients/useClients';
import { useAtom } from 'jotai';
import { dataTenants } from '@/store/TenantsStore';
import { NoDataTenants } from '../../components/NoDataTenants';
import { removeDuplicates } from '@/utils/removeDuplicateItemsArr';
import { usePermissionsTenants } from '../../hooks/usePermissionsTenants';
import { Box } from '@mui/system';

export const HomeTenants = ({ changeStage }) => {
  const { t } = useTranslation();
  const { isLoadingDataClients } = useClients();
  const { permissionsCreate, isSysAdmin } = usePermissionsTenants();

  const renderText = (spell) => {
    return t(`tenants.home.${spell}`);
  };
  const [listTenants] = useAtom(dataTenants);

  const { loadingTenants, loadingGetMatchTenant, mutateGetMatchTenant } =
    useTenants();

  const [searchTenant, setSearchTenant] = useState('');

  const filterTenants = useCallback(() => {
    if (!listTenants) {
      return [];
    }
    const filterNameTenant = listTenants.filter((data) =>
      data.name.toLowerCase().includes(searchTenant.toLowerCase())
    );
    const filterHostTenant = listTenants.filter((data) =>
      data.host.toLowerCase().includes(searchTenant.toLowerCase())
    );
    const dataFilter = [...filterNameTenant, ...filterHostTenant];
    return removeDuplicates(dataFilter);
  }, [listTenants, searchTenant]);

  const isLoading =
    loadingTenants || isLoadingDataClients || loadingGetMatchTenant;

  return (
    <ContainerHomeTenants initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <TitleComponent textTitle={renderText('title')} iconTitle='tenants' />
      {isSysAdmin && (
        <InputComponent
          placeholder={renderText('placeholder-filterTenants')}
          value={searchTenant}
          onChange={(e) => setSearchTenant(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <BiSearch />
              </InputAdornment>
            ),
          }}
        />
      )}
      <div className='content_btn_new_tenant'>
        {isSysAdmin && (
          <Button onClick={() => changeStage(2)} disabled={!permissionsCreate}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <TbServerCog size={16} />
              <span>{renderText('btn-addTenants')}</span>
            </Stack>
          </Button>
        )}
      </div>

      {!isSysAdmin && (
        <Box width={'100%'}>
          <Alert severity='error'>{renderText('msg-noPermission')}</Alert>
        </Box>
      )}

      {filterTenants().length > 0 ? (
        <TableTenants
          changeStage={changeStage}
          dataTable={filterTenants()}
          mutateGetMatchTenant={mutateGetMatchTenant}
        />
      ) : (
        <NoDataTenants
          handleStage={() => changeStage(2)}
          typeData={'tenants'}
        />
      )}
    </ContainerHomeTenants>
  );
};
