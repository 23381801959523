import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { errorToast } from '@/components/FormComponents/Toast';
import { dataNlus } from '@/store/FlowBuilder';
import { getNlus } from '@/services/flowBuilder/nlus/getNlus';
import { createNlus } from '@/services/flowBuilder/nlus/createNlus';

export const useEndpoints = () => {
  const { t } = useTranslation();
  const [, setNlus] = useAtom(dataNlus);

  const {
    isLoading: loadingNlus,
    isFetching: isFetchingNlus,
    refetch: reloadNlus,
  } = useQuery(
    'nlusFlow',
    async () => {
      return await getNlus();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => setNlus(data),
      onError: () =>
        errorToast(t('flowBuilder.configs.endpoints.msg-error-getEndpoint')),
    }
  );

  const { mutate: mutateCreateEndpoint, isLoading: loadingCreateEndpoint } =
    useMutation({
      mutationFn: async (dataCreateEndpoint) => {
        return await createNlus(dataCreateEndpoint);
      },

      onSuccess: () => {
        reloadNlus();
      },
      onError: () => {
        console.log(t('flowBuilder.configs.endpoints.msg-error-addedEndpoint'));
      },
    });

  return {
    loadingNlus,
    isFetchingNlus,
    mutateCreateEndpoint,
    loadingCreateEndpoint,
  };
};
