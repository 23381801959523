export const checkLabelExistiNode = (idNode, labelNode, listNodes) => {
  const checkLabel = listNodes.filter((item) => {
    return (
      item.data.label?.toLowerCase() === labelNode?.toLowerCase() &&
      item.id !== idNode
    );
  });
  if (checkLabel.length > 0) {
    return true;
  }
  return false;
};
