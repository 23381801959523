import { useQuery } from 'react-query';
import { useLocalStorage } from '@hooks/useLocalStorage';

export const ProtectedLayout = ({ children }) => {
  const { getLocalStorage } = useLocalStorage();

  const { data, isLoading, error } = useQuery(
    'userSession',
    () => {
      return getLocalStorage('@s');
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60,
    }
  );

  if (!isLoading) {
    return !error && data ? children : (window.location.href = '/');
  }
};

export const ProtectedComponent = ({ children, canDisplay }) => {
  if (canDisplay) {
    return <>{children}</>;
  } else {
    return null;
  }
};
