import useWindowDimensions from '@/hooks/useWidth';
import { useLayoutEffect, useRef, useState } from 'react';
import { ContainerTableSummary } from './styled';
import { useTranslation } from 'react-i18next';
import { TablePagination } from '@/components/Tables/TablePagination';
import { Stack } from '@mui/material';
import { TiMicrophone } from 'react-icons/ti';
import { IoChatbubbleEllipses } from 'react-icons/io5';

export const TableSummary = ({ dataTable }) => {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const tableSummaryMonitor = useRef(null);

  const ColumnsHeaderSummaryMonitor = [
    {
      Header: t('summaryMonitor.table.th-first'),
      accessor: 'queue',
    },
    {
      Header: t('summaryMonitor.table.th-second'),
      accessor: 'dialing',
      Cell: ({ value }) => {
        return (
          <Stack direction='row' alignItems={'center'} spacing={2}>
            <TiMicrophone />
            {value}
          </Stack>
        );
      },
    },
    {
      Header: t('summaryMonitor.table.th-third'),
      accessor: 'speaking_ura',
      Cell: ({ value }) => {
        return (
          <Stack direction='row' alignItems={'center'} spacing={1}>
            <IoChatbubbleEllipses />
            {value}
          </Stack>
        );
      },
    },
    {
      Header: t('summaryMonitor.table.th-fourth'),
      accessor: 'campaignDescription',
    },
    {
      Header: t('summaryMonitor.table.th-fifth'),
      accessor: 'list',
    },
    {
      Header: t('summaryMonitor.table.th-sixth'),
      accessor: 'operator',
    },
  ];

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableSummaryMonitor) {
      const tableMaxWidth = tableSummaryMonitor.current.offsetHeight;
      const cellMinWidth = 60;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, tableSummaryMonitor]);

  return (
    <ContainerTableSummary ref={tableSummaryMonitor}>
      <TablePagination
        dataColumns={ColumnsHeaderSummaryMonitor}
        dataTable={dataTable}
        textNoData={'No Data'}
        rowsPerPageDisplay={heightTable}
      />
    </ContainerTableSummary>
  );
};
