import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { motion } from 'framer-motion';
import { MdEdit } from 'react-icons/md';

import { dataSelectedTenants } from '@/store/TenantsStore';
import useWindowDimensions from '@/hooks/useWidth';
import { TablePagination } from '@/components/Tables/TablePagination';
import { ContainerTable } from './styled';
import { renderDate } from '@/utils/formatsDateAndTime';
import { usePermissionsTenants } from '../../../hooks/usePermissionsTenants';
import { PiTrashFill } from 'react-icons/pi';
import { DialogComponent } from '@/components/DialogComponent';
import { RemoveTenant } from './RemoveTenant';

export const TableTenants = ({
  changeStage,
  dataTable,
  mutateGetMatchTenant,
}) => {
  const { t } = useTranslation();
  const { permissionsEdit } = usePermissionsTenants();

  const renderText = (spell) => {
    return t(`tenants.home.table.${spell}`);
  };

  const [, setTenantSelected] = useAtom(dataSelectedTenants);
  const [dialogDeleteTenant, setDialogDeleteTenant] = useState(false);

  const tableTenantsRef = useRef(null);
  const { height, width } = useWindowDimensions();
  const [heightTable, setHeightTable] = useState(0);

  const dataTenants = useMemo(() => dataTable, [dataTable]);

  useLayoutEffect(() => {
    if (tableTenantsRef) {
      const tableMaxWidth = tableTenantsRef.current.offsetHeight;
      const cellMinWidth = width < 500 ? 200 : 54;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, width]);

  const handleEditItem = (dataSelected) => {
    setTenantSelected(dataSelected);
    changeStage(3);
  };

  const handleToAggregateTenants = (dataTenants) => {
    mutateGetMatchTenant(dataTenants, {
      onSuccess: () => {
        setTenantSelected(dataTenants);
        changeStage(1);
      },
    });
  };

  const handleDeleteTenant = (dataTenant) => {
    setTenantSelected(dataTenant);
    setDialogDeleteTenant(true);
  };

  const closeDeleteTenant = () => {
    setTenantSelected(null);
    setDialogDeleteTenant(false);
  };

  const columnsTenant = [
    {
      Header: renderText('th-name'),
      accessor: 'name',
      Cell: ({ row }) => {
        return (
          <div className='btn-to-aggregate-tenants '>
            <motion.button
              whileTap={{ scale: 0.98 }}
              onClick={() => handleToAggregateTenants(row.original)}
            >
              {row.original.name}
            </motion.button>
          </div>
        );
      },
    },
    {
      Header: renderText('th-host'),
      accessor: 'host',
    },
    {
      Header: renderText('th-createdAt'),
      accessor: 'created_at',
      Cell: ({ row }) => renderDate(row.original.created_at),
    },
    {
      Header: renderText('th-updatedAt'),
      accessor: 'updated_at',
      Cell: ({ row }) => renderDate(row.original.updated_at),
    },
    {
      Header: renderText('th-action'),
      Cell: ({ row }) => (
        <div className='editRow'>
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => handleDeleteTenant(row.original)}
            // title={renderText('btn-title-edit')}
            // disabled={!permissionsEdit}
          >
            <PiTrashFill />
          </motion.button>
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => handleEditItem(row.original)}
            title={renderText('btn-title-edit')}
            disabled={!permissionsEdit}
          >
            <MdEdit />
          </motion.button>
        </div>
      ),
    },
  ];

  return (
    <ContainerTable ref={tableTenantsRef}>
      <TablePagination
        dataColumns={columnsTenant}
        dataTable={dataTenants}
        textNoData={renderText('no-data')}
        rowsPerPageDisplay={heightTable}
      />
      <DialogComponent
        open={dialogDeleteTenant}
        onClose={closeDeleteTenant}
        fullWidth={true}
      >
        <RemoveTenant closeDialog={closeDeleteTenant} />
      </DialogComponent>
    </ContainerTable>
  );
};
