import { Stack } from '@mui/system';
import { ContainerDetailsReports, ContainerItem, ContentItem } from './styled';
import AnimatedNumber from 'animated-number-react';
import { FaRegCircleDot } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export const DetailsCountReports = ({
  dataCountReport,
  filterByType,
  setFilterByType,
}) => {
  const { t } = useTranslation();
  const formatValue = (value) => (value ? value.toFixed(0) : 0);

  const handleFilterByType = (type) => {
    setFilterByType(type);
  };

  const filterServedContact = dataCountReport.filter((item) => item.atendida);

  const filterEffectiveContact = dataCountReport.filter(
    (item) => item.contato_efetivo
  );

  const filterPositiveContact = dataCountReport.filter(
    (item) => item.contato_positivo
  );

  const mountNumbers = useCallback((dataList, propCount) => {
    if (dataList) {
      const totalSum = dataList.reduce((accumulator, currentValue) => {
        return Number(accumulator) + Number(currentValue[propCount]);
      }, 0);
      return totalSum;
    }
    return 0;
  }, []);

  return (
    <ContainerDetailsReports>
      <ContainerItem
        whileTap={{ scale: 0.99 }}
        onClick={() => handleFilterByType('total')}
        typeFilter={filterByType === 'total' ? 1 : 0}
      >
        <div className='marker-item'></div>
        <ContentItem>
          <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
            <FaRegCircleDot size={10} />
            <span>{t('reports.countInList.countReports.btn-card-total')}</span>
          </Stack>
          <AnimatedNumber
            value={mountNumbers(dataCountReport, 'qtd')}
            formatValue={formatValue}
            duration={400}
          />
        </ContentItem>
      </ContainerItem>
      <ContainerItem
        whileTap={{ scale: 0.99 }}
        onClick={() => handleFilterByType('answered')}
        typeFilter={filterByType === 'answered' ? 1 : 0}
      >
        <div className='marker-item'></div>
        <ContentItem colorItem={'blue'}>
          <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
            <FaRegCircleDot size={10} />
            <span>
              {t('reports.countInList.countReports.btn-card-attempts')}
            </span>
          </Stack>

          <AnimatedNumber
            value={mountNumbers(filterServedContact, 'qtd')}
            formatValue={formatValue}
            duration={400}
          />
        </ContentItem>
      </ContainerItem>
      <ContainerItem
        colorItem={'orange'}
        whileTap={{ scale: 0.99 }}
        onClick={() => handleFilterByType('effective')}
        typeFilter={filterByType === 'effective' ? 1 : 0}
      >
        <div className='marker-item'></div>
        <ContentItem>
          <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
            <FaRegCircleDot size={10} />
            <span>
              {t('reports.countInList.countReports.btn-card-effective')}
            </span>
          </Stack>

          <AnimatedNumber
            value={mountNumbers(filterEffectiveContact, 'qtd')}
            formatValue={formatValue}
            duration={300}
          />
        </ContentItem>
      </ContainerItem>
      <ContainerItem
        colorItem={'green'}
        whileTap={{ scale: 0.99 }}
        onClick={() => handleFilterByType('positive')}
        typeFilter={filterByType === 'positive' ? 1 : 0}
      >
        <div className='marker-item'></div>
        <ContentItem>
          <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
            <FaRegCircleDot size={10} />
            <span>
              {t('reports.countInList.countReports.btn-card-positive')}
            </span>
          </Stack>

          <AnimatedNumber
            value={mountNumbers(filterPositiveContact, 'qtd')}
            formatValue={formatValue}
            duration={400}
          />
        </ContentItem>
      </ContainerItem>
    </ContainerDetailsReports>
  );
};
