import { TablePagination } from '@/components/Tables/TablePagination';
import { ElementStatus, TableContainer } from './styled';

import { useTranslation } from 'react-i18next';
import useWindowDimensions from '@/hooks/useWidth';
import { useLayoutEffect, useRef, useState } from 'react';
import { AiFillMessage } from 'react-icons/ai';

export const AgentsList = ({ data }) => {
  const { t } = useTranslation();

  const { height } = useWindowDimensions();
  const tableMonitorRef = useRef(null);

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableMonitorRef) {
      const tableMaxWidth = tableMonitorRef.current.offsetHeight;
      const cellMinWidth = 50;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height]);

  const generateStatusQueueText = (status) => {
    switch (status) {
      case 'EMLIG':
        return t('monitor.option-inCall');
      case 'DISCANDO':
        return t('monitor.option-discing');
      case 'LIVRE':
        return t('monitor.option-free');
      case 'EMPAUSA':
        return t('monitor.option-inPause');
      default:
        return '';
    }
  };

  const generateStatusQueueColor = (status) => {
    switch (status) {
      case 'EMLIG':
        return '#4c48ff';
      case 'DISCANDO':
        return '#0edd56';
      case 'LIVRE':
        return '#FF4C43';
      case 'EMPAUSA':
        return '#FFb839';
      default:
        return '';
    }
  };

  const columnsAgents = [
    {
      Header: t('monitor.table.th-first'),
      accessor: 'estado',
      Cell: ({ value }) => {
        return (
          <ElementStatus>
            <AiFillMessage color={generateStatusQueueColor(value)} />
            {generateStatusQueueText(value)}
          </ElementStatus>
        );
      },
    },
    {
      Header: t('monitor.table.th-second'),
      accessor: 'agente',
    },
    {
      Header: t('monitor.table.th-third'),
      accessor: 'linha',
    },
    {
      Header: t('monitor.table.th-fourth'),
      accessor: 'fila',
    },
    {
      Header: t('monitor.table.th-fifth'),
      accessor: 'lista',
    },
    {
      Header: t('monitor.table.th-sixth'),
      accessor: 'ramal',
    },
    {
      Header: t('monitor.table.th-seventh'),
      accessor: 'horaInicial',
    },
    {
      Header: t('monitor.table.th-eighth'),
      accessor: 'fone',
    },
    {
      Header: t('monitor.table.th-ninth'),
      accessor: 'lig_tipo',
    },
  ];

  return (
    <TableContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      ref={tableMonitorRef}
    >
      <TablePagination
        dataColumns={columnsAgents}
        dataTable={data}
        textNoData={t('monitor.noDataMonitor')}
        rowsPerPageDisplay={heightTable}
      />
    </TableContainer>
  );
};
