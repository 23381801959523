import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import {
  ContainerChartPerHour,
  ContentChartServicePerHour,
  HeaderChartServicePerHour,
} from './styled';
import { Stack } from '@mui/system';
import { formatDate } from '@/utils/formatsDateAndTime';
import { parse } from 'date-fns';

const renderConfigChart = (themeSelected) => ({
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    foreColor: themeSelected.colors.typography.baseText,
  },
  grid: {
    show: true,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: true,
  },
  xaxis: {
    type: 'category',
    axisBorder: {
      color: themeSelected.colors.typography.baseText,
    },
    axisTicks: {
      color: themeSelected.colors.typography.baseText,
    },
    categories: [
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
    ],
  },
  colors: ['#43ff86', '#4c48ff'],
  fill: {
    opacity: 0.3,
    type: 'gradient',
    gradient: {
      shade: 'dark',
      opacityFrom: 0.7,
      opacityTo: 0.3,
    },
  },
});

export const ChartPerHour = ({ dataChartPerHour, selectedQueue }) => {
  const renderDataToday = (() => {
    const { today } = dataChartPerHour;
    if (today) {
      const dataListToday = today.map((item) => {
        return {
          hour: Number(item.hora),
          value: Number(item.total),
        };
      });
      return dataListToday;
    }
    return [];
  })();

  const renderDataYesterday = (() => {
    const { yesterday } = dataChartPerHour;
    if (yesterday) {
      const dataListYesterday = yesterday.map((item) => {
        return {
          hour: Number(item.hora),
          value: Number(item.total),
        };
      });
      return dataListYesterday;
    }
    return [];
  })();

  const { t } = useTranslation();
  const theme = useTheme();

  const matches = useMediaQuery('(max-width:1550px)');
  const [heightChart, setHeightChart] = useState(240);

  const [dataToday, setDataToday] = useState([]);
  const [dataYesterday, setDataYesterday] = useState([]);

  const [chartState, setChartState] = useState({
    options: renderConfigChart(theme),
  });

  useEffect(() => {
    setChartState({
      options: renderConfigChart(theme),
    });
  }, [theme]);

  useEffect(() => {
    if (matches) {
      return setHeightChart(220);
    }
    setHeightChart(290);
  }, [matches]);

  const getDataChartTime = (stateTimes, setStateTimes) => {
    const listTime = Array(15).fill(0);

    const hourToIndex = {
      '07:00': 0,
      '08:00': 1,
      '09:00': 2,
      '10:00': 3,
      '11:00': 4,
      '12:00': 5,
      '13:00': 6,
      '14:00': 7,
      '15:00': 8,
      '16:00': 9,
      '17:00': 10,
      '18:00': 11,
      '19:00': 12,
      '20:00': 13,
      '21:00': 14,
    };

    // Itera sobre o array stateTimes
    stateTimes.forEach((item) => {
      // Verifica se item é válido e se a hora formatada está no mapa hourToIndex
      if (item) {
        const formattedHour = `${item.hour.toString().padStart(2, '0')}:00`;
        if (hourToIndex[formattedHour] !== undefined) {
          // Atualiza o valor correspondente no array listTime
          listTime[hourToIndex[formattedHour]] = item.value;
        }
      }
    });
    // Define o novo estado para setStateTimes com o array atualizado
    setStateTimes(listTime);
  };

  useEffect(() => {
    if (dataChartPerHour.length !== 0) {
      getDataChartTime(renderDataToday, setDataToday);
      getDataChartTime(renderDataYesterday, setDataYesterday);
    }
  }, [dataChartPerHour]);

  const series = [
    {
      name: t('dashboardPage.title-chartYesterday'),
      data: dataYesterday,
    },
    {
      name: t('dashboardPage.title-chartToday'),
      data: dataToday,
    },
  ];

  const renderSelectedQueue = selectedQueue.map((item, i) => {
    const totalLength = selectedQueue.length - 1;
    if (totalLength === i) {
      return <span key={i}>{item.queue}</span>;
    }
    return <span key={i}>{item.queue},</span>;
  });

  const renderDataSelected = () => {
    if (selectedQueue.length > 0) {
      const firstDate = parse(
        selectedQueue[0].first_date,
        'yyyy-MM-dd',
        new Date()
      );

      const endDate = parse(
        selectedQueue[0].last_date,
        'yyyy-MM-dd',
        new Date()
      );

      if (formatDate(firstDate) === formatDate(endDate)) {
        return formatDate(firstDate);
      }

      return `${formatDate(firstDate)} - ${formatDate(endDate)}`;
    }

    const currentDate = new Date();
    return formatDate(currentDate);
  };

  return (
    <ContainerChartPerHour>
      <HeaderChartServicePerHour>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <h2>{t('dashboardPage.title-chartPerHour')}</h2>
          <span>{renderDataSelected()}</span>
        </Stack>

        <Stack direction={'row'} spacing={'4px'}>
          {renderSelectedQueue}
        </Stack>
      </HeaderChartServicePerHour>
      <ContentChartServicePerHour>
        <Chart
          options={chartState.options}
          series={series}
          type='area'
          width={'100%'}
          height={heightChart}
        />
      </ContentChartServicePerHour>
    </ContainerChartPerHour>
  );
};
