import { useTranslation } from 'react-i18next';
import { BoxAgentContainer } from './styles';
import { useEffect, useState } from 'react';

export const BoxAgent = ({ infoAgent }) => {
  const { t } = useTranslation();
  const [statusCard, setStatusCard] = useState(0);
  const [statusRender, setStatusRender] = useState('');

  useEffect(() => {
    switch (infoAgent.estado) {
      case 'LIVRE':
        setStatusRender(t('monitorVirtualAgents.agentsOnline'));
        setStatusCard(1);
        break;
      case 'DISCANDO':
        setStatusRender(t('monitorVirtualAgents.queueDialing'));
        setStatusCard(2);
        break;
      case 'EMPAUSA':
        setStatusRender(t('monitorVirtualAgents.queuePaused'));
        setStatusCard(3);
        break;
      case 'EMLIG':
        setStatusRender('Em Ligação');
        setStatusCard(4);
        break;
      default:
        setStatusCard(0);
        break;
    }
  }, [statusCard, infoAgent]);

  return (
    <BoxAgentContainer statusCard={statusCard}>
      <div className='name_user'>
        <span>{infoAgent.nome}</span>
      </div>
      <div className='info_user'>
        <div className='box_status'>
          <span>{t('monitorVirtualAgents.title-boxAgentStatus')}: </span>
          <span className='status_call'>{statusRender}</span>
        </div>
        <div className='box_time'>
          <span>{t('monitorVirtualAgents.title-boxAgentInit')}</span>
          <span className='status_time'>{infoAgent.tempo_inicio_ura}</span>
        </div>
      </div>
    </BoxAgentContainer>
  );
};
