import { apiData } from '@/services/api';

export async function deleteScreen(dataDelete) {
  try {
    const { data } = await apiData.delete(
      `securityProfiles/deleteSecurityProfile/${dataDelete}`
    );

    return data;
  } catch (error) {
    if (error.response.data.message) {
      return error.response.data.message;
    }
    throw new Error(error);
  }
}
