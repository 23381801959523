import { motion } from 'framer-motion';
import styled from 'styled-components';

export const TableContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  border-spacing: 0 1em;
  user-select: none;

  table {
    tbody {
      tr {
        height: 40px;
        max-width: 200px;
        min-width: 180px;
        td {
          z-index: 1;
          padding: 0 0.8rem;

          &:nth-child(1) {
            width: 200px;
          }

          &:nth-child(2) {
            min-width: 160px;
            text-transform: uppercase;
          }

          &:nth-child(5) {
            min-width: 200px;
          }

          &:nth-child(8) {
            min-width: 140px;
          }
        }
      }
    }
  }
`;

export const ElementStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
