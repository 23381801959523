import styled, { css } from 'styled-components';
import { ContainerNodeWrapper, ContentNodeWrapper } from '../styled';

export const NodeDtmfContainer = styled(ContainerNodeWrapper)`
  position: relative;
  background: ${(props) => props.theme.colors.containers.colorSecondNode};
  height: ${(props) => props.heighNode};

  .react-flow__handle {
    &::after {
      background: #39264c;
    }
  }
`;

export const NodeContent = styled(ContentNodeWrapper)`
  width: 220px;

  .header {
    background: ${(props) => props.theme.colors.nodesFlow.dtmf};
  }
`;

export const SubNodeDtmfContainer = styled.div`
  padding: 5px;
  width: 220px;
  border-radius: 2px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background: #39264c;

  ${(props) =>
    props.typeHandler === 'timeOutCondition' &&
    css`
      background: #ff57ab;
    `}

  .react-flow__handle {
    background: transparent;
    width: 30px;
    height: 100%;
    border-radius: 0;
    border: none;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      border-radius: 10px;
      width: 8px;
      height: 8px;
      border: 2px solid ${(props) => props.theme.colors.containers.colorCard};
      background: #39264c;

      ${(props) =>
        props.typeHandler === 'timeOutCondition' &&
        css`
          background: #ff57ab;
        `}
    }
  }
  .headerNode {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    font-size: 0.6rem;

    span {
      font-size: 0.66rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #ffff;
      line-height: 10px;
      padding-top: 1px;
    }
  }
`;
