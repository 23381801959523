import styled from 'styled-components';

export const ContainerLegend = styled.div`
  button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 1.4rem;
      color: ${(props) => props.theme.colors.typography.baseTitle};
    }
  }
`;

export const ContentLegend = styled.div`
  padding: 1rem;
  gap: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  .colorSvgLegend {
    color: ${(props) => props.theme.colors.typography.baseTitle} !important;
  }

  h2 {
    font-size: 0.7rem;
    color: ${(props) => props.theme.colors.typography.baseText};
    text-transform: uppercase;
  }

  .item {
    gap: 0.4rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;

    svg {
      color: ${(props) => props.theme.colors.primary};
    }

    span {
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.baseText};
      padding-top: 2px;
    }
  }
`;
