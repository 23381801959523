import { useMemo } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { ContainerTableVarsApi } from './styled';
import { SimpleTable } from '@/components/Tables/SimpleTable';

export const TableVarsData = ({ dataTable, handleRemoveVarList }) => {
  const { t } = useTranslation();
  const columnsDetailsPosition = [
    {
      Header: t('flowBuilder.flow.nodes.dataApi.menu.table-name'),
      accessor: 'name',
    },
    {
      Header: t('flowBuilder.flow.nodes.dataApi.menu.table-path'),
      accessor: 'value',
    },
    {
      Header: t('flowBuilder.flow.nodes.dataApi.menu.table-action'),
      Cell: ({ row }) => (
        <div className='editRow'>
          <button onClick={() => handleRemoveVarList(row.original.id)}>
            <IoCloseOutline />
          </button>
        </div>
      ),
    },
  ];

  const dataRows = useMemo(() => dataTable, [dataTable]);

  return (
    <ContainerTableVarsApi>
      <SimpleTable
        dataColumns={columnsDetailsPosition}
        dataTable={dataRows}
        textNoData={t('flowBuilder.flow.nodes.dataApi.menu.table-no-data')}
      />
    </ContainerTableVarsApi>
  );
};
