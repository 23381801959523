import { apiData } from '@/services/api';

export async function updateSecurityProfile(dataRoleUpdate) {
  try {
    const { data } = await apiData.put(
      `securityProfiles/updateSecurityProfilesPermissions`,
      dataRoleUpdate
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
}
