import React, { useState } from 'react';
import axios from 'axios';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { FiDownload } from 'react-icons/fi';
import { ButtonDownloadStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import useActiveRecordings from '@/hooks/recordings/useActiveRecordings';
import { RiDownloadCloudFill } from 'react-icons/ri';

export const DownloadButton = ({
  dataDownload,
  withText = false,
  disabledBtn = false,
}) => {
  const { t } = useTranslation();
  const { mutateAudioConverter, loadingAudioConverter } = useActiveRecordings();

  const [loadingDownload, setLoadingDownload] = useState(false);

  const renderURLGsm = (() => {
    const currentUrl = dataDownload?.url_gravacao ?? '';
    const createNewUrl = currentUrl.replace(
      'http://18.229.245.129',
      'https://dialer.voiccelabs.com'
    );
    return createNewUrl;
  })();

  const handleDownload = () => {
    setLoadingDownload(true);
    const fileUrl = renderURLGsm;
    const extFile = `wav`;
    const nameFile = `${new Date().getTime()}-${dataDownload?.fone ?? '-'}-${
      dataDownload.nome_cliente ? dataDownload.nome_cliente.toLowerCase() : '-'
    }.${extFile}`;

    const dataWavDownload = {
      audioUrl: fileUrl,
    };

    mutateAudioConverter(dataWavDownload, {
      onSuccess: (data) => {
        const arrayBuffer = data;
        const audioUrl = URL.createObjectURL(new Blob([arrayBuffer]));

        axios
          .get(audioUrl, { responseType: 'arraybuffer' })
          .then((response) => {
            const blob = new Blob([response.data], { type: 'audio/wav' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = nameFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            successToast(
              t(
                `activeRecordings.listActiveRecords.downloadSound.msg-success-download`
              )
            );
            setLoadingDownload(false);
          })
          .catch(() => {
            errorToast(
              t(
                `activeRecordings.listActiveRecords.downloadSound.msg-error-download`
              )
            );
            setLoadingDownload(false);
          });
      },
      onError: () => setLoadingDownload(false),
    });
  };

  return withText ? (
    <ButtonDownloadStyled onClick={handleDownload} whileTap={{ scale: 0.95 }}>
      <RiDownloadCloudFill />
      {withText}
    </ButtonDownloadStyled>
  ) : (
    <ButtonDownloadStyled
      onClick={handleDownload}
      whileTap={{ scale: 0.95 }}
      disabled={disabledBtn}
    >
      {loadingDownload || loadingAudioConverter ? (
        <CircularProgress size={14} />
      ) : (
        <FiDownload
          size={16}
          style={{ paddingLeft: '1px', paddingRight: '1px' }}
        />
      )}
    </ButtonDownloadStyled>
  );
};
