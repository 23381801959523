import { apiData } from '@/services/api';

export async function createScheduleDDD(dataCreate) {
  try {
    const { data } = await apiData.post(
      'dialingRules/createDDDManager',
      dataCreate
    );
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
