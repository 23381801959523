import { apiData } from '../api';

export async function addToDial(dataNewList) {
  try {
    const { data } = await apiData.post('dialer/addToDial', dataNewList);
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
