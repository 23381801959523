import { apiFlow } from '../api';

export async function getFlowTemplatesById(idFlow) {
  try {
    const { data } = await apiFlow.get(`dialplan/${idFlow}`);
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
