import { useAtom } from 'jotai';
import { memo, useEffect } from 'react';
import { ContainerMenuNode } from './styled';
import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { HangUp } from './formsNode/HungUp';
import { RecVoz } from './formsNode/RecVoz';
import { PlayPromptForm } from './formsNode/PlayPromptForm';
import { InitNodeMenu } from './formsNode/InitNodeMenu';
import { ConditionalForm } from './formsNode/ConditionalForm';
import { PersonaForm } from './formsNode/PersonaForm';
import { DispositionForm } from './formsNode/DispositionForm';
import { VariablesForm } from './formsNode/VariablesForm';
import { DataApi } from './formsNode/DataApi';
import { LoopMenu } from './formsNode/LoopMenu';
import { usePermissionsFlow } from '@/components/pages/FlowBuilder/hooks/usePermissionsFlow';
import { TransferMenu } from './formsNode/TransferForm';
import { DtmfMenu } from './formsNode/DtmfMenu';

export const MenuNodeComponent = ({ dataNodes }) => {
  const { permissionsEdit, permissionsCreate, isSysAdmin } =
    usePermissionsFlow();

  const [showMenu, setShowMenu] = useAtom(showMenuNodes);
  const [selected] = useAtom(selectedNode);

  const closeMenu = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    return () => closeMenu();
  }, []);

  const handleEscPress = (event) => {
    if (event.key === 'Escape') {
      if (showMenu) {
        return closeMenu();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscPress);
    return () => {
      document.removeEventListener('keydown', handleEscPress);
    };
  }, [showMenu]);

  const variantsMenuBackground = {
    hidden: {
      opacity: 0,
      x: 300,
      transition: {
        type: 'spring',
        stiffness: 500,
        damping: 40,
      },
    },
    show: {
      opacity: showMenu ? 1 : 0,
      x: showMenu ? 0 : 300,
      transition: {
        type: 'spring',
        stiffness: 500,
        damping: 40,
      },
    },
  };

  const permissionMenu = permissionsEdit || permissionsCreate || isSysAdmin;

  const renderEditForm = () => {
    const { handler } = selected.data;

    switch (handler) {
      case 'dbQuery':
        return <InitNodeMenu dataNodes={dataNodes} />;
      case 'dialplanVariables':
        return <PersonaForm dataNodes={dataNodes} />;
      case 'hangUp':
        return <HangUp dataNodes={dataNodes} />;
      case 'recVoz':
        return <RecVoz dataNodes={dataNodes} />;
      case 'ttsVoicceLabs':
        return <PlayPromptForm dataNodes={dataNodes} />;
      case 'conditionalNode':
        return <ConditionalForm dataNodes={dataNodes} />;
      case 'disposition':
        return <DispositionForm dataNodes={dataNodes} />;
      case 'setVariables':
        return <VariablesForm dataNodes={dataNodes} />;
      case 'apiData':
        return <DataApi dataNodes={dataNodes} />;
      case 'loop':
        return <LoopMenu dataNodes={dataNodes} />;
      case 'dtmf':
        return <DtmfMenu dataNodes={dataNodes} />;
      case 'transferQueue':
        return <TransferMenu dataNodes={dataNodes} />;

      default:
        return <></>;
    }
  };

  return (
    <ContainerMenuNode
      variants={variantsMenuBackground}
      initial='hidden'
      animate='show'
      onClick={closeMenu}
    >
      {selected && permissionMenu && renderEditForm()}
    </ContainerMenuNode>
  );
};

export const MenuNode = memo(MenuNodeComponent);
