import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { InputComponent } from '../InputComponent';

export const ControlPhoneInput = ({
  control,
  nameControl,
  defaultValue = '',
  ...rest
}) => {
  const fixNumber = (v) => {
    const dig = !v ? '' : v.replace(/[^\d]/g, '');
    if (!dig || dig.length < 10) return v;
    const corte = dig.length === 10 ? 6 : 7;
    const max = dig.length > 11 ? 11 : dig.length;
    return `(${dig.substring(0, 2)}) ${dig.substring(2, corte)}-${dig.substring(
      corte,
      max
    )}`;
  };

  const maskBuilder = (v) => {
    if (!v || v.length == 0) return '';
    const a = fixNumber(v);
    return a.length >= 6 && a[0] === '9' ? '9 9999-9999' : '9999-9999';
    // const currentLang = window.localStorage.getItem('lang');
    // if (currentLang === 'pt') {
    //   if (!v || v.length == 0) return '';
    //   const a = fixNumber(v);
    //   return a.length >= 6 && a[0] === '9' ? '99999-9999' : '9999-9999';
    // }
    // return '999-9999';
  };

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={nameControl}
      render={({ field: { onChange, value } }) => (
        <InputMask mask={maskBuilder(value)} value={value} onChange={onChange}>
          {() => (
            <InputComponent
              {...rest}
              size='small'
              value={value}
              onChange={onChange}
            />
          )}
        </InputMask>
      )}
    />
  );
};
