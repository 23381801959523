import styled, { css } from 'styled-components';

export const BoxAgentContainer = styled.div`
  flex: 1 1;
  height: 80px;
  background: ${(props) => props.theme.colors.containers.colorCardThird};
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 2fr;
  padding: 0.8rem 1rem;
  gap: 2px;

  ${(props) =>
    props.statusCard === 1 &&
    css`
      border-left: 8px solid ${(props) => props.theme.colors.agentColors.AGLIG};
    `}

  ${(props) =>
    props.statusCard === 2 &&
    css`
      border-left: 8px solid ${(props) => props.theme.colors.agentColors.POSLIG};
    `}

    ${(props) =>
    props.statusCard === 3 &&
    css`
      border-left: 8px solid ${(props) => props.theme.colors.agentColors.PAUSA};
    `}

    ${(props) =>
    props.statusCard === 4 &&
    css`
      border-left: 8px solid ${(props) => props.theme.colors.agentColors.EMLIG};
    `}

  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.17);
  border-radius: 3px;

  .name_user {
    grid-column: 1/3;
    span {
      font-weight: 600;
      font-size: 0.82rem;
      line-height: 17px;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }

  .info_user {
    grid-column: 1/3;

    display: flex;
    flex-direction: row;

    .box_status {
      width: 100%;
      display: flex;
      flex-direction: column;

      span {
        font-weight: 700;
        font-size: 0.85rem;
        text-transform: capitalize;
        color: ${(props) => props.theme.colors.typography.baseText};

        ${(props) =>
          props.statusCard === 1 &&
          css`
            color: ${(props) => props.theme.colors.agentColors.AGLIG};
          `}

        ${(props) =>
          props.statusCard === 2 &&
          css`
            color: ${(props) => props.theme.colors.agentColors.POSLIG};
          `}

        ${(props) =>
          props.statusCard === 3 &&
          css`
            color: ${(props) => props.theme.colors.agentColors.PAUSA};
          `}

        ${(props) =>
          props.statusCard === 4 &&
          css`
            color: ${(props) => props.theme.colors.agentColors.EMLIG};
          `}

        &:first-child {
          font-weight: 400;
          font-size: 0.7rem;
          text-transform: capitalize;
          color: ${(props) => props.theme.colors.typography.baseText};
        }
      }
    }

    .box_time {
      width: 100%;
      display: flex;
      flex-direction: column;

      span {
        font-weight: 700;
        font-size: 0.85rem;
        text-transform: capitalize;
        color: ${(props) => props.theme.colors.typography.baseText};

        &:first-child {
          font-weight: 400;
          font-size: 0.7rem;
          text-transform: capitalize;
          color: ${(props) => props.theme.colors.typography.baseText};
        }
      }
    }
  }

  @media (max-width: 680px) {
    width: 100%;
    min-width: 100%;
    min-height: auto;
  }
`;
