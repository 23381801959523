// import axios from 'axios';

export async function updateCurrentLines() {
  try {
    const data = {
      id: '2',
      campaign: 'BMW',
      description: 'BMW',
      client: 'BMW',
      type: 'PRED',
      ura: 'ura-name-example',
      queue: 101,
      totalLines: 20,
      currentLines: 3,
    };

    return new Promise((resolve) => {
      setTimeout(() => resolve(data), import.meta.env.VITE_QUEUE);
    });

    // return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
