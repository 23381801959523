import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { dataDispositions, templateSelected } from '@/store/FlowBuilder';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useDispositions } from '@/hooks/FlowBuilder/useDispositions';
import { DragTransferListCreate } from '@/components/pages/FlowBuilder/components/DragTransferListCreate';
import { usePermissionsFlow } from '@/components/pages/FlowBuilder/hooks/usePermissionsFlow';
import {
  ContainerConfig,
  ContainerGlobalVars,
  FooterConfigForm,
  MainConfigForm,
} from './styled';

export const HomeListDispositionsConfig = ({
  controlStage,
  controlStageConfig,
}) => {
  const { t } = useTranslation();
  const { hasVoicceLabsClient } = usePermissionsFlow();
  const { loadingDispositions } = useDispositions();
  const [codeLigs] = useAtom(dataDispositions);

  const [, setTemplate] = useAtom(templateSelected);

  const [dataCodeLigsSelected, setDataCodeLigsSelected] = useState([]);

  const handleBackStage = () => {
    controlStage.handleBackStage();
  };

  const handleNextStage = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        codeLigs: dataCodeLigsSelected,
      };
    });
    controlStage.handleNextStage();
  };
  return (
    <ContainerConfig initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <MainConfigForm>
        <ContainerGlobalVars>
          {loadingDispositions && <BackDropCircularLoading />}
          <DescriptionInformation
            textDescription={t(
              'flowBuilder.newFlow.stages.codeLigs.description'
            )}
          />
          {codeLigs.length > 0 && (
            <DragTransferListCreate
              codeList={codeLigs}
              changeDataCodeList={setDataCodeLigsSelected}
              typeList={'dispositions'}
            />
          )}
        </ContainerGlobalVars>
      </MainConfigForm>
      <FooterConfigForm>
        {hasVoicceLabsClient ? (
          <Button
            typeText
            onClick={controlStageConfig.toManagementDispositions}
          >
            {t('flowBuilder.configs.dispositions.btn-add-dispositions')}
          </Button>
        ) : (
          <div className=''></div>
        )}
        <Stack direction={'row'} spacing={1}>
          <Button
            typeText
            type='button'
            variant='secondary'
            onClick={handleBackStage}
          >
            {t('flowBuilder.newFlow.stages.btn.btn-back')}
          </Button>
          <Button typeText onClick={handleNextStage}>
            {t('flowBuilder.newFlow.stages.btn.btn-next')}
          </Button>
        </Stack>
      </FooterConfigForm>
    </ContainerConfig>
  );
};
