import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerTableQueues = styled(motion.div)`
  width: 100%;
  height: 100%;
  min-height: 200px;
  user-select: none;
  overflow-y: auto;

  table {
    position: relative;
    width: 100%;
    thead {
      position: sticky;
      top: 0;
      z-index: 99999;
    }
    tbody {
      tr {
        height: 46px;
        td {
          z-index: 1;
          padding: 0 0.8rem;

          overflow: hidden;
          word-wrap: break-word;

          span {
            color: ${(props) => props.theme.colors.typography.baseText};
          }

          &:nth-child(1) {
            width: 60px;
          }
        }
      }
    }

    tfoot {
      position: sticky;
      bottom: 0;
      z-index: 99999;
    }
  }

  .colorDiscing {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }

  .colorTalking {
    color: ${(props) => props.theme.colors.primary} !important;
  }

  .colorNull {
    color: ${(props) => props.theme.colors.secondary} !important;
  }

  @media (max-width: 1300px) {
    table {
      tbody {
        tr {
          height: 54px;
        }
      }
    }
  }

  @media (max-width: 500px) {
    margin-top: 0.675rem;
    max-height: calc(100vh - 300px);
    table tbody,
    table tr,
    table td {
      display: block;
      width: 100%;
    }

    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          margin-bottom: 12px;
          width: 100%;
          min-width: 100%;
          height: auto;
          padding: 1rem 0;
          border-left: 3px solid ${(props) => props.theme.colors.primary};

          td {
            padding-left: 40%;
            text-align: left;
            position: relative;
            padding-bottom: 6px;

            min-width: 100%;
            overflow: none;
            text-overflow: initial;

            span {
              font-size: 12px;
            }
            &::before {
              content: attr(data-label) ':';
              position: absolute;
              left: 0;
              width: 50%;
              padding-left: 15px;
              text-align: left;
              font-size: 12px;
              color: ${(props) => props.theme.colors.typography.baseText};
              line-height: 1.2;
              font-weight: bold;
            }
          }
        }
      }
      tfoot {
        display: none;
      }
    }
  }
`;
