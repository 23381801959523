export async function getListSelected() {
  try {
    const data = [
      {
        id: '1',
        list: 'list',
        description: 'List Description',
        creationDate: 1696617036064,
        status: 'active',
        campaign: 'BCOORIGINAL',
      },
      {
        id: '2',
        list: 'list',
        description: 'List Description',
        creationDate: 1696617036064,
        status: 'disabled',
        campaign: 'BMW',
      },
      {
        id: '3',
        list: 'list',
        description: 'List Description',
        creationDate: 1696617036064,
        status: 'active',
        campaign: 'BPP',
      },
      {
        id: '4',
        list: 'list',
        description: 'List Description',
        creationDate: 1696617036064,
        status: 'disabled',
        campaign: 'BCOORIGINAL',
      },
      {
        id: '5',
        list: 'list',
        description: 'List Description',
        creationDate: 1696617036064,
        status: 'active',
        campaign: 'BMW',
      },
      {
        id: '6',
        list: 'list',
        description: 'List Description',
        creationDate: 1696617036064,
        status: 'disabled',
        campaign: 'BCOORIGINAL',
      },
      {
        id: '7',
        list: 'list',
        description: 'List Description',
        creationDate: 1696617036064,
        status: 'active',
        campaign: 'BMW',
      },
      {
        id: '8',
        list: 'list',
        description: 'List Description',
        creationDate: 1696617036064,
        status: 'disabled',
        campaign: 'BCOORIGINAL',
      },
      {
        id: '9',
        list: 'list',
        description: 'List Description',
        creationDate: 1696617036064,
        status: 'active',
        campaign: 'BMW',
      },
      {
        id: '10',
        list: 'list',
        description: 'List Description',
        creationDate: 1696617036064,
        status: 'disabled',
        campaign: 'BCOORIGINAL',
      },
    ];

    return new Promise((resolve) => {
      setTimeout(() => resolve(data), import.meta.env.VITE_QUEUE);
    });
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
