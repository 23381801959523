import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerConfig = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr 30px;
`;

export const MainConfigForm = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 95%;

  p {
    color: ${(props) => props.theme.colors.typography.baseText};
    font-weight: bold;
  }

  .success-checkmark {
    height: 60px;
    margin: 0 auto;
    user-select: none;

    .check-icon {
      width: 60px;
      height: 60px;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 3px solid #4caf50;

      &::before {
        top: 2px;
        left: -1px;
        width: 22.5px;
        transform-origin: 100% 50%;
        border-radius: 100px 0 0 100px;
      }

      &::after {
        top: 0;
        left: 22.5px;
        width: 45px;
        transform-origin: 0 50%;
        border-radius: 0 100px 100px 0;
        animation: rotate-circle 4.25s ease-in;
      }

      &::before,
      &::after {
        content: '';
        height: 75px;
        position: absolute;
        background: transparent;
        transform: rotate(-45deg);
      }

      .icon-line {
        height: 3.75px;
        background-color: #4caf50;
        display: block;
        border-radius: 2px;
        position: absolute;
        z-index: 10;

        &.line-tip {
          top: 34.5px;
          left: 10.5px;
          width: 18.75px;
          transform: rotate(45deg);
          animation: icon-line-tip 0.75s;
        }

        &.line-long {
          top: 28.5px;
          right: 6px;
          width: 35.25px;
          transform: rotate(-45deg);
          animation: icon-line-long 0.75s;
        }
      }

      .icon-circle {
        top: -3px;
        left: -3px;
        z-index: 10;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        position: absolute;
        box-sizing: content-box;
        border: 3px solid rgba(76, 175, 80, 0.5);
      }

      .icon-fix {
        top: 6px;
        width: 3.75px;
        left: 19.5px;
        z-index: 1;
        height: 63.75px;
        position: absolute;
        transform: rotate(-45deg);
        background-color: transparent;
      }
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }
    5% {
      transform: rotate(-45deg);
    }
    12% {
      transform: rotate(-405deg);
    }
    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 0.75px;
      top: 14.25px;
    }
    54% {
      width: 0;
      left: 0.75px;
      top: 14.25px;
    }
    70% {
      width: 37.5px;
      left: -6px;
      top: 27.75px;
    }
    84% {
      width: 12.75px;
      left: 15.75px;
      top: 36px;
    }
    100% {
      width: 18.75px;
      left: 10.5px;
      top: 33.75px;
    }
  }

  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 34.5px;
      top: 40.5px;
    }
    65% {
      width: 0;
      right: 34.5px;
      top: 40.5px;
    }
    84% {
      width: 41.25px;
      right: 0px;
      top: 26.25px;
    }
    100% {
      width: 35.25px;
      right: 6px;
      top: 28.5px;
    }
  }
`;

export const FooterConfigForm = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;

export const DescriptionFlow = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  border-radius: 3px;
  padding: 1rem;
  display: flex;
  gap: 1rem;

  .item__screen {
    display: flex;
    gap: 1rem;

    .item__data_list {
      width: 100%;
      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        li {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 0.7rem;
          color: ${(props) => props.theme.colors.defaultColors.white};
          padding: 4px 8px 3px;
          border-radius: 3px;
          background-color: ${(props) => props.theme.colors.defaultColors.blue};
        }
      }
    }
    span {
      font-size: 0.76rem;

      &:first-child {
        font-weight: bold;
        color: ${(props) => props.theme.colors.typography.baseText};
        width: 120px;
      }
      &:last-child {
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }
  }
`;
