import Tree from 'react-d3-tree';
import { useEffect, useMemo, useState } from 'react';
import { ContentTree } from './styled';
import { renderForeignObjectNode } from './NodeComponent/renderCustomNode';
import { PanelTree } from './PanelTree';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCenteredTree } from '@/hooks/Journey/useCenteredTree';
import { LoadingComponent } from '@/components/layout/LoadingComponent';

export const TreeComponent = ({ dataChart = [], isLoadingData = false }) => {
  const matchesQueriesLg = useMediaQuery('(min-width:1600px)');
  const matchesQueriesMd = useMediaQuery('(max-width:1460px)');

  const [translate, containerRef, setTranslate, centralizeTree] =
    useCenteredTree();
  const dataList = useMemo(() => dataChart, [dataChart]);
  const [scaleZoom, setScaleZoom] = useState(0.75);
  const [pathTree, setPathTree] = useState('diagonal');
  const [lockTree, setLockTree] = useState(false);

  useEffect(() => {
    if (matchesQueriesLg) {
      centralizeTree();
      setScaleZoom(0.85);
    }
    if (matchesQueriesMd) {
      setScaleZoom(0.62);
    }
  }, [matchesQueriesMd, matchesQueriesLg]);

  const nodeSize = {
    x: 280,
    y: 90,
    padding: '0 10px',
  };

  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: '-120px',
  };

  return (
    <ContentTree ref={containerRef}>
      <PanelTree
        lockTreeProps={{ lockTree, setLockTree }}
        pathTreeProps={{ pathTree, setPathTree }}
        scaleZoomProps={{ scaleZoom, setScaleZoom }}
        translateProps={{ translate, setTranslate }}
      />
      {!isLoadingData ? (
        <Tree
          draggable={!lockTree}
          initialDepth={10}
          zoom={scaleZoom}
          zoomable={!lockTree}
          data={dataList}
          transitionDuration={400}
          translate={translate}
          nodeSize={nodeSize}
          enableLegacyTransitions={true}
          transition={true}
          centeringTransitionDuration={450}
          pathFunc={pathTree}
          pathClassFunc={() => 'link__node'}
          separation={{ nonSiblings: 1.3, siblings: 1.2 }}
          renderCustomNodeElement={(rd3tProps) =>
            renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
          }
        />
      ) : (
        <LoadingComponent />
      )}
    </ContentTree>
  );
};
