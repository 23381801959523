import { useCallback } from 'react';
import { ContainerCard, HeaderNode, MainNode } from './styled';
import { Stack, Tooltip } from '@mui/material';
import { PiTreeStructureLight, PiLineSegment } from 'react-icons/pi';
import { HiPhoneArrowUpRight, HiPhoneArrowDownLeft } from 'react-icons/hi2';
import { CgEditBlackPoint } from 'react-icons/cg';
import { useTranslation } from 'react-i18next';

export const NodeComponent = ({ nodeDatum, toggleNode }) => {
  const { t } = useTranslation();
  const handleExpandNode = useCallback(() => {
    toggleNode();
  }, [toggleNode]);
  return (
    <ContainerCard onClick={handleExpandNode}>
      <HeaderNode>
        <h2>{nodeDatum?.name ?? ''}</h2>

        {nodeDatum.children &&
          (nodeDatum.__rd3t.collapsed ? (
            <PiTreeStructureLight size={14} color='white' />
          ) : (
            <PiLineSegment size={14} color='white' />
          ))}
      </HeaderNode>
      <MainNode>
        <Tooltip title={t('journey.chartTree.legend.label-node')} arrow>
          <Stack
            direction={'row'}
            spacing={0.3}
            className='first-item'
            alignItems={'center'}
          >
            <CgEditBlackPoint />
            <span>{nodeDatum?.totalValues ?? ''}</span>
          </Stack>
        </Tooltip>
        <Tooltip title={t('journey.chartTree.legend.label-totalCalls')} arrow>
          <Stack
            direction={'row'}
            spacing={0.3}
            className='second-item'
            alignItems={'center'}
          >
            <HiPhoneArrowUpRight />
            <span>{nodeDatum?.inCall ?? ''}</span>
          </Stack>
        </Tooltip>
        <Tooltip title={t('journey.chartTree.legend.label-closeCalls')} arrow>
          <Stack direction={'row'} spacing={0.3} alignItems={'center'}>
            <HiPhoneArrowDownLeft />
            <span>{nodeDatum?.closeCall ?? ''} </span>
            <span className='percentage-data'>
              ({nodeDatum?.percentage ?? '0'}%)
            </span>
          </Stack>
        </Tooltip>
      </MainNode>
    </ContainerCard>
  );
};
