import { Button } from '@/components/FormComponents/ButtonComponent';
import { ContainerBoardDtmf, ContainerPhoneInputs, ItemDtmf } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const PhoneCapture = ({ dataDtmf }) => {
  const { t } = useTranslation();

  const { listDtmf, setListDtmf, timeOut, setTimeOut, typeKeyCapture } =
    dataDtmf;

  useEffect(() => {
    return () => {
      setTimeOut(1);
      setListDtmf([]);
    };
  }, []);

  const dataBoard = [
    {
      key: '1',
    },
    {
      key: '2',
    },
    {
      key: '3',
    },
    {
      key: '4',
    },
    {
      key: '5',
    },
    {
      key: '6',
    },
    {
      key: '7',
    },
    {
      key: '8',
    },
    {
      key: '9',
    },
    {
      key: '*',
    },
    {
      key: '0',
    },
    {
      key: '#',
    },
  ];

  const handleSelectKeyDtmf = (key) => {
    const hasUsed = listDtmf.filter((item) => item.key === key);
    if (hasUsed.length > 0) {
      const unselectItem = listDtmf.filter((item) => item.key !== key);
      return setListDtmf(unselectItem);
    }
    return setListDtmf((preview) => [...preview, { key }]);
  };

  const checkUsedKey = (itemToCheck, listDtmf) => {
    const hasUsed = listDtmf.filter((item) => item.key === itemToCheck);
    if (hasUsed.length > 0) {
      return `usedKey-selector`;
    }
    return ``;
  };

  return (
    <ContainerPhoneInputs>
      <h4>{t('flowBuilder.flow.nodes.dtmf.menu.title-custom-capture')}</h4>

      <InputComponent
        label={t('flowBuilder.flow.nodes.dtmf.menu.label-timeout')}
        type={'number'}
        value={timeOut}
        onChange={(e) => setTimeOut(e.target.value)}
        inputProps={{ min: 0, max: 60 }}
      />

      <ContainerBoardDtmf>
        {dataBoard.map((item, i) => {
          return (
            <ItemDtmf key={i}>
              <Button
                typeText
                onClick={() => handleSelectKeyDtmf(item.key)}
                className={checkUsedKey(item.key, listDtmf)}
                title={item.key}
              >
                {item.key}
              </Button>
            </ItemDtmf>
          );
        })}
      </ContainerBoardDtmf>
    </ContainerPhoneInputs>
  );
};
