import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { Stack } from '@mui/system';
import { dataNlus, templateSelected } from '@/store/FlowBuilder';
import { ContainerGlobalVars } from '../ConfigDispositions/styled';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useEndpoints } from '@/hooks/FlowBuilder/useEndpoints';
import { DragTransferListCreate } from '@/components/pages/FlowBuilder/components/DragTransferListCreate';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ContainerConfig, FooterConfigForm, MainConfigForm } from './styled';
import { usePermissionsFlow } from '@/components/pages/FlowBuilder/hooks/usePermissionsFlow';

export const HomeConfigNlu = ({ changeStateFlow, controlStageConfigNlus }) => {
  const { t } = useTranslation();
  const { loadingNlus } = useEndpoints();
  const [nlus] = useAtom(dataNlus);
  const [dataNLUsSelected, setDataNLUsSelected] = useState([]);
  const [, setTemplate] = useAtom(templateSelected);
  const { hasVoicceLabsClient } = usePermissionsFlow();

  const handleBackStage = () => {
    changeStateFlow.handleBackStage();
  };

  const handleNextStage = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        nlus: dataNLUsSelected,
      };
    });
    changeStateFlow.handleNextStage();
  };
  return (
    <ContainerConfig initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <MainConfigForm>
        <ContainerGlobalVars>
          {loadingNlus && <BackDropCircularLoading />}
          <DescriptionInformation
            textDescription={t('flowBuilder.newFlow.stages.nlus.description')}
          />
          {nlus.length > 0 && (
            <DragTransferListCreate
              codeList={nlus}
              changeDataCodeList={setDataNLUsSelected}
            />
          )}
        </ContainerGlobalVars>
      </MainConfigForm>
      <FooterConfigForm>
        {hasVoicceLabsClient ? (
          <Button typeText onClick={controlStageConfigNlus}>
            {t('flowBuilder.newFlow.stages.nlus.btn-management-nlus')}
          </Button>
        ) : (
          <div className=''></div>
        )}

        <Stack direction={'row'} spacing={1}>
          <Button
            typeText
            type='button'
            variant='secondary'
            onClick={handleBackStage}
          >
            {t('flowBuilder.newFlow.stages.btn.btn-back')}
          </Button>
          <Button typeText onClick={handleNextStage}>
            {t('flowBuilder.newFlow.stages.btn.btn-next')}
          </Button>
        </Stack>
      </FooterConfigForm>
    </ContainerConfig>
  );
};
