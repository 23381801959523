import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { FormNewList } from './styled';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { errorToast } from '@/components/FormComponents/Toast';
import useFormControl from '@/hooks/useFormControl';
import { newScheduleList } from '@/store/ScheduleStore/SchedulerList';
import { dataListsByClient, selectedQueue } from '@/store/DialingStore';
import { dataQueues } from '@/store/Queues';
import { ControlAutoComplete } from '@/components/FormComponents/AutoCompleteSelect';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const QueueSchedule = ({ controlAddStage, controlMainStage }) => {
  const { t } = useTranslation();

  const [queueSelected] = useAtom(selectedQueue);
  const [listsDialing] = useAtom(dataListsByClient);
  const [listQueues] = useAtom(dataQueues);
  const [, setNewSchedule] = useAtom(newScheduleList);

  const renderTxt = (word) => {
    return t(`dialControl.schedulesList.createSchedule.queueSchedules.${word}`);
  };

  const createQueuesScheduleSchema = yup.object().shape({
    queue: yup.string().required(),
    list: yup.object().required(),
  });

  const { handleSubmit, control, reset, errors, watch } = useFormControl(
    createQueuesScheduleSchema
  );

  const checkValueFields = {
    queue: watch('queue'),
    list: watch('list'),
  };

  useEffect(() => {
    if (errors.list || errors.queue) {
      errorToast(renderTxt('errorMessage'));
    }
  }, [errors]);

  const handleCreateSchedule = (data) => {
    const dataCreate = {
      queue: data.queue,
      list: data.list.value,
    };
    setNewSchedule(dataCreate);
    controlAddStage(1);
    reset();
  };

  const handlePreviousSchedule = () => {
    controlMainStage.toStage(1);
    setNewSchedule(null);
    reset();
  };

  const orderListDec = listsDialing?.sort((a, b) => b.lista - a.lista);

  const renderOptions = orderListDec?.map((item) => {
    return {
      label: `${item.lista} - ${item.descricao}`,
      value: item.lista,
    };
  });

  return (
    <FormNewList
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onSubmit={handleSubmit(handleCreateSchedule)}
    >
      <div className='main'>
        <div className='desc'>
          <DescriptionInformation textDescription={renderTxt('description')} />
        </div>
        <ControlSelect
          control={control}
          nameControl={'queue'}
          defaultValue={queueSelected.fila}
          label={renderTxt('label-queue')}
          error={Boolean(errors.queue)}
          readOnly
          disabled
        >
          <ItemSelect value={'0'} disabled>
            {renderTxt('option-queue')}
          </ItemSelect>

          {listQueues.map((item, i) => (
            <ItemSelect value={item.fila} key={i}>
              {item.fila} - {item.campanha}
            </ItemSelect>
          ))}
        </ControlSelect>

        <ControlAutoComplete
          control={control}
          nameControl={'list'}
          label={renderTxt('label-list')}
          optionsList={renderOptions}
          error={Boolean(errors.list)}
        />
      </div>

      <div className='footer'>
        <Button typeText variant='secondary' onClick={handlePreviousSchedule}>
          {renderTxt('btn-cancel')}
        </Button>
        <Button
          typeText
          type='submit'
          disabled={
            Boolean(errors.list) ||
            Boolean(errors.queue) ||
            checkValueFields.queue === '' ||
            checkValueFields.list === ''
          }
        >
          {renderTxt('btn-next')}
        </Button>
      </div>
    </FormNewList>
  );
};
