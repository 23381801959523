import { apiData } from '@/services/api';

export async function updateScheduleQueue(dataUpdate) {
  try {
    const { data } = await apiData.put(
      `dialerSchedule/updateScheduleQueue`,
      dataUpdate
    );
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
