import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerNewFlow = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 80px 1fr;
  gap: 2rem;
`;
