import { checkboxClasses, FormControlLabel } from '@mui/material';
import {
  CheckBoxFlowComponent,
  StyledCheckBox,
  StyledCheckBoxWithLabel,
} from './styled';
import { useTheme } from 'styled-components';
import { Controller } from 'react-hook-form';
import { useId } from 'react';

export const CheckboxComponent = ({ ...props }) => {
  return <StyledCheckBox {...props} />;
};

export const CheckboxWithLabel = ({ label = '', ...props }) => {
  const theme = useTheme();

  return (
    <FormControlLabel
      control={<StyledCheckBoxWithLabel {...props} />}
      name={label}
      label={label}
      id={label}
      sx={{
        [`&, &.${checkboxClasses.checked}`]: {
          color: `${theme.colors.typography.baseText}`,
          fontSize: '0.8rem',
          marginTop: 0,
          lineHeight: 0,
        },
        ['.MuiCheckbox-colorPrimary']: {
          color: `${theme.colors.bases.active}`,
        },
      }}
    />
  );
};

export const ControlCheckbox = ({
  control,
  nameControl,
  defaultValue = '',
  ...rest
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={nameControl}
      render={({ field: { onChange, value } }) => (
        <CheckboxWithLabel {...rest} value={value} onChange={onChange} />
      )}
    />
  );
};

export const CheckBoxFlow = ({ ...rest }) => {
  return <CheckBoxFlowComponent {...rest} id={useId()} />;
};
