import { apiData } from '../api';

export async function getItensProfileUser() {
  try {
    const { data } = await apiData.get(
      `securityProfiles/listRoutingProfileUser`
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
}
