import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import React, { memo, useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { NodeContent, NodeFunctionContainer } from './styled';
import { FaGears } from 'react-icons/fa6';
import { shortNameRender } from '../../../../utils/shortNameRender';
import { PanelNode } from '@/components/pages/FlowBuilder/components/PanelNode';

const FunctionNodeComponent = ({ id, data, selected }) => {
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [, setSelectedNode] = useAtom(selectedNode);

  const handleNode = useCallback(() => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  }, [id, data, setSelectedNode, setShowMenu]);

  return (
    <NodeFunctionContainer
      selected={selected}
      onDoubleClick={() => handleNode()}
      errorConnect={data.errorConnect ? 1 : 0}
    >
      <PanelNode data={data} id={id} />

      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <FaGears size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
            <span>Function</span>
          </div>
        </div>
        <div className='main'></div>
      </NodeContent>
      <Handle type='source' position={Position.Right} />
    </NodeFunctionContainer>
  );
};

export const FunctionNode = memo(FunctionNodeComponent);
