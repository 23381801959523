import { apiData } from '../api';

export async function getFunnelAttendance(queueSelected, signal) {
  try {
    const { data } = await apiData.post(
      `/dashboards/serviceFunnel`,
      queueSelected,
      {
        signal,
      }
    );
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
