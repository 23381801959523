import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContentScreens = styled(motion.div)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  min-height: 200px;
  padding: 3rem 2rem;

  .content_btn_add_screen {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
`;

export const HeaderListScreens = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.7rem;
  margin-top: -8px;

  svg {
    color: ${(props) => props.theme.colors.primary};
    font-size: 0.85rem;
  }
`;
