import styled from 'styled-components';

export const ContainerBoxMonitor = styled.div`
  width: 100%;
  margin-bottom: 20px;

  .expandIconAccordion {
    color: ${(props) => props.theme.colors.bases.baseWhite};
  }

  .content-agents {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding: 0.6rem;

    @media (max-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 570px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
