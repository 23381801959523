import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';
import { Stack } from '@mui/system';

export const StatusDataRow = ({ status }) => {
  const { t } = useTranslation();
  return status ? (
    <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
      <IoCheckmarkCircleOutline
        size={20}
        color='green'
        title={t('usersAgentsList.table.statusActive')}
      />
    </Box>
  ) : (
    <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
      <IoCloseCircleOutline
        size={20}
        color='red'
        title={t('usersAgentsList.table.statusInative')}
      />
    </Box>
  );
};

const renderStatusIndicator = (nameLabel, valueProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction={'row'} spacing={1}>
      <span>{nameLabel}: </span>
      <span>
        {valueProps === 1
          ? t('usersAgentsList.table.statusActive')
          : t('usersAgentsList.table.statusInative')}
      </span>
    </Stack>
  );
};

export const StatusIndicator = ({ nameLabel = '', status = 0 }) => {
  return status ? (
    <Tooltip title={renderStatusIndicator(nameLabel, status)} followCursor>
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <IoCheckmarkCircleOutline size={20} color='green' />
      </Box>
    </Tooltip>
  ) : (
    <Tooltip title={renderStatusIndicator(nameLabel, status)} followCursor>
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <IoCloseCircleOutline size={20} color='red' />
      </Box>
    </Tooltip>
  );
};
