import { apiData } from '@/services/api';

export async function createTenant(dataCreate) {
  try {
    const { data } = await apiData.post('/servers', dataCreate);
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
