import { Button } from '@/components/FormComponents/ButtonComponent';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerAddDisposition, FormAddDisposition } from './styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/system';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { Grid } from '@mui/material';
import { SwitchControlled } from '@/components/FormComponents/SwitchComponent';
import { useDispositionsDialing } from '@/hooks/Dispositions/useDispositionsDialing';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { successToast } from '@/components/FormComponents/Toast';

export const AddDisposition = ({ changeStage }) => {
  const { t } = useTranslation();
  const {
    mutateCreateMailingDisposition,
    loadingCreateMailingDisposition,
    mutateCreateUraDisposition,
    loadingCreateUraDisposition,
  } = useDispositionsDialing();

  const addDispositionSchema = yup.object().shape({
    cod_lig: yup.string().required(),
    descricao: yup.string().required(),
    finaliza_contato: yup.boolean(),
    finaliza_fone: yup.boolean(),
    contato_positivo: yup.boolean(),
    contato_efetivo: yup.boolean(),
    padrao_sim_nao: yup.boolean(),
    tela_agente_sn: yup.boolean(),
    sistemico_sn: yup.boolean(),
    condicao: yup.boolean(),
    recusa: yup.boolean(),
    transferencia: yup.boolean(),
    interrompido: yup.boolean(),
    contato_sem_interacao: yup.boolean(),
    anti_flooding: yup.boolean(),
    transferido: yup.boolean(),
    uraConnectionCode: yup.boolean(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addDispositionSchema),
  });

  const handleBack = () => {
    changeStage(0);
    reset();
  };

  const handleNewDisposition = (data) => {
    const dataCreate = {
      cod_lig: data.cod_lig.toUpperCase(),
      descricao: data.descricao,
      finaliza_contato: data.finaliza_contato ? 1 : 0,
      finaliza_fone: data.finaliza_fone ? 1 : 0,
      contato_positivo: data.contato_positivo ? 1 : 0,
      contato_efetivo: data.contato_efetivo ? 1 : 0,
      padrao_sim_nao: data.padrao_sim_nao ? 1 : 0,
      tela_agente_sn: data.tela_agente_sn ? 1 : 0,
      sistemico_sn: data.sistemico_sn ? 1 : 0,
      condicao: data.condicao ? 1 : 0,
      recusa: data.recusa ? 1 : 0,
      transferencia: data.transferencia ? 1 : 0,
      interrompido: data.interrompido ? 1 : 0,
      contato_sem_interacao: data.contato_sem_interacao ? 1 : 0,
      anti_flooding: data.anti_flooding ? 1 : 0,
      transferido: data.transferido ? 1 : 0,
    };

    if (data.uraConnectionCode) {
      return mutateCreateUraDisposition(dataCreate, {
        onSuccess: () => {
          successToast(t('dispositions.create.msg-success-created'));
          handleBack();
        },
      });
    }
    return mutateCreateMailingDisposition(dataCreate, {
      onSuccess: () => {
        successToast(t('dispositions.create.msg-success-created'));
        handleBack();
      },
    });
  };

  useEffect(() => {
    return () => handleBack();
  }, []);

  const isLoading =
    loadingCreateMailingDisposition || loadingCreateUraDisposition;

  return (
    <ContainerAddDisposition initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <FormAddDisposition onSubmit={handleSubmit(handleNewDisposition)}>
        <TitleComponent
          textTitle={t('dispositions.create.title')}
          iconTitle='tenants'
        />

        <div className='mainFormDisposition'>
          <Stack spacing={2}>
            <TitleComponent
              textTitle={t('dispositions.create.title-details')}
            />
            <ControlInput
              control={control}
              nameControl={'cod_lig'}
              label={t('dispositions.create.label-disposition')}
              error={Boolean(errors.cod_lig)}
            />
            <ControlInput
              control={control}
              nameControl={'descricao'}
              label={t('dispositions.create.label-description')}
              error={Boolean(errors.descricao)}
            />
          </Stack>

          <TitleComponent textTitle={t('dispositions.create.title-config')} />

          <Grid container spacing={4} padding={'8px'}>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'uraConnectionCode'}
                activeLabel={t('dispositions.create.label-uraConnection')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'finaliza_contato'}
                activeLabel={t('dispositions.create.label-ends')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'finaliza_fone'}
                activeLabel={t('dispositions.create.label-endCall')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'contato_positivo'}
                activeLabel={t('dispositions.create.label-positive')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'contato_efetivo'}
                activeLabel={t('dispositions.create.label-effective')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'padrao_sim_nao'}
                activeLabel={t('dispositions.create.label-sn')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'tela_agente_sn'}
                activeLabel={t('dispositions.create.label-agentScreenSn')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'sistemico_sn'}
                activeLabel={t('dispositions.create.label-sysSn')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'condicao'}
                activeLabel={t('dispositions.create.label-condition')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'recusa'}
                activeLabel={t('dispositions.create.label-refusal')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'transferencia'}
                activeLabel={t('dispositions.create.label-transfer')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'interrompido'}
                activeLabel={t('dispositions.create.label-interrupted')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'contato_sem_interacao'}
                activeLabel={t('dispositions.create.label-noInteraction')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'anti_flooding'}
                activeLabel={t('dispositions.create.label-antFlooding')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'transferido'}
                activeLabel={t('dispositions.create.label-transferred')}
              />
            </Grid>
          </Grid>
        </div>

        <div className='footerAddNewDisposition'>
          <Button typeText variant={'secondary'} onClick={handleBack}>
            {t('dispositions.create.btn-cancel')}
          </Button>
          <Button typeText type='submit'>
            {t('dispositions.create.btn-create')}
          </Button>
        </div>
      </FormAddDisposition>
    </ContainerAddDisposition>
  );
};
