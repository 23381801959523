import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { createListMailing } from '@/services/loadList/createListMailing';
import { getMetadataTemplates } from '@/services/loadList/getMetadataTemplates';
import { getTemplatesMailingById } from '@/services/loadList/getTemplatesMailingById';
import { saveCurrentTemplate } from '@/services/loadList/saveCurrentTemplate';
import {
  dataMailing,
  dataTemplateMailing,
  listTemplateMetadata,
  savedTemplateMailing,
} from '@/store/LoadList';
import { currentPageId, isReload } from '@/store/NavigationDashboard';

export const useLoadList = (enabledReload = false) => {
  const { t } = useTranslation();
  const [, setDataTemplateMailing] = useAtom(dataTemplateMailing);
  const [, setDataSavedTemplate] = useAtom(savedTemplateMailing);
  const [, setListTemplateMetadata] = useAtom(listTemplateMetadata);
  const [mailingData, setMailingData] = useAtom(dataMailing);
  const [reloaded] = useAtom(isReload);
  const [currentPage] = useAtom(currentPageId);

  const controller = new AbortController();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [reloaded, currentPage]);

  const {
    isLoading: loadingTemplateMetadata,
    refetch: refetchListMetadata,
    isFetching: fetchingTemplateMetadata,
  } = useQuery(
    'listTemplateMetadata',
    async () => {
      return await getMetadataTemplates(
        mailingData.campaign,
        controller.signal
      );
    },
    {
      enabled: enabledReload,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setListTemplateMetadata(
          data.map((metadata) => ({
            ...metadata,
            hasImported: true,
          }))
        );
      },
    }
  );

  const { mutate: mutateDataTemplate, isLoading: loadingDataTemplate } =
    useMutation({
      mutationFn: async ({ idTemplate, signal }) => {
        return await getTemplatesMailingById(idTemplate, signal);
      },
      onSuccess: (data) => {
        const dataTemplate = {
          flow: {
            nodes: data.nodes,
            edges: data.edges,
          },
          metadata: {
            ...data.metadata,
            hasImported: true,
          },
        };
        setDataSavedTemplate(dataTemplate);
      },
    });

  const {
    mutate: mutateCreateTemplateLoadList,
    isLoading: loadingCreateTemplateLoadList,
  } = useMutation({
    mutationFn: async ({ dataCreate, signal }) => {
      return await saveCurrentTemplate(dataCreate, signal);
    },
    onSuccess: (data) => {
      setMailingData(data.metadata);
      refetchListMetadata();
      const dataTemplate = {
        flow: {
          nodes: data.nodes,
          edges: data.edges,
        },
        metadata: data.metadata,
        hasImported: true,
      };
      setDataTemplateMailing(dataTemplate);
    },
    onError: (error) => {
      const errorMessage = error.message || JSON.stringify(error);
      const toastMessage =
        errorMessage === 'This flow name already exists.'
          ? t('listLoader.importMailing.flow.msg-error-existis')
          : t('listLoader.importMailing.flow.msg-error-save');
      return errorToast(toastMessage);
    },
  });

  const { mutate: mutateCreateList, isLoading: loadingCreateList } =
    useMutation({
      mutationFn: async (dataCreate) => {
        return await createListMailing(dataCreate);
      },
      onSuccess: (data) => {
        console.log('🚀 -> data:', data);
      },
      onError: (error) => {
        const errorMessage = error.message || JSON.stringify(error);
        const toastMessage =
          errorMessage === ''
            ? ''
            : t('listLoader.importMailing.flow.msg-error-created');
        return errorToast(toastMessage);
      },
    });

  return {
    loadingTemplateMetadata,
    fetchingTemplateMetadata,
    mutateDataTemplate,
    loadingDataTemplate,
    refetchListMetadata,
    mutateCreateTemplateLoadList,
    loadingCreateTemplateLoadList,
    mutateCreateList,
    loadingCreateList,
    controller,
  };
};
