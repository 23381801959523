import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PasswordChecklist from 'react-password-checklist';

import { Button } from '@components/FormComponents/ButtonComponent';
import { InputComponent } from '@components/FormComponents/InputComponent';
import { TitleComponent } from '@components/layout/TitleComponent';

import { ContainerChangePassword, FormModalNewPass } from './styled';
import imgLogo from '@/assets/imgs/lg_voiccelabs_white.webp';
import { useMutation } from 'react-query';
import { changePassword } from '@/services/sessions/forgotPassword';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { LoadingComponent } from '@/components/layout/LoadingComponent';

export const NewUserPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { token } = useParams();

  useEffect(() => {
    window.localStorage.setItem('theme', 'light');
  }, [window.localStorage]);

  useEffect(() => {
    if (!token) {
      return navigate('/');
    }
  }, []);

  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [isValidNewPass, setIsValidNewPass] = useState(false);

  const isValidateForm = password === passwordAgain && isValidNewPass;

  const { mutate, isLoading } = useMutation({
    mutationFn: (dataChange) => {
      return changePassword(dataChange);
    },
    retry: false,
    onSuccess: () => {
      setPasswordAgain('');
      setPassword('');
      navigate('/');
      successToast(t('forgotPass.messages.successMessageForgotPass'));
    },
    onError: () => {
      setPasswordAgain('');
      setPassword('');
      errorToast(t('newUserPassword.messages.errorNewPass'));
    },
  });

  const handleSubmitNewPass = (event) => {
    event.preventDefault();

    if (!isValidateForm) {
      errorToast(t('forgotPass.messages.errorIdenticFields'));
      return;
    }

    const dataChange = {
      idForgot: token,
      newPass: password,
    };
    mutate(dataChange);
  };

  return (
    <ContainerChangePassword>
      {isLoading && <LoadingComponent />}
      <div className='logo_pass'>
        <img src={imgLogo} alt='' />
      </div>
      <div className='main_pass'>
        <FormModalNewPass onSubmit={handleSubmitNewPass}>
          <TitleComponent
            textTitle={t('newUserPassword.title-NewPass')}
            iconTitle='user'
          />
          <main>
            <div className='info_pass'>
              <p>{t('newUserPassword.firstSentence')} </p>

              <p>
                {t('newUserPassword.secondSentence')}
                <br />
                <strong>{t('newUserPassword.thirdSentence')}</strong>
              </p>
            </div>
            <InputComponent
              fullWidth
              type='password'
              id='NewUserPassword'
              label={t('newUserPassword.label-newPass')}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <InputComponent
              fullWidth
              type='password'
              id='confirmNewUserPassword'
              size='small'
              label={t('newUserPassword.label-confirmPass')}
              value={passwordAgain}
              onChange={(event) => setPasswordAgain(event.target.value)}
            />
            <div className='content_validation_pass'>
              <h3>{t('newUserPassword.fourthSentence')}</h3>
              <PasswordChecklist
                className='validation_new_input'
                rules={[
                  'minLength',
                  'specialChar',
                  'number',
                  'capital',
                  'lowercase',
                ]}
                minLength={8}
                value={password}
                onChange={(isValid) => {
                  setIsValidNewPass(isValid);
                }}
                messages={{
                  minLength: t('newUserPassword.firstItemVerifyPass'),
                  specialChar: t('newUserPassword.secondItemVerifyPass'),
                  number: t('newUserPassword.thirdItemVerifyPass'),
                  lowercase: t('newUserPassword.fourthItemVerifyPass'),
                  capital: t('newUserPassword.fifthItemVerifyPass'),
                }}
              />
            </div>
          </main>

          <footer>
            <Button
              typeText
              variant='secondary'
              type='button'
              onClick={() => navigate('/')}
            >
              {t('newUserPassword.btn-cancel')}
            </Button>
            <Button typeText type='submit' disabled={!isValidateForm}>
              {t('newUserPassword.btn-apply')}
            </Button>
          </footer>
        </FormModalNewPass>
      </div>
    </ContainerChangePassword>
  );
};
