import { Handle, Position } from 'reactflow';
import { useAtom } from 'jotai';
import React, { memo, useCallback } from 'react';
import { PiMicrophoneSlashFill } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import { NodeContent, NodeHangUpContainer } from './styled';
import { shortNameRender } from '../../../../utils/shortNameRender';
import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { PanelNode } from '@/components/pages/FlowBuilder/components/PanelNode';

const HangUpNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();

  const [, setShowMenu] = useAtom(showMenuNodes);
  const [, setSelectedNode] = useAtom(selectedNode);

  const handleNode = useCallback(() => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  }, [id, data, setSelectedNode, setShowMenu]);

  return (
    <NodeHangUpContainer
      selected={selected}
      onDoubleClick={() => handleNode()}
      handler={data.handler}
    >
      <div className='panelNode'>
        <PanelNode data={data} id={id} />
      </div>

      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <PiMicrophoneSlashFill />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 6)}</h4>
            <span>{t('flowBuilder.flow.nodes.hangUp.label')}</span>
          </div>
        </div>
      </NodeContent>
    </NodeHangUpContainer>
  );
};

export const HangUpNode = memo(HangUpNodeComponent);
