import { errorToast } from '@/components/FormComponents/Toast';
import { convertGsmToWav } from '@/services/recordings/convertGsmToWav';
import { getActiveRecordings } from '@/services/recordings/getActiveRecordings';
import { dataQueueRecordings } from '@/store/Recordings/PanelRecords';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

export default function useActiveRecordings() {
  const { t } = useTranslation();
  const [, setDataRecording] = useAtom(dataQueueRecordings);

  const { mutate: mutateDataRecordings, isLoading: loadingDataRecordings } =
    useMutation({
      mutationFn: async (dataSearch) => {
        return await getActiveRecordings(dataSearch);
      },
      onSuccess: (data) => {
        setDataRecording(data);
      },
      onError: () => {
        return errorToast(
          t('activeRecordings.listActiveRecords.msg-error-list')
        );
      },
    });

  const { mutate: mutateAudioConverter, isLoading: loadingAudioConverter } =
    useMutation({
      mutationFn: async (urlGsm) => {
        return await convertGsmToWav(urlGsm);
      },
      onError: () => {
        return errorToast(
          t('activeRecordings.listActiveRecords.msg-error-list')
        );
      },
    });

  return {
    mutateDataRecordings,
    loadingDataRecordings,
    mutateAudioConverter,
    loadingAudioConverter,
  };
}
