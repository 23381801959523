import { motion } from 'framer-motion';
import { ContainerListReports } from './styled';
import { FiDownload } from 'react-icons/fi';
import { TableColorRow } from '@/components/Tables/TableColorRow';
import { FaRegCircleDot } from 'react-icons/fa6';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { saveAs } from 'file-saver';

import { CircularProgress } from '@mui/material';
import { useAtom } from 'jotai';
import { progressDownload } from '@/store/workersStore';
import { dataConfigSelected } from '@/store/Reports/countInListStore';
import { timeFormatToRequest } from '@/utils/formatsDateAndTime';

const getWorkerInstance = () => {
  if (!window.workerInstance) {
    window.workerInstance = new Worker(
      new URL(
        '../../../../../../../workers/workerDownload.js',
        import.meta.url
      ),
      {
        type: 'module',
      }
    );
  }
  return window.workerInstance;
};

export const ListCountReports = ({
  dataCountReport,
  filterDispositions,
  filterByType,
  mutateGetDataDownloadList,
}) => {
  const { t } = useTranslation();

  const [, setProgress] = useAtom(progressDownload);
  const [loadingWorker, setLoadingWorker] = useState(false);
  const [configSelected] = useAtom(dataConfigSelected);

  const [workerDownload, setWorkerDownload] = useState(null);

  useEffect(() => {
    const worker = getWorkerInstance();
    setWorkerDownload(worker);

    worker.onmessage = (event) => {
      if (event.data.type === 'error') {
        errorToast(t('reports.countInList.msg-error-downloaded'));
        setProgress(0);
        setLoadingWorker(false);
      }
      if (event.data.type === 'percent') {
        setProgress(event.data.percentage);
      }
      if (event.data.type === 'csv') {
        const blob = new Blob([event.data.csv], {
          type: 'text/csv;charset=utf-8;',
        });
        saveAs(blob, event.data.nameFile);
        setLoadingWorker(false);
      }
    };

    return () => {
      if (workerDownload) {
        workerDownload.terminate();
        workerDownload.onmessage = null;
        window.workerInstance = null;
      }
    };
  }, [workerDownload]);

  const [selectDisposition, setSelectDisposition] = useState(null);

  const filterByTypeList = (filterByType, dataTable) => {
    switch (filterByType) {
      case 'answered':
        return dataTable.filter((item) => item.atendida);
      case 'effective':
        return dataTable.filter((item) => item.contato_efetivo);
      case 'positive':
        return dataTable.filter((item) => item.contato_positivo);
      default:
        return dataTable;
    }
  };

  const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };

  const renderListFiltered = useCallback(
    (list) => {
      if (list) {
        const filterByDisposition = list.filter((data) =>
          data.cod_lig.toLowerCase().includes(filterDispositions.toLowerCase())
        );

        const filterByDescription = list.filter((data) =>
          data.descricao
            .toLowerCase()
            .includes(filterDispositions.toLowerCase())
        );

        const newArrFiltered = [...filterByDisposition, ...filterByDescription];
        return removeDuplicates(newArrFiltered);
      }
    },
    [filterDispositions]
  );

  const filterDataByType = filterByTypeList(filterByType, dataCountReport);
  const dataList = renderListFiltered(filterDataByType);

  const mountNumbers = useCallback((dataList, propCount) => {
    if (dataList) {
      const totalSum = dataList.reduce((accumulator, currentValue) => {
        return Number(accumulator) + Number(currentValue[propCount]);
      }, 0);
      return totalSum;
    }
    return 0;
  }, []);

  const renderDownloadData = (nameDisposition, data) => {
    try {
      setProgress(0);
      const timeToName = new Date().getTime().toString();
      const nameFile = `${nameDisposition}-${timeToName}.csv`;
      workerDownload.postMessage({
        type: 'sendFileCSV',
        dataJson: data,
        nameFile,
      });
    } catch {
      return errorToast(t('reports.countInList.msg-error-downloaded'));
    }
  };

  const handleDownloadDataDisposition = (dataList) => {
    if (!dataList) {
      return errorToast(t('reports.countInList.msg-error-downloaded'));
    }

    if (configSelected.list) {
      const dataRowListAll = {
        campaign: configSelected.campaign,
        list: configSelected.list,
        codLig: dataList.cod_lig,
      };

      const dataRowListByDate = {
        campaign: configSelected.campaign,
        list: configSelected.list,
        codLig: dataList.cod_lig,
        dtInicial: timeFormatToRequest(configSelected.dateInit),
        dtFinal: timeFormatToRequest(configSelected.dateEnd),
      };

      const checkDateToDownload = configSelected.hasFilterByDate
        ? dataRowListByDate
        : dataRowListAll;

      setLoadingWorker(true);
      setSelectDisposition(dataList.cod_lig);
      return mutateGetDataDownloadList(checkDateToDownload, {
        onSuccess: (data) => {
          if (data.length > 0) {
            const formatDateDisposition = data.map((item) => ({
              ...item,
              fone1: item.fone1.replace('\r', ''),
            }));

            return renderDownloadData(dataList.cod_lig, formatDateDisposition);
          }
          successToast(
            t('reports.countInList.countReports.table.no-data-download')
          );
          setLoadingWorker(false);
        },
        onError: () => {
          setLoadingWorker(false);
        },
      });
    }

    const dataRowCampaignAll = {
      campaign: configSelected.label,
      codLig: dataList.cod_lig,
    };

    const dataRowCampaignByDate = {
      campaign: configSelected.label,
      codLig: dataList.cod_lig,
      dtInicial: timeFormatToRequest(configSelected.dateInit),
      dtFinal: timeFormatToRequest(configSelected.dateEnd),
    };

    const checkDateToDownload = configSelected.hasFilterByDate
      ? dataRowCampaignByDate
      : dataRowCampaignAll;

    setLoadingWorker(true);
    setSelectDisposition(dataList.cod_lig);
    return mutateGetDataDownloadList(checkDateToDownload, {
      onSuccess: (data) => {
        if (data.length > 0) {
          const formatDateDisposition = data.map((item) => ({
            ...item,
            fone1: item.fone1.replace('\r', ''),
          }));

          return renderDownloadData(dataList.cod_lig, formatDateDisposition);
        }
        successToast(
          t('reports.countInList.countReports.table.no-data-download')
        );
        setLoadingWorker(false);
      },
      onError: () => {
        setLoadingWorker(false);
      },
    });
  };

  const handleDownloadAllData = () => {
    setLoadingWorker(true);
    const dataAllData = {
      campaign: configSelected.campaign ?? configSelected.label,
      list: configSelected.list ?? '',
      codLig: '',
      dtInicial: timeFormatToRequest(configSelected.dateInit),
      dtFinal: timeFormatToRequest(configSelected.dateEnd),
    };
    setSelectDisposition('allData');
    mutateGetDataDownloadList(dataAllData, {
      onSuccess: (data) => {
        if (data.length > 0) {
          const formatDateDisposition = data.map((item) => ({
            ...item,
            fone1: item.fone1.replace('\r', ''),
          }));

          return renderDownloadData(
            configSelected.campaign ?? configSelected.label,
            formatDateDisposition
          );
        }
        successToast(
          t('reports.countInList.countReports.table.no-data-download')
        );
        setLoadingWorker(false);
      },
    });
  };

  const columnListCounts = [
    {
      Header: '  ',
      Footer: '',
      accessor: 'type',
      Cell: ({ row }) => {
        if (
          row.original.atendida ||
          row.original.contato_efetivo ||
          row.original.contato_positivo
        ) {
          return <FaRegCircleDot />;
        }
        return;
      },
    },
    {
      Header: t('reports.countInList.countReports.table.th-disposition'),
      Footer: '',
      accessor: 'cod_lig',
    },
    {
      Header: t('reports.countInList.countReports.table.th-description'),
      Footer: 'Total',
      accessor: 'descricao',
    },
    {
      Header: t('reports.countInList.countReports.table.th-quantityNumbers'),
      Footer: () => {
        const total = mountNumbers(dataList, 'qtd');
        return `${total} ${t(
          'reports.countInList.countReports.table.tf-numbers'
        )}`;
      },
      accessor: 'qtd',
    },
    {
      Header: t('reports.countInList.countReports.table.th-attempts'),
      Footer: () => {
        const total = mountNumbers(dataList, 'qtdTentativa');
        return `${total} ${t(
          'reports.countInList.countReports.table.tf-attempts'
        )}`;
      },
      accessor: 'qtdTentativa',
    },
    {
      Header: 'Download',
      Footer: () => (
        <div className='content-download-all'>
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => handleDownloadAllData()}
          >
            {loadingWorker && selectDisposition === 'allData' ? (
              <CircularProgress size={13} />
            ) : (
              <FiDownload />
            )}
          </motion.button>
        </div>
      ),
      Cell: ({ row }) => (
        <div className='editRow'>
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => handleDownloadDataDisposition(row.original)}
            disabled={
              loadingWorker && selectDisposition !== row.original.cod_lig
            }
          >
            {loadingWorker && selectDisposition === row.original.cod_lig ? (
              <CircularProgress size={13} />
            ) : (
              <FiDownload />
            )}
          </motion.button>
        </div>
      ),
    },
  ];

  return (
    <ContainerListReports>
      <TableColorRow
        dataColumns={columnListCounts}
        dataTable={dataList}
        textNoData={t('reports.countInList.countReports.table.no-data')}
      />
    </ContainerListReports>
  );
};
