import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export default function useFormControl(dataSchema, defaultValues = null) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
    getValues,
    setValue,
    resetField,
    watch,
    clearErrors,
  } = useForm({
    defaultValues,
    resolver: yupResolver(dataSchema),
  });

  return {
    handleSubmit,
    control,
    errors,
    setError,
    clearErrors,
    reset,
    getValues,
    resetField,
    watch,
    setValue,
  };
}
