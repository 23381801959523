import styled, { css } from 'styled-components';

export const ContainerDescription = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.containers.baseContainer};
  ${(props) =>
    props.variant === 'secondary' &&
    css`
      background-color: #80808033;
    `}
  padding: 1.4rem;
  border-radius: 3px;
  span {
    color: ${(props) => props.theme.colors.typography.baseText};
    font-size: 0.9rem;
  }

  @media (max-width: 1440px) {
    padding: 0.85rem;
    span {
      font-size: 0.85rem;
    }
  }
`;
