import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerStatusDispositionsMenu = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;

  h3 {
    color: ${(props) => props.theme.colors.typography.baseText}AA;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 400 !important;
  }

  div {
    span {
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }

  .list-items-content {
    height: 44vh;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const ItemConfigDisposition = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  span {
    font-size: 0.9rem;
    padding-top: 1px;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .unusedSpan {
    color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  .usedItem {
    color: ${(props) => props.theme.colors.success};
  }

  .unusedItem {
    color: ${(props) => props.theme.colors.active};
  }
`;
