import { apiData } from '../api';

export async function getAgentInfo(dataStateCall) {
  const { extension, queue } = dataStateCall;
  try {
    const { data } = await apiData.post('/agent/getAgent', {
      extension,
      queue,
    });

    return data.data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
