import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerPanelCallEfforts = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;

  .icon_handle_back {
    svg {
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }
  .date-panel-item {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
      color: ${(props) => props.theme.colors.typography.baseText}AA;
      font-size: 0.85rem;
      padding-top: 2px;
    }

    svg {
      color: ${(props) => props.theme.colors.typography.baseText}AA;
      font-size: 0.85rem;
    }
  }

  .itemPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
    span {
      text-transform: uppercase;
      font-weight: 600;
      color: ${(props) => props.theme.colors.typography.baseText};
      padding-top: 1px;
    }
  }
`;
