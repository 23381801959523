import { motion } from 'framer-motion';
import styled from 'styled-components';

export const AgentMonitorGridContainer = styled(motion.div)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: 1px 4px 10px -1px rgba(0, 0, 0, 0.4);
  padding: 10px;
  color: ${(props) => props.theme.colors.typography.baseText};
  border: 2px solid ${(props) => props.borderStatus};

  @media (max-width: 920px) {
    width: ${(props) => props.widthBox};
    min-width: 300px;
  }

  @media (max-width: 600px) {
    width: 100%;
    max-width: 100%;
  }
  .separator {
    margin: 10px 0;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      flex: 1;
      height: 1px;
      background: gray;
    }
  }

  border-radius: 5px;
`;

export const Agent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;

  > svg {
    size: 24px;
    margin-right: 2px;
    color: ${(props) => props.borderStatus};
  }

  > div {
    span {
      font-size: 0.75rem;
      font-weight: bold;
      color: ${(props) => props.theme.colors.typography.baseText};
      padding-top: 2px;
    }
  }
`;

export const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 50% 50%;
  grid-column-gap: 10px;
  grid-row-gap: 3px;
  width: 100%;

  div {
    svg {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }

  span {
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.typography.baseText};
    padding-top: 1.3px;
  }

  > div {
    display: flex;
    align-items: center;
    color: ${(props) => props.borderStatus};
  }
`;
