import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { useMutation, useQuery } from 'react-query';

import {
  dataOptionsClients,
  dataUsersAgents,
  stateRenderUsers,
} from '@store/UsersAgentsStore/userAgentsStore';
import { getUserList } from '@services/userAgents/usersList/getUserList';
import { createUserAgent } from '@services/userAgents/usersList/createUserAgent';

import { errorToast } from '@components/FormComponents/Toast';
import { updateUser } from '@/services/userAgents/usersList/updateUser';
import { getClients } from '@/services/clients/getClients';
// import { dataListClients } from '@/store/Clients';
import { createRoutingProfileUser } from '@/services/userAgents/routingProfile/createRoutingProfileUser';

export const useUsersAgents = (fetchClients = true) => {
  const { t } = useTranslation();
  const [, setDataUsersAgents] = useAtom(dataUsersAgents);
  const [stateRenderUsersAgents] = useAtom(stateRenderUsers);
  const [, setOptionsClients] = useAtom(dataOptionsClients);
  //   const [, setDataList] = useAtom(dataListClients);

  const { isLoading: isLoadingDataUsers, isFetching: fetchingUserList } =
    useQuery(
      'dataListUsers',
      async () => {
        return await getUserList();
      },
      {
        enabled: stateRenderUsersAgents === 0,
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: (data) => {
          setDataUsersAgents(data);
        },
        onError: (error) => {
          const errorMessage = error.message || JSON.stringify(error);
          const toastMessage =
            errorMessage === 'User is not SysAdmin!'
              ? null
              : errorToast(t('usersAgentsList.messages.errorGetUserList'));
          return toastMessage;
        },
      }
    );

  const { mutate: mutateCreateUserAgent, isLoading: loadingCreateUserAgent } =
    useMutation({
      mutationFn: async (dataCreate) => {
        return await createUserAgent(dataCreate);
      },

      onError: () => {
        errorToast(t('usersAgentsList.addUser.messages.errorMessage'));
      },
    });

  const { mutate: mutateUpdateUserAgent, isLoading: loadingUpdateUserAgent } =
    useMutation({
      mutationFn: async (dataCreate) => {
        return await updateUser(dataCreate);
      },
      onError: () => {
        errorToast(t('usersAgentsList.editUser.messages.errorMessage'));
      },
    });

  const {
    mutate: mutateAddUserRoutingProfile,
    isLoading: loadingAddUserProfileUser,
  } = useMutation({
    mutationFn: async (dataCreate) => {
      return await createRoutingProfileUser(dataCreate);
    },
  });

  const { mutate: mutateRemoveUserAgent, isLoading: loadingRemoveUserAgent } =
    useMutation({
      mutationFn: async (dataCreate) => {
        return await createUserAgent(dataCreate);
      },
      onError: () => {
        errorToast(t('usersAgentsList.removeUser.messages.errorMessage'));
      },
    });

  const { isLoading: isLoadingDataClients } = useQuery(
    'dataListClientsUsers',
    async () => {
      return await getClients();
    },
    {
      retry: false,
      enabled: stateRenderUsersAgents === 0 && fetchClients,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setOptionsClients(data);
      },
    }
  );

  return {
    isLoadingDataUsers,
    fetchingUserList,
    mutateCreateUserAgent,
    loadingCreateUserAgent,
    mutateUpdateUserAgent,
    loadingUpdateUserAgent,
    mutateRemoveUserAgent,
    loadingRemoveUserAgent,
    isLoadingDataClients,
    mutateAddUserRoutingProfile,
    loadingAddUserProfileUser,
  };
};
