import { motion } from 'framer-motion';
import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { templateSelected } from '@/store/FlowBuilder';
import { hiddenSidebar } from '@/store/NavigationDashboard';
import smLogoVoice from '@/assets/imgs/icon-sm-voicce.svg';
import {
  ContainerConfig,
  DescriptionFlow,
  FooterConfigForm,
  MainConfigForm,
} from './styled';

export const CreatedFlow = ({ changeStateFlow }) => {
  const { t } = useTranslation();

  const [template] = useAtom(templateSelected);
  const [, setIsVisibleSidebar] = useAtom(hiddenSidebar);

  const handleNextStage = () => {
    changeStateFlow(1);
    setIsVisibleSidebar(false);
  };

  const renderListData = (list) => {
    if (!list || list.length === 0) {
      return (
        <span>{t('flowBuilder.newFlow.stages.complete.label-noData')}</span>
      );
    }
    return (
      <ul>
        {list.map((item, i) => (
          <li key={i}>
            <img
              src={smLogoVoice}
              alt='voicce'
              width={'16px'}
              height={'16px'}
            />
            {item.name}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <ContainerConfig initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <MainConfigForm>
        <motion.div
          className='success-checkmark'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className='check-icon'>
            <span className='icon-line line-tip'></span>
            <span className='icon-line line-long'></span>
            <div className='icon-circle'></div>
            <div className='icon-fix'></div>
          </div>
        </motion.div>
        <Stack justifyContent={'center'} alignItems={'center'} spacing={2}>
          <p>
            <strong>{t('flowBuilder.newFlow.stages.complete.title')}</strong>
          </p>
        </Stack>
        <DescriptionFlow>
          <Stack width={'100%'} spacing={2}>
            <div className='item__screen'>
              <span>
                <strong>
                  {t('flowBuilder.newFlow.stages.complete.label-name')}:
                </strong>
              </span>
              <span>{template.nameIVR}</span>
            </div>
            <div className='item__screen'>
              <span>
                <strong>
                  {t('flowBuilder.newFlow.stages.complete.label-description')}:
                </strong>
              </span>
              <span>
                {template.descriptionIVR === 'No Description'
                  ? t('flowBuilder.newFlow.stages.complete.label-noDescription')
                  : template.descriptionIVR}
              </span>
            </div>
            <Divider />

            <div className='item__screen'>
              <span>
                <strong>
                  {t('flowBuilder.newFlow.stages.complete.label-vars')}:
                </strong>
              </span>
              <span></span>
              <div className='item__data_list'>
                {renderListData(template.variablesGlobal)}
              </div>
            </div>
            <Divider />

            <div className='item__screen'>
              <span>
                <strong>
                  {t('flowBuilder.newFlow.stages.complete.label-dispositions')}:
                </strong>
              </span>
              <span></span>
              <div className='item__data_list'>
                {renderListData(template.codeLigs)}
              </div>
            </div>
            <Divider />

            <div className='item__screen'>
              <span>
                <strong>Endpoints:</strong>
              </span>
              <span></span>
              <div className='item__data_list'>
                {renderListData(template.nlus)}
              </div>
            </div>
            <Divider />

            <div className='item__screen'>
              <span>
                <strong>
                  {t('flowBuilder.newFlow.stages.complete.label-trunks')}:
                </strong>
              </span>
              <span></span>
              <div className='item__data_list'>
                {renderListData(template.trunks)}
              </div>
            </div>
          </Stack>
        </DescriptionFlow>
      </MainConfigForm>
      <FooterConfigForm>
        <Button onClick={handleNextStage}>
          {t('flowBuilder.newFlow.stages.btn.btn-goToFlow')}
        </Button>
      </FooterConfigForm>
    </ContainerConfig>
  );
};
