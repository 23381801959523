import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormMenuNode, MainAddNode } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { FieldVariables } from './FieldVariables';
import { errorToast } from '@/components/FormComponents/Toast';
import { checkLabelExistiNode } from '@/components/pages/FlowBuilder/utils/checkNameNodes';
import { TitleMenuNode } from '../TitleMenuNode';
import { FooterNode } from '@/components/pages/FlowBuilder/components/FooterNode';
import { ItemListVars } from './ItemListVars';
import {
  focusedFieldArea,
  isSavedFlow,
  selectedNode,
  showMenuNodes,
  variablesList,
} from '@/store/FlowBuilder';

export const VariablesForm = ({ dataNodes }) => {
  const { t } = useTranslation();
  const [, setFocusedArea] = useAtom(focusedFieldArea);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [selected, setSelected] = useAtom(selectedNode);
  const [, setIsSaved] = useAtom(isSavedFlow);

  const { nodes, setNodes } = dataNodes;
  const { id, data } = selected;

  const [labelNode, setLabelNode] = useState('');
  const [labelNodeError, setLabelNodeError] = useState('');
  const [listVariables, setListVariables] = useAtom(variablesList);

  const [varSelected, setVarSelected] = useState(null);

  const validateLabelNode = (value) => {
    if (!value.trim()) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.variables.menu.msg-name-required')
      );
    } else if (value.length > 50) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.variables.menu.msg-name-length', {
          length: '50',
        })
      );
    } else if (!/^[_a-zA-Z0-9]*$/.test(value)) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.variables.menu.msg-name-letters')
      );
    } else {
      setLabelNodeError('');
    }
    setLabelNode(value);
  };

  useEffect(() => {
    if (data) {
      setLabelNode(data.label ? data.label : '');
    }
  }, [data]);

  const renderListVariables = (nodeId, list) => {
    const filteredVarsByNode = list.filter((item) => item.parent === nodeId);
    return filteredVarsByNode;
  };

  const closeMenu = () => {
    setShowMenu(false);
    setLabelNode('');
    setSelected(null);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const handleSubmitNewNode = (e) => {
    e.preventDefault();

    const checkLabel = checkLabelExistiNode(id, labelNode, nodes);

    if (checkLabel) {
      return errorToast(
        t('flowBuilder.flow.nodes.variables.menu.msg-name-used')
      );
    }

    if (labelNode === '') {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.variables.menu.msg-name-required')
      );
      return;
    }

    const filteredNode = nodes.map((node) =>
      node.id === id
        ? {
            ...node,
            data: {
              ...node.data,
              label: labelNode,
              listVariables: renderListVariables(node.id, listVariables),
              errorConnect: false,
            },
          }
        : node
    );
    setNodes(filteredNode);
    setIsSaved(false);
    closeMenu();
  };

  const handleRemoveVariable = (dataVarId) => {
    const checkPromptsNodes = nodes
      .filter((item) => item.data.handler === 'ttsVoicceLabs')
      .map((item) => item.data.prompt)
      .map((item) => item.split(' '))
      .flat();

    const checkConditionsNodes = nodes
      .filter((item) => item.data.handler === 'conditionalNode')
      .map((item) => `$${item.data.typeCondition.toLowerCase()}`);

    const checkDataApiNodes = nodes
      .filter((item) => item.data.handler === 'apiData')
      .map((item) => item.data.response.map((item) => `{{${item.name}}}`))
      .flat();

    const varsUsedInNodes = [
      ...checkPromptsNodes,
      ...checkConditionsNodes,
      ...checkDataApiNodes,
    ];

    const checkExistingVariables = listVariables
      .filter((item) => item.id === dataVarId)
      .map((item) => varsUsedInNodes.includes(`{{${item.name}}}`))
      .some((result) => result === true);

    if (checkExistingVariables) {
      errorToast('Used');
      return;
    }

    const removeItemList = listVariables.filter(
      (item) => item.id !== dataVarId
    );

    return setListVariables(removeItemList);
  };

  const handleEdit = (id) => {
    const itemSelectedToEdit = listVariables.filter((item) => item.id === id);

    if (itemSelectedToEdit.length) {
      setVarSelected(itemSelectedToEdit[0]);
    }

    return;
  };

  return (
    <FormMenuNode
      onClick={handleChildClick}
      onSubmit={handleSubmitNewNode}
      id='DispositionForm'
      name='DispositionForm'
    >
      <MainAddNode>
        <TitleMenuNode
          title={t('flowBuilder.flow.nodes.variables.menu.title')}
          description={t('flowBuilder.flow.nodes.variables.description')}
        />

        <InputComponent
          label={t('flowBuilder.flow.nodes.variables.menu.label-name')}
          value={labelNode}
          inputProps={{ maxLength: 50 }}
          onChange={(e) => validateLabelNode(e.target.value)}
          autoComplete='off'
          helperText={labelNodeError}
          error={Boolean(labelNodeError)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />

        <FieldVariables
          varSelected={varSelected}
          setVarSelected={setVarSelected}
          dataNodes={dataNodes}
          selectedId={id}
        />

        {/* <h4>{t('flowBuilder.flow.nodes.variables.menu.title-list-items')}</h4> */}
        <ItemListVars
          listVariables={listVariables}
          setListVariables={setListVariables}
          handleRemoveVariable={handleRemoveVariable}
          handleEdit={handleEdit}
          idSelected={id}
        />
      </MainAddNode>

      <FooterNode
        closeMenu={closeMenu}
        disabledSubmitBtn={Boolean(labelNodeError || labelNode === '')}
      />
    </FormMenuNode>
  );
};
