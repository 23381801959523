import { useAtom } from 'jotai';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import useFormControl from '@/hooks/useFormControl';
import { FormMenuNode, MainAddNode } from './styled';
import { TitleMenuNode } from '../TitleMenuNode';
import { FooterNode } from '@/components/pages/FlowBuilder/components/FooterNode';
import { StatusDispositionSelected } from './StatusDispositionSelected';
import { ControlAutoComplete } from '@/components/FormComponents/AutoCompleteSelect';
import {
  focusedFieldArea,
  isSavedFlow,
  selectedNode,
  showMenuNodes,
  templateSelected,
} from '@/store/FlowBuilder';

export const DispositionForm = ({ dataNodes }) => {
  const { t } = useTranslation();
  const [template] = useAtom(templateSelected);
  const { codeLigs } = template;
  const [, setFocusedArea] = useAtom(focusedFieldArea);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [selected, setSelected] = useAtom(selectedNode);
  const [, setIsSaved] = useAtom(isSavedFlow);
  const { nodes, setNodes } = dataNodes;
  const { id, data } = selected;

  const dispositionsMenuSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .object()
          .required(
            t('flowBuilder.flow.nodes.dispositions.menu.msg-code-required')
          )
          .test(
            'not-zero',
            t('flowBuilder.flow.nodes.dispositions.menu.msg-code-required'),
            (value) => !!value || value.value === ''
          ),
      }),
    []
  );

  const { handleSubmit, control, reset, errors, watch } = useFormControl(
    dispositionsMenuSchema
  );

  const currentDispositionSelected = watch('name', null);

  const renderOptionsList =
    codeLigs
      ?.map((item) => {
        return {
          label: `${item.name} - ${item.descricao}`,
          value: item.value,
        };
      })
      .concat({
        label: 'Select',
        value: '0',
      }) ?? [];

  useEffect(() => {
    const filteredItemDisposition = codeLigs.filter(
      (disposition) => disposition.name === data.label
    )[0];

    if (!data.label) {
      return reset({
        name: {
          label: `Select`,
          value: '0',
        },
      });
    }

    reset({
      name: {
        label: `${filteredItemDisposition?.name ?? ''} - ${
          filteredItemDisposition?.descricao ?? ''
        }`,
        value: data.label,
      },
    });
  }, [data, reset]);

  const closeMenu = () => {
    setShowMenu(false);
    setSelected(null);
    reset();
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const handleSubmitNewNode = (data) => {
    const filteredNode = nodes.map((node) =>
      node.id === id
        ? {
            ...node,
            data: {
              ...node.data,
              label: data.name.value,
              errorConnect: false,
            },
          }
        : node
    );
    setNodes(filteredNode);
    setIsSaved(false);
    closeMenu();
  };

  return (
    <FormMenuNode
      onClick={handleChildClick}
      onSubmit={handleSubmit(handleSubmitNewNode)}
      id='DispositionForm'
      name='DispositionForm'
    >
      <MainAddNode>
        <TitleMenuNode
          title={t('flowBuilder.flow.nodes.dispositions.menu.title')}
          description={t('flowBuilder.flow.nodes.dispositions.description')}
        />

        <div className='content-autocomplete'>
          <ControlAutoComplete
            control={control}
            nameControl={'name'}
            label={t('flowBuilder.flow.nodes.dispositions.menu.label-code')}
            optionsList={renderOptionsList}
            onFocus={() => setFocusedArea(true)}
            onBlur={() => setFocusedArea(false)}
            error={Boolean(errors.name)}
          />
        </div>

        <StatusDispositionSelected
          currentDispositionSelected={currentDispositionSelected?.value}
          dispositions={codeLigs}
        />
      </MainAddNode>

      <FooterNode
        closeMenu={closeMenu}
        disabledSubmitBtn={
          !currentDispositionSelected ||
          currentDispositionSelected?.value === '0'
        }
      />
    </FormMenuNode>
  );
};
