import styled from 'styled-components';

export const ContainerRemoveTenant = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
`;

export const FormRemoveTenant = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  p {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 30px !important;
  }
`;
