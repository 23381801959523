import { TableRoutingProfile } from './styled';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { MdClose, MdEdit } from 'react-icons/md';
import { useAtom } from 'jotai';
import { stateRenderRoutingProfiles } from '@/store/UsersAgentsStore/routingProfiles';
import { useLayoutEffect, useRef, useState } from 'react';
import useWindowDimensions from '@/hooks/useWidth';
import { TablePagination } from '@/components/Tables/TablePagination';
import { dataRoutesProfileSelected } from '@/store/UsersAgentsStore/userAgentsStore';
import { dataListClients } from '@/store/Clients';
import { DialogComponent } from '@/components/DialogComponent';
import { RemoveRoutingProfile } from '../RemoveRoutingProfile';
import { useRoutingProfile } from '@/hooks/UserAgents/useRoutingProfile';
import { usePermissionsRoutingProfile } from '../../../hooks/usePermissionsRoutingProfile';

export const TableRouting = ({ dataTable, isDataLoading }) => {
  const { t } = useTranslation();
  const { permissionsCreate, permissionsEdit, permissionsDelete } =
    usePermissionsRoutingProfile();
  const { mutateDeleteRoutingProfile, loadingDeleteRoutingProfile } =
    useRoutingProfile();

  const [dialogRemoveRoutingProfile, setDialogRemoveRoutingProfile] =
    useState(false);
  const [, setDataRouteSelected] = useAtom(dataRoutesProfileSelected);
  const [dataClientsList] = useAtom(dataListClients);
  const [, setCurrentRenderRoutingProfile] = useAtom(
    stateRenderRoutingProfiles
  );
  const { height, width } = useWindowDimensions();
  const tableRoutingRef = useRef(null);

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableRoutingRef) {
      const tableMaxWidth = tableRoutingRef.current.offsetHeight;
      const cellMinWidth = width < 500 ? 150 : 60;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, width]);

  const handleEditRoutingProfile = (dataEdit) => {
    setDataRouteSelected(dataEdit);
    setCurrentRenderRoutingProfile({ current: 2, previous: 1 });
  };

  const handleRemoveItem = (dataRemove) => {
    setDataRouteSelected(dataRemove);
    setDialogRemoveRoutingProfile(true);
  };

  const handleScreensProfile = (dataRoute) => {
    setDataRouteSelected(dataRoute);
    setCurrentRenderRoutingProfile({ current: 3, previous: 0 });
  };

  const renderNameClientRouting = (idRouting) => {
    const filterRouting = dataClientsList
      .filter((item) => item.id === idRouting)
      .map((item) => item.company_name)
      .toString();

    const checkNameRouting = filterRouting === '' ? '-' : filterRouting;
    return checkNameRouting;
  };

  const columnsRouters = [
    {
      Header: t('routingProfile.homeRoutingProfiles.table.th-first'),
      accessor: 'name',
      Cell: ({ row }) => {
        return (
          <div className='showScreensBtn'>
            <motion.button
              whileTap={{ scale: 0.98 }}
              onClick={() => handleScreensProfile(row.original)}
              disabled={!permissionsEdit || !permissionsCreate}
            >
              {row.original.name}
            </motion.button>
          </div>
        );
      },
    },
    {
      Header: t('routingProfile.homeRoutingProfiles.table.th-second'),
      accessor: 'description',
    },
    {
      Header: 'Client',
      accessor: 'client_id',
      Cell: ({ row }) => {
        return renderNameClientRouting(row.original.client_id);
      },
    },
    {
      Header: t('routingProfile.homeRoutingProfiles.table.th-fourth'),
      accessor: 'numberAgentsMaintained',
    },
    {
      Header: t('routingProfile.homeRoutingProfiles.table.th-fifth'),
      Cell: ({ row }) => {
        return (
          <div className='editRow'>
            <motion.button
              whileTap={{ scale: 0.98 }}
              disabled={!permissionsDelete}
              onClick={() => handleRemoveItem(row.original)}
            >
              <MdClose />
            </motion.button>
            <motion.button
              whileTap={{ scale: 0.98 }}
              onClick={() => handleEditRoutingProfile(row.original)}
              disabled={!permissionsEdit}
            >
              <MdEdit />
            </motion.button>
          </div>
        );
      },
    },
  ];

  return (
    <TableRoutingProfile ref={tableRoutingRef}>
      <TablePagination
        dataColumns={columnsRouters}
        dataTable={dataTable}
        textNoData={
          isDataLoading
            ? t('routingProfile.homeRoutingProfiles.table.th-loadList')
            : t('routingProfile.homeRoutingProfiles.table.noData')
        }
        rowsPerPageDisplay={heightTable}
      />
      <DialogComponent
        open={dialogRemoveRoutingProfile}
        onClose={() => setDialogRemoveRoutingProfile(false)}
        fullWidth={true}
      >
        <RemoveRoutingProfile
          closeDialog={() => setDialogRemoveRoutingProfile(false)}
          handleDeleteItem={mutateDeleteRoutingProfile}
          loadingDeleteItem={loadingDeleteRoutingProfile}
          goToScreens={handleScreensProfile}
        />
      </DialogComponent>
    </TableRoutingProfile>
  );
};
