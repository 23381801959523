import { useMutation } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { getRenderPromptFile } from '@/services/flowBuilder/getRenderPromptFile';
import { useTranslation } from 'react-i18next';

export const useFlowBuilder = () => {
  const { t } = useTranslation();
  const { mutate: mutateRenderPrompt, isLoading: loadingRenderPrompt } =
    useMutation({
      mutationFn: async (dataCreatePrompt) => {
        return await getRenderPromptFile(dataCreatePrompt);
      },

      onError: () => {
        errorToast(
          t('flowBuilder.flow.nodes.playPrompt.menu.msg-error-create')
        );
      },
    });

  return { mutateRenderPrompt, loadingRenderPrompt };
};
