import { TimePicker } from '@mui/x-date-pickers';
import styled from 'styled-components';

export const TimePickerStyled = styled(TimePicker)`
  width: 100%;
  user-select: none;

  .MuiInputBase-root,
  .MuiOutlinedInput-root,
  .MuiInputBase-colorPrimary,
  .MuiInputBase-formControl {
    max-height: 42px;
  }

  label {
    color: ${(props) => props.theme.colors.typography.baseText};
    font-size: 1rem;
    top: -6px;
  }

  > .MuiInputLabel-shrink {
    top: 0;
  }

  .MuiButtonBase-root .MuiMenuItem-gutters {
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .MuiInputBase-input {
    color: ${(props) => props.theme.colors.typography.baseText};

    &:disabled {
      opacity: 0.4;
    }
  }

  .Mui-focused {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }

  &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.typography.baseText};
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  svg {
    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;
