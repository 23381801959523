import { useTranslation } from 'react-i18next';
import { NoDataContainer } from './styled';
import { AiOutlineWarning } from 'react-icons/ai';

export const NoDataCard = () => {
  const { t } = useTranslation();
  return (
    <NoDataContainer>
      <AiOutlineWarning />
      <span>{t('dialing.span-noDataQueuesStatus')}</span>
    </NoDataContainer>
  );
};
