import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { motion } from 'framer-motion';
import * as yup from 'yup';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import { BsCheck2 } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import { FaCircle } from 'react-icons/fa';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { TablePagination } from '@/components/Tables/TablePagination';
import useFormControl from '@/hooks/useFormControl';
import useWindowDimensions from '@/hooks/useWidth';
import { ContentQueueCampaign } from './styled';
import { successToast } from '@/components/FormComponents/Toast';
import { StatusDataRow } from '@/components/Tables/StatusDataRow';
import { usePermissionsQueueCampaigns } from '../../hooks/usePermissionsQueueCampaigns';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const ListQueueCampaigns = ({
  dataTable,
  campaigns,
  mutateQueueCampaign,
}) => {
  const { t } = useTranslation();
  const { permissionsEdit } = usePermissionsQueueCampaigns();
  const renderTxt = (word) => {
    return t(`dialControl.queueCampaign.${word}`);
  };

  const { height } = useWindowDimensions();

  const tableCampaigns = useRef(null);
  const [selectedRow, setSelectedRow] = useState({});

  const updateStatusQueueSchema = yup.object().shape({
    campaignQueue: yup.string(),
  });

  const { handleSubmit, control, reset } = useFormControl(
    updateStatusQueueSchema
  );

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setSelectedRow({});
    }
  }, []);

  useEffect(() => {
    return () => setSelectedRow({});
  }, [dataTable]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const handleEdit = useCallback((selectedRow) => {
    setSelectedRow(selectedRow);
    reset();
  }, []);

  const handleUpdateQueueCampaign = (data) => {
    const { original } = selectedRow;

    const dataUpdateSystemQueue = {
      queue: original.fila,
      campaign: data.campaignQueue,
    };

    mutateQueueCampaign(dataUpdateSystemQueue, {
      onSuccess: () => {
        successToast(renderTxt('msg-successUpdate'));
        setSelectedRow({});
      },
    });
  };

  const handleCancelEdit = () => {
    setSelectedRow({});
  };

  const verifyLengthSelected = Object.keys(selectedRow).length;

  const renderStatusQueue = (statusQueue) => {
    switch (statusQueue) {
      case 'VAZIO':
        return (
          <div className='itemTable'>
            <FaCircle size={'8px'} className='colorEmpty' />
            <span>{statusQueue}</span>
          </div>
        );

      case 'FECHADO':
        return (
          <div className='itemTable'>
            <FaCircle size={'8px'} className='colorClosed' />
            <span>{statusQueue}</span>
          </div>
        );

      case 'MANUTENCAO':
        return (
          <div className='itemTable'>
            <FaCircle size={'8px'} className='colorClosed' />
            <span>{statusQueue}</span>
          </div>
        );

      case 'DISCANDO':
        return (
          <div className='itemTable'>
            <FaCircle size={'8px'} className='colorDiscing' />
            <span>{statusQueue}</span>
          </div>
        );

      default:
        return (
          <div className='itemTable'>
            <FaCircle size={'8px'} className='colorEmpty' />
            <span>{statusQueue}</span>
          </div>
        );
    }
  };

  const columnsSystemQueue = [
    {
      Header: renderTxt('tableControl.th-active'),
      accessor: 'ativo',
      Cell: ({ row }) => <StatusDataRow status={row.original.ativo} />,
    },

    {
      Header: renderTxt('tableControl.th-third'),
      accessor: 'estado',
      Cell: ({ row }) => {
        return <div className=''>{renderStatusQueue(row.original.estado)}</div>;
      },
    },

    {
      Header: renderTxt('tableControl.th-first'),
      accessor: 'fila',
    },

    {
      Header: renderTxt('tableControl.th-second'),
      accessor: 'campanha',
      Cell: ({ row }) => {
        if (selectedRow.id !== row.id) {
          return (
            <span
              className={
                verifyLengthSelected > 0
                  ? selectedRow.id !== row.id
                    ? ''
                    : 'selected-row'
                  : ''
              }
            >
              {row.original.campanha}
            </span>
          );
        }
        return (
          <motion.div
            initial={{ opacity: 0, translateX: '-80px' }}
            animate={{ opacity: 1, translateX: '0px' }}
          >
            <ControlSelect
              control={control}
              nameControl={'campaignQueue'}
              defaultValue={() => row.original.campanha}
              sx={{ maxWidth: '500px' }}
            >
              {campaigns.length > 0 &&
                campaigns.map((item, i) => (
                  <ItemSelect key={i} value={item.campanha}>
                    {item.campanha}
                  </ItemSelect>
                ))}
            </ControlSelect>
          </motion.div>
        );
      },
    },

    {
      Header: renderTxt('tableControl.th-fourth'),
      Cell: ({ row }) => {
        if (selectedRow.id !== row.id) {
          return (
            <Button
              onClick={() => handleEdit(row)}
              disabled={
                Boolean(verifyLengthSelected && selectedRow.id !== row.id) ||
                !permissionsEdit
              }
            >
              <MdEdit />
            </Button>
          );
        }
        return (
          <motion.div
            initial={{ opacity: 0, translateX: '-80px' }}
            animate={{ opacity: 1, translateX: '0px' }}
          >
            <Stack direction={'row'} spacing={1}>
              <Button onClick={handleCancelEdit} variant='error'>
                <IoClose />
              </Button>
              <Button
                variant='success'
                type='submit'
                disabled={!permissionsEdit}
              >
                <BsCheck2 size={16} />
              </Button>
            </Stack>
          </motion.div>
        );
      },
    },
  ];

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableCampaigns) {
      const tableMaxWidth = tableCampaigns.current.offsetHeight;
      const cellMinWidth = 55;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, tableCampaigns]);

  return (
    <form onSubmit={handleSubmit(handleUpdateQueueCampaign)}>
      <ContentQueueCampaign ref={tableCampaigns}>
        <TablePagination
          dataColumns={columnsSystemQueue}
          dataTable={dataTable}
          textNoData={renderTxt('tableControl.no-data')}
          rowsPerPageDisplay={heightTable}
          selectedRow={selectedRow?.id ?? null}
          hasSelected={verifyLengthSelected}
        />
      </ContentQueueCampaign>
    </form>
  );
};
