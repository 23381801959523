import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { addMinutes } from 'date-fns';
import { Stack } from '@mui/material';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { FormSchedules } from './styled';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { ControlTimePicker } from '@/components/FormComponents/TimePicker';
import useFormControl from '@/hooks/useFormControl';
import { errorToast } from '@/components/FormComponents/Toast';
import { WeekDaysSelector } from '@/components/FormComponents/WeekDaysSelector';
import { newScheduleList } from '@/store/ScheduleStore/SchedulerList';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const SchedulesDetails = ({ controlStage }) => {
  const { t } = useTranslation();
  const [, setNewSchedule] = useAtom(newScheduleList);

  const renderTxt = (word) =>
    t(`dialControl.schedulesList.createSchedule.schedulesDetails.${word}`);

  const createNewScheduleSchema = yup.object().shape({
    timeInit: yup
      .date()
      .required(renderTxt('errors.timeInitRequired'))
      .test(
        'is-timeInit-before-timeEnd',
        'timeInitGreaterThanTimeEnd',
        function (value) {
          const { timeEnd } = this.parent;
          return !timeEnd || value <= timeEnd;
        }
      ),
    timeEnd: yup
      .date()
      .required(renderTxt('errors.timeEndRequired'))
      .test(
        'is-timeInit-before-timeEnd',
        'timeEndGreaterThanTimeEnd',
        function (value) {
          const { timeInit } = this.parent;
          return !timeInit || value >= timeInit;
        }
      ),
    positionList: yup.string().required(renderTxt('errors.positionList')),
    positionQueue: yup.string().required(renderTxt('errors.positionQueue')),
  });

  const { handleSubmit, control, errors } = useFormControl(
    createNewScheduleSchema
  );

  const [selectedDays, setSelectedDays] = useState([]);
  const [errorSelectedDays, setErrorSelectedDays] = useState(false);

  useEffect(() => {
    if (errorSelectedDays && selectedDays.length > 0) {
      setErrorSelectedDays(false);
    }
    if (Boolean(Object.keys(errors).length > 0) && selectedDays.length === 0) {
      setErrorSelectedDays(true);
    }

    if (errorSelectedDays && Object.keys(errors).length > 0) {
      errorToast(renderTxt('errors.generalError'));
    }
  }, [selectedDays, errors, errorSelectedDays]);

  const handleNextStage = (data) => {
    if (selectedDays.length === 0) {
      return setErrorSelectedDays(true);
    }

    const dataSchedule = {
      initTime: data.timeInit.getTime(),
      endTime: data.timeEnd.getTime(),
      positionPhone: data.positionQueue,
      position: data.positionList,
      dayOfTheWeek: selectedDays,
    };

    setNewSchedule((prevState) => ({ ...prevState, ...dataSchedule }));
    controlStage(2);
  };

  const handlePreviousStage = () => {
    controlStage(0);
  };

  return (
    <FormSchedules
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onSubmit={handleSubmit(handleNextStage)}
    >
      <div className='main'>
        <DescriptionInformation textDescription={renderTxt('description')} />

        <Stack direction={'row'} spacing={2}>
          <ControlTimePicker
            control={control}
            nameControl={'timeInit'}
            defaultValue={addMinutes(new Date(), 10)}
            error={errors.timeInit}
            label={renderTxt('label-init')}
          />

          <ControlTimePicker
            control={control}
            nameControl={'timeEnd'}
            defaultValue={addMinutes(new Date(), 70)}
            error={errors.timeEnd}
            label={renderTxt('label-concluded')}
          />
        </Stack>

        <Stack direction={'column'} spacing={3}>
          <ControlSelect
            control={control}
            nameControl={'positionList'}
            label={renderTxt('label-position-list')}
            error={Boolean(errors.positionList)}
          >
            <ItemSelect value={0} key={0} disabled>
              {renderTxt('option-position')}
            </ItemSelect>
            {new Array(10).fill(null).map((_, i) => (
              <ItemSelect value={i + 1} key={i + 1}>
                {i + 1}
              </ItemSelect>
            ))}
          </ControlSelect>

          <ControlSelect
            control={control}
            nameControl={'positionQueue'}
            label={renderTxt('label-position-queue')}
            error={Boolean(errors.positionQueue)}
          >
            <ItemSelect value={0} key={0} disabled>
              {renderTxt('option-position')}
            </ItemSelect>
            {new Array(10).fill(null).map((_, i) => (
              <ItemSelect value={i + 1} key={i + 1}>
                {i + 1}
              </ItemSelect>
            ))}
          </ControlSelect>
        </Stack>

        <WeekDaysSelector
          errorSelectedDays={errorSelectedDays}
          selectedDays={selectedDays}
          setSelectedDays={setSelectedDays}
        />
      </div>

      <div className='footer'>
        <Button typeText variant='secondary' onClick={handlePreviousStage}>
          {renderTxt('btn-cancel')}
        </Button>
        <Button
          typeText
          type='submit'
          disabled={Boolean(Object.keys(errors).length > 0)}
        >
          {renderTxt('btn-next')}
        </Button>
      </div>
    </FormSchedules>
  );
};
