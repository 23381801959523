import { useId } from 'react';
import { EditDetailRouting } from './EditDetailRouting';
import { ScreensRouting } from '../ScreensRouting';
import { useAtom } from 'jotai';
import { currentStageEditFlow } from '@/store/UsersAgentsStore/routingProfiles';

export const EditRoutingProfile = () => {
  const [stageEditFlow, setStageEditFlow] = useAtom(currentStageEditFlow);

  const changeStageEditFlow = (stageEdit) => {
    return setStageEditFlow(stageEdit);
  };

  const flowEditRouting = [
    <EditDetailRouting
      key={useId()}
      changeStageEditFlow={changeStageEditFlow}
    />,
    <ScreensRouting key={useId()} origin={'editRouting'} />,
  ];

  return flowEditRouting[stageEditFlow];
};
