import { Handle, Position } from 'reactflow';
import { useAtom } from 'jotai';
import React, { memo, useCallback, useMemo } from 'react';
import { MdShapeLine } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { shortNameRender } from '../../../../utils/shortNameRender';
import { FlexBoxRow } from '../styled';
import { NodeConditionContainer, NodeContent } from './styled';
import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { PanelNode } from '@/components/pages/FlowBuilder/components/PanelNode';

const ConditionNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();

  const [, setShowMenu] = useAtom(showMenuNodes);
  const [, setSelectedNode] = useAtom(selectedNode);

  const handleNode = useCallback(() => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  }, [id, data, setSelectedNode, setShowMenu]);

  const heightNode = useMemo(() => {
    const totalItems = data?.conditionsList?.length ?? 0;
    const heightItem = 28;
    return `${totalItems <= 1 ? 140 : 130 + totalItems * heightItem}px`;
  }, [data?.conditionsList]);

  return (
    <NodeConditionContainer
      selected={selected}
      errorConnect={data.errorConnect ? 1 : 0}
      onDoubleClick={() => handleNode()}
      heighNode={heightNode}
      handler={data.handler}
    >
      <PanelNode data={data} id={id} />
      <Handle type='target' position={Position.Left} />

      <NodeContent>
        <div className='header'>
          <MdShapeLine size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
            <span>{t('flowBuilder.flow.nodes.conditions.label')}</span>
          </div>
        </div>
        <div className='main'>
          <FlexBoxRow>
            <span>
              {t('flowBuilder.flow.nodes.conditions.menu.label-condition')}:{' '}
            </span>
            <span>
              <strong>{data.typeCondition}</strong>
            </span>
          </FlexBoxRow>
        </div>
      </NodeContent>
    </NodeConditionContainer>
  );
};

export const ConditionNode = memo(ConditionNodeComponent);
