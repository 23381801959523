import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { IoWarningOutline } from 'react-icons/io5';
import styled from 'styled-components';

export const ContainerNoData = styled(motion.div)`
  width: 100%;
  height: 100px;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 1px 2px 4px -1px rgba(0, 0, 0, 0.2);
  gap: 4px;

  .detail-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    svg {
      color: ${(props) => props.theme.colors.defaultColors.orange};
    }

    span {
      padding-top: 1px;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }

  .btn-add-scheduler {
    span {
      font-size: 0.85rem;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
    button {
      all: unset;
      cursor: pointer;
      &:hover {
        span {
          text-decoration: underline;
          color: ${(props) => props.theme.colors.defaultColors.blue};
        }
      }
    }
  }
`;

export const NoScheduling = ({ controlStage }) => {
  const { t } = useTranslation();

  const handleStage = () => {
    controlStage.toStage(2);
  };

  return (
    <ContainerNoData
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className='detail-content'>
        <IoWarningOutline />
        <span>
          {t('dialControl.schedulesList.listSchedules.label-no-data-schedules')}
        </span>
      </div>
      <div className='btn-add-scheduler'>
        <button onClick={handleStage}>
          <span>
            {t('dialControl.schedulesList.listSchedules.label-btn-click')}
          </span>
        </button>
        <span>
          , {t('dialControl.schedulesList.listSchedules.label-btn-sentence')}
        </span>
      </div>
    </ContainerNoData>
  );
};
