import { useLayoutEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { ContainerTable } from './styled';
import useWindowDimensions from '@/hooks/useWidth';
import { TablePagination } from '@/components/Tables/TablePagination';
import { MdEdit } from 'react-icons/md';
import { Box, Tooltip } from '@mui/material';
import { IoCloseOutline, IoLinkOutline } from 'react-icons/io5';
import { IoIosMenu } from 'react-icons/io';
import { ConfirmationComponent } from '@/components/layout/ConfirmationComponent';
import { TbMenu, TbTextCaption } from 'react-icons/tb';
import { useAtom } from 'jotai';
import { currentFlowScreens, dataSelectedScreens } from '@/store/Screens';
import { dataListScreens } from '@/store/NavigationDashboard';
import { useTranslation } from 'react-i18next';
import { verifyIcon } from '@/components/pages/Dashboard/SectionItemNavigation/iconVerify';
import { shortNameRender } from '@/utils/shortNameRender';
import { StatusDataRow } from '@/components/Tables/StatusDataRow';
import { usePermissionsScreens } from '../../../hooks/usePermissionsScreens';
import { useRenderNamesScreens } from '../../../hooks/useRenderNamesScreens';

export const TableScreens = ({ dataTable, mutateDeleteScreen }) => {
  const { t } = useTranslation();
  const { renderNameItem } = useRenderNamesScreens();
  const { permissionsEdit, permissionsDelete } = usePermissionsScreens();
  const [, setStateRenderScreens] = useAtom(currentFlowScreens);
  const [screenSelected, setScreenSelected] = useAtom(dataSelectedScreens);
  const [listScreens] = useAtom(dataListScreens);
  const { height, width } = useWindowDimensions();

  const tableUsersRef = useRef(null);

  const [openConfirm, setConfirm] = useState(false);
  const [heightTable, setHeightTable] = useState(0);

  const handleRemoveScreen = (dataRemove) => {
    setScreenSelected(dataRemove);
    setConfirm(true);
  };

  const actionRemoveScreen = () => {
    const { id } = screenSelected;
    mutateDeleteScreen(id);
  };

  const handleEditScreen = (data) => {
    setScreenSelected(data);
    setStateRenderScreens(2);
  };

  useLayoutEffect(() => {
    if (tableUsersRef) {
      const tableMaxWidth = tableUsersRef.current.offsetHeight;
      const cellMinWidth = width < 500 ? 200 : 60;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, width]);

  const renderIconType = (type) => {
    switch (type) {
      case 'menu':
        return {
          name: 'Menu',
          icon: <IoIosMenu />,
        };
      case 'titleMenu':
        return {
          name: 'Title',
          icon: <TbTextCaption />,
        };
      case 'submenu':
        return {
          name: 'Sub menu',
          icon: <TbMenu size={10} />,
        };
      case 'iframe':
        return {
          name: 'Iframe',
          icon: <IoLinkOutline />,
        };
      default:
        return '-';
    }
  };

  const renderIdTable = (idComponent, listFilter) => {
    if (idComponent === '0') {
      return '-';
    }

    const filterScreen = listFilter
      .filter((item) => item.id === idComponent)
      .map((item) => item.name)
      .toString();

    return filterScreen ? (
      filterScreen
    ) : (
      <Tooltip title={idComponent}>
        <span>{shortNameRender(idComponent, 8)}</span>
      </Tooltip>
    );
  };

  const columnsUserAgents = [
    {
      Header: t('screens.table.th-status'),
      accessor: 'isActive',
      Cell: ({ row }) => <StatusDataRow status={row.original.isActive} />,
    },
    {
      Header: t('screens.table.th-name'),
      accessor: 'name',
      Cell: ({ row }) => <strong>{renderNameItem(row.original.name)}</strong>,
    },
    {
      Header: t('screens.table.th-slug'),
      accessor: 'slug',
      Cell: ({ row }) => {
        return (
          renderIconType(row.original.slug) && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              gap='4px'
            >
              {row.original.hasExternalUrl ? (
                <IoLinkOutline />
              ) : (
                renderIconType(row.original.slug).icon
              )}
              <span>{renderIconType(row.original.slug).name}</span>
            </Box>
          )
        );
      },
    },
    {
      Header: t('screens.table.th-area'),
      accessor: 'area',
      Cell: ({ row }) => {
        return row.original.area === '0' || row.original.area === ''
          ? '-'
          : row.original.area;
      },
    },
    {
      Header: 'Href',
      accessor: 'href',
      Cell: ({ row }) => {
        return !row.original.href || row.original.href === '0' ? (
          '-'
        ) : row.original.hasExternalUrl ? (
          <Tooltip title={row.original.href}>
            <span>{shortNameRender(row.original.href, 20)}</span>
          </Tooltip>
        ) : (
          renderIdTable(row.original.href, listScreens)
        );
      },
    },
    {
      Header: 'Parent',
      accessor: 'parent_id',
      Cell: ({ row }) =>
        !row.original.parent_id
          ? '-'
          : renderIdTable(row.original.parent_id, dataTable),
    },

    {
      Header: t('screens.table.th-icon'),
      accessor: 'icon',
      Cell: ({ row }) => {
        return row.original.icon && verifyIcon(row.original.icon) ? (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            gap='4px'
          >
            {verifyIcon(row.original.icon)}
            <span>{row.original.icon}</span>
          </Box>
        ) : (
          '-'
        );
      },
    },

    {
      Header: t('screens.table.th-description'),
      accessor: 'description',
      Cell: ({ row }) => {
        return (
          <Tooltip title={row.original.description}>
            <span>{shortNameRender(row.original.description, 16)}</span>
          </Tooltip>
        );
      },
    },
    {
      Header: t('screens.table.th-action'),
      Cell: ({ row }) => (
        <div className='editRow'>
          <motion.button
            title={t('screens.table.title-btn-remove')}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleRemoveScreen(row.original)}
            disabled={!permissionsDelete}
          >
            <IoCloseOutline />
          </motion.button>
          <motion.button
            title={t('screens.table.title-btn-edit')}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleEditScreen(row.original)}
            disabled={!permissionsEdit}
          >
            <MdEdit />
          </motion.button>
        </div>
      ),
    },
  ];

  const renderMessage = `Deseja remover a tela ${screenSelected?.name ?? '-'}?`;

  return (
    <ContainerTable ref={tableUsersRef}>
      <TablePagination
        dataColumns={columnsUserAgents}
        dataTable={dataTable}
        textNoData={t('screens.table.no-data')}
        rowsPerPageDisplay={heightTable}
      />
      <ConfirmationComponent
        open={openConfirm}
        messageConfirm={renderMessage}
        close={() => setConfirm(false)}
        actionConfirm={actionRemoveScreen}
      />
    </ContainerTable>
  );
};
