import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactFlow, {
  useEdgesState,
  useNodesState,
  addEdge,
  MarkerType,
  ReactFlowProvider,
} from 'reactflow';
import { errorToast } from '@/components/FormComponents/Toast';
import { hasSavedTemplateMailing } from '@/store/LoadList';

export const useFlowMailing = (setHasErrorColumnsFromCsv) => {
  const { t } = useTranslation();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    return () => {
      setNodes([]);
      setEdges([]);
    };
  }, []);

  useEffect(() => {
    const checkErrors = nodes.filter(
      (node) => !node.data.hasColumnInCsv && node.type === 'nodeFromCsv'
    );
    checkErrors.length > 0
      ? setHasErrorColumnsFromCsv(true)
      : setHasErrorColumnsFromCsv(false);
  }, [nodes]);

  const [, setSaveFlowMailing] = useAtom(hasSavedTemplateMailing);

  const onConnect = useCallback(
    (params) => {
      const checkNodeConnect = nodes.filter(
        (node) => node.id === params.source
      );

      if (checkNodeConnect.length > 0) {
        if (!checkNodeConnect[0].data.hasColumnInCsv) {
          return errorToast(t('listLoader.importMailing.flow.msg-tooltip-all'));
        }
      }

      const hasConnectedNode = edges.filter(
        (edge) => edge.target === params.target
      );

      if (hasConnectedNode.length > 0) {
        return errorToast(
          t('listLoader.importMailing.flow.msg-error-hasConnected')
        );
      }

      setEdges((eds) => {
        return addEdge(
          {
            ...params,
            type: 'customEdge',
            animated: false,
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 18,
              height: 18,
            },
          },
          eds
        );
      });
      setSaveFlowMailing(false);
    },
    [nodes, edges]
  );

  const onEdgeClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return {
    ReactFlow,
    ReactFlowProvider,
    nodes,
    edges,
    onConnect,
    onEdgesChange,
    onEdgeClick,
    onNodesChange,
    setNodes,
    setEdges,
  };
};
