import { format } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';

export const timeFormatEn = (timeToFormat) => {
  if (!timeToFormat) {
    return '-';
  } else {
    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      // timeZone: 'UTF',
    };
    return new Intl.DateTimeFormat('pt-BR', {
      ...timeOptions,
    }).format(new Date(timeToFormat));
  }
};

export const timeFormatNoSecEn = (timeToFormat) => {
  if (!timeToFormat) {
    return '-';
  } else {
    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      // timeZone: 'UTF',
    };
    return new Intl.DateTimeFormat('pt-BR', {
      ...timeOptions,
    }).format(new Date(timeToFormat));
  }
};

export const timeFormatToRequest = (timeToFormat) => {
  const date = new Date(timeToFormat);
  const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  return formattedDate;
};

export const dateFormatEn = (dateToFormat) => {
  if (!dateToFormat) {
    return '-';
  } else {
    const timeOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    return new Intl.DateTimeFormat('en-us', {
      timeZone: 'UTC',
      ...timeOptions,
    }).format(new Date(dateToFormat));
  }
};

export const formatDate = (timestamp) => {
  const getLocale = localStorage.getItem('lang');
  const generateLocale = getLocale === 'en' ? enUS : ptBR;
  return format(new Date(timestamp), 'P', { locale: generateLocale });
};

export const formatTime = (timestamp) => {
  const getLocale = localStorage.getItem('lang');
  const generateLocale = getLocale === 'en' ? enUS : ptBR;
  return format(new Date(timestamp), 'HH:mm', { locale: generateLocale });
};

export const renderDate = (currentDate) => {
  const getLocale = localStorage.getItem('lang');
  const generateLocale = getLocale === 'en' ? enUS : ptBR;
  if (currentDate) {
    return format(new Date(currentDate), 'Pp', { locale: generateLocale });
  }
  return format(new Date(), 'Pp', { locale: generateLocale });
};
