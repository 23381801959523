import { atom } from 'jotai';

export const dataDashboardCharts = atom({
  queuesDashboard: [],
  funnelAttendance: [],
  mailingAnalysis: [],
  callPerHour: [],
  byRegion: [],
});

export const selectedQueueToSearchCharts = atom('0');
