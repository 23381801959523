import { Stack } from '@mui/system';
import { ContainerStatus, ContainerStatusQueue } from '../styled';
import {
  ContainerRadioInLine,
  StyledFormControlLabel2,
  StyledRadio,
  StyledRadioGroupInLine,
} from '@/components/FormComponents/RadioComponent/styled';
import { useAtom } from 'jotai';
import { statusQueueSelected } from '@/store/DialingStore';
import { useDialingQueues } from '@/hooks/Dialing/useDialingQueues';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { user } from '@/store/AuthenticatorStore';

const PanelStatusQueueComponent = () => {
  const { t } = useTranslation();
  const [currentUser] = useAtom(user);
  const { isSysAdmin } = currentUser.userRole;

  const { mutateChangeStatus, dataStatus } = useDialingQueues();

  const [statusQueueRadio, setStatusQueueRadio] = useAtom(statusQueueSelected);

  const generateTextStateQueue = (stateQueue) => {
    if (isSysAdmin) {
      switch (stateQueue) {
        case 'abrir':
          return t('dialing.option-opened');
        case 'fechar':
          return t('dialing.option-closed');
        default:
          return t('dialing.option-maintenance');
      }
    }
    switch (stateQueue) {
      case 'abrir':
        return t('dialing.option-opened');
      case 'fechar':
        return t('dialing.option-closed');
      default:
        return t('dialing.option-closed');
    }
  };

  const handleChangeStatus = useCallback((e) => {
    switch (e.target.value) {
      case 'manutencao':
        mutateChangeStatus({ dataStatus: dataStatus[2] });
        setStatusQueueRadio(e.target.value);
        break;
      case 'fechar':
        mutateChangeStatus({ dataStatus: dataStatus[1] });
        setStatusQueueRadio(e.target.value);
        break;
      case 'abrir':
        mutateChangeStatus({ dataStatus: dataStatus[0] });
        setStatusQueueRadio(e.target.value);
        break;
      default:
        break;
    }
  }, []);

  return (
    <ContainerStatus>
      <Stack
        direction='column'
        spacing={2}
        padding={1}
        justifyContent={'center'}
      >
        <ContainerStatusQueue currentState={statusQueueRadio}>
          <span>{t('dialing.label-statusQueue')}: </span>
          <span className='whitBorder'>
            {generateTextStateQueue(statusQueueRadio)}
          </span>
        </ContainerStatusQueue>

        <ContainerRadioInLine>
          <StyledRadioGroupInLine
            row
            name={'labelRadio'}
            value={statusQueueRadio}
            onChange={handleChangeStatus}
          >
            <StyledFormControlLabel2
              value={'abrir'}
              control={<StyledRadio size='small' />}
              label={
                statusQueueRadio === 'abrir'
                  ? t('dialing.option-opened')
                  : t('dialing.option-open')
              }
            />
            <StyledFormControlLabel2
              value={'fechar'}
              control={<StyledRadio size='small' />}
              label={
                statusQueueRadio === 'fechar'
                  ? t('dialing.option-closed')
                  : t('dialing.option-close')
              }
            />
            {isSysAdmin && (
              <StyledFormControlLabel2
                value={'manutencao'}
                control={<StyledRadio size='small' />}
                label={t('dialing.option-maintenance')}
              />
            )}
          </StyledRadioGroupInLine>
        </ContainerRadioInLine>
      </Stack>
    </ContainerStatus>
  );
};

export const PanelStatusQueue = memo(PanelStatusQueueComponent);
