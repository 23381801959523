import * as yup from 'yup';
import useFormControl from '@/hooks/useFormControl';
import { Stack } from '@mui/system';
import { add } from 'date-fns';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { ControlDataPicker } from '@/components/FormComponents/DataPickerComponent';
import { ControlTimePicker } from '@/components/FormComponents/TimePicker';
import { AccordionComponent } from '@/components/layout/AccordionComponent';
import { useTranslation } from 'react-i18next';

export const AdvancedSearchLog = ({
  mutateAdvancedSearch,
  refetchActivityLog,
}) => {
  const { t } = useTranslation();

  const searchAdvancedSchema = yup.object().shape({
    initialTime: yup.date(),
    endTime: yup
      .date()
      .test(
        'is-greater',
        'Initial time must be less than or equal to end time',
        function (endTime) {
          const { initialTime } = this.parent;
          const initialHoursMinutes = `${initialTime.getHours()}:${initialTime.getMinutes()}`;
          const endHoursMinutes = `${endTime.getHours()}:${endTime.getMinutes()}`;
          return initialHoursMinutes <= endHoursMinutes;
        }
      ),
    initialDate: yup.date(),
    endDate: yup
      .date()
      .test(
        'is-greater',
        'End date must be greater than or equal to initial date',
        function (endDate) {
          const { initialDate } = this.parent;
          return (
            endDate.toISOString().split('T')[0] >=
            initialDate.toISOString().split('T')[0]
          );
        }
      ),
  });

  const { handleSubmit, control, errors, reset } =
    useFormControl(searchAdvancedSchema);

  const handleSubmitAdvancedLog = (data) => {
    mutateAdvancedSearch(data);
  };

  const actionOnClose = () => {
    reset();
    refetchActivityLog();
  };

  return (
    <AccordionComponent
      opemText={t('activityLog.panel.label-openAdvanced')}
      closeText={t('activityLog.panel.label-closeAdvanced')}
      alignText={'end'}
      actionOnClose={actionOnClose}
    >
      <form onSubmit={handleSubmit(handleSubmitAdvancedLog)}>
        <Stack direction={'row'} spacing={2}>
          <ControlTimePicker
            control={control}
            nameControl={'initialTime'}
            defaultValue={new Date()}
            error={errors.initialTime}
            label={t('activityLog.panel.label-timeInit')}
          />

          <ControlTimePicker
            control={control}
            nameControl={'endTime'}
            defaultValue={add(new Date(), {
              hours: 1,
            })}
            error={errors.endTime}
            label={t('activityLog.panel.label-timeEnd')}
          />
        </Stack>
        <Stack direction={'row'} spacing={2}>
          <ControlDataPicker
            label={t('activityLog.panel.label-dateInit')}
            control={control}
            nameControl={'initialDate'}
            defaultValue={new Date()}
            disableOpenPicker
          />

          <ControlDataPicker
            label={t('activityLog.panel.label-dateEnd')}
            control={control}
            nameControl={'endDate'}
            defaultValue={new Date()}
            error={errors.endDate}
            disableOpenPicker
          />
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <ButtonStyled type={'submit'} size={'small'}>
            {t('activityLog.panel.btn-search')}
          </ButtonStyled>
        </Stack>
      </form>
    </AccordionComponent>
  );
};
