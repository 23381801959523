import { apiData } from '@/services/api';

export async function updateQueue(dataUpdateQueue) {
  try {
    const { data } = await apiData.put('dialer/updateQueue', dataUpdateQueue);
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
