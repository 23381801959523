import { useState } from 'react';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { FaGear } from 'react-icons/fa6';
import { useAtom } from 'jotai';
import { MdLock } from 'react-icons/md';
import { FooterConfig } from '../../../styled';
import { ContainerMainDispositions, ContentManageDispositions } from './styled';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { dataDispositions } from '@/store/FlowBuilder';
import { FormDispositions } from './FormDispositions';

export const AddDispositions = ({ changeFlowConfigState, usedDisposition }) => {
  const { t } = useTranslation();
  const [dispositions] = useAtom(dataDispositions);
  const [selected, setSelected] = useState(null);

  const handleSelectDisposition = (dataDisposition) => {
    setSelected(dataDisposition);
  };

  return (
    <ContainerMainDispositions>
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4>{t('flowBuilder.configs.dispositions.btn-add-dispositions')}</h4>
      </Stack>

      <ContentManageDispositions>
        <FormDispositions selected={selected} setSelected={setSelected} />
        <div className='divider'></div>
        <div className='content-list-dispositions'>
          <ul>
            {dispositions.length > 0 &&
              dispositions.map((disposition, i) => (
                <li
                  key={i}
                  onClick={() => handleSelectDisposition(disposition)}
                  className={
                    selected?.id === disposition.id
                      ? 'selected-item-disposition'
                      : ''
                  }
                >
                  <Stack direction={'row'} spacing={1}>
                    <span>
                      {disposition.name ?? ''} - {disposition.descricao ?? ''}
                    </span>
                  </Stack>
                  {usedDisposition.includes(disposition.name) && <MdLock />}
                </li>
              ))}
          </ul>
        </div>
      </ContentManageDispositions>
      <FooterConfig>
        <div className=''></div>
        <Stack direction={'row'} spacing={1}>
          <Button
            typeText
            variant='secondary'
            onClick={() => changeFlowConfigState(0)}
          >
            {t('flowBuilder.configs.btn-back')}
          </Button>
        </Stack>
      </FooterConfig>
    </ContainerMainDispositions>
  );
};
