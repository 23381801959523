import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerNewFlow = styled(motion.div)`
  width: 100%;
  height: 99%;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  h4 {
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseTitle};
    margin-bottom: 10px;
  }
`;

export const FormNewFlow = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
`;

export const MainForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  height: 100%;
`;
