import { useCallback } from 'react';
import { PanelChart } from '../styled';
import Button from '@mui/material/Button';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { BiGitBranch } from 'react-icons/bi';
import LegendPopover from './LegendPopover';

import { MdOutlineLock, MdOutlineLockOpen } from 'react-icons/md';
import { PiLineSegment } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

export const PanelTree = ({
  scaleZoomProps,
  lockTreeProps,
  pathTreeProps,
  translateProps,
}) => {
  const { t } = useTranslation();
  const handleChangeTypePath = useCallback(
    (typePath) => {
      if (typePath === 0) pathTreeProps.setPathTree('diagonal');
      if (typePath === 1) pathTreeProps.setPathTree('step');
    },
    [pathTreeProps]
  );

  const handleZoom = (typeZoom) => {
    if (typeZoom === 'out') {
      scaleZoomProps.scaleZoom > 0 &&
        (scaleZoomProps.setScaleZoom(scaleZoomProps.scaleZoom - 0.05),
        translateProps.setTranslate({
          ...translateProps.translate,
          x: translateProps.translate.x + 28,
        }));
    }

    if (typeZoom === 'in') {
      scaleZoomProps.scaleZoom < 1 &&
        (scaleZoomProps.setScaleZoom(scaleZoomProps.scaleZoom + 0.05),
        translateProps.setTranslate({
          ...translateProps.translate,
          x: translateProps.translate.x - 28,
        }));
    }
  };

  const renderBtnPath = () => {
    if (pathTreeProps.pathTree === 'diagonal') {
      return (
        <Button
          variant='contained'
          size='small'
          onClick={() => handleChangeTypePath(1)}
          title={t('journey.chartTree.btn-changeLineChart')}
        >
          <BiGitBranch size={14} />
        </Button>
      );
    }
    if (pathTreeProps.pathTree === 'step') {
      return (
        <Button
          variant='contained'
          size='small'
          onClick={() => handleChangeTypePath(0)}
          title={t('journey.chartTree.btn-changeLineChart')}
        >
          <PiLineSegment size={14} />
        </Button>
      );
    }

    return (
      <Button
        variant='contained'
        size='small'
        onClick={() => handleChangeTypePath(1)}
        title={t('journey.chartTree.btn-changeLineChart')}
      >
        <PiLineSegment size={14} />
      </Button>
    );
  };
  return (
    <PanelChart>
      <LegendPopover />
      {renderBtnPath()}
      <Button
        variant='contained'
        size='small'
        disabled={scaleZoomProps.scaleZoom >= 1}
        onClick={() => handleZoom('in')}
        title={t('journey.chartTree.btn-ZoomIn')}
      >
        <FiZoomIn />
      </Button>
      <Button
        variant='contained'
        size='small'
        onClick={() => handleZoom('out')}
        disabled={scaleZoomProps.scaleZoom <= 0.5}
        title={t('journey.chartTree.btn-ZoomOut')}
      >
        <FiZoomOut />
      </Button>
      <Button
        variant='contained'
        size='small'
        color={!lockTreeProps.lockTree ? 'primary' : 'warning'}
        onClick={() => lockTreeProps.setLockTree(!lockTreeProps.lockTree)}
        title={
          lockTreeProps.lockTree
            ? t('journey.chartTree.btn-unlock')
            : t('journey.chartTree.btn-blocked')
        }
      >
        {lockTreeProps.lockTree ? <MdOutlineLock /> : <MdOutlineLockOpen />}
      </Button>
    </PanelChart>
  );
};
