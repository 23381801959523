import { apiData } from '@/services/api';

export async function updateUFManager(dataUpdate) {
  try {
    const { data } = await apiData.put(
      'dialingRules/updateUFManager',
      dataUpdate
    );
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
