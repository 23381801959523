import { apiData } from '@/services/api';

export async function deleteScreenRoutingProfile(dataDelete) {
  try {
    const { data } = await apiData.delete(
      `securityProfiles/deleteSecurityProfilesRoles`,
      {
        data: dataDelete,
      }
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
}
