import styled from 'styled-components';

export const ContainerBotConnected = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  gap: 1rem;
  border-top: 1px solid
    ${(props) => props.theme.colors.containers.dividerColor}B7;

  margin-top: 10px;
  padding: 1.2rem 0.6rem 0.6rem 0.6rem;
  user-select: none;

  .divider_string {
  }

  .item_footer_card {
    div {
      span {
        font-style: normal;
        font-weight: 300;
        font-size: 0.875rem;
        text-transform: capitalize;
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }
    span {
      font-weight: 700;
      font-size: 0.9rem;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.typography.baseText};
      padding-top: 2px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 2px;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid
      ${(props) => props.theme.colors.containers.dividerColor}B7;
  }

  @media (max-width: 660px) {
    justify-content: space-around;
  }

  @media (max-width: 500px) {
    padding: 10px 20px;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.875rem;
  }
`;
