import { errorToast } from '@/components/FormComponents/Toast';
import { getVirtualAgentsService } from '@/services/monitor/getVirtualAgentsService';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export const useLineMonitoring = () => {
  const { t } = useTranslation();

  const status = ['EMLIG', 'DISCANDO', 'LIVRE', 'EMPAUSA'];

  function getRandomStatus() {
    const randomIndex = Math.floor(Math.random() * status.length);
    return status[randomIndex];
  }

  function renderTime() {
    const seconds = Math.floor(Math.random() * 60);
    const minutes = Math.floor(Math.random() * 10);
    return `00:${minutes > 9 ? minutes : `0${minutes}`}:${
      seconds > 9 ? seconds : `0${seconds}`
    }`;
  }

  const data = [
    {
      fila: '100',
      campanha: 'Voicce Labs',
      fila_estado: 'DISCANDO',
      falando: 10,
      discando: Math.floor(Math.random() * 50),
      array_ag: [
        {
          nome: 'Lucas Miguel',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Lucas',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Isabella',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Pedro',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Ana Clara',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Felipe Henrique',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Maria Eduarda',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Marcio Henrique',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Beatriz',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'João',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
      ],
    },
    {
      fila: '300',
      campanha: 'Voicce Labs 2',
      fila_estado: 'PAUSA',
      falando: 4,
      discando: 0,
      array_ag: [
        {
          nome: 'Lucas Miguel',
          estado: 'EMPAUSA',
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Lucas',
          estado: 'EMPAUSA',
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Isabella',
          estado: 'EMPAUSA',
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Pedro',
          estado: 'EMPAUSA',
          tempo_inicio_ura: renderTime(),
        },
      ],
    },
    {
      fila: '209',
      campanha: 'Voicce Labs 3',
      fila_estado: 'LIVRE',
      falando: 8,
      discando: 0,
      array_ag: [
        {
          nome: 'Maria Eduarda',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Lucas Gabriel',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Isabella Fernanda',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Sophia Beatriz',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'João Victor',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Laura Eduarda',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Daniel Felipe',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Manuela Cristina',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
      ],
    },
    {
      fila: '901',
      campanha: 'Voicce Labs 4',
      fila_estado: 'DISCANDO',
      falando: 10,
      discando: Math.floor(Math.random() * 50),
      array_ag: [
        {
          nome: 'Letícia Amanda',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Gustavo Henrique',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Manuela Cristina',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'André Luiz',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Camila Raquel',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Enzo Miguel',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Juliana Alves',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Daniel Felipe',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Amanda Beatriz',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
        {
          nome: 'Thiago Alexandre',
          estado: getRandomStatus(),
          tempo_inicio_ura: renderTime(),
        },
      ],
    },
  ];

  const { data: dataLinesMonitoring, isLoading: loadingDataLinesMonitoring } =
    useQuery(
      'dataLinesMonitoring',
      async () => {
        // return await getVirtualAgentsService();
        return data;
      },
      {
        retry: 0,
        refetchOnWindowFocus: false,
        refetchInterval: 1000 * 5,
        onError: () =>
          errorToast(t('monitorVirtualAgents.messages.errorFetchData')),
      }
    );

  return { dataLinesMonitoring, loadingDataLinesMonitoring };
};
