import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerChartsDash = styled(motion.div)`
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: minmax(auto, 300px) minmax(300px, 460px);

  @media (max-width: 1550px) {
    height: 80%;
  }

  gap: 1rem;

  .first_row_item_1 {
    grid-column: 1/3;
    grid-row: 1 / 2;
  }

  .first_row_item_2 {
    grid-column: 3/5;
    grid-row: 1 / 2;
  }

  .second_row_item_1 {
    grid-column: 1/4;
  }

  .second_row_item_2 {
    grid-column: 4/5;
  }

  @media (min-width: 2600px) {
    grid-template-rows: auto auto;
    height: 100%;

    .first_row_item_1 {
      grid-column: 1/5;
      grid-row: 1 / 2;
    }

    .first_row_item_2 {
      grid-column: 1/3;
      grid-row: 2 / 2;
    }

    .second_row_item_1 {
      grid-column: 1/5;
    }
    .second_row_item_2 {
      grid-column: 3/5;
      grid-row: 2 / 2;
    }
  }

  @media (max-width: 1440px) {
    .first_row_item_1 {
      grid-column: 1/3;
      grid-row: 1 / 2;
    }

    .first_row_item_2 {
      grid-column: 3/5;
      grid-row: 1 / 2;
    }

    .second_row_item_1 {
      grid-column: 1/5;
    }
    .second_row_item_2 {
      grid-column: 1/5;
    }
  }
  @media (max-width: 1190px) {
    grid-template-rows: auto auto;

    .first_row_item_1 {
      grid-column: 1/5;
      grid-row: 1 / 2;
    }

    .first_row_item_2 {
      grid-column: 1/5;
      grid-row: 2 / 2;
    }

    .second_row_item_1 {
      grid-column: 1/5;
    }
    .second_row_item_2 {
      grid-column: 1/5;
    }
  }
`;
