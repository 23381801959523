export const useMappingRows = () => {
  const mappingRows = (list) => {
    if (!list) return [];

    return list.map((item) => ({
      id: item.id,
      ddd: item.ddd,
      week: item.hora_comeca,
      saturday: item.hora_comeca_sabado,
      sunday: item.hora_comeca_domingo,
      weekEnd: item.hora_termina,
      saturdayEnd: item.hora_termina_sabado,
      sundayEnd: item.hora_termina_domingo,
    }));
  };

  return { mappingRows };
};
