import { Typography } from '@mui/material';
import { useId, useState } from 'react';
import { Stack } from '@mui/system';
import { PiTreeStructureFill, PiTreeStructureLight } from 'react-icons/pi';
import { MdLock, MdOutlineEdit } from 'react-icons/md';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonControlIntent,
  CountIntentsSpan,
} from './styled';

export const AccordionToItem = ({
  children,
  dataIntent,
  updateIntent,
  usedNlu,
}) => {
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    if (!newExpanded) {
      return setExpanded(false);
    }
    return setExpanded(panel);
  };

  const handleUpdatedItem = (e) => {
    e.stopPropagation();
    return updateIntent(dataIntent);
  };

  return (
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <AccordionSummary
        aria-controls={`panel-${useId()}`}
        id={`panel-header-${useId()}`}
      >
        <Stack direction={'row'} justifyContent={'space-between'} width={'98%'}>
          <Stack direction={'row'} spacing={'8px'} alignItems={'center'}>
            {expanded ? (
              <PiTreeStructureFill size={20} color='#4c48ff' />
            ) : (
              <PiTreeStructureLight size={20} />
            )}

            <Typography>
              <strong>{dataIntent.name}</strong>
            </Typography>
          </Stack>

          <Stack direction={'row'} spacing={'8px'} alignItems={'center'}>
            <CountIntentsSpan>{dataIntent.data.length}</CountIntentsSpan>
            <ButtonControlIntent
              type='button'
              whileTap={{ scale: 0.95 }}
              onClick={handleUpdatedItem}
              disabled={usedNlu}
            >
              {usedNlu ? <MdLock /> : <MdOutlineEdit />}
            </ButtonControlIntent>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
