import { apiData } from '../api';

export async function getActiveRecordings(dataSearch) {
  try {
    const { data } = await apiData.post(
      'dialer/listDialerRecordings',
      dataSearch
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
