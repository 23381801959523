import { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { MarkerType } from 'reactflow';

export const useNodeFlow = (dataNodes) => {
  const theme = useTheme();

  const handlerAddNodeByType = (
    typeNode,
    position,
    totalNodesEnd,
    renderLabelLoop
  ) => {
    let id = uuid();
    const getId = () => `node_id_${id}`;

    switch (typeNode) {
      case 'hangUp':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: totalNodesEnd,
            handler: `${typeNode}`,
          },
        };
      case 'playFiles':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'playFiles',
            validInput: [],
            allowSkip: false,
            sounds: [],
          },
        };
      case 'ttsVoicceLabs':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'ttsVoicceLabs',
            fileName: '',
            prompt: '',
          },
        };
      case 'recVoz':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'recVoz',
            timeout: 5,
            allowSkip: false,
            nluEndpoint: '',
            validInput: [],
            fileName: '',
            qtdRepesca: 0,
            utterance: '',
            slot: '',
          },
        };
      case 'conditionalNode':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'conditionalNode',
            conditions: {
              nextError: 'Error',
            },
            typeCondition: '',
            conditionsList: [],
            specialCondition: false,
          },
        };
      case 'dialplanVariables':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'dialplanVariables',
            type: 'get',
            language: '0',
            voice: '0',
            prosodyRate: '0',
            valueVoice: '',
            next: '',
            nextError: '',
          },
        };
      case 'setVariables':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'setVariables',
            listVariables: [],
          },
        };
      case 'apiData':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: typeNode,
            url: '',
            method: 'GET',
            headers: [],
            parameters: [],
            body: [],
            configRequest: {},
            response: [],
            soundRate: true,
            waitApi: true,
          },
        };
      case 'functionHandler':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: typeNode,
          },
        };
      case 'monestIa':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: ``,
            handler: `${typeNode}`,
          },
        };
      case 'deepCenter':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: ``,
            handler: `${typeNode}`,
          },
        };
      case 'loop':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: renderLabelLoop,
            handler: 'loop',
            attempts: 0,
            looping: '',
            complete: '',
          },
        };
      case 'dtmf':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'dtmf',
            prompt: '',
            typeCapture: 'CUSTOM',
            totalDigits: 0,
            timeout: 1,
            listDtmf: [],
            utterance: '',
            fileName: '',
          },
        };
      case 'transferQueue':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: ``,
            handler: `${typeNode}`,
            typeTransfer: '',
            transferTo: '',
            mcdu: '',
          },
        };

      default:
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            handler: `${typeNode}`,
          },
        };
    }
  };

  const renderPositionByType = (type) => {
    switch (type) {
      case 'dialplanVariables':
        return {
          success: {
            x: 10,
            y: 130,
          },
          error: {
            x: 10,
            y: 160,
          },
        };

      case 'apiData':
        return {
          success: {
            x: 10,
            y: 100,
          },
          error: {
            x: 10,
            y: 130,
          },
        };

      case 'dtmf':
        return {
          success: {
            x: 10,
            y: 140,
          },
          error: {
            x: 10,
            y: 170,
          },
        };

      case 'transferQueue':
        return {
          success: {
            x: 10,
            y: 105,
          },
          error: {
            x: 10,
            y: 135,
          },
        };

      default:
        return {
          success: {
            x: 10,
            y: 140,
          },
          error: {
            x: 10,
            y: 170,
          },
        };
    }
  };

  const renderSubItensConditional = (id, type) => {
    const subItems = [
      {
        id: `sub_id_${id}_${'success'}`,
        type: 'subNode',
        data: {
          label: 'Success',
          handler: 'successCondition',
        },
        position: renderPositionByType(type).success,
        parentNode: id,
        extent: 'parent',
        className: 'nodrag',
        selectable: false,
      },
      {
        id: `sub_id_${id}_${'error'}`,
        type: 'subNode',
        data: {
          label: 'Error',
          handler: 'errorCondition',
        },
        position: renderPositionByType(type).error,
        parentNode: id,
        extent: 'parent',
        className: 'nodrag',
        selectable: false,
      },
    ];

    const subItemsConditional = [
      {
        id: `sub_id_${id}_${'success'}`,
        type: 'subNode',
        data: {
          label: 'Set a condition',
          handler: 'successCondition',
        },
        position: {
          x: 10,
          y: 80,
        },
        parentNode: id,
        extent: 'parent',
        className: 'nodrag',
        selectable: false,
      },
      {
        id: `sub_id_${id}_${'error'}`,
        type: 'subNode',
        data: {
          label: 'Error',
          handler: 'errorCondition',
        },
        position: {
          x: 10,
          y: 110,
        },
        parentNode: id,
        extent: 'parent',
        className: 'nodrag',
        selectable: false,
      },
    ];

    const subItemsLoop = [
      {
        id: `sub_id_${id}_${'looping'}`,
        type: 'itemLoopVoicce',
        data: {
          label: 'Looping',
          handler: 'loopingVoicce',
        },
        position: {
          x: 10,
          y: 80,
        },
        parentNode: id,
        extent: 'parent',
        className: 'nodrag',
        selectable: false,
      },
      {
        id: `sub_id_${id}_${'loop'}`,
        type: 'itemLoopVoicce',
        data: {
          label: 'Complete',
          handler: 'completeVoicce',
        },
        position: {
          x: 10,
          y: 110,
        },
        parentNode: id,
        extent: 'parent',
        className: 'nodrag',
        selectable: false,
      },
    ];

    if (type === 'conditionalNode') {
      return subItemsConditional;
    }

    if (type === 'loop') {
      return subItemsLoop;
    }

    if (type === 'dtmf') {
      return subItems;
    }

    return subItems;
  };

  const renderLabelHangUp = () => {
    const filterHangups = dataNodes.nodes.filter(
      (item) => item.data.handler === 'hangUp'
    );
    if (!filterHangups || filterHangups.length === 0) {
      return 'end';
    }
    return `end_${filterHangups.length + 1}`;
  };

  const renderLabelLoop = () => {
    const filterHangups = dataNodes.nodes.filter(
      (item) => item.data.handler === 'loop'
    );
    if (!filterHangups || filterHangups.length === 0) {
      return 'loop';
    }
    return `loop_${filterHangups.length + 1}`;
  };

  const renderTypeNode = (typeNode) => {
    if (typeNode !== 'successCondition') {
      return 'errorEdge';
    }
    return 'successEdge';
  };

  const checkTargetUsedNodes = (idNodeSource) => {
    const searchUserEdgeTarget = dataNodes.edges.filter(
      (item) => item.source === idNodeSource
    );

    if (searchUserEdgeTarget.length > 0) {
      return true;
    }

    return false;
  };

  const renderColorMarkerEnd = (typeNode) => {
    if (typeNode !== 'successCondition') {
      return theme.colors.nodesFlow.errorEdge;
    }
    return theme.colors.nodesFlow.successEdge;
  };

  const renderEdge = (idSource) => {
    const filteredEdge = dataNodes.nodes
      .filter((node) => node.id === idSource)
      .map((node) => node.type);

    const filteredEdgeType = dataNodes.nodes
      .filter((node) => node.id === idSource)
      .map((node) => node.data.handler);

    switch (filteredEdge[0]) {
      case 'initNode':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 18,
            height: 18,
            color: `${theme.colors.nodesFlow.initNode}`,
            typeEdge: filteredEdge[0],
          },
        };

      case 'disposition':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 18,
            height: 18,
            color: `${theme.colors.nodesFlow.disposition}`,
            typeEdge: filteredEdge[0],
          },
        };

      case 'ttsVoicceLabs':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 16,
            height: 16,
            color: `${theme.colors.nodesFlow.ttsVoicceLabs}`,
            typeEdge: 'ttsVoicceLabs',
          },
        };
      case 'setVariables':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 16,
            height: 16,
            color: `${theme.colors.nodesFlow.setVariables}`,
            typeEdge: 'setVariables',
          },
        };
      case 'functionHandler':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 16,
            height: 16,
            color: `${theme.colors.nodesFlow.functionHandler}`,
            typeEdge: 'functionHandler',
          },
        };

      case 'itemRecVoicceNode':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 18,
            height: 18,
            color: `${theme.colors.nodesFlow.itemRecVoicceNode}`,
            typeEdge: 'itemRecVoicceNode',
          },
        };

      case 'itemDtmf':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 18,
            height: 18,
            color: `${theme.colors.nodesFlow.dtmf}`,
            typeEdge: 'itemDtmf',
          },
        };

      case 'subNode':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 18,
            height: 18,
            color: renderColorMarkerEnd(filteredEdgeType[0]),
            typeEdge: renderTypeNode(filteredEdgeType[0]),
          },
        };
      default:
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 18,
            height: 18,
            color: `red`,
            typeEdge: 'initNode',
          },
        };
    }
  };

  return {
    handlerAddNodeByType,
    renderSubItensConditional,
    renderLabelLoop,
    renderLabelHangUp,
    renderTypeNode,
    checkTargetUsedNodes,
    renderEdge,
  };
};
