import styled from 'styled-components';
import { ContainerNodeWrapper, ContentNodeWrapper } from '../styled';

export const NodeRecVozContainer = styled(ContainerNodeWrapper)`
  background: ${(props) => props.theme.colors.containers.colorSecondNode};
  border-radius: 4px;
  height: ${(props) => props.heighNode};
  min-height: 150px;
  position: relative;

  .react-flow__handle {
    &::after {
      background: #672e93;
    }
  }

  .react-flow__handle-left {
    left: -7px;
  }

  .react-flow__handle-right {
    right: -8px;
  }
`;

export const NodeContent = styled(ContentNodeWrapper)`
  min-width: 220px;

  .header {
    background: #672e93;
  }
`;

export const SubNodeRecVozContainer = styled.div`
  padding: 5px;
  width: 220px;
  border-radius: 2px;
  background: #672e93;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  .react-flow__handle {
    background: transparent;
    width: 30px;
    height: 100%;
    border-radius: 0;
    border: none;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      border-radius: 10px;
      width: 8px;
      height: 8px;
      border: 2px solid ${(props) => props.theme.colors.containers.colorCard};
      background: #672e93;
    }
  }
  .headerNode {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    font-size: 0.6rem;

    span {
      font-size: 0.66rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #ffff;
      line-height: 10px;
      padding-top: 1px;
    }
  }
`;
