import { apiData } from '@/services/api';

export async function updateUser(dataUserUpdate) {
  try {
    const { userId } = dataUserUpdate;
    const { data } = await apiData.put(`users/${userId}`, dataUserUpdate);

    return data;
  } catch (error) {
    throw new Error(error);
  }
}
