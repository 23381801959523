import { TextField } from '@mui/material';
import styled from 'styled-components';

export const StyledInput = styled(TextField)`
  label {
    color: ${(props) => props.theme.colors.typography.baseText};
    font-size: 1rem;
  }

  .MuiButtonBase-root .MuiMenuItem-gutters {
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .MuiInputBase-input {
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .Mui-focused {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }

  &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.typography.baseText};
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  svg {
    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;

export const ContainerBtnControlInput = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 6px;
  top: -4px;
  height: 100%;
  align-items: center;
  justify-content: center;

  button {
    all: unset;
    padding: 0;
    height: 12px;
    cursor: pointer;
    svg {
      font-size: 1.3rem;
    }
  }
`;
