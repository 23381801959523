import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { IoCopyOutline } from 'react-icons/io5';

export const CopyToClipboard = ({ textToCopy }) => {
  const [hasCopy, setHasCopy] = useState(false);

  useEffect(() => {
    if (hasCopy) {
      new Promise((resolve) => {
        setTimeout(() => {
          setHasCopy(false);
          resolve();
        }, 300);
      });
    }
  }, [hasCopy]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setHasCopy(true);
    });
  };

  return (
    <motion.button
      whileTap={{ scale: 0.9 }}
      onClick={() => copyToClipboard(textToCopy)}
      title='Copy'
    >
      {hasCopy ? (
        <IoMdCheckmarkCircle size={14} color='green' />
      ) : (
        <IoCopyOutline size={12} />
      )}
    </motion.button>
  );
};
