import { apiData } from '@/services/api';

export async function deleteDispositionsDialing(idRemove) {
  try {
    const { data } = await apiData.delete(
      `dialer/deleteMasterCallingCode/${idRemove}`
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
