import { useEffect, useId } from 'react';
import { ContainerListCampaigns } from './styled';
import { SelectedList } from './stages/SelectedList';
import { CampaignList } from './stages/CampaignList';
import { useAtom } from 'jotai';
import { stageListCampaign } from '@/store/DialingStore';
import { selectedListCampaign } from '@/store/ListCampaigns';

export const ListCampaign = () => {
  const [currentStage, setCurrentStage] = useAtom(stageListCampaign);
  const [, setSelected] = useAtom(selectedListCampaign);

  useEffect(() => {
    return () => {
      setCurrentStage(0);
      setSelected(null);
    };
  }, []);

  const controlStage = {
    previousStage: () => setCurrentStage((currentStage) => currentStage - 1),
    nextStage: () => setCurrentStage((currentStage) => currentStage + 1),
    toStage: (stage) => setCurrentStage(stage),
  };

  const flowListCampaign = [
    <CampaignList key={useId()} controlStage={controlStage} />,
    <SelectedList key={useId()} controlStage={controlStage} />,
  ];

  return (
    <ContainerListCampaigns>
      {flowListCampaign[currentStage]}
    </ContainerListCampaigns>
  );
};
