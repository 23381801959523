import { apiData } from '@/services/api';

export async function getDetailCampaign(dataCampaign) {
  try {
    const { data } = await apiData.get(
      `dialer/litsCampaignCodeAdditional/${dataCampaign}`,
      dataCampaign
    );
    return data;
  } catch (err) {
    throw new Error(err);
  }
}
