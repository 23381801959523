import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import styled from 'styled-components';

export const StyledRadio = styled(Radio)``;

export const StyledFormLabel = styled.span`
  color: ${(props) => props.theme.colors.typography.baseText} !important;
  font-size: 0.845rem !important;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  span {
    font-size: 0.87rem !important;
    color: ${(props) => props.theme.colors.typography.baseText};
  }
  .Mui-checked {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

export const StyledFormControlLabelImg = styled(FormControlLabel)`
  display: flex;
  align-items: flex-start !important;

  .content_radio_image {
    margin-top: 8px;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #dededec9;
    img {
      width: 100%;
      max-width: 100px;
    }
  }

  .Mui-checked {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }
`;

export const ContainerRadioInLine = styled.div`
  div {
    flex-wrap: nowrap !important;
    span {
      @media (max-width: 1550px) {
        font-size: 0.7rem !important;
      }
    }
  }
`;

export const StyledRadioGroupInLine = styled(RadioGroup)`
  padding: 0;
  margin: 0;
`;

export const StyledFormControlLabel2 = styled(FormControlLabel)`
  user-select: none;
  span {
    font-size: 0.8rem;
    text-align: start;
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }

  .Mui-checked {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }
`;
