import styled from 'styled-components';

export const ContentPanelAggregate = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  position: relative;

  .icon_handle_back {
    svg {
      color: ${(props) => props.theme.colors.typography.baseText}AA;
    }
  }

  .itemPanel {
    span {
      text-transform: uppercase;
      font-weight: bold;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }
`;
