import { Grid } from '@mui/material';
import {
  AccordionDetailContent,
  AccordionDetailPlayer,
  AccordionDetailSummary,
  ContainerDetailPlayer,
} from './styles';
import { useTranslation } from 'react-i18next';
import { renderDate } from '@/utils/formatsDateAndTime';
import { useState } from 'react';
import { DownloadButton } from '@/components/ButtonDownloadData/DownloadWav';
import { CopyToClipboard } from '@/components/CopyToClipboard';
import { MdArrowForwardIos } from 'react-icons/md';

export const DetailPlayer = ({ dataDetail }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false > 'panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const renderText = (spell) =>
    t(`activeRecordings.listActiveRecords.table.${spell}`);

  const labelExpanded = t(
    `activeRecordings.listActiveRecords.player.label-expanded`
  );
  const labelClosed = t(
    `activeRecordings.listActiveRecords.player.label-closed`
  );

  return (
    <AccordionDetailPlayer
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <AccordionDetailSummary
        expandIcon={<MdArrowForwardIos sx={{ fontSize: '0.8rem' }} />}
        aria-controls='panel1d-content'
        id='panel1d-header'
      >
        <span>{expanded ? labelExpanded : labelClosed}</span>
      </AccordionDetailSummary>
      <AccordionDetailContent>
        <ContainerDetailPlayer>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className='containerItem'>
                <div className='itemDetail'>
                  <strong>{renderText('th-name')}: </strong>
                  <span>{dataDetail?.nome_cliente}</span>
                </div>

                <CopyToClipboard textToCopy={dataDetail?.nome_cliente} />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className='containerItem'>
                <div className='itemDetail'>
                  <strong>CPF: </strong>
                  <span>{dataDetail?.cpf}</span>
                </div>
                <CopyToClipboard textToCopy={dataDetail?.cpf} />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className='containerItem'>
                <div className='itemDetail'>
                  <strong>{renderText('th-phone')}: </strong>
                  <span>
                    {dataDetail?.ddd} {dataDetail?.fone}
                  </span>
                </div>
                <CopyToClipboard
                  textToCopy={`${dataDetail?.ddd} ${dataDetail?.fone}`}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className='containerItem'>
                <div className='itemDetail'>
                  <strong>{renderText('th-hour')}: </strong>
                  <span>{renderDate(dataDetail?.atende_dt)}</span>
                </div>
                <CopyToClipboard
                  textToCopy={renderDate(dataDetail?.atende_dt)}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className='containerItem'>
                <div className='itemDetail'>
                  <strong>{renderText('th-dispositions')}: </strong>
                  <span>
                    {dataDetail?.cod_lig} - {dataDetail?.desc_cod_lig}
                  </span>
                </div>
                <CopyToClipboard
                  textToCopy={`${dataDetail?.cod_lig} - ${dataDetail?.desc_cod_lig}`}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className='containerItem'>
                <div className='itemDetail'>
                  <strong>{renderText('th-codInt')}: </strong>
                  <span>{dataDetail?.cod_link_int}</span>
                </div>
                <CopyToClipboard textToCopy={dataDetail?.cod_link_int} />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className='containerItem'>
                <div className='itemDetail'>
                  <strong>{'Id List'}: </strong>
                  <span>{dataDetail?.id_tabela_lista}</span>
                </div>
                <CopyToClipboard textToCopy={dataDetail?.id_tabela_lista} />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className='containerItem'>
                <div className='itemDetail'>
                  <strong>{renderText('th-list')}: </strong>
                  <span>{dataDetail?.desc_lista}</span>
                </div>
                <CopyToClipboard textToCopy={dataDetail?.desc_lista} />
              </div>
            </Grid>
          </Grid>

          <div className='content-download-detail'>
            <DownloadButton dataDownload={dataDetail} withText={'Download'} />
          </div>
        </ContainerDetailPlayer>
      </AccordionDetailContent>
    </AccordionDetailPlayer>
  );
};
