import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContainerEditTenant, FormEditTenant } from './styled';
import { useTranslation } from 'react-i18next';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { Grid } from '@mui/material';
import { useAtom } from 'jotai';
import { dataSelectedTenants } from '@/store/TenantsStore';
import { Stack } from '@mui/system';
import { useTenants } from '@/hooks/Tenants/useTenants';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { successToast } from '@/components/FormComponents/Toast';

export const EditTenant = ({ changeStage }) => {
  const { t } = useTranslation();
  const {
    loadingGetMatchTenant,
    mutateGetMatchTenant,
    mutateEditTenant,
    loadingEditTenant,
  } = useTenants();

  const renderText = (spell) => {
    return t(`tenants.edit.${spell}`);
  };

  const [tenantSelected, setTenantSelected] = useAtom(dataSelectedTenants);

  const editNewTenantSchema = yup.object().shape({
    name: yup.string().required(renderText('msg-required-field')),
    host: yup.string().required(renderText('msg-required-field')),
    port: yup.string().required(renderText('msg-required-field')),
    username: yup.string().required(renderText('msg-required-field')),
    database_name: yup
      .string()
      .required(renderText('msg-required-field'))
      .matches(/^[a-zA-Z0-9_]*$/, renderText('msg-special-character')),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(editNewTenantSchema),
    defaultValues: {
      name: tenantSelected?.name,
      host: tenantSelected?.host,
      port: tenantSelected?.port,
      username: tenantSelected?.username,
      database_name: tenantSelected?.database_name,
    },
  });

  const handleBack = () => {
    changeStage(0);
    setTenantSelected(null);
    reset();
  };

  const handleSubmitEditTenant = (data) => {
    const dataEdit = {
      id: tenantSelected.id,
      dataUpdated: {
        name: data.name,
        host: data.host,
        port: data.port,
        username: data.username,
        database_name: data.database_name,
      },
    };
    mutateEditTenant(dataEdit, {
      onSuccess: () => {
        successToast(renderText('msg-updated-tenant'));
        handleBack();
      },
    });
  };

  const handleMatchTenants = () => {
    mutateGetMatchTenant(tenantSelected, {
      onSuccess: () => {
        changeStage(1);
      },
    });
  };

  const isLoading = loadingGetMatchTenant || loadingEditTenant;

  return (
    <ContainerEditTenant initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <FormEditTenant onSubmit={handleSubmit(handleSubmitEditTenant)}>
        <TitleComponent textTitle={renderText('title')} iconTitle='tenants' />
        <div className='mainFormTenants'>
          <ControlInput
            control={control}
            nameControl={'name'}
            label={renderText('label-name')}
            error={Boolean(errors.name)}
            helperText={errors.name && errors.name.message}
          />
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <ControlInput
                control={control}
                nameControl={'host'}
                label={renderText('label-host')}
                error={Boolean(errors.host)}
                helperText={errors.host && errors.host.message}
              />
            </Grid>
            <Grid item xs={4}>
              <ControlInput
                control={control}
                nameControl={'port'}
                label={renderText('label-port')}
                error={Boolean(errors.port)}
                helperText={errors.port && errors.port.message}
              />
            </Grid>
          </Grid>
          <ControlInput
            control={control}
            nameControl={'username'}
            label={renderText('label-userName')}
            error={Boolean(errors.username)}
            helperText={errors.username && errors.username.message}
          />
          <ControlInput
            control={control}
            nameControl={'database_name'}
            label={renderText('label-databaseName')}
            error={Boolean(errors.database_name)}
            helperText={errors.database_name && errors.database_name.message}
          />

          <Stack alignItems={'flex-end'}>
            <Button typeText onClick={handleMatchTenants}>
              {renderText('btn-matchTenants')}
            </Button>
          </Stack>
        </div>

        <div className='footerEditTenant'>
          <Button typeText variant={'secondary'} onClick={handleBack}>
            {renderText('btn-cancel')}
          </Button>
          <Button typeText type='submit'>
            {renderText('btn-editTenants')}
          </Button>
        </div>
      </FormEditTenant>
    </ContainerEditTenant>
  );
};
