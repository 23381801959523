import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { selectedCampaign } from '@/store/Campaigns';
import useWindowDimensions from '@/hooks/useWidth';
import { TablePagination } from '@/components/Tables/TablePagination';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ConfirmationComponent } from '@/components/layout/ConfirmationComponent';
import { MdClose, MdEdit } from 'react-icons/md';
import { ContainerTableCampaign } from './styled';
import { useCampaigns } from '@/hooks/dialControl/Campaigns/useCampaigns';
import { successToast } from '@/components/FormComponents/Toast';
import { dataListClients } from '@/store/Clients';
import { Stack } from '@mui/system';
import { usePermissionsDialControl } from '@/hooks/dialControl/usePermissionsDialControl';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

export const TableCampaigns = ({
  filterCampaign,
  filterClientIdCampaigns,
  controlStage,
}) => {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const { permissionsEdit, permissionsDelete } = usePermissionsDialControl();
  const {
    mutateRemoveCampaign,
    loadingRemoveCampaign,
    mutateDetailCampaign,
    loadingDetailCampaign,
  } = useCampaigns();

  const [selectedDataCampaign, setSelectedDataCampaign] =
    useAtom(selectedCampaign);

  const tableCampaigns = useRef(null);

  const [listClients] = useAtom(dataListClients);

  const [openConfirmationBox, setOpemConfirmationBox] = useState(false);

  const handleOpemConfirmationRemove = (rowCampaign) => {
    setOpemConfirmationBox(true);
    setSelectedDataCampaign(rowCampaign);
  };

  const filterClientCampaign = (idCliente) => {
    const filterClient = listClients.filter((item) => item.id === idCliente);

    if (!filterClient) {
      return '-';
    }

    return filterClient[0]?.commercial_name ?? '-';
  };

  const handleEditCampaign = (selectedCampaign) => {
    mutateDetailCampaign(selectedCampaign.id, {
      onSuccess: (data) => {
        const dataSelected = {
          ...selectedCampaign,
          dispositions: data,
        };
        setSelectedDataCampaign(dataSelected);
        controlStage.toStage(2);
      },
    });
  };

  const ColumnsCampaigns = [
    {
      Header: t('dialControl.campaigns.listCampaigns.table.th-id'),
      accessor: 'id',
    },
    {
      Header: t('dialControl.campaigns.listCampaigns.table.th-first'),
      accessor: 'campanha',
    },
    {
      Header: t('dialControl.campaigns.listCampaigns.table.th-third'),
      accessor: 'id_cliente',
      Cell: ({ row }) => {
        return filterClientCampaign(row.original.id_cliente);
      },
    },
    {
      Header: t('dialControl.campaigns.listCampaigns.table.th-second'),
      accessor: 'descricao',
    },
    {
      Header: t('dialControl.campaigns.listCampaigns.table.th-fourth'),
      Cell: ({ row }) => {
        return (
          <Stack direction={'row'} spacing={'4px'}>
            <Button
              variant='secondary'
              onClick={() => handleOpemConfirmationRemove(row.original)}
              disabled={!permissionsDelete}
            >
              <MdClose />
            </Button>
            <Button
              onClick={() => handleEditCampaign(row.original)}
              disabled={!permissionsEdit}
            >
              <MdEdit />
            </Button>
          </Stack>
        );
      },
    },
  ];

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableCampaigns) {
      const tableMaxWidth = tableCampaigns.current.offsetHeight;
      const cellMinWidth = 55;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, tableCampaigns]);

  const filterCampaignList = () => {
    if (filterCampaign === '0') {
      return filterClientIdCampaigns;
    }
    const filtered = filterClientIdCampaigns.filter((data) => {
      return data.id === filterCampaign;
    });
    return filtered;
  };

  const handleRemoveCampaign = useCallback(() => {
    mutateRemoveCampaign(selectedDataCampaign.id, {
      onSuccess: () => {
        successToast(
          t(
            'dialControl.campaigns.createCampaign.errosInputs.msg-success-delete'
          )
        );
        setSelectedDataCampaign(null);
      },
    });
  }, [selectedDataCampaign]);

  const isLoading = loadingDetailCampaign;

  return (
    <ContainerTableCampaign ref={tableCampaigns}>
      {isLoading && <BackDropCircularLoading />}
      <TablePagination
        dataColumns={ColumnsCampaigns}
        dataTable={filterCampaignList}
        textNoData={t('dialControl.campaigns.listCampaigns.table.noDataTable')}
        rowsPerPageDisplay={heightTable}
      />
      <ConfirmationComponent
        open={openConfirmationBox}
        close={setOpemConfirmationBox}
        messageConfirm={t(
          'dialControl.campaigns.listCampaigns.confirmationRemove.sentence',
          { campaign: selectedDataCampaign?.campaign ?? '' }
        )}
        actionConfirm={handleRemoveCampaign}
        isLoading={loadingRemoveCampaign}
      />
    </ContainerTableCampaign>
  );
};
