import { ContainerButton, StyledButton } from './styled';

export const ButtonStyled = ({
  children,
  variantstyle = 'primary',
  isActive = 0,
  ...props
}) => {
  return (
    <StyledButton
      variant='contained'
      variantstyle={variantstyle}
      {...props}
      active={isActive}
      aria-label='Button'
    >
      {children}
    </StyledButton>
  );
};

export const Button = ({
  children,
  typeText = false,
  variant = 'primary',
  isActive = 0,
  ...props
}) => {
  return (
    <ContainerButton
      variant='contained'
      variantstyle={variant}
      size='small'
      {...props}
      active={isActive}
      aria-label='Button'
    >
      {typeText ? <span>{children}</span> : children}
    </ContainerButton>
  );
};
