import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FullContainerSidebar = styled(motion.div)`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 40px;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  background: ${(props) => props.theme.colors.containers.baseContainer};
  position: relative;
  z-index: 1000;
  width: 300px;
  user-select: none;

  @media (max-width: 1020px) {
    position: absolute;
    left: 0;
    bottom: 0;
    height: calc(100% - 60px);
  }
`;

export const SideBarContainer = styled(motion.div)`
  position: relative;
  height: 100%;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  @media (max-width: 1020px) {
    position: absolute;
    left: 0;
    bottom: 0;
    height: calc(100%);
  }
`;

export const HeaderSidebar = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;
  margin-bottom: 30px;
  margin-top: 10px;
  user-select: none;

  @media (max-width: 1550px) {
    margin-bottom: 10px;
  }

  @media (max-width: 1020px) {
    height: 80px;
    margin-top: 0px;
    margin-bottom: 10px;
    img {
      width: 100%;
      max-width: 180px;
      height: auto;
    }
  }
`;

export const ContainerItemsNavigation = styled.div`
  width: 100%;
  padding: 1rem 0.2rem 1rem 1rem;
  h2 {
    font-size: 0.7rem;
    padding: 20px 10px 20px 0px;
    font-weight: 400;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  height: calc(100vh - 100px);
  overflow: scroll;

  .load_itens__sidebar {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;

    svg {
      font-size: 0.7rem;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }
`;

export const SectionItens = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  h2 {
    font-size: 0.75rem;
    padding: 10px 10px 10px 0px;
    /* font-weight: 700; */
  }

  ul li {
    white-space: nowrap;
    list-style: none;
  }
`;

export const DividerX = styled(motion.div)`
  margin: 20px auto;
  width: 94%;
  height: 1px;
  background: ${(props) => props.theme.colors.typography.baseText};
  opacity: 0.2;
`;

export const Copyright = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  margin: 20px;
  padding: 10px 5px;
  span {
    display: block;
    color: ${(props) => props.theme.colors.typography.baseText};
    font-size: 0.7rem;
    @media (max-width: 1550px) {
      font-size: 0.55rem;
    }
  }

  @media (max-width: 1020px) {
    display: none;
  }
`;
