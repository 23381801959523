import { errorToast } from '@/components/FormComponents/Toast';
import { getCallOperators } from '@/services/callOperators/getCallOperators';
import { setSettingsDialingQueue } from '@/services/queue/setSettingsDialingQueue';
import { dataQueueOperators } from '@/store/DialingStore';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

export const useSettingsDialing = () => {
  const { t } = useTranslation();
  const [, setOperatorsCampaign] = useAtom(dataQueueOperators);

  const { isLoading: loadingDataOperators } = useQuery(
    'dataCallOperatorsSettings',
    async ({ signal }) => {
      return await getCallOperators(signal);
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setOperatorsCampaign(data),
      onError: () => {
        errorToast(t('dialing.configQueue.msg-error-get-operators'));
      },
    }
  );

  const {
    mutate: mutateSaveSettingsQueue,
    isLoading: loadingSaveSettingsQueue,
  } = useMutation({
    mutationFn: async (dataSettings) => {
      return await setSettingsDialingQueue(dataSettings);
    },
    onError: () => {
      errorToast(t('dialing.configQueue.msg-error-save-configs'));
    },
  });

  return {
    loadingDataOperators,
    mutateSaveSettingsQueue,
    loadingSaveSettingsQueue,
  };
};
