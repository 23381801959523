import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { Stack } from '@mui/material';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { dataTrunks, templateSelected } from '@/store/FlowBuilder';
import { DragTransferListCreate } from '@/components/pages/FlowBuilder/components/DragTransferListCreate';
import { usePermissionsFlow } from '@/components/pages/FlowBuilder/hooks/usePermissionsFlow';

import {
  ContainerConfig,
  ContainerTrunks,
  FooterConfigForm,
  MainConfigForm,
} from './styled';

export const HomeConfigTrunks = ({
  controlStageNewFlow,
  handleToManagement,
}) => {
  const { t } = useTranslation();
  const { hasVoicceLabsClient } = usePermissionsFlow();
  const [trunksList] = useAtom(dataTrunks);
  const [dataTrunksSelected, setDataTrunksSelected] = useState([]);
  const [, setTemplate] = useAtom(templateSelected);

  const handleAddTrunks = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        trunks: dataTrunksSelected,
      };
    });
    controlStageNewFlow.handleNextStage();
  };

  return (
    <ContainerConfig initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <MainConfigForm>
        <ContainerTrunks>
          <DescriptionInformation
            textDescription={t('flowBuilder.newFlow.stages.trunks.description')}
          />
          {trunksList.length > 0 && (
            <DragTransferListCreate
              codeList={trunksList}
              changeDataCodeList={setDataTrunksSelected}
            />
          )}
        </ContainerTrunks>
      </MainConfigForm>
      <FooterConfigForm>
        {hasVoicceLabsClient ? (
          <Button typeText onClick={handleToManagement}>
            {t('flowBuilder.newFlow.stages.trunks.btn-management-trunks')}
          </Button>
        ) : (
          <div className=''></div>
        )}
        <Stack direction={'row'} spacing={1}>
          <Button
            typeText
            type='button'
            variant='secondary'
            onClick={controlStageNewFlow.handleBackStage}
          >
            {t('flowBuilder.newFlow.stages.btn.btn-back')}
          </Button>
          <Button typeText onClick={handleAddTrunks}>
            {t('flowBuilder.newFlow.stages.btn.btn-next')}
          </Button>
        </Stack>
      </FooterConfigForm>
    </ContainerConfig>
  );
};
