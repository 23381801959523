import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ItemVariable } from './ItemVariable';

export const ItemListVars = ({
  listVariables,
  setListVariables,
  handleRemoveVariable,
  handleEdit,
  idSelected,
}) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      listVariables,
      result.source.index,
      result.destination.index
    );

    setListVariables(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className='itens_valid_input'
          >
            {listVariables.map((item, index) => {
              if (item.parent === idSelected) {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          width: snapshot.isDragging ? '280px' : '100%',
                        }}
                        key={item.id}
                      >
                        <ItemVariable
                          key={item.id}
                          itemVariable={item}
                          handleRemove={() => handleRemoveVariable(item.id)}
                          handleEdit={() => handleEdit(item.id)}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              }
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
