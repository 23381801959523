import * as yup from 'yup';
import useFormControl from '@/hooks/useFormControl';

import { Button } from '@/components/FormComponents/ButtonComponent';
import { FooterEditGroup, FormEditGroupUf, MainEditGroup } from './styled';
import { Stack } from '@mui/system';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { dataUfManagerSelected } from '@/store/DialingStore';
import { useTranslation } from 'react-i18next';
import { TableUF } from './TableUf';
import { useUFManager } from '@/hooks/dialControl/UFManager/useUfManager';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { user } from '@/store/AuthenticatorStore';

export const EditGroupUF = ({ controlStage }) => {
  const { t } = useTranslation();
  const { mutateUpdateGroupUF, loadingUpdateGroupUF } = useUFManager();
  const renderText = (spell) => {
    return t(`dialControl.ufManager.create.${spell}`);
  };

  const [currentUser] = useAtom(user);

  const [selectedUfManager, setSelectedUfManager] = useAtom(
    dataUfManagerSelected
  );

  const handleBackGroup = () => {
    setSelectedUfManager([]);
    controlStage.toStage(0);
  };

  const addUfManageSchema = yup.object().shape({
    nameGroup: yup.string().required(),
    description: yup.string().required(),
  });

  const { handleSubmit, control, errors, reset } =
    useFormControl(addUfManageSchema);

  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState(null);
  const [errorRow, setErrorRow] = useState(null);

  useEffect(() => {
    if (selectedUfManager) {
      setRows(selectedUfManager.schedules);
    }
  }, []);

  const dataRows = {
    rows,
    setRows,
    selected,
    setSelected,
    errorRow,
    setErrorRow,
  };

  const handleEditGroupUf = (data) => {
    const dataUpdate = {
      cod_uf_bloq: data.nameGroup,
      descricao: data.description,
      id_cliente: currentUser.client_id,
    };

    mutateUpdateGroupUF(dataUpdate, {
      onSuccess: () => {
        handleBackGroup();
        reset();
      },
    });
  };

  return (
    <FormEditGroupUf
      onSubmit={handleSubmit(handleEditGroupUf)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingUpdateGroupUF && <BackDropCircularLoading />}
      <MainEditGroup>
        <Stack width={'100%'} direction={'column'} spacing={2}>
          <ControlInput
            control={control}
            defaultValue={selectedUfManager.name}
            nameControl={'nameGroup'}
            label={renderText('label-name')}
            error={Boolean(errors.nameGroup)}
            InputProps={{
              readOnly: true,
            }}
          />
          <ControlInput
            control={control}
            defaultValue={selectedUfManager.description}
            nameControl={'description'}
            label={renderText('label-description')}
            error={Boolean(errors.description)}
          />
        </Stack>

        <TableUF dataRows={dataRows} />
      </MainEditGroup>
      <FooterEditGroup>
        <Button typeText variant={'secondary'} onClick={handleBackGroup}>
          {renderText('btn-cancel')}
        </Button>
        <Button
          typeText
          type='submit'
          //   disabled={rows.length === 0 || Boolean(errorRow) || Boolean(selected)}
        >
          {t(`dialControl.ufManager.edit.btn-update`)}
        </Button>
      </FooterEditGroup>
    </FormEditGroupUf>
  );
};
