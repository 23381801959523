import { useEffect, useId } from 'react';
import { useAtom } from 'jotai';
import { selectedQueue, stateRenderDialing } from '@/store/DialingStore';

import { DataQueue } from './stages/DataQueue';
import { StatusQueues } from './stages/StatusQueues';

import { ContainerDialing } from './styled';
import { ConfigsQueues } from './stages/ConfigsQueue';
import { useDialingServers } from '@/hooks/Dialing/useDialingServers';
import { dataCampaigns } from '@/store/Campaigns';

export const Dialing = () => {
  const { isLoadingDataCampaign } = useDialingServers();
  const [dataCampaignsList, setCampaignsList] = useAtom(dataCampaigns);

  const [, setSelected] = useAtom(selectedQueue);

  const [stateRender, setStateRender] = useAtom(stateRenderDialing);

  useEffect(() => {
    return () => {
      setSelected(null);
      setStateRender(0);
      setCampaignsList(null);
    };
  }, []);

  const changeFlowDialing = (stateDialing) => setStateRender(stateDialing);

  const flowDialing = [
    <StatusQueues
      key={useId()}
      changeFlowDialing={changeFlowDialing}
      dataQueues={dataCampaignsList}
      isLoadingData={isLoadingDataCampaign}
    />,
    <DataQueue key={useId()} changeFlowDialing={changeFlowDialing} />,
    <ConfigsQueues key={useId()} changeFlowDialing={changeFlowDialing} />,
  ];

  return <ContainerDialing>{flowDialing[stateRender]}</ContainerDialing>;
};
