import styled from 'styled-components';

export const ContainerConfigQueue = styled.div`
  width: 100%;
  height: 100%;
  min-height: 600px;
  gap: 1rem;

  display: grid;
  grid-template-rows: auto 1fr;

  h2 {
    font-size: 0.95rem;
    font-weight: bold;
    color: ${(props) => props.theme.colors.typography.baseTitle};
  }
`;
