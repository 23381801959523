import { apiData } from '../api';

export async function setSettingsDialingQueue(dataSettings) {
  try {
    const { data } = await apiData.put(
      'dialer/updateQueueConfig',
      dataSettings
    );
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
