import { IoWarningOutline } from 'react-icons/io5';
import { ContainerNoData } from './styled';
import { Box, Stack } from '@mui/system';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NoPermissionCard = ({ message = '' }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={4} width={'100%'} paddingTop={'2rem'}>
      <Box width={'100%'}>
        <Alert severity='error'>
          {t('components.no-permission-card.msg-noPermission')}
        </Alert>
      </Box>
      <ContainerNoData
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className='detail-content'>
          <IoWarningOutline />
          <span>{message}</span>
        </div>
      </ContainerNoData>
    </Stack>
  );
};
