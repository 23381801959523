import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { Handle, Position } from 'reactflow';
import { useAtom } from 'jotai';
import React, { memo, useCallback } from 'react';
import { NodeContent, NodeTabulationContainer } from './styled';
import { TbVariableMinus } from 'react-icons/tb';
import { PiArrowBendDownRightBold, PiHandPointing } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import { shortNameRender } from '../../../../utils/shortNameRender';
import { PanelNode } from '@/components/pages/FlowBuilder/components/PanelNode';

const VariablesNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();

  const [, setShowMenu] = useAtom(showMenuNodes);
  const [, setSelectedNode] = useAtom(selectedNode);

  function removeDuplicates(arr) {
    const seenIds = new Set();
    const seenNames = new Set();
    return arr.filter((item) => {
      if (seenIds.has(item.id) || seenNames.has(item.name)) {
        return false;
      } else {
        seenIds.add(item.id);
        seenNames.add(item.name);
        return true;
      }
    });
  }

  const handleNode = useCallback(() => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  }, [id, data, setSelectedNode, setShowMenu]);

  const filterListVars = data.listVariables.filter(
    (item) => item.parent === id
  );

  const removedDuplicates = removeDuplicates(filterListVars);

  return (
    <NodeTabulationContainer
      selected={selected}
      errorConnect={data.errorConnect ? 1 : 0}
      onDoubleClick={() => handleNode()}
      handler={data.handler}
    >
      <PanelNode data={data} id={id} />

      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <TbVariableMinus size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
            <span>{t('flowBuilder.flow.nodes.variables.label')}</span>
          </div>
        </div>
        <div className='main'>
          <ul>
            {removedDuplicates.length === 0 ? (
              <div
                className='no-variables-content '
                onClick={() => handleNode()}
              >
                <PiHandPointing />
                <span>
                  {t('flowBuilder.flow.nodes.variables.label-add-vars')}
                </span>
              </div>
            ) : (
              removedDuplicates.map((item) => {
                return (
                  <li key={item.id}>
                    <PiArrowBendDownRightBold />
                    <span>
                      <strong>{shortNameRender(item.name, 12)}</strong>
                    </span>
                    <span className='responseValueVar'>
                      {' '}
                      = {shortNameRender(item.value, 12)}
                    </span>
                  </li>
                );
              })
            )}
          </ul>
        </div>
      </NodeContent>
      <Handle type='source' position={Position.Right} />
    </NodeTabulationContainer>
  );
};

export const VariablesNode = memo(VariablesNodeComponent);
