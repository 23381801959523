import { useAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { useVariablesFlow } from '@/hooks/FlowBuilder/useVariablesFlow';
import { templateSelected, variablesGlobal } from '@/store/FlowBuilder';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { DragTransferListCreate } from '@/components/pages/FlowBuilder/components/DragTransferListCreate';
import { Button } from '@/components/FormComponents/ButtonComponent';
import {
  ContainerConfig,
  ContainerGlobalVars,
  FooterConfigForm,
  MainConfigForm,
} from './styled';
import { usePermissionsFlow } from '@/components/pages/FlowBuilder/hooks/usePermissionsFlow';

export const HomeConfigGlobalVars = ({
  controlStageNewFlow,
  handleToManagement,
}) => {
  const { t } = useTranslation();
  const { loadingVariables } = useVariablesFlow(1);
  const [, setTemplate] = useAtom(templateSelected);
  const [globalVars] = useAtom(variablesGlobal);
  const { hasVoicceLabsClient } = usePermissionsFlow();

  const [dataVarsSelected, setDataVarsSelected] = useState([]);

  const handleBackStage = () => {
    controlStageNewFlow.handleBackStage();
  };

  const handleNextStage = () => {
    controlStageNewFlow.handleNextStage();
  };

  const handleAddVars = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        variablesGlobal: dataVarsSelected,
      };
    });

    handleNextStage();
  };

  return (
    <ContainerConfig initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <MainConfigForm>
        <ContainerGlobalVars>
          {loadingVariables && <BackDropCircularLoading />}
          <DescriptionInformation
            textDescription={t(
              'flowBuilder.newFlow.stages.globalVars.description'
            )}
          />
          {globalVars.length > 0 && (
            <DragTransferListCreate
              codeList={globalVars}
              changeDataCodeList={setDataVarsSelected}
            />
          )}
        </ContainerGlobalVars>
      </MainConfigForm>
      <FooterConfigForm>
        {hasVoicceLabsClient ? (
          <Button typeText onClick={handleToManagement}>
            {t(
              'flowBuilder.newFlow.stages.globalVars.btn-management-globalVars'
            )}
          </Button>
        ) : (
          <div className=''></div>
        )}

        <Stack direction={'row'} spacing={1}>
          <Button
            typeText
            type='button'
            variant='secondary'
            onClick={handleBackStage}
          >
            {t('flowBuilder.newFlow.stages.btn.btn-back')}
          </Button>
          <Button typeText onClick={handleAddVars}>
            {t('flowBuilder.newFlow.stages.btn.btn-next')}
          </Button>
        </Stack>
      </FooterConfigForm>
    </ContainerConfig>
  );
};
