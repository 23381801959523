import { useEffect, useId } from 'react';
import { ContainerCallOperators } from './styled';
import { ListCallOperators } from './stages/ListCallOperators';
import { CreateCallOperator } from './stages/CreateCallOperator';
import { EditCallOperator } from './stages/EditCallOperator';
import { useAtom } from 'jotai';
import { stageCallOperators } from '@/store/CallOperator';

export const CallOperators = () => {
  const [stateRenderCallOperators, setStateRenderCallOperators] =
    useAtom(stageCallOperators);

  const handleCallOperatorsStage = (stage) => {
    return setStateRenderCallOperators(stage);
  };

  useEffect(() => {
    return () => {
      setStateRenderCallOperators(0);
    };
  }, []);

  const flowCallOperators = [
    <ListCallOperators key={useId()} changeStage={handleCallOperatorsStage} />,
    <CreateCallOperator key={useId()} changeStage={handleCallOperatorsStage} />,
    <EditCallOperator key={useId()} changeStage={handleCallOperatorsStage} />,
  ];

  return (
    <ContainerCallOperators>
      {flowCallOperators[stateRenderCallOperators]}
    </ContainerCallOperators>
  );
};
