import * as yup from 'yup';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { PanelOperator } from './PanelOperator';
import useFormControl from '@/hooks/useFormControl';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { useOperatorControl } from '@/hooks/dialControl/operatorsControl/useOperatorControl';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { dataSelectedLinesControl } from '@/store/LineControl';
import { successToast } from '@/components/FormComponents/Toast';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import {
  ContainerLineControl,
  FooterFormCampaign,
  FormCampaignOperator,
  MainFormCampaign,
} from './styled';

export const CampaignOperator = ({ controlStage }) => {
  const { t } = useTranslation();
  const [selected] = useAtom(dataSelectedLinesControl);
  const {
    loadingDataOperators,
    // dataOperators,
    mutateUpdateOperators,
    loadingUpdateOperators,
  } = useOperatorControl(controlStage);

  const campaignOperatorSchema = yup.object().shape({
    mobileOperator: yup.string().required(),
    fixedOperator: yup.string().required(),
  });

  const renderTxt = (word) => {
    return t(`dialControl.operatorControl.campaignOperator.${word}`);
  };

  const { handleSubmit, control, errors, reset } = useFormControl(
    campaignOperatorSchema
  );

  const handleUpdateOperators = (data) => {
    const dataUpdate = {
      //   ...selected,
      mobileOperator: data.mobileOperator,
      fixedOperator: data.fixedOperator,
    };
    mutateUpdateOperators(dataUpdate, {
      onSuccess: () => {
        successToast(renderTxt('msg-successUpdate'));
        controlStage.toStage(0);
        reset();
      },
    });
  };

  const handleBackStage = () => {
    controlStage.toStage(0);
    reset();
  };

  const isLoading = loadingDataOperators || loadingUpdateOperators;

  return (
    <ContainerLineControl initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <PanelOperator controlMainStage={handleBackStage} />
      <FormCampaignOperator onSubmit={handleSubmit(handleUpdateOperators)}>
        <MainFormCampaign>
          <DescriptionInformation textDescription={renderTxt('description')} />
          <ControlSelect
            control={control}
            label={renderTxt('label-mobileOperator')}
            nameControl={'mobileOperator'}
            // defaultValue={selected ? selected.mobileOperator : '1'}
            defaultValue={'1'}
            error={Boolean(errors.mobileOperator)}
          >
            <ItemSelect value={'0'} disabled>
              {renderTxt('option-mobileOperator')}
            </ItemSelect>
            <ItemSelect value={'1'}>{selected.nome_operadora}</ItemSelect>
          </ControlSelect>

          <ControlSelect
            control={control}
            label={renderTxt('label-fixedOperator')}
            nameControl={'fixedOperator'}
            // defaultValue={selected.fixedOperator ? selected.fixedOperator : '0'}
            defaultValue={'1'}
            error={Boolean(errors.fixedOperator)}
          >
            <ItemSelect value={'0'} disabled>
              {renderTxt('option-fixedOperator')}
            </ItemSelect>
            <ItemSelect value={'1'}>{selected.nome_operadora}</ItemSelect>
            {/* {dataOperators &&
              dataOperators.map((operator) => (
                <ItemSelect value={operator.name} key={operator.id}>
                  {operator.name}
                </ItemSelect>
              ))} */}
          </ControlSelect>
        </MainFormCampaign>
        <FooterFormCampaign>
          <Button typeText variant={'secondary'} onClick={handleBackStage}>
            {renderTxt('btn-cancel')}
          </Button>
          <Button typeText type='submit'>
            {renderTxt('btn-save')}
          </Button>
        </FooterFormCampaign>
      </FormCampaignOperator>
    </ContainerLineControl>
  );
};
