import { useTranslation } from 'react-i18next';
import { Button } from '@components/FormComponents/ButtonComponent';
import { ContainerResetPassword } from './styled';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { successToast } from '@/components/FormComponents/Toast';

export const ResetPasswordStep = ({ closeDialog, emailUser }) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    successToast(t('forgotPass.messages.successMessageForgotPass'));
    closeDialog();
  };

  return (
    <ContainerResetPassword initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <TitleComponent
        textTitle={t('usersAgentsList.addUser.title-passwordSteps')}
        iconTitle='userAgents'
      />
      <div className='mainContent'>
        <p>{t('usersAgentsList.editUser.sentenceResetPassword')}</p>
        <span>
          <strong>{emailUser.toLowerCase()}</strong>
        </span>
      </div>
      <div className='footerContent'>
        <Button typeText onClick={handleConfirm}>
          Ok
        </Button>
      </div>
    </ContainerResetPassword>
  );
};
