import styled, { css } from 'styled-components';

export const ContainerNavigationFlow = styled.aside`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  position: relative;
  gap: 1rem;

  .content-back {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    button {
      all: unset;
      width: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      svg {
        color: ${(props) => props.theme.colors.typography.baseText};
        font-size: 1.4rem;
      }
    }
  }

  .list-handlers {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.7rem;
    height: 80%;
    overflow: auto;
    padding-right: 8px;
    h4 {
      padding: 0 0 6px 0;
      font-size: 0.85rem;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }

  .list-buttons {
    @media (max-height: 500px) {
      display: none;
    }
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
  }
`;

export const HandlerItemNode = styled.div`
  background: ${(props) => props.theme.colors.containers.colorNode};
  border-radius: 3px;
  padding: 10px 10px 10px 16px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 6px 4px 5px -3px rgba(0, 0, 0, 0.24);
  cursor: grab;
  position: relative;
  ${(props) =>
    !props.showItem &&
    css`
      opacity: 0.7;
      cursor: no-drop;
    `}

  .marker_item {
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    width: 6px;
    height: 100%;
    background-color: ${(props) =>
      props.theme.colors.nodesFlow[props.typeNode]};
  }

  svg {
    color: ${(props) => props.theme.colors.nodesFlow[props.typeNode]};
  }

  img {
    width: 14px;
  }

  span {
    padding-top: 3px;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.typography.baseText}AA;
  }
`;
