import { useTranslation } from 'react-i18next';

export const useDaysOfWeek = () => {
  const { t } = useTranslation();

  const txtSchedules = (word) =>
    t(`dialControl.schedulesList.listSchedules.${word}`);

  const daysOfWeek = [
    { title: txtSchedules('daysOfTheWeek.sunday'), numberDay: 0 },
    { title: txtSchedules('daysOfTheWeek.monday'), numberDay: 1 },
    { title: txtSchedules('daysOfTheWeek.tuesday'), numberDay: 2 },
    { title: txtSchedules('daysOfTheWeek.wednesday'), numberDay: 3 },
    { title: txtSchedules('daysOfTheWeek.thursday'), numberDay: 4 },
    { title: txtSchedules('daysOfTheWeek.friday'), numberDay: 5 },
    { title: txtSchedules('daysOfTheWeek.saturday'), numberDay: 6 },
  ];

  return { daysOfWeek };
};
