import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { HiMiniViewfinderCircle } from 'react-icons/hi2';
import { MdSquareFoot } from 'react-icons/md';
import { ContainerPanelFlow } from './styled';

export const PanelFlow = ({
  handleFitView,
  zoomIn,
  zoomOut,
  showRules,
  setShowRules,
}) => {
  return (
    <ContainerPanelFlow>
      <button
        onClick={() => setShowRules(!showRules)}
        title={'Show rules'}
        style={{
          opacity: showRules ? 1 : 0.5,
        }}
      >
        <MdSquareFoot />
      </button>
      <button onClick={() => zoomIn({ duration: 200 })} title={'Zoom In'}>
        <AiOutlineZoomIn />
      </button>
      <button
        onClick={() => zoomOut({ minZoom: 0, duration: 200 })}
        title={'Zoom Out'}
      >
        <AiOutlineZoomOut />
      </button>
      <button onClick={handleFitView} title={'Fit Flow'}>
        <HiMiniViewfinderCircle />
      </button>
    </ContainerPanelFlow>
  );
};
