import styled, { css } from 'styled-components';

export const NodeMailingContainer = styled.div`
  background: ${(props) => props.theme.colors.listNodes.bgCard};
  width: 170px;
  height: 40px;
  border-radius: 3px;

  border: 1px solid ${(props) => props.theme.colors.defaultColors.black};

  .react-flow__handle {
    background: transparent;
    width: 40%;
    height: 100%;
    border-radius: 0;
    border: none;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: -3px;
      transform: translateY(-50%);
      border-radius: 10px;
      width: 8px;
      height: 8px;
      border: 2px solid ${(props) => props.theme.colors.containers.colorCard};
      ${(props) => css`
        background: ${props.connected
          ? props.theme.colors.defaultColors.black
          : props.theme.colors.active};

        ${props.errorEdge &&
        css`
          background: ${props.theme.colors.defaultColors.orange};
        `}
      `}
    }
  }
`;

export const ContentNode = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.defaultColors.black};
  }

  .headerNode {
    width: 60px;
    display: flex;
    justify-content: center;
    flex-direction: center;
    align-items: center;
    img {
      padding-left: 6px;
    }
  }

  .mainNode {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    h4 {
      font-size: 0.74rem;
      color: ${(props) => props.theme.colors.typography.baseText};
    }

    span {
      font-size: 0.7rem;
      color: ${(props) => props.theme.colors.typography.baseText}AA;
    }
  }

  .indicatorConnect {
    color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  .content-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;

    svg {
      ${(props) =>
        props.errorEdge &&
        css`
          color: ${props.theme.colors.defaultColors.orange};
        `}
    }

    button {
      display: flex;
      border: none;
      padding: 2px;
      background-color: transparent;
      opacity: 0;
      transition: opacity 0.1s ease;

      &:hover {
        opacity: 1;
      }
    }
  }
`;
