import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { useMediaQuery } from '@mui/material';
import {
  currentPageId,
  idSubItem,
  isCloseItem,
  isReload,
} from '@store/NavigationDashboard';
import { NavBar } from '../NavBar';
import { MainContainer } from './styled';
import { schemaScreens, sysAdmin } from '@/store/AuthenticatorStore';
import { Profile } from '@/components/pages/Profile';
import { IframePage } from '@/components/pages/Iframe';
import { ControlClient } from '@/components/pages/ControlClient';

export const Main = ({ isFlowBuilder }) => {
  const [currentIdPage] = useAtom(currentPageId);
  const [idSubItemPage, setSubId] = useAtom(idSubItem);
  const [isReloadComponent] = useAtom(isReload);
  const [isClose, setIsClose] = useAtom(isCloseItem);
  const matches = useMediaQuery('(max-width:1020px)');
  const [screenSchema] = useAtom(schemaScreens);
  const [isSysAdmin] = useAtom(sysAdmin);

  const renderComponentPage = useCallback(
    (idScreen) => {
      if (currentIdPage === 'profile') {
        return <Profile />;
      }

      if (screenSchema) {
        const filterRenderComponent =
          screenSchema.length > 0 &&
          screenSchema.filter((item) => item.id === idScreen);

        if (idSubItemPage && filterRenderComponent.length > 0) {
          const filterRenderSubComponent =
            filterRenderComponent[0].subItem?.filter(
              (item) => item.id === idSubItemPage
            );

          if (filterRenderSubComponent) {
            if (filterRenderSubComponent[0]?.hasExternalUrl) {
              return (
                <IframePage codeIframe={filterRenderSubComponent[0].href} />
              );
            }
            return filterRenderSubComponent[0]?.component ?? [];
          }
          setSubId(false);
        }

        if (!filterRenderComponent && filterRenderComponent.length === 0) {
          return [];
        }

        if (filterRenderComponent[0]?.hasExternalUrl) {
          return <IframePage codeIframe={filterRenderComponent[0].href} />;
        }
        return filterRenderComponent[0]?.component ?? [];
      }
    },
    [currentIdPage, idSubItemPage, isReloadComponent]
  );

  return (
    <MainContainer>
      <NavBar isFlowBuilder={isFlowBuilder} />
      {matches && !isClose && (
        <div
          className='box-verify-click'
          onClick={() => setIsClose(true)}
        ></div>
      )}

      {isReloadComponent ? '' : renderComponentPage(currentIdPage)}

      {isSysAdmin && <ControlClient />}
    </MainContainer>
  );
};
