import React from 'react';
import { motion } from 'framer-motion';

const AudioVisualizer = () => {
  // Função para gerar alturas crescentes para cada ciclo de animação
  const generateIncreasingHeights = () => [0.5, 1, 1.5]; // Alturas em escala crescente

  return (
    <div
      style={{
        display: 'flex',
        gap: '3px',
        justifyContent: 'center',
        paddingTop: '3px',
      }}
    >
      {[...Array(3)].map((_, i) => (
        <motion.div
          key={i}
          animate={{
            scaleY: generateIncreasingHeights(), // Define alturas em ordem crescente
          }}
          transition={{
            repeat: Infinity,
            repeatType: 'mirror', // Vai e volta
            duration: 0.5, // Duração de cada ciclo
            ease: 'easeInOut',
            delay: i * 0.1, // Adiciona um leve delay para cada barra
          }}
          style={{
            width: '2px',
            height: '8px',
            backgroundColor: '#FFF', // Cor das barras
            transformOrigin: 'bottom', // Animação de baixo para cima
          }}
        />
      ))}
    </div>
  );
};

export default AudioVisualizer;
