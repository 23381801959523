import { MdOutlineEdit } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FaGear } from 'react-icons/fa6';
import { IoChatboxEllipses } from 'react-icons/io5';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { FooterConfig } from '../../../FlowBuilderComponent/NavigationFlow/ConfigCurrentFlow/styled';
import { dataDispositions } from '@/store/FlowBuilder';
import { FormManageDispositions } from './FormManageDispositions';
import { useDispositions } from '@/hooks/FlowBuilder/useDispositions';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import {
  ButtonEditDisposition,
  ContentAddDispositionsConfig,
  ContentDispositionsConfig,
  MainDispositionsContainer,
} from './styled';

export const ManageDispositions = ({ controlStage }) => {
  const { t } = useTranslation();

  const {
    isLoadingManagement,
    mutateUpdateDisposition,
    mutateCreateDisposition,
  } = useDispositions();

  const [codeLigs] = useAtom(dataDispositions);
  const [selectedDisposition, setSelectedDisposition] = useState(null);

  useEffect(() => {
    return () => setSelectedDisposition(null);
  }, []);

  const handleEditDisposition = (dataSelected) => {
    setSelectedDisposition(dataSelected);
  };

  const isLoading = isLoadingManagement;

  return (
    <ContentDispositionsConfig
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {isLoading && <BackDropCircularLoading />}
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4> {t('flowBuilder.configs.dispositions.btn-add-dispositions')}</h4>
      </Stack>

      <MainDispositionsContainer>
        <ContentAddDispositionsConfig>
          <FormManageDispositions
            selected={selectedDisposition}
            setSelected={setSelectedDisposition}
            handleCreate={mutateCreateDisposition}
            handleUpdate={mutateUpdateDisposition}
          />
          <div className='divider'></div>
          <div className='content-list-dispositions'>
            <ul>
              {codeLigs.length > 0 &&
                codeLigs.map((disposition, i) => {
                  return (
                    <li
                      key={i}
                      onClick={() => handleEditDisposition(disposition)}
                      className={
                        selectedDisposition?.id === disposition.id
                          ? 'selected-item-disposition'
                          : ''
                      }
                    >
                      <Stack direction={'row'} spacing={1}>
                        <IoChatboxEllipses />
                        <span>
                          {disposition.name} - {disposition.descricao}
                        </span>
                      </Stack>
                      <ButtonEditDisposition
                        type='button'
                        whileTap={{ scale: 0.95 }}
                        onClick={() => handleEditDisposition(disposition)}
                      >
                        <MdOutlineEdit />
                      </ButtonEditDisposition>
                    </li>
                  );
                })}
            </ul>
          </div>
        </ContentAddDispositionsConfig>
      </MainDispositionsContainer>

      <FooterConfig>
        <div className=''></div>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <Button
            typeText
            variant='secondary'
            type='button'
            onClick={controlStage}
          >
            {t('flowBuilder.newFlow.stages.btn.btn-back')}
          </Button>
        </Stack>
      </FooterConfig>
    </ContentDispositionsConfig>
  );
};
