import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerButtonDownload = styled(motion.div)`
  display: flex;
  flex-direction: row-reverse;
  gap: 0.4rem;

  button {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.bases.baseWhite};
    width: 46px;
    height: 100%;
    border: none;
    border-radius: 4px;
    padding: 4px;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:disabled {
      background-color: ${(props) => props.theme.colors.active}AA;
    }
  }
`;

export const PanelBtnDownload = styled(motion.div)`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
`;

export const ButtonDownloadStyled = styled(motion.button)`
  all: unset;
  background: ${(props) => props.theme.colors.primary};
  color: #ffff;
  padding: 0.2rem;
  border-radius: 30px;
  line-height: 0;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    background: gray;
    opacity: 0.8;
  }

  svg {
    color: #fff;
  }
`;
