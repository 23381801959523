import { apiData } from '../api';

export async function createClient(dataCreate) {
  try {
    const { data } = await apiData.post(`clients`, dataCreate);
    return data;
  } catch (error) {
    throw new Error(error);
  }
}
