import * as yup from 'yup';
import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ContainerNewFlow, FormNewFlow, MainForm } from './styled';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { templateSelected } from '@/store/FlowBuilder';
import useFormControl from '@/hooks/useFormControl';

export const NewFlow = ({ changeStateFlow, handleBack }) => {
  const { t } = useTranslation();
  const [, setTemplate] = useAtom(templateSelected);

  const detailNewFlowSchema = yup.object().shape({
    name: yup
      .string()
      .required(t('flowBuilder.newFlow.msg-name-required'))
      .max(50, t('flowBuilder.newFlow.msg-name-length', { length: '50' }))
      .matches(/^[_a-zA-Z0-9]*$/, t('flowBuilder.newFlow.msg-name-letters')),
    description: yup
      .string()
      .max(200, t('flowBuilder.newFlow.msg-name-length', { length: '200' })),
  });

  const { handleSubmit, control, reset, errors } =
    useFormControl(detailNewFlowSchema);

  const handleNewFlow = (data) => {
    const dataNewFlow = {
      nameIVR: data.name,
      descriptionIVR:
        data.description === '' ? 'No Description' : data.description,
      flow: {},
      variablesGlobal: [],
      codeLigs: [],
      nlus: [],
      trunks: [],
      nodes: [
        {
          id: '1',
          type: 'initNode',
          data: {
            label: 'First',
            handler: 'dbQuery',
            query: 'newUser',
            next: 'inputClient',
          },
          position: { x: 60, y: 400 },
        },
      ],
      edges: [],
      published: false,
    };
    setTemplate(dataNewFlow);
    reset();
    changeStateFlow(1);
  };

  const handleBackStage = () => {
    reset();
    handleBack('recentFlow');
  };

  return (
    <ContainerNewFlow initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <DescriptionInformation
        textDescription={t('flowBuilder.newFlow.stages.new.description')}
      />
      <FormNewFlow onSubmit={handleSubmit(handleNewFlow)}>
        <MainForm>
          <ControlInput
            control={control}
            nameControl={'name'}
            label={t('flowBuilder.newFlow.stages.new.label-name')}
            error={Boolean(errors.name)}
            helperText={errors.name ? errors.name.message : ' '}
          />
          <ControlInput
            control={control}
            nameControl={'description'}
            label={t('flowBuilder.newFlow.stages.new.label-description')}
            error={Boolean(errors.description)}
            helperText={errors.description ? errors.description.message : ' '}
          />
        </MainForm>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <Button
            typeText
            type='button'
            variant='secondary'
            onClick={handleBackStage}
          >
            {t('flowBuilder.newFlow.stages.btn.btn-back')}
          </Button>
          <Button typeText type='submit'>
            {t('flowBuilder.newFlow.stages.btn.btn-next')}
          </Button>
        </Stack>
      </FormNewFlow>
    </ContainerNewFlow>
  );
};
