import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { ContainerChartMap, ContentChartMap, HeaderChartMap } from './styled';
import { SvgMap } from './svgMap';
import { formatDate } from '@/utils/formatsDateAndTime';
import { parse } from 'date-fns';

export const ChartMap = ({ dataChartMap, selectedQueue }) => {
  const { t } = useTranslation();

  const dataTranslate = {
    title: t('dashboardPage.title-mapChart'),
    state: t('dashboardPage.label-states'),
    phone: t('dashboardPage.label-phone'),
    countPhones: t('dashboardPage.label-countPhones'),
  };

  const [totalCalls, setTotalCalls] = useState(0);
  const [dataMapHover, setDataMapHover] = useState('');
  const [dataMapShow, setDataMapShow] = useState({
    state: null,
    total: null,
  });

  function sumTotalValuesStates(mapChart) {
    if (mapChart) {
      const totalSum = mapChart.reduce(
        (acc, current) => acc + (parseFloat(current.total) ?? 0),
        0
      );
      return totalSum;
    }
    return 0;
  }

  useEffect(() => {
    const sumValues = sumTotalValuesStates(dataChartMap);
    setTotalCalls(sumValues);
  }, [dataChartMap]);

  useEffect(() => {
    const handleClick = (event) => {
      const target = event.currentTarget;
      const stateRender = target.getAttribute('name');
      const filterState = dataChartMap.filter(
        (dataState) => dataState.state === stateRender
      );
      if (!filterState) {
        setDataMapHover(`${target.getAttribute('name')} 0`);
      }
      setDataMapHover(`${target.getAttribute('name')}`);

      setDataMapShow({
        state: target.getAttribute('name'),
        total: filterState[0]?.total ?? 0,
      });
    };

    const states = document.getElementsByClassName('estado');

    for (let i = 0; i < states.length; i++) {
      states[i].addEventListener('mouseover', handleClick);
    }

    return () => {
      for (let i = 0; i < states.length; i++) {
        states[i].removeEventListener('mouseover', handleClick);
      }
    };
  }, [dataChartMap]);

  const renderSelectedQueue = selectedQueue.map((item, i) => {
    const totalLength = selectedQueue.length - 1;
    if (totalLength === i) {
      return <span key={i}>{item.queue}</span>;
    }
    return <span key={i}>{item.queue},</span>;
  });

  const renderDataSelected = () => {
    if (selectedQueue.length > 0) {
      const firstDate = parse(
        selectedQueue[0].first_date,
        'yyyy-MM-dd',
        new Date()
      );

      const endDate = parse(
        selectedQueue[0].last_date,
        'yyyy-MM-dd',
        new Date()
      );

      if (formatDate(firstDate) === formatDate(endDate)) {
        return formatDate(firstDate);
      }

      //   return `${formatDate(firstDate)} - ${formatDate(endDate)}`;
      formatDate(firstDate);
    }

    const currentDate = new Date();
    return formatDate(currentDate);
  };

  return (
    <ContainerChartMap>
      <HeaderChartMap>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <h2>{dataTranslate['title']}:</h2>
          <span>{renderDataSelected()}</span>
        </Stack>

        <Stack direction={'row'} spacing={'4px'}>
          {renderSelectedQueue}
        </Stack>
      </HeaderChartMap>
      <ContentChartMap>
        <SvgMap dataMapHover={dataMapHover} />

        <div className='infoHoverMap'>
          <Stack direction={'column'}>
            <h3>{dataTranslate['state']}:</h3>
            <span>{dataMapShow?.state ?? '-'}</span>
          </Stack>
          <div className='dividerData'></div>
          <Stack direction={'column'}>
            <h3>{dataTranslate['phone']}:</h3>
            <span>{dataMapShow?.total ?? 0}</span>
          </Stack>
          <div className='dividerData'></div>
          <Stack direction={'column'}>
            <h3>{dataTranslate['countPhones']}:</h3>
            <span>{totalCalls}</span>
          </Stack>
        </div>
      </ContentChartMap>
    </ContainerChartMap>
  );
};
