import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { motion } from 'framer-motion';
import * as yup from 'yup';
import { TablePagination } from '@/components/Tables/TablePagination';
import useWindowDimensions from '@/hooks/useWidth';
import { ContentTableSystemQueue } from './styled';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { ControlRadio } from '@/components/FormComponents/RadioComponent';
import { BsCheck2 } from 'react-icons/bs';
import { Stack } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import useFormControl from '@/hooks/useFormControl';
import { successToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';

export const ListSystemQueueControl = ({
  dataTable,
  mutateUpdateSystemQueue,
}) => {
  const { t } = useTranslation();

  const renderTxt = (word) => {
    return t(`dialControl.systemQueueControl.${word}`);
  };

  const { height } = useWindowDimensions();

  const tableCampaigns = useRef(null);
  const [selectedRow, setSelectedRow] = useState({});

  const activeOption = renderTxt(
    'tableControl.label-active'
  ).toLocaleLowerCase();
  const disabledOption = renderTxt(
    'tableControl.label-disabled'
  ).toLocaleLowerCase();

  const updateStatusQueueSchema = yup.object().shape({
    queueStatus: yup.string(),
  });

  const { handleSubmit, control, reset } = useFormControl(
    updateStatusQueueSchema
  );

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setSelectedRow({});
    }
  }, []);

  useEffect(() => {
    return () => setSelectedRow({});
  }, [dataTable]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const handleEdit = useCallback((selectedRow) => {
    setSelectedRow(selectedRow);
    reset();
  }, []);

  const handleUpdateSystemQueue = (data) => {
    const dataUpdateSystemQueue = {
      ...selectedRow.original,
      ativo: data.queueStatus === activeOption ? 1 : 0,
    };

    mutateUpdateSystemQueue(dataUpdateSystemQueue, {
      onSuccess: () => {
        setSelectedRow({});
        successToast(renderTxt('msg-successUpdate'));
      },
    });
  };

  const handleCancelEdit = () => {
    setSelectedRow({});
  };

  const verifyLengthSelected = Object.keys(selectedRow).length;

  const columnsSystemQueue = [
    {
      Header: renderTxt('tableControl.th-first'),
      accessor: 'fila',
      Cell: ({ row }) => {
        return (
          <span
            className={
              verifyLengthSelected > 0
                ? selectedRow.id !== row.id
                  ? ''
                  : 'selected-row'
                : ''
            }
          >
            {row.original.fila}
          </span>
        );
      },
    },
    {
      Header: renderTxt('tableControl.th-second'),
      accessor: 'campanha',
    },
    {
      Header: renderTxt('tableControl.th-third'),
      accessor: 'ativo',
      Cell: ({ row }) => {
        if (selectedRow.id !== row.id) {
          return (
            <motion.div>
              {row.original.ativo === 1 ? (
                <div className='item-status'>
                  <FaMicrophone />
                  <span>{activeOption}</span>
                </div>
              ) : (
                <div className='item-status disabled-item'>
                  <FaMicrophoneSlash />
                  <span>{disabledOption}</span>
                </div>
              )}
            </motion.div>
          );
        }
        return (
          <motion.div
            initial={{ opacity: 0, translateX: '-80px' }}
            animate={{ opacity: 1, translateX: '0px' }}
          >
            <ControlRadio
              control={control}
              defaultValue={
                row.original.ativo === 1 ? activeOption : disabledOption
              }
              labelValue1={activeOption}
              labelValue2={disabledOption}
              nameControl='queueStatus'
            />
          </motion.div>
        );
      },
    },
    {
      Header: renderTxt('tableControl.th-fourth'),
      Cell: ({ row }) => {
        if (selectedRow.id !== row.id) {
          return (
            <Button
              onClick={() => handleEdit(row)}
              disabled={Boolean(
                verifyLengthSelected && selectedRow.id !== row.id
              )}
            >
              <MdEdit />
            </Button>
          );
        }
        return (
          <motion.div
            initial={{ opacity: 0, translateX: '-80px' }}
            animate={{ opacity: 1, translateX: '0px' }}
          >
            <Stack direction={'row'} spacing={1}>
              <Button onClick={handleCancelEdit} variant='error'>
                <IoClose />
              </Button>
              <Button variant='success' type='submit'>
                <BsCheck2 size={16} />
              </Button>
            </Stack>
          </motion.div>
        );
      },
    },
  ];

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableCampaigns) {
      const tableMaxWidth = tableCampaigns.current.offsetHeight;
      const cellMinWidth = 55;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, tableCampaigns]);

  return (
    <form onSubmit={handleSubmit(handleUpdateSystemQueue)}>
      <ContentTableSystemQueue ref={tableCampaigns}>
        <TablePagination
          dataColumns={columnsSystemQueue}
          dataTable={dataTable}
          textNoData={'no data'}
          rowsPerPageDisplay={heightTable}
          selectedRow={selectedRow?.id ?? null}
          hasSelected={verifyLengthSelected}
        />
      </ContentTableSystemQueue>
    </form>
  );
};
