import { useAtom } from 'jotai';
import { sysAdmin } from '@/store/AuthenticatorStore';
import { useSecurityProfileRender } from '@/hooks/Navigator/useSecurityProfileRender';

export const usePermissionsTenants = () => {
  const { permissionComponent } = useSecurityProfileRender();
  const [isSysAdmin] = useAtom(sysAdmin);

  const permissionsCreate = permissionComponent('create') || isSysAdmin;
  const permissionsEdit = permissionComponent('update') || isSysAdmin;
  const permissionsDelete = permissionComponent('delete') || isSysAdmin;
  const permissionsDisplay = permissionComponent('display') || isSysAdmin;

  return {
    permissionsCreate,
    permissionsEdit,
    permissionsDelete,
    permissionsDisplay,
    isSysAdmin,
  };
};
