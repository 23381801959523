import { apiData } from '@/services/api';

export async function updateDispositionsCampaign(dataUpdateDispositions) {
  try {
    const { data } = await apiData.put(
      'dialer/updateCampaignCodeAdditional',
      dataUpdateDispositions
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
