import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContentConfigMailing = styled(motion.div)`
  padding: 2rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  div {
    span {
      font-size: 0.78rem;
    }
  }

  .footerConfig {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
`;

export const MainConfigContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
