import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { ContainerSystemQueueControl, PanelSystemQueueControl } from './styled';
import { ListSystemQueueControl } from './stages/ListSystemQueueControl';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { dataQueues } from '@/store/Queues';
import { useSystemQueueControl } from '@/hooks/dialControl/SystemQueueControl/useSystemQueueControl';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const SystemQueueControl = () => {
  const { t } = useTranslation();

  const renderTxt = (word) => {
    return t(`dialControl.systemQueueControl.${word}`);
  };

  const [filterCampaign, setFilterCampaign] = useState('0');

  const {
    loadingDataQueues,
    loadingUpdateSystemQueue,
    mutateUpdateSystemQueue,
  } = useSystemQueueControl();

  const [listQueues] = useAtom(dataQueues);

  const filtered = listQueues.filter((data) => {
    if (filterCampaign === '0') {
      return data;
    }
    return data.fila.toString().includes(filterCampaign);
  });

  const isLoading = loadingDataQueues || loadingUpdateSystemQueue;

  return (
    <ContainerSystemQueueControl
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {isLoading && <BackDropCircularLoading />}
      <PanelSystemQueueControl>
        <SelectComponent
          labelSelect={renderTxt('label-filterCampaign')}
          value={filterCampaign}
          onChange={(event) => setFilterCampaign(event.target.value)}
        >
          <ItemSelect value={'0'}>
            {renderTxt('option-filterCampaign')}
          </ItemSelect>
          {listQueues.length > 0 &&
            listQueues.map((item, i) => (
              <ItemSelect value={item.fila} key={i}>
                {item.fila} - {item.campanha}
              </ItemSelect>
            ))}
        </SelectComponent>
      </PanelSystemQueueControl>

      <ListSystemQueueControl
        dataTable={filtered}
        mutateUpdateSystemQueue={mutateUpdateSystemQueue}
      />
    </ContainerSystemQueueControl>
  );
};
