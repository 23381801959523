import { useReactFlow } from 'reactflow';
import { useEffect, useId, useState } from 'react';
import { ListDispositions } from './ListDispositions';
import { AddDispositions } from './AddDispositions';

export const ConfigDispositions = ({
  dataCodeLigs: listDispositions,
  handleClose,
}) => {
  const [stageDispositionsFLow, setStageDispositionsFlow] = useState(0);
  const { getNodes } = useReactFlow();
  const [usedDisposition, setDisposition] = useState([]);

  const changeFlowConfigState = (stage) => setStageDispositionsFlow(stage);

  const hasUsedDisposition = (usedNodes) => {
    const listNodes = usedNodes ? usedNodes : [];
    const mapListDisposition = listDispositions
      .map((itemSelected) => {
        const dispositionsUsed = listNodes.filter(
          (item) => item.data.label === itemSelected.name
        );
        if (dispositionsUsed.length > 0) {
          return dispositionsUsed[0];
        }
        return false;
      })
      .filter((item) => item)
      .map((item) => item.data.label);
    return mapListDisposition;
  };

  useEffect(() => {
    setDisposition(hasUsedDisposition(getNodes()));
  }, [getNodes]);

  const flowGlobalVariables = [
    <ListDispositions
      key={useId()}
      handleClose={handleClose}
      listDispositions={listDispositions}
      changeFlowConfigState={changeFlowConfigState}
      usedDisposition={usedDisposition}
    />,
    <AddDispositions
      key={useId()}
      changeFlowConfigState={changeFlowConfigState}
      usedDisposition={usedDisposition}
    />,
  ];

  return flowGlobalVariables[stageDispositionsFLow];
};
