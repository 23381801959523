import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerLogin = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
