import styled from 'styled-components';

export const ContentDetails = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.4rem;

  .divider {
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.typography.baseText}AA;
    opacity: 0.2;
  }

  .heater_details {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    h4 {
      color: ${(props) => props.theme.colors.typography.baseText};
      font-weight: 400;
    }

    svg {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  .item-details {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    span {
      color: ${(props) => props.theme.colors.typography.baseText};
      font-size: 0.9rem;

      &:nth-child(1) {
        font-weight: 600;
      }
    }
  }
`;

export const PanelDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;
