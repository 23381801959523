import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { InputAdornment, Stack } from '@mui/material';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { focusedFieldArea } from '@/store/FlowBuilder';
import smLogoVoice from '@/assets/imgs/icon-sm-voicce.svg';
import {
  ButtonControlSearch,
  ContainerPanelSearch,
  InputSearchFlowNodes,
} from './styled';

export const PanelSearch = ({ dataNodes, fitView, controlPanelSearch }) => {
  const { t } = useTranslation();
  const { nodes, setNodes } = dataNodes;
  const [, setFocusedArea] = useAtom(focusedFieldArea);

  const { statusPanelSearch } = controlPanelSearch;
  const [fieldSearch, setFieldSearch] = useState('');
  const [positionSearch, setPositionSearch] = useState(0);

  const excludedKeys = [
    'errorConnect',
    'handler',
    'next',
    'nextError',
    'valueVoice',
    'type',
    'allowSkip',
    'fileName',
    'validInput',
    'utterance',
    'slot',
    'typeCapture',
    'id',
    'specialCondition',
    'parent',
    'referNode',
    'configRequest',
    'idSelect',
    'disabled',
    'soundRate',
    'waitApi',
    'complete',
    'looping',
  ];

  const handleCleanSearch = () => {
    setFieldSearch('');
    fitView({
      maxZoom: '0.8',
      duration: 200,
    });
  };

  useEffect(() => {
    setPositionSearch(0);
  }, [fieldSearch]);

  useEffect(() => {
    if (!statusPanelSearch) {
      setFieldSearch('');
    }
  }, [statusPanelSearch]);

  function containsTextInData(data, text, excludedKeys = []) {
    for (const key in data) {
      if (excludedKeys.includes(key)) continue;

      const value = data[key];

      if (
        typeof value === 'string' &&
        value.toLowerCase().includes(text.toLowerCase())
      ) {
        return true;
      } else if (typeof value === 'object' && containsTextInData(value, text)) {
        return true;
      }
    }
    return false;
  }

  const filterNodeByFieldSearch =
    fieldSearch.length >= 1
      ? nodes.filter(
          (node) =>
            !node.parentNode &&
            containsTextInData(node.data, fieldSearch, excludedKeys)
        )
      : [];

  const increment = () => {
    if (positionSearch < filterNodeByFieldSearch.length - 1) {
      setPositionSearch((prevCounter) => prevCounter + 1);
    }
  };

  const decrement = () =>
    setPositionSearch((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));

  useEffect(() => {
    if (fieldSearch) {
      if (filterNodeByFieldSearch.length > 0) {
        setNodes((nodes) =>
          nodes.map((node) => {
            if (node.id === filterNodeByFieldSearch[positionSearch].id) {
              return { ...node, selected: true };
            }
            return { ...node, selected: false };
          })
        );
        fitView({
          nodes: [filterNodeByFieldSearch[positionSearch]],
          padding: 0.1,
          includeHiddenNodes: false,
          maxZoom: '0.9',
          duration: 200,
        });
      }
    }
  }, [statusPanelSearch, fieldSearch, positionSearch]);

  return (
    <ContainerPanelSearch>
      <div className='contentFieldSearch'>
        <InputSearchFlowNodes
          placeholder={t('flowBuilder.flow.placeholder-filter-nodes')}
          value={fieldSearch}
          onChange={(e) => setFieldSearch(e.target.value)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
          error={fieldSearch !== '' && filterNodeByFieldSearch.length === 0}
          errors={
            fieldSearch !== '' && filterNodeByFieldSearch.length === 0 ? 1 : 0
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <div className='icon-voicce'>
                  <img
                    src={smLogoVoice}
                    alt='voicce'
                    width={'24px'}
                    height={'24px'}
                  />
                </div>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <Stack direction={'row'} spacing={'6px'}>
                  <ButtonControlSearch
                    aria-label='clear'
                    onClick={handleCleanSearch}
                    disabled={fieldSearch === ''}
                  >
                    {fieldSearch === '' ? ' ' : <IoClose />}
                  </ButtonControlSearch>

                  <ButtonControlSearch
                    aria-label='decrement'
                    onClick={decrement}
                    disabled={positionSearch === 0}
                  >
                    <IoIosArrowDown />
                  </ButtonControlSearch>
                  <span className='total-text-search'>
                    {fieldSearch !== '' && filterNodeByFieldSearch.length > 0
                      ? positionSearch + 1
                      : 0}
                    /{fieldSearch !== '' ? filterNodeByFieldSearch.length : 0}
                  </span>
                  <ButtonControlSearch
                    aria-label='increment'
                    onClick={increment}
                    disabled={
                      filterNodeByFieldSearch.length === positionSearch + 1
                    }
                  >
                    <IoIosArrowUp />
                  </ButtonControlSearch>
                </Stack>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </ContainerPanelSearch>
  );
};
