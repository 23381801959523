import { useTranslation } from 'react-i18next';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ContentFooterNode } from './styled';
import { memo } from 'react';

const FooterNodeComponent = ({ closeMenu, disabledSubmitBtn = false }) => {
  const { t } = useTranslation();
  return (
    <ContentFooterNode>
      <Button typeText variant={'secondary'} onClick={closeMenu}>
        {t('flowBuilder.flow.nodes.persona.menu.btn-cancel')}
      </Button>
      <Button typeText type='submit' disabled={disabledSubmitBtn}>
        {t('flowBuilder.flow.nodes.persona.menu.btn-updated')}
      </Button>
    </ContentFooterNode>
  );
};

export const FooterNode = memo(FooterNodeComponent);
