import { AiOutlineUser } from 'react-icons/ai';
import { StyledTitleComponent } from './styled';
import {
  MdOutlineSwapVerticalCircle,
  MdPersonPin,
  MdOutlineFeaturedPlayList,
  MdOutlineScreenShare,
} from 'react-icons/md';
import { RiRouteLine, RiSoundModuleLine } from 'react-icons/ri';
import {
  IoLockOpenOutline,
  IoPlayCircleSharp,
  IoSearchOutline,
} from 'react-icons/io5';
import { BsCreditCard2Back, BsPhoneVibrate } from 'react-icons/bs';
import { TbPlugConnected, TbServerCog } from 'react-icons/tb';
import { PiFileCsv } from 'react-icons/pi';

export const TitleComponent = ({ textTitle = '', iconTitle = '', ...rest }) => {
  const renderIcon = () => {
    switch (iconTitle) {
      case 'user':
        return <AiOutlineUser />;

      case 'userAgents':
        return <MdPersonPin />;

      case 'routingProfile':
        return <RiRouteLine />;

      case 'securityProfile':
        return <IoLockOpenOutline />;

      case 'agents':
        return <MdOutlineSwapVerticalCircle />;

      case 'mailing':
        return <MdOutlineFeaturedPlayList />;

      case 'addCampaign':
        return <RiSoundModuleLine />;

      case 'client':
        return <BsCreditCard2Back />;

      case 'screen':
        return <MdOutlineScreenShare />;

      case 'tenants':
        return <TbServerCog />;

      case 'config':
        return <RiSoundModuleLine />;

      case 'callOperator':
        return <BsPhoneVibrate />;

      case 'player':
        return <IoPlayCircleSharp />;

      case 'search':
        return <IoSearchOutline />;

      case 'connected':
        return <TbPlugConnected />;

      case 'csvFile':
        return <PiFileCsv />;

      default:
        return ' ';
    }
  };

  return (
    <StyledTitleComponent {...rest}>
      {renderIcon()}
      <h2>{textTitle}</h2>
    </StyledTitleComponent>
  );
};
