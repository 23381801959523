import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SectionItems = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  user-select: none;

  .activeItem {
    background: ${(props) => props.theme.colors.bases.gradientBg};
    border-radius: 4px;

    span,
    svg {
      color: white !important;
      /* color: ${(props) =>
        props.theme.colors.typography.activeTextSidebar} !important;
      user-select: none; */
    }
  }

  .activeItemDropDown {
    background: ${(props) => props.theme.colors.bases.gradientBg};
  }

  h2 {
    /* font-family: Poppins, sans-serif; */
    font-size: 0.8rem;
    padding: 10px 10px 10px 0px;
    color: ${(props) => props.theme.colors.typography.baseText};
    /* font-weight: 700; */
  }
  ul li {
    list-style: none;
    padding: 5px 0;
    border-radius: 5px;

    @media (max-width: 1550px) {
      padding: 3px 0;
    }

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .dividerHorizontal {
    width: 90%;
    height: 1px;
    background: ${(props) => props.theme.colors.typography.baseText};
    opacity: 0.3;
  }
`;
