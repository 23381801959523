import { t } from 'i18next';
import { ContentDataDetail } from '../../styled';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { selectedQueueMonitor } from '@/store/QueueMonitor';

export const CardSelectedQueues = ({ dataList }) => {
  const [currentTotal, setCurrentTotal] = useState(0);
  const [selectedQueue] = useAtom(selectedQueueMonitor);

  const renderDispositions = (dataList) => {
    const replacedItem = dataList
      .replace('CODLIG USADOS: ', '')
      .split(' ')
      .filter((item) => item)
      .join(', ');

    return replacedItem === '' ? '-' : replacedItem;
  };

  useEffect(() => {
    if (dataList && selectedQueue) {
      const filterSelected = dataList.filter(
        (row) => row.fila === selectedQueue.fila
      );
      if (filterSelected.length === 0) return;
      setCurrentTotal(
        filterSelected[0].discando + filterSelected[0].falando_ura
      );
    }
  }, [dataList, selectedQueue]);

  return (
    <ContentDataDetail initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div className='detail-queues'>
        <div className='item-detail'>
          <span>{t('queueMonitor.table.th-campaign')}: </span>
          <strong>{selectedQueue.campanha_descricao}</strong>
        </div>
        <div className='item-detail'>
          <span>{t('queueMonitor.table.th-list')}: </span>
          <strong>{selectedQueue.lista}</strong>
        </div>
        <div className='item-detail operators-grid'>
          <span>Telecom: </span>
          <strong>{selectedQueue.operadora}</strong>
        </div>
        <div className='item-detail total-grid '>
          <span>Total: </span>
          <strong>
            {currentTotal} {t('queueMonitor.table.th-attendances')}
          </strong>
        </div>
        <div className='item-detail dispositions-grid'>
          <span>{t('queueMonitor.table.th-dispositions')}: </span>
          <strong>{renderDispositions(selectedQueue.titulo)}</strong>
        </div>
      </div>
    </ContentDataDetail>
  );
};
