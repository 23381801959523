import { apiData } from '@/services/api';

export async function updateUraDispositionsDialing(dataUpdate) {
  try {
    const { data } = await apiData.put(
      'dialer/updateMasterCallingCodeUra',
      dataUpdate
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
