import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'date-fns/locale/pt-BR';
import { addMinutes } from 'date-fns';
// import { saveAs } from 'file-saver';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { CheckboxWithLabel } from '@/components/FormComponents/CheckboxComponent';
import { TimePickerComponent } from '@/components/FormComponents/TimePicker';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import {
  ContainerScheduleMailing,
  FooterScheduleMailing,
  MainScheduleMailing,
} from './styled';
import { dataMailing, fileDataImport, useHeaderCsv } from '@/store/LoadList';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useLoadList } from '@/hooks/LoadList/useLoadList';
import { useStageControlLists } from '../../hooks/useStageControlLists';

export const ScheduleMailing = () => {
  const { t } = useTranslation();
  const { setCurrentStateMailing, toStageProgress, setProgress, nextProgress } =
    useStageControlLists();

  const { mutateCreateList, loadingCreateList } = useLoadList();

  const [file, setFile] = useAtom(fileDataImport);
  const [initTimeField, setInitTimeField] = useState(addMinutes(new Date(), 2));
  const [endTimeField, setEndTimeField] = useState(addMinutes(new Date(), 62));
  const [hasSchedule, setHasSchedule] = useState(false);
  const [hasErrorSchedule, setHasErrorSchedule] = useState(false);
  const [, setRenderHeaderCsv] = useAtom(useHeaderCsv);

  const [mailingData, setMailingData] = useAtom(dataMailing);

  function validateTimes(start, end) {
    const startTime = new Date(start);
    const endTime = new Date(end);

    const startHoursMinutes =
      startTime.getHours() * 60 + startTime.getMinutes();
    const endHoursMinutes = endTime.getHours() * 60 + endTime.getMinutes();

    if (endHoursMinutes < startHoursMinutes) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    if (validateTimes(initTimeField, endTimeField)) {
      return setHasErrorSchedule(true);
    }
    return setHasErrorSchedule(false);
  }, [initTimeField, endTimeField, hasErrorSchedule]);

  const handlePreviousStage = () => {
    setCurrentStateMailing(0);
    toStageProgress(0);
    setProgress(0);
    setMailingData({});
    setFile(null);
    return;
  };

  const handleCreateList = () => {
    // DOWNLOAD CSV CREATED

    // saveAs(mailingData.dataFile, file.name);

    const formData = new FormData();
    formData.append('mailing', mailingData.dataFile, file.name);
    formData.append('campaign', mailingData.campaign);
    formData.append('description', mailingData.description);
    formData.append('ddd_restrictions', mailingData.dddGroup);
    formData.append('uf_restrictions', mailingData.ufGroup);
    formData.append('retry_restrictions', mailingData.renitencyGroup);

    // formData.append('schedule', hasSchedule);
    // if (hasSchedule) {
    //   formData.append('schedule_initial', initTimeField);
    //   formData.append('schedule_final', endTimeField);
    // }

    mutateCreateList(formData, {
      onSuccess: () => {
        setRenderHeaderCsv(false);
        setMailingData(dataMailing);
        setCurrentStateMailing(3);
        nextProgress();
      },
    });
  };

  return (
    <ContainerScheduleMailing initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {loadingCreateList && <BackDropCircularLoading />}
      <MainScheduleMailing>
        <DescriptionInformation
          textDescription={t('listLoader.scheduleList.descriptionSentence')}
        />
        <CheckboxWithLabel
          label={t('listLoader.scheduleList.label-schedule')}
          checked={hasSchedule}
          onChange={(e) => setHasSchedule(e.target.checked)}
        />
        <Stack
          direction={'row'}
          justifyContent={'flex-start'}
          spacing={2}
          width={'100%'}
        >
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ptBR}
          >
            <Box width={'100%'}>
              <TimePickerComponent
                disabled={!hasSchedule}
                label={t('listLoader.scheduleList.label-timeInit')}
                value={initTimeField}
                onChange={setInitTimeField}
                error={hasErrorSchedule}
              />
            </Box>
            <Box width={'100%'}>
              <TimePickerComponent
                disabled={!hasSchedule}
                label={t('listLoader.scheduleList.label-timeEnd')}
                value={endTimeField}
                onChange={setEndTimeField}
                error={hasErrorSchedule}
              />
            </Box>
          </LocalizationProvider>
        </Stack>
      </MainScheduleMailing>

      <FooterScheduleMailing>
        <Button typeText variant={'secondary'} onClick={handlePreviousStage}>
          {t('listLoader.footer.btn-preview')}
        </Button>
        <Button
          typeText
          type='button'
          onClick={handleCreateList}
          disabled={hasErrorSchedule}
        >
          {t('listLoader.footer.btn-confirm')}
        </Button>
      </FooterScheduleMailing>
    </ContainerScheduleMailing>
  );
};
