import * as yup from 'yup';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { FormCurrentLines } from './styled';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { dataSelectedLinesControl, remainingLines } from '@/store/LineControl';
import { useLineControl } from '@/hooks/dialControl/LineControl/useLineControl';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
// import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import useFormControl from '@/hooks/useFormControl';
import { ControlInput } from '@/components/FormComponents/InputComponent';

export const CurrentLines = ({ controlMainStage }) => {
  const { t } = useTranslation();
  const { loadingUpdateCurrentLines, mutateUpdateCurrentLines } =
    useLineControl();

  const [remaining, setRemaining] = useAtom(remainingLines);

  const renderTxt = (word) => {
    return t(`dialControl.lineControl.selectedCampaign.currentLines.${word}`);
  };

  const [selected] = useAtom(dataSelectedLinesControl);

  const lineControlSchema = yup.object().shape({
    currentLines: yup.number().required(),
  });

  const { handleSubmit, control, errors, watch } =
    useFormControl(lineControlSchema);

  const currentLinesWatch = watch('currentLines', 0);

  useEffect(() => {
    if (selected) {
      setRemaining(selected.max_linha_por_agente - currentLinesWatch);
    }
  }, [selected, currentLinesWatch]);

  const handleChangeLines = (data) => {
    console.log('🚀 -> data:', data);

    // mutateUpdateCurrentLines(totalLinesField, {
    //   onSuccess: () => {
    //     successToast(renderTxt('msg-success'));
    //     controlMainStage.toStage(0);
    //   },
    //   onError: () => {
    //     errorToast(renderTxt('msg-error'));
    //   },
    // });
  };

  const handleBackStage = () => {
    controlMainStage.toStage(0);
  };

  return (
    <FormCurrentLines
      onSubmit={handleSubmit(handleChangeLines)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingUpdateCurrentLines && <BackDropCircularLoading />}
      <div className='main'>
        <TitleComponent textTitle={renderTxt('title')} />
        <DescriptionInformation textDescription={renderTxt('description')} />
        <ControlInput
          control={control}
          nameControl={'currentLines'}
          defaultValue={selected.maxLinhasTotal}
          label={renderTxt('label-currentLines')}
          type='number'
          inputProps={{ min: 0, max: selected.max_linha_por_agente }}
          error={Boolean(errors.currentLines)}
        />
      </div>
      <div className='footer'>
        <Button typeText variant={'secondary'} onClick={handleBackStage}>
          {renderTxt('btn-cancel')}
        </Button>
        <Button typeText type='submit' disabled={remaining < 0}>
          {renderTxt('btn-save')}
        </Button>
      </div>
    </FormCurrentLines>
  );
};
