import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerFlowComponent = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 56px);
  display: grid;
  grid-template-columns: 200px 1fr;
  padding: 1rem;
  gap: 1rem;
  position: relative;

  @media (max-width: 600px) {
    width: 500px;
    overflow-x: scroll;
  }

  @media (max-width: 430px) {
    width: 400px;
    overflow-x: scroll;
  }

  @media (max-width: 330px) {
    width: 300px;
    overflow-x: scroll;
  }

  path.react-flow__edge-path {
    stroke-width: 2;
  }

  .react-flow__attribution {
    display: none;
  }

  .react-flow__edge-path-selector:hover {
    cursor: pointer;
  }
  .react-flow__edge-path-selector:hover + .react-flow__edge-path,
  .react-flow__edge-path:hover {
    stroke: #555;
    cursor: pointer;
  }
  .react-flow__edge-path-selector {
    fill: none;
    stroke: transparent;
    stroke-width: 28;
  }

  .edgebutton {
    width: 26px;
    height: 26px;
    background: ${(props) => props.theme.colors.containers.colorNode};
    border: 2px solid gray;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
    opacity: 0;
  }

  .edgebutton:hover {
    opacity: 1;
  }

  .content__panel-edge {
    width: 80px;
    height: 26px;
    background: ${(props) => props.theme.colors.containers.colorNode};
    background: red;
    cursor: pointer;
    font-size: 12px;
    line-height: 1;
  }

  .content__panel-edgen:hover {
    opacity: 1;
  }
`;

export const ContainerFabControl = styled.div`
  position: absolute;
  bottom: 30px;
  right: 15px;

  .listButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: 8px;
    margin-bottom: 4px;

    .subItemsControl {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 1rem;

      svg {
        animation: none;
      }
    }
  }

  button {
    background-color: ${(props) => props.theme.colors.primary};

    svg {
      color: white;
      font-size: 1.4rem;

      @keyframes rotate {
        to {
          transform: rotate(360deg);
        }
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.primaryDark};
      svg {
        animation: rotate 5s linear infinite;
      }
    }
  }
`;
