import styled from 'styled-components';
import { ContainerNodeWrapper, ContentNodeWrapper } from '../styled';

export const NodeTTSContainer = styled(ContainerNodeWrapper)`
  background: ${(props) => props.theme.colors.containers.colorSecondNode};
  border-radius: 4px;
  min-height: 200px;
  position: relative;

  .react-flow__handle {
    &::after {
      background: ${(props) => props.theme.colors.agentColors.AGLIG};
    }
  }

  .react-flow__handle-left {
    left: -7px;
  }

  .react-flow__handle-right {
    right: -8px;
  }
`;

export const NodeContent = styled(ContentNodeWrapper)`
  width: 220px;
  max-width: 220px;

  .header {
    background: ${(props) => props.theme.colors.nodesFlow.ttsVoicceLabs};
  }
`;
