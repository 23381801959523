import { apiFlow } from '../api';

export async function createFlow(dataCreateFlow) {
  try {
    const { data } = await apiFlow.post(`/dialplan`, dataCreateFlow);

    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
