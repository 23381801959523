import { apiData } from '@/services/api';

export async function createQueue(dataCreateQueue) {
  try {
    const { data } = await apiData.post('dialer/createQueue', dataCreateQueue);
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
