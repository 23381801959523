import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerQueueManagement = styled(motion.div)`
  width: 100%;
  height: calc(100% - 44px);

  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  user-select: none;
  padding: 3rem 2rem;

  overflow: auto;

  @media (max-height: 450px) {
    height: 80%;
  }
`;
