import { Stack } from '@mui/system';
import { ContainerPanelReports } from './styled';
import { motion } from 'framer-motion';
import { FaArrowLeft } from 'react-icons/fa';
import { useAtom } from 'jotai';
import { CircularWithValueLabel } from '@/components/layout/CircularWithValueLabel';
import { progressDownload } from '@/store/workersStore';
import { CheckedAnimation } from '@/components/layout/CheckedAnimation';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useTheme } from 'styled-components';
import { MdOutlineDateRange } from 'react-icons/md';

import { formatDate } from '@/utils/formatsDateAndTime';

export const PanelCountReports = ({
  dataCountReport,
  handleBackStage,
  loadingGetDataDownloadList,
}) => {
  const theme = useTheme();
  const changeFlowDialing = () => {
    return;
  };

  const [progress, setProgress] = useAtom(progressDownload);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => setProgress(0), 1000);
    }
  }, [progress]);

  const variantsChecked = {
    show: { opacity: 1, display: 'block' },
    hidden: {
      opacity: 0,
      transition: { delay: 1 },
      transitionEnd: {
        display: 'none',
      },
    },
  };

  return (
    <ContainerPanelReports>
      <Stack direction='column' spacing={1} width={'100%'}>
        <Stack
          direction='row'
          spacing={2}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Stack direction='row' spacing={2} width={'100%'}>
            <motion.div
              whileTap={{ scale: 0.8 }}
              className='icon_handle_back'
              onClick={handleBackStage}
            >
              <FaArrowLeft onClick={() => changeFlowDialing(0)} size='16' />
            </motion.div>
            <div className='itemPanel'>
              <span>
                <strong>{dataCountReport.label ?? '-'}</strong>
              </span>
            </div>
          </Stack>
          <Stack direction='row' spacing={2} justifyContent={'flex-end'}>
            {dataCountReport.dateInit === dataCountReport.dateEnd ? (
              <div className='date-panel-item'>
                <MdOutlineDateRange />
                <span>{formatDate(dataCountReport.dateInit)}</span>
              </div>
            ) : (
              <>
                <div className='date-panel-item'>
                  <MdOutlineDateRange />
                  <span>{formatDate(dataCountReport.dateInit)}</span>
                </div>
                <span>-</span>
                <div className='date-panel-item'>
                  <MdOutlineDateRange />
                  <span>{formatDate(dataCountReport.dateEnd)}</span>
                </div>
              </>
            )}
          </Stack>
          <Stack direction='row' spacing={2}>
            {loadingGetDataDownloadList && (
              <CircularProgress
                size={24}
                sx={{
                  color: `${theme.colors.defaultColors.pink} !important`,
                }}
              />
            )}

            {progress > 0 && progress < 100 && (
              <CircularWithValueLabel value={progress} />
            )}

            <motion.div
              variants={variantsChecked}
              initial={'hidden'}
              animate={progress === 100 ? 'show' : 'hidden'}
            >
              <CheckedAnimation />
            </motion.div>
          </Stack>
        </Stack>
      </Stack>
    </ContainerPanelReports>
  );
};
