import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { currentConfigComponent } from '@/store/DialingStore';
import { ConfigList } from './ConfigList';
import { NavListConfig } from './NavListConfig';
import { NewList } from './NewList';
import { ContainerConfigQueue } from './styled';

export const ConfigsQueues = ({ changeFlowDialing }) => {
  const flowConfig = [
    <NewList key={0} changeFlowDialing={changeFlowDialing} />,
    <ConfigList key={1} changeFlowDialing={changeFlowDialing} />,
  ];
  const [currentComponent, setCurrent] = useAtom(currentConfigComponent);

  useEffect(() => {
    return () => setCurrent(0);
  }, []);

  return (
    <ContainerConfigQueue>
      <NavListConfig />
      {flowConfig[currentComponent]}
    </ContainerConfigQueue>
  );
};
