import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FormMenuNode = styled(motion.form)`
  width: 320px;
  height: 100%;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr 40px;
  gap: 1rem;
  overflow: auto;
`;

export const HeaderNode = styled.header``;

export const MainAddNode = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem 0 0 0.5rem;

  .itens_valid_input {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    font-size: 0.7rem;
    gap: 4px;

    .item-rec-list {
      background: #672e93;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 6px;
      border-radius: 3px;
      padding: 5px 8px;
      box-shadow: 6px 4px 5px -3px rgba(0, 0, 0, 0.24);

      svg {
        color: ${(props) => props.theme.colors.bases.baseWhite};
      }

      span {
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.bases.baseWhite};
        padding-top: 1px;
      }
    }
  }
`;
