import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import {
  focusedFieldArea,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { FormMenuNode, MainAddNode } from './styled';
import { checkLabelExistiNode } from '@/components/pages/FlowBuilder/utils/checkNameNodes';
import { errorToast } from '@/components/FormComponents/Toast';

export const InitNodeMenu = ({ dataNodes }) => {
  const [, setFocusedArea] = useAtom(focusedFieldArea);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [selected, setSelected] = useAtom(selectedNode);
  const [labelNode, setLabelNode] = useState('');

  const { nodes, setNodes } = dataNodes;
  const { id, data } = selected;

  useEffect(() => {
    if (selected) {
      setLabelNode(data.label);
    }
  }, [selected]);

  const closeMenu = () => {
    setShowMenu(false);
    setLabelNode('');
    setSelected(null);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const handleSubmitNewNode = (e) => {
    e.preventDefault();

    const checkLabel = checkLabelExistiNode(id, labelNode, nodes);

    if (checkLabel) {
      return errorToast('Name of the block already used');
    }

    const filteredNode = nodes.map((node) =>
      node.id === id
        ? {
            ...node,
            data: {
              ...node.data,
              label: labelNode,
            },
          }
        : node
    );
    setNodes(filteredNode);
    closeMenu();
  };

  return (
    <FormMenuNode
      onClick={handleChildClick}
      onSubmit={handleSubmitNewNode}
      id='form1'
      name='form1'
    >
      <MainAddNode>
        <TitleComponent textTitle='Edit Init' />

        <InputComponent
          label='Name Init:'
          value={labelNode}
          onChange={(e) => setLabelNode(e.target.value)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />
      </MainAddNode>
    </FormMenuNode>
  );
};
