import { apiData } from '@/services/api';

export async function updateCallOperators(dataUpdate) {
  try {
    const { data } = await apiData.put('dialer/updateOperator', dataUpdate);
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
