import styled from 'styled-components';

export const ContainerNavigationFlowMailing = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  .errorColumn {
    svg {
      padding-top: 3px;
      color: red;
      font-size: 2rem;
    }
  }

  .content-detail {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    span {
      font-size: 0.9rem;
    }
  }
  .content-save {
    button {
      min-width: 20px;
      padding: 6px 10px;
      svg {
        padding-top: 3px;
        color: red;
        font-size: 1.6rem;
      }
    }
  }
`;
