import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ContainerMenuCreate, OptionsIcon, SelectIconsInput } from './styled';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { listIconsSelect } from '@/components/pages/Dashboard/SectionItemNavigation/iconVerify';
import { dataListScreens } from '@/store/NavigationDashboard';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { SwitchComponent } from '@/components/FormComponents/SwitchComponent';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const MenuCreate = ({
  setDataMenuForm,
  dataMenuForm,
  renderSequence = [],
  useIframe,
  setUseIframe,
}) => {
  const { t } = useTranslation();
  const [listScreens] = useAtom(dataListScreens);

  useEffect(() => {
    if (useIframe) {
      return setDataMenuForm((previousValue) => ({
        ...previousValue,
        href: '0',
      }));
    }
    return setDataMenuForm((previousValue) => ({
      ...previousValue,
      link: '',
    }));
  }, [useIframe]);

  const validationAreaMenu = (value) => {
    setDataMenuForm((previousValue) => ({
      ...previousValue,
      areaMenu: value,
    }));
  };

  const validationLinkMenu = (value) => {
    setDataMenuForm((previousValue) => ({
      ...previousValue,
      link: value,
    }));
  };

  const validationHrefMenu = (value) => {
    setDataMenuForm((previousValue) => ({
      ...previousValue,
      href: value,
    }));
  };

  const validationIconMenu = (value) => {
    setDataMenuForm((previousValue) => ({
      ...previousValue,
      icon: value,
    }));
  };

  const validationSequenceMenu = (value) => {
    setDataMenuForm((previousValue) => ({
      ...previousValue,
      sequence: value,
    }));
  };

  let listSequence = Array.from(
    Array(renderSequence.length + 2),
    (_, i) => i + 1
  );

  useEffect(() => {
    return () =>
      setDataMenuForm((previousValue) => ({
        ...previousValue,
        relationship: '0',
        areaMenu: '0',
      }));
  }, []);

  return (
    <ContainerMenuCreate>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TitleComponent
            textTitle={t('screens.addScreen.title-detail-menu')}
            style={{ width: '50%' }}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: '2rem' }}>
          <Stack alignItems={'flex-end'}>
            <SwitchComponent
              value={useIframe}
              onChange={setUseIframe}
              label={t('screens.addScreen.label-externalUrl')}
            />
          </Stack>
        </Grid>
      </Grid>
      <SelectComponent
        defaultValue='0'
        labelSelect={t('screens.addScreen.label-area-menu')}
        value={dataMenuForm.areaMenu}
        onChange={(event) => validationAreaMenu(event.target.value)}
      >
        <ItemSelect value={'0'} disabled>
          {t('screens.addScreen.option-area-select')}
        </ItemSelect>
        <ItemSelect value={'Products'}>
          {t('screens.addScreen.option-area-products')}
        </ItemSelect>
        <ItemSelect value={'Administrator'}>
          {t('screens.addScreen.option-area-administrator')}
        </ItemSelect>
      </SelectComponent>
      {useIframe ? (
        <InputComponent
          value={dataMenuForm.link}
          placeholder={'https://url.com'}
          onChange={(event) => validationLinkMenu(event.target.value)}
          label={t('screens.addScreen.label-externalUrl')}
        />
      ) : (
        <SelectComponent
          defaultValue='0'
          labelSelect={t('screens.addScreen.label-relationship')}
          value={dataMenuForm.href}
          onChange={(event) => validationHrefMenu(event.target.value)}
        >
          <ItemSelect value={'0'} disabled>
            {t('screens.addScreen.option-relationship')}
          </ItemSelect>

          {listScreens.length > 0 &&
            listScreens.map((item, i) => (
              <ItemSelect value={item.id} key={i}>
                {t(`dashboard.${item.name}`)}
              </ItemSelect>
            ))}
        </SelectComponent>
      )}

      <Stack direction={'row'} spacing={1} width={'100%'}>
        <SelectIconsInput
          defaultValue='0'
          labelSelect={t('screens.addScreen.label-icon')}
          value={dataMenuForm.icon}
          onChange={(event) => validationIconMenu(event.target.value)}
        >
          <ItemSelect value={'0'} disabled>
            {t('screens.addScreen.option-icon')}
          </ItemSelect>

          {listIconsSelect.length > 0 &&
            listIconsSelect.map((item, i) => (
              <OptionsIcon value={item.name} key={i}>
                {item.icon}
                <span>{item.name}</span>
              </OptionsIcon>
            ))}
        </SelectIconsInput>

        <SelectComponent
          defaultValue='0'
          labelSelect='Sequence'
          value={dataMenuForm.sequence}
          onChange={(event) => validationSequenceMenu(event.target.value)}
        >
          <ItemSelect value={0} disabled>
            Select sequence menu
          </ItemSelect>
          {listSequence.length > 0 &&
            listSequence.map((item, i) => (
              <ItemSelect
                value={item}
                key={i}
                disabled={renderSequence.includes(item)}
              >
                {item}
              </ItemSelect>
            ))}
        </SelectComponent>
      </Stack>
    </ContainerMenuCreate>
  );
};
