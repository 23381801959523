import styled from 'styled-components';

export const ContainerMenuNode = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 1rem;

  display: grid;
  grid-template-rows: auto 1fr auo;
  gap: 2rem;
`;

export const HeaderMenu = styled.header`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .connect-icon {
    svg {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const MainMenu = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FooterMenu = styled.footer`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;
