import styled from 'styled-components';

export const StyledTitleComponent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  h2 {
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
    padding-top: 2px;
  }

  svg {
    color: ${(props) => props.theme.colors.typography.baseTitle};
  }
`;
