import * as yup from 'yup';
import useFormControl from '@/hooks/useFormControl';

import { useTranslation } from 'react-i18next';
import {
  ContainerSearchCallingEfforts,
  FormSearchCallingEfforts,
} from './styled';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { Stack } from '@mui/system';
import { ControlDataPicker } from '@/components/FormComponents/DataPickerComponent';
import { useState } from 'react';
import { SwitchComponent } from '@/components/FormComponents/SwitchComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { useAtom } from 'jotai';
import {
  dataCallingEfforts,
  dataCampaignsCallingEfforts,
  dataListsCallingEfforts,
} from '@/store/Reports/callingEffortsStore';
import { useCallingEfforts } from '@/hooks/reports/mailingPenetration/useCallingEfforts';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useDispositionsDialing } from '@/hooks/Dispositions/useDispositionsDialing';
import { dataDispositionsDialing } from '@/store/DialingStore';
import { ControlAutoComplete } from '@/components/FormComponents/AutoCompleteSelect';

export const SearchCallingEfforts = ({ changeFlow }) => {
  const { t } = useTranslation();
  const {
    loadingCampaignsReports,
    loadingListsReports,
    mutateCallingEffortsList,
    loadingCallingEffortsList,
  } = useCallingEfforts();
  const { loadingDataDisposition } = useDispositionsDialing();
  const renderText = (spell) =>
    t(`reports.callingEfforts.searchCallingEfforts.${spell}`);

  const [campaignsCallingEfforts] = useAtom(dataCampaignsCallingEfforts);
  const [ListCallingEfforts] = useAtom(dataListsCallingEfforts);
  const [listsDispositions] = useAtom(dataDispositionsDialing);
  const [, setCallingEfforts] = useAtom(dataCallingEfforts);

  const [typeSearch, setTypeSearch] = useState(true);

  const searchCallingEffortsSchema = yup.object().shape({
    initDate: yup
      .string()
      .required()
      .test(
        'initDate-before-endDate',
        'Initial date must be before or equal to end date',
        function (value) {
          const { endDate } = this.parent;
          return new Date(value) <= new Date(endDate);
        }
      ),
    endDate: yup
      .string()
      .required()
      .test(
        'endDate-after-initDate',
        'End date must be after or equal to initial date',
        function (value) {
          const { initDate } = this.parent;
          return new Date(value) >= new Date(initDate);
        }
      ),
    listSearch: yup.object(),
    dispositionSearch: yup.object(),
    campaignSearch: yup.object(),
  });

  const { handleSubmit, control, errors, reset, resetField, watch } =
    useFormControl(searchCallingEffortsSchema);

  const handleChangeType = (value) => {
    if (value) {
      resetField('campaignSearch');
      return setTypeSearch(true);
    }
    resetField('listSearch');
    return setTypeSearch(false);
  };

  const handleSearchCallingEfforts = (data) => {
    const dataEffortsCalls = {
      ...data,
      campaignSearch: data.campaignSearch?.value ?? null,
      listSearch: data.listSearch?.label ?? null,
    };

    mutateCallingEffortsList(dataEffortsCalls, {
      onSuccess: (data) => {
        setCallingEfforts(data);
        reset();
        changeFlow(1);
      },
    });
  };

  const orderListDec = ListCallingEfforts?.sort((a, b) => b.lista - a.lista);

  const renderOptionsList =
    orderListDec?.map((item) => {
      return {
        label: `${item.lista} - ${item.descricao}`,
        value: item.lista,
      };
    }) ?? [];

  const listDispositionsNoDuplicates = listsDispositions.reduce((acc, item) => {
    if (!acc.some((i) => i.cod_lig === item.cod_lig)) {
      acc.push(item);
    }
    return acc;
  }, []);

  const renderOptionsDispositions =
    listDispositionsNoDuplicates?.map((item) => {
      return {
        label: `${item.cod_lig} - ${item.descricao}`,
        value: item.cod_lig,
      };
    }) ?? [];

  const renderOptionsCampaign =
    campaignsCallingEfforts?.map((item) => {
      return {
        label: `${item.campanha} - ${item.descricao}`,
        value: item.campanha,
      };
    }) ?? [];

  const isEmptyCampaigns = watch('campaignSearch');
  const isEmptyList = watch('listSearch');
  const isEmptyDateInit = watch('initDate');
  const isEmptyDateEnd = watch('endDate');

  const checkDatesToSearch =
    isEmptyDateInit?.toString() === 'Invalid Date' ||
    isEmptyDateEnd?.toString() === 'Invalid Date';

  const checkFieldToSearch = typeSearch
    ? isEmptyList
      ? true
      : false
    : isEmptyCampaigns
    ? true
    : false;

  const isLoading =
    loadingCampaignsReports ||
    loadingListsReports ||
    loadingDataDisposition ||
    loadingCallingEffortsList;

  return (
    <ContainerSearchCallingEfforts>
      {isLoading && <BackDropCircularLoading />}
      <TitleComponent textTitle={renderText('title')} iconTitle='search' />
      <DescriptionInformation textDescription={renderText('description')} />
      <FormSearchCallingEfforts
        onSubmit={handleSubmit(handleSearchCallingEfforts)}
      >
        <Stack
          direction={'column'}
          justifyContent={'flex-start'}
          spacing={4}
          height={'100%'}
        >
          <Stack direction={'row'} spacing={2}>
            <ControlDataPicker
              label={renderText('label-initDate')}
              control={control}
              nameControl={'initDate'}
              defaultValue={new Date()}
              disableOpenPicker
            />

            <ControlDataPicker
              label={renderText('label-endDate')}
              control={control}
              nameControl={'endDate'}
              defaultValue={new Date()}
              disableOpenPicker
            />
          </Stack>

          <SwitchComponent
            value={typeSearch}
            onChange={handleChangeType}
            label={
              typeSearch
                ? renderText('label-byList')
                : renderText('label-byCampaign')
            }
          />

          {typeSearch ? (
            <ControlAutoComplete
              control={control}
              nameControl={'listSearch'}
              label={renderText('label-lists')}
              optionsList={renderOptionsList}
              error={Boolean(errors.listSearch)}
            />
          ) : (
            <ControlAutoComplete
              control={control}
              nameControl={'campaignSearch'}
              label={renderText('label-campaigns')}
              optionsList={renderOptionsCampaign}
              error={Boolean(errors.campaignSearch)}
            />
          )}
          <ControlAutoComplete
            control={control}
            nameControl={'dispositionSearch'}
            label={renderText('label-dispositions')}
            optionsList={renderOptionsDispositions}
            error={Boolean(errors.dispositionSearch)}
          />
        </Stack>

        <Stack direction={'row'} justifyContent={'flex-end'}>
          <Button
            typeText
            type='submit'
            disabled={!checkFieldToSearch || checkDatesToSearch}
          >
            {renderText('btn-searchLins')}
          </Button>
        </Stack>
      </FormSearchCallingEfforts>
    </ContainerSearchCallingEfforts>
  );
};
