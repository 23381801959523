import { Background, BackgroundVariant } from 'reactflow';
import { useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { ContainerFlowMailing, ContentListCsv } from './styled';
import { nodeTypes } from './nodesMailing/nodeTypes';
import { edgeTypes } from './edgesMailing/edgeTypes';
import { FooterImport } from '../../components/FooterImport';
import { useFlowMailing } from '../../../../hooks/useFlowMailing';
import { NavigationListFlow } from './NavigationListFlow';
import { errorToast } from '@/components/FormComponents/Toast';
import { useRenderNodesMailing } from '../../../../hooks/useRenderNodesMailing';
import { MenuNodeTemplate } from './MenuNode';
import { ConfirmationToSave } from '../../components/ConfirmationSave';
import { PanelFlowMailing } from './PanelFlowMailing';
import { useLeaveClientConfirmation } from '@/components/pages/LoadList/hooks/useLeaveConfirmation';
import { useRenderPositionsNodes } from '@/components/pages/LoadList/hooks/useRenderPositionsNodes';
import { confirmationOfExchange } from '@/store/NavigationDashboard';
import {
  dataConfirmationToSave,
  dataTemplateMailing,
  hasSavedTemplateMailing,
  savedTemplateMailing,
  selectedTemplate,
} from '@/store/LoadList';

export const ListManagement = ({
  controlStage,
  controlNodes,
  worker,
  mutateDataTemplate,
}) => {
  const reactFlowWrapper = useRef(null);
  const [hasSavedTemplate, setSavedTemplate] = useAtom(hasSavedTemplateMailing);
  const [dataTemplates, setDataTemplates] = useAtom(dataTemplateMailing);
  const [, setDataSavedTemplate] = useAtom(savedTemplateMailing);
  const [layoutSelected, setLayoutSelected] = useAtom(selectedTemplate);
  const [hasErrorColumnsFromCsv, setHasErrorColumnsFromCsv] = useState(false);
  const [, setConfirmationToSave] = useAtom(dataConfirmationToSave);
  const [, setConfirmationExchange] = useAtom(confirmationOfExchange);

  const [reactFlowMailingInstance, setReactFlowMailingInstance] =
    useState(null);

  const {
    ReactFlowProvider,
    ReactFlow,
    nodes,
    edges,
    onConnect,
    onEdgesChange,
    onEdgeClick,
    setNodes,
    setEdges,
  } = useFlowMailing(setHasErrorColumnsFromCsv);

  const {
    renderRelationshipNodes,
    renderRelationshipColumn,
    renderPreviewNodes,
    checkDisabledNext,
  } = useRenderNodesMailing();

  const { bounds } = useRenderPositionsNodes(nodes, reactFlowMailingInstance);

  useLeaveClientConfirmation(!hasSavedTemplate);

  useEffect(() => {
    setConfirmationExchange(!hasSavedTemplate);
  }, [hasSavedTemplate]);

  useEffect(() => {
    renderPreviewNodes(worker, setNodes, setEdges);
  }, [dataTemplates]);

  const handleOpenConfirmation = (dataNext) => {
    setConfirmationToSave({
      open: true,
      next: dataNext,
    });
    return;
  };

  const renderNodeMailing = () => {
    const nodesColumMailing = nodes.filter(
      (node) => node.type === 'nodeMailing'
    );
    const dataCreateCsv = nodesColumMailing.map((node) => {
      return {
        mailing: node.data.label,
        filters: node.data.filters,
        importedFromCsv: renderRelationshipNodes(node.id, nodes, edges),
        columnRelationsImported: renderRelationshipColumn(
          node.id,
          nodes,
          edges
        ),
      };
    });

    controlStage.handleNextStage(dataCreateCsv);
  };

  const nextStepFlowMailing = () => {
    if (edges.length === 0 || !edges) {
      return errorToast('');
    }
    if (!hasSavedTemplate) {
      return handleOpenConfirmation(renderNodeMailing);
    }
    return renderNodeMailing();
  };

  const resetStateFlow = () => {
    setNodes([]);
    setEdges([]);
    setHasErrorColumnsFromCsv(false);
    setDataTemplates(null);
    setDataSavedTemplate(null);
    setLayoutSelected('0');
    setSavedTemplate(true);
    setConfirmationExchange(false);
    controlStage.handlePreviousStage();
  };

  const handleBackStage = () => {
    if (!hasSavedTemplate) {
      return handleOpenConfirmation(resetStateFlow);
    }
    resetStateFlow();
  };

  return (
    <>
      <ReactFlowProvider>
        <ContainerFlowMailing initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <PanelFlowMailing
            layoutSelected={layoutSelected}
            setLayoutSelected={setLayoutSelected}
            dataLayout={dataTemplates}
            hasErrorColumnsFromCsv={hasErrorColumnsFromCsv}
            mutateDataTemplate={mutateDataTemplate}
          />
          <ContentListCsv ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onConnect={onConnect}
              onInit={setReactFlowMailingInstance}
              onEdgesChange={onEdgesChange}
              onEdgeClick={onEdgeClick}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              nodesDraggable={false}
              panOnScroll={true}
              zoomOnDoubleClick={false}
              translateExtent={[
                [bounds.xMin, bounds.yMin],
                [bounds.xMax, bounds.yMax],
              ]}
            >
              <Background id='1' gap={10} variant={BackgroundVariant.Dots} />
              <NavigationListFlow
                dataNodes={{ nodes, setNodes, edges, setEdges }}
                controlNodes={controlNodes}
                layoutSelected={layoutSelected}
                setLayoutSelected={setLayoutSelected}
                dataLayout={dataTemplates}
              />
            </ReactFlow>
          </ContentListCsv>
          <FooterImport
            disabledNext={checkDisabledNext(edges)}
            handleNextStage={nextStepFlowMailing}
            handlePreviousStage={handleBackStage}
          />
          <ConfirmationToSave />
          <MenuNodeTemplate nodes={nodes} setNodes={setNodes} />
        </ContainerFlowMailing>
      </ReactFlowProvider>
    </>
  );
};
