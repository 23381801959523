import { useLayoutEffect, useRef, useState } from 'react';
import useWindowDimensions from '@/hooks/useWidth';
import { motion } from 'framer-motion';
import { TablePagination } from '@/components/Tables/TablePagination';
import { ContainerTableUfs } from './styled';
import { IoCloseOutline } from 'react-icons/io5';
import { MdEdit } from 'react-icons/md';
import { ConfirmationComponent } from '@/components/layout/ConfirmationComponent';
import { dataUfManagerSelected } from '@/store/DialingStore';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { dataListClients } from '@/store/Clients';
import { successToast } from '@/components/FormComponents/Toast';
import { useMappingUfRows } from '../../../hooks/useMappingUfRows';
import { usePermissionsUFManager } from '../../../hooks/usePermissionsUFManager';

export const TableListUfs = ({
  dataTable,
  controlStage,
  loadingDeleteGroupUF,
  mutateDeleteGroupUF,
  mutateGetDataUF,
}) => {
  const { t } = useTranslation();
  const { permissionsEdit, permissionsDelete } = usePermissionsUFManager();

  const { height } = useWindowDimensions();
  const [listClients] = useAtom(dataListClients);
  const { mappingRows } = useMappingUfRows();

  const renderText = (spell) => {
    return t(`dialControl.ufManager.list.table.${spell}`);
  };

  const [selectedUfGroup, setSelectedUfManager] = useAtom(
    dataUfManagerSelected
  );

  const [openConfirmationBox, setOpemConfirmationBox] = useState(false);

  const handleOpemConfirmationRemove = (rowCampaign) => {
    setSelectedUfManager(rowCampaign);
    setOpemConfirmationBox(true);
  };

  const handleCloseConfirmationRemove = () => {
    setSelectedUfManager(null);
    setOpemConfirmationBox(false);
  };

  const handleDeleteGroup = () => {
    mutateDeleteGroupUF(selectedUfGroup.cod_uf_bloq, {
      onSuccess: () => {
        successToast(t(`dialControl.dddManager.create.msg-success-delete`));
        handleCloseConfirmationRemove();
      },
    });
  };

  const handleEditGroup = (dataRow) => {
    mutateGetDataUF(dataRow.cod_uf_bloq, {
      onSuccess: (data) => {
        const dataSelected = {
          name: dataRow.cod_uf_bloq,
          description: dataRow.descricao,
          schedules: mappingRows(data),
        };
        setSelectedUfManager(dataSelected);
        controlStage.toStage(1);
      },
    });
  };

  const searchClientGroup = (idCLient) => {
    if (idCLient) {
      const filterClient = listClients.filter((item) => item.id === idCLient);
      if (filterClient.length > 0) {
        return filterClient[0].commercial_name;
      }
      return idCLient;
    }
    return '-';
  };

  const tableUf = useRef(null);

  const ColumnsGroups = [
    {
      Header: renderText('th-codUf'),
      accessor: 'cod_uf_bloq',
    },
    {
      Header: renderText('th-description'),
      accessor: 'descricao',
    },

    {
      Header: renderText('th-client'),
      accessor: 'id_cliente',
      Cell: ({ row }) => searchClientGroup(row.original.id_cliente),
    },

    {
      Header: renderText('th-action'),
      Cell: ({ row }) => (
        <div className='editRow'>
          <motion.button
            title={renderText('btn-action-remove')}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleOpemConfirmationRemove(row.original)}
            disabled={!permissionsDelete}
          >
            <IoCloseOutline />
          </motion.button>
          <motion.button
            title={renderText('btn-action-edit')}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleEditGroup(row.original)}
            disabled={!permissionsEdit}
          >
            <MdEdit />
          </motion.button>
        </div>
      ),
    },
  ];

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableUf) {
      const tableMaxWidth = tableUf.current.offsetHeight;
      const cellMinWidth = 55;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, tableUf]);

  return (
    <ContainerTableUfs ref={tableUf}>
      <TablePagination
        dataColumns={ColumnsGroups}
        dataTable={dataTable}
        textNoData={renderText('noData')}
        rowsPerPageDisplay={heightTable}
      />

      <ConfirmationComponent
        open={openConfirmationBox}
        close={setOpemConfirmationBox}
        messageConfirm={renderText('msg-remove')}
        actionConfirm={handleDeleteGroup}
        isLoading={loadingDeleteGroupUF}
      />
    </ContainerTableUfs>
  );
};
