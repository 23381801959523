export const listNodesRender = [
  {
    type: 'dialplanVariables',
    show: true,
    description: 'Description Handler',
  },
  {
    type: 'ttsVoicceLabs',
    show: true,
    description: 'Description Handler',
  },
  {
    type: 'recVoz',
    show: true,
    description: 'Description Handler',
  },

  {
    type: 'hangUp',
    show: true,
    description: 'Description Handler',
  },
  {
    type: 'conditionalNode',
    show: true,
    description: 'Description Handler',
  },
  {
    type: 'disposition',
    show: true,
    description: 'Description Handler',
  },
  {
    type: 'setVariables',
    show: true,
    description: 'Description Handler',
  },
  {
    type: 'apiData',
    show: true,
    description: 'Description Handler',
  },
  {
    type: 'loop',
    show: true,
    description: 'Description Handler',
  },
  {
    type: 'transferQueue',
    show: true,
    description: 'Description Handler',
  },

  {
    type: 'dtmf',
    show: false,
    description: 'Description Handler',
  },

  {
    type: 'functionHandler',
    show: false,
    description: 'Description Handler',
  },
];
