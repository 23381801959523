import { useDeferredValue, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { InputAdornment, Stack } from '@mui/material';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';
import { useUsersAgents } from '@hooks/UserAgents/useUsersAgents';
import { Button } from '@components/FormComponents/ButtonComponent';
import { InputComponent } from '@components/FormComponents/InputComponent';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { TitleComponent } from '@components/layout/TitleComponent';
import { removeDuplicates } from '@/utils/removeDuplicateItemsArr';
import { TableUsersAgents } from './TableUsersAgents';
import { ContainerListUsers } from './styled';
import { useRoutingProfile } from '@/hooks/UserAgents/useRoutingProfile';
import { NoPermissionCard } from '@/components/layout/NoPermissionCard';
import { usePermissionsUsers } from '../../hooks/usePermissionsUsers';
import {
  dataUsersAgents,
  stateRenderUsers,
} from '@store/UsersAgentsStore/userAgentsStore';

export const HomeUsers = () => {
  const { t } = useTranslation();
  const { permissionsCreate, isSysAdmin, isAdmin } = usePermissionsUsers();

  const {
    isLoadingRoutingProfile,
    isFetchingRouting,
    mutateRoutingById,
    loadingRoutingById,
  } = useRoutingProfile('homeUsers');
  const { isLoadingDataUsers, isLoadingDataClients } = useUsersAgents();

  const [dataListUsers] = useAtom(dataUsersAgents);
  const [, setStateRenderUsersAgents] = useAtom(stateRenderUsers);

  const [searchUser, setSearchUser] = useState('');
  const deferredSearchUser = useDeferredValue(searchUser);

  const handleAddUser = () => {
    setStateRenderUsersAgents(1);
  };

  const filterAllTableUsers = (dataFilter) => {
    if (dataFilter) {
      const filterTableUserName = dataFilter.filter((data) =>
        data.name.toLowerCase().includes(deferredSearchUser.toLowerCase())
      );
      const filterTableEmail = dataFilter.filter((data) =>
        data.email.toLowerCase().includes(deferredSearchUser.toLowerCase())
      );
      const newArrFiltered = [...filterTableUserName, ...filterTableEmail];
      return removeDuplicates(newArrFiltered);
    }
  };

  const filteredData = filterAllTableUsers(dataListUsers);

  const isDataLoading =
    isLoadingDataUsers ||
    isLoadingDataClients ||
    isLoadingRoutingProfile ||
    isFetchingRouting ||
    loadingRoutingById;

  return (
    <ContainerListUsers initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isDataLoading && <BackDropCircularLoading />}

      <TitleComponent
        textTitle={t('usersAgentsList.title-userAgents')}
        iconTitle='userAgents'
      />

      {!isSysAdmin ? (
        <NoPermissionCard message={t('usersAgentsList.table.noData')} />
      ) : (
        <>
          <InputComponent
            placeholder={t('usersAgentsList.placeholder-searchUser')}
            value={searchUser}
            onChange={(e) => setSearchUser(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <BiSearch />
                </InputAdornment>
              ),
            }}
          />
          <div className='content_btn_new_user'>
            <Button
              onClick={handleAddUser}
              title={t('usersAgentsList.btn-addUser')}
              disabled={!permissionsCreate || !isAdmin}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <AiOutlineUserAdd size={16} />
                <span>{t('usersAgentsList.btn-addUser')}</span>
              </Stack>
            </Button>
          </div>

          <TableUsersAgents
            dataTable={filteredData}
            mutateRoutingById={mutateRoutingById}
          />
        </>
      )}
    </ContainerListUsers>
  );
};
