import { useId, useState } from 'react';
import { ContainerSchedulesQueue } from './styled';
import { ListQueueSchedules } from './stages/ListQueueSchedules';
import { SelectedScheduleQueue } from './stages/SelectedScheduleQueue';
import { AddScheduleQueue } from './stages/AddScheduleQueue';
import { EditScheduleQueue } from './stages/EditScheduleQueue';

export const SchedulerQueue = () => {
  const [currentStage, setCurrentStage] = useState(0);

  const controlStage = {
    previousStage: () => setCurrentStage((currentStage) => currentStage - 1),
    nextStage: () => setCurrentStage((currentStage) => currentStage + 1),
    toStage: (stage) => setCurrentStage(stage),
  };

  const flowSchedulerQueue = [
    <ListQueueSchedules key={useId()} controlStage={controlStage} />,
    <SelectedScheduleQueue key={useId()} controlStage={controlStage} />,
    <AddScheduleQueue key={useId()} controlStage={controlStage} />,
    <EditScheduleQueue key={useId()} controlStage={controlStage} />,
  ];

  return (
    <ContainerSchedulesQueue>
      {flowSchedulerQueue[currentStage]}
    </ContainerSchedulesQueue>
  );
};
