import { motion } from 'framer-motion';
import styled from 'styled-components';

export const MainTrunksContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ContentMainTrunks = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
`;

export const ContentAddTrunk = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  gap: 2rem;
  padding-top: 1rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;

    .content-form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .divider {
    width: 100%;
    background-color: #e8e8e8;
  }

  .content-list-trunks {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 1.4rem;

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style: none;
      padding-right: 4px;

      li {
        display: flex;
        padding: 0.7rem;
        background-color: ${(props) =>
          props.theme.colors.containers.baseContainer};
        border-radius: 3px;
        justify-content: space-between;
        align-items: center;

        svg {
          color: ${(props) => props.theme.colors.typography.baseText};
        }

        span {
          font-size: 0.8rem;
          color: ${(props) => props.theme.colors.typography.baseText};
          padding-top: 2px;
        }
      }
    }
  }
`;

export const ButtonRemoveTrunk = styled(motion.button)`
  all: unset;
  display: flex;
  svg {
    font-size: 1.1rem;
    color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
