import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const ContainerWavesurfer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    all: unset;

    svg {
      font-size: 2.4rem;
    }
  }
`;

export const PlayerTimes = styled(motion.div)`
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;

  button {
    all: unset;

    svg {
      font-size: 2.4rem;
    }
  }

  span {
    width: 80px;
    font-size: 0.7rem;
    color: ${(props) => props.theme.colors.typography.baseText};

    &:last-child {
      text-align: end;
    }
  }
`;

export const ButtonAudio = styled(motion.button)`
  width: 32px !important;
  height: 32px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 30px !important;
  color: ${(props) => props.theme.colors.typography.baseText} !important;

  ${(props) =>
    props.styledType === 'play' &&
    css`
      width: 44px !important;
      height: 44px !important;
      svg {
        color: ${(props) => props.theme.colors.primary} !important;
        font-size: 3.2rem !important;
      }
    `}

  svg {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
    font-size: 1rem;
  }

  &:disabled {
    opacity: 0.3;
  }
`;

export const ContainerTimersAudio = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span {
    color: ${(props) => props.theme.colors.typography.baseText};
    font-size: 0.8rem;
  }
`;
