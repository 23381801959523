import { apiData } from '@/services/api';

export async function createRoutingProfileUser(dataCreate) {
  try {
    const { data } = await apiData.post(
      'securityProfiles/relateRoutingUsers',
      dataCreate
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
}
