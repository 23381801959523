import useFormControl from '@/hooks/useFormControl';
import { Button } from '@/components/FormComponents/ButtonComponent';
import {
  ContentCallAttempts,
  FooterEditGroup,
  FormEditGroup,
  MainEditGroup,
  TableEditConfig,
} from './styled';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { SwitchComponent } from '@/components/FormComponents/SwitchComponent';
import { useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { successToast } from '@/components/FormComponents/Toast';
import { useAtom } from 'jotai';
import { dataSelectedRenitency } from '@/store/Renitency';
import { useRenitency } from '@/hooks/dialControl/Renitency/useRenitency';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { user } from '@/store/AuthenticatorStore';
import { editCampaignSchema } from './renitencySchema';

export const EditGroupRenitency = ({ controlStage }) => {
  const { t } = useTranslation();
  const { mutateUpdatedRenitency, loadingUpdatedRenitency } = useRenitency();

  const [currentUser] = useAtom(user);

  const [selectedRenitency, setSelectedRenitency] = useAtom(
    dataSelectedRenitency
  );

  const renderTextCreate = (spell) => {
    return t(`dialControl.renitency.create.${spell}`);
  };

  const [callAttempts, setCallAttempts] = useState(false);
  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (selectedRenitency) {
      setCallAttempts(selectedRenitency.habilitar_telefone ? true : false);
      setStatus(selectedRenitency.ativo ? true : false);
    }
  }, []);

  const { handleSubmit, control, errors, reset } =
    useFormControl(editCampaignSchema);

  const handleBackGroup = () => {
    setCallAttempts(false);
    reset();
    controlStage.toStage(0);
    setSelectedRenitency(null);
  };
  const handleEditGroup = (data) => {
    const dataEdit = {
      id_regra_renitencia: String(selectedRenitency.id_regra_renitencia),
      id_cliente: currentUser.client_id,
      descricao: data.description,
      max_ocup: String(data.busyMaximumNumberAttempts),
      intervalo_ocup: String(data.busyIntervalBetweenCalls),
      max_sece: String(data.answeringMachineMaximumNumberAttempts),
      intervalo_sece: String(data.answeringMachineIntervalBetweenCalls),
      max_nao_atende: String(data.doesNotAttendMaximumNumberAttempts),
      intervalo_nao_atende: String(data.doesNotAttendIntervalBetweenCalls),
      max_sem_interacao: String(data.noInteractionMaximumNumberAttempts),
      intervalo_sem_interacao: String(data.noInteractionIntervalBetweenCalls),
      max_invalido: String(data.invalidNumberMaximumNumberAttempts),
      intervalo_invalido: String(data.invalidNumberIntervalBetweenCalls),
      max_tentativa_sistemico: String(data.systemicFinishMaximumNumberAttempts),
      intervalo_sistemico: String(data.systemicFinishIntervalBetweenCalls),
      max_tentativa_nao_sistemico: String(
        data.nonSystemicTerminationMaximumNumberAttempts
      ),
      intervalo_nao_sistemico: String(
        data.nonSystemicTerminationIntervalBetweenCalls
      ),
      max_tentativa_fone1: String(data.attempt_1) ?? '0',
      max_tentativa_fone2: String(data.attempt_2) ?? '0',
      max_tentativa_fone3: String(data.attempt_3) ?? '0',
      max_tentativa_fone4: String(data.attempt_4) ?? '0',
      max_tentativa_fone5: String(data.attempt_5) ?? '0',
      max_tentativa_fone6: String(data.attempt_6) ?? '0',
      max_tentativa_fone7: String(data.attempt_7) ?? '0',
      max_tentativa_fone8: String(data.attempt_8) ?? '0',
      max_tentativa_fone9: String(data.attempt_9) ?? '0',
      max_tentativa_fone10: String(data.attempt_10) ?? '0',
      max_tentativas_unique: callAttempts ? '1' : '0',
      ativo: status ? '1' : '0',
      habilitar_telefone: callAttempts ? '1' : '0',
    };

    mutateUpdatedRenitency(dataEdit, {
      onSuccess: () => {
        successToast(t(`dialControl.renitency.edit.messages.success-updated`));
        handleBackGroup();
      },
    });
  };

  return (
    <FormEditGroup
      onSubmit={handleSubmit(handleEditGroup)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingUpdatedRenitency && <BackDropCircularLoading />}
      <MainEditGroup>
        <Stack width={'100%'} direction={'row'} spacing={2}>
          <ControlInput
            control={control}
            defaultValue={selectedRenitency.id_regra_renitencia}
            nameControl={'nameGroup'}
            label={renderTextCreate('name')}
            error={Boolean(errors.nameGroup)}
            InputProps={{
              readOnly: true,
            }}
          />
          <ControlInput
            control={control}
            nameControl={'description'}
            defaultValue={selectedRenitency.descricao}
            label={renderTextCreate('label-description')}
            error={Boolean(errors.description)}
          />
        </Stack>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          width={'100%'}
          padding={'0 0.6rem'}
        >
          <SwitchComponent
            value={callAttempts}
            onChange={setCallAttempts}
            label={renderTextCreate('label-attempts')}
          />

          <SwitchComponent
            value={status}
            onChange={setStatus}
            label={
              status
                ? renderTextCreate('label-active-status')
                : renderTextCreate('label-disabled-status')
            }
          />
        </Stack>
        <TableEditConfig>
          <thead>
            <tr>
              <th>{renderTextCreate('table.th-callFinish')}</th>
              <th>{renderTextCreate('table.th-maxAmountAttempts')}</th>
              <th>{renderTextCreate('table.th-intervalCalls')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{renderTextCreate('table.td-busy')}</td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.max_ocup}
                  nameControl={'busyMaximumNumberAttempts'}
                  error={Boolean(errors.busyMaximumNumberAttempts)}
                  type='number'
                  inputProps={{ min: 0 }}
                />
              </td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.intervalo_ocup}
                  nameControl={'busyIntervalBetweenCalls'}
                  type='number'
                  error={Boolean(errors.busyIntervalBetweenCalls)}
                  inputProps={{ min: 0 }}
                />
              </td>
            </tr>
            <tr>
              <td>{renderTextCreate('table.td-answeringMachine')}</td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.max_sece}
                  nameControl={'answeringMachineMaximumNumberAttempts'}
                  error={Boolean(errors.answeringMachineMaximumNumberAttempts)}
                  inputProps={{ min: 0 }}
                />
              </td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.intervalo_sece}
                  nameControl={'answeringMachineIntervalBetweenCalls'}
                  error={Boolean(errors.answeringMachineIntervalBetweenCalls)}
                  type='number'
                  inputProps={{ min: 0 }}
                />
              </td>
            </tr>
            <tr>
              <td>{renderTextCreate('table.td-doesNotAttend')}</td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.max_nao_atende}
                  nameControl={'doesNotAttendMaximumNumberAttempts'}
                  error={Boolean(errors.doesNotAttendMaximumNumberAttempts)}
                  type='number'
                  inputProps={{ min: 0 }}
                />
              </td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.intervalo_nao_atende}
                  nameControl={'doesNotAttendIntervalBetweenCalls'}
                  error={Boolean(errors.doesNotAttendIntervalBetweenCalls)}
                  type='number'
                  inputProps={{ min: 0 }}
                />
              </td>
            </tr>
            <tr>
              <td>{renderTextCreate('table.td-withoutInteraction')}</td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.max_sem_interacao}
                  nameControl={'noInteractionMaximumNumberAttempts'}
                  error={Boolean(errors.noInteractionMaximumNumberAttempts)}
                  type='number'
                  inputProps={{ min: 0 }}
                />
              </td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.intervalo_sem_interacao}
                  nameControl={'noInteractionIntervalBetweenCalls'}
                  error={Boolean(errors.noInteractionIntervalBetweenCalls)}
                  type='number'
                  inputProps={{ min: 0 }}
                />
              </td>
            </tr>
            <tr>
              <td>{renderTextCreate('table.td-invalidNumber')}</td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.max_invalido}
                  nameControl={'invalidNumberMaximumNumberAttempts'}
                  error={Boolean(errors.invalidNumberMaximumNumberAttempts)}
                  type='number'
                  inputProps={{ min: 0 }}
                />
              </td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.intervalo_invalido}
                  nameControl={'invalidNumberIntervalBetweenCalls'}
                  error={Boolean(errors.invalidNumberIntervalBetweenCalls)}
                  type='number'
                  inputProps={{ min: 0 }}
                />
              </td>
            </tr>
            <tr>
              <td>{renderTextCreate('table.td-systemicFinishing')}</td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.max_tentativa_sistemico}
                  nameControl={'systemicFinishMaximumNumberAttempts'}
                  error={Boolean(errors.systemicFinishMaximumNumberAttempts)}
                  type='number'
                  inputProps={{ min: 0 }}
                />
              </td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.intervalo_sistemico}
                  nameControl={'systemicFinishIntervalBetweenCalls'}
                  error={Boolean(errors.systemicFinishIntervalBetweenCalls)}
                  type='number'
                  inputProps={{ min: 0 }}
                />
              </td>
            </tr>
            <tr>
              <td>{renderTextCreate('table.td-nonSystemicFinishing')}</td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.max_tentativa_nao_sistemico}
                  nameControl={'nonSystemicTerminationMaximumNumberAttempts'}
                  error={Boolean(
                    errors.nonSystemicTerminationMaximumNumberAttempts
                  )}
                  type='number'
                  inputProps={{ min: 0 }}
                />
              </td>
              <td>
                <ControlInput
                  control={control}
                  defaultValue={selectedRenitency.intervalo_nao_sistemico}
                  nameControl={'nonSystemicTerminationIntervalBetweenCalls'}
                  error={Boolean(
                    errors.nonSystemicTerminationIntervalBetweenCalls
                  )}
                  type='number'
                  inputProps={{ min: 0 }}
                />
              </td>
            </tr>
          </tbody>
        </TableEditConfig>

        {callAttempts && (
          <ContentCallAttempts
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <ControlInput
              control={control}
              type='number'
              label={t(`dialControl.renitency.create.label-phone`, {
                numb: 1,
              })}
              defaultValue={selectedRenitency.max_tentativa_fone1}
              nameControl={'attempt_1'}
              error={Boolean(errors.attempt_1)}
              inputProps={{ min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
            />

            <ControlInput
              control={control}
              type='number'
              label={t(`dialControl.renitency.create.label-phone`, {
                numb: 2,
              })}
              defaultValue={selectedRenitency.max_tentativa_fone2}
              nameControl={'attempt_2'}
              error={Boolean(errors.attempt_2)}
              inputProps={{ min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
            />

            <ControlInput
              control={control}
              type='number'
              label={t(`dialControl.renitency.create.label-phone`, {
                numb: 3,
              })}
              defaultValue={selectedRenitency.max_tentativa_fone3}
              nameControl={'attempt_3'}
              error={Boolean(errors.attempt_3)}
              inputProps={{ min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
            />

            <ControlInput
              control={control}
              type='number'
              label={t(`dialControl.renitency.create.label-phone`, {
                numb: 4,
              })}
              defaultValue={selectedRenitency.max_tentativa_fone4}
              nameControl={'attempt_4'}
              error={Boolean(errors.attempt_4)}
              inputProps={{ min: 0 }}
            />

            <ControlInput
              control={control}
              type='number'
              label={t(`dialControl.renitency.create.label-phone`, {
                numb: 5,
              })}
              defaultValue={selectedRenitency.max_tentativa_fone5}
              nameControl={'attempt_5'}
              error={Boolean(errors.attempt_5)}
              inputProps={{ min: 0 }}
            />

            <ControlInput
              control={control}
              type='number'
              label={t(`dialControl.renitency.create.label-phone`, {
                numb: 6,
              })}
              defaultValue={selectedRenitency.max_tentativa_fone6}
              nameControl={'attempt_6'}
              error={Boolean(errors.attempt_6)}
              inputProps={{ min: 0 }}
            />

            <ControlInput
              control={control}
              type='number'
              label={t(`dialControl.renitency.create.label-phone`, {
                numb: 7,
              })}
              defaultValue={selectedRenitency.max_tentativa_fone7}
              nameControl={'attempt_7'}
              error={Boolean(errors.attempt_7)}
              inputProps={{ min: 0 }}
            />

            <ControlInput
              control={control}
              type='number'
              label={t(`dialControl.renitency.create.label-phone`, {
                numb: 8,
              })}
              defaultValue={selectedRenitency.max_tentativa_fone8}
              nameControl={'attempt_8'}
              error={Boolean(errors.attempt_8)}
              inputProps={{ min: 0 }}
            />

            <ControlInput
              control={control}
              type='number'
              label={t(`dialControl.renitency.create.label-phone`, {
                numb: 9,
              })}
              defaultValue={selectedRenitency.max_tentativa_fone9}
              nameControl={'attempt_9'}
              error={Boolean(errors.attempt_9)}
              inputProps={{ min: 0 }}
            />

            <ControlInput
              control={control}
              label={t(`dialControl.renitency.create.label-phone`, {
                numb: 10,
              })}
              type='number'
              defaultValue={selectedRenitency.max_tentativa_fone10}
              nameControl={'attempt_10'}
              error={Boolean(errors.attempt_10)}
              inputProps={{ min: 0 }}
            />
          </ContentCallAttempts>
        )}
      </MainEditGroup>
      <FooterEditGroup>
        <Button typeText variant={'secondary'} onClick={handleBackGroup}>
          {t(`dialControl.renitency.edit.btn-cancel`)}
        </Button>
        <Button type='submit' typeText>
          {t(`dialControl.renitency.edit.btn-update`)}
        </Button>
      </FooterEditGroup>
    </FormEditGroup>
  );
};
