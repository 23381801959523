import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { Stack, Box } from '@mui/material';
import { ContainerListGroups } from './styled';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { TableGroupsRenitency } from './TableGroupsRenitency';
import { useRenitency } from '@/hooks/dialControl/Renitency/useRenitency';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { dataListRenitency } from '@/store/Renitency';
import { useClients } from '@/hooks/Clients/useClients';
import { DialogComponent } from '@/components/DialogComponent';
import { CreateGroupRenitency } from '../CreateGroupRenitency';
import { usePermissionsRenitency } from '../../hooks/usePermissionsRenitency';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const ListGroupsRenitency = ({ controlStage }) => {
  const { t } = useTranslation();
  const { permissionsCreate } = usePermissionsRenitency();
  const { isLoadingDataClients } = useClients();
  const [listRenitency] = useAtom(dataListRenitency);
  const [openCreateRule, setOpenCreateRule] = useState(false);
  const [filterGroup, setFilterGroup] = useState('0');
  const {
    loadingDataRenitency,
    mutateDeleteRenitency,
    loadingDeleteRenitency,
  } = useRenitency();

  const controlStageCreate = {
    handleCreate: () => setOpenCreateRule(true),
    handleClose: () => setOpenCreateRule(false),
  };

  const renderTextList = (spell) => {
    return t(`dialControl.renitency.list.${spell}`);
  };

  const filterRenitency = listRenitency.filter(
    (data) => data.id_regra_renitencia === filterGroup
  );

  const filteredData =
    listRenitency.length === 0 || filterGroup === '0'
      ? listRenitency
      : filterRenitency;

  const dataTable = filteredData;

  const isLoading =
    loadingDeleteRenitency || loadingDataRenitency || isLoadingDataClients;

  return (
    <ContainerListGroups initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <Stack direction={'column'} spacing={2} justifyContent={'space-between'}>
        <Box>
          <SelectComponent
            labelSelect={renderTextList('label-filter-renitency')}
            value={filterGroup}
            onChange={(e) => setFilterGroup(e.target.value)}
          >
            <ItemSelect value={'0'}>
              {renderTextList('option-filter-renitency')}
            </ItemSelect>
            {listRenitency.length > 0 &&
              listRenitency.map((item, i) => (
                <ItemSelect value={item.id_regra_renitencia} key={i}>
                  {item.id_regra_renitencia} - {item.descricao}
                </ItemSelect>
              ))}
          </SelectComponent>
        </Box>

        <Stack
          direction={'row'}
          spacing={1}
          justifyContent={'flex-end'}
          height={'100%'}
        >
          <Button
            typeText
            onClick={controlStageCreate.handleCreate}
            title={renderTextList('btn-add-renitency')}
            disabled={!permissionsCreate}
            sx={{ padding: '10px' }}
          >
            {renderTextList('btn-add-renitency')}
          </Button>
        </Stack>
      </Stack>
      <TableGroupsRenitency
        dataTable={dataTable}
        controlStage={controlStage}
        mutateDeleteRenitency={mutateDeleteRenitency}
      />

      <DialogComponent
        open={openCreateRule}
        onClose={controlStageCreate.handleClose}
        fullWidth={true}
      >
        <CreateGroupRenitency controlStage={controlStageCreate.handleClose} />
      </DialogComponent>
    </ContainerListGroups>
  );
};
