import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerSchedule = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 3rem 2rem;
  position: relative;
  overflow: scroll;
  gap: 2rem;

  @media (max-height: 400px) {
    height: calc(100vh - 60px);
  }
`;
