export async function updateSystemQueueControl(dataUpdateStatus) {
  try {
    console.log(dataUpdateStatus);
    return new Promise((resolve) => {
      setTimeout(() => resolve(dataUpdateStatus), 500);
    });
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
