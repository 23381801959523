export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export function toPascalCase(str) {
  let words = str.split(' ');

  if (words.length === 1) {
    return str;
  }

  let pascalCaseStr = '';
  for (let word of words) {
    pascalCaseStr += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  return pascalCaseStr;
}
