import styled from 'styled-components';

export const ContainerWeekDays = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 180px 1fr;
  align-items: center;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }

  span {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .content-days {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1rem;

    input {
      display: none !important;
    }

    label {
      border-radius: 4px;
      background: ${(props) => props.theme.colors.containers.baseContainer};
      padding: 0.2rem 0.4rem;
      margin-right: 3px;
      line-height: 28px;
      text-align: center;
      cursor: pointer;
      color: ${(props) => props.theme.colors.typography.baseText};
      box-shadow: 1px 2px 5px -1px rgba(0, 0, 0, 0.4);
      font-size: 0.8rem;
      border: 1px solid transparent;
      transition: all 0.1s ease;
      text-transform: uppercase;

      &:nth-child(${(props) => props.current}) {
        border: 1px solid ${(props) => props.theme.colors.primary};
      }

      :hover {
        border: 1px solid ${(props) => props.theme.colors.primary};
        box-shadow: 1px 1px 1px -1px rgba(0, 0, 0, 0.2);
      }
    }

    .errorDaysWeek {
      border: 1px solid ${(props) => props.theme.colors.error}AA;
    }

    .checkedDay {
      background: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
      :hover {
        border: 1px solid ${(props) => props.theme.colors.primaryDark};
      }
    }
  }
`;
