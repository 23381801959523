import { useId } from 'react';
import { ContainerReluctance } from './styled';
import { ListGroupsRenitency } from './stages/ListGroupsRenitency';
import { EditGroupRenitency } from './stages/EditGroupRenitency';
import { useAtom } from 'jotai';
import { currentStageRenitency } from '@/store/Renitency';

export const RenitencyRules = () => {
  const [currentStageReluctanceRules, setCurrentStageReluctanceRules] = useAtom(
    currentStageRenitency
  );

  const controlStage = {
    previousStage: () =>
      setCurrentStageReluctanceRules(
        (currentStageCampaign) => currentStageCampaign - 1
      ),
    nextStage: () =>
      setCurrentStageReluctanceRules(
        (currentStageCampaign) => currentStageCampaign + 1
      ),
    toStage: (stage) => setCurrentStageReluctanceRules(stage),
    currentStage: currentStageReluctanceRules,
  };

  const flowReluctanceRules = [
    <ListGroupsRenitency key={useId()} controlStage={controlStage} />,
    <EditGroupRenitency key={useId()} controlStage={controlStage} />,
  ];

  return (
    <ContainerReluctance>
      {flowReluctanceRules[currentStageReluctanceRules]}
    </ContainerReluctance>
  );
};
