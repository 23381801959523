import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { successToast } from '@/components/FormComponents/Toast';
import { removeDuplicates } from '@/utils/removeDuplicateItemsArr';
import {
  dataDispositions,
  dataTrunks,
  isSavedFlow,
  templateSelected,
  variablesList,
} from '@/store/FlowBuilder';

export const useRenderDataFlow = () => {
  const { t } = useTranslation();
  const [listVariable, setListVariables] = useAtom(variablesList);
  const [listTrunks] = useAtom(dataTrunks);
  const [listDispositions] = useAtom(dataDispositions);
  const [, setIsSaved] = useAtom(isSavedFlow);
  const [template, setTemplate] = useAtom(templateSelected);

  const checkTrunksInTemplate = (dataTemplate, listTrunksFromApi) => {
    const { trunks, nodes } = dataTemplate;
    if (!trunks) {
      const usedTrunks = nodes
        .filter(
          (node) =>
            node.data.handler === 'transferQueue' &&
            node.data.typeTransfer === 'trunk'
        )
        .map((node) => node.data.transferTo);

      if (usedTrunks.length === 0) {
        return;
      }

      const filterUsedNotListed = listTrunksFromApi
        .map((trunkFromApi) => {
          const hasTrunk = usedTrunks.includes(trunkFromApi.value);
          if (hasTrunk) {
            return trunkFromApi;
          }
          return null;
        })
        .filter((item) => item);

      setTemplate((preview) => {
        return {
          ...preview,
          trunks: filterUsedNotListed,
        };
      });
    }
  };

  const checkDispositionsInTemplate = (template, listUsedDispositions) => {
    const { codeLigs, nodes } = template;
    const usedDispositionsNodes = nodes
      .filter((node) => node.data.handler === 'disposition')
      .map((node) => node.data.label);

    if (!codeLigs) {
      const renderDataUsedDispositions = listUsedDispositions
        .map((deposition) => {
          if (usedDispositionsNodes.includes(deposition.cod_lig)) {
            return deposition;
          }
          return null;
        })
        .filter((disposition) => disposition);

      setTemplate((preview) => {
        return {
          ...preview,
          codeLigs: renderDataUsedDispositions,
        };
      });
      return;
    }

    const prepareListDispositionsFromTemplate = codeLigs
      .map((disposition) => disposition.name)
      .concat(usedDispositionsNodes);

    const uniqueListDispositionsNames = [
      ...new Set(prepareListDispositionsFromTemplate),
    ];

    const renderDispositions = listUsedDispositions
      .map((disposition) => {
        if (uniqueListDispositionsNames.includes(disposition.name)) {
          return disposition;
        }
        return null;
      })
      .filter((disposition) => disposition);

    setTemplate((preview) => {
      return {
        ...preview,
        codeLigs: renderDispositions,
      };
    });
  };

  const renderImportTemplate = (template, dataNodes, listTrunks) => {
    const { nodes, edges, flow } = template;

    checkTrunksInTemplate(template, listTrunks);
    checkDispositionsInTemplate(template, listDispositions);

    let changeSelectedToFalse = nodes.map((node) => {
      return { ...node, selected: false };
    });

    let changeEdgeSelectedToFalse = edges
      .map((edge) => {
        const isConnected = nodes.some((node) => node.id === edge.source);
        if (isConnected) {
          return { ...edge, selected: false };
        }
        return null;
      })
      .filter((item) => item);

    if (flow) {
      setListVariables([]);

      // ADDED VARS FROM CONTEXT
      const filterPropsVariables = Object.entries(flow)
        .filter(
          ([, value]) =>
            value.handler === 'dialplanVariables' && value.listVariables
        )
        .map(([, value]) => value.listVariables)
        .flat();

      // ADDED CONDITIONAL VARS
      const filterConditionsVariablesUtterance = Object.entries(flow)
        .filter(([, value]) => value.handler === 'recVoz' && value.utterance)
        .map(([, value]) => value.utterance);

      const filterConditionsVariablesDtmfs = Object.entries(flow)
        .filter(([, value]) => value.handler === 'dtmf' && value.utterance)
        .map(([, value]) => value.utterance);

      const filterConditionsVariablesSlot = Object.entries(flow)
        .filter(([, value]) => value.handler === 'recVoz' && value.slot)
        .map(([, value]) => value.slot);

      const dataConditionsVars = [
        ...filterConditionsVariablesUtterance,
        ...filterConditionsVariablesSlot,
        ...filterConditionsVariablesDtmfs,
      ];

      const mapCondVars = dataConditionsVars.map((item) => {
        const importVar = {
          id: item,
          name: item,
          value: item,
          parent: '',
          referNode: false,
        };

        return importVar;
      });

      const listVars = listVariable;

      listVars.forEach((item) => {
        const exists = filterPropsVariables.some(
          (varItem) => varItem.id === item.id
        );
        if (!exists) {
          filterPropsVariables.push(item);
        }
      });

      const uniqueList = removeDuplicates([
        ...mapCondVars,
        ...filterPropsVariables,
      ]);

      setListVariables(uniqueList);

      const { setNodes, setEdges } = dataNodes;

      setNodes(changeSelectedToFalse);
      setEdges(changeEdgeSelectedToFalse);
    }
  };

  const renderFlowFromHist = useCallback(
    (dataTemplate, dataNodes) => {
      const { nodes, edges, flow } = dataTemplate;

      checkTrunksInTemplate(dataTemplate, listTrunks);
      checkDispositionsInTemplate(dataTemplate, listDispositions);

      let changeSelectedToFalse = nodes.map((node) => {
        return { ...node, selected: false };
      });

      let changeEdgeSelectedToFalse = edges
        .map((edge) => {
          const isConnected = nodes.some((node) => node.id === edge.source);
          if (isConnected) {
            return { ...edge, selected: false };
          }
          return null;
        })
        .filter((item) => item);

      const filterPropsVariables = Object.entries(flow)
        .filter(
          ([, value]) =>
            value.handler === 'dialplanVariables' && value.listVariables
        )
        .map(([, value]) => value.listVariables)
        .flat();

      // ADDED CONDITIONAL VARS
      const filterConditionsVariablesUtterance = Object.entries(flow)
        .filter(([, value]) => value.handler === 'recVoz' && value.utterance)
        .map(([, value]) => value.utterance);

      const filterConditionsVariablesDtmfs = Object.entries(flow)
        .filter(([, value]) => value.handler === 'dtmf' && value.utterance)
        .map(([, value]) => value.utterance);

      const filterConditionsVariablesSlot = Object.entries(flow)
        .filter(([, value]) => value.handler === 'recVoz' && value.slot)
        .map(([, value]) => value.slot);

      const dataConditionsVars = [
        ...filterConditionsVariablesDtmfs,
        ...filterConditionsVariablesUtterance,
        ...filterConditionsVariablesSlot,
      ];

      const mapCondVars = dataConditionsVars.map((item) => {
        const importVar = {
          id: item,
          name: item,
          value: item,
          parent: '',
          referNode: false,
        };

        return importVar;
      });

      const uniqueList = removeDuplicates([
        ...mapCondVars,
        ...filterPropsVariables,
      ]);

      const updatedListVars = uniqueList;

      updatedListVars.forEach((item) => {
        const exists = filterPropsVariables.some(
          (varItem) => varItem.id === item.id
        );

        if (!exists) {
          filterPropsVariables.push(item);
        }
      });

      setListVariables(updatedListVars);

      const { setNodes, setEdges } = dataNodes;
      setNodes(changeSelectedToFalse);
      setEdges(changeEdgeSelectedToFalse);
      successToast(t('flowBuilder.flow.msg-flow-import'));
      setIsSaved(true);
    },
    [setIsSaved, listTrunks, template]
  );

  return { renderImportTemplate, renderFlowFromHist };
};
