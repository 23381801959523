import { TreeComponent } from './components/TreeComponent';
import { PanelControl } from './components/PanelControl';
import { ContainerJourney } from './styled';
import { useJourney } from '@/hooks/Journey/useJourney';

export const Journey = () => {
  const { dataListTree, dataCampaigns } = useJourney();
  const { dataOptionsCampaigns } = dataCampaigns;
  const { dataList, isLoading, loadingMutate, getListMutate, isFetching } =
    dataListTree;

  const toArr = Object.keys(dataList).length === 0;
  const firstLoading = toArr && isFetching;

  return (
    <ContainerJourney>
      <PanelControl
        getListMutate={getListMutate}
        dataCampaigns={dataOptionsCampaigns}
        isLoading={isLoading || loadingMutate}
      />
      <TreeComponent
        dataChart={dataList}
        isLoadingData={isLoading || loadingMutate || firstLoading}
      />
    </ContainerJourney>
  );
};
