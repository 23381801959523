import { errorToast } from '@/components/FormComponents/Toast';
import { getQueuesCampaigns } from '@/services/dialControl/campaigns/getQueuesCampaigns';
import { dataCampaigns } from '@/store/Campaigns';
import { stateRenderDialing } from '@/store/DialingStore';
import { dataServersControl } from '@/store/Queues';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export const useDialingServers = () => {
  const { t } = useTranslation();
  const [, setDataCampaignsList] = useAtom(dataCampaigns);
  const [stageDialing] = useAtom(stateRenderDialing);
  const [serversControl] = useAtom(dataServersControl);

  const {
    isLoading,
    isFetching,
    refetch: refetchDataQueuesCampaigns,
  } = useQuery(
    'dataQueueCampaign',
    async () => {
      return await getQueuesCampaigns({
        first_queue: serversControl.first_queue,
        last_queue: serversControl.last_queue,
        isActive: true,
      });
    },
    {
      enabled: stageDialing === 0 && !!serversControl,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setDataCampaignsList(data),
      onError: () => {
        errorToast(t('dialing.newListConfig.messages.errorRequesStatus'));
      },
    }
  );

  const isLoadingDataCampaign = isFetching || isLoading;

  return { isLoadingDataCampaign, refetchDataQueuesCampaigns };
};
