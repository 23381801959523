import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerListExtDownload = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
`;

export const ContentListExtDownload = styled(motion.div)`
  display: flex;
  gap: 8px;
`;

export const BtnPanel = styled(ButtonStyled)`
  background-color: ${(props) => (!props.show ? `` : `gray`)} !important;
  div {
    span {
      padding-top: 4px !important;
    }
  }
`;
