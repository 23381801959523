import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import useAuth from '@/hooks/Authenticator/useAuth';

import {
  currentUserAgent,
  dataCallInformation,
  dataTimeToTab,
  exitDialler,
  isLoadingComponent,
} from '@store/AgentsStore';
import { user } from '@store/AuthenticatorStore';

import { agentAuth } from '@services/agents/agentAuth';
import { logOutSystem } from '@services/agents/logOutSystem';
import { dispositionCall } from '@services/agents/dispositionCall';
import { hangUpCall } from '@services/agents/hangUpCall';
import { getAgentInfo } from '@services/agents/getAgentInfo';
import { getInDialer } from '@services/agents/getInDialer';
import { getOutDialer } from '@services/agents/getOutDialer';
import { getPauses } from '@services/agents/getPauses';
import { setBreaks } from '@services/agents/setBreaks';
import { goOutSystem } from '@services/agents/goOutSystem';

import { errorToast } from '@components/FormComponents/Toast';

export const useAgents = () => {
  const { t } = useTranslation();
  const { logOut } = useAuth();

  const [currentUser] = useAtom(user);
  const [, setDataCall] = useAtom(dataCallInformation);
  const [, setTimeToTab] = useAtom(dataTimeToTab);
  const [, setIsLoadingAgent] = useAtom(isLoadingComponent);
  const [, setIsExited] = useAtom(exitDialler);
  const [userAgent] = useAtom(currentUserAgent);

  const currentUserData = {
    extension: currentUser && currentUser.extension,
    queue: currentUser && currentUser.queue,
  };

  const logoutOnErrorReq = (msgError) => {
    errorToast(msgError);
    logOut();
    // new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve(logOut());
    //   }, 3000);
    // });
  };

  const { mutate: mutateAuthAgent } = useMutation({
    mutationFn: async (dataLog) => {
      return await agentAuth(dataLog);
    },
    onError: () => {
      logoutOnErrorReq(t('agents.errorRequestMsg.authAgent'));
    },
    onSuccess: (data) => {
      if (data.erro === '0') {
        new Promise((resolve) => {
          setTimeout(() => {
            resolve(mutateGetAgentsAuth(currentUserData));
          }, 600);
        });
      }
    },
  });

  const { mutate: mutateGetAgentsAuth } = useMutation({
    mutationFn: async (dataLog) => {
      return await getAgentInfo(dataLog);
    },
    onError: () => {
      logoutOnErrorReq(t('agents.errorRequestMsg.getAgent'));
    },
    onSuccess: (data) => {
      if (data.estado === 'LOGOUT') {
        mutateSetPause({
          extension: currentUserData.extension,
          queue: currentUserData.queue,
          status: 'livre',
        });
      } else {
        setDataCall(data);
        setIsLoadingAgent(false);
      }
    },
  });

  const { mutate: mutateGetAgents } = useMutation({
    mutationFn: async (dataLog) => {
      return await getAgentInfo(dataLog);
    },
    onError: () => {
      logoutOnErrorReq(t('agents.errorRequestMsg.getAgent'));
    },
    onSuccess: (data) => {
      if (data.estado === 'LOGOUT') {
        setDataCall(data);
        setIsLoadingAgent(true);
      } else {
        if (data.segundos_acw && data.segundos_acw !== '0') {
          setTimeToTab(data.segundos_acw);
        }
        setDataCall(data);
        setIsLoadingAgent(false);
      }
    },
  });

  const { mutate: mutateGetPauses, isLoading: loadingGetPauses } = useMutation({
    mutationFn: async (dataLog) => {
      setIsLoadingAgent(true);
      return await getPauses(dataLog);
    },
    onError: () => {
      errorToast(t('agents.errorRequestMsg.setPause'));
      setIsLoadingAgent(false);
    },
  });

  const { mutate: mutateSetPause } = useMutation({
    mutationFn: async (dataLog) => {
      setIsLoadingAgent(true);
      return await setBreaks(dataLog);
    },
    onError: () => {
      errorToast(t('agents.errorRequestMsg.setPause'));
      mutateGetAgents(currentUserData);
    },
    onSuccess: (data) => {
      if (data.erro === '0') {
        new Promise((resolve) => {
          setTimeout(() => {
            resolve(mutateGetAgents(currentUserData));
          }, 500);
        });
      }
    },
  });

  const { mutate: mutateOpenAudioCall } = useMutation({
    mutationFn: async (dataLog) => {
      return await getInDialer(dataLog);
    },
    onError: () => {
      errorToast(t('agents.errorRequestMsg.openAudioCall'));
      mutateGetAgents(currentUserData);
    },
    onSuccess: () => {
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(mutateGetAgents(currentUserData));
        }, 500);
      });
    },
  });

  const { mutate: mutateCloseCurrentAudio } = useMutation({
    mutationFn: async (dataLog) => {
      return await getOutDialer(dataLog);
    },
    onError: () => {
      errorToast(t('agents.errorRequestMsg.openAudioCall'));
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(mutateGetAgents(currentUserData));
        }, 500);
      });
    },
  });

  const handleChangePause = (pauseType) => {
    setIsLoadingAgent(true);
    mutateCloseCurrentAudio(
      { extension: currentUserData.extension },
      {
        onSuccess: () =>
          mutateSetPause({
            extension: currentUserData.extension,
            queue: currentUserData.queue,
            status: pauseType,
          }),
      }
    );
  };

  const { mutate: mutateCloseCall, isLoading: loadingCloseCall } = useMutation({
    mutationFn: async (dataLog) => {
      return await dispositionCall(dataLog);
    },
    onError: () => {
      errorToast(t('agents.errorRequestMsg.closeCall'));
      setIsLoadingAgent(false);
    },
    onSuccess: () => {
      setIsLoadingAgent(true);
      mutateGetAgents(currentUserData);
    },
  });

  const { mutate: mutateCloseCurrentCall } = useMutation({
    mutationFn: async (dataLog) => {
      return await hangUpCall(dataLog);
    },
    onError: () => {
      errorToast(t('agents.errorRequestMsg.closeCall'));
      setIsLoadingAgent(false);
    },
    onSuccess: () => {
      setIsLoadingAgent(true);
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(mutateGetAgents(currentUserData));
        }, 500);
      });
    },
  });

  const { mutate: mutateAgentLogout } = useMutation({
    mutationFn: async (dataLog) => {
      return await logOutSystem(dataLog);
    },
    onError: () => {
      errorToast(t('agents.errorRequestMsg.agentLogout'));
    },
  });

  const { mutate: mutateGoOutSystem } = useMutation({
    mutationFn: async (dataLog) => {
      return await goOutSystem(dataLog);
    },
    onError: () => {
      errorToast(t('agents.errorRequestMsg.agentLogout'));
    },
    onSuccess: () => {
      setIsLoadingAgent(false);
      setDataCall({});
      setIsExited(true);
      userAgent.stop();
      logOut();
    },
  });

  return {
    mutateAuthAgent,
    mutateGetAgents,
    mutateSetPause,
    mutateOpenAudioCall,
    mutateCloseCall,
    handleChangePause,

    mutateGetPauses,
    loadingGetPauses,

    loadingCloseCall,
    mutateCloseCurrentCall,
    mutateAgentLogout,
    mutateCloseCurrentAudio,
    mutateGoOutSystem,
  };
};
