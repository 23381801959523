import { InputComponent } from '@/components/FormComponents/InputComponent';
import { ContainerPanelListActive, TitlePanelActiveRecords } from './styled';
import { InputAdornment } from '@mui/material';
import { BiSearch } from 'react-icons/bi';
import { IoArrowBackOutline } from 'react-icons/io5';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { dateSearchRecordings } from '@/store/Recordings/PanelRecords';
import { formatDate } from '@/utils/formatsDateAndTime';
import { dataQueues } from '@/store/Queues';
import { useTranslation } from 'react-i18next';

export const PanelList = ({ dataFilters, backStage }) => {
  const { t } = useTranslation();
  const { searchField, setSearchField } = dataFilters;
  const [dateSearch] = useAtom(dateSearchRecordings);
  const [listQueues] = useAtom(dataQueues);
  const currentDate = formatDate(dateSearch.dateSearch);

  const handleBackStage = () => {
    backStage();
  };

  const renderQueueCampaign = () => {
    const filterCampaign = listQueues.filter(
      (item) => item.fila == dateSearch.queueSearch
    )[0];

    return (
      <span>
        {dateSearch.queueSearch} - {filterCampaign && filterCampaign?.campanha}
      </span>
    );
  };

  return (
    <ContainerPanelListActive>
      <TitlePanelActiveRecords>
        <div className='details-panel'>
          <motion.button onClick={handleBackStage} whileTap={{ scale: 1.1 }}>
            <IoArrowBackOutline />
          </motion.button>
          {renderQueueCampaign()}
        </div>
        <div className='time-panel'>
          <span>{currentDate}</span>
        </div>
      </TitlePanelActiveRecords>
      <InputComponent
        placeholder={t(
          'activeRecordings.listActiveRecords.placeholder-filter-record'
        )}
        value={searchField}
        onChange={(e) => setSearchField(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <BiSearch />
            </InputAdornment>
          ),
        }}
      />
    </ContainerPanelListActive>
  );
};
