import { ContainerSecurityProfile } from './styled';
import { useAtom } from 'jotai';
import { stateRenderSecurityProfiles } from '@store/UsersAgentsStore/securityProfiles';
import { useEffect, useId } from 'react';
import { EditSecurityProfile } from './stages/EditSecurityProfile';
import { HomeSecurityProfile } from './stages/HomeSecurityProfile';

export const SecurityProfile = () => {
  const [currentStateSecurity, setCurrentStateSecurity] = useAtom(
    stateRenderSecurityProfiles
  );

  useEffect(() => {
    return () => {
      setCurrentStateSecurity(0);
    };
  }, []);

  const flowSecurityProfile = [
    <HomeSecurityProfile key={useId()} />,
    <EditSecurityProfile key={useId()} />,
  ];

  return (
    <ContainerSecurityProfile initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {flowSecurityProfile[currentStateSecurity]}
    </ContainerSecurityProfile>
  );
};
