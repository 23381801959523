import { useId } from 'react';
import { useAtom } from 'jotai';
import { FlowBuilderComponent } from './stages/FlowBuilderComponent';
import { FlowSelectProject } from './stages/FlowSelectProject';
import { stageFlow } from '@/store/FlowBuilder';

export const FlowBuilder = () => {
  const [currentStage, setCurrentStage] = useAtom(stageFlow);

  const changeStageFlow = (valueStage) => {
    setCurrentStage(valueStage);
  };

  const renderCurrentPage = [
    <FlowSelectProject key={useId()} changeStageFlow={changeStageFlow} />,
    <FlowBuilderComponent key={useId()} changeStageFlow={changeStageFlow} />,
  ];

  return renderCurrentPage[currentStage];
};
