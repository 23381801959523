import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerStatusQueues = styled(motion.div)`
  width: 100%;

  .selector-queue-dash {
    & > .MuiSelect-select {
      padding-bottom: 7px;
    }
  }

  .content__advanced_search {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      all: unset;
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.baseText};

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const MotionContainer = styled(motion.div)``;
