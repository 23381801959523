import { Checkbox } from '@mui/material';
import styled from 'styled-components';

export const StyledCheckBox = styled(Checkbox)`
  position: absolute !important;
  top: 0;
  color: transparent !important;
`;

export const StyledCheckBoxWithLabel = styled(Checkbox)`
  background-color: red;
  .MuiCheckbox-root.Mui-checked {
    color: red !important;
  }
`;

export const CheckBoxFlowComponent = styled(Checkbox)`
  width: 20px;
  height: 20px;
  color: #9c9c9c !important;
`;
