import styled from 'styled-components';

export const ContainerTable = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  .content_table_grid {
    overflow: auto;
  }

  .unselected-row {
    /* background-color: ${(props) => props.theme.colors.tables.tableHead}; */
  }
`;

export const TableContent = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 2px;
  user-select: text;
  padding: 0 2px;
  transition: all 0.1s ease-in-out;

  .effective-contact-row {
    span {
      color: ${(props) => props.theme.colors.defaultColors.purple}!important;
      font-weight: bold !important;
    }

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }

  .selected-row {
    background-color: #a2a2a299;
    &:hover {
      background-color: #a2a2a299;
    }
  }

  .positive-contact-row {
    span {
      color: ${(props) => props.theme.colors.success}!important;
      font-weight: bold !important;
    }

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }

  .attempts-row {
    span {
      color: ${(props) => props.theme.colors.defaultColors.blue}!important;
      font-weight: bold !important;
    }

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }

  thead {
    tr {
      user-select: none;
      position: relative;

      &:after {
        content: '';
        position: absolute;

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 40px;
        background-color: ${(props) => props.theme.colors.tables.tableHead};
        border-radius: 3px;
      }

      th {
        div {
          position: relative;
          z-index: 2;
          min-width: 60px;
          text-align: initial;
          user-select: none;

          font-size: 0.8rem;
          font-weight: 500;
          text-transform: uppercase;
          color: ${(props) => props.theme.colors.typography.textHeaderTable};

          &:nth-child(1) {
            padding-left: 10px;
          }

          @media (max-width: 1300px) {
            font-size: 0.8rem;
            padding: 15px 5px;

            &:nth-child(1),
            &:nth-child(2) {
              min-width: 40px;
            }
          }

          @media (max-width: 799px) {
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  tfoot {
    tr {
      user-select: none;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        max-height: 60px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background-color: ${(props) =>
          props.theme.colors.containers.baseContainer};

        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        /* border-radius: 3px; */
      }

      td {
        position: relative;
        z-index: 999;
        min-width: 60px;
        text-align: initial;
        font-size: 0.8rem;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px 5px 8px 12px;
        color: ${(props) => props.theme.colors.typography.baseText};

        /* @media (max-width: 1300px) {
          font-size: 0.8rem;
          padding: 15px 5px;

          &:nth-child(1),
          &:nth-child(2) {
            min-width: 40px;
          }
        }

        @media (max-width: 799px) {
          font-size: 0.875rem;
        } */
      }
    }
  }

  .content_th {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.7rem 0px 6px 0px;
    svg {
      font-size: 1rem;
    }
  }
`;

export const ContentTr = styled.tr`
  position: relative;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.containers.colorCard};

  &:hover {
    background-color: ${(props) =>
      props.theme.colors.containers.baseContainer}AA;
  }
`;

export const ContainerTd = styled.td`
  span {
    font-size: 0.8rem;
    font-weight: normal;
    word-wrap: normal;
    color: ${(props) => props.theme.colors.typography.baseText}AA;

    @media (max-width: 799px) {
      font-size: 0.7rem;
    }
  }

  .editRow {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    z-index: 10;
    position: relative;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      color: ${(props) => props.theme.colors.bases.baseWhite};
      padding: 4px;
      border-radius: 4px;

      &:disabled {
        opacity: 0.6;
        background-color: gray !important;
      }

      &:first-child {
        background-color: ${(props) => props.theme.colors.error};
      }
      &:last-child {
        background-color: ${(props) => props.theme.colors.bases.primary};
      }
    }
  }

  .flex_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      color: ${(props) => props.theme.colors.secondary};
      font-size: 1.1rem;
      margin-right: 10px;
    }
  }
`;
