import { Stack } from '@mui/material';
import { FooterConfig } from '../../styled';
import { FaGear } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ContentMainTrunks, MainTrunksContainer } from './styled';
import { DragTransferListConfigs } from '@/components/pages/FlowBuilder/components/DragTransferListConfigs';
import { useTrunks } from '@/hooks/FlowBuilder/useTrunks';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useAtom } from 'jotai';
import { dataTrunks, templateSelected } from '@/store/FlowBuilder';
import { useState } from 'react';

export const ListTrunks = ({ trunks, handleControlStages }) => {
  const { t } = useTranslation();
  const { isLoading } = useTrunks();
  const [trunksList] = useAtom(dataTrunks);
  const [, setTemplate] = useAtom(templateSelected);
  const [dataTrunksSelected, setDatTrunksSelected] = useState([]);

  const { listTrunks, usedTrunks } = trunks;

  const renderAvailableList = () => {
    const availableList = trunksList.map((element) => {
      const filterUsedVars = listTrunks.some(
        (item) => item.name === element.name
      );
      if (filterUsedVars) {
        return null;
      }
      return element;
    });
    const result = [...new Set(availableList.filter((item) => item !== null))];
    return result;
  };

  const handleUpdateTrunks = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        trunks: dataTrunksSelected,
      };
    });

    handleControlStages.handleClose();
    return;
  };

  return (
    <ContentMainTrunks initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4>{t('flowBuilder.configs.trunks.title-trunks')}</h4>
      </Stack>

      <MainTrunksContainer>
        {trunksList.length > 0 && (
          <DragTransferListConfigs
            codeList={renderAvailableList()}
            changeDataCodeList={setDatTrunksSelected}
            dataSelected={listTrunks}
            checkRemoveData={usedTrunks}
            typeList={'Trunks'}
          />
        )}
      </MainTrunksContainer>

      <FooterConfig>
        <Button typeText onClick={handleControlStages.toAddTrunk}>
          {t('flowBuilder.configs.trunks.btn-update')}
        </Button>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <Button
            typeText
            variant='secondary'
            onClick={handleControlStages.handleClose}
          >
            {t('flowBuilder.configs.trunks.btn-cancel')}
          </Button>
          <Button typeText onClick={handleUpdateTrunks}>
            {t('flowBuilder.configs.trunks.btn-add')}
          </Button>
        </Stack>
      </FooterConfig>
    </ContentMainTrunks>
  );
};
