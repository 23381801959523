import { motion } from 'framer-motion';
import { Box, Divider, Stack } from '@mui/material';
import { PanelLineControl } from './styled';
import { dataSelectedLinesControl } from '@/store/LineControl';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa';

export const PanelOperator = ({ controlMainStage }) => {
  const { t } = useTranslation();
  const [selected] = useAtom(dataSelectedLinesControl);

  const renderTxt = (word) => {
    return t(`dialControl.operatorControl.campaignOperator.${word}`);
  };

  return (
    <PanelLineControl>
      <Stack direction='column' spacing={2} padding={1}>
        <Stack direction='row' spacing={2}>
          <motion.div
            whileTap={{ scale: 0.8 }}
            className='icon_handle_back'
            onClick={controlMainStage}
          >
            <FaArrowLeft size='16' />
          </motion.div>
          <div className='itemPanel'>
            <span>{`${selected.fila ?? ''} - ${selected.campanha ?? ''}`}</span>
          </div>
        </Stack>
        <Divider />
        <Stack
          direction='row'
          spacing={2}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Box
            className='status_queue'
            display={'flex'}
            gap={0.5}
            alignItems={'center'}
          >
            <span>{renderTxt('label-uraContext')}:</span>
            <strong>{selected?.contexto_ura ?? '-'}</strong>
          </Box>
        </Stack>
      </Stack>
    </PanelLineControl>
  );
};
