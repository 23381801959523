import styled from 'styled-components';

export const ContentPanelNode = styled.div`
  width: 100%;
  position: absolute;
  right: 4px;
  top: 4px;
  opacity: 0;
  transition: all 0.1s ease;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 4px;
  gap: 10px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;

    svg {
      font-size: 1.3rem;
      color: #fff;
    }
  }

  &:hover {
    opacity: 1;
  }
`;
