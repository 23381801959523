import { getLinesService } from '@/services/monitor/getLinesService';
import { v4 as uuid } from 'uuid';
import { useQuery } from 'react-query';

export const useMonitorLines = () => {
  const status = ['EMLIG', 'DISCANDO', 'LIVRE', 'EMPAUSA'];

  function getRandomStatus() {
    const randomIndex = Math.floor(Math.random() * status.length);
    return status[randomIndex];
  }

  function renderTime() {
    const seconds = Math.floor(Math.random() * 60);
    const minutes = Math.floor(Math.random() * 10);
    return `00:${minutes > 9 ? minutes : `0${minutes}`}:${
      seconds > 9 ? seconds : `0${seconds}`
    }`;
  }

  function renderPhone() {
    const getRandomDigit = () => Math.floor(Math.random() * 10);
    const areaCode = `${getRandomDigit()}${getRandomDigit()}`;
    const firstPart = `${getRandomDigit()}${getRandomDigit()}${getRandomDigit()}${getRandomDigit()}`;
    const secondPart = `${getRandomDigit()}${getRandomDigit()}${getRandomDigit()}${getRandomDigit()}`;

    return `(${areaCode}) ${firstPart}-${secondPart}`;
  }

  const data = [
    {
      id: uuid(),
      fila: '100',
      lista: 'Voicce Labs',
      linha: '100',
      ramal: '0001',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'Lucas Miguel',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '100',
      lista: 'Voicce Labs',
      linha: '100',
      ramal: '0002',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'LUCAS',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '100',
      lista: 'Voicce Labs',
      linha: '100',
      ramal: '0003',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'ISABELLA',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '100',
      lista: 'Voicce Labs',
      linha: '100',
      ramal: '0004',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'ISABELLA',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '100',
      lista: 'Voicce Labs',
      linha: '100',
      ramal: '0005',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'PEDRO',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '100',
      lista: 'Voicce Labs',
      linha: '100',
      ramal: '0006',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'Ana Clara',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '100',
      lista: 'Voicce Labs',
      linha: '100',
      ramal: '0007',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'FELIPE HENRIQUE',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '100',
      lista: 'Voicce Labs',
      linha: '100',
      ramal: '0008',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'LUCAS',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '100',
      lista: 'Voicce Labs',
      linha: '100',
      ramal: '0009',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'MARIA EDUARDA',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '100',
      lista: 'Voicce Labs',
      linha: '100',
      ramal: '0010',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'MARCIO HENRIQUE',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '100',
      lista: 'Voicce Labs',
      linha: '100',
      ramal: '0011',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'BEATRIZ',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '100',
      lista: 'Voicce Labs',
      linha: '100',
      ramal: '0012',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'JOÃO',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '300',
      lista: 'Voicce Labs 3',
      linha: '300',
      ramal: '3000',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'MARIA EDUARDA',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '300',
      lista: 'Voicce Labs 3',
      linha: '300',
      ramal: '3001',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'LUCAS GABRIEL',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '300',
      lista: 'Voicce Labs 3',
      linha: '300',
      ramal: '3002',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'ISABELLA FERNANDA',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '300',
      lista: 'Voicce Labs 3',
      linha: '300',
      ramal: '3003',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'SOPHIA BEATRIZ',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '300',
      lista: 'Voicce Labs 3',
      linha: '300',
      ramal: '3004',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'JOÃO VICTOR',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '300',
      lista: 'Voicce Labs 3',
      linha: '300',
      ramal: '3005',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'LAURA EDUARDA',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '300',
      lista: 'Voicce Labs 3',
      linha: '300',
      ramal: '3006',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'DANIEL FELIPE',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '300',
      lista: 'Voicce Labs 3',
      linha: '300',
      ramal: '3007',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'MANUELA CRISTINA',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '209',
      lista: 'Voicce Labs 2',
      linha: '209',
      ramal: '2000',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'LUCAS MIGUEL',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '209',
      lista: 'Voicce Labs 2',
      linha: '209',
      ramal: '2001',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'LUCAS',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '209',
      lista: 'Voicce Labs 2',
      linha: '209',
      ramal: '2002',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'ISABELLA',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '209',
      lista: 'Voicce Labs 2',
      linha: '209',
      ramal: '2003',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'PEDRO',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '209',
      lista: 'Voicce Labs 2',
      linha: '209',
      ramal: '2003',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'PEDRO',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '901',
      lista: 'Voicce Labs 4',
      linha: '901',
      ramal: '9000',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'LETÍCIA AMANDA',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '901',
      lista: 'Voicce Labs 4',
      linha: '901',
      ramal: '9001',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'GUSTAVO HENRIQUE',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '901',
      lista: 'Voicce Labs 4',
      linha: '901',
      ramal: '9002',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'MANUELA CRISTINA',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '901',
      lista: 'Voicce Labs 4',
      linha: '901',
      ramal: '9003',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'ANDRÉ LUIZ',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '901',
      lista: 'Voicce Labs 4',
      linha: '901',
      ramal: '9004',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'CAMILA RAQUEL',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '901',
      lista: 'Voicce Labs 4',
      linha: '901',
      ramal: '9005',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'ENZO MIGUEL',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '901',
      lista: 'Voicce Labs 4',
      linha: '901',
      ramal: '9006',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'JULIANA ALVES',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '901',
      lista: 'Voicce Labs 4',
      linha: '901',
      ramal: '9007',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'DANIEL FELIPE',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '901',
      lista: 'Voicce Labs 4',
      linha: '901',
      ramal: '9008',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'AMANDA BEATRIZ',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
    {
      id: uuid(),
      fila: '901',
      lista: 'Voicce Labs 4',
      linha: '901',
      ramal: '9009',
      lig_tipo: 'pred',
      estado: getRandomStatus(),
      agente: 'THIAGO ALEXANDRE',
      fone: renderPhone(),
      horaInicial: renderTime(),
    },
  ];

  const { data: dataMonitorLines, isLoading: loadingDataMonitor } = useQuery(
    'dataMonitorLines',
    async () => {
      //   return await getLinesService();
      return data;
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 5,
    }
  );

  return { dataMonitorLines, loadingDataMonitor };
};
