import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Stack } from '@mui/material';
import * as yup from 'yup';
import { FaGear } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { FiTrash2 } from 'react-icons/fi';
import { BiTransferAlt } from 'react-icons/bi';
import { MdLock } from 'react-icons/md';
import { checkDisabled } from '@/components/pages/FlowBuilder/utils/checkDisabledItem';
import useFormControl from '@/hooks/useFormControl';
import { FooterConfig } from '../../styled';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { dataTrunks } from '@/store/FlowBuilder';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import {
  ButtonRemoveTrunk,
  ContentAddTrunk,
  ContentMainTrunks,
  MainTrunksContainer,
} from './styled';

export const AddTrunks = ({ usedTrunks, handleControlStages }) => {
  const [trunksList] = useAtom(dataTrunks);
  const [trunks, setTrunks] = useState(trunksList);

  const { t } = useTranslation();

  const trunksSchema = yup.object().shape({
    nameTrunk: yup
      .string()
      .required(t('flowBuilder.configs.trunks.msg-requiredTrunk'))
      .matches(/^[\w]+$/, t('flowBuilder.configs.trunks.msg-errorCharsTrunk'))
      .test(
        'unique-name',
        t('flowBuilder.configs.trunks.msg-alreadyExists'),
        function (value) {
          const isDuplicate = trunks.some(
            (trunk) => trunk.value.toLowerCase() === value?.toLowerCase()
          );
          return !isDuplicate;
        }
      ),
  });

  const { handleSubmit, control, reset, errors } = useFormControl(trunksSchema);

  const handleAddTrunk = (data) => {
    const dataNewTrunk = {
      id: uuid(),
      name: data.nameTrunk,
      value: data.nameTrunk,
    };
    setTrunks((previewTrunk) => [dataNewTrunk, ...previewTrunk]);
    reset();
  };

  const handleRemoveTrunk = (itemRemove) => {
    const removeTrunk = trunks.filter(
      (trunk) =>
        trunk.name !== itemRemove.name && trunk.value !== itemRemove.value
    );
    setTrunks(removeTrunk);
    return;
  };

  return (
    <ContentMainTrunks initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4>{t('flowBuilder.configs.trunks.title-addTrunks')}</h4>
      </Stack>

      <MainTrunksContainer>
        <ContentAddTrunk>
          <form onSubmit={handleSubmit(handleAddTrunk)}>
            <div className='content-form'>
              <DescriptionInformation
                textDescription={t(
                  'flowBuilder.configs.trunks.description-addTrunks'
                )}
              />
              <ControlInput
                label={t('flowBuilder.configs.trunks.label-nameTrunks')}
                control={control}
                nameControl={'nameTrunk'}
                error={Boolean(errors.nameTrunk)}
                helperText={
                  Boolean(errors.nameTrunk) && errors.nameTrunk.message
                }
              />
              <Stack width={'100%'} alignItems={'flex-end'}>
                <Button typeText type='submit'>
                  {t('flowBuilder.configs.trunks.btn-add')}
                </Button>
              </Stack>
            </div>
          </form>

          <div className='divider'></div>

          <div className='content-list-trunks'>
            <ul>
              {trunks.length > 0 ? (
                trunks.map((trunk, i) => {
                  return (
                    <li key={i}>
                      <Stack direction={'row'} spacing={1}>
                        <BiTransferAlt />
                        <span>{trunk.value}</span>
                      </Stack>
                      <ButtonRemoveTrunk
                        whileTap={{ scale: 0.95 }}
                        onClick={() => handleRemoveTrunk(trunk)}
                        disabled={checkDisabled(usedTrunks, trunk.value)}
                      >
                        {checkDisabled(usedTrunks, trunk.value) ? (
                          <MdLock />
                        ) : (
                          <FiTrash2 />
                        )}
                      </ButtonRemoveTrunk>
                    </li>
                  );
                })
              ) : (
                <li>
                  <Stack direction={'row'} spacing={1}>
                    <BiTransferAlt />
                    <span>
                      {t('flowBuilder.configs.trunks.label-noDataTrunks')}
                    </span>
                  </Stack>
                </li>
              )}
            </ul>
          </div>
        </ContentAddTrunk>
      </MainTrunksContainer>

      <FooterConfig>
        <div className=''></div>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <Button
            typeText
            variant='secondary'
            onClick={handleControlStages.toList}
            type='button'
          >
            {t('flowBuilder.configs.trunks.btn-cancel')}
          </Button>
        </Stack>
      </FooterConfig>
    </ContentMainTrunks>
  );
};
