import { InputComponent } from '@/components/FormComponents/InputComponent';
import { ContainerCustomCapture } from './styled';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const CustomCapture = ({ dataDtmf }) => {
  const { t } = useTranslation();
  const { totalDigits, setTotalDigits, timeOut, setTimeOut, setListDtmf } =
    dataDtmf;

  useEffect(() => {
    return () => {
      setTotalDigits(0);
      setTimeOut(1);
      setListDtmf([]);
    };
  }, []);

  return (
    <ContainerCustomCapture>
      <h4>{t('flowBuilder.flow.nodes.dtmf.menu.title-automatic-capture')}</h4>
      <InputComponent
        label={t('flowBuilder.flow.nodes.dtmf.menu.label-total-digits')}
        type={'number'}
        value={totalDigits}
        onChange={(e) => setTotalDigits(e.target.value)}
        inputProps={{ min: 0, max: 30 }}
      />

      <InputComponent
        label={t('flowBuilder.flow.nodes.dtmf.menu.label-timeout')}
        type={'number'}
        value={timeOut}
        onChange={(e) => setTimeOut(e.target.value)}
        inputProps={{ min: 0, max: 60 }}
      />
    </ContainerCustomCapture>
  );
};
