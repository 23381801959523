import * as yup from 'yup';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { IoSaveSharp } from 'react-icons/io5';
import { useEffect, useState } from 'react';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { ContainerSaveTemplateMailing, FormSaveTemplate } from './styled';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import useFormControl from '@/hooks/useFormControl';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { useSaveTemplateLoadList } from '../../../../hooks/useSaveTemplate';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useLoadList } from '@/hooks/LoadList/useLoadList';
import { successToast } from '@/components/FormComponents/Toast';
import { DialogComponent } from '@/components/DialogComponent';
import {
  hasSavedTemplateMailing,
  isLoadingRenderFlow,
  selectedTemplate,
} from '@/store/LoadList';

export const SaveTemplateMailing = ({ handleCloseSave }) => {
  const { t } = useTranslation();
  const { handleSaveTemplateLoadList } = useSaveTemplateLoadList();
  const { mutateCreateTemplateLoadList, loadingCreateTemplateLoadList } =
    useLoadList(false);
  const [, setHasSavedTemplate] = useAtom(hasSavedTemplateMailing);
  const [, setSelectedLayout] = useAtom(selectedTemplate);

  const controller = new AbortController();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const addTemplateSchema = yup.object().shape({
    templateName: yup
      .string()
      .required()
      .matches(
        /^[a-zA-Z0-9_-]*$/,
        'A descrição só pode conter letras, números, _ e -'
      ),
    templateDescription: yup
      .string()
      .required()
      .matches(
        /^[a-zA-Z0-9_-]*$/,
        'A descrição só pode conter letras, números, _ e -'
      ),
  });

  const { handleSubmit, control, errors, reset } =
    useFormControl(addTemplateSchema);

  const handleSaveTemplate = (data) => {
    const { templateName, templateDescription } = data;
    const dataCreate = handleSaveTemplateLoadList(
      templateName,
      templateDescription
    );
    mutateCreateTemplateLoadList(
      { dataCreate, signal: controller.signal },
      {
        onSuccess: (data) => {
          setSelectedLayout(data.metadata.id);
          reset();
          setHasSavedTemplate(true);
          handleCloseSave();
          successToast(t('listLoader.importMailing.flow.msg-success-save'));
        },
      }
    );
  };

  return (
    <ContainerSaveTemplateMailing>
      {loadingCreateTemplateLoadList && <BackDropCircularLoading />}
      <TitleComponent
        textTitle={t('listLoader.importMailing.flow.title-save-template')}
        iconTitle='config'
      />
      <DescriptionInformation
        textDescription={t(
          'listLoader.importMailing.flow.description-save-template'
        )}
      />
      <FormSaveTemplate onSubmit={handleSubmit(handleSaveTemplate)}>
        <ControlInput
          control={control}
          nameControl={'templateName'}
          label={t('listLoader.importMailing.flow.placeholder-save-template')}
          error={Boolean(errors.templateName)}
        />
        <ControlInput
          control={control}
          nameControl={'templateDescription'}
          label={t(
            'listLoader.importMailing.flow.placeholder-save-description'
          )}
          error={Boolean(errors.templateDescription)}
        />
        <Stack direction={`row`} gap={1} justifyContent={'flex-end'}>
          <Button typeText variant={'secondary'} onClick={handleCloseSave}>
            {t('listLoader.importMailing.flow.btn-cancel')}
          </Button>
          <Button typeText type='submit'>
            {t('listLoader.importMailing.flow.btn-save')}
          </Button>
        </Stack>
      </FormSaveTemplate>
    </ContainerSaveTemplateMailing>
  );
};

export const SaveTemplate = () => {
  const { t } = useTranslation();
  const [openSaveTemplate, setOpenSaveTemplate] = useState(false);
  const [hasSavedTemplate] = useAtom(hasSavedTemplateMailing);
  const [, setIsLoadingChangeHeaders] = useAtom(isLoadingRenderFlow);

  const handleCloseSave = () => {
    setIsLoadingChangeHeaders(false);
    setOpenSaveTemplate(false);
  };

  const handleSaveTemplate = () => {
    setOpenSaveTemplate(true);
  };

  return (
    <>
      {!hasSavedTemplate && (
        <Button
          variant='success'
          className='no-animation'
          onClick={handleSaveTemplate}
          title={t('listLoader.importMailing.flow.title-btn-save')}
        >
          <IoSaveSharp />
        </Button>
      )}

      <DialogComponent
        open={openSaveTemplate}
        onClose={handleCloseSave}
        fullWidth={true}
      >
        <SaveTemplateMailing handleCloseSave={handleCloseSave} />
      </DialogComponent>
    </>
  );
};
