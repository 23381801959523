import styled from 'styled-components';
import { ContainerNodeWrapper, ContentNodeWrapper } from '../styled';

export const NodeFunctionContainer = styled(ContainerNodeWrapper)`
  background: ${(props) => props.theme.colors.containers.colorSecondNode};

  .react-flow__handle {
    &::after {
      background: ${(props) => props.theme.colors.nodesFlow.functionHandler};
    }
  }

  .react-flow__handle-right {
    right: -8px;
  }
`;

export const NodeContent = styled(ContentNodeWrapper)`
  width: 220px;
  .header {
    background: ${(props) => props.theme.colors.nodesFlow.functionHandler};
  }
`;
