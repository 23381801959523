import styled from 'styled-components';

export const ContainerDetailListQueue = styled.div`
  width: 100%;
  height: 100%;
  min-height: 620px;
  padding: 2rem;
  gap: 1rem;
  user-select: none;

  display: grid;
  grid-template-rows: auto auto 1fr auto;

  h2 {
    font-size: 0.95rem;
    font-weight: bold;
    color: ${(props) => props.theme.colors.typography.baseTitle};
  }
`;

export const NavigationDetailQueue = styled.nav`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  .inativeButton {
    background: gray !important;
  }
`;

export const ContainerStateDetailQueue = styled.div`
  width: 100%;
  height: 460px;
  overflow: auto;
`;

export const FooterDetailQueue = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
