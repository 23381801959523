import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { dataListCampaigns } from '@/store/Campaigns';
import { updateQueueCampaign } from '@/services/dialControl/QueueCampaign/updateQueueCampaign';
import { dataQueues, dataServersControl } from '@/store/Queues';
import { getQueuesCampaigns } from '@/services/dialControl/campaigns/getQueuesCampaigns';
import { getCampaignsByClient } from '@/services/dialControl/campaigns/getCampaignsByClient';

export const useQueueCampaign = () => {
  const { t } = useTranslation();
  const [, setDataCampaigns] = useAtom(dataListCampaigns);
  const [, setListQueues] = useAtom(dataQueues);
  const [serversControl] = useAtom(dataServersControl);

  const { isLoading: loadingDataQueues, refetch: refetchQueues } = useQuery(
    'getQueuesCampaigns',
    async () => {
      return await getQueuesCampaigns({
        first_queue: serversControl.first_queue,
        last_queue: serversControl.last_queue,
        isActive: true,
      });
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setListQueues(data),

      onError: () => {
        errorToast(t('dialing.newListConfig.messages.errorRequesStatus'));
      },
    }
  );

  const { isLoading: loadingDataCampaigns } = useQuery(
    'getCampaignsByClient',
    async () => {
      return await getCampaignsByClient();
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setDataCampaigns(data);
      },
    }
  );

  const { mutate: mutateQueueCampaign, isLoading: loadingQueueCampaign } =
    useMutation({
      mutationFn: async (dataUpdateCampaign) => {
        return await updateQueueCampaign(dataUpdateCampaign);
      },
      onSuccess: () => {
        refetchQueues();
      },
      onError: () => errorToast(t('dialControl.queueCampaign.msg-errorUpdate')),
    });

  return {
    loadingDataQueues,
    loadingDataCampaigns,
    mutateQueueCampaign,
    loadingQueueCampaign,
  };
};
