import { Stack } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import lgVoicceBlack from '@/assets/imgs/lg_voiccelabs_black_r.webp';
import lgVoicceWhite from '@/assets/imgs/lg_voiccelabs_white_r.webp';
import {
  ContainerLoading,
  ContainerLoadingDisabledScreen,
  ContainerBackdropLoading,
  ContainerLoadingInWindow,
} from './styled';
import { useTheme } from 'styled-components';
import { ImageComponent } from '../ImageComponent';

export const LoadingComponent = () => {
  const theme = useTheme();
  const { title } = theme;

  const logoSrc = title === 'Light' ? lgVoicceBlack : lgVoicceWhite;
  const altText = 'Logo Voicce Labs';

  return (
    <ContainerLoading>
      <ImageComponent
        src={logoSrc}
        width='300'
        height='74.02'
        alt={altText}
        loading='eager'
      />
    </ContainerLoading>
  );
};

export const LoadingDisableScreen = () => {
  return <ContainerLoadingDisabledScreen />;
};

export const CircularLoading = () => {
  return (
    <Stack
      direction='row'
      alignItems={'center'}
      justifyContent={'center'}
      flex={1}
    >
      <CircularProgress />
    </Stack>
  );
};

export const BackDropCircularLoading = () => {
  return (
    <ContainerBackdropLoading>
      <CircularProgress />
    </ContainerBackdropLoading>
  );
};

export const LoadingComponentInContent = () => {
  const theme = useTheme();
  const { title } = theme;

  const logoSrc = title === 'Light' ? lgVoicceBlack : lgVoicceWhite;
  const altText = 'Logo Voicce Labs';

  return (
    <ContainerLoadingInWindow>
      <motion.img
        src={logoSrc}
        alt={altText}
        animate={{
          scale: [0.95, 1, 0.95],
        }}
        transition={{
          repeat: Infinity,
          ease: 'easeIn',
          duration: 1.2,
        }}
      />
    </ContainerLoadingInWindow>
  );
};

export const CircularAbsoluteLoading = () => {
  return (
    <Stack
      direction='row'
      alignItems={'center'}
      justifyContent={'center'}
      flex={1}
      style={{
        background: '#9090906b',
        opacity: 1,
        position: 'absolute',
        top: -14,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99999,
      }}
    >
      <CircularProgress />
    </Stack>
  );
};
