import { ChartPerHour } from './ChartPerHour';
import { ContainerChartsDash } from './styled';
import { MailingAnalysis } from './MailingAnalysis';
import { FunnelAttendance } from './FunnelAttendance';
import { ChartMap } from './ChartMap';
import { dataDashboardCharts } from '@/store/DashboardPage';
import { useAtom } from 'jotai';

const ChartsDash = ({ selectedQueue }) => {
  const [dashboardDataCharts] = useAtom(dataDashboardCharts);
  const { mailingAnalysis, funnelAttendance, callPerHour } =
    dashboardDataCharts;

  return (
    <ContainerChartsDash initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div className='first_row_item_1'>
        <FunnelAttendance
          selectedQueue={selectedQueue}
          dataStatusQueues={funnelAttendance}
        />
      </div>

      <div className='first_row_item_2'>
        <MailingAnalysis
          selectedQueue={selectedQueue}
          dataChartMailing={mailingAnalysis}
        />
      </div>

      <div className='second_row_item_1'>
        <ChartPerHour
          dataChartPerHour={callPerHour}
          selectedQueue={selectedQueue}
        />
      </div>
      <div className='second_row_item_2'>
        <ChartMap dataChartMap={[]} selectedQueue={selectedQueue} />
      </div>
    </ContainerChartsDash>
  );
};

export { ChartsDash };
