import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerDetails = styled(motion.div)`
  padding: 2rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  div {
    span {
      font-size: 0.78rem;
    }
  }

  .hasErrors {
    color: red !important;
  }
  .mainDetails {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .footerDetails {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
`;

export const MainDetails = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: ${(props) => props.theme.colors.typography.baseText};
`;
