import { apiData } from '../api';

export async function dispositionCall(dataStateCall) {
  const { extension, code } = dataStateCall;
  try {
    const { data } = await apiData.post('/agent/dispositionCall', {
      extension,
      status: code,
    });
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
