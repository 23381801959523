import styled, { css } from 'styled-components';

export const FieldDtmfMessage = styled.div`
  width: 100%;
  position: relative;

  .btn_load_prompt {
    position: absolute;
    right: 6px;
    bottom: -14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;

    svg {
      color: #fff;
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.colors.primary};

      border-radius: 50px;
    }
  }

  .btn_play_prompt {
    position: absolute;
    right: 6px;
    bottom: -14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;

    .item-btn {
      background-color: white;
      border-radius: 50px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        svg {
          font-size: 1.1rem;
        }
      }
    }
  }

  .MuiInputBase-input {
    font-size: 14px;
  }
`;

export const LabelSwitch = styled.span`
  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.typography.baseText};
  padding-top: 3px;
  cursor: pointer;
  user-select: none;
`;
