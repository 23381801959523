import { apiData } from '../api';

export async function getDetailList(dataDetailQueue) {
  try {
    const data = {
      posicao_telefone: [
        {
          posicao_phone: 'Telefone 1',
          quantidade: '20',
          invalidos: '5',
        },
        {
          posicao_phone: 'Telefone 2',
          quantidade: '20',
          invalidos: '5',
        },
        {
          posicao_phone: 'Telefone 3',
          quantidade: '20',
          invalidos: '5',
        },
        {
          posicao_phone: 'Telefone 4',
          quantidade: '20',
          invalidos: '5',
        },
        {
          posicao_phone: 'Telefone 5',
          quantidade: '20',
          invalidos: '5',
        },
        {
          posicao_phone: 'Telefone 6',
          quantidade: '20',
          invalidos: '5',
        },
        {
          posicao_phone: 'Telefone 7',
          quantidade: '20',
          invalidos: '5',
        },
        {
          posicao_phone: 'Telefone 8',
          quantidade: '20',
          invalidos: '5',
        },
        {
          posicao_phone: 'Telefone 9',
          quantidade: '20',
          invalidos: '5',
        },
        {
          posicao_phone: 'Telefone 10',
          quantidade: '20',
          invalidos: '5',
        },
      ],
      qtd_cod_lig: [
        {
          cod_lig: 'DISO',
          quantidade: 1,
        },
        {
          cod_lig: 'GR11',
          quantidade: 1,
        },
        {
          cod_lig: 'GR27',
          quantidade: 1,
        },
        {
          cod_lig: 'HUMA',
          quantidade: 1,
        },
        {
          cod_lig: 'LF07',
          quantidade: 1,
        },
        {
          cod_lig: 'LF09',
          quantidade: 1,
        },
        {
          cod_lig: 'NATN',
          quantidade: 1,
        },
        {
          cod_lig: 'NOVO',
          quantidade: 1,
        },
        {
          cod_lig: 'SECE',
          quantidade: 1,
        },
        {
          cod_lig: 'T001',
          quantidade: 1,
        },
        {
          cod_lig: 'T017',
          quantidade: 1,
        },
        {
          cod_lig: 'T019',
          quantidade: 1,
        },
        {
          cod_lig: 'T021',
          quantidade: 1,
        },
        {
          cod_lig: 'T034',
          quantidade: 1,
        },
        {
          cod_lig: 'TP01',
          quantidade: 1,
        },
        {
          cod_lig: 'TP02',
          quantidade: 1,
        },
        {
          cod_lig: 'TP03',
          quantidade: 1,
        },
        {
          cod_lig: 'TP04',
          quantidade: 1,
        },
        {
          cod_lig: 'TP05',
          quantidade: 1,
        },
        {
          cod_lig: 'TP06',
          quantidade: 1,
        },
        {
          cod_lig: 'TP07',
          quantidade: 1,
        },
        {
          cod_lig: 'TP08',
          quantidade: 1,
        },
        {
          cod_lig: 'TP09',
          quantidade: 1,
        },
        {
          cod_lig: 'TP10',
          quantidade: 1,
        },
        {
          cod_lig: 'TP11',
          quantidade: 1,
        },
        {
          cod_lig: 'TP12',
          quantidade: 1,
        },
        {
          cod_lig: 'TP13',
          quantidade: 1,
        },
        {
          cod_lig: 'TP14',
          quantidade: 1,
        },
      ],
    };

    return new Promise((resolve) => {
      setTimeout(() => resolve(data), 1000);
    });
  } catch (err) {
    throw new Error(err);
  }
}
