import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const TableContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  max-height: calc(60vh - 40px);
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  position: relative;
  th,
  td {
    padding: 8px;
  }
  thead {
    tr {
      height: 34px !important;
    }
    th {
      background-color: ${(props) => props.theme.colors.tables.tableHead};
      color: #fff;
      font-size: 0.82rem;
      text-align: start;

      &:first-child {
        width: 100px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &:last-child {
        width: 70px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  tr {
    position: relative;
    height: 50px;
    &:hover {
      background-color: ${(props) => props.theme.colors.tables.tableRow}AA;
    }

    /* span {
      padding-left: 4px;
      font-size: 0.84rem;
      color: ${(props) => props.theme.colors.typography.baseText};
    } */

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 96%;
      background-color: ${(props) => props.theme.colors.tables.tableRow};
      border-radius: 3px;
      z-index: -1;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.09);
    }
  }

  .actionTable {
    display: flex;
    justify-content: space-around;
    height: 100%;
    gap: 2px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      border: none;
      border-radius: 4px;

      &:first-child {
        background-color: ${(props) => props.theme.colors.primary};
      }

      &:last-child {
        background-color: ${(props) => props.theme.colors.secondary};
      }

      svg {
        color: #fff;
      }
    }
  }

  @media (max-width: 600px) {
    display: block;
    width: auto;
  }
`;

export const NoDataTable = styled(motion.div)`
  width: 100%;
  margin-top: -10px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.tables.tableRow};
  border-radius: 4px;

  button {
    all: unset;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.65rem;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.09);

    span {
      color: ${(props) => props.theme.colors.typography.baseText};
      font-size: 0.85rem;
      padding-top: 4px;
    }

    svg {
      color: ${(props) => props.theme.colors.success};
      font-size: 1rem;
    }
  }
`;

const ButtonTable = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border: none;
  border-radius: 4px;

  &:disabled {
    background-color: #c7c7c7 !important;
  }

  svg {
    color: #fff;
  }
`;

export const TextTable = styled.span`
  padding-left: 4px;
  font-size: 0.84rem;
  color: ${(props) => props.theme.colors.typography.baseText};

  ${(props) =>
    props.selected &&
    css`
      color: #c7c7c7;
    `}
`;

export const BtnEdit = styled(ButtonTable)`
  background-color: ${(props) => props.theme.colors.primary};
`;
export const BtnCancel = styled(ButtonTable)`
  background-color: ${(props) => props.theme.colors.secondary};
`;
export const BtnConfirm = styled(ButtonTable)`
  background-color: ${(props) => props.theme.colors.success} !important;
`;
