import { useAtom } from 'jotai';
import { ThemeProvider } from 'styled-components';
import { currentThemeAtom } from '@store/ThemeStore/theme';
import { dark } from '../../styles/themes/dark';
import { light } from '../../styles/themes/light';

export function ThemeStyled({ children }) {
  const [currentTheme] = useAtom(currentThemeAtom);

  return (
    <ThemeProvider theme={currentTheme === 'light' ? light : dark}>
      {children}
    </ThemeProvider>
  );
}
