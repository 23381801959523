import { v4 as uuid } from 'uuid';

export async function getSummaryMonitor() {
  try {
    const data = [
      {
        id: uuid(),
        queue: '100',
        speaking_ura: 10,
        dialing: 10,
        campaignDescription: 'Campaign 100-test',
        list: '100-test',
        operator: 'Joe Doe',
      },
      {
        id: uuid(),
        queue: '100',
        speaking_ura: 5,
        dialing: 15,
        campaignDescription: 'Campaign 101-test',
        list: '101-test',
        operator: 'James',
      },
      {
        id: uuid(),
        queue: '100',
        speaking_ura: 12,
        dialing: 8,
        campaignDescription: 'Campaign 102-test',
        list: '102-test',
        operator: 'Robert',
      },
      {
        id: uuid(),
        queue: '101',
        speaking_ura: 6,
        dialing: 14,
        campaignDescription: 'Campaign 103-test',
        list: '103-test',
        operator: 'John',
      },
      {
        id: uuid(),
        queue: '101',
        speaking_ura: 10,
        dialing: 10,
        campaignDescription: 'Campaign 104-test',
        list: '104-test',
        operator: 'Michael',
      },
      {
        id: uuid(),
        queue: '101',
        speaking_ura: 0,
        dialing: 20,
        campaignDescription: 'Campaign 105-test',
        list: '105-test',
        operator: 'David',
      },
      {
        id: uuid(),
        queue: '102',
        speaking_ura: 10,
        dialing: 10,
        campaignDescription: 'Campaign 106-test',
        list: '100-test',
        operator: 'Joe Doe',
      },
      {
        id: uuid(),
        queue: '102',
        speaking_ura: 5,
        dialing: 15,
        campaignDescription: 'Campaign 101-test',
        list: '101-test',
        operator: 'James',
      },
      {
        id: uuid(),
        queue: '102',
        speaking_ura: 12,
        dialing: 8,
        campaignDescription: 'Campaign 102-test',
        list: '102-test',
        operator: 'Robert',
      },
      {
        id: uuid(),
        queue: '102',
        speaking_ura: 6,
        dialing: 14,
        campaignDescription: 'Campaign 103-test',
        list: '103-test',
        operator: 'John',
      },
      {
        id: uuid(),
        queue: '110',
        speaking_ura: 10,
        dialing: 10,
        campaignDescription: 'Campaign 104-test',
        list: '104-test',
        operator: 'Michael',
      },
      {
        id: uuid(),
        queue: '111',
        speaking_ura: 0,
        dialing: 20,
        campaignDescription: 'Campaign 105-test',
        list: '105-test',
        operator: 'David',
      },
      {
        id: uuid(),
        queue: '112',
        speaking_ura: 10,
        dialing: 10,
        campaignDescription: 'Campaign 106-test',
        list: '100-test',
        operator: 'Joe Doe',
      },
      {
        id: uuid(),
        queue: '113',
        speaking_ura: 5,
        dialing: 15,
        campaignDescription: 'Campaign 107-test',
        list: '101-test',
        operator: 'James',
      },
      {
        id: uuid(),
        queue: '114',
        speaking_ura: 12,
        dialing: 8,
        campaignDescription: 'Campaign 108-test',
        list: '102-test',
        operator: 'Robert',
      },
      {
        id: uuid(),
        queue: '115',
        speaking_ura: 6,
        dialing: 14,
        campaignDescription: 'Campaign 103-test',
        list: '103-test',
        operator: 'John',
      },
    ];
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
