import { FormControlLabel, Switch } from '@mui/material';
import styled, { css } from 'styled-components';

export const StyledFormControl = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    color: ${(props) => props.theme.colors.typography.baseText};
    font-size: 0.8rem;
    text-transform: uppercase;
    padding-left: 6px;

    ${(props) =>
      props.ischecked
        ? css`
            opacity: 1;
            font-weight: 600;
          `
        : css`
            opacity: 0.8;
            font-weight: 400;
          `}
  }
`;
export const StyledSwitch = styled(Switch)`
  input {
  }
`;

export const CustomSwitch = styled(Switch)`
  & .MuiSwitch-switchBase.Mui-checked {
    color: ${(props) => props.theme.colors.typography.baseText};
    &:hover {
      background-color: rgba(255, 87, 34, 0.08);
    }
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #bdbdbd;
  }
  & .MuiSwitch-switchBase {
    color: #707070;
    &:hover {
      background-color: rgba(33, 150, 243, 0.08);
    }
  }
  & .MuiSwitch-track {
    background-color: #bdbdbd;
  }
`;
