import { useId, useState } from 'react';
import { CreateCampaign } from './stages/CreateCampaign';
import { ListCampaigns } from './stages/ListCampaign';
import { ContainerCampaigns } from './styled';
import { EditCampaign } from './stages/EditCampaign';

export const Campaigns = () => {
  const [currentStageCampaign, setCurrentStageCampaign] = useState(0);

  const controlStage = {
    previousStage: () =>
      setCurrentStageCampaign(
        (currentStageCampaign) => currentStageCampaign - 1
      ),
    nextStage: () =>
      setCurrentStageCampaign(
        (currentStageCampaign) => currentStageCampaign + 1
      ),
    toStage: (stage) => setCurrentStageCampaign(stage),
    currentStage: currentStageCampaign,
  };

  const flowCampaigns = [
    <ListCampaigns key={useId()} controlStage={controlStage} />,
    <CreateCampaign key={useId()} controlStage={controlStage} />,
    <EditCampaign key={useId()} controlStage={controlStage} />,
  ];

  return (
    <ContainerCampaigns>
      {flowCampaigns[currentStageCampaign]}
    </ContainerCampaigns>
  );
};
