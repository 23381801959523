import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContentQueueCampaign = styled(motion.div)`
  width: 100%;
  height: 100%;
  min-height: 200px;
  user-select: none;
  padding-top: 20px;

  .item-status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    svg {
      font-size: 0.9rem;
      color: ${(props) => props.theme.colors.primary};
    }
  }

  .unselected-row {
    span {
      color: ${(props) => props.theme.colors.typography.baseText}AA;
      opacity: 0.8;
    }
  }

  .selected-row {
    height: 64px;
    background-color: ${(props) =>
      props.theme.colors.containers.baseContainer}AA;
    span {
      color: ${(props) => props.theme.colors.typography.baseText};
      font-weight: 600 !important;
    }
  }

  .disabled-item {
    svg {
      font-size: 0.95rem;
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  .itemTable {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;

    span {
      padding-top: 2px;
      font-size: 12px;
    }
  }

  table {
    position: relative;
    tbody {
      tr {
        height: 42px;
        transition: all 0.1s ease;

        .colorDiscing {
          color: ${(props) => props.theme.colors.defaultColors.green};
        }
        .colorEmpty {
          color: ${(props) => props.theme.colors.defaultColors.blue};
        }
        .colorClosed {
          color: ${(props) => props.theme.colors.defaultColors.orange};
        }

        td {
          z-index: 1;
          padding: 0 0.8rem;

          &:nth-child(1) {
            width: 10%;
          }

          &:nth-child(2) {
            width: 20%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:nth-child(4) {
            width: 53%;
          }

          &:last-child {
            width: 7%;
            button {
              border-radius: 3px;
              padding: 0;
              height: 20px;
              width: 20px;
              min-width: 20px !important;
            }
          }
        }
      }
    }
  }
`;
