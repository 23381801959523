import styled from 'styled-components';

export const ContainerSaveTemplateMailing = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
`;

export const FormSaveTemplate = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  p {
    font-size: 0.74rm;
    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;
