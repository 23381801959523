import { useEffect, useId, useState } from 'react';
import { useAtom } from 'jotai';
import { useReactFlow } from 'reactflow';
import { ListGlobalVariables } from './ListGlobalVariables';
import { AddGlobalVariables } from './AddGlobalVariables';
import { variablesGlobal } from '@/store/FlowBuilder';

export const ConfigGlobalVars = ({ dataGlobals, handleClose }) => {
  const { getNodes } = useReactFlow();
  const [stageGlobalVariablesFLow, setStageGlobalVariablesFlow] = useState(0);
  const [globalVars] = useAtom(variablesGlobal);
  const [usedGlobalVars, setUsedGlobalVars] = useState([]);

  const handleControlGlobalVars = {
    toAddVar: () => setStageGlobalVariablesFlow(1),
    toList: () => setStageGlobalVariablesFlow(0),
  };

  const hasUsedVars = (searchStrings, listCurrentNodes) => {
    const listNodes = listCurrentNodes ? listCurrentNodes : [];
    const results = new Set();

    function searchRecursive(obj) {
      if (typeof obj === 'string') {
        searchStrings.forEach((str) => {
          if (obj.includes(str)) {
            results.add(str);
          }
        });
      } else if (Array.isArray(obj)) {
        obj.forEach((item) => searchRecursive(item));
      } else if (obj && typeof obj === 'object') {
        Object.entries(obj).forEach(([key, value]) => {
          if (key === 'label' && obj.data) return;
          searchRecursive(value);
        });
      }
    }
    searchRecursive(listNodes);
    return Array.from(results);
  };

  useEffect(() => {
    setUsedGlobalVars(
      hasUsedVars(
        globalVars.map((globalVar) => globalVar.value),
        getNodes()
      )
    );
  }, [globalVars, getNodes]);

  const flowGlobalVariables = [
    <ListGlobalVariables
      key={useId()}
      dataGlobals={dataGlobals}
      handleClose={handleClose}
      changeFlowConfigState={handleControlGlobalVars}
      usedGlobalVars={usedGlobalVars}
    />,
    <AddGlobalVariables
      key={useId()}
      changeFlowConfigState={handleControlGlobalVars}
      usedGlobalVars={usedGlobalVars}
    />,
  ];

  return flowGlobalVariables[stageGlobalVariablesFLow];
};
