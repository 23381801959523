export async function getAdvancedSearch() {
  try {
    return new Promise((resolve) => {
      const dataLogs = [
        {
          user: 'João Pedro Almeida',
          client: 'Agivu',
          address: '84.68.138.240',
          httpCode: 'POST',
          route: '/dialer/listQueuesCampaign',
          date: '2024-10-16T18:46:16.016Z',
          browser:
            '"Google Chrome";v="129", "Not=A?Brand";v="8", "Chromium";v="129"',
          platform: 'macOS',
          body: { first_queue: 100, last_queue: 900 },
        },
        {
          user: 'Maria Clara Souza',
          client: 'Zoonder',
          address: '75.19.75.248',
          httpCode: 'GET',
          route: '/dialer/listQueuesCampaign',
          date: '2024-10-16T18:46:16.016Z',
          browser:
            '"Google Chrome";v="129", "Not=A?Brand";v="8", "Chromium";v="129"',
          platform: 'macOS',
          body: { first_queue: 100, last_queue: 900 },
        },
        {
          user: 'Ana Júlia Fernandes',
          client: 'Digitube',
          address: '96.101.6.94',
          httpCode: 'PUT',
          route: '/dialer/listQueuesCampaign',
          date: '2024-10-16T18:46:16.016Z',
          browser:
            '"Google Chrome";v="129", "Not=A?Brand";v="8", "Chromium";v="129"',
          platform: 'macOS',
          body: { first_queue: 100, last_queue: 900 },
        },
        {
          user: 'Miguel Ângelo Ribeiro',
          client: 'Quatz',
          address: '236.208.68.244',
          httpCode: 'DELETE',
          route: '/dialer/listQueuesCampaign',
          date: '2024-10-16T18:46:16.016Z',
          browser:
            '"Google Chrome";v="129", "Not=A?Brand";v="8", "Chromium";v="129"',
          platform: 'macOS',
          body: { first_queue: 100, last_queue: 900 },
        },
        {
          user: 'Laura Beatriz Pereira',
          client: 'Topicshots',
          address: '6.69.23.161',
          httpCode: 'POST',
          route: '/dialer/listQueuesCampaign',
          date: '2024-10-16T18:46:16.016Z',
          browser:
            '"Google Chrome";v="129", "Not=A?Brand";v="8", "Chromium";v="129"',
          platform: 'macOS',
          body: { first_queue: 100, last_queue: 900 },
        },
        {
          user: 'Carlos Eduardo Silva',
          client: 'Edgepulse',
          address: '170.159.159.79',
          httpCode: 'POST',
          route: '/dialer/listQueuesCampaign',
          date: '2024-10-16T18:46:16.016Z',
          browser:
            '"Google Chrome";v="129", "Not=A?Brand";v="8", "Chromium";v="129"',
          platform: 'macOS',
          body: { first_queue: 100, last_queue: 900 },
        },
      ];

      setTimeout(() => resolve(dataLogs), 600);
    });
  } catch (error) {
    throw new Error(error);
  }
}
