import { Handle, Position } from 'reactflow';
import { useAtom } from 'jotai';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BiTransferAlt } from 'react-icons/bi';
import { NodeContent, NodeTransferContainer } from './styled';
import { shortNameRender } from '../../../../utils/shortNameRender';
import { FlexBoxRow } from '../styled';
import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { PanelNode } from '@/components/pages/FlowBuilder/components/PanelNode';

const TransferNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();

  const [, setShowMenu] = useAtom(showMenuNodes);
  const [, setSelectedNode] = useAtom(selectedNode);

  const handleNode = useCallback(() => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  }, [id, data, setSelectedNode, setShowMenu]);

  return (
    <NodeTransferContainer
      selected={selected}
      errorConnect={data.errorConnect ? 1 : 0}
      onDoubleClick={() => handleNode()}
      handler={data.handler}
    >
      <PanelNode data={data} id={id} />

      <Handle type='target' position={Position.Left} />
      <NodeContent typetransfer={data.typeTransfer === 'trunk' ? 1 : 0}>
        <div className='header'>
          <BiTransferAlt size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
            <span>{t('flowBuilder.flow.nodes.transferQueue.label')}</span>
          </div>
        </div>
        <div className='main'>
          <FlexBoxRow>
            <span>
              {t(
                'flowBuilder.flow.nodes.transferQueue.menu.label-typeTransfer'
              )}
              :
            </span>
            <span>
              <strong>
                {data.typeTransfer === ''
                  ? '-'
                  : data.typeTransfer === 'trunk'
                  ? t('flowBuilder.flow.nodes.transferQueue.menu.label-trunk')
                  : t('flowBuilder.flow.nodes.transferQueue.menu.label-queue')}
              </strong>
            </span>
          </FlexBoxRow>
          <FlexBoxRow>
            <span>
              {t(
                'flowBuilder.flow.nodes.transferQueue.menu.label-typeTransferTo'
              )}
              :
            </span>
            <span>
              <strong>{data.transferTo === '' ? '-' : data.transferTo}</strong>
            </span>
          </FlexBoxRow>
          {data.typeTransfer === 'trunk' && (
            <FlexBoxRow>
              <span>{t('Mcdu')}:</span>
              <span>
                <strong>
                  {data.mcdu === ' ' || !data.mcdu ? '-' : data.mcdu}
                </strong>
              </span>
            </FlexBoxRow>
          )}
        </div>
      </NodeContent>
    </NodeTransferContainer>
  );
};

export const TransferNode = memo(TransferNodeComponent);
