import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '@/hooks/useWidth';
import { TablePagination } from '@/components/Tables/TablePagination';
import { ContainerTableGroups } from './styled';
import { motion } from 'framer-motion';
import { IoCloseOutline } from 'react-icons/io5';
import { MdEdit } from 'react-icons/md';
import { ConfirmationComponent } from '@/components/layout/ConfirmationComponent';
import { useAtom } from 'jotai';
import { dataSelectedRenitency } from '@/store/Renitency';
import { successToast } from '@/components/FormComponents/Toast';
import { dataListClients } from '@/store/Clients';
import { StatusDataRow } from '@/components/Tables/StatusDataRow';
import { usePermissionsRenitency } from '../../../hooks/usePermissionsRenitency';

export const TableGroupsRenitency = ({
  dataTable,
  controlStage,
  mutateDeleteRenitency,
}) => {
  const { permissionsEdit, permissionsDelete } = usePermissionsRenitency();
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const [listClients] = useAtom(dataListClients);
  const [selectedRenitency, setSelectedRenitency] = useAtom(
    dataSelectedRenitency
  );

  const renderTextList = (spell) => {
    return t(`dialControl.renitency.list.${spell}`);
  };

  const handleEditGroup = (rowCampaign) => {
    setSelectedRenitency(rowCampaign);
    controlStage.toStage(1);
  };

  const [openConfirmationBox, setOpemConfirmationBox] = useState(false);

  const handleOpemConfirmationRemove = (rowCampaign) => {
    setSelectedRenitency(rowCampaign);
    setOpemConfirmationBox(true);
  };

  const handleDeleteRule = () => {
    mutateDeleteRenitency(selectedRenitency.id_regra_renitencia, {
      onSuccess: () => {
        successToast(renderTextList('messages.success-remove'));
        setSelectedRenitency(null);
      },
    });
  };

  const searchClientGroup = (idCLient) => {
    if (idCLient) {
      const filterClient = listClients.filter((item) => item.id === idCLient);
      if (filterClient.length > 0) {
        return filterClient[0].commercial_name;
      }
      return idCLient;
    }
    return '-';
  };

  const tableGroups = useRef(null);

  const ColumnsGroups = [
    {
      Header: 'status',
      accessor: 'ativo',
      Cell: ({ row }) => <StatusDataRow status={row.original.ativo} />,
    },

    {
      Header: renderTextList('table.th-group'),
      accessor: 'id_regra_renitencia',
    },

    {
      Header: renderTextList('table.th-description'),
      accessor: 'descricao',
    },

    {
      Header: renderTextList('table.th-client'),
      accessor: 'id_cliente',
      Cell: ({ row }) => searchClientGroup(row.original.id_cliente),
    },
    {
      Header: renderTextList('table.th-action'),
      Cell: ({ row }) => (
        <div className='editRow'>
          <motion.button
            title={renderTextList('table.btn-remove-renitency')}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleOpemConfirmationRemove(row.original)}
            disabled={!permissionsDelete}
          >
            <IoCloseOutline />
          </motion.button>
          <motion.button
            title={renderTextList('table.btn-edit-renitency')}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleEditGroup(row.original)}
            disabled={!permissionsEdit}
          >
            <MdEdit />
          </motion.button>
        </div>
      ),
    },
  ];

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableGroups) {
      const tableMaxWidth = tableGroups.current.offsetHeight;
      const cellMinWidth = 55;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, tableGroups]);

  return (
    <ContainerTableGroups ref={tableGroups}>
      <TablePagination
        dataColumns={ColumnsGroups}
        dataTable={dataTable}
        textNoData={renderTextList('table.no-data')}
        rowsPerPageDisplay={heightTable}
      />

      <ConfirmationComponent
        open={openConfirmationBox}
        close={setOpemConfirmationBox}
        messageConfirm={renderTextList('table.msg-remove')}
        actionConfirm={handleDeleteRule}
        isLoading={false}
      />
    </ContainerTableGroups>
  );
};
