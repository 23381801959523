import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerCampaign = styled(motion.div)`
  width: 100%;
  height: auto;

  padding-bottom: 1rem;

  display: grid;
  grid-template-rows: 100px 1fr;
  justify-items: flex-start;
  gap: 1rem;

  @media (max-width: 500px) {
    grid-template-rows: 80px 1fr;
  }
`;

export const ContentPanelCampaigns = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
`;

export const ContentStatusCampaigns = styled(motion.div)`
  width: 100%;
  /* overflow: auto; */
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: space-evenly;
  align-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
`;
