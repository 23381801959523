import { useLayoutEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { motion } from 'framer-motion';
import { ContainerTable } from './styled';
import useWindowDimensions from '@/hooks/useWidth';
import { TablePagination } from '@/components/Tables/TablePagination';
import { Box } from '@mui/material';
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';
import { MdEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { currentFlowClients, selectedClient } from '@/store/Clients';

export const TableClients = ({ dataTable }) => {
  const { t } = useTranslation();
  const { height, width } = useWindowDimensions();
  const tableUsersRef = useRef(null);

  const [, setStateRenderClients] = useAtom(currentFlowClients);
  const [, setSelectedClient] = useAtom(selectedClient);

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableUsersRef) {
      const tableMaxWidth = tableUsersRef.current.offsetHeight;
      const cellMinWidth = width < 500 ? 200 : 54;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, width]);

  const handleEditClient = (dataEdit) => {
    const separateAddress = dataEdit?.address?.split('-');
    const numberAddress = separateAddress[0]?.match(/\d+/g);
    const street = separateAddress[0]?.replace(numberAddress, '') ?? '-';

    const dataSelected = {
      ...dataEdit,
      numberAddress: numberAddress[0] ? numberAddress[0].trim() : '-',
      street: street ? street.trim() : '-',
      city: separateAddress[1]?.trim() ?? '-',
      state: separateAddress[2]?.trim() ?? '-',
    };

    setSelectedClient(dataSelected);
    setStateRenderClients(2);
  };

  const columnsUserAgents = [
    {
      Header: t('clients.homeClients.table.th-first'),
      accessor: 'isActive',
      Cell: ({ row }) =>
        row.original.isActive ? (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <IoCheckmarkCircleOutline size={20} color='green' />
          </Box>
        ) : (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <IoCloseCircleOutline size={20} color='red' />
          </Box>
        ),
    },
    {
      Header: t('clients.homeClients.table.th-second'),
      accessor: 'company_name',
    },
    {
      Header: t('clients.homeClients.table.th-third'),
      accessor: 'tax_number',
    },
    {
      Header: t('clients.homeClients.table.th-tenth'),
      accessor: 'commercial_name',
    },
    {
      Header: t('clients.homeClients.table.th-fourth'),
      accessor: 'contact_email',
    },
    {
      Header: t('clients.homeClients.table.th-fifth'),
      accessor: 'address',
    },
    {
      Header: t('clients.homeClients.table.th-sixth'),
      accessor: 'zipcode',
    },
    // {
    //   Header: t('clients.homeClients.table.th-seventh'),
    //   accessor: 'created_at',
    //   Cell: ({ row }) =>
    //     row.original.created_at ? renderDate(row.original.created_at) : '-',
    // },
    {
      Header: t('clients.homeClients.table.th-ninth'),
      Cell: ({ row }) => (
        <div className='editRow'>
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => handleEditClient(row.original)}
          >
            <MdEdit />
          </motion.button>
        </div>
      ),
    },
  ];

  return (
    <ContainerTable ref={tableUsersRef}>
      <TablePagination
        dataColumns={columnsUserAgents}
        dataTable={dataTable}
        textNoData={t('clients.homeClients.table.no-data')}
        rowsPerPageDisplay={heightTable}
      />
    </ContainerTable>
  );
};
