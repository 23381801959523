import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const TableStyled = styled.table`
  width: 100%;

  @media (max-width: 799px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  thead {
    tr {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        ${(props) =>
          props.errortable
            ? `background-color: ${props.theme.colors.error};`
            : `background-color: ${props.theme.colors.tables.tableHead};`}
      }

      th {
        &:nth-child(1) {
          display: flex;
          justify-content: flex-start;
        }

        div {
          position: relative;
          z-index: 2;
          min-width: 60px;
          text-align: center;
          padding: 10px;
          font-size: 0.875rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors.typography.textHeaderTable};

          @media (max-width: 1300px) {
            font-size: 0.75rem;
            padding: 15px 5px;
          }

          @media (max-width: 799px) {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
`;

export const ContentTr = styled.tr`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${(props) =>
      props.disabledRow
        ? css`
            background-color: ${(props) =>
              props.theme.colors.containers.colorCardSecondary}AA;
            backdrop-filter: blur(10px);
          `
        : css`
            background-color: ${(props) => props.theme.colors.tables.tableRow};
          `}
    border-radius: 5px;
    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.15);
  }

  &:hover:after {
    background-color: ${(props) =>
      props.theme.colors.containers.colorCardSecondary}AA;
  }
`;

export const ContainerTd = styled.td`
  position: relative;
  z-index: 1;
  padding: 4px;
  text-align: center;

  &:nth-child(1) {
    text-align: left;
    padding-left: 10px;
    width: 200px;
  }

  span {
    font-size: 0.875rem;
    font-weight: normal;
    word-wrap: normal;
    color: ${(props) => props.theme.colors.typography.baseText};
    @media (max-width: 799px) {
      font-size: 0.7rem;
    }
  }
`;

export const NoDataTableRow = styled(motion.tr)`
  width: 100%;
  height: 80px;
  position: relative;

  .contentNoData {
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: ${(props) => props.theme.colors.containers.colorCard};
    border-radius: 4px;

    span {
      font-size: 1rem;
      font-weight: normal;
      word-wrap: normal;
      color: ${(props) => props.theme.colors.typography.baseText};
    }

    svg {
      color: ${(props) => props.theme.colors.secondary};
      font-size: 1.2rem;
    }
  }
`;
