import { styled as styledMui } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { MdArrowForwardIos } from 'react-icons/md';

export const Accordion = styledMui((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: 'transparent',
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styledMui((props) => (
  <MuiAccordionSummary
    expandIcon={<MdArrowForwardIos sx={{ fontSize: '0.7rem' }} />}
    {...props}
  />
))(() => ({
  flexDirection: 'row',
  padding: '0px 0px 0px 0px',
  minHeight: '20px',

  '& .MuiTypography-root': {
    color: '#000',
    fontSize: '0.8rem',
    width: '100%',
    textAlign: 'start',
    paddingRight: '8px',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

export const AccordionDetails = styledMui(MuiAccordionDetails)(() => ({
  padding: '10px 0',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

export const CountIntentsSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.tables.tableHead}AA;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 300px;
  font-size: 0.65rem;
  padding-top: 1px;
`;

export const ButtonControlIntent = styled(motion.button)`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.9;
  }
`;
