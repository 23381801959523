import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';

import {
  admin,
  permissions,
  schemaScreens,
  sysAdmin,
  token,
  triggerReloadApp,
  user,
} from '@/store/AuthenticatorStore';

import {
  currentPageId,
  dataInicialIdComponent,
  dataListScreens,
} from '@/store/NavigationDashboard';

import { apiData } from '@/services/api';
import useAuth from '../Authenticator/useAuth';

import { useControlTimeAgent } from '../Agents/useControlTimeAgent';

import { useLocalStorage } from '../useLocalStorage';
import { getItensProfileUser } from '@/services/navigation/getItensProfileUser';

export const useNavigationAccess = () => {
  const { logOut } = useAuth();
  const { removeLocalStatus } = useControlTimeAgent();
  const { getLocalStorage } = useLocalStorage();

  const [reloadApp] = useAtom(triggerReloadApp);

  const [localScreens] = useAtom(dataListScreens);
  const [, setCurrentUser] = useAtom(user);
  const [, setSysAdmin] = useAtom(sysAdmin);
  const [, setIsAdmin] = useAtom(admin);
  const [, setCurrentToken] = useAtom(token);
  const [, defineCurrentId] = useAtom(currentPageId);
  const [, setInicialIdComponent] = useAtom(dataInicialIdComponent);
  const [, setSchemaScreens] = useAtom(schemaScreens);
  const [, setPermissions] = useAtom(permissions);

  const renderComponentScreen = (idComponent) => {
    if (localScreens) {
      const filterComponent = localScreens
        .filter((item) => item.id === idComponent)
        .map((item) => item.component);

      if (filterComponent.length > 0) {
        return filterComponent[0];
      }
      return null;
    }
    return null;
  };

  const renderComponentDefaultSequence = (idComponent) => {
    if (localScreens) {
      const filterComponent = localScreens
        .filter((item) => item.id === idComponent)
        .map((item) => item.sequence);

      if (filterComponent.length > 0 && filterComponent[0]) {
        return filterComponent[0];
      }
      return 10;
    }
    return 0;
  };

  const renderNameComponentScreen = (idComponent) => {
    if (localScreens) {
      const filterComponent = localScreens
        .filter((item) => item.id === idComponent)
        .map((item) => item.name);

      if (filterComponent.length > 0) {
        return filterComponent[0];
      }
      return null;
    }
    return null;
  };

  const renderLinkComponentScreen = (idComponent) => {
    if (localScreens) {
      const filterComponent = localScreens
        .filter((item) => item.id === idComponent)
        .map((item) => item.link);

      return filterComponent.length > 0 ? filterComponent[0] : false;
    }
    return false;
  };

  const renderPermissionsUser = (originalData, cleanList) => {
    const renderPermissionsMap = cleanList.map((item) => {
      const { id, name, subItem, permissions } = item;
      return {
        id,
        name,
        subItem: subItem ? subItem : false,
        permissions,
      };
    });

    const dataPermissions = {
      id: originalData.id ?? null,
      name: originalData.name ?? null,
      permissions: renderPermissionsMap,
    };
    return setPermissions(dataPermissions);
  };

  const renderListItemsPermission = (screens) => {
    if (screens && screens.length > 0) {
      const filterInicialPage = screens.filter(
        (item) => item.area === 'Products'
      );

      const filterDisplayTrue = filterInicialPage.filter((item) => {
        const permissionDisplay = item.permissions.find(
          (permission) => permission.name === 'display'
        );
        return permissionDisplay && permissionDisplay.value === true;
      });

      const orderFirstItemDisplay = filterDisplayTrue
        .sort((a, b) => {
          if (a.sequence === 0 && b.sequence !== 0) {
            return -1;
          } else if (a.sequence !== 0 && b.sequence === 0) {
            return 1;
          } else {
            return a.sequence - b.sequence;
          }
        })
        .map((item) => item.id)[0];

      defineCurrentId(orderFirstItemDisplay);
      setInicialIdComponent(orderFirstItemDisplay);

      const newList = screens.map((item) => {
        if (item.subItem.length === 0) {
          const itemMenu = {
            id: item.id,
            name: item.name,
            href: item.href,
            alias: renderNameComponentScreen(item.href),
            icon: item.icon,
            isOpenMenu: false,
            link: renderLinkComponentScreen(item.href),
            component: renderComponentScreen(item.href),
            area: item.area,
            sequence: item.sequence,
            slug: item.slug,
            hasExternalUrl: item.hasExternalUrl,
            permissions: item.permissions,
            subItem: item.subItem,
          };
          return itemMenu;
        }

        const mappingSubItems = item.subItem.map((item) => {
          let newData = {
            id: item.id,
            name: item.name,
            href: item.href,
            isOpenMenu: false,
            link: renderLinkComponentScreen(item.href),
            alias: renderNameComponentScreen(item.href),
            component: renderComponentScreen(item.href),
            hasExternalUrl: item.hasExternalUrl,
            permissions: item.permissions,
            defaultSequence: renderComponentDefaultSequence(item.href),
          };
          return newData;
        });

        const itemMenu = {
          id: item.id,
          name: item.name,
          href: item.href,
          alias: renderNameComponentScreen(item.href),
          icon: item.icon,
          isOpenMenu: false,
          link: renderLinkComponentScreen(item.href),
          component: renderComponentScreen(item.href),
          area: item.area,
          sequence: item.sequence,
          slug: item.slug,
          hasExternalUrl: item.hasExternalUrl,
          permissions: item.permissions,
          subItem: mappingSubItems,
        };
        return itemMenu;
      });

      renderPermissionsUser(newList, screens);

      return newList;
    }
  };

  const { mutate: mutateGetProfileUser, isLoading: loadingGetProfileUser } =
    useMutation({
      mutationFn: () => {
        return getItensProfileUser();
      },
      onSuccess: (data) => {
        setSchemaScreens(renderListItemsPermission(data));
      },
      onError: () => logOut(),
    });

  // Populate user and token
  useEffect(() => {
    removeLocalStatus();
    const userLocal = getLocalStorage('@s');

    if (userLocal) {
      userLocal.user = {
        ...userLocal.user,
        extension: import.meta.env.VITE_EXTENSION,
        queue: import.meta.env.VITE_QUEUE,
      };

      setCurrentUser(userLocal.user);
      setCurrentToken(userLocal.token);
      setSysAdmin(userLocal.user.userRole.isSysAdmin);
      setIsAdmin(userLocal.user.userRole.isAdmin);

      apiData.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${userLocal.token}`;

      mutateGetProfileUser();
    }
  }, [reloadApp]);

  return { loadingGetProfileUser, mutateGetProfileUser };
};
