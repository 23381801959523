import { useAtom } from 'jotai';
import { AnimatePresence, motion } from 'framer-motion';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';

import { HeaderItemDropDown, SectionCollapsedItem } from './styled';
import { verifyIcon } from '../iconVerify';
import {
  confirmationOfExchange,
  currentPageId,
  hasSubItem,
  idSubItem,
  isCloseItem,
  isReload,
} from '../../../../../store/NavigationDashboard';
import { useSecurityProfileRender } from '@/hooks/Navigator/useSecurityProfileRender';
import { useNavigate } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { ConfirmationToChangePage } from '../ConfirmationToChangePage';
import { isSavedFlow } from '@/store/FlowBuilder';

export const DropMenuItem = ({ itemMenuObj, openMenuDash }) => {
  const { isOpenMenu } = itemMenuObj;
  const matches = useMediaQuery('(max-width:1020px)');
  const { permissionStatus } = useSecurityProfileRender();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentPage, setCurrentId] = useAtom(currentPageId);
  const [idSub, setSubItemId] = useAtom(idSubItem);
  const [, setHaveSubItem] = useAtom(hasSubItem);
  const [, setIsReload] = useAtom(isReload);
  const [, setIsClosed] = useAtom(isCloseItem);

  const [hasConfirmationExchange, setConfirmationExchange] = useAtom(
    confirmationOfExchange
  );

  const [hasSavedFlow, setSavedFlow] = useAtom(isSavedFlow);

  const [toIdSelected, setToIdSelected] = useState('');

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleNavigate = (link) => {
    navigate(link);
  };

  const reloadPage = async () => {
    setIsReload(true);
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(setIsReload(false));
      }, 10);
    });
  };

  const handleNextConfirmation = () => {
    setSavedFlow(true);
    setConfirmationExchange(false);

    setCurrentId(toIdSelected.itemId);
    setHaveSubItem(toIdSelected.havSubItem);
    setSubItemId(toIdSelected.idSubItem);

    if (matches) {
      setIsClosed(true);
    }
  };

  const confirmationChange = (hasConfirmation = false) => {
    if (hasConfirmation) {
      setOpenConfirmation(true);
      return false;
    }
    return true;
  };

  const confirmationFlowBuilder = (hasConfirmation) => {
    if (!hasConfirmation) {
      setOpenConfirmation(true);
      return false;
    }
    return true;
  };

  const handlePageMainDashboard = (itemId, havSubItem, idSubItem) => {
    setToIdSelected({ itemId, havSubItem, idSubItem });
    if (idSub === idSubItem) {
      reloadPage();
    }

    const confirmationChangeLoadLists = confirmationChange(
      hasConfirmationExchange
    );

    const confirmationChangeFlowBuilder = confirmationFlowBuilder(hasSavedFlow);

    if (!confirmationChangeLoadLists || !confirmationChangeFlowBuilder) {
      return;
    }

    setCurrentId(itemId);
    setHaveSubItem(havSubItem);
    setSubItemId(idSubItem);

    if (matches) {
      setIsClosed(true);
    }
  };

  const isActiveClass =
    currentPage === itemMenuObj.id ? 'activeTitleSubItem' : '';

  const renderNameItem = (itemName, hasExternalUrl) => {
    const renderName = t(`dashboard.${itemName.replaceAll(' ', '')}`);

    if (renderName === `dashboard.${itemName.replaceAll(' ', '')}`) {
      return itemName;
    }

    if (hasExternalUrl) {
      return renderName;
    }

    return renderName;
  };

  const orderSubItem = itemMenuObj?.subItem?.sort(
    (a, b) => a.defaultSequence - b.defaultSequence
  );

  if (
    permissionStatus(itemMenuObj)?.includes('all') ||
    permissionStatus(itemMenuObj)?.includes('display')
  ) {
    return (
      <>
        <HeaderItemDropDown>
          {verifyIcon(itemMenuObj.icon, isActiveClass)}
          <span
            className={isActiveClass}
            onClick={() => openMenuDash(itemMenuObj.id, isOpenMenu)}
          >
            {renderNameItem(itemMenuObj?.name)}
          </span>
          <RiArrowDropDownLine
            aria-label='Button to open the sub menu'
            onClick={() => openMenuDash(itemMenuObj.id, isOpenMenu)}
            className={isOpenMenu ? 'arrow-btn rotate-arrow' : 'arrow-btn'}
          />
        </HeaderItemDropDown>
        <AnimatePresence initial={false}>
          {isOpenMenu && (
            <SectionCollapsedItem
              key='content'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{
                duration: 0.3,
                ease: [0.8, 0.22, 0.12, 0.98],
              }}
            >
              {itemMenuObj.subItem !== false &&
                orderSubItem.map((item, index) => {
                  if (item) {
                    if (
                      permissionStatus(itemMenuObj, item)?.includes('all') ||
                      permissionStatus(itemMenuObj, item)?.includes('display')
                    ) {
                      if (item.link) {
                        return (
                          <motion.span
                            key={index}
                            whileTap={{ scale: 0.99 }}
                            aria-label={item.name}
                            onClick={() => handleNavigate(item.link)}
                            className={idSub === item.id ? 'activeSubItem' : ''}
                          >
                            {renderNameItem(item.name, item.hasExternalUrl)}
                          </motion.span>
                        );
                      }

                      return (
                        <motion.span
                          key={index}
                          whileTap={{ scale: 0.99 }}
                          aria-label={item.name}
                          onClick={() =>
                            handlePageMainDashboard(
                              itemMenuObj.id,
                              itemMenuObj.haveSubItem,
                              item.id
                            )
                          }
                          className={idSub === item.id ? 'activeSubItem' : ''}
                        >
                          {renderNameItem(item.name, item.hasExternalUrl)}
                        </motion.span>
                      );
                    }
                  }
                })}
            </SectionCollapsedItem>
          )}
        </AnimatePresence>

        <ConfirmationToChangePage
          hasOpem={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          confirmCallback={handleNextConfirmation}
        />
      </>
    );
  }
};
