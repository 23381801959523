import { apiFlow } from '../api';

export async function getFlowTemplates(signal = false, client_id) {
  try {
    const { data } = await apiFlow.get(`/dialplan-metadata/${client_id}`, {
      signal,
    });
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
