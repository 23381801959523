import { motion } from 'framer-motion';
import styled from 'styled-components';

import BgPass from '../../assets/imgs/bg-newPass.webp';

export const ContainerChangePassword = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${BgPass});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: grid;
  grid-template-rows: 300px 1fr;

  @media (max-width: 1600px) {
    grid-template-rows: 160px 1fr;
  }

  @media (max-width: 600px) {
    grid-template-rows: 1fr;
  }

  .logo_pass {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    @media (max-width: 600px) {
      display: none;
    }

    img {
      width: 100%;
      max-width: 400px;

      @media (max-width: 1600px) {
        max-width: 300px;
      }
    }
  }

  .main_pass {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 600px) {
      align-items: center;
    }
  }
`;

export const FormModalNewPass = styled(motion.form)`
  width: 96%;
  max-width: 600px;
  max-height: 600px;
  max-height: 600px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  user-select: none;

  @media (max-width: 700px) {
    height: auto;
  }

  header {
    h2 {
      color: ${(props) => props.theme.colors.typography.baseTitle};
    }
  }

  main {
    margin-top: 1rem;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem 0;

    .info_pass {
      margin: 10px 0;
      p {
        font-size: 0.75rem;
        line-height: 1.2rem;
        letter-spacing: 0.145em;
      }
    }

    .content_validation_pass {
      h3 {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 131.4%;
        letter-spacing: 0.1em;
        color: ${(props) => props.theme.colors.typography.baseText};
      }

      .validation_new_input {
        margin-top: 1rem;

        display: flex;
        flex-direction: column;
        gap: 5px;
        span {
          color: ${(props) => props.theme.colors.typography.baseText};
          font-size: 0.75rem;
          margin-left: 1rem;
        }
      }
    }
  }

  footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 0.845rem;
  }
`;
