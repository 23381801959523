import { useMemo, useState, useEffect, Fragment } from 'react';
import { motion } from 'framer-motion';
import { useTable, useSortBy, useExpanded } from 'react-table';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { RiErrorWarningLine } from 'react-icons/ri';
import {
  ExpandedContainerTable,
  TableContentExpanded,
  Td,
  TdNoData,
  Tr,
  TrExpanded,
} from './styled';
import { renderDate } from '@/utils/formatsDateAndTime';

export const ExpandedTable = ({ dataColumns, dataTable, textNoData = '' }) => {
  const { t } = useTranslation();

  const [dataTableState, setDataTableState] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});

  useEffect(() => {
    if (dataTable) setDataTableState(dataTable);
  }, [dataTable]);

  const columns = useMemo(() => dataColumns, [dataColumns]);
  const data = useMemo(() => dataTableState, [dataTableState]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
      },
      useSortBy,
      useExpanded
    );

  const toggleRowExpansion = (row) => {
    const isExpanded = expandedRows[row.id] || false;
    setExpandedRows({
      ...expandedRows,
      [row.id]: !isExpanded,
    });
  };

  const renderTextExpanded = (key) => {
    const labelTranslate = t(`activityLog.table.th-${key}`);
    const hasTranslate = !labelTranslate.includes('activityLog.table.th');
    if (!hasTranslate) {
      return key;
    }
    return labelTranslate;
  };

  return (
    <TableContentExpanded {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <th
                key={i}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.Header !== 'Edit' ? (
                  <div className='content_th'>
                    {column.render('Header')}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <IoMdArrowDropdown />
                      ) : (
                        <IoMdArrowDropup />
                      )
                    ) : (
                      <IoMdArrowDropup style={{ color: 'transparent' }} />
                    )}
                  </div>
                ) : (
                  <div>{column.render('Header')}</div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.length > 0 ? (
          rows.map((row) => {
            prepareRow(row);
            const isExpanded = expandedRows[row.id];

            return (
              <Fragment key={row.id}>
                <Tr
                  {...row.getRowProps()}
                  onClick={() => toggleRowExpansion(row)}
                >
                  {row.cells.map((cell, index) => (
                    <Td key={index} {...cell.getCellProps()}>
                      <span>{cell.render('Cell')}</span>
                    </Td>
                  ))}
                  <Td>
                    <div className='content-details-log'>
                      <motion.div animate={{ rotate: isExpanded ? 90 : 0 }}>
                        <MdKeyboardArrowRight />
                      </motion.div>
                    </div>
                  </Td>
                </Tr>
                {isExpanded && (
                  <TrExpanded>
                    <td colSpan={columns.length}>
                      <div>
                        {row.cells.map((cell, cellIndex) => {
                          if (cellIndex === 0) {
                            const rowData = cell.row.original;
                            return (
                              <ExpandedContainerTable
                                key={cellIndex}
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                              >
                                {Object.entries(rowData).map(([key, value]) => {
                                  if (key === 'date') {
                                    return (
                                      <div className='item-expanded' key={key}>
                                        <span>{renderTextExpanded(key)}:</span>
                                        <span>
                                          {typeof value === 'object' &&
                                          value !== null
                                            ? JSON.stringify(value)
                                            : renderDate(value) ?? '-'}
                                        </span>
                                      </div>
                                    );
                                  }
                                  return (
                                    <div className='item-expanded' key={key}>
                                      <span>{renderTextExpanded(key)}:</span>
                                      <span>
                                        {typeof value === 'object' &&
                                        value !== null
                                          ? JSON.stringify(value)
                                          : value ?? '-'}
                                      </span>
                                    </div>
                                  );
                                })}
                              </ExpandedContainerTable>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </td>
                  </TrExpanded>
                )}
              </Fragment>
            );
          })
        ) : (
          <tr>
            <TdNoData colSpan={columns.length}>
              <div>
                <RiErrorWarningLine />
                <span>{textNoData || 'No Data'}</span>
              </div>
            </TdNoData>
          </tr>
        )}
      </tbody>
    </TableContentExpanded>
  );
};
