import { ContainerBotConnected } from './styled';
import { BiSolidPhoneOutgoing } from 'react-icons/bi';
import { RiMessage3Fill } from 'react-icons/ri';

import { useTranslation } from 'react-i18next';
import { HiOutlineStatusOnline } from 'react-icons/hi';

export const ContentBot = ({ talking = 0, discing = 0, status = '' }) => {
  const { t } = useTranslation();

  const generateStatus = (status) => {
    switch (status) {
      case 'DISCANDO':
        return t('monitorVirtualAgents.queueDialing').toUpperCase();
      case 'LIVRE':
        return t('monitorVirtualAgents.queueFree').toUpperCase();
      case 'PAUSA':
        return t('monitorVirtualAgents.queuePaused').toUpperCase();
    }
  };

  return (
    <ContainerBotConnected>
      <div className='item_footer_card flex-row'>
        <RiMessage3Fill style={{ color: '#FF4C43' }} />
        <div className='divider_string'>
          <span>{t('summaryMonitor.label-discingLines')}:</span>
        </div>
        <span>{discing < 10 ? `0${discing}` : discing}</span>
      </div>
      <div className='item_footer_card flex-row'>
        <BiSolidPhoneOutgoing style={{ color: '#4c48ff' }} />
        <div className='divider_string'>
          <span>{t('summaryMonitor.label-talkingLines')}:</span>
        </div>
        <span>{talking < 10 ? `0${talking}` : talking}</span>
      </div>
      <div className='item_footer_card flex-row'>
        <HiOutlineStatusOnline style={{ color: '#0cc04b' }} />
        <div className=''>
          <span>Status:</span>
        </div>
        <span className='status_string'>{generateStatus(status)}</span>
      </div>
    </ContainerBotConnected>
  );
};
