import WaveFlow from '@/components/Waveform/waveFlow';
import { ContainerPlayer } from './styles';

export const PlayerPromptMessage = ({ soundResponse }) => {
  return (
    <ContainerPlayer>
      <WaveFlow audio={soundResponse} />
    </ContainerPlayer>
  );
};
