import styled from 'styled-components';

export const StyledColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px 1fr;
  width: 100%;
  height: 480px;
  gap: 8px;

  @media (max-width: 1440px) {
    height: 400px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr 36px 1fr;
    gap: 1px;
  }

  .available {
    grid-row: 1/2;
    grid-column: 1/2;
    height: 480px;

    @media (max-width: 1440px) {
      height: 400px;
    }
  }

  .control-list {
    grid-row: 1/2;
    grid-column: 2/3;
  }

  .selected {
    grid-row: 1/2;
    grid-column: 3/4;
    height: 480px;

    @media (max-width: 1440px) {
      height: 400px;
    }
  }
`;

export const StyledColumn = styled.div`
  padding: 0rem;
  display: flex;
  flex-direction: column;
  margin-top: 4;
  background-color: ${(props) => props.theme.colors.containers.baseContainer}AA;
  border-radius: 3px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 8px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: ${(props) =>
      props.theme.colors.containers.baseContainer}AA;
    bottom: -8px;
    z-index: -1;
  }

  h2 {
    margin: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: ${(props) => props.theme.colors.containers.baseShadows};
    width: 100%;
    background-color: ${(props) => props.theme.colors.containers.primaryToDark};
    padding: 0.7rem 1rem 0.48rem 1rem;
    color: ${(props) => props.theme.colors.bases.baseWhite} !important;
    font-size: 0.75rem !important;
    text-transform: uppercase;
  }
`;

export const StyledList = styled.div`
  border-radius: 2px;
  padding: 0.4rem 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;

  span {
    width: 90%;
  }
`;

export const ContainerControlList = styled.div`
  grid-row: 1/2;
  grid-column: 2/3;
  width: 100%;
  flex: 1;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 640px) {
    button {
      padding: 0 !important;
      min-width: 30px !important;
    }
  }
`;

export const StyledItem = styled.div`
  background-color: ${(props) => props.theme.colors.containers.colorCardThird};
  border-radius: 4px;
  padding: 0.6rem 1rem;
  transition: all 0.1s ease;
  margin-top: 0.4rem;
  color: ${(props) => props.theme.colors.typography.baseText};
  display: flex;
  font-size: 0.78rem;
  user-select: none;

  justify-content: space-between;
  align-items: center;

  .icon-item {
    svg {
      display: none;
    }
  }

  :hover {
    background-color: ${(props) => props.theme.colors.containers.dividerColor};
    font-weight: 600;

    .icon-item {
      svg {
        display: block;
      }
    }
  }
`;
