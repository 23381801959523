import { ContainerDescription } from './styled';

export const DescriptionInformation = ({
  textDescription = '',
  variant = 'primary',
}) => {
  return (
    <ContainerDescription variant={variant}>
      <span>{textDescription}</span>
    </ContainerDescription>
  );
};
