import { Button } from '@/components/FormComponents/ButtonComponent';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { ContainerControlList } from './styledItems';

export const ControlList = ({ controls, listAvailable, listSelected }) => {
  return (
    <ContainerControlList>
      <Button
        onClick={controls.handleSelectAllValues}
        disabled={listAvailable.length === 0}
      >
        <MdArrowForward size={20} />
      </Button>
      <Button
        onClick={controls.handleUnselectAllValues}
        disabled={listSelected && listSelected.length === 0}
      >
        <MdArrowBack size={20} />
      </Button>
    </ContainerControlList>
  );
};
