import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { createGroupDDD } from '@/services/dialControl/DDDManager/createGroupDDD';
import { createScheduleDDD } from '@/services/dialControl/DDDManager/createScheduleDDD';
import { deleteGroupDDD } from '@/services/dialControl/DDDManager/deleteGroupDDD';
import { deleteSelectedDDDManager } from '@/services/dialControl/DDDManager/deleteSelectedDDDManager';
import { getDDDManager } from '@/services/dialControl/DDDManager/getDDDManager';
import { getSelectedDDDManager } from '@/services/dialControl/DDDManager/getSelectedDDDManager';
import { updateDDDManager } from '@/services/dialControl/DDDManager/updateDDDManager';
import { dataDDDManager, stageDDDManager } from '@/store/DialingStore';
import { updateDDDGroup } from '@/services/dialControl/DDDManager/updateDDDGroup';

export const useDDDManager = () => {
  const { t } = useTranslation();
  const [, setListDDDManagers] = useAtom(dataDDDManager);
  const [currentStageDDD] = useAtom(stageDDDManager);

  const {
    isLoading: loadingDataDDDs,
    refetch: refetchDDDs,
    isFetching: fetchingDataDDDs,
  } = useQuery(
    'getDDDsList',
    async () => {
      return await getDDDManager();
    },
    {
      enabled: currentStageDDD === 0,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => setListDDDManagers(data),
      onError: () =>
        errorToast(t(`dialControl.dddManager.list.msg-error-getLists`)),
    }
  );

  const { mutate: mutateUpdateGroupDDD, isLoading: loadingUpdateGroupDDD } =
    useMutation({
      mutationFn: async (dataSchedule) => {
        return await updateDDDGroup(dataSchedule);
      },
      onSuccess: () => {
        refetchDDDs();
      },
      onError: () => {
        errorToast(t(`dialControl.dddManager.edit.msg-error-updatedGroup`));
      },
    });

  const { mutate: mutateCreateGroupDDD, isLoading: loadingCreateGroupDDD } =
    useMutation({
      mutationFn: async (dataSchedule) => {
        return await createGroupDDD(dataSchedule);
      },
      onSuccess: () => {
        refetchDDDs();
      },
      onError: (error) => {
        const errorMessage = error.message || JSON.stringify(error);
        const toastMessage =
          errorMessage === 'This DDD manager already exists.'
            ? t('dialControl.dddManager.create.msg-error-alreadyExists')
            : t('dialControl.dddManager.create.msg-error-created');
        return errorToast(toastMessage);
      },
    });

  const { mutate: mutateDeleteGroupDDD, isLoading: loadingDeleteGroupDDD } =
    useMutation({
      mutationFn: async (idGroupDelete) => {
        return await deleteGroupDDD(idGroupDelete);
      },
      onSuccess: () => {
        refetchDDDs();
      },
      onError: () => {
        errorToast(t(`dialControl.dddManager.list.msg-error-delete`));
      },
    });

  const { mutate: mutateGetDataDDD, isLoading: loadingGetDataDDD } =
    useMutation({
      mutationFn: async (dataOperator) => {
        return await getSelectedDDDManager(dataOperator);
      },
      onError: () => {
        errorToast(t(`dialControl.dddManager.list.msg-error-getLists`));
      },
    });

  const { mutate: mutateUpdatedDDD, isLoading: loadingUpdatedDDD } =
    useMutation({
      mutationFn: async (dataUpdate) => {
        return await updateDDDManager(dataUpdate);
      },
      onError: () => {
        errorToast(
          t(`dialControl.dddManager.create.msg-error-updatedScheduler`)
        );
      },
    });

  const {
    mutate: mutateCreateScheduleDDD,
    isLoading: loadingCreateScheduleDDD,
  } = useMutation({
    mutationFn: async (dataSchedule) => {
      return await createScheduleDDD(dataSchedule);
    },
    onError: () => {
      errorToast(t(`dialControl.dddManager.create.msg-error-createdScheduler`));
    },
  });

  const {
    mutate: mutateDeleteScheduleDDD,
    isLoading: loadingDeleteScheduleDDD,
  } = useMutation({
    mutationFn: async (dataSchedule) => {
      return await deleteSelectedDDDManager(dataSchedule);
    },
    onError: () => {
      errorToast(t(`dialControl.dddManager.create.msg-error-deleteScheduler`));
    },
  });

  return {
    loadingDataDDDs,
    refetchDDDs,
    fetchingDataDDDs,
    mutateGetDataDDD,
    loadingGetDataDDD,
    mutateUpdatedDDD,
    loadingUpdatedDDD,
    mutateCreateScheduleDDD,
    loadingCreateScheduleDDD,
    mutateDeleteScheduleDDD,
    loadingDeleteScheduleDDD,
    mutateCreateGroupDDD,
    loadingCreateGroupDDD,
    mutateDeleteGroupDDD,
    loadingDeleteGroupDDD,
    mutateUpdateGroupDDD,
    loadingUpdateGroupDDD,
  };
};
