import styled from 'styled-components';

export const NoDataContainer = styled.div`
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  user-select: none;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  gap: 0.4rem;

  svg {
    color: ${(props) => props.theme.colors.bases.secondary};
    font-size: 1.2rem;
  }
  span {
    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;
