import { getServersControl } from '@/services/dialControl/serversControl/getServersControl';
import { dataServersControl } from '@/store/Queues';
import { useAtom } from 'jotai';
import { useQuery } from 'react-query';

export const useServersControl = () => {
  const [, setServersControl] = useAtom(dataServersControl);

  const { isLoading: isLoadingServers } = useQuery(
    'dataServersControl',
    async () => {
      return await getServersControl();
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.length > 0) {
          let minFirstQueue = data[0].first_queue;
          let maxLastQueue = data[0].last_queue;
          for (let i = 1; i < data.length; i++) {
            if (data[i].isActive) {
              if (parseInt(data[i].first_queue) < parseInt(minFirstQueue)) {
                minFirstQueue = data[i].first_queue;
              }
              if (parseInt(data[i].last_queue) > parseInt(maxLastQueue)) {
                maxLastQueue = data[i].last_queue;
              }
            }
          }
          let rangeQueues = {
            first_queue: minFirstQueue,
            last_queue: maxLastQueue,
          };
          setServersControl(rangeQueues);
        }
      },
    }
  );

  return { isLoadingServers };
};
