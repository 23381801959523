import { Handle, Position } from 'reactflow';
import { useAtom } from 'jotai';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TiArrowLoop } from 'react-icons/ti';
import { shortNameRender } from '../../../../utils/shortNameRender';
import { FlexBoxRow } from '../styled';
import { NodeConditionContainer, NodeContent } from './styled';
import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { PanelNode } from '@/components/pages/FlowBuilder/components/PanelNode';

const LoopNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();

  const [, setShowMenu] = useAtom(showMenuNodes);
  const [, setSelectedNode] = useAtom(selectedNode);

  const handleNode = useCallback(() => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  }, [id, data, setSelectedNode, setShowMenu]);

  const renderHeightNode = useMemo(() => {
    const totalItems = data?.conditionsList?.length ?? 0;
    const heightItem = 28;
    const totalHeight = totalItems <= 1 ? 140 : 130 + totalItems * heightItem;
    return `${totalHeight}px`;
  }, [data?.conditionsList]);

  return (
    <NodeConditionContainer
      selected={selected}
      onDoubleClick={() => handleNode()}
      errorConnect={data.errorConnect ? 1 : 0}
      heighNode={renderHeightNode}
      handler={data.handler}
    >
      <PanelNode data={data} id={id} />

      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <TiArrowLoop size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
          </div>
        </div>
        <div className='main'>
          <FlexBoxRow>
            <span>{t('flowBuilder.flow.nodes.loop.menu.label-attempts')}:</span>
            <span>
              <strong>{data.attempts}</strong>
            </span>
          </FlexBoxRow>
        </div>
      </NodeContent>
    </NodeConditionContainer>
  );
};

export const LoopNode = memo(LoopNodeComponent);
