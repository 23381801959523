import styled from 'styled-components';

export const ContainerJourney = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: grid;
  grid-template-rows: 160px 1fr;
  padding: 2rem;
  gap: 2rem;
`;
