import { atom } from 'jotai';

export const selectedQueue = atom(null);
export const dataInfoQueue = atom(null);
export const serversQueue = atom(null);

export const currentConfigComponent = atom(0);

export const listOptionsCampaign = atom(null);
export const dataInfoCampaign = atom(null);

export const valueFieldQueue = atom('');
export const valueFieldPhonePosition = atom('');
export const valueCheckboxAllPositions = atom(false);

export const openDialogInfoStatus = atom(false);

export const statusQueueSelected = atom('abrir');
export const queueInfo = atom(null);

export const stateRenderDialing = atom(0);

export const CodListAvailable = atom([]);
export const CodListToAdd = atom([]);
export const errorTransferList = atom(false);

export const dataOptionsDialing = atom([]);

export const dataFlowOptions = atom([]);

export const dataQueueOperators = atom([]);

// DATA DDD MANAGER
export const stageDDDManager = atom(0);
export const dataDDDManager = atom([]);
export const dataSelectedDDDManager = atom(null);

// DATA UF MANAGER
export const stageUFManager = atom(0);
export const dataUFManager = atom([]);
export const dataUfManagerSelected = atom([]);

// DATA LISTS DIALING
export const dataListsDialing = atom([]);
export const dataListsByClient = atom([]);
export const stageListCampaign = atom(0);

// DATA LISTS DIALING
export const stageDispositions = atom(0);
export const dataDispositionsDialing = atom([]);
export const dataSelectedDisposition = atom(null);
