import { string, object } from 'yup';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import useFormControl from '@/hooks/useFormControl';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { ContentChangeClienteControl } from './styled';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { dataListClients } from '@/store/Clients';
import { user } from '@/store/AuthenticatorStore';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { useControlClient } from '@/hooks/UserAgents/useControlClient';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import {
  dataDashboardCharts,
  selectedQueueToSearchCharts,
} from '@/store/DashboardPage';
// import { ControlAutoComplete } from '@/components/FormComponents/AutoCompleteSelect';

export const ChangeClienteControl = ({ handleClose }) => {
  const { t } = useTranslation();
  const [listClients] = useAtom(dataListClients);
  const [currentUser] = useAtom(user);
  const [, setQueueSelected] = useAtom(selectedQueueToSearchCharts);
  const [, setDashData] = useAtom(dataDashboardCharts);

  const { mutateUpdateClientUser, loadingUpdateClientUser } =
    useControlClient();

  const changeClientSchema = object().shape({
    client: string(),
  });

  const { handleSubmit, control, reset } = useFormControl(changeClientSchema);

  const handleSubmitChange = (data) => {
    setQueueSelected('0');
    setDashData((previous) => ({
      ...previous,
      queuesDashboard: [],
    }));
    const { user_id } = currentUser;
    const dataUpdate = {
      userId: user_id,
      client_id: data.client,
    };
    return mutateUpdateClientUser(dataUpdate, {
      onSuccess: () => {
        reset();
      },
    });
  };

  return (
    <ContentChangeClienteControl>
      {loadingUpdateClientUser && <BackDropCircularLoading />}
      <div className='header'>
        <TitleComponent
          iconTitle='client'
          textTitle={t('controlClient.title')}
        />
      </div>
      <div className='main'>
        <form onSubmit={handleSubmit(handleSubmitChange)}>
          <DescriptionInformation
            variant='secondary'
            textDescription={t('controlClient.description')}
          />

          <ControlSelect
            control={control}
            nameControl={'client'}
            label={t('controlClient.label-client')}
            defaultValue={currentUser.client_id}
          >
            <ItemSelect value={'0'} disabled>
              {t('controlClient.options-client')}
            </ItemSelect>
            {listClients.length > 0 &&
              listClients.map((item, i) => (
                <ItemSelect value={item.id} key={i}>
                  {item.commercial_name}
                </ItemSelect>
              ))}
          </ControlSelect>

          {/* <ControlAutoComplete
            control={control}
            nameControl={'client'}
            label={t('controlClient.label-client')}
            optionsList={[]}
            // error={Boolean(errors.list)}
          /> */}

          <div className='footer'>
            <Button typeText variant={'secondary'} onClick={handleClose}>
              {t('controlClient.btn-cancel')}
            </Button>
            <Button typeText type='submit'>
              {t('controlClient.btn-salve')}
            </Button>
          </div>
        </form>
      </div>
    </ContentChangeClienteControl>
  );
};
