import styled from 'styled-components';

export const ContainerForgotPassForm = styled.form`
  width: 60%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 460px) {
    width: 86%;
  }

  .title_forgot {
    h2 {
      width: 60%;
      font-size: 1.125rem;
      color: #27395e;
    }
  }

  .main_forgot {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    p {
      width: 100%;
      font-size: 1rem;
      color: #27395e;
    }
  }

  .footer_forgot {
    display: flex;
    justify-content: flex-end;
    gap: 0.4rem;
  }
`;
