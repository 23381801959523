import { useEffect, useState } from 'react';
import { ContentNode, NodeMailingContainer } from './styled';
import { Handle, Position, useStore } from 'reactflow';

import smLogoVoicce from '@/assets/imgs/icon-sm-voicce.svg';
import smLogoVoicceDisabled from '@/assets/imgs/icon-sm-voicce-disabled.svg';
import { useAtom } from 'jotai';
import { openMenuNodeMailing, selectedNodeConfig } from '@/store/LoadList';
import { AiOutlineFunction } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { errorToast } from '@/components/FormComponents/Toast';
import { Tooltip } from '@mui/material';

export const NodeMailing = ({ id, data, selected }) => {
  const { t } = useTranslation();
  const [isConnected, setIsConnected] = useState(false);
  const [, setOpenMenu] = useAtom(openMenuNodeMailing);
  const [, setSelectedConfigNode] = useAtom(selectedNodeConfig);

  const [hasErrorConnectEdge, setHasErrorConnectEdge] = useState(0);

  const [isHovered, setIsHovered] = useState(false);

  const handleOpen = () => {
    setIsHovered(true);
  };

  const handleClose = () => {
    setIsHovered(false);
  };

  const dataFlow = useStore((store) => {
    return {
      edges: store.edges,
      nodes: store.getNodes(),
    };
  });

  useEffect(() => {
    const dataConnectedError = dataFlow.edges
      .filter((edge) => edge.target === id)
      .filter((edge) => edge.type === 'errorEdge');

    setHasErrorConnectEdge(0);

    if (dataConnectedError.length > 0) {
      setHasErrorConnectEdge(1);
    }

    const isHandleConnected = dataFlow.edges.some((edge) => edge.target === id);
    setIsConnected(isHandleConnected);
  }, [dataFlow.edges, id]);

  const handleEditNode = () => {
    if (!isConnected || hasErrorConnectEdge) {
      return;
    }

    const connectedEdges = dataFlow.edges.filter((edge) => edge.target === id);

    if (connectedEdges.length === 0) {
      return errorToast(
        t('listLoader.importMailing.flow.label-column-not-found')
      );
    }

    const idSourceNodeConnected = connectedEdges
      .map((item) => item.source)
      .toString();

    const sourceNode = dataFlow.nodes.find(
      (node) => node.id === connectedEdges[0].source
    );

    if (!sourceNode) {
      return errorToast('O campo não existe!');
    }

    if (sourceNode.data.label === 'column_NULL') {
      return errorToast(
        t('listLoader.importMailing.flow.label-column-not-found')
      );
    }

    setOpenMenu(true);
    setSelectedConfigNode({
      id,
      ...data,
      isConnected,
      idNodeConnected: idSourceNodeConnected,
    });
  };

  const translateFunction = (nameFunc) => {
    switch (nameFunc) {
      case 'sub':
        return t('listLoader.importMailing.flow.option-substring');
      case 'replace':
        return t('listLoader.importMailing.flow.option-replace');
      case 'include':
        return t('Include');
      default:
        return nameFunc;
    }
  };

  const renterTitleFunction = () => {
    if (hasErrorConnectEdge) {
      return 'No Data Column';
    }

    if (data.filters.length > 0) {
      return (
        <ul>
          {data.filters.map((data, i) => (
            <li key={i}>
              {i + 1} - {translateFunction(data.data[0])}
            </li>
          ))}
        </ul>
      );
    }

    return '';
  };

  return (
    <NodeMailingContainer
      selected={selected}
      connected={isConnected}
      onDoubleClick={handleEditNode}
      aria-hidden={true}
      errorEdge={hasErrorConnectEdge}
    >
      <ContentNode errorEdge={hasErrorConnectEdge}>
        <div className='headerNode'>
          {isConnected ? (
            <img src={smLogoVoicce} alt='' />
          ) : (
            <img src={smLogoVoicceDisabled} alt='' />
          )}
        </div>
        <div className='mainNode'>
          <h4>{data.label}</h4>
          <span>
            {t('listLoader.importMailing.flow.label-column-from-system')}
          </span>
        </div>
        <div className='content-edit'>
          {data.filters.length > 0 ? (
            <Tooltip
              open={isHovered}
              onClose={handleClose}
              onOpen={handleOpen}
              arrow
              placement='right'
              title={renterTitleFunction()}
            >
              <div
                className='indicatorConnect'
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
              >
                <AiOutlineFunction />
              </div>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      </ContentNode>
      <Handle type='target' position={Position.Left} />
    </NodeMailingContainer>
  );
};
