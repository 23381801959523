import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerListCallOperators = styled(motion.div)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  min-height: 200px;
  padding: 3rem 2rem;

  .content_btn_new_tenant {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;
