import styled from 'styled-components';

export const ControlBtnsTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .management_queue,
  .management_table {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    svg {
      color: ${(props) => props.theme.colors.bases.baseWhite};
      height: 18px;
    }
  }
`;
