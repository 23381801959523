import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  dataByClientRoutingProfile,
  dataDefaultRoutingProfile,
  dataRoutingProfile,
  stateRenderRoutingProfiles,
} from '@store/UsersAgentsStore/routingProfiles';

import { getRoutingProfileList } from '@services/userAgents/routingProfile/getRoutingProfileList';
import { createRoutingProfile } from '@services/userAgents/routingProfile/createRoutingProfile';
import { editRoutingProfile } from '@services/userAgents/routingProfile/editRoutingProfile';

import { errorToast } from '@components/FormComponents/Toast';
import { deleteRoutingProfile } from '@/services/userAgents/routingProfile/deleteRoutingProfile';
import { deleteScreenRoutingProfile } from '@/services/userAgents/routingProfile/deleteScreenRoutingProfile';
import { createScreenRoutingProfile } from '@/services/userAgents/routingProfile/createScreenRoutingProfile';
import { getRoutingProfileByClient } from '@/services/userAgents/routingProfile/getRoutingProfileByClient';
import { getRoutingProfileById } from '@/services/userAgents/routingProfile/getRoutingProfileById';

export const useRoutingProfile = (isExternal = false, origin = null) => {
  const { t } = useTranslation();
  const [, setDataRoutingProfile] = useAtom(dataRoutingProfile);
  const [, setDataByClientRoutingProfile] = useAtom(dataByClientRoutingProfile);
  const [, setDataDefaultRoutingProfile] = useAtom(dataDefaultRoutingProfile);
  const [currentRenderRoutingProfile] = useAtom(stateRenderRoutingProfiles);

  // LIST ROUTING PROFILE
  const {
    isLoading: isLoadingRoutingProfile,
    isFetching: isFetchingRouting,
    refetch: refetchRoutingProfile,
  } = useQuery(
    'dataRoutingProfile',
    async ({ signal }) => {
      return await getRoutingProfileList(signal);
    },
    {
      enabled: currentRenderRoutingProfile.current === 0 && !origin,
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setDataRoutingProfile(data);
      },
      onError: (error) => {
        if (!isExternal) {
          const errorMessage = error.message || JSON.stringify(error);
          const toastMessage =
            errorMessage === 'User is not SysAdmin!'
              ? null
              : errorToast(
                  t(
                    'routingProfile.homeRoutingProfiles.messages.errorDataTableRoutingProfile'
                  )
                );
          return toastMessage;
        }
      },
    }
  );

  // CREATE ROUTING PROFILE
  const {
    mutate: mutateCreateRoutingProfile,
    isLoading: loadingCreateRoutingProfile,
  } = useMutation({
    mutationFn: async (dataCreate) => {
      return await createRoutingProfile(dataCreate);
    },
    onError: (error) => {
      const errorMessage = error.message || JSON.stringify(error);
      const toastMessage =
        errorMessage === 'Routing already exists!'
          ? t(
              'routingProfile.addRoutingProfile.detailsAddRouting.messages.error-alreadyExists'
            )
          : t(
              'routingProfile.addRoutingProfile.messages.errorCreateRoutingProfile'
            );
      return errorToast(toastMessage);
    },
  });

  // EDIT ROUTING PROFILE
  const {
    mutate: mutateEditRoutingProfile,
    isLoading: loadingEditRoutingProfile,
  } = useMutation({
    mutationFn: async (dataEdit) => {
      return await editRoutingProfile(dataEdit);
    },
    onSuccess: () => refetchRoutingProfile(),
    onError: () => {
      t('routingProfile.editRoutingProfile.messages.errorEditProfile');
    },
  });

  // DELETE ROUTING PROFILE
  const {
    mutate: mutateDeleteRoutingProfile,
    isLoading: loadingDeleteRoutingProfile,
  } = useMutation({
    mutationFn: async (idRoutingDelete) => {
      return await deleteRoutingProfile(idRoutingDelete);
    },
    onSuccess: () => refetchRoutingProfile(),
  });

  // Create SCREENS ROUTING_PROFILE
  const {
    mutate: mutateCreateScreenRouting,
    isLoading: loadingCreateScreenRouting,
  } = useMutation({
    mutationFn: async (dataCreateScreen) => {
      return await createScreenRoutingProfile(dataCreateScreen);
    },
    onSuccess: () => refetchRoutingProfile(),
    onError: () => {
      t(
        'routingProfile.addRoutingProfile.createScreens.messages.msg-error-createScreen'
      );
    },
  });

  // Delete SCREENS ROUTING_PROFILE
  const {
    mutate: mutateDeleteScreenRouting,
    isLoading: loadingDeleteScreenRouting,
  } = useMutation({
    mutationFn: async (dataRemoveScreen) => {
      return await deleteScreenRoutingProfile(dataRemoveScreen);
    },
    onSuccess: () => refetchRoutingProfile(),
    onError: () => {
      t(
        'routingProfile.addRoutingProfile.createScreens.messages.msg-error-deleteScreen'
      );
    },
  });

  // GET DEFAULT ROUTING PROFILES

  const { mutate: mutateDefaultRouting, isLoading: loadingDefaultRouting } =
    useMutation({
      mutationFn: async (dataListRoutingUser) => {
        return await console.log(dataListRoutingUser);
      },
      //   onSuccess: (data) => {
      //     console(data);
      //   },
      onError: () => {
        t(
          'routingProfile.homeRoutingProfiles.messages.errorDataTableRoutingProfile'
        );
      },
    });

  const { mutate: mutateRoutingByClient, isLoading: loadingRoutingByClient } =
    useMutation({
      mutationFn: async (dataListRoutingUser) => {
        return await getRoutingProfileByClient(dataListRoutingUser);
      },
      onSuccess: (data) => {
        setDataByClientRoutingProfile(data);
      },
      onError: () => {
        t(
          'routingProfile.homeRoutingProfiles.messages.errorDataTableRoutingProfile'
        );
      },
    });

  const { mutate: mutateRoutingById, isLoading: loadingRoutingById } =
    useMutation({
      mutationFn: async (idUser) => {
        return await getRoutingProfileById(idUser);
      },
      onSuccess: (data) => {
        setDataDefaultRoutingProfile(data);
      },
      onError: () => {
        t(
          'routingProfile.homeRoutingProfiles.messages.errorDataTableRoutingProfile'
        );
      },
    });

  return {
    isLoadingRoutingProfile,
    isFetchingRouting,
    mutateCreateRoutingProfile,
    loadingCreateRoutingProfile,
    mutateEditRoutingProfile,
    loadingEditRoutingProfile,
    mutateDeleteRoutingProfile,
    loadingDeleteRoutingProfile,

    mutateDeleteScreenRouting,
    loadingDeleteScreenRouting,

    mutateCreateScreenRouting,
    loadingCreateScreenRouting,

    mutateDefaultRouting,
    loadingDefaultRouting,

    mutateRoutingByClient,
    loadingRoutingByClient,

    mutateRoutingById,
    loadingRoutingById,
  };
};
