import { Checkbox } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { TableStyledComponent } from '@/components/Tables/CustomTable';
import { TableRow } from '@/components/Tables/CustomTable/TableRow';
import { TableData } from '@/components/Tables/CustomTable/TableData';
import { useSecurityProfile } from '@/hooks/UserAgents/useSecurityProfile';
import { toPascalCase } from '@/utils/capitalizeFirstLetter ';

export const TablePermissions = ({
  dataPermission,
  dataScreensList,
  setDataScreensList,
}) => {
  const { t } = useTranslation();

  const { mutateUpdateRoleSecurityProfile } = useSecurityProfile();

  const headersTable = [
    'securityProfile.addSecurityProfiles.listPermissions.dataHeaderTables.firstItem',
    // 'securityProfile.addSecurityProfiles.listPermissions.dataHeaderTables.secondItem',
    'securityProfile.addSecurityProfiles.listPermissions.dataHeaderTables.fifthItem',
    'securityProfile.addSecurityProfiles.listPermissions.dataHeaderTables.fourthItem',
    'securityProfile.addSecurityProfiles.listPermissions.dataHeaderTables.sixthItem',
    'securityProfile.addSecurityProfiles.listPermissions.dataHeaderTables.thirdItem',
  ];

  const changePermissionCheckBox = (dataCheckbox, namePermission, newValue) => {
    for (let permission of dataCheckbox.permissions) {
      if (permission.name === namePermission) {
        permission.value = newValue;
        break;
      }
    }
  };

  const handleChange = (event, dataState, id, roles_id) => {
    const dataUpdate = {
      roles_id: roles_id,
      name: dataState.name,
      value: !dataState.value,
    };

    const updatedDataScreens = dataScreensList.permissions.map((item) => {
      if (item.subItem || item.subItem.length > 0) {
        item.subItem.map((itemSub) => {
          if (itemSub.id === id) {
            if (event.target.name === 'all') {
              itemSub.permissions.forEach((permission) => {
                permission.value = event.target.checked;
              });
            } else {
              changePermissionCheckBox(
                itemSub,
                dataState.name,
                event.target.checked
              );
              const isAnyUnchecked = itemSub.permissions.some(
                (permission) => !permission.value
              );
              if (isAnyUnchecked) {
                const allPermission = itemSub.permissions.find(
                  (permission) => permission.name === 'all'
                );
                if (allPermission) {
                  allPermission.value = false;
                }
              }
            }
          }
          return itemSub;
        });
      }
      if (item.id === id) {
        if (event.target.name === 'all') {
          //   item.permissions.forEach((permission) => {
          //     permission.value = event.target.checked;
          //   });
        } else {
          changePermissionCheckBox(item, dataState.name, event.target.checked);
          const isAnyUnchecked = item.permissions.some(
            (permission) => !permission.value
          );
          if (isAnyUnchecked) {
            const allPermission = item.permissions.find(
              (permission) => permission.name === 'all'
            );
            if (allPermission) {
              allPermission.value = false;
            }
          }
        }
        return item;
      }
      return item;
    });

    mutateUpdateRoleSecurityProfile(dataUpdate, {
      onSuccess: () => {
        return setDataScreensList((previousState) => {
          return {
            ...previousState,
            permissions: updatedDataScreens,
          };
        });
      },
    });
  };

  const createDataRow = (dataPermission) => {
    const { id, name, permissions, subItem, roles_id } = dataPermission;

    const addAllPermissions = [...permissions];

    const dataSub = [];

    if (subItem) {
      const itensSubTable = subItem.map((item) => ({
        id: item.id,
        typeLabel: item.name,
        permissions: item.permissions,
        roles_id: item.roles_id,
      }));

      dataSub.push(...itensSubTable);
    }

    const dataRowHome = [
      {
        id,
        typeLabel: name,
        permissions: addAllPermissions,
        roles_id,
      },
      ...dataSub,
    ];

    return dataRowHome;
  };

  const renderTitleSubTable = (itemTypeLabel) => {
    const result = toPascalCase(itemTypeLabel);
    const translatedItem = t(`dashboard.${result}`);
    return translatedItem === `dashboard.${result}` ? result : translatedItem;
  };

  return (
    <TableStyledComponent>
      <thead>
        <tr>
          {headersTable.map((item, i) => {
            const labelHeader = t(item);
            return (
              <th key={i}>
                <div>{labelHeader}</div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {createDataRow(dataPermission).map((item, i) => {
          return (
            <TableRow key={i}>
              <TableData>
                <span>{renderTitleSubTable(item.typeLabel, i)}</span>
              </TableData>
              {item.permissions?.map((itemCheckBox, i) => {
                return (
                  <TableData key={i}>
                    <Checkbox
                      name={itemCheckBox.name}
                      checked={itemCheckBox.value}
                      onChange={(e) =>
                        handleChange(e, itemCheckBox, item.id, item.roles_id)
                      }
                      inputProps={{ 'aria-label': itemCheckBox.name }}
                      size='small'
                    />
                  </TableData>
                );
              })}
            </TableRow>
          );
        })}
      </tbody>
    </TableStyledComponent>
  );
};
