import { useEffect } from 'react';
import { useControlTimeAgent } from '@hooks/Agents/useControlTimeAgent';
import { LeftSideLogin } from './LeftSide';
import { RightSideLogin } from './RightSide';
import { ContainerLogin } from './styled';

export const Login = () => {
  const { removeLocalStatus } = useControlTimeAgent();

  useEffect(() => {
    removeLocalStatus();
  }, []);

  return (
    <ContainerLogin>
      <LeftSideLogin />
      <RightSideLogin />
    </ContainerLogin>
  );
};
