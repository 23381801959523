import { motion } from 'framer-motion';
import styled from 'styled-components';

export const TabMonitorContainer = styled(motion.div)`
  width: 100%;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 420px) {
    flex-direction: column;
  }

  .buttonActive {
    opacity: 0.65 !important;
  }

  .disposition-list {
    display: flex;
    justify-content: center;
    gap: 10px;

    button {
      width: 20px;
      height: 38px;
      padding: 0;
      svg {
        font-size: 1rem;
      }

      @media (max-width: 420px) {
        width: 100%;
        height: 30px;
      }
    }
  }

  .select-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 1rem;

    .select-status {
      width: 100%;
      max-width: 300px;
    }
  }
`;
