export async function geCallingEffortsService(dataSearch) {
  try {
    const dataEfforts = [];

    for (let i = 1; i <= 10000; i++) {
      dataEfforts.push({
        name: `name ${i}`,
        cpf: `${i}`,
        list: `list ${i}`,
        ddd: `00`,
        phone: `${i}`,
        disposition: `${i}`,
        description: 'descricao disposition',
        duration: '00:00:00',
        dateCall: '16/07/2024 10:58:20',
      });
    }

    const data = {
      ...dataSearch,
      callingEfforts: dataEfforts,
    };
    return new Promise((resolve) => {
      setTimeout(() => resolve(data), 1000);
    });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
