import { apiData } from '@/services/api';

export async function getCountListByCampaignsService(dataSearch) {
  try {
    const { data } = await apiData.post(
      'reports/listCountByCampaign',
      dataSearch
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
