import { useEffect } from 'react';

export const useLeaveClientConfirmation = (shouldConfirm) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (shouldConfirm) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldConfirm]);
};
