import { useEffect, useId } from 'react';
import { ContainerCountInList } from './styled';
import { SearchCounts } from './SearchCounts';
import { useAtom } from 'jotai';
import { stageCountInList } from '@/store/Reports/countInListStore';
import { CountReports } from './CountReports';

export const CountInList = () => {
  const [stateRender, setStateRender] = useAtom(stageCountInList);

  const changeFlow = (stage) => setStateRender(stage);

  useEffect(() => {
    return () => setStateRender(0);
  }, []);

  const flowCountInList = [
    <SearchCounts key={useId()} changeFlow={changeFlow} />,
    <CountReports key={useId()} changeFlow={changeFlow} />,
  ];

  return (
    <ContainerCountInList>{flowCountInList[stateRender]}</ContainerCountInList>
  );
};
