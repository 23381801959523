import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Stack } from '@mui/system';
import { FaGear } from 'react-icons/fa6';
import { Skeleton } from '@mui/material';
import { useVariablesFlow } from '@/hooks/FlowBuilder/useVariablesFlow';
import { templateSelected, variablesGlobal } from '@/store/FlowBuilder';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ContentConfig, FooterConfig } from '../../styled';
import { DragTransferListConfigs } from '@/components/pages/FlowBuilder/components/DragTransferListConfigs';

export const ListGlobalVariables = ({
  dataGlobals,
  handleClose,
  changeFlowConfigState,
  usedGlobalVars,
}) => {
  const { t } = useTranslation();
  const { loadingVariables } = useVariablesFlow(1);
  const [globalVars] = useAtom(variablesGlobal);
  const [, setTemplate] = useAtom(templateSelected);

  const [dataVarsSelected, setDataVarsSelected] = useState([]);

  const renderAvailableList = () => {
    const availableList = globalVars
      .map((element) => {
        const filterUsedVars = dataGlobals.filter(
          (item) => item.name === element.name
        );
        if (filterUsedVars.length > 0) {
          return null;
        }
        return element;
      })
      .filter((item) => Boolean(item));

    return availableList;
  };

  const handleUpdateGlobalVars = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        variablesGlobal: dataVarsSelected,
      };
    });
    handleClose();
    return;
  };

  return (
    <ContentConfig>
      {loadingVariables && <BackDropCircularLoading />}
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4>{t('flowBuilder.configs.globalVars.title-globalVars')}</h4>
      </Stack>
      {globalVars.length > 0 ? (
        <DragTransferListConfigs
          codeList={renderAvailableList()}
          changeDataCodeList={setDataVarsSelected}
          dataSelected={dataGlobals}
          checkRemoveData={usedGlobalVars}
          typeList={'Global'}
        />
      ) : (
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={'100%'}
          sx={{ opacity: 0.4 }}
        />
      )}
      <FooterConfig>
        <Button typeText onClick={changeFlowConfigState.toAddVar}>
          {t('flowBuilder.configs.globalVars.btn-manage-globalVars')}
        </Button>
        <Stack direction={'row'} spacing={1}>
          <Button typeText variant='secondary' onClick={handleClose}>
            {t('flowBuilder.configs.btn-cancel')}
          </Button>
          <Button typeText onClick={handleUpdateGlobalVars}>
            {t('flowBuilder.configs.btn-update')}
          </Button>
        </Stack>
      </FooterConfig>
    </ContentConfig>
  );
};
