import { apiData } from '@/services/api';

export async function createSchedulesList(dataCreate) {
  try {
    const { data } = await apiData.post(
      `dialerSchedule/createScheduleList`,
      dataCreate
    );
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
