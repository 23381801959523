import { Button } from '@/components/FormComponents/ButtonComponent';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerAddDisposition, FormAddDisposition } from './styled';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { yupResolver } from '@hookform/resolvers/yup';
import { SwitchControlled } from '@/components/FormComponents/SwitchComponent';
import { Grid } from '@mui/material';
import { Stack } from '@mui/system';
import { dataSelectedDisposition } from '@/store/DialingStore';
import { useAtom } from 'jotai';
import { useDispositionsDialing } from '@/hooks/Dispositions/useDispositionsDialing';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { successToast } from '@/components/FormComponents/Toast';

export const EditDispositions = ({ changeStage }) => {
  const { t } = useTranslation();

  const {
    mutateUpdateMailingDisposition,
    loadingUpdateMailingDisposition,
    mutateUpdateUraDisposition,
    loadingUpdateUraDisposition,
  } = useDispositionsDialing();

  const [dispositionSelected, setDispositionSelected] = useAtom(
    dataSelectedDisposition
  );

  const editDispositionSchema = yup.object().shape({
    cod_lig: yup.string().required(),
    descricao: yup.string().required(),
    finaliza_contato: yup.boolean(),
    finaliza_fone: yup.boolean(),
    contato_positivo: yup.boolean(),
    contato_efetivo: yup.boolean(),
    padrao_sim_nao: yup.boolean(),
    tela_agente_sn: yup.boolean(),
    sistemico_sn: yup.boolean(),
    condicao: yup.boolean(),
    recusa: yup.boolean(),
    transferencia: yup.boolean(),
    interrompido: yup.boolean(),
    contato_sem_interacao: yup.boolean(),
    anti_flooding: yup.boolean(),
    transferido: yup.boolean(),
    uraConnectionCode: yup.boolean(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(editDispositionSchema),
    defaultValues: {
      cod_lig: dispositionSelected.cod_lig,
      descricao: dispositionSelected.descricao,
      finaliza_contato: dispositionSelected.finaliza_contato === 1,
      finaliza_fone: dispositionSelected.finaliza_fone === 1,
      contato_positivo: dispositionSelected.contato_positivo === 1,
      contato_efetivo: dispositionSelected.contato_efetivo === 1,
      padrao_sim_nao: dispositionSelected.padrao_sim_nao === 1,
      tela_agente_sn: dispositionSelected.tela_agente_sn === 1,
      sistemico_sn: dispositionSelected.sistemico_sn === 1,
      condicao: dispositionSelected.condicao === 1,
      recusa: dispositionSelected.recusa === 1,
      transferencia: dispositionSelected.transferencia === 1,
      interrompido: dispositionSelected.interrompido === 1,
      contato_sem_interacao: dispositionSelected.contato_sem_interacao === 1,
      anti_flooding: dispositionSelected.anti_flooding === 1,
      transferido: dispositionSelected.transferido === 1,
      uraConnectionCode: dispositionSelected.origin === 'ura',
    },
  });

  const handleBack = () => {
    setDispositionSelected(null);
    reset();
    changeStage(0);
  };

  const handleEditDisposition = (data) => {
    const dataUpdate = {
      id: dispositionSelected.id,
      cod_lig: dispositionSelected.cod_lig,
      descricao: data.descricao,
      finaliza_contato: data.finaliza_contato ? 1 : '0',
      finaliza_fone: data.finaliza_fone ? 1 : '0',
      contato_positivo: data.contato_positivo ? 1 : '0',
      contato_efetivo: data.contato_efetivo ? 1 : '0',
      padrao_sim_nao: data.padrao_sim_nao ? 1 : '0',
      tela_agente_sn: data.tela_agente_sn ? 1 : '0',
      sistemico_sn: data.sistemico_sn ? 1 : '0',
      condicao: data.condicao ? 1 : '0',
      recusa: data.recusa ? 1 : '0',
      transferencia: data.transferencia ? 1 : '0',
      interrompido: data.interrompido ? 1 : '0',
      contato_sem_interacao: data.contato_sem_interacao ? 1 : '0',
      anti_flooding: data.anti_flooding ? 1 : '0',
      transferido: data.transferido ? 1 : '0',
    };

    if (dispositionSelected.origin === 'ura') {
      return mutateUpdateUraDisposition(dataUpdate, {
        onSuccess: () => {
          successToast(t('dispositions.edit.msg-success-update'));
          handleBack();
        },
      });
    }
    return mutateUpdateMailingDisposition(dataUpdate, {
      onSuccess: () => {
        successToast(t('dispositions.edit.msg-success-update'));
        handleBack();
      },
    });
  };

  useEffect(() => {
    return () => handleBack();
  }, []);

  const isLoading =
    loadingUpdateMailingDisposition || loadingUpdateUraDisposition;

  return (
    <ContainerAddDisposition initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <FormAddDisposition onSubmit={handleSubmit(handleEditDisposition)}>
        <TitleComponent
          textTitle={t('dispositions.edit.title')}
          iconTitle='tenants'
        />
        <div className='mainFormDisposition'>
          <Stack spacing={2}>
            <TitleComponent textTitle={t('dispositions.edit.title-details')} />
            <br />
            <ControlInput
              control={control}
              nameControl={'cod_lig'}
              label={t('dispositions.edit.label-disposition')}
              error={Boolean(errors.cod_lig)}
              InputProps={{
                readOnly: true,
              }}
            />
            <ControlInput
              control={control}
              nameControl={'descricao'}
              label={t('dispositions.edit.label-description')}
              error={Boolean(errors.descricao)}
            />
          </Stack>
          <br />
          <TitleComponent textTitle={t('Configs')} />

          <Grid container spacing={4} padding={'8px'}>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'uraConnectionCode'}
                activeLabel={t('dispositions.edit.label-uraConnection')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'finaliza_contato'}
                activeLabel={t('dispositions.edit.label-ends')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'finaliza_fone'}
                activeLabel={t('dispositions.edit.label-endCall')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'contato_positivo'}
                activeLabel={t('dispositions.edit.label-positive')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'contato_efetivo'}
                activeLabel={t('dispositions.edit.label-effective')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'padrao_sim_nao'}
                activeLabel={t('dispositions.edit.label-sn')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'tela_agente_sn'}
                activeLabel={t('dispositions.edit.label-agentScreenSn')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'sistemico_sn'}
                activeLabel={t('dispositions.edit.label-sysSn')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'condicao'}
                activeLabel={t('dispositions.edit.label-condition')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'recusa'}
                activeLabel={t('dispositions.edit.label-refusal')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'transferencia'}
                activeLabel={t('dispositions.edit.label-transfer')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'interrompido'}
                activeLabel={t('dispositions.edit.label-interrupted')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'contato_sem_interacao'}
                activeLabel={t('dispositions.edit.label-noInteraction')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'anti_flooding'}
                activeLabel={t('dispositions.edit.label-antFlooding')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SwitchControlled
                control={control}
                nameControl={'transferido'}
                activeLabel={t('dispositions.edit.label-transferred')}
              />
            </Grid>
          </Grid>
        </div>
        <div className='footerAddNewDisposition'>
          <Button typeText variant={'secondary'} onClick={handleBack}>
            {t('dispositions.edit.btn-cancel')}
          </Button>
          <Button typeText type='submit'>
            {t('dispositions.edit.btn-update')}
          </Button>
        </div>
      </FormAddDisposition>
    </ContainerAddDisposition>
  );
};
