import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerCampaignMailing = styled(motion.div)`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

export const MainCampaignMailing = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
`;

export const FormDetailMailing = styled.form`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 40px;

  .contentForm {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
  }
`;

export const FooterCampaignMailing = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
