import { apiData } from '@/services/api';

export async function getRoutingProfileById(dataIdUser) {
  try {
    const { data } = await apiData.get(
      `securityProfiles/listRoutingProfilesByUser/${dataIdUser}`
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
}
