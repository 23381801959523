import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { errorToast } from '@components/FormComponents/Toast';
import { authSession } from '@services/sessions/authSession';
import { isLoading, schemaScreens } from '@store/AuthenticatorStore';

import { useControlTimeAgent } from '@hooks/Agents/useControlTimeAgent';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { user } from '@/store/AuthenticatorStore';
import { useSessionStorage } from '../useSessionStorage';

export default function useAuth() {
  const { t } = useTranslation();
  const { saveLocalStorage, removeLocalStorage } = useLocalStorage();
  const { removeSessionStorage } = useSessionStorage();
  const [, setLoadingHandleForm] = useAtom(isLoading);
  const [, setScreenSchema] = useAtom(schemaScreens);
  const { removeLocalStatus } = useControlTimeAgent();
  const [, setCurrentUser] = useAtom(user);

  const navigate = useNavigate();

  const signIn = useMutation({
    mutationFn: ({ email, password }) => {
      return authSession(email, password);
    },
    onSuccess: (data) => {
      saveLocalStorage('@s', data);

      if (data?.message === 'Email or password incorrect!') {
        errorToast(t('login.messages.errorLogin'));
        setLoadingHandleForm(false);
        return;
      }

      const { user_type } = data.user;
      user_type !== 1
        ? (window.location.pathname = '/customerService')
        : navigate('/Dashboard');
    },

    onError: (error) => {
      const errorMessage = error.message || JSON.stringify(error);
      const toastMessage =
        errorMessage === 'Email or password incorrect!'
          ? t('login.messages.error-auth')
          : t('login.messages.errorLogin');
      errorToast(toastMessage);
      setLoadingHandleForm(false);
    },
  });

  const logOut = () => {
    removeLocalStorage('@s');
    removeSessionStorage('@p');
    removeLocalStatus();
    setCurrentUser(null);
    setScreenSchema(null);
    window.location.pathname = '/';
  };

  return { signIn, logOut };
}
