import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormConfig = styled(motion.form)`
  width: 100%;
  /* height: calc(100vh - 204px); */
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 1rem;

  @media (max-width: 1550px) {
    height: calc(100vh - 204px);
  }
`;

export const TableConfigContent = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  user-select: none;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;

export const FooterConfigQueue = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 08px;
`;
