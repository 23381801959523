import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

export const useLocalStorage = () => {
  const saveLocalStorage = (keyLocal, dataLocal) => {
    const dataString = JSON.stringify(dataLocal);
    const encrypt = CryptoAES.encrypt(
      dataString,
      import.meta.env.VITE_KEY_LOCAL
    );
    localStorage.setItem(keyLocal, encrypt);
  };

  const getLocalStorage = (keyLocal) => {
    const getItem = localStorage.getItem(keyLocal);

    if (!getItem) {
      return null;
    }

    const decrypt = CryptoAES.decrypt(
      getItem.toString(),
      import.meta.env.VITE_KEY_LOCAL
    );

    if (decrypt) {
      const dataDecrypt = decrypt.toString(CryptoENC);
      return JSON.parse(dataDecrypt);
    }

    return null;
  };

  const removeLocalStorage = (keyLocal) => {
    localStorage.removeItem(keyLocal);
  };

  return { saveLocalStorage, getLocalStorage, removeLocalStorage };
};
