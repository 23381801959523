import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ContainerTableActiveRecordings } from './styled';
import { DialogComponent } from '@/components/DialogComponent';
import { PlayerRecordings } from '../PlayerRecordings';
import { motion } from 'framer-motion';
import { Stack } from '@mui/system';
import { IoPlayCircleOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '@/hooks/useWidth';
import { TablePagination } from '@/components/Tables/TablePagination';
import { timeFormatEn } from '@/utils/formatsDateAndTime';
import useActiveRecordings from '@/hooks/recordings/useActiveRecordings';
import { CircularProgress } from '@mui/material';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { CopyToClipboard } from '@/components/CopyToClipboard';
import { DownloadButton } from '@/components/ButtonDownloadData/DownloadWav';

export const TableActiveRecordings = ({ dataTable }) => {
  const { t } = useTranslation();
  const { getSessionStorage } = useSessionStorage();

  const { mutateAudioConverter, loadingAudioConverter } = useActiveRecordings();

  const [dataPlayedUser, setDataPlayedUser] = useState([]);

  const { height, width } = useWindowDimensions();
  const tableActiveRecordings = useRef(null);

  const [heightTable, setHeightTable] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState([]);

  useLayoutEffect(() => {
    if (tableActiveRecordings) {
      const tableMaxWidth = tableActiveRecordings.current.offsetHeight;
      const cellMinWidth = width < 500 ? 200 : 54;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, width]);

  const renderText = (spell) =>
    t(`activeRecordings.listActiveRecords.table.${spell}`);

  const [dialogPlaySound, setDialogPlaySound] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);

  useEffect(() => {
    const hasPlayed = getSessionStorage('@p');
    if (hasPlayed) {
      setDataPlayedUser(hasPlayed);
    }
  }, [dialogPlaySound]);

  const handleOpenDialogSound = (dataRow, idSelected) => {
    setSelectedRowId((prev) => [...prev, idSelected]);
    const createNewUrl = dataRow.url_gravacao.replace(
      'http://18.229.245.129',
      'https://dialer.voiccelabs.com'
    );
    const dataConvertWave = {
      audioUrl: createNewUrl,
    };

    mutateAudioConverter(dataConvertWave, {
      onSuccess: (data) => {
        const arrayBuffer = data;
        const audioUrl = URL.createObjectURL(new Blob([arrayBuffer]));
        setDataSelected({
          ...dataRow,
          url_gravacao: createNewUrl,
          blobWave: audioUrl,
          idRow: idSelected,
        });
        setDialogPlaySound(true);
        setSelectedRowId([]);
      },
      onError: () => setSelectedRowId([]),
    });
  };

  const handleCloseDialogSound = () => {
    setDialogPlaySound(false);
    setDataSelected(null);
  };

  const checkHasPlayedRecording = (fone = '', serviceTime = '') => {
    const idPlayed = `${fone}${serviceTime}`;
    if (dataPlayedUser.includes(idPlayed)) {
      return true;
    }
    return false;
  };

  const columnsActiveRecordings = [
    {
      Header: renderText('th-dispositions'),
      accessor: 'cod_lig',
      Cell: ({ row }) =>
        `${row.original.cod_lig} - ${row.original.desc_cod_lig}`,
    },

    {
      Header: renderText('th-hour'),
      accessor: 'atende_dt',
      Cell: ({ row }) => timeFormatEn(row.original.atende_dt),
    },
    {
      Header: renderText('th-list'),
      accessor: 'desc_lista',
      Cell: ({ row }) =>
        `${row.original.id_tabela_lista} - ${row.original.desc_lista}`,
    },

    {
      Header: renderText('th-phone'),
      accessor: 'fone',
      Cell: ({ row }) => {
        return (
          <Stack
            direction={'row'}
            spacing={2}
            width={'100%'}
            justifyContent={'flex-start'}
          >
            {row.original.ddd} {row.original.fone}
            <CopyToClipboard textToCopy={row.original.fone} />
          </Stack>
        );
      },
    },
    {
      Header: renderText('th-name'),
      accessor: 'nome_cliente',
      Cell: ({ row }) => {
        return (
          <Stack
            direction={'row'}
            spacing={2}
            width={'100%'}
            justifyContent={'flex-start'}
          >
            {row.original.nome_cliente}
            <CopyToClipboard textToCopy={row.original.nome_cliente} />
          </Stack>
        );
      },
    },
    {
      Header: 'CPF',
      accessor: 'cpf',
      Cell: ({ row }) => {
        return (
          <Stack
            direction={'row'}
            spacing={2}
            width={'100%'}
            justifyContent={'flex-start'}
          >
            {row.original.cpf}
            <CopyToClipboard textToCopy={row.original.cpf} />
          </Stack>
        );
      },
    },
    {
      Header: renderText('th-duration'),
      accessor: 'tempo',
    },
    {
      Header: renderText('th-records'),
      accessor: 'recording',
      Cell: ({ row }) => {
        const isLoading = selectedRowId.includes(row.index);
        return (
          <Stack direction={'row'} spacing={'4px'}>
            <motion.button
              whileTap={{ scale: 0.98 }}
              onClick={() => handleOpenDialogSound(row.original, row.index)}
              className={
                checkHasPlayedRecording(
                  row.original.fone,
                  row.original.atende_dt
                )
                  ? 'hasPlayedSound'
                  : ''
              }
            >
              {isLoading ? (
                <CircularProgress size={15} />
              ) : (
                <IoPlayCircleOutline size={15} />
              )}
            </motion.button>
            <DownloadButton
              dataDownload={row.original}
              disabledBtn={loadingAudioConverter || isLoading}
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <ContainerTableActiveRecordings
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      ref={tableActiveRecordings}
    >
      <TablePagination
        dataColumns={columnsActiveRecordings}
        dataTable={dataTable}
        textNoData={renderText('no-data-table')}
        rowsPerPageDisplay={heightTable}
      />
      <DialogComponent open={dialogPlaySound} onClose={handleCloseDialogSound}>
        <PlayerRecordings
          dataSelected={dataSelected}
          setDataSelected={setDataSelected}
          onClose={handleCloseDialogSound}
        />
      </DialogComponent>
    </ContainerTableActiveRecordings>
  );
};
