import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import styled, { css } from 'styled-components';

export const ContentAddCondition = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.6rem;

  .content__use_vars {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h4 {
    font-size: 0.7rem;
    font-weight: 400;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  button {
    font-size: 0.7rem;
    padding: 2px 4px;
  }

  .input__value_var {
    height: 45px;
    input {
      min-height: 25px;
    }
  }
`;

export const ConditionItem = styled(ItemSelect)`
  gap: 4px;
  span {
    font-size: 0.8ren;
    opacity: 0.5;
  }
`;

export const LabelSwitch = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.typography.baseText};
  line-height: 0;

  ${(props) =>
    props.typeValueVar
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0.4;
        `}
`;
