import styled from 'styled-components';
import { motion } from 'framer-motion';

import BgImgLogin from '../../../assets/imgs/bg-voicce.webp';

export const ContentSectionLeft = styled(motion.div)`
  background-image: url(${BgImgLogin});
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;
  img {
    position: absolute;
    left: 2%;
    bottom: 4%;
    /* width: 100%;
    max-width: 520px; */
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
