import { apiData } from '@/services/api';

export async function getCallOperators(signal = false) {
  try {
    const { data } = await apiData.get('dialer/listOperators', {
      signal,
    });
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
