import { CurrentNewFlow } from './stages/CurrentNewFlow';
import { LatestFlow } from './stages/LatestFlow';
import { ContainerMainHomeFlow } from './styled';

export const MainHomeFlow = ({
  selectedItem,
  changeStageFlow,
  changeStageTypeSelectFlow,
}) => {
  const flowComponents = [
    {
      name: 'recentFlow',
      component: (
        <LatestFlow
          changeStageFlow={changeStageFlow}
          changeStageTypeSelectFlow={changeStageTypeSelectFlow}
        />
      ),
      show: true,
    },
    {
      name: 'newFlow',
      component: (
        <CurrentNewFlow
          changeStageFlow={changeStageFlow}
          changeStageTypeSelectFlow={changeStageTypeSelectFlow}
        />
      ),
      show: true,
    },
  ];

  const renderMainFlow = flowComponents
    .filter((item) => item.name === selectedItem && item.show)
    .map((item) => item.component)[0];

  return (
    <ContainerMainHomeFlow
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
    >
      {renderMainFlow}
    </ContainerMainHomeFlow>
  );
};
