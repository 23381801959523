import { useEffect } from 'react';
import * as yup from 'yup';
import { useAtom } from 'jotai';
import { motion } from 'framer-motion';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { dataMailing, dataTemplateMailing } from '@/store/LoadList';
import { dataCampaignsByClient } from '@/store/Campaigns';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useCampaignByClient } from '@/hooks/dialControl/Campaigns/useCampaignByClient';
import { ControlAutoComplete } from '@/components/FormComponents/AutoCompleteSelect';
import useFormControl from '@/hooks/useFormControl';
import { SwitchControlled } from '@/components/FormComponents/SwitchComponent';
import { useDDDManager } from '@/hooks/dialControl/DDDManager';
import { dataDDDManager, dataUFManager } from '@/store/DialingStore';
import { useUFManager } from '@/hooks/dialControl/UFManager/useUfManager';
import { useRenitency } from '@/hooks/dialControl/Renitency/useRenitency';
import { dataListRenitency } from '@/store/Renitency';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { usePermissionsLoadList } from '../../hooks/usePermissionsLoadList';
import { useStageControlLists } from '../../hooks/useStageControlLists';
import {
  ContainerCampaignMailing,
  FooterCampaignMailing,
  FormDetailMailing,
  MainCampaignMailing,
} from './styled';

export const CampaignSelect = () => {
  const { t } = useTranslation();
  const { permissionsCreate } = usePermissionsLoadList();
  const { nextProgress, setCurrentStateMailing } = useStageControlLists();
  const { loadingDataCampaignsByClient } = useCampaignByClient();
  const { loadingDataDDDs, fetchingDataDDDs } = useDDDManager();
  const { loadingDataUFs, fetchingUFs } = useUFManager();
  const { loadingDataRenitency, fetchingRenitency } = useRenitency();
  const [, setMailingData] = useAtom(dataMailing);
  const [, setDataTemplates] = useAtom(dataTemplateMailing);

  useEffect(() => {
    setDataTemplates(null);
  }, []);

  const addDetailMailingSchema = yup.object().shape({
    campaign: yup.object().required(),
    description: yup
      .string()
      .matches(
        /^[a-zA-Z0-9_\s-]*$/,
        'A descrição só pode conter letras, números, espaços, _ e -'
      ),
    dddManager: yup.boolean(),
    groupDDD: yup.object().when('dddManager', {
      is: true,
      then: (schema) =>
        schema.required('Group DDD is required when DDD Manager is true'),
      otherwise: (schema) => schema.nullable(),
    }),
    ufManager: yup.boolean(),
    groupUF: yup.object().when('ufManager', {
      is: true,
      then: (schema) =>
        schema.required('Group UF is required when UF Manager is true'),
      otherwise: (schema) => schema.nullable(),
    }),
    renitencyManager: yup.boolean(),
    groupRenitency: yup.object().when('renitencyManager', {
      is: true,
      then: (schema) =>
        schema.required(
          'Group Renitency is required when renitency manager is true'
        ),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const { handleSubmit, control, errors, watch } = useFormControl(
    addDetailMailingSchema
  );

  const dataCheckForm = watch();

  const [dataCampaigns] = useAtom(dataCampaignsByClient);
  const [listDDDManagers] = useAtom(dataDDDManager);
  const [listUFManager] = useAtom(dataUFManager);
  const [listRenitency] = useAtom(dataListRenitency);

  const handleNextStage = (data) => {
    const timeToNameFile = new Date().getTime();

    const dataDetailMailing = {
      campaign: data.campaign.value,
      description:
        data.description !== '' ? data.description : 'no description',
      dddGroup: data.groupDDD?.value ?? null,
      ufGroup: data.groupUF?.value ?? null,
      renitencyGroup: data.groupRenitency?.value ?? null,
      nameFileCsv: `${timeToNameFile}-${data.campaign.value}`,
    };

    setMailingData(dataDetailMailing);
    setCurrentStateMailing(1);
    nextProgress();
  };

  const renderOptionsCampaigns = dataCampaigns?.map((item) => {
    return {
      label: item.campanha,
      value: item.campanha,
    };
  });

  const renderOptionsDDD = listDDDManagers?.map((item) => {
    return {
      label: `${item.cod_ddd_bloq} - ${item.descricao}`,
      value: item.cod_ddd_bloq,
    };
  });

  const renderOptionsUF = listUFManager?.map((item) => {
    return {
      label: `${item.cod_uf_bloq} - ${item.descricao}`,
      value: item.cod_uf_bloq,
    };
  });

  const renderOptionsRenitency = listRenitency?.map((item) => {
    return {
      label: `${item.id_regra_renitencia} - ${item.descricao}`,
      value: item.id_regra_renitencia,
    };
  });

  const isLoading =
    loadingDataDDDs ||
    fetchingDataDDDs ||
    loadingDataCampaignsByClient ||
    loadingDataUFs ||
    fetchingUFs ||
    loadingDataRenitency ||
    fetchingRenitency;

  return (
    <ContainerCampaignMailing initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <MainCampaignMailing>
        <DescriptionInformation
          textDescription={t('listLoader.campaignSelect.informationStage')}
        />
        <FormDetailMailing onSubmit={handleSubmit(handleNextStage)}>
          <div className='contentForm'>
            <ControlAutoComplete
              control={control}
              nameControl={'campaign'}
              label={t('listLoader.campaignSelect.label-campaign')}
              optionsList={renderOptionsCampaigns}
              errorAutoComplete={Boolean(errors.campaign)}
            />

            <ControlInput
              control={control}
              nameControl={'description'}
              label={t('listLoader.campaignSelect.label-description')}
              error={Boolean(errors.description)}
            />

            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              sx={{ paddingLeft: '6px' }}
            >
              <SwitchControlled
                control={control}
                nameControl={'dddManager'}
                activeLabel={t('listLoader.campaignSelect.label-dddManager')}
              />
              <SwitchControlled
                control={control}
                nameControl={'ufManager'}
                activeLabel={t('listLoader.campaignSelect.label-ufManager')}
              />
              <SwitchControlled
                control={control}
                nameControl={'renitencyManager'}
                activeLabel={t(
                  'listLoader.campaignSelect.label-renitencyManager'
                )}
              />
            </Stack>

            {dataCheckForm.dddManager && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <ControlAutoComplete
                  control={control}
                  nameControl={'groupDDD'}
                  label={t('listLoader.campaignSelect.label-dddGroup')}
                  optionsList={renderOptionsDDD}
                  errorAutoComplete={Boolean(errors.groupDDD)}
                />
              </motion.div>
            )}

            {dataCheckForm.ufManager && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <ControlAutoComplete
                  control={control}
                  nameControl={'groupUF'}
                  label={t('listLoader.campaignSelect.label-ufGroup')}
                  optionsList={renderOptionsUF}
                  errorAutoComplete={Boolean(errors.groupUF)}
                />
              </motion.div>
            )}

            {dataCheckForm.renitencyManager && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <ControlAutoComplete
                  control={control}
                  nameControl={'groupRenitency'}
                  label={t('listLoader.campaignSelect.label-renitencyGroup')}
                  optionsList={renderOptionsRenitency}
                  errorAutoComplete={Boolean(errors.groupRenitency)}
                />
              </motion.div>
            )}
          </div>
          <FooterCampaignMailing>
            <Button typeText type='submit' disabled={!permissionsCreate}>
              {t('listLoader.footer.btn-next')}
            </Button>
          </FooterCampaignMailing>
        </FormDetailMailing>
      </MainCampaignMailing>
    </ContainerCampaignMailing>
  );
};
