import { useId, useState } from 'react';
import { HomeConfigNlu } from './HomeConfigNlu';
import { ManageEndpoints } from '@/components/pages/FlowBuilder/stages/Configs/Endpoints/ManageEndpoints';

export const ConfigNLU = ({ changeStateFlow }) => {
  const [stageConfigNLU, setStageConfigNLU] = useState(0);

  const controlStageNewFlow = {
    handleBackStage: () => changeStateFlow(2),
    handleNextStage: () => changeStateFlow(4),
  };

  const controlStageConfigNlus = {
    toHomeNlus: () => setStageConfigNLU(0),
    toManagementNlus: () => setStageConfigNLU(1),
  };

  const flowConfigNLU = [
    <HomeConfigNlu
      key={useId()}
      changeStateFlow={controlStageNewFlow}
      controlStageConfigNlus={controlStageConfigNlus.toManagementNlus}
    />,
    <ManageEndpoints
      key={useId()}
      controlStage={controlStageConfigNlus.toHomeNlus}
    />,
  ];

  return flowConfigNLU[stageConfigNLU];
};
