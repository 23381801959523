import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerManagementEndpoints = styled(motion.div)`
  width: 100%;
  height: 74.5vh;
  max-height: 1080px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 1492px) {
    height: 72vh;
  }

  .titleConfig {
    h4 {
      font-size: 0.85rem;
      text-transform: uppercase;
      font-weight: 400;
      color: ${(props) => props.theme.colors.typography.baseText}AA;
      padding-top: 3px;
    }
    svg {
      font-size: 0.85rem;
      color: ${(props) => props.theme.colors.typography.baseTitle};
    }
  }
`;

export const ContentManagementEndpoints = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(1px, auto) 1fr;
  gap: 2rem;
  padding-top: 10px;
  overflow: hidden;

  .divider {
    width: 100%;
    background-color: #e8e8e8;
  }
`;

export const FormAddEndpoint = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  overflow: hidden;
  padding-top: 0.4rem;
`;

export const PanelIntents = styled(motion.div)`
  display: flex;
  flex-direction: row;

  width: 100%;
  gap: 0.6rem;

  button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 40px;
    background-color: transparent;

    svg {
      font-size: 1.1rem;
      color: ${(props) => props.theme.colors.typography.baseText}AA;
    }
  }

  .panel-btn-control-intent {
    button {
      background-color: ${(props) => props.theme.colors.typography.baseText};
      padding: 0 !important;
      box-shadow: none;
      min-width: 26px;

      svg {
        font-size: 0.8rem;
        color: ${(props) => props.theme.colors.defaultColors.white};
        transform: rotate(90deg);
      }
    }
  }
`;

export const ContainerFormIntents = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  ul {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    padding-right: 2px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${(props) =>
        props.theme.colors.containers.baseContainer};
      border-radius: 3px;
      padding: 8px;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.4rem;
        svg {
          font-size: 0.5rem;
          color: ${(props) => props.theme.colors.typography.baseText};
        }
        span {
          font-size: 0.85rem;
          padding-top: 3px;
          color: ${(props) => props.theme.colors.typography.baseText};
        }
      }

      button {
        all: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: ${(props) => props.theme.colors.typography.baseText};
        }
      }
    }
  }
`;

export const ContentIntents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  overflow: auto;
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    padding-right: 4px;
  }
`;

export const ItemIntentStyled = styled.div`
  width: 100%;
  padding: 8px;
  transition: all 0.1s ease-in-out;
  background-color: ${(props) => props.theme.colors.containers.baseContainer};
  border-radius: 3px;
  border: ${(props) =>
    props.selected
      ? `1px solid ${props.theme.colors.defaultColors.black}`
      : `1px solid transparent`};

  p {
    strong {
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }

  svg {
    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;

export const TreeUl = styled.ul`
  list-style: none;
`;

export const TreeLi = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.colors.typography.baseText};
  padding-left: 6px;
  span {
    padding-top: 2px;
    font-size: 0.78rem;
  }

  svg {
    font-size: 0.55rem;
    color: ${(props) => props.theme.colors.defaultColors.blue};
  }
`;
