import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { getQueuesCampaigns } from '@/services/dialControl/campaigns/getQueuesCampaigns';
import { dataQueues, dataServersControl } from '@/store/Queues';
import { createQueue } from '@/services/dialControl/queues/createQueue';
import { updateQueue } from '@/services/dialControl/queues/updateQueue';

export const useQueues = (stageCampaign = 0) => {
  const { t } = useTranslation();
  const [, setListQueues] = useAtom(dataQueues);
  const [serversControl] = useAtom(dataServersControl);

  const { isLoading: loadingDataQueues, refetch: refetchQueues } = useQuery(
    'getQueuesCampaigns',
    async () => {
      return await getQueuesCampaigns({
        first_queue: serversControl.first_queue,
        last_queue: serversControl.last_queue,
        isActive: true,
      });
    },
    {
      enabled: !!serversControl && stageCampaign === 0,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => setListQueues(data),

      onError: () => {
        errorToast(t('dialing.newListConfig.messages.errorRequesStatus'));
      },
    }
  );

  const { mutate: mutateCreateQueue, isLoading: loadingCreateQueue } =
    useMutation({
      mutationFn: async (dataCreateQueue) => {
        return await createQueue(dataCreateQueue);
      },
      onError: () => {
        errorToast(
          t('dialControl.queues.newQueue.messages.error-create-queue')
        );
      },
    });

  const { mutate: mutateUpdateQueue, isLoading: loadingUpdateQueue } =
    useMutation({
      mutationFn: async (dataUpdateQueues) => {
        return await updateQueue(dataUpdateQueues);
      },
      onError: () => {
        errorToast(
          t('dialControl.queues.newQueue.messages.error-updated-queue')
        );
      },
    });

  return {
    refetchQueues,
    loadingDataQueues,
    mutateCreateQueue,
    loadingCreateQueue,
    mutateUpdateQueue,
    loadingUpdateQueue,
  };
};
