import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import { SubNodeRecVozContainer } from './styled';
import { useTranslation } from 'react-i18next';

const ItemRecVoicceNodeComponent = ({ data }) => {
  const { t } = useTranslation();

  return (
    <SubNodeRecVozContainer>
      <div className='headerNode'>
        <span>
          {data.label === 'transcription'
            ? t('flowBuilder.flow.nodes.recVoz.label-transcribe')
            : data.label}
        </span>
      </div>
      <Handle type='source' position={Position.Right} />
    </SubNodeRecVozContainer>
  );
};

export const ItemRecVoicceNode = memo(ItemRecVoicceNodeComponent);
