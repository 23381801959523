import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import { SubNodeDtmfContainer } from './styled';
import { useTranslation } from 'react-i18next';

const itemDtmfNodeComponent = ({ data }) => {
  const { t } = useTranslation();

  if (data.label === 'timeout') {
    return (
      <SubNodeDtmfContainer typeHandler={'timeOutCondition'}>
        <div className='headerNode'>
          <span>{t('flowBuilder.flow.nodes.recVoz.label-timeOutNode')}</span>
        </div>
        <Handle type='source' position={Position.Right} />
      </SubNodeDtmfContainer>
    );
  }

  return (
    <SubNodeDtmfContainer typeHandler={data.handler}>
      <div className='headerNode'>
        <span>
          {t('flowBuilder.flow.nodes.subNode.key')}: {data.value}
        </span>
      </div>
      <Handle type='source' position={Position.Right} />
    </SubNodeDtmfContainer>
  );
};

export const ItemDtmfNode = memo(itemDtmfNodeComponent);
