import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerQueueCampaign = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 60px);
  padding: 3rem 2rem;
  gap: 2rem;
  display: grid;
  grid-template-rows: 80px 1fr;
`;

export const PanelQueueCampaign = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
