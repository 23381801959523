import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { PiArrowBendDownRightBold } from 'react-icons/pi';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { checkLabelExistiNode } from '@/components/pages/FlowBuilder/utils/checkNameNodes';
import { errorToast } from '@/components/FormComponents/Toast';
import { TitleMenuNode } from '../TitleMenuNode';
import { FooterNode } from '@/components/pages/FlowBuilder/components/FooterNode';
import { renderPositionSubNode } from '@/components/pages/FlowBuilder/utils/renderPositionNodes';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import { FormMenuNode, MainAddNode } from './styled';
import {
  focusedFieldArea,
  isSavedFlow,
  selectedNode,
  showMenuNodes,
  templateSelected,
  variablesList,
} from '@/store/FlowBuilder';

export const RecVoz = ({ dataNodes }) => {
  const { t } = useTranslation();
  const [template] = useAtom(templateSelected);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [selected, setSelected] = useAtom(selectedNode);
  const [, setIsSaved] = useAtom(isSavedFlow);
  const [, setFocusedArea] = useAtom(focusedFieldArea);
  const [listVariable, setListVariables] = useAtom(variablesList);
  const [labelNode, setLabelNode] = useState('');
  const [labelNodeError, setLabelNodeError] = useState('');
  const [validInputSelect, setValidInputSelect] = useState('');
  const [timeOut, setTimeOut] = useState(0);
  const [recap, setRecap] = useState(0);
  const [allowSkipNode, setAllowSkipNodeNode] = useState('Yes');
  const [listRenderItensRecVoz, setRenderItensRecVoz] = useState([]);
  const { nlus } = template;

  const filterOptionsValidInputs = nlus?.map((item) => item.type) ?? [];

  const { nodes, setNodes, edges, setEdges } = dataNodes;
  const { id, data } = selected;

  const validateLabelNode = (value) => {
    if (!value.trim()) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.recVoz.menu.msg-name-required')
      );
    } else if (value.length > 50) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.recVoz.menu.msg-name-length', {
          length: '50',
        })
      );
    } else if (!/^[\w\d_]*$/.test(value)) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.recVoz.menu.msg-name-letters')
      );
    } else {
      setLabelNodeError('');
    }
    setLabelNode(value);
  };

  useEffect(() => {
    if (selected) {
      setLabelNode(data.label);
      setAllowSkipNodeNode(data.allowSkip ? 'Yes' : 'No');
      setTimeOut(data.timeout);
      setValidInputSelect(data.nluEndpoint);
      setRecap(data.qtdRepesca);
    }
  }, [selected]);

  const closeMenu = () => {
    setShowMenu(false);
    setLabelNode('');
    setValidInputSelect('');
    setTimeOut(0);
    setAllowSkipNodeNode('Yes');
    setSelected(null);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const renderListValidInputs = () => {
    const filterListValidInputs = nlus?.filter(
      (item) => item.type === validInputSelect && item.data
    );
    if (filterListValidInputs.length === 0) {
      return [];
    }

    const listValidInputs = filterListValidInputs[0].data;
    const hasItemTimeOut = listValidInputs.includes('timeout');
    const hasItemError = listValidInputs.includes('error');

    !hasItemTimeOut && listValidInputs.push('timeout');
    !hasItemError && listValidInputs.push('error');

    return listValidInputs;
  };

  useEffect(() => {
    setRenderItensRecVoz(renderListValidInputs());
  }, [validInputSelect]);

  const renderNodeListValidInputs = () => {
    const filterListValidInputs = nlus?.filter(
      (item) => item.type === validInputSelect && item.data
    );

    if (filterListValidInputs.length === 0) {
      return [];
    }

    return filterListValidInputs[0].data;
  };

  const compareLists = (arr1, arr2) => {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    if (set1.size !== set2.size) {
      return false;
    }
    for (let item of set1) {
      if (!set2.has(item)) {
        return false;
      }
    }
    return true;
  };

  const checkEdgesOnChangeEndpoint = () => {
    if (validInputSelect !== '') {
      const subNodeSelected = nodes
        .filter(
          (node) =>
            node.parentNode === id && node.data.handler !== 'errorCondition'
        )
        .map((item) => item.data.label);

      const subNodeChanged =
        nlus
          ?.filter((item) => item.type === validInputSelect)
          .map((item) => item.data)[0] || [];

      const filterErrorNodes = subNodeChanged.filter(
        (item) => !['timeout', 'error'].includes(item)
      );

      const hasEqualsList = compareLists(filterErrorNodes, subNodeSelected);

      if (!hasEqualsList) {
        return false;
      }
      return true;
    }
  };

  const handleAddToVarsList = (labelNode) => {
    const newVarUtterance = {
      id: uuid(),
      name: `$${labelNode}_utterance`,
      value: `$${labelNode}_utterance`,
      parent: '',
      referNode: false,
    };

    const newVarSlot = {
      id: uuid(),
      name: `$${labelNode}_slot`,
      value: `$${labelNode}_slot`,
      parent: '',
      referNode: false,
    };

    const hasExistUtterance = listVariable.filter(
      (item) => item.name === newVarUtterance.name
    ).length;

    const hasExistSlot = listVariable.filter(
      (item) => item.name === newVarSlot.name
    ).length;

    if (hasExistUtterance === 0) {
      setListVariables((previousState) => [...previousState, newVarUtterance]);
    }

    if (hasExistSlot === 0) {
      setListVariables((previousState) => [...previousState, newVarSlot]);
    }
  };

  const renderEdgesRecVoz = (oldNlu, newNlu) => {
    if (oldNlu === '' || oldNlu === newNlu) {
      return;
    }
    const filteredSubNode = nodes
      .filter((node) => node.parentNode === id)
      .map((node) => node.id);

    const updateEdges = edges
      .map((edge) => {
        const hasEdge = filteredSubNode.includes(edge.source);
        if (hasEdge) {
          return null;
        }
        return edge;
      })
      .filter((edge) => edge);

    setEdges(updateEdges);
    return;
  };

  const handleSubmitNewNode = (e) => {
    e.preventDefault();
    const checkLabel = checkLabelExistiNode(id, labelNode, nodes);
    if (checkLabel) {
      return errorToast(t('flowBuilder.flow.nodes.recVoz.menu.msg-name-used'));
    }
    const parentsNodeSelected = nodes.filter((node) => node.parentNode !== id);
    const filteredNode = parentsNodeSelected.map((node) =>
      node.id === id
        ? {
            ...node,
            data: {
              ...node.data,
              label: labelNode,
              timeout: Number(timeOut),
              allowSkip: allowSkipNode === 'Yes' && true,
              nluEndpoint: validInputSelect,
              qtdRepesca: recap,
              validInput: renderNodeListValidInputs(),
              errorConnect: false,
            },
          }
        : node
    );
    handleAddToVarsList(labelNode);
    setNodes(filteredNode);
    const subNodeData = nodes.filter(
      (node) => node.parentNode === id && node.data.handler !== 'errorCondition'
    );
    const renderIdSubNodes = Math.floor(Math.random() * 1000) + 1;
    if (listRenderItensRecVoz.length > 0) {
      const listSub = listRenderItensRecVoz.map((item, i) => {
        const checkSubNodesExisting = subNodeData.filter(
          (itemVerify) => itemVerify.data.label === item
        );

        const subNode = {
          id:
            checkSubNodesExisting.length > 0 && checkEdgesOnChangeEndpoint()
              ? checkSubNodesExisting[0].id
              : `sub_id_${id}_${
                  i > 0 ? renderIdSubNodes * i + 1 : renderIdSubNodes * 2
                }`,
          type: 'itemRecVoicceNode',
          data: {
            label: item,
          },
          position: renderPositionSubNode(i, 10, 140),
          parentNode: id,
          extent: 'parent',
          className: 'nodrag',
          selectable: false,
        };

        const subTimeOut = {
          id: `sub_id_${id}_${'timeOut'}`,
          type: 'subNode',
          data: {
            label: 'timeout',
            handler: 'errorCondition',
          },
          position: renderPositionSubNode(i, 10, 140),
          parentNode: id,
          extent: 'parent',
          className: 'nodrag',
          selectable: false,
        };

        const subError = {
          id: `sub_id_${id}_${'error'}`,
          type: 'subNode',
          data: {
            label: 'nextError',
            handler: 'errorCondition',
          },
          position: renderPositionSubNode(i, 10, 140),
          parentNode: id,
          extent: 'parent',
          className: 'nodrag',
          selectable: false,
        };
        if (item === 'timeout') {
          return subTimeOut;
        }

        if (item === 'error') {
          return subError;
        }
        return subNode;
      });

      listSub.map((item) => dataNodes.setNodes((nds) => nds.concat(item)));
    }
    renderEdgesRecVoz(data.nluEndpoint, validInputSelect);
    setIsSaved(false);
    closeMenu();
  };

  const filterValidInputs = listRenderItensRecVoz.filter(
    (item) => item !== 'error' && item !== 'timeout'
  );

  return (
    <FormMenuNode
      onClick={handleChildClick}
      onSubmit={handleSubmitNewNode}
      id='formRecVoz'
      name='formRecVoz'
    >
      <MainAddNode>
        <TitleMenuNode
          title={t('flowBuilder.flow.nodes.recVoz.menu.title')}
          description={t('flowBuilder.flow.nodes.recVoz.description')}
        />

        <InputComponent
          label={t('flowBuilder.flow.nodes.recVoz.menu.label-name')}
          value={labelNode}
          onChange={(e) => validateLabelNode(e.target.value)}
          inputProps={{ maxLength: 51 }}
          helperText={labelNodeError}
          error={Boolean(labelNodeError)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />

        <SelectComponent
          defaultValue='0'
          labelSelect={t('flowBuilder.flow.nodes.recVoz.menu.label-endpoint')}
          value={validInputSelect}
          onChange={(e) => setValidInputSelect(e.target.value)}
        >
          <ItemSelect value={'0'} disabled>
            {t('flowBuilder.flow.nodes.recVoz.menu.option-endpoint')}
          </ItemSelect>
          {filterOptionsValidInputs.map((item, i) => {
            if (item === 'Transcribe') {
              return (
                <ItemSelect value={item} key={i}>
                  {t('flowBuilder.flow.nodes.recVoz.label-transcribe')}
                </ItemSelect>
              );
            }
            return (
              <ItemSelect value={item} key={i}>
                {item}
              </ItemSelect>
            );
          })}
        </SelectComponent>

        <InputComponent
          label={t('flowBuilder.flow.nodes.recVoz.menu.label-timeOut')}
          type='number'
          value={timeOut}
          onChange={(e) => setTimeOut(e.target.value)}
          InputProps={{ inputProps: { min: 0, max: 100 } }}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />
        <InputComponent
          label={t('flowBuilder.flow.nodes.recVoz.menu.label-attempts')}
          type='number'
          value={recap}
          onChange={(e) => setRecap(e.target.value)}
          InputProps={{ inputProps: { min: 0, max: 10 } }}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />

        <div className='itens_valid_input'>
          {listRenderItensRecVoz.length > 1 &&
            filterValidInputs.map((item, i) => {
              return (
                <div className='item-rec-list' key={i}>
                  <PiArrowBendDownRightBold />
                  <span>
                    {item === 'transcription'
                      ? t('flowBuilder.flow.nodes.recVoz.label-transcribe')
                      : item}
                  </span>
                </div>
              );
            })}
        </div>
      </MainAddNode>

      <FooterNode
        closeMenu={closeMenu}
        disabledSubmitBtn={
          Boolean(labelNodeError) ||
          labelNode === '' ||
          validInputSelect === '0' ||
          validInputSelect === ''
        }
      />
    </FormMenuNode>
  );
};
