import { useEffect, useState } from 'react';
import { ChartsDash } from './ChartsDash';
import { StatusQueue } from './StatusQueues';
import { ContainerDashboardPage } from './styled';
import { useDashboardPage } from '@/hooks/DashboardPage/useDashboardPage';
import { CircularAbsoluteLoading } from '@/components/layout/LoadingComponent';
import { useSecurityProfileRender } from '@/hooks/Navigator/useSecurityProfileRender';
import { SkeletonLoadDash } from './SkeletonLoadDash';
import { NoDataCharts } from './ChartsDash/NoDataCharts';
import { errorToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';

const DashboardPage = () => {
  const { t } = useTranslation();
  const { permissionComponent } = useSecurityProfileRender();
  const {
    isLoadingQueuesCampaign,

    mutateMailingAnalysis,
    loadingMailingAnalysis,

    mutateFunnelAttendance,
    loadingFunnelAttendance,

    mutateServicesPerHour,
    loadingServicesPerHour,
    errorGetLists,
  } = useDashboardPage();

  const [queueSelect, setQueueSelect] = useState([]);

  useEffect(() => {
    if (errorGetLists) {
      errorToast(t('dashboardPage.messages.errorFetchData'));
      setQueueSelect([]);
    }
  }, [errorGetLists]);

  const handleSearchQueue = (listQueue) => {
    setQueueSelect([listQueue]);
    mutateFunnelAttendance(listQueue);
    mutateMailingAnalysis(listQueue);
    mutateServicesPerHour(listQueue);
  };

  const permissionsComponent =
    permissionComponent('create') || permissionComponent('update');

  const isLoading =
    isLoadingQueuesCampaign ||
    loadingMailingAnalysis ||
    loadingFunnelAttendance ||
    loadingServicesPerHour;

  return (
    <ContainerDashboardPage initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <CircularAbsoluteLoading />}
      {permissionsComponent && (
        <StatusQueue
          handleSearchQueue={handleSearchQueue}
          loadingDashboardPage={isLoading}
        />
      )}
      {isLoading || queueSelect.length === 0 ? (
        <>
          {queueSelect.length === 0 && <NoDataCharts />}
          <SkeletonLoadDash />
        </>
      ) : (
        <ChartsDash selectedQueue={queueSelect} />
      )}
    </ContainerDashboardPage>
  );
};

export { DashboardPage };
