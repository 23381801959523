import { useMutation, useQuery } from 'react-query';
import { useAtom } from 'jotai';
import { errorToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';

import {
  dataMatchTenants,
  dataTenants,
  stageTenants,
} from '@/store/TenantsStore';
import { updateServerControl } from '@/services/dialControl/serversControl/updateServerControl';
import { getTenants } from '@/services/dialControl/serversControl/getTenants';
import { getMatchTenants } from '@/services/dialControl/serversControl/getMatchTenants';
import { createMatchTenant } from '@/services/dialControl/serversControl/createMatchTenant';
import { deleteMatchTenant } from '@/services/dialControl/serversControl/deleteMatchTenant';
import { createTenant } from '@/services/dialControl/serversControl/createTenant';
import { deleteServerControl } from '@/services/dialControl/serversControl/deleteTenant';

export const useTenants = () => {
  const { t } = useTranslation();
  const [, setDataTenants] = useAtom(dataTenants);
  const [, setDataMatchTenants] = useAtom(dataMatchTenants);
  const [stageTenantsControl] = useAtom(stageTenants);

  const { isLoading: loadingTenants, refetch: refetchTenants } = useQuery(
    'dataListTenants',
    async () => {
      return await getTenants();
    },
    {
      retry: 0,
      enabled: stageTenantsControl === 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setDataTenants(data);
      },
      onError: (error) => {
        const errorMessage = error.message || JSON.stringify(error);
        const toastMessage =
          errorMessage === 'User is not SysAdmin!'
            ? null
            : errorToast(t('tenants.home.mgs-error-tenant'));
        return toastMessage;
      },
    }
  );

  const { mutate: mutateEditTenant, isLoading: loadingEditTenant } =
    useMutation({
      mutationFn: async (dataEditTenant) => {
        return await updateServerControl(dataEditTenant);
      },
      onSuccess: () => {
        refetchTenants();
      },
      onError: () => {
        errorToast(t('tenants.edit.mgs-error-edit-tenant'));
      },
    });

  const { mutate: mutateCreateTenant, isLoading: loadingCreateTenant } =
    useMutation({
      mutationFn: async (dataCreate) => {
        return await createTenant(dataCreate);
      },
      onSuccess: () => {
        refetchTenants();
      },
      onError: (error) => {
        const errorMessage = error.message || JSON.stringify(error);
        const toastMessage =
          errorMessage === 'Server control already exists!'
            ? t('tenants.add.msg-error-alreadyExists')
            : t('tenants.add.mgs-error-add-tenant');
        return errorToast(toastMessage);
      },
    });

  const { mutate: mutateDeleteTenant, isLoading: loadingDeleteTenant } =
    useMutation({
      mutationFn: async (dataDelete) => {
        return await deleteServerControl(dataDelete);
      },
      onSuccess: () => {
        refetchTenants();
      },
      onError: () => {
        return errorToast(t('tenants.home.table.mgs-error-remove-tenant'));
      },
    });

  const { mutate: mutateGetMatchTenant, isLoading: loadingGetMatchTenant } =
    useMutation({
      mutationFn: async (dataEditTenant) => {
        return await getMatchTenants(dataEditTenant);
      },
      onSuccess: (data) => {
        setDataMatchTenants(data);
      },
      onError: () => {
        errorToast(t('tenants.addMatchTenant.mgs-error-getMatch'));
      },
    });

  const { mutate: mutateCreateMatch, isLoading: loadingCreateMatch } =
    useMutation({
      mutationFn: async (dataCreate) => {
        return await createMatchTenant(dataCreate);
      },
      onSuccess: () => {
        mutateGetMatchTenant();
      },
      onError: (error) => {
        const errorMessage = error.message || JSON.stringify(error);
        const toastMessage =
          errorMessage === 'Client and Server are already aggregated!'
            ? t('tenants.addMatchTenant.table.msg-alreadyExists')
            : t('tenants.addMatchTenant.table.msg-error-create');
        return errorToast(toastMessage);
      },
    });

  const { mutate: mutateDeleteMatch, isLoading: loadingDeleteMatch } =
    useMutation({
      mutationFn: async (dataDelete) => {
        return await deleteMatchTenant(dataDelete);
      },
      onSuccess: () => {
        mutateGetMatchTenant();
      },
      onError: () => {
        return errorToast(
          t('tenants.addMatchTenant.table.mgs-error-remove-match')
        );
      },
    });

  return {
    loadingTenants,
    mutateGetMatchTenant,
    loadingGetMatchTenant,
    mutateCreateMatch,
    loadingCreateMatch,
    mutateDeleteTenant,
    loadingDeleteTenant,

    mutateDeleteMatch,
    loadingDeleteMatch,
    mutateEditTenant,
    loadingEditTenant,
    mutateCreateTenant,
    loadingCreateTenant,
  };
};
