import { Droppable } from 'react-beautiful-dnd';
import { StyledColumn, StyledList } from './styledItems';
import { Item } from './Item';
import { useTranslation } from 'react-i18next';

export const Column = ({ col: { list, id }, handleToSelectedList }) => {
  const { t } = useTranslation();

  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <StyledColumn className={id}>
          <h2>
            {t(
              `dialControl.schedulesList.createSchedule.transferDetails.label-${id}`
            )}
            : {list.length}
          </h2>
          <StyledList {...provided.droppableProps} ref={provided.innerRef}>
            {list.map((text, index) => (
              <Item
                key={text}
                text={text}
                index={index}
                colSelected={id}
                handleToSelectedList={handleToSelectedList}
              />
            ))}
            {provided.placeholder}
          </StyledList>
        </StyledColumn>
      )}
    </Droppable>
  );
};
