import styled from 'styled-components';

export const ContainerRestrictions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;

  @media (max-width: 950px) {
    display: none;
  }

  div {
    min-width: 36%;
    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      svg {
        color: ${(props) => props.theme.colors.primary};
        font-size: 0.8rem;
      }

      h2 {
        font-size: 0.85rem;
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }

    .main {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.45rem;

      span {
        color: ${(props) => props.theme.colors.typography.baseText};
        opacity: 0.6;
        font-size: 0.8rem;
      }

      svg {
        color: ${(props) => props.theme.colors.secondary};
        font-size: 0.8rem;
      }
    }
  }
`;
