import { Autocomplete, Paper } from '@mui/material';
import styled from 'styled-components';

export const StyledAutoComplete = styled(Autocomplete)``;

export const StyledPaper = styled(Paper)`
  background-color: ${(props) =>
    props.theme.colors.containers.colorNode} !important;
  color: ${(props) => props.theme.colors.typography.baseText} !important;
`;
