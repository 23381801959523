import { useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { getVariablesFlow } from '@/services/flowBuilder/globalsVariables/getVariablesFlow';
import { useAtom } from 'jotai';
import { variablesGlobal } from '@/store/FlowBuilder';
import { useTranslation } from 'react-i18next';

export const useVariablesFlow = (stage = false) => {
  const { t } = useTranslation();
  const [, setGlobalVars] = useAtom(variablesGlobal);

  const { isLoading: loadingVariables } = useQuery(
    'variablesFlow',
    async () => {
      return await getVariablesFlow();
    },
    {
      enabled: stage === 1,
      onSuccess: (data) => setGlobalVars(data),
      onError: () => errorToast(t('flowBuilder.flow.msg-erro-getVariables')),
    }
  );

  return {
    loadingVariables,
  };
};
