import { useAtom } from 'jotai';
import { useMutation, useQuery } from 'react-query';
import { dataActivityLog } from '@/store/ActivityLog';
import { getLogs } from '@/services/activityLog/getLogs';
import { getAdvancedSearch } from '@/services/activityLog/getAdvancedSearch';

export const useActivityLog = () => {
  const [, setActivityLog] = useAtom(dataActivityLog);

  const {
    isLoading: loadingListActivityLog,
    isFetching: fetchingListActivityLog,
    refetch: refetchActivityLog,
  } = useQuery(
    'getActivityLogList',
    async () => {
      return await getLogs();
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setActivityLog(data);
      },
      onError: () => console.log('error'),
    }
  );

  const { mutate: mutateAdvancedSearch, isLoading: loadingAdvancedSearch } =
    useMutation({
      mutationFn: async (dataSearchList) => {
        return await getAdvancedSearch(dataSearchList);
      },
      onSuccess: (data) => {
        setActivityLog(data);
      },
      onError: () => console.log('error'),
    });

  const isLoading =
    fetchingListActivityLog || loadingListActivityLog || loadingAdvancedSearch;

  return { isLoading, refetchActivityLog, mutateAdvancedSearch };
};
