import { motion } from 'framer-motion';
import styled from 'styled-components';

export const MainManagerGlobalVars = styled(motion.div)`
  width: 100%;
  height: 86%;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  gap: 2rem;
  overflow: hidden;
  padding-top: 8px;
`;

export const FormAddGlobalVars = styled.form``;

export const ListGlobalVars = styled.div`
  height: 100%;
  overflow: auto;
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    padding-right: 4px;

    li {
      display: flex;
      padding: 0.7rem;
      background-color: ${(props) =>
        props.theme.colors.containers.baseContainer};
      border-radius: 3px;
      justify-content: space-between;
      align-items: center;

      svg {
        color: ${(props) => props.theme.colors.typography.baseText};
      }

      span {
        font-size: 0.8rem;
        color: ${(props) => props.theme.colors.typography.baseText};
        padding-top: 2px;
      }
    }
  }
`;

export const DividerConfig = styled.div`
  width: 100%;
  background-color: #e8e8e8;
`;

export const ButtonRemoveVar = styled(motion.button)`
  all: unset;
  display: flex;
  svg {
    font-size: 1.1rem;
    color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
