import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import { useTranslation } from 'react-i18next';
import { SubNodeLoopContainer } from './styled';

const itemLoopVoicceComponent = ({ data }) => {
  const { t } = useTranslation();

  return (
    <SubNodeLoopContainer typeHandler={data.handler}>
      <div className='headerNode'>
        <span>
          {data.label === 'Looping'
            ? t('flowBuilder.flow.nodes.loop.menu.label-looping')
            : t('flowBuilder.flow.nodes.loop.menu.label-complete')}
        </span>
      </div>
      <Handle type='source' position={Position.Right} />
    </SubNodeLoopContainer>
  );
};

export const ItemLoopVoicce = memo(itemLoopVoicceComponent);
