import { useAtom } from 'jotai';
import { PanelCallingEfforts } from './PanelCallingEfforts';
import { ContainerListCallingEfforts } from './styled';
import { dataCallingEfforts } from '@/store/Reports/callingEffortsStore';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { InputAdornment } from '@mui/material';
import { BiSearch } from 'react-icons/bi';
import { TableCallingEfforts } from './TableCallingEfforts';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DownloadData } from './DownloadData';
import { useTranslation } from 'react-i18next';

export const ListCallingEfforts = ({ changeFlow }) => {
  const { t } = useTranslation();
  const panelRef = useRef(null);
  const buttonRef = useRef(null);
  const [callingEfforts, setCallingEfforts] = useAtom(dataCallingEfforts);
  const [showPanelDownload, setShowPanelDownload] = useState(false);
  const [filterCalls, setFilterCalls] = useState('');

  const handleClickOutside = (event) => {
    if (
      panelRef.current &&
      !panelRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowPanelDownload(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleBack = () => {
    changeFlow(0);
    setCallingEfforts(null);
  };

  const handlePanelDownloads = () => {
    setShowPanelDownload((prevState) => !prevState);
  };

  const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };

  const filterCallingTable = useCallback(
    (list) => {
      if (!list) {
        return [];
      }
      const filterByName = list.filter((data) =>
        data.name.toLowerCase().includes(filterCalls.toLowerCase())
      );

      const filterByCPF = list.filter((data) =>
        data.cpf.toLowerCase().includes(filterCalls.toLowerCase())
      );

      const filterByList = list.filter((data) =>
        data.list.toLowerCase().includes(filterCalls.toLowerCase())
      );

      const filterByDispositions = list.filter((data) =>
        data.disposition.toLowerCase().includes(filterCalls.toLowerCase())
      );

      const filterByPhone = list.filter((data) =>
        data.phone.toLowerCase().includes(filterCalls.toLowerCase())
      );

      const newArrFiltered = [
        ...filterByName,
        ...filterByCPF,
        ...filterByList,
        ...filterByDispositions,
        ...filterByPhone,
      ];
      return removeDuplicates(newArrFiltered);
    },
    [filterCalls]
  );

  return (
    <ContainerListCallingEfforts>
      <PanelCallingEfforts
        dataCallingEfforts={callingEfforts}
        handleBack={handleBack}
      />
      <InputComponent
        value={filterCalls}
        onChange={(e) => setFilterCalls(e.target.value)}
        placeholder={t(
          `reports.callingEfforts.listCallingEfforts.placeholder-filter`
        )}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <BiSearch />
            </InputAdornment>
          ),
        }}
      />
      <DownloadData
        handlePanelDownloads={handlePanelDownloads}
        panelRef={panelRef}
        showPanelDownload={showPanelDownload}
        dataDownload={callingEfforts.callingEfforts ?? []}
        disabled={Boolean(!callingEfforts.callingEfforts.length)}
      />
      <TableCallingEfforts
        dataTable={filterCallingTable(callingEfforts.callingEfforts)}
      />
    </ContainerListCallingEfforts>
  );
};
