import { useId } from 'react';
import { ContainerDDDManager } from './styled';
import { EditGroupDDD } from './stages/EditGroupDDD';
import { useAtom } from 'jotai';
import { stageDDDManager } from '@/store/DialingStore';
import { ListDDD } from './stages/ListDDD';

export const DDDManager = () => {
  const [currentStageDDD, setCurrentStageDDD] = useAtom(stageDDDManager);

  const controlStage = {
    previousStage: () =>
      setCurrentStageDDD((currentStageUf) => currentStageUf - 1),
    nextStage: () => setCurrentStageDDD((currentStageUf) => currentStageUf + 1),
    toStage: (stage) => setCurrentStageDDD(stage),
    currentStage: currentStageDDD,
  };

  const flowDDD = [
    <ListDDD key={useId()} controlStage={controlStage} />,
    <EditGroupDDD key={useId()} controlStage={controlStage} />,
  ];

  return <ContainerDDDManager>{flowDDD[currentStageDDD]}</ContainerDDDManager>;
};
