import { useEffect, useMemo, useState } from 'react';
import { object, string, number } from 'yup';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import useFormControl from '@/hooks/useFormControl';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import DonatoSound from '@/assets/sounds/flowSounds/saudacao1-pt-BR-DonatoNeural.wav';
import AntonioSound from '@/assets/sounds/flowSounds/saudacao1-pt-BR-AntonioNeural.wav';
import FranciscaSound from '@/assets/sounds/flowSounds/saudacao1-pt-BR-FranciscaNeural.wav';
import GiovannaSound from '@/assets/sounds/flowSounds/saudacao1-pt-BR-GiovannaNeural.wav';
import HumbertoSound from '@/assets/sounds/flowSounds/saudacao1-pt-BR-HumbertoNeural.wav';
import JulioSound from '@/assets/sounds/flowSounds/saudacao1-pt-BR-JulioNeural.wav';
import NicoSound from '@/assets/sounds/flowSounds/saudacao1-pt-BR-NicolauNeural.wav';
import { checkLabelExistiNode } from '@/components/pages/FlowBuilder/utils/checkNameNodes';
import { errorToast } from '@/components/FormComponents/Toast';
import { FooterNode } from '@/components/pages/FlowBuilder/components/FooterNode';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import { TitleMenuNode } from '../TitleMenuNode';
import { FormMenuNode, MainAddNode } from './styled';
import {
  ControlSelect,
  SelectComponent,
} from '@/components/FormComponents/SelectInput';
import {
  focusedFieldArea,
  isSavedFlow,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';
import AudioWaveform from './wavePersona';

export const PersonaForm = ({ dataNodes }) => {
  const { t } = useTranslation();
  const [, setFocusedArea] = useAtom(focusedFieldArea);

  const dataPersona = {
    dataLanguage: [
      {
        name: t('flowBuilder.flow.nodes.persona.menu.option-ptBr'),
        value: 'pt-br',
      },
      {
        name: t('flowBuilder.flow.nodes.persona.menu.option-en'),
        value: 'en-us',
      },
    ],
    voices: [
      {
        name: 'Donato',
        value: 'pt-BR-DonatoNeural',
        url: DonatoSound,
      },
      {
        name: 'Antonio',
        value: 'pt-BR-AntonioNeural',
        url: AntonioSound,
      },
      {
        name: 'Francisca',
        value: 'pt-BR-FranciscaNeural',
        url: FranciscaSound,
      },
      {
        name: 'Giovanna',
        value: 'pt-BR-GiovannaNeural',
        url: GiovannaSound,
      },
      {
        name: 'Humberto',
        value: 'pt-BR-HumbertoNeural',
        url: HumbertoSound,
      },
      {
        name: 'Julio',
        value: 'pt-BR-JulioNeural',
        url: JulioSound,
      },
      {
        name: 'Nico',
        value: 'pt-BR-NicolauNeural',
        url: NicoSound,
      },
    ],
  };

  const personaSchema = useMemo(
    () =>
      object().shape({
        labelNode: string()
          .required(t('flowBuilder.flow.nodes.persona.menu.msg-name-required'))
          .max(
            50,
            t('flowBuilder.flow.nodes.persona.menu.msg-name-length', {
              length: '50',
            })
          )
          .matches(
            /^[_a-zA-Z0-9]*$/,
            t('flowBuilder.flow.nodes.persona.menu.msg-name-letters')
          ),
        language: string()
          .required(
            t('flowBuilder.flow.nodes.persona.menu.msg-language-required')
          )
          .test(
            'not-zero-null-undefined',
            t('flowBuilder.flow.nodes.persona.menu.msg-language-required'),
            (value) => {
              return value !== '0' && value !== null && value !== undefined;
            }
          ),
        prosodyRate: number()
          .positive('Deve ser um número positivo')
          .min(0, 'Deve ser no mínimo 0')
          .max(10, 'Velocidade máxima permitida é 10'),
      }),
    []
  );

  const [voiceField, setVoiceField] = useState('0');
  const [urlSound, setUrlSound] = useState('');
  const [count, setCount] = useState(0);
  const [, setIsSaved] = useAtom(isSavedFlow);

  const validateVoiceField = (value) => {
    setCount((count) => count + 1);
    setVoiceField(value);
  };

  const { handleSubmit, control, errors, reset } =
    useFormControl(personaSchema);

  const [, setShowMenu] = useAtom(showMenuNodes);

  const [selected, setSelected] = useAtom(selectedNode);

  const { dataLanguage, voices } = dataPersona;

  const { nodes, setNodes } = dataNodes;
  const { id, data: dataSelected } = selected;

  useEffect(() => {
    if (selected) {
      setVoiceField(dataSelected.voice);
    }
  }, [selected]);

  useEffect(() => {
    if (voiceField !== '0' && count > 0) {
      const filtered = voices
        .filter((item) => item.name === voiceField)
        .map((item) => item.url);
      setUrlSound(filtered);
    }
  }, [voiceField, count]);

  const closeMenu = () => {
    reset();
    setShowMenu(false);
    setSelected(null);
    setCount(0);
  };

  useEffect(() => {
    return () => closeMenu;
  }, []);

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const editFileName = (currentFileName, newFileName) => {
    const separateString = currentFileName.split('-');
    const removeIndexVoice = separateString.splice(
      separateString.length - 3,
      3
    );
    const joinFileName = separateString.join('-');

    const editedFileName = `${joinFileName}-${newFileName}`;

    return editedFileName;
  };

  const handleSubmitNewNode = (data) => {
    const checkLabel = checkLabelExistiNode(id, data.labelNode, nodes);

    if (checkLabel) {
      return errorToast(t('flowBuilder.flow.nodes.persona.menu.msg-name-used'));
    }

    const renderValueVoice = voices
      .filter((item) => item.name === voiceField)
      .map((item) => item.value)
      .toString();

    const filteredNode = nodes.map((node) => {
      if (node.data.handler === 'ttsVoicceLabs') {
        return {
          ...node,
          data: {
            ...node.data,
            fileName: editFileName(node.data.fileName, renderValueVoice),
          },
        };
      }

      if (node.id === id) {
        return {
          ...node,
          data: {
            ...node.data,
            label: data.labelNode,
            language: data.language,
            voice: voiceField,
            valueVoice: renderValueVoice,
            prosodyRate: data.prosodyRate,
            errorConnect: false,
          },
        };
      }

      return node;
    });
    setNodes(filteredNode);
    setIsSaved(false);
    closeMenu();
  };

  return (
    <FormMenuNode
      onClick={handleChildClick}
      onSubmit={handleSubmit(handleSubmitNewNode)}
      id='formPersona'
      name='formPersona'
    >
      <MainAddNode>
        <TitleMenuNode
          title={t('flowBuilder.flow.nodes.persona.menu.title')}
          description={t('flowBuilder.flow.nodes.persona.description')}
        />
        <ControlInput
          label={t('flowBuilder.flow.nodes.persona.menu.label-name')}
          control={control}
          nameControl={'labelNode'}
          defaultValue={dataSelected.label ?? ''}
          error={Boolean(errors.labelNode)}
          helperText={errors.labelNode ? errors.labelNode.message : ' '}
          inputProps={{ maxLength: 51 }}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />

        <h4>{t('flowBuilder.flow.nodes.persona.menu.description')}</h4>

        <ControlSelect
          control={control}
          nameControl={'language'}
          label={t('flowBuilder.flow.nodes.persona.menu.option-language')}
          defaultValue={dataSelected.language}
          error={Boolean(errors.language)}
          helperText={errors.language ? errors.language.message : ' '}
        >
          <ItemSelect value={'0'} disabled>
            {t('flowBuilder.flow.nodes.persona.menu.option-language')}
          </ItemSelect>
          {dataLanguage.map((item, i) => (
            <ItemSelect value={item.value} key={i}>
              {item.name}
            </ItemSelect>
          ))}
        </ControlSelect>

        <SelectComponent
          labelSelect={t('flowBuilder.flow.nodes.persona.menu.label-voice')}
          value={voiceField}
          onChange={(e) => validateVoiceField(e.target.value)}
          error={Boolean(errors.voice)}
          helperText={errors.voice ? errors.voice.message : ' '}
        >
          <ItemSelect value={'0'} disabled>
            {t('flowBuilder.flow.nodes.persona.menu.option-voice')}
          </ItemSelect>
          {voices.map((item, i) => (
            <ItemSelect value={item.name} key={i}>
              {item.name}
            </ItemSelect>
          ))}
        </SelectComponent>

        <ControlInput
          label={t('flowBuilder.flow.nodes.persona.label-prosodyRate')}
          control={control}
          type='number'
          nameControl={'prosodyRate'}
          defaultValue={dataSelected.prosodyRate}
          error={Boolean(errors.prosodyRate)}
          helperText={errors.prosodyRate ? errors.prosodyRate.message : ' '}
          inputProps={{ min: 0, max: 10 }}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />

        <div className='audio-content'>
          {count !== 0 && urlSound && urlSound !== '' && (
            <AudioWaveform audioUrl={urlSound} />
          )}
        </div>
      </MainAddNode>

      <FooterNode closeMenu={closeMenu} />
    </FormMenuNode>
  );
};
