import { useCallback, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useCenteredTree = () => {
  const matchesQueriesMd = useMediaQuery('(max-width:1460px)');

  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [currentHeightScreen] = useState(1.65);
  const [centralizedTree, setCentralizedTree] = useState(false);

  const centralizeTree = () => {
    setCentralizedTree(!centralizedTree);
  };

  const containerRef = useCallback(
    (containerElem) => {
      if (containerElem !== null) {
        const { width, height } = containerElem.getBoundingClientRect();
        setTranslate({ x: width / 10, y: height / currentHeightScreen });
      }
    },
    [matchesQueriesMd, currentHeightScreen, centralizedTree]
  );

  return [translate, containerRef, setTranslate, centralizeTree];
};
