import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ContainerSubstring } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

export const SplitFunction = ({
  errorFunc,
  setErrorFunc,
  idFilterRender,
  itemPreview,
  listFilter,
  setListFilter,
}) => {
  const { t } = useTranslation();

  const [resultText, setResultText] = useState('');

  const [displayedText, setDisplayedText] = useState('');
  const [typeCase, setTypeCase] = useState('');

  const [indexPosition, setIndexPosition] = useState(0);
  const [characterSplit, setCharacterSplit] = useState('#');
  const [dataSplit, setDataSplit] = useState([]);

  useEffect(() => {
    if (resultText) {
      setDataSplit(resultText.split(characterSplit));
    }
  }, [resultText, characterSplit]);

  const renderPreview = () => {
    return dataSplit.map((item, i) => {
      return (
        <Button
          variant='contained'
          size='small'
          key={i}
          onClick={() => setIndexPosition(i)}
          className={i === indexPosition ? 'active-index' : ''}
          disabled={item === ''}
        >
          <span>
            {i}º {item}
          </span>
        </Button>
      );
    });
  };

  const filterDataRender = listFilter.filter(
    (list) => list.id === idFilterRender
  );

  function splitResult(strSplit, index, resultText) {
    if (typeof resultText !== 'string' || !strSplit) {
      return '';
    }
    const splitValue = resultText.split(strSplit);

    if (index < 0 || index >= splitValue.length) {
      return '';
    }
    return String(splitValue[index] || '');
  }

  useEffect(() => {
    if (filterDataRender.length > 0) {
      if (filterDataRender[0].sequence === 1) {
        return setResultText(itemPreview);
      }
      const currentSequence = filterDataRender[0].sequence;
      const sequenceList = listFilter
        .map((list) => list.sequence)
        .filter((seq) => seq !== currentSequence)
        .sort((a, b) => a - b);
      const previousClosest = sequenceList
        .filter((seq) => seq < currentSequence)
        .reduce((prev, curr) => (curr > prev ? curr : prev), -Infinity);
      const getPreviousValue = listFilter.filter(
        (list) => list.sequence === previousClosest
      );
      if (getPreviousValue.length > 0) {
        setResultText(getPreviousValue[0].result);
      }
    }
  }, [filterDataRender]);

  useEffect(() => {
    if (filterDataRender.length > 0) {
      setCharacterSplit(filterDataRender[0].data[1] ?? '#');
      setIndexPosition(filterDataRender[0].data[2] ?? 0);
      setTypeCase(filterDataRender[0].data[3] ?? '');
    }
  }, []);

  useEffect(() => {
    if (displayedText === '') {
      if (!errorFunc.includes(idFilterRender)) {
        setErrorFunc((previous) => [...previous, idFilterRender]);
      }
    } else {
      if (errorFunc.includes(idFilterRender)) {
        setErrorFunc((previous) =>
          previous.filter((error) => error !== idFilterRender)
        );
      }
    }
  }, [displayedText, errorFunc, idFilterRender]);

  const rendeCaseSensitive = (typeCase, dataChangeCase) => {
    switch (typeCase) {
      case 'uppercase':
        return dataChangeCase.toUpperCase();

      case 'lowercase':
        return dataChangeCase.toLowerCase();

      case 'capitalize':
        return dataChangeCase
          .split(' ')
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(' ');

      default:
        return dataChangeCase;
    }
  };

  const changeCaseSensitive = (typeCaseSelected) => {
    if (typeCaseSelected === typeCase) {
      return setTypeCase('');
    }
    setTypeCase(typeCaseSelected);
  };

  useEffect(() => {
    const renderString = splitResult(characterSplit, indexPosition, resultText);
    setDisplayedText(rendeCaseSensitive(typeCase, renderString));

    const updatedList = listFilter.map((list) => {
      if (list.id === idFilterRender) {
        return {
          ...list,
          data: ['split', characterSplit, indexPosition, typeCase],
          result: rendeCaseSensitive(typeCase, renderString),
        };
      }
      return list;
    });
    setListFilter(updatedList);
  }, [resultText, characterSplit, indexPosition, typeCase, displayedText]);

  const hasError = errorFunc.includes(idFilterRender) ? 1 : 0;

  return (
    <ContainerSubstring errorString={hasError}>
      <div className='case-content'>
        <motion.button
          whileTap={{ scale: 0.9 }}
          className={typeCase === 'uppercase' ? 'active-case' : ''}
          onClick={() => changeCaseSensitive('uppercase')}
          title='Uppercase'
        >
          <span>AA</span>
        </motion.button>
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={() => changeCaseSensitive('capitalize')}
          className={typeCase === 'capitalize' ? 'active-case' : ''}
          title='Capitalize'
        >
          <span>Aa</span>
        </motion.button>
        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={() => changeCaseSensitive('lowercase')}
          className={typeCase === 'lowercase' ? 'active-case' : ''}
          title='LowerCase'
        >
          <span>aa</span>
        </motion.button>
      </div>
      <div className='input-content'>
        <span>{t('listLoader.importMailing.flow.label-input')}:</span>
        <span>
          <strong style={{ whiteSpace: 'pre' }}>{resultText}</strong>
        </span>
      </div>
      <Stack direction={'row'} spacing={2}>
        <InputComponent
          label={t('listLoader.importMailing.flow.label-char-split')}
          value={characterSplit}
          onChange={(e) => {
            const newValue = e.target.value;
            if (newValue !== ';') {
              setCharacterSplit(newValue);
            }
          }}
          autoComplete='off'
          inputProps={{ maxLength: 1 }}
        />
        <InputComponent
          label={t('listLoader.importMailing.flow.label-position-index')}
          type='number'
          value={indexPosition}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (value >= 0 && value <= dataSplit.length - 1) {
              setIndexPosition(value);
            }
          }}
          autoComplete='off'
          InputProps={{
            inputProps: {
              min: 0,
              max: dataSplit.length - 1,
            },
          }}
        />
      </Stack>

      <div className='preview-content'>
        <span>{t('listLoader.importMailing.flow.title-preview-split')}:</span>
        <div className='contentPreview'>{renderPreview()}</div>
      </div>
      <div className='result-content'>
        <span>{t('listLoader.importMailing.flow.label-output')}:</span>
        <span>
          <strong style={{ whiteSpace: 'pre' }}>{displayedText}</strong>
        </span>
      </div>
    </ContainerSubstring>
  );
};
