import styled from 'styled-components';

export const ContentTree = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  border: 1px solid ${(props) => props.theme.colors.typography.baseText}AA;
  border-radius: 3px;
  position: relative;

  .link__node {
    fill: none;
    stroke: gray;
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    pointer-events: none;
  }

  .rd3t-node {
    cursor: pointer;
    fill: ${(props) => props.theme.colors.primary};
    stroke: ${(props) => props.theme.colors.typography.baseText};
    stroke-width: 2;
  }

  .rd3t-leaf-node {
    fill: white;
    stroke: ${(props) => props.theme.colors.typography.baseText};
    stroke-width: 1;
  }
`;

export const PanelChart = styled.div`
  display: flex;
  gap: 0.4rem;
  justify-content: flex-start;
  flex-direction: row;
  position: absolute;
  right: 2px;
  padding: 0.4rem;
  background-color: ${(props) =>
    props.theme.colors.containers.baseBackground}AA;

  button {
    min-width: 24px;
    height: 24px;
    padding: 0;
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.primary};
    svg {
      font-size: 1rem;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.primaryDark};
    }
  }
`;
