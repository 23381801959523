import { ItemIntentStyled, TreeLi, TreeUl } from './styled';
import { AccordionToItem } from '@/components/pages/FlowBuilder/components/AccordionToItem';
import { TbSquareRounded } from 'react-icons/tb';

export const ItemIntent = ({
  intent,
  removeIntent,
  updateIntent,
  selected,
  usedNlus,
}) => {
  const hasSelected = intent.id === selected?.id ? 1 : 0;
  return (
    <ItemIntentStyled selected={hasSelected}>
      <AccordionToItem
        dataIntent={intent}
        removeIntent={removeIntent}
        updateIntent={updateIntent}
        usedNlu={usedNlus.includes(intent.name)}
      >
        <TreeUl>
          {intent.data &&
            intent.data.length > 0 &&
            intent.data.map((item, i) => (
              <TreeLi key={i}>
                <TbSquareRounded />
                <span>{item}</span>
              </TreeLi>
            ))}
        </TreeUl>
      </AccordionToItem>
    </ItemIntentStyled>
  );
};
