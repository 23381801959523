import { useEffect, useState } from 'react';
import { AiFillApi } from 'react-icons/ai';
import { useAtom } from 'jotai';
import { MdModeEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { FaCircleArrowRight } from 'react-icons/fa6';
import { selectedNode, variablesList } from '@/store/FlowBuilder';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { TableVarsData } from './TableVarsData';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import { FlexContainer } from '@/components/layout/FlexContainer/styled';
import { CodeRender } from '@/components/layout/CodeRender';
import {
  ContainerResponseDataApi,
  FooterResponse,
  HeaderResponse,
  MainResponse,
} from './styled';

export const ResponseDataApiFlow = ({
  dataResponse,
  onClose,
  nodes,
  setNodes,
}) => {
  const { t } = useTranslation();
  const [valueFilterResponseData, setValueFilterResponseData] = useState('');
  const [nameVarApiFiltered, setNameVarApiFiltered] = useState('');
  const [errorNameVarApiFiltered, setErrorNameVarApiFiltered] = useState('');
  const [selectedHandler] = useAtom(selectedNode);
  const [selectedVar, setSelectedVar] = useState(null);

  const [variablesFlow, setVariablesFlow] = useAtom(variablesList);
  const [dataFilterResponse, setDataFilterResponse] = useState();

  const resetFieldsFormData = () => {
    setNameVarApiFiltered('');
    setValueFilterResponseData('');
    setDataFilterResponse({});
    setSelectedVar(null);
  };

  useEffect(() => {
    return () => resetFieldsFormData();
  }, []);

  const validateNameVarApi = (value) => {
    if (!value.trim()) {
      setErrorNameVarApiFiltered(
        t('flowBuilder.flow.nodes.dataApi.menu.msg-name-required')
      );
    } else {
      setErrorNameVarApiFiltered('');
    }
    setNameVarApiFiltered(value);
  };

  const getValueFromPath = (obj, path) => {
    let current = obj;
    for (let i = 0; i < path.length; i++) {
      if (path[i].includes('[') && path[i].includes(']')) {
        const [key, index] = path[i].replace(']', '').split('[');
        if (
          current[key] &&
          Array.isArray(current[key]) &&
          current[key].length > Number(index)
        ) {
          current = current[key][Number(index)];
        } else {
          return null;
        }
      } else {
        current = current[path[i]];
      }
      if (current === undefined) {
        return null;
      }
    }
    return current;
  };

  useEffect(() => {
    if (valueFilterResponseData.includes('$')) {
      let varsFiltered = valueFilterResponseData
        .split('.')
        .map((v) => v.replace('$', ''));
      const arrayMatch =
        varsFiltered[varsFiltered.length - 1].match(/\[(\d+)\]$/);
      if (arrayMatch) {
        varsFiltered[varsFiltered.length - 1] = varsFiltered[
          varsFiltered.length - 1
        ].replace(/\[\d+\]$/, '');
      }
      if (varsFiltered.length === 1 && varsFiltered[0] === '') {
        setDataFilterResponse(null);
        return;
      }

      const result = getValueFromPath(dataResponse.data, varsFiltered);

      setDataFilterResponse(result);
    }
  }, [valueFilterResponseData]);

  const handleAddPathVar = (id) => {
    const updatedVariables = variablesFlow.map((item) => {
      if (item.name === nameVarApiFiltered) {
        if (!item.referNode) {
          return {
            ...item,
            referNode: id,
            value: valueFilterResponseData,
          };
        }
      }

      return item;
    });

    const filteredNode = nodes.map((node) => {
      if (node.data.handler === 'setVariables') {
        return {
          ...node,
          data: {
            ...node.data,
            listVariables: updatedVariables,
          },
        };
      }
      return node;
    });
    setNodes(filteredNode);
    setNameVarApiFiltered('0');
    setVariablesFlow(updatedVariables);
    setValueFilterResponseData('');
    setDataFilterResponse();
  };

  const handleRemoveVarList = (dataRowId) => {
    const removeItemList = variablesFlow.map((item) => {
      if (item.id === dataRowId) {
        return {
          ...item,
          value: 'null',
          referNode: false,
        };
      }
      return item;
    });
    setVariablesFlow(removeItemList);

    const filteredNode = nodes.map((node) => {
      if (node.data.handler === 'setVariables') {
        return {
          ...node,
          data: {
            ...node.data,
            listVariables: removeItemList,
          },
        };
      }
      return node;
    });
    setNodes(filteredNode);
  };

  const filterVarsByNode = variablesFlow.filter(
    (item) => item.referNode === selectedHandler.id
  );

  return (
    <ContainerResponseDataApi>
      <HeaderResponse>
        <FlexContainer direction={'row'} justify={'space-between'}>
          <div className='title-header'>
            <AiFillApi size={17} />
            <h4>
              {t('flowBuilder.flow.nodes.dataApi.menu.title-response-api')}
            </h4>
          </div>

          {dataResponse.statusCode || dataResponse.status === 'Error' ? (
            <span className='errorResponse'>
              status:{' '}
              <strong>
                {dataResponse.status} -{' '}
                {dataResponse.statusCode
                  ? dataResponse.statusCode
                  : dataResponse.data}
              </strong>
            </span>
          ) : (
            <span>
              status: <strong>{dataResponse.status}</strong>
            </span>
          )}
        </FlexContainer>
      </HeaderResponse>

      <MainResponse>
        <div className='itemResponse'>
          <h4>
            {t('flowBuilder.flow.nodes.dataApi.menu.title-response-data')}
          </h4>
          <div className='body-response'>
            <CodeRender dataRender={dataResponse.data} />
          </div>
        </div>

        {dataResponse.statusCode || dataResponse.status === 'Error' ? (
          ''
        ) : (
          <>
            <div className='itemResponse'>
              <h4>
                {t('flowBuilder.flow.nodes.dataApi.menu.title-response-filter')}
              </h4>
              <FlexContainer gap={'0.6rem'}>
                <InputComponent
                  size={'small'}
                  value={valueFilterResponseData}
                  onChange={(e) => setValueFilterResponseData(e.target.value)}
                  placeholder={t(
                    'flowBuilder.flow.nodes.dataApi.menu.label-response-filter'
                  )}
                  InputProps={{
                    endAdornment:
                      typeof dataFilterResponse === 'string' ||
                      typeof dataFilterResponse === 'boolean' ||
                      typeof dataFilterResponse === 'number' ? (
                        <IoMdCheckmarkCircleOutline color='green' size={20} />
                      ) : (
                        <IoCloseCircleOutline color='red' size={20} />
                      ),
                  }}
                />
                <SelectComponent
                  defaultValue='0'
                  labelSelect={t(
                    'flowBuilder.flow.nodes.dataApi.menu.label-name-var'
                  )}
                  size={'small'}
                  value={nameVarApiFiltered}
                  onChange={(e) => validateNameVarApi(e.target.value)}
                  disabled={
                    !(
                      (typeof dataFilterResponse === 'string' ||
                        typeof dataFilterResponse === 'boolean' ||
                        typeof dataFilterResponse === 'number') &&
                      !errorNameVarApiFiltered
                    )
                  }
                  error={Boolean(errorNameVarApiFiltered)}
                  helperText={errorNameVarApiFiltered}
                >
                  <ItemSelect value={'0'} disabled>
                    {t('flowBuilder.flow.nodes.dataApi.menu.option-variable')}
                  </ItemSelect>
                  {variablesFlow.length > 0 &&
                    variablesFlow
                      .filter(
                        (item) => !item.referNode && item.value === 'null'
                      )
                      .map((item) => {
                        return (
                          <ItemSelect key={item.id} value={item.name}>
                            {item.name}
                          </ItemSelect>
                        );
                      })}
                </SelectComponent>

                {!selectedVar ? (
                  <button
                    onClick={() => handleAddPathVar(selectedHandler.id)}
                    title={t(
                      'flowBuilder.flow.nodes.dataApi.menu.title-btn-add-var'
                    )}
                    disabled={
                      !(
                        (typeof dataFilterResponse === 'string' ||
                          typeof dataFilterResponse === 'boolean' ||
                          typeof dataFilterResponse === 'number') &&
                        !errorNameVarApiFiltered
                      )
                    }
                  >
                    <FaCircleArrowRight />
                  </button>
                ) : (
                  <button
                    title={t(
                      'flowBuilder.flow.nodes.dataApi.menu.title-btn-edit-var'
                    )}
                    disabled={
                      !(
                        (typeof dataFilterResponse === 'string' ||
                          typeof dataFilterResponse === 'number') &&
                        !errorNameVarApiFiltered
                      )
                    }
                  >
                    <MdModeEdit />
                  </button>
                )}
              </FlexContainer>
              <div className='body-response'>
                <CodeRender dataRender={dataFilterResponse} index={2} />
              </div>
            </div>
            <FlexContainer gap={'0.6rem'} direction={'column'}>
              <h4>
                {t('flowBuilder.flow.nodes.dataApi.menu.title-api-variables')}
              </h4>

              <div className='render-vars-api'>
                <TableVarsData
                  dataTable={filterVarsByNode}
                  handleRemoveVarList={handleRemoveVarList}
                />
              </div>
            </FlexContainer>
          </>
        )}
      </MainResponse>

      <FooterResponse>
        <Button typeText variant={'secondary'} onClick={onClose}>
          {t('flowBuilder.flow.nodes.dataApi.menu.btn-cancel')}
        </Button>
        <Button typeText onClick={onClose}>
          oK
        </Button>
      </FooterResponse>
    </ContainerResponseDataApi>
  );
};
