import { useAtom } from 'jotai';
import { errorToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { dataDashboardCharts } from '@/store/DashboardPage';
import { getQueuesCampaigns } from '@/services/dialControl/campaigns/getQueuesCampaigns';
import { getMailingAnalysis } from '@/services/dashboardPage/getMailingAnalysis';
import { getFunnelAttendance } from '@/services/dashboardPage/getFunnelAttendance';
import { dataServersControl } from '@/store/Queues';
import { getServicePerHour } from '@/services/dashboardPage/getServicePerHour';
import { useEffect, useState } from 'react';
import { currentPageId, isReload } from '@/store/NavigationDashboard';

export const useDashboardPage = () => {
  const { t } = useTranslation();
  const [, setDashboardData] = useAtom(dataDashboardCharts);
  const [serversControl] = useAtom(dataServersControl);
  const [errorGetLists, setErrorGetsLists] = useState(false);
  const [reloaded] = useAtom(isReload);
  const [currentPage] = useAtom(currentPageId);

  const controller = new AbortController();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [reloaded, currentPage]);

  // RENDER LIST QUEUES
  const { isLoading: isLoadingQueuesCampaign } = useQuery(
    'dataQueueDashboard',
    async () => {
      return await getQueuesCampaigns({
        first_queue: serversControl.first_queue,
        last_queue: serversControl.last_queue,
      });
    },
    {
      enabled: !!serversControl,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setDashboardData((previousValue) => {
          return { ...previousValue, queuesDashboard: data };
        });
      },
      onError: () => {
        errorToast(t('dialing.newListConfig.messages.errorRequesStatus'));
      },
    }
  );

  // GET MAILING ANALYSIS
  const sumMailingAnalysis = (lists) => {
    try {
      const result = {};
      lists.forEach((list) => {
        const { total, cod_lig } = list;
        result[cod_lig] = (result[cod_lig] || 0) + parseInt(total);
      });
      const resultData = Object.keys(result).map((cod_lig) => ({
        total: result[cod_lig].toString(),
        cod_lig: cod_lig,
      }));
      return resultData;
    } catch {
      return [];
    }
  };

  const { mutate: mutateMailingAnalysis, isLoading: loadingMailingAnalysis } =
    useMutation({
      mutationFn: async (listQueue) => {
        const results = await getMailingAnalysis(listQueue, controller.signal);
        return sumMailingAnalysis(results);
      },
      onSuccess: (data) => {
        setErrorGetsLists(false);
        setDashboardData((previousValue) => {
          return {
            ...previousValue,
            mailingAnalysis: data,
          };
        });
      },
      onError: () => setErrorGetsLists(true),
    });

  // GET FUNNEL ATTENDANCE
  const renderSumValues = (list) => {
    const totalCount = list.reduce((acc, item) => {
      return acc + item.total;
    }, 0);

    const totalAgreement = list
      .filter((item) => item.contato_positivo)
      .reduce((acc, item) => {
        return acc + item.total;
      }, 0);

    const totalEffective = list
      .filter((item) => item.contato_efetivo)
      .reduce((acc, item) => {
        return acc + item.total;
      }, 0);

    const result = {
      total: totalCount,
      contato_positivo: totalAgreement,
      contato_efetivo: totalEffective,
    };

    return result;
  };

  const { mutate: mutateFunnelAttendance, isLoading: loadingFunnelAttendance } =
    useMutation({
      mutationFn: async (listQueue) => {
        const result = await getFunnelAttendance(listQueue, controller.signal);
        return renderSumValues(result);
      },

      onSuccess: (data) => {
        setErrorGetsLists(false);
        setDashboardData((previousValue) => {
          return {
            ...previousValue,
            funnelAttendance: data,
          };
        });
      },
      onError: () => setErrorGetsLists(true),
    });

  const { mutate: mutateServicesPerHour, isLoading: loadingServicesPerHour } =
    useMutation({
      mutationFn: async (listQueue) => {
        const result = await getServicePerHour(
          listQueue.queue,
          controller.signal
        );
        return result;
      },
      onSuccess: (data) => {
        setErrorGetsLists(false);
        setDashboardData((previousValue) => {
          return {
            ...previousValue,
            callPerHour: data,
          };
        });
      },
      onError: () => setErrorGetsLists(true),
    });

  return {
    isLoadingQueuesCampaign,
    mutateMailingAnalysis,
    loadingMailingAnalysis,
    mutateFunnelAttendance,
    loadingFunnelAttendance,
    mutateServicesPerHour,
    loadingServicesPerHour,
    errorGetLists,
  };
};
