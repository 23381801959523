import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useActivityLog } from '@/hooks/ActivityLog/useActivityLog';
import { ListActivityLog } from './components/ListActivityLog';
import { PanelActivityLog } from './components/PanelActivityLog';
import { ContainerActivityLog } from './styled';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { dataActivityLog } from '@/store/ActivityLog';
import { removeDuplicates } from '@/utils/removeDuplicateItemsArr';

export const ActivityLog = () => {
  const { isLoading, mutateAdvancedSearch, refetchActivityLog } =
    useActivityLog();
  const [listActivityLog, setActivityLog] = useAtom(dataActivityLog);
  const [fieldSearchLogs, setFieldSearchLogs] = useState('');

  useEffect(() => {
    return () => {
      setActivityLog([]);
    };
  }, []);

  const dataSearch = {
    fieldSearchLogs,
    setFieldSearchLogs,
  };

  const filterAllTableLog = (dataFilter) => {
    if (dataFilter) {
      const filterTableByUser = dataFilter.filter((data) =>
        data.user.toLowerCase().includes(fieldSearchLogs.toLowerCase())
      );
      const filterTableByClient = dataFilter.filter((data) =>
        data.client.toLowerCase().includes(fieldSearchLogs.toLowerCase())
      );
      const filterTableByAddress = dataFilter.filter((data) =>
        data.address.toLowerCase().includes(fieldSearchLogs.toLowerCase())
      );
      const filterTableByRoute = dataFilter.filter((data) =>
        data.route.toLowerCase().includes(fieldSearchLogs.toLowerCase())
      );

      const newArrFiltered = [
        ...filterTableByUser,
        ...filterTableByClient,
        ...filterTableByAddress,
        ...filterTableByRoute,
      ];

      return removeDuplicates(newArrFiltered);
    }
  };

  return (
    <ContainerActivityLog initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <PanelActivityLog
        isLoading={isLoading}
        dataSearch={dataSearch}
        mutateAdvancedSearch={mutateAdvancedSearch}
        refetchActivityLog={refetchActivityLog}
      />
      <ListActivityLog
        isLoading={isLoading}
        dataList={filterAllTableLog(listActivityLog)}
      />
    </ContainerActivityLog>
  );
};
