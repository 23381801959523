import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import { HiSortAscending, HiSortDescending } from 'react-icons/hi';
import { dataQueues } from '@/store/Queues';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { useQueues } from '@/hooks/dialControl/Queues/useQueues';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { NoDataStatus } from './NoDataStatus';
import { CardStatus } from './CardStatus';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { useQueueManagement } from '@/hooks/dialControl/QueueManagement';
import { usePermissionsDialControl } from '@/hooks/dialControl/usePermissionsDialControl';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import {
  ContainerListQueues,
  ContentPanelQueues,
  ContentStatusQueues,
} from './styled';

export const ListQueuesManagement = ({ controlStage }) => {
  const { t } = useTranslation();
  const { permissionsCreate, isSysAdmin } = usePermissionsDialControl();
  const { loadingFlowQueue, loadingDataOperators } = useQueueManagement();
  const { loadingDataQueues } = useQueues();
  const [listQueues] = useAtom(dataQueues);
  const [filterQueue, setFilterQueue] = useState('');

  const [sortByStatus, setSortByStatus] = useState('');
  const [sortByQueue, setSortByQueue] = useState(false);

  useEffect(() => {
    setSortByStatus('0');
  }, [filterQueue]);

  const dataOptionsStatus = [
    {
      value: '0',
      name: t('dialing.option-allStatus'),
    },
    {
      value: '1',
      name: t('dialing.option-discing'),
    },
    {
      value: '2',
      name: t('dialing.option-empty'),
    },
    {
      value: '3',
      name: t('dialing.option-closed'),
    },
  ];

  const handleSortByQueue = () => setSortByQueue(!sortByQueue);

  const filterByQueue =
    listQueues && listQueues.filter((q) => q.fila === filterQueue);

  const renderQueues =
    listQueues && filterByQueue.length === 0 ? listQueues : filterByQueue;

  const filterByStatus = () => {
    switch (sortByStatus) {
      case 1:
        return renderQueues.filter((q) => q.estado === 'DISCANDO');
      case 2:
        return renderQueues.filter(
          (q) => q.estado === 'VAZIO' || q.estado === 'MANUTENCAO'
        );
      case 3:
        return renderQueues.filter(
          (q) => q.estado === 'FECHADO' || q.estado === 'PARADO'
        );
      default:
        return renderQueues;
    }
  };

  let orderQueue = !sortByQueue
    ? (a, b) => a.fila - b.fila
    : (a, b) => b.fila - a.fila;

  const renderQueuesSort = listQueues && filterByStatus().sort(orderQueue);

  const isLoading =
    loadingFlowQueue || loadingDataQueues || loadingDataOperators;

  return (
    <ContainerListQueues initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <ContentPanelQueues>
        <Stack direction={'row'} spacing={2}>
          <Box width={'80%'}>
            <SelectComponent
              labelSelect={t(
                'dialControl.queues.listQueues.label-filter-queue'
              )}
              value={filterQueue}
              onChange={(event) => setFilterQueue(event.target.value)}
            >
              <ItemSelect value={'0'}>
                {t('dialControl.queues.listQueues.options-all-queue')}
              </ItemSelect>
              {listQueues &&
                listQueues.map((item, i) => (
                  <ItemSelect value={item.fila} key={i}>
                    {item.fila}
                  </ItemSelect>
                ))}
            </SelectComponent>
          </Box>
          <Box width={'20%'}>
            <SelectComponent
              labelSelect={t('dialing.label-statusQueue')}
              value={sortByStatus}
              onChange={(event) => setSortByStatus(event.target.value)}
            >
              {dataOptionsStatus.map((item) => (
                <ItemSelect key={item.value} value={+item.value}>
                  {item.name}
                </ItemSelect>
              ))}
            </SelectComponent>
          </Box>
          <Button
            onClick={handleSortByQueue}
            title='Sort By Queue'
            sx={{ height: '42px' }}
          >
            {sortByQueue ? (
              <HiSortDescending size={20} />
            ) : (
              <HiSortAscending size={20} />
            )}
          </Button>
        </Stack>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <Button
            typeText
            variant='primary'
            onClick={() => controlStage.toStage(2)}
            disabled={!permissionsCreate && !isSysAdmin}
          >
            {t('dialControl.queues.listQueues.label-addQueue')}
          </Button>
        </Stack>
      </ContentPanelQueues>
      <ContentStatusQueues
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
      >
        {listQueues && renderQueuesSort.length > 0 ? (
          renderQueuesSort.map((item, i) => (
            <CardStatus
              key={i}
              dataQueue={item}
              changeFlowDialing={controlStage.toStage}
            />
          ))
        ) : (
          <NoDataStatus />
        )}
      </ContentStatusQueues>
    </ContainerListQueues>
  );
};
