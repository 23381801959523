import { Handle, Position } from 'reactflow';
import { useAtom } from 'jotai';
import React, { memo, useCallback } from 'react';
import { PiMonitorPlayFill } from 'react-icons/pi';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { shortNameRender } from '../../../../utils/shortNameRender';
import { FlexBoxCl, FlexBoxRow } from '../styled';
import { NodeContent, NodePlayFilesContainer } from './styled';
import { selectedNode, showMenuNodes } from '@/store/FlowBuilder';
import { PanelNode } from '@/components/pages/FlowBuilder/components/PanelNode';

const PlayFilesNodeComponent = ({ id, data, selected }) => {
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [, setSelectedNode] = useAtom(selectedNode);
  const { validInput, sounds } = data;

  const renderSoundsText = (text) => text && `...${text.slice(-20)}`;

  const handleNode = useCallback(() => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  }, [id, data, setSelectedNode, setShowMenu]);

  return (
    <NodePlayFilesContainer
      selected={selected}
      onDoubleClick={() => handleNode()}
    >
      <PanelNode data={data} id={id} />
      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <PiMonitorPlayFill />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
            <span>{data.handler}</span>
          </div>
        </div>
        <div className='main'>
          <FlexBoxRow>
            <span>Sounds: </span>
            <span>{renderSoundsText(sounds[0])}</span>
          </FlexBoxRow>
          <div className='dividerNode'></div>
          <FlexBoxRow>
            <span>Allow skip: </span>
            {data.allowSkip ? (
              <FlexBoxRow className='trueSkip'>
                <IoCheckmarkCircleOutline />
                <span>Yes</span>
              </FlexBoxRow>
            ) : (
              <FlexBoxRow className='falseSkip'>
                <IoIosCloseCircleOutline />
                <span>No</span>
              </FlexBoxRow>
            )}
          </FlexBoxRow>
          <div className='dividerNode'></div>
          <FlexBoxCl>
            <span>Valid Input: </span>
            <FlexBoxRow width={'100%'}>
              {[...Array(10).keys(), '#'].map((value) => (
                <span
                  key={value}
                  className={`itemInput ${
                    validInput?.includes(value.toString())
                      ? 'itemInputSelected'
                      : ''
                  }`}
                >
                  {value}
                </span>
              ))}
            </FlexBoxRow>
          </FlexBoxCl>
        </div>
      </NodeContent>
      <Handle type='source' position={Position.Right} />
    </NodePlayFilesContainer>
  );
};

export const PlayFilesNode = memo(PlayFilesNodeComponent);
