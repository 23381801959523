import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Item } from './Item';

export const TimeItemConditional = ({
  conditionsList,
  setConditionsList,
  handleRemoveCondition,
}) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      conditionsList,
      result.source.index,
      result.destination.index
    );

    setConditionsList(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className='contentItemsRender'
          >
            {conditionsList.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      width: snapshot.isDragging ? '280px' : '100%',
                    }}
                    key={item.id}
                  >
                    <Item
                      typeCondition={item.condition}
                      valueCondition={item.value}
                      handleRemoveCondition={() =>
                        handleRemoveCondition(item.id)
                      }
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
