import { useEffect, useId } from 'react';
import { useAtom } from 'jotai';
import { stateRenderRoutingProfiles } from '@store/UsersAgentsStore/routingProfiles';

import { ContainerRoutingProfile } from './styled';
import { HomeRoutingProfile } from './stages/HomeRoutingProfile';
import { AddRoutingProfile } from './stages/AddRoutingProfile';
import { ScreensRouting } from './stages/ScreensRouting';
import { EditRoutingProfile } from './stages/EditRoutingProfile';

export const RoutingProfile = () => {
  const [currentRenderRoutingProfile, setCurrentRenderRoutingProfile] = useAtom(
    stateRenderRoutingProfiles
  );
  useEffect(() => {
    return () => setCurrentRenderRoutingProfile({ current: 0, previous: 0 });
  }, []);

  const flowRenderRoutingProfile = [
    <HomeRoutingProfile key={useId()} />,
    <AddRoutingProfile
      key={useId()}
      handleBackRouting={() =>
        setCurrentRenderRoutingProfile({ current: 0, previous: 0 })
      }
    />,
    <EditRoutingProfile key={useId()} />,
    <ScreensRouting key={useId()} />,
  ];

  return (
    <ContainerRoutingProfile
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      stageRender={currentRenderRoutingProfile.current === 3}
    >
      {flowRenderRoutingProfile[currentRenderRoutingProfile.current]}
    </ContainerRoutingProfile>
  );
};
