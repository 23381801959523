import { useLayoutEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { MdClose, MdEdit } from 'react-icons/md';
import useWindowDimensions from '@/hooks/useWidth';
import { TablePagination } from '@/components/Tables/TablePagination';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { ConfirmationComponent } from '@/components/layout/ConfirmationComponent';
import { ContainerTableSchedules } from './styled';
import { dataScheduleQueueSelected } from '@/store/ScheduleStore/SchedulerQueue';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { usePermissionsSchedulerQueue } from '../../../hooks/usePermissionsSchedulerQueue';

export const TableSchedules = ({
  dataSchedule,
  controlStage,
  mutateDeleteScheduleQueue,
  loadingDeleteScheduleQueue,
}) => {
  const { t } = useTranslation();
  const { permissionsDelete, permissionsEdit } = usePermissionsSchedulerQueue();
  const { height } = useWindowDimensions();
  const tableSchedules = useRef(null);
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [dataSelected, setDataSelected] = useAtom(dataScheduleQueueSelected);

  const txtSchedules = (word) =>
    t(`dialControl.schedulesList.listSchedules.${word}`);

  const txtTableSchedules = (word) =>
    t(`dialControl.schedulesQueue.listSchedules.tableSchedules.${word}`);

  const daysOfWeek = [
    { title: txtSchedules('daysOfTheWeek.monday'), numberDay: 1 },
    { title: txtSchedules('daysOfTheWeek.tuesday'), numberDay: 2 },
    { title: txtSchedules('daysOfTheWeek.wednesday'), numberDay: 3 },
    { title: txtSchedules('daysOfTheWeek.thursday'), numberDay: 4 },
    { title: txtSchedules('daysOfTheWeek.friday'), numberDay: 5 },
    { title: txtSchedules('daysOfTheWeek.saturday'), numberDay: 6 },
    { title: txtSchedules('daysOfTheWeek.sunday'), numberDay: 0 },
  ];

  const renderDayOfWeek = (currentDays) => {
    if (!currentDays && currentDays !== 0) {
      return '-';
    }
    const filterWeekDay = daysOfWeek.filter(
      (day) => day.numberDay === Number(currentDays)
    );

    if (filterWeekDay.length === 0) {
      return '-';
    }

    return <span title={filterWeekDay[0].title}>{filterWeekDay[0].title}</span>;
  };

  const handleOpenConfirmation = (dataDelete) => {
    setDataSelected(dataDelete);
    return setConfirmationDelete(true);
  };

  const handleEditSchedule = (scheduleSelected) => {
    setDataSelected(scheduleSelected);
    return controlStage.toStage(3);
  };

  const handleDeleteSchedule = () => {
    const { id } = dataSelected;

    mutateDeleteScheduleQueue(id, {
      onError: () => {
        errorToast(t(`dialControl.schedulesQueue.deleteSchedules.msg-error`));
        setDataSelected(null);
      },
      onSuccess: () => {
        setConfirmationDelete(false);
        setDataSelected(null);
        successToast(
          t(`dialControl.schedulesQueue.deleteSchedules.msg-success`)
        );
      },
    });
  };

  const ColumnsCampaigns = [
    {
      Header: txtTableSchedules('th-first'),
      accessor: 'fila',
    },
    {
      Header: txtTableSchedules('th-second'),
      accessor: 'abre',
    },
    {
      Header: txtTableSchedules('th-third'),
      accessor: 'fecha',
    },
    {
      Header: txtTableSchedules('th-fourth'),
      accessor: 'pausa_ini',
    },
    {
      Header: txtTableSchedules('th-fifth'),
      accessor: 'pausa_fim',
    },
    {
      Header: txtTableSchedules('th-sixth'),
      accessor: 'dia_semana',
      Cell: ({ row }) => renderDayOfWeek(row.original.dia_semana),
    },
    {
      Header: txtTableSchedules('th-seventh'),
      Cell: ({ row }) => {
        return (
          <div className='btn-actions'>
            <Button
              onClick={() => handleEditSchedule(row.original)}
              disabled={!permissionsEdit}
            >
              <MdEdit />
            </Button>
            <Button
              onClick={() => handleOpenConfirmation(row.original)}
              variant='secondary'
              disabled={!permissionsDelete}
            >
              <MdClose />
            </Button>
          </div>
        );
      },
    },
  ];

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableSchedules) {
      const tableMaxWidth = tableSchedules.current.offsetHeight;
      const cellMinWidth = 60;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, tableSchedules]);

  return (
    <ContainerTableSchedules ref={tableSchedules}>
      {loadingDeleteScheduleQueue && <BackDropCircularLoading />}

      <TablePagination
        dataColumns={ColumnsCampaigns}
        dataTable={dataSchedule}
        textNoData={'No data Schedules'}
        rowsPerPageDisplay={heightTable}
      />
      <ConfirmationComponent
        actionConfirm={handleDeleteSchedule}
        messageConfirm={t(
          `dialControl.schedulesQueue.deleteSchedules.msgDelete`
        )}
        open={confirmationDelete}
        close={setConfirmationDelete}
      />
    </ContainerTableSchedules>
  );
};
