import { NodeComponent } from '.';

export const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
}) => (
  <g>
    <circle r={4}></circle>
    <foreignObject {...foreignObjectProps}>
      <NodeComponent nodeDatum={nodeDatum} toggleNode={toggleNode} />
    </foreignObject>
  </g>
);
