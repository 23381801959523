import { Highlight, themes } from 'prism-react-renderer';
import { Line, LineContent, LineNo, Pre } from './styled';

export const CodeRender = ({ dataRender, index = 1 }) => {
  return (
    <Highlight
      theme={themes.vsDark}
      code={JSON.stringify(dataRender ?? {}, null, 2)}
      language='tsx'
    >
      {({ tokens, getLineProps, getTokenProps }) => (
        <Pre index={index}>
          {tokens.map((line, i) => (
            <Line key={i} {...getLineProps({ line })}>
              <LineNo>{i + 1}</LineNo>
              <LineContent>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token })} />
                ))}
              </LineContent>
            </Line>
          ))}
        </Pre>
      )}
    </Highlight>
  );
};
