import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerMonitor = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 60px);
  padding: 3rem 2rem;
  overflow: scroll;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 700px) {
    padding: 2rem 1rem;
  }
`;
