import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isLoading, showForgotPass } from '@store/AuthenticatorStore';
import useAuth from '@/hooks/Authenticator/useAuth';

import {
  ContentSectionRight,
  FormContainerLogin,
  ForgetPassContainer,
} from './styled';

import { FormLogin } from './FormLogin';
import { FormForgatPass } from './FormForgetPass';
import { useEffect } from 'react';

export const RightSideLogin = () => {
  const variantsContentLogin = {
    initial: { opacity: 0 },
    visible: { opacity: 1 },
    hidden: { y: 20, opacity: 0, display: 'none' },
  };

  const variantsContentForgot = {
    initial: { opacity: 0, y: -50, display: 'none' },
    visible: { opacity: 1, y: 0, display: 'flex' },
  };

  const { signIn } = useAuth();
  const { t } = useTranslation();
  const [loadingHandleForm, setLoadingHandleForm] = useAtom(isLoading);

  const [showForgot, setShowForgot] = useAtom(showForgotPass);

  useEffect(() => {
    return () => setLoadingHandleForm(false);
  }, []);

  const loginSchema = yup.object().shape({
    email: yup.string().email().required(t('login.messages.errorFieldEmail')),
    password: yup.string().required(t('login.messages.errorFieldPass')),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const handleLogin = async (data) => {
    setLoadingHandleForm(true);
    signIn.mutate({ email: data.email, password: data.password });
  };

  const handleForgotPass = () => {
    reset();
    setShowForgot(true);
  };

  return (
    <ContentSectionRight>
      <ForgetPassContainer
        variants={variantsContentForgot}
        initial={'initial'}
        animate={showForgot ? 'visible' : 'initial'}
      >
        <FormForgatPass />
      </ForgetPassContainer>
      <FormContainerLogin
        variants={variantsContentLogin}
        initial={'initial'}
        animate={!showForgot ? 'visible' : 'hidden'}
      >
        <FormLogin
          handleLogin={handleSubmit(handleLogin)}
          controlForm={control}
          register={register}
          errorsFields={errors}
          isLoading={loadingHandleForm}
          handleForgotPass={handleForgotPass}
        />
      </FormContainerLogin>
    </ContentSectionRight>
  );
};
