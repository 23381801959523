import { useId, useState } from 'react';
import { HomeConfigTrunks } from './HomeConfigTrunks';
import { ManageTrunks } from '@/components/pages/FlowBuilder/stages/Configs/Trunks/ManageTrunks';

export const ConfigTrunks = ({ changeStateFlow }) => {
  const [stageConfigTrunks, setStageConfigTrunks] = useState(0);

  const controlStageNewFlow = {
    handleBackStage: () => changeStateFlow(3),
    handleNextStage: () => changeStateFlow(5),
  };

  const controlStageConfigTrunks = {
    toHomeTrunk: () => setStageConfigTrunks(0),
    toManagementTrunk: () => setStageConfigTrunks(1),
  };

  const flowConfigTrunks = [
    <HomeConfigTrunks
      key={useId()}
      controlStageNewFlow={controlStageNewFlow}
      handleToManagement={controlStageConfigTrunks.toManagementTrunk}
    />,
    <ManageTrunks
      key={useId()}
      handleBack={controlStageConfigTrunks.toHomeTrunk}
    />,
  ];

  return flowConfigTrunks[stageConfigTrunks];
};
