import { useAtom } from 'jotai';
import { useSecurityProfileRender } from '../../../../hooks/Navigator/useSecurityProfileRender';
import { sysAdmin, user } from '@/store/AuthenticatorStore';

export const usePermissionsFlow = () => {
  const { permissionComponent } = useSecurityProfileRender();
  const [isSysAdmin] = useAtom(sysAdmin);
  const [currentUser] = useAtom(user);

  const hasVoicceLabsClient =
    currentUser.client_id === '29904593-681a-4ad6-97c8-e26483468b73' &&
    isSysAdmin;

  const permissionsCreate = permissionComponent('create');
  const permissionsEdit = permissionComponent('update');
  const permissionsDelete = permissionComponent('delete');
  const permissionsDisplay = permissionComponent('display');

  return {
    permissionsCreate,
    permissionsEdit,
    permissionsDelete,
    permissionsDisplay,
    isSysAdmin,
    hasVoicceLabsClient,
  };
};
