import { apiData } from '../api';

export async function getInfoManagement(dataQueue) {
  try {
    const { queueNumber, nameCampaign } = dataQueue;
    const { data } = await apiData.post('dialer/queueInfoManager', {
      queue: queueNumber,
      campaign: nameCampaign,
    });

    return data;
  } catch (err) {
    throw new Error(err);
  }
}
