import { useEffect, useState } from 'react';
import {
  ContainerPaginator,
  PageItem,
  LineMarque,
  SpanText,
  ProgressBar,
  CurrentTextPage,
  ContainerHeader,
} from './styled';
import { useTranslation } from 'react-i18next';

import { HiCheckCircle } from 'react-icons/hi';

function FlowAddSchedule({ stageFlow }) {
  const { t } = useTranslation();

  function currentStage() {
    if (stageFlow === 1) {
      return '33.33%';
    } else if (stageFlow === 2) {
      return '66.33%';
    } else if (stageFlow === 3) {
      return '100%';
    } else {
      return '0';
    }
  }

  const [statusProgress, setStatusProgress] = useState('0');

  const [textStatus] = useState([
    t('dialControl.schedulesList.createSchedule.titleFlowSchedules.firstStep'),
    t('dialControl.schedulesList.createSchedule.titleFlowSchedules.secondStep'),
    t('dialControl.schedulesList.createSchedule.titleFlowSchedules.thirtyStep'),
    t('dialControl.schedulesList.createSchedule.titleFlowSchedules.fourthStep'),
  ]);

  useEffect(() => {
    setStatusProgress(currentStage);
  }, [stageFlow]);

  return (
    <ContainerHeader>
      <ContainerPaginator>
        <PageItem isActive>
          <SpanText isActive>{stageFlow >= 1 ? <HiCheckCircle /> : 1}</SpanText>
        </PageItem>
        <PageItem isActive={stageFlow >= 1 && true}>
          <SpanText isActive={stageFlow >= 1 && true}>
            {stageFlow >= 2 ? <HiCheckCircle /> : 2}
          </SpanText>
        </PageItem>
        <PageItem isActive={stageFlow >= 2 && true}>
          <SpanText isActive={stageFlow >= 2 && true}>
            {stageFlow >= 3 ? <HiCheckCircle /> : 3}
          </SpanText>
        </PageItem>
        <PageItem isActive={stageFlow >= 3 && true}>
          <SpanText isActive={stageFlow >= 3 && true}>
            {stageFlow === 3 ? <HiCheckCircle /> : 4}
          </SpanText>
        </PageItem>
        <LineMarque>
          <ProgressBar statusProgress={statusProgress} />
        </LineMarque>
      </ContainerPaginator>
      <CurrentTextPage>{textStatus[stageFlow]}</CurrentTextPage>
    </ContainerHeader>
  );
}

export { FlowAddSchedule };
