import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoCloseCircle } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import {
  ContainerStatusDispositionsMenu,
  ItemConfigDisposition,
} from './styled';

export const StatusDispositionSelected = ({
  currentDispositionSelected,
  dispositions,
}) => {
  const { t } = useTranslation();
  const renderConfigSelectedDisposition = dispositions.filter(
    (disposition) => disposition.name === currentDispositionSelected
  )[0];

  const propertyBlockList = ['id', 'cod_lig', 'descricao', 'name', 'value'];

  const renderListConfig = (selectedList) => {
    if (selectedList) {
      return Object.entries(selectedList).map(
        ([key, value], index) =>
          !propertyBlockList.includes(key) && (
            <ItemConfigDisposition key={index}>
              {value ? (
                <IoIosCheckmarkCircle className='usedItem' />
              ) : (
                <IoCloseCircle className='unusedItem' />
              )}
              <span className={value ? '' : 'unusedSpan'}>
                {t(
                  `flowBuilder.flow.nodes.dispositions.menu.listConfig.${key}`
                )}
              </span>
            </ItemConfigDisposition>
          )
      );
    }
    return '-';
  };

  return (
    <ContainerStatusDispositionsMenu>
      <div className=''>
        <h3>{t('flowBuilder.flow.nodes.dispositions.menu.label-code')}</h3>
        <span>{renderConfigSelectedDisposition?.cod_lig ?? '-'}</span>
      </div>
      <div className=''>
        <h3>
          {t('flowBuilder.flow.nodes.dispositions.menu.label-description')}
        </h3>
        <span>{renderConfigSelectedDisposition?.descricao ?? '-'}</span>
      </div>
      <div className='list-items-content'>
        <h3>{t('flowBuilder.flow.nodes.dispositions.menu.label-config')}</h3>
        <ul>{renderListConfig(renderConfigSelectedDisposition)}</ul>
      </div>
    </ContainerStatusDispositionsMenu>
  );
};
