import { errorToast } from '@/components/FormComponents/Toast';
import { createRenitency } from '@/services/dialControl/renitency/createRenitency';
import { deleteRenitency } from '@/services/dialControl/renitency/deleteRenitency';
import { getRenitency } from '@/services/dialControl/renitency/getRenitency';
import { updateRenitency } from '@/services/dialControl/renitency/updateRenitency';
import { currentStageRenitency, dataListRenitency } from '@/store/Renitency';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

export const useRenitency = () => {
  const { t } = useTranslation();
  const [, setListRenitency] = useAtom(dataListRenitency);
  const [currentStageReluctanceRules] = useAtom(currentStageRenitency);

  const {
    isLoading: loadingDataRenitency,
    refetch: refetchRenitency,
    isFetching: fetchingRenitency,
  } = useQuery(
    'getRenitency',
    async () => {
      return await getRenitency();
    },
    {
      enabled: currentStageReluctanceRules === 0,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => setListRenitency(data),
      onError: () =>
        errorToast(t('dialControl.renitency.list.messages.error-getList')),
    }
  );

  const { mutate: mutateUpdatedRenitency, isLoading: loadingUpdatedRenitency } =
    useMutation({
      mutationFn: async (dataUpdate) => {
        return await updateRenitency(dataUpdate);
      },
      onSuccess: () => refetchRenitency(),
      onError: () => {
        errorToast(t('dialControl.renitency.edit.messages.error-updated'));
      },
    });

  const { mutate: mutateCreateRenitency, isLoading: loadingCreateRenitency } =
    useMutation({
      mutationFn: async (dataUpdate) => {
        return await createRenitency(dataUpdate);
      },
      onSuccess: () => refetchRenitency(),
      onError: (error) => {
        const errorMessage = error.message || JSON.stringify(error);
        const toastMessage =
          errorMessage === 'This Rules Renitency already exists.'
            ? t('dialControl.renitency.create.messages.error-alreadyExists')
            : t('dialControl.renitency.create.messages.error-created');
        return errorToast(toastMessage);
      },
    });

  const { mutate: mutateDeleteRenitency, isLoading: loadingDeleteRenitency } =
    useMutation({
      mutationFn: async (dataDelete) => {
        return await deleteRenitency(dataDelete);
      },
      onSuccess: () => refetchRenitency(),
      onError: () => {
        errorToast(t('dialControl.renitency.list.messages.error-remove'));
      },
    });

  return {
    loadingDataRenitency,
    fetchingRenitency,
    mutateUpdatedRenitency,
    loadingUpdatedRenitency,
    mutateCreateRenitency,
    loadingCreateRenitency,
    mutateDeleteRenitency,
    loadingDeleteRenitency,
  };
};
