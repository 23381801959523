import { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { motion } from 'framer-motion';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { ContainerTable, ContainerTd, ContentTr, TableContent } from './styled';
import { NoDataTable } from './NoDataTable';

export const TableColorRow = ({
  dataColumns,
  dataTable,
  textNoData = '',
  useFooter = true,
  handleClickRow = false,
  selected = false,
}) => {
  const [dataTableState, setDataTableState] = useState([]);

  useEffect(() => {
    if (dataTable) setDataTableState(dataTable);
  }, [dataTable]);

  const columns = useMemo(() => dataColumns, [dataColumns]);
  const data = useMemo(() => dataTableState, [dataTableState]);

  const [sortBy, setSortBy] = useState([
    {
      id: 'fila',
      desc: true,
    },
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    setPageSize,
    state: { sortBy: tableSortBy },
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { sortBy },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setSortBy(tableSortBy);
  }, [tableSortBy]);

  useEffect(() => {
    setPageSize(1000);
  }, []);

  const renderTypeColorRow = (type) => {
    if (type.contato_positivo) {
      return 'positive-contact-row';
    }
    if (type.contato_efetivo) {
      return 'effective-contact-row';
    }

    if (type.fila === selected) {
      return 'selected-row';
    }
    return '';
  };

  const clickRow = (data) => {
    if (!handleClickRow) {
      return;
    }
    return handleClickRow(data);
  };

  return (
    <ContainerTable>
      <div className='content_table_grid'>
        <TableContent {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    key={i}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={column.render('Header')}
                  >
                    {column.Header !== 'Edit' ? (
                      <div className='content_th'>
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <IoMdArrowDropdown />
                          ) : (
                            <IoMdArrowDropup />
                          )
                        ) : (
                          <IoMdArrowDropup style={{ color: 'transparent' }} />
                        )}
                      </div>
                    ) : (
                      <div>{column.render('Header')}</div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, j) => {
                prepareRow(row);

                return (
                  <ContentTr
                    key={j}
                    {...row.getRowProps()}
                    className={renderTypeColorRow(row.original)}
                    onClick={() => clickRow(row.original)}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <ContainerTd
                          key={index}
                          {...cell.getCellProps()}
                          data-label={cell.column.Header}
                        >
                          <span>{cell.render('Cell')}</span>
                        </ContainerTd>
                      );
                    })}
                  </ContentTr>
                );
              })
            ) : (
              <NoDataTable textContent={textNoData} />
            )}
          </tbody>
          {useFooter && (
            <tfoot>
              {page.length > 0 &&
                footerGroups.map((footerGroup, index) => (
                  <motion.tr
                    key={index}
                    {...footerGroup.getFooterGroupProps()}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: page.length > 0 ? 1 : 0, y: 0 }}
                  >
                    {footerGroup.headers.map((column, index2) => (
                      <td key={index2} {...column.getFooterProps}>
                        {column.render('Footer')}
                      </td>
                    ))}
                  </motion.tr>
                ))}
            </tfoot>
          )}
        </TableContent>
      </div>
    </ContainerTable>
  );
};
