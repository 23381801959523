import { apiData } from '@/services/api';

export async function getUserById(idUser) {
  try {
    const { data } = await apiData.get(`/users/${idUser}`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
}
