import { stateLists, stateMailing, stateProgressBar } from '@/store/LoadList';
import { useAtom } from 'jotai';

export const useStageControlLists = () => {
  const [progress, setProgress] = useAtom(stateProgressBar);
  const [stateProgress, setStateProgress] = useAtom(stateLists);
  const [currentStateMailing, setCurrentStateMailing] = useAtom(stateMailing);

  return {
    currentStateMailing,
    setCurrentStateMailing,
    progress,
    setProgress,
    stateProgress,
    setStateProgress,
    toStageProgress: (stage) => setStateProgress(stage),
    nextProgress: () => setStateProgress((stateProgress) => stateProgress + 1),
    previousProgress: () =>
      setStateProgress((stateProgress) => stateProgress - 1),
  };
};
