import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const ContainerListActive = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 2rem;
`;

export const ContainerPanelList = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 1px 4px 10px -1px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 0.5rem;
  position: relative;

  .status_queue {
    span {
      color: ${(props) => props.theme.colors.typography.baseText};
      font-size: 0.875rem;

      strong {
        ${(props) =>
          !props.status
            ? css`
                color: ${props.theme.colors.primary};
              `
            : css`
                color: ${props.theme.colors[props.status]};
                text-transform: capitalize;
              `}
      }
    }

    svg {
      ${(props) =>
        !props.status
          ? css`
              color: ${props.theme.colors.primary};
            `
          : css`
              color: ${props.theme.colors[props.status]};
            `}
    }
  }
  .btn-add-content {
    position: absolute;
    right: 2%;
    bottom: -16px;

    button {
      border-radius: 500px;
      min-width: 0 !important;
      padding: 0.6rem !important;
      svg {
        font-size: 1.4rem;
      }
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  .icon_handle_back {
    svg {
      color: ${(props) => props.theme.colors.typography.baseTitle};
    }
  }

  .itemPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
    span {
      text-transform: uppercase;
      font-weight: 600;
      color: ${(props) => props.theme.colors.typography.baseTitle};
    }
  }

  .detail_queue {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.4em;

    @media (max-width: 900px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    span {
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.baseText};
      opacity: 0.6;
    }
  }

  .indicators_panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-left: 3rem;
  }
`;
