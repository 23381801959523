import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';

import { Stack } from '@mui/system';
import { useTheme } from 'styled-components';

const WaveFlowDtmf = ({ audio, setIsPlayingMessage }) => {
  const theme = useTheme();
  const containerRef = useRef();
  const waveSurferRef = useRef({
    isPlaying: () => false,
  });

  const [isPlaying, toggleIsPlaying] = useState(false);

  useEffect(() => {
    setIsPlayingMessage(isPlaying);
  }, [isPlaying]);

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      waveColor: theme.colors.containers.dividerColor,
      height: 30,
      progressColor: theme.colors.primary,
      cursorColor: theme.colors.primary,
      barWidth: 2,
      barGap: 1,
      barRadius: 2,
    });

    waveSurfer.load(audio);

    waveSurfer.on('ready', () => {
      waveSurferRef.current = waveSurfer;
      toggleIsPlaying(true);
      waveSurfer.play();
    });

    waveSurfer.on('finish', () => {
      toggleIsPlaying(false);
      waveSurfer.seekTo(0);
    });
    return () => {
      waveSurfer.destroy();
    };
  }, []);

  return (
    <Stack spacing={2} direction='column'>
      <div ref={waveSurferRef}></div>
      <div ref={containerRef} />
    </Stack>
  );
};

WaveFlowDtmf.propTypes = {
  audio: PropTypes.string.isRequired,
};

export default WaveFlowDtmf;
