import { Box } from '@mui/system';
import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { ContainerListCampaigns, ContentCampaigns } from './styled';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { NoDataCard } from '@/components/layout/NoDataCard';
import { useQueues } from '@/hooks/dialControl/Queues/useQueues';
import { dataQueues } from '@/store/Queues';
import { CardStatus } from './CardStatus';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const ListCampaigns = ({ controlStage }) => {
  const { t } = useTranslation();
  const { loadingDataQueues } = useQueues();
  const [listDataQueues] = useAtom(dataQueues);

  const [filterCampaign, setFilterCampaign] = useState('0');

  const filterCampaignList = () => {
    if (filterCampaign === '0') {
      return listDataQueues;
    }
    const filtered = listDataQueues.filter((data) =>
      data.campanha.toLowerCase().includes(filterCampaign.toLowerCase())
    );
    return filtered;
  };

  return (
    <ContainerListCampaigns>
      {loadingDataQueues && <BackDropCircularLoading />}
      <Box width={'100%'}>
        <SelectComponent
          labelSelect={t(
            'dialControl.lineControl.listCampaigns.label-filterCampaign'
          )}
          value={filterCampaign}
          onChange={(event) => setFilterCampaign(event.target.value)}
        >
          <ItemSelect value={'0'}>
            {t('dialControl.lineControl.listCampaigns.option-filterCampaign')}
          </ItemSelect>
          {listDataQueues &&
            listDataQueues.map((item, i) => (
              <ItemSelect value={item.campanha} key={i}>
                {item.campanha}
              </ItemSelect>
            ))}
        </SelectComponent>
      </Box>

      <ContentCampaigns
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
      >
        {listDataQueues && listDataQueues.length > 0 ? (
          filterCampaignList().map((item, i) => (
            <CardStatus
              key={i}
              dataQueue={item}
              changeFlowDialing={controlStage.toStage}
            />
          ))
        ) : (
          <NoDataCard />
        )}
      </ContentCampaigns>
    </ContainerListCampaigns>
  );
};
