import { useEffect, useState } from 'react';
import {
  ContainerPaginator,
  PageItem,
  LineMarque,
  SpanText,
  ProgressBar,
  CurrentTextPage,
  ContainerHeader,
  CheckedStage,
} from './styled';
import { useTranslation } from 'react-i18next';

import { FaCheck } from 'react-icons/fa6';

function NewFlowPaginator({ stageFlow }) {
  const { t } = useTranslation();

  function currentStage() {
    switch (stageFlow) {
      case 1:
        return '20%';
      case 2:
        return '40%';
      case 3:
        return '60%';
      case 4:
        return '80%';
      case 5:
        return '100%';
      default:
        return '0%';
    }
  }

  const [statusProgress, setStatusProgress] = useState('0');

  const [textStatus] = useState([
    t('flowBuilder.newFlow.stages.new.title'),
    t('flowBuilder.newFlow.stages.globalVars.title'),
    t('flowBuilder.newFlow.stages.codeLigs.title'),
    t('flowBuilder.newFlow.stages.nlus.title'),
    t('flowBuilder.newFlow.stages.trunks.title'),
    t('flowBuilder.newFlow.stages.complete.title'),
  ]);

  useEffect(() => {
    setStatusProgress(currentStage);
  }, [stageFlow]);

  return (
    <ContainerHeader>
      <ContainerPaginator>
        <PageItem isActive>
          {stageFlow >= 1 ? (
            <CheckedStage>
              <FaCheck />
            </CheckedStage>
          ) : (
            <SpanText>1</SpanText>
          )}
        </PageItem>
        <PageItem isActive={stageFlow >= 1 && true}>
          {stageFlow >= 2 ? (
            <CheckedStage>
              <FaCheck />
            </CheckedStage>
          ) : (
            <SpanText>2</SpanText>
          )}
        </PageItem>
        <PageItem isActive={stageFlow >= 2 && true}>
          {stageFlow >= 2 ? (
            <CheckedStage>
              <FaCheck />
            </CheckedStage>
          ) : (
            <SpanText>3</SpanText>
          )}
        </PageItem>
        <PageItem isActive={stageFlow >= 3 && true}>
          {stageFlow >= 3 ? (
            <CheckedStage>
              <FaCheck />
            </CheckedStage>
          ) : (
            <SpanText>4</SpanText>
          )}
        </PageItem>
        <PageItem isActive={stageFlow >= 4 && true}>
          {stageFlow >= 4 ? (
            <CheckedStage>
              <FaCheck />
            </CheckedStage>
          ) : (
            <SpanText>5</SpanText>
          )}
        </PageItem>
        <PageItem isActive={stageFlow >= 5 && true}>
          {stageFlow >= 5 ? (
            <CheckedStage>
              <FaCheck />
            </CheckedStage>
          ) : (
            <SpanText>6</SpanText>
          )}
        </PageItem>
        <LineMarque>
          <ProgressBar statusProgress={statusProgress} />
        </LineMarque>
      </ContainerPaginator>
      <CurrentTextPage>{textStatus[stageFlow]}</CurrentTextPage>
    </ContainerHeader>
  );
}

export { NewFlowPaginator };
