import * as yup from 'yup';

export const editCampaignSchema = yup.object().shape({
  nameGroup: yup.string().required(),
  description: yup.string().required(),

  busyMaximumNumberAttempts: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),

  busyIntervalBetweenCalls: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),

  answeringMachineMaximumNumberAttempts: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),
  answeringMachineIntervalBetweenCalls: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),

  doesNotAttendMaximumNumberAttempts: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),
  doesNotAttendIntervalBetweenCalls: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),

  noInteractionMaximumNumberAttempts: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),
  noInteractionIntervalBetweenCalls: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),

  invalidNumberMaximumNumberAttempts: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),
  invalidNumberIntervalBetweenCalls: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),

  systemicFinishMaximumNumberAttempts: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),
  systemicFinishIntervalBetweenCalls: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),

  nonSystemicTerminationMaximumNumberAttempts: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),
  nonSystemicTerminationIntervalBetweenCalls: yup
    .number()
    .typeError('Deve ser um número')
    .required()
    .test(
      'is-number',
      'Deve ser um número',
      (value) => typeof value === 'number' && !isNaN(value)
    ),

  attempt_1: yup.number(),
  attempt_2: yup.number(),
  attempt_3: yup.number(),
  attempt_4: yup.number(),
  attempt_5: yup.number(),
  attempt_6: yup.number(),
  attempt_7: yup.number(),
  attempt_8: yup.number(),
  attempt_9: yup.number(),
  attempt_10: yup.number(),
});
