import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { ContainerListActivityLog, ContentHttpCode } from './styled';
import { ExpandedTable } from '../ExpandedTable';
import { renderDate } from '@/utils/formatsDateAndTime';

export const ListActivityLog = ({ isLoading, dataList }) => {
  const { t } = useTranslation();

  const tableActivityLog = useRef(null);

  const renderHttpCodeTable = (httpCode) => {
    if (!httpCode) {
      return;
    }
    return <ContentHttpCode typeCode={httpCode}>{httpCode}</ContentHttpCode>;
  };

  const columnsActivityLog = [
    {
      Header: t('activityLog.table.th-user'),
      accessor: 'user',
    },
    {
      Header: t('activityLog.table.th-client'),
      accessor: 'client',
    },
    {
      Header: t('activityLog.table.th-address'),
      accessor: 'address',
    },
    {
      Header: t('activityLog.table.th-route'),
      accessor: 'route',
      Cell: ({ row }) => {
        return (
          <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
            {renderHttpCodeTable(row.original.httpCode)}
            <span>{row.original.route}</span>
          </Stack>
        );
      },
    },
    {
      Header: t('activityLog.table.th-date'),
      accessor: 'date',
      Cell: ({ row }) => {
        return <span>{renderDate(row.original.date)}</span>;
      },
    },
  ];

  return (
    <ContainerListActivityLog ref={tableActivityLog}>
      <ExpandedTable
        dataColumns={columnsActivityLog}
        dataTable={dataList}
        textNoData={
          isLoading
            ? t('activityLog.table.th-load-data')
            : t('activityLog.table.no-data')
        }
      />
    </ContainerListActivityLog>
  );
};
