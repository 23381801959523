import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { Box, Stack } from '@mui/system';
import { BsBoxes } from 'react-icons/bs';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { IoMdSave } from 'react-icons/io';
import { renderDate } from '@/utils/formatsDateAndTime';
import { useSaveFlow } from '@/components/pages/FlowBuilder/hooks/useSaveFlow';
import { ConfirmationComponent } from '@/components/layout/ConfirmationComponent';
import { useHandleFlow } from '@/hooks/FlowBuilder/useHandlerFlow';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { shortNameRender } from '../../../../utils/shortNameRender';
import { ConfirmationToSave } from '../../NavigationFlow/ConfirmationToSave';
import { usePermissionsFlow } from '@/components/pages/FlowBuilder/hooks/usePermissionsFlow';
import { user } from '@/store/AuthenticatorStore';
import { DetailFlow } from './detailFlow';
import { useUser } from '@/hooks/UserAgents/useUser';
import {
  Badge,
  CircularProgress,
  Tooltip,
  Button as ButtonMui,
  Zoom,
  Skeleton,
} from '@mui/material';
import {
  confirmationChangeFlow,
  hasModalOpen,
  isSavedFlow,
  listTemplates,
  stageFlow,
  templateSelected,
} from '@/store/FlowBuilder';
import {
  ContainerHeaderFlow,
  MenuItemHistory,
  MenuItemPublished,
  SelectNav,
} from './styled';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { useRenderDataFlow } from '@/components/pages/FlowBuilder/hooks/useRenderDataFlow';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const HeaderFlow = ({ dataNodes }) => {
  const { t } = useTranslation();
  const { permissionsEdit, permissionsCreate, isSysAdmin } =
    usePermissionsFlow();

  const {
    mutateSetStatusTemplate,
    mutateImportFlow,
    loadingSetStatus,
    loadingImportFlow,
  } = useHandleFlow();

  const { handleRenderNodes, loadingHandleFlow, checkErrorsNodes } =
    useSaveFlow(dataNodes);

  const { renderFlowFromHist } = useRenderDataFlow();

  const { renderLastUpdatedUserFlow } = useUser();
  const [isSaved, setIsSaved] = useAtom(isSavedFlow);
  const [template] = useAtom(templateSelected);
  const [listTemplate] = useAtom(listTemplates);
  const [confirmationChangedFlow, setConfirmationChangedFlow] = useAtom(
    confirmationChangeFlow
  );

  const [currentUser] = useAtom(user);

  const [confirmationDialogSaveFlow, setConfirmationDialogSaveFlow] =
    useState(false);
  const [countSave, setCountSave] = useState(0);
  const [idRenderHistoric, setIdRenderHistoric] = useState('');
  const [statusFlow, setStatusFlow] = useState(false);
  const [nameFlow, setNameFlow] = useState('Name');
  const [historicFlow, setHistoricFlow] = useState('0');
  const [idFlow, setIdFlow] = useState('#');

  const [, setModalIsOpened] = useAtom(hasModalOpen);

  const [, setCurrentStage] = useAtom(stageFlow);

  useEffect(() => {
    if (template) {
      setTimeout(() => {
        setIsSaved(true);
      }, 200);
    }

    return () => {
      clearTimeout();
    };
  }, [template]);

  const checkStatus = (dataStatus) => {
    if (dataStatus) {
      return 'published';
    }
    return 'publish';
  };

  useEffect(() => {
    if (Object.entries(template).length > 0) {
      setNameFlow(template.nameIVR);
      setIdFlow(template._id);
      setStatusFlow(checkStatus(template.published ?? false));
    }
  }, [template]);

  const renderListTemplateByName = ((listTemplate, nameRendered) => {
    const separateNameTemplate = listTemplate
      .filter((item) => item.nameIVR === nameRendered)
      .sort((a, b) => b.lastUpdate - a.lastUpdate);

    return separateNameTemplate;
  })(listTemplate, template.nameIVR);

  useEffect(() => {
    if (countSave === 0) {
      const listOptions = renderListTemplateByName
        .filter((item) => item.published)
        .map((item) => item.relatedObjectId)
        .toString();

      if (listOptions === '') {
        const currentListOptions = renderListTemplateByName.map(
          (item) => item.relatedObjectId
        );

        return currentListOptions.length > 0
          ? setHistoricFlow(currentListOptions[0].toString())
          : setHistoricFlow('0');
      }

      return setHistoricFlow(
        renderListTemplateByName
          .filter((item) => item.published)
          .map((item) => item.relatedObjectId)
          .toString()
      );
    } else {
      setHistoricFlow(template._id);
    }
  }, [listTemplate, countSave]);

  useEffect(() => {
    return () => {
      setNameFlow('');
      setIdFlow('');
      setStatusFlow(false);
    };
  }, []);

  const processSaveOnChangeFlow = () => {
    mutateImportFlow(idRenderHistoric, {
      onSuccess: (data) => {
        setHistoricFlow(data._id);
        renderLastUpdatedUserFlow(data.user_id);
        renderFlowFromHist(data, dataNodes);
        setIsSaved(true);
        setConfirmationChangedFlow(false);
      },
    });
  };

  const validationHistoricFlow = (value) => {
    setIdRenderHistoric(value);

    if (!isSaved) {
      return setConfirmationChangedFlow(true);
    }

    mutateImportFlow(value, {
      onSuccess: (data) => {
        renderLastUpdatedUserFlow(data.user_id);
        setHistoricFlow(data._id);
        renderFlowFromHist(data, dataNodes);
        setIsSaved(true);
      },
    });
  };

  const handlePublished = () => {
    const checkPersona = dataNodes.nodes.filter(
      (item) => item.type === 'dialplanVariables'
    );

    if (checkPersona.length === 0) {
      errorToast(t('flowBuilder.flow.msg-error-noPersona'));
      return;
    }

    const dataStatus = {
      id: template._id,
      nameIVR: template.nameIVR,
      last_user_published: currentUser.client_id,
    };

    mutateSetStatusTemplate(dataStatus, {
      onSuccess: () => {
        mutateImportFlow(template._id);
        successToast(t('flowBuilder.flow.msg-flow-published'));
        setIsSaved(true);
      },
    });
  };

  const confirmationSaveFlow = () => {
    const isNoNull = checkErrorsNodes();
    if (!isNoNull) {
      return;
    }
    setModalIsOpened(true);
    dataNodes.removeSelectedOnSaveTemplate();
    return setConfirmationDialogSaveFlow(true);
  };

  const closeConfirmationSaveFlow = () => {
    setModalIsOpened(false);
    setConfirmationDialogSaveFlow(false);
  };

  const handleSave = (description, isSameCustomer) => {
    const save = handleRenderNodes(description);
    if (save && !isSameCustomer) {
      setCurrentStage(0);
      setCountSave((countSave) => countSave + 1);
      return;
    }
    return setCountSave((countSave) => countSave + 1);
  };

  const permissionsUser = permissionsCreate || permissionsEdit || isSysAdmin;

  const hasDisabledBtnPub =
    template.published ||
    !isSaved ||
    dataNodes.edges.length === 0 ||
    dataNodes.nodes.length <= 1 ||
    historicFlow === '0' ||
    historicFlow === '' ||
    loadingHandleFlow ||
    loadingSetStatus ||
    loadingImportFlow ||
    !permissionsUser;

  const isLoading = loadingHandleFlow || loadingSetStatus || loadingImportFlow;

  return (
    <ContainerHeaderFlow
      status={statusFlow}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingHandleFlow ||
        loadingSetStatus ||
        (loadingImportFlow && <div className='disabled-cursor'></div>)}
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={1}
        className='container-title'
      >
        <BsBoxes />
        <div className='contentTitleHeader'>
          <h4>{shortNameRender(nameFlow, 50)}</h4>
          <span>{idFlow}</span>
        </div>

        <Tooltip TransitionComponent={Zoom} arrow title={<DetailFlow />}>
          <ButtonMui>
            <HiOutlineInformationCircle />
          </ButtonMui>
        </Tooltip>
      </Stack>

      <div className='content-status-flow'>
        <span>
          {statusFlow === 'published'
            ? t('flowBuilder.flow.navigationFlow.status-card-flow-published')
            : t('flowBuilder.flow.navigationFlow.status-card-flow-publish')}
        </span>
      </div>

      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={1}
        justifyContent={'center'}
      >
        <Box width={'300px'}>
          {!isLoading ? (
            <SelectNav
              size='small'
              value={historicFlow}
              onChange={(e) => validationHistoricFlow(e.target.value)}
              disabled={
                loadingHandleFlow ||
                loadingSetStatus ||
                loadingImportFlow ||
                !permissionsUser
              }
            >
              <ItemSelect value={'0'} disabled>
                {t('flowBuilder.flow.navigationFlow.label-historic-flow')}
              </ItemSelect>

              {renderListTemplateByName.map((item, i) =>
                item.published ? (
                  <MenuItemPublished value={item.relatedObjectId} key={i}>
                    <span>{renderDate(item.lastUpdate)} - </span>
                    <span>
                      {shortNameRender(
                        !item.descriptionIVR || item.descriptionIVR === ''
                          ? t(
                              'flowBuilder.flow.navigationFlow.option-no-description'
                            )
                          : item.descriptionIVR,
                        30
                      )}
                    </span>
                  </MenuItemPublished>
                ) : (
                  <MenuItemHistory value={item.relatedObjectId} key={i}>
                    <span>{renderDate(item.lastUpdate)} - </span>
                    <span>
                      {shortNameRender(
                        !item.descriptionIVR || item.descriptionIVR === ''
                          ? t(
                              'flowBuilder.flow.navigationFlow.option-no-description'
                            )
                          : item.descriptionIVR,
                        30
                      )}
                    </span>
                  </MenuItemHistory>
                )
              )}
            </SelectNav>
          ) : (
            <Skeleton
              variant='rectangular'
              width={'100%'}
              height={'29px'}
              sx={{ borderRadius: '4px' }}
            />
          )}
        </Box>
        <div className='btn-current-flow'>
          <Badge
            color='error'
            variant='dot'
            invisible={isSaved}
            badgeContent=''
          >
            <Button
              variant='success'
              onClick={confirmationSaveFlow}
              disabled={
                isSaved ||
                loadingHandleFlow ||
                loadingSetStatus ||
                loadingImportFlow ||
                !permissionsUser
              }
            >
              {loadingHandleFlow || loadingImportFlow ? (
                <CircularProgress size={20} />
              ) : (
                <IoMdSave size={20} />
              )}
            </Button>
          </Badge>
          <Button
            typeText
            disabled={hasDisabledBtnPub}
            onClick={handlePublished}
            className='btn-publish'
          >
            {loadingSetStatus ? (
              <CircularProgress size={18} sx={{ color: '#fff' }} />
            ) : (
              <span
                style={{
                  paddingTop: '3px',
                }}
              >
                {t('flowBuilder.flow.navigationFlow.status-card-flow-publish')}
              </span>
            )}
          </Button>
        </div>
      </Stack>

      <ConfirmationComponent
        actionConfirm={processSaveOnChangeFlow}
        close={() => setConfirmationChangedFlow(false)}
        txtBtnSuccess={'OK'}
        messageConfirm={t('flowBuilder.flow.msg-confirmation-exit')}
        open={confirmationChangedFlow}
      />
      <ConfirmationToSave
        actionConfirm={handleSave}
        close={closeConfirmationSaveFlow}
        open={confirmationDialogSaveFlow}
      />
    </ContainerHeaderFlow>
  );
};
