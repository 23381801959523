import { useMemo, useState } from 'react';
import { Alert, Stack } from '@mui/material';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { DialogComponent } from '@/components/DialogComponent';
import { ContainerDialogErrorsRows, ContainerErrorsList } from './styled';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { TableStyledComponent } from '@/components/Tables/CustomTable';

export const ErrorsList = ({ errorsRows, hasErrorColumnsFromCsv }) => {
  const { t } = useTranslation();

  const dataRows = useMemo(
    () => (errorsRows.length ? errorsRows.sort((a, b) => a.line - b.line) : []),
    [errorsRows]
  );

  const [openDialogErrorsRows, setOpenDialogErrorsRows] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialogErrorsRows(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogErrorsRows(false);
  };

  return (
    <ContainerErrorsList>
      <button
        onClick={handleOpenDialog}
        aria-hidden={openDialogErrorsRows}
        disabled={openDialogErrorsRows}
        tabIndex={openDialogErrorsRows ? -1 : 0}
      >
        <RiErrorWarningLine />
      </button>

      <DialogComponent
        open={openDialogErrorsRows}
        onClose={handleCloseDialog}
        fullWidth={true}
      >
        <ContainerDialogErrorsRows>
          <div className='header'>
            <TitleComponent
              textTitle={t(
                'listLoader.importMailing.flow.errorList.title-errors'
              )}
            />

            {hasErrorColumnsFromCsv && (
              <Alert
                severity='error'
                sx={{ width: '100%', fontSize: '0.8rem', lineHeight: '22px' }}
              >
                {t('listLoader.importMailing.flow.errorList.error-column-csv')}
              </Alert>
            )}
          </div>
          <div className='main'>
            {dataRows.length > 0 && (
              <TableStyledComponent>
                <thead>
                  <tr>
                    <th>
                      {t('listLoader.importMailing.flow.errorList.th-line')}
                    </th>
                    <th>
                      {t('listLoader.importMailing.flow.errorList.th-error')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataRows.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            spacing={'10px'}
                          >
                            <RiErrorWarningLine />
                            <span>{row.line + 1}</span>
                          </Stack>
                        </td>
                        <td>
                          <span>
                            {t(
                              'listLoader.importMailing.flow.errorList.td-error'
                            )}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </TableStyledComponent>
            )}
          </div>
          <div className='footer'>
            <Button typeText type='button' onClick={handleCloseDialog}>
              Ok
            </Button>
          </div>
        </ContainerDialogErrorsRows>
      </DialogComponent>
    </ContainerErrorsList>
  );
};
