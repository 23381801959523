import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { ContainerQueueMonitor } from './styled';
import { useSocketQueueMonitor } from './hooks/useSocketQueueMonitor';
import { PanelQueuesMonitor } from './components/PanelQueuesMonitor';
import { QueuesTable } from './components/QueuesTable';
import { DetailQueues } from './components/DetailsQueue';
import { selectedQueueMonitor } from '@/store/QueueMonitor';
import { useQueuesMonitor } from './hooks/useQueuesMonitor';

export const QueueMonitor = () => {
  const [fieldFilter, setFieldFilter] = useState('');
  const { filterQueuesMonitor } = useQueuesMonitor();
  const [, setSelectedQueue] = useAtom(selectedQueueMonitor);

  const { dataQueueMonitor, isConnected, hasErrorSocket } =
    useSocketQueueMonitor();

  useEffect(() => {
    setSelectedQueue(null);
  }, [fieldFilter]);

  useEffect(() => {
    return () => {
      setSelectedQueue(null);
      setFieldFilter('');
    };
  }, []);

  const isLoading =
    (!isConnected || dataQueueMonitor.length === 0) && !hasErrorSocket;

  return (
    <ContainerQueueMonitor initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}

      <PanelQueuesMonitor
        fieldFilter={fieldFilter}
        setFieldFilter={setFieldFilter}
        hasErrorSocket={hasErrorSocket}
      />
      <DetailQueues
        dataList={filterQueuesMonitor(dataQueueMonitor, fieldFilter)}
      />
      <QueuesTable
        dataTable={filterQueuesMonitor(dataQueueMonitor, fieldFilter)}
        isLoading={isLoading}
      />
    </ContainerQueueMonitor>
  );
};
