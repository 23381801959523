import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { StyledItem } from './styledItems';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';

export const Item = ({ text, index, handleToSelectedList, colSelected }) => {
  return (
    <Draggable draggableId={text} index={index}>
      {(provided) => (
        <StyledItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onDoubleClick={() => handleToSelectedList(text, colSelected)}
        >
          {text}

          <div className='icon-item'>
            {colSelected === 'available' ? <HiArrowRight /> : <HiArrowLeft />}
          </div>
        </StyledItem>
      )}
    </Draggable>
  );
};
