import { useId } from 'react';
import { ContainerUfManager } from './styled';
import { EditGroupUF } from './stages/EditGroupUF';
import { useAtom } from 'jotai';
import { stageUFManager } from '@/store/DialingStore';
import { ListUfs } from './stages/ListUfs';

export const UfManager = () => {
  const [currentStageUf, setCurrentStageUf] = useAtom(stageUFManager);

  const controlStage = {
    previousStage: () =>
      setCurrentStageUf((currentStageUf) => currentStageUf - 1),
    nextStage: () => setCurrentStageUf((currentStageUf) => currentStageUf + 1),
    toStage: (stage) => setCurrentStageUf(stage),
    currentStage: currentStageUf,
  };

  const flowUfs = [
    <ListUfs key={useId()} controlStage={controlStage} />,
    <EditGroupUF key={useId()} controlStage={controlStage} />,
  ];

  return <ContainerUfManager>{flowUfs[currentStageUf]}</ContainerUfManager>;
};
