import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerHomeSecurityProfile = styled(motion.div)`
  width: 100%;
  height: calc(100vh);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;

  /*   @media (max-width: 1620px) {
    gap: 2rem;
  } */

  .content_btn_new_security_profile {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;
