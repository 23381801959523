import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PasswordChecklist from 'react-password-checklist';
import { useMutation } from 'react-query';

import { Button } from '@components/FormComponents/ButtonComponent';
import { InputComponent } from '@components/FormComponents/InputComponent';
import { TitleComponent } from '@components/layout/TitleComponent';

import imgLogo from '@/assets/imgs/lg_voiccelabs_white.webp';

import { ContainerChangePassword, FormModalNewPass } from './styled';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { changePassword } from '@/services/sessions/forgotPassword';
import { LoadingComponent } from '@/components/layout/LoadingComponent';

export const ChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { token } = useParams();

  useEffect(() => {
    window.localStorage.setItem('theme', 'light');
  }, []);

  useEffect(() => {
    if (!token) {
      return navigate('/');
    }
  }, []);

  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [isValidNewPass, setIsValidNewPass] = useState(false);

  const isValidateForm = password === passwordAgain && isValidNewPass;

  const { mutate, isLoading } = useMutation({
    mutationFn: (dataChange) => {
      return changePassword(dataChange);
    },
    retry: false,
    onSuccess: () => {
      setPasswordAgain('');
      setPassword('');
      successToast(t('forgotPass.messages.successMessageForgotPass'));
      navigate('/');
    },
    onError: (error) => {
      setPasswordAgain('');
      setPassword('');
      const errorMessage = error.message || JSON.stringify(error);
      const toastMessage =
        errorMessage === 'Token invalid!'
          ? t('forgotPass.messages.errorMessageForgotPassTokenInvalid')
          : t('forgotPass.messages.errorMessageForgotPass');
      return errorToast(toastMessage);
    },
  });

  const handleSubmitNewPass = (event) => {
    event.preventDefault();

    if (!isValidateForm) {
      errorToast(t('forgotPass.messages.errorIdenticFields'));
      return;
    }

    const dataChange = {
      idForgot: token,
      newPass: password,
    };
    mutate(dataChange);
  };

  return (
    <ContainerChangePassword>
      {isLoading && <LoadingComponent />}
      <div className='logo_pass'>
        <img src={imgLogo} alt='' />
      </div>
      <div className='main_pass'>
        <FormModalNewPass onSubmit={handleSubmitNewPass}>
          <TitleComponent
            textTitle={t('newPass.title-newPass')}
            iconTitle='user'
          />

          <main>
            <div className='info_pass'>
              <p>{t('newPass.firstSentence')} </p>

              <p>
                {t('newPass.secondSentence')}
                <br />
                <strong>{t('newPass.thirdSentence')}</strong>
              </p>
            </div>
            <InputComponent
              fullWidth
              type='password'
              id='newPass'
              label={t('newPass.label-newPass')}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <InputComponent
              fullWidth
              type='password'
              id='confirmNewPass'
              size='small'
              label={t('newPass.label-confirmPass')}
              value={passwordAgain}
              onChange={(event) => setPasswordAgain(event.target.value)}
            />
            <div className='content_validation_pass'>
              <h3>{t('newPass.fourthSentence')}</h3>
              <PasswordChecklist
                className='validation_new_input'
                rules={[
                  'minLength',
                  'specialChar',
                  'number',
                  'capital',
                  'lowercase',
                ]}
                minLength={8}
                value={password}
                onChange={(isValid) => {
                  setIsValidNewPass(isValid);
                }}
                messages={{
                  minLength: t('newPass.firstItemVerifyPass'),
                  specialChar: t('newPass.secondItemVerifyPass'),
                  number: t('newPass.thirdItemVerifyPass'),
                  lowercase: t('newPass.fourthItemVerifyPass'),
                  capital: t('newPass.fifthItemVerifyPass'),
                }}
              />
            </div>
          </main>

          <footer>
            <Button
              typeText
              variant='secondary'
              type='button'
              onClick={() => navigate('/')}
            >
              {t('newPass.btn-cancel')}
            </Button>
            <Button typeText type='submit' disabled={!isValidateForm}>
              {t('newPass.btn-apply')}
            </Button>
          </footer>
        </FormModalNewPass>
      </div>
    </ContainerChangePassword>
  );
};
