import { Controller } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { useId } from 'react';
import { ContainerBtnControlInput, StyledInput } from './styled';

export const InputComponent = ({ ...rest }) => {
  return (
    <StyledInput
      {...rest}
      variant='outlined'
      fullWidth
      size='small'
      id={useId()}
    />
  );
};

export const ControlInput = ({
  control,
  nameControl,
  defaultValue = '',
  ...rest
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={nameControl}
      render={({ field: { onChange, value } }) => (
        <InputComponent
          {...rest}
          size='small'
          value={value}
          onChange={onChange}
          autoComplete='off'
        />
      )}
    />
  );
};

export const ControlInputMask = ({
  control,
  nameControl,
  defaultValue = '',
  mask = '',
  ...rest
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={nameControl}
      render={({ field: { onChange, value } }) => (
        <InputMask
          {...rest}
          mask={mask}
          maskChar={null}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {(inputProps) => (
            <InputComponent
              {...inputProps}
              size='small'
              autoComplete='off'
              variant='outlined'
            />
          )}
        </InputMask>
      )}
    />
  );
};

export const ControlInputNumber = ({
  control,
  nameControl,
  defaultValue = '',
  ...rest
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={nameControl}
      render={({ field: { onChange, value } }) => (
        <InputComponent
          {...rest}
          size='small'
          value={value}
          onChange={(e) => {
            const newValue = e.target.value;
            if (newValue === '') {
              onChange('0');
            } else if (/^-?\d*\.?\d*$/.test(newValue)) {
              onChange(newValue);
            } else {
              onChange('0');
            }
          }}
          autoComplete='off'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <ContainerBtnControlInput>
                  <button
                    aria-label='Increment value'
                    type='button'
                    onClick={() =>
                      onChange((parseFloat(value) + 0.01).toFixed(2))
                    }
                  >
                    <MdArrowDropUp />
                  </button>

                  <button
                    aria-label='Decrement value'
                    type='button'
                    onClick={() => {
                      const newValue = parseFloat(value) - 0.01;
                      if (newValue >= 0) {
                        onChange(newValue.toFixed(2));
                      }
                    }}
                  >
                    <MdArrowDropDown />
                  </button>
                </ContainerBtnControlInput>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
