import styled from 'styled-components';

export const ContainerCustomFunction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;

  .ace_layer {
    .ace_identifier {
      all: unset !important;
    }

    .ace_type {
      all: unset !important;
    }
    .ace_entity,
    .ace_bracket,
    .ace_start {
      all: unset !important;
    }
  }

  .case-content {
    display: flex;
    flex-direction: row;
    gap: 10px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      background-color: ${(props) => props.theme.colors.active};
      border: none;
      border-radius: 2px;
      span {
        padding-top: 3px;
        color: ${(props) => props.theme.colors.defaultColors.white};
      }
    }
  }

  .active-case {
    background-color: ${(props) => props.theme.colors.primary} !important;
  }

  .input-content {
    width: 100%;
    background-color: ${(props) =>
      props.theme.colors.containers.baseContainerFlow};
    padding: 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    span {
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.baseText};
      &:first-child {
        font-size: 0.85rem;
      }
    }
  }

  .result-content {
    width: 100%;
    min-height: 50px;
    background-color: ${(props) =>
      props.errorString
        ? props.theme.colors.defaultColors.orange
        : props.theme.colors.defaultColors.green}AA;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    span {
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.baseText};
      &:first-child {
        font-size: 0.85rem;
      }
    }
  }
`;
