import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerResetPassword = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;

  .mainContent {
    width: 100%;
    p {
      font-size: 1rem;
      color: ${(props) => props.theme.colors.typography.baseText};
      margin-bottom: 10px;
    }
    span {
      font-size: 1rem;
      color: ${(props) => props.theme.colors.typography.baseText};
      font-style: normal;
    }
  }

  .footerContent {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
`;
