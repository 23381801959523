import { useCallback, useState } from 'react';
import { useAtom } from 'jotai';
import { TableSchedules } from './TableSchedules';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useSchedulesList } from '@/hooks/dialControl/Schedules/useSchedulesList';

import { PanelFilter } from './PanelFilter';
import { ContentSchedule } from './styled';
import { schedulesList } from '@/store/ScheduleStore/SchedulerList';
import { NoDataCard } from './ListCardsSchedules/NoDataCard';

export const Schedules = ({ controlStage }) => {
  const { loadingSchedulesList, mutateDeleteSchedule, loadingDeleteSchedule } =
    useSchedulesList();

  const [scheduleList] = useAtom(schedulesList);
  const [filterQueue, setFilterQueue] = useState('');
  const [selectedDaysFilter, setSelectedDaysFilter] = useState([]);

  const filterByDay = useCallback(() => {
    if (selectedDaysFilter.length === 0) {
      return scheduleList.sort((a, b) => a.dia_semana - b.dia_semana);
    }
    const filterDay = selectedDaysFilter
      .map((daySelected) => {
        const filterListByWeekDay = scheduleList.filter(
          (item) => item.dia_semana === daySelected
        );
        return filterListByWeekDay.length ? filterListByWeekDay : null;
      })
      .filter((item) => Boolean(item));
    return filterDay.flat().sort((a, b) => a.dia_semana - b.dia_semana);
  }, [selectedDaysFilter, scheduleList]);

  const handleNewSchema = () => {
    controlStage.toStage(2);
  };

  const isLoading = loadingSchedulesList || loadingDeleteSchedule;

  return (
    <ContentSchedule initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}

      <PanelFilter
        handleNewSchema={handleNewSchema}
        scheduleList={scheduleList}
        controlStage={() => controlStage.toStage(0)}
        filters={{
          filterQueue,
          setFilterQueue,
          selectedDaysFilter,
          setSelectedDaysFilter,
        }}
      />

      <div className='main'>
        {filterByDay().length === 0 ? (
          <NoDataCard controlStage={handleNewSchema} />
        ) : (
          <TableSchedules
            dataSchedule={filterByDay()}
            controlStage={controlStage}
            deleteSelectedSchedule={mutateDeleteSchedule}
          />
        )}
      </div>
    </ContentSchedule>
  );
};
