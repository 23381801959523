import { motion } from 'framer-motion';
import { FaArrowLeft } from 'react-icons/fa';
import { useAtom } from 'jotai';
import { InputAdornment, Stack } from '@mui/material';
import { PanelLineControl } from './styled';
import { selectedListCampaign } from '@/store/ListCampaigns';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { BiSearch } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

export const PanelSelectedLine = ({
  controlMainStage,
  filterList,
  setFilterList,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useAtom(selectedListCampaign);

  const handleBackMainStage = () => {
    setSelected(null);
    controlMainStage.toStage(0);
  };

  return (
    <PanelLineControl>
      <Stack direction='column' spacing={2}>
        <Stack direction='row' spacing={2}>
          <motion.div
            whileTap={{ scale: 0.8 }}
            className='icon_handle_back'
            onClick={handleBackMainStage}
          >
            <FaArrowLeft size='14' />
          </motion.div>
          <div className='itemPanel'>
            <span>
              <strong>{selected?.name ?? '-'}</strong>
            </span>
          </div>
        </Stack>
        <InputComponent
          placeholder={t(
            `dialControl.listCampaign.selectedList.placeHolder-filterList`
          )}
          value={filterList}
          onChange={(e) => setFilterList(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <BiSearch />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </PanelLineControl>
  );
};
