import styled, { css } from 'styled-components';

export const ContentHeaderCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left-info {
    flex: 1;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    color: white;
    font-size: 0.875rem;

    .title_row {
      font-size: 0.85rem;
      text-transform: uppercase;

      @media (max-width: 500px) {
        font-size: 0.8rem;
      }
      @media (max-width: 362px) {
        font-size: 0.6rem;
      }
    }

    .status-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      line-height: 12px;
      ${(props) =>
        props.status === 0 &&
        css`
          color: #47fa6b;
        `};

      ${(props) =>
        props.status === 1 &&
        css`
          color: #ffab52;
        `};

      ${(props) =>
        props.status === 2 &&
        css`
          color: #9eb8ff;
        `};

      font-size: 0.7rem;

      gap: 6px;

      span {
        padding-top: 2px;
      }
      @media (max-width: 320px) {
        font-size: 0.6rem;
      }

      svg {
        transform: rotate(90deg);
        font-size: 0.46rem;
        padding-bottom: 1px;
      }
      @media (max-width: 320px) {
        display: none;
      }
    }
  }

  .right-info {
    width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 500px) {
      width: auto;
    }

    .talking-header,
    .dialing-header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 4px;

      svg {
        font-size: 0.85rem;
      }
    }

    .inf-agent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: white;
      font-size: 0.7rem;
      text-transform: uppercase;
      gap: 2px;

      @media (max-width: 500px) {
        font-size: 0.8rem;
      }

      img {
        @media (max-width: 320px) {
          display: none;
        }
      }
    }
  }
`;
