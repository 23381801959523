import styled from 'styled-components';

export const ContainerTable = styled.div`
  width: 100%;
  position: relative;
  flex: 1;
  max-height: calc(100vh - 330px);
  @media (max-width: 1550px) {
    max-height: calc(100vh - 320px);
  }
  min-height: 200px;
  overflow-x: auto;
  table {
    thead {
      tr {
        user-select: none;
        position: relative;

        th {
          div {
            font-size: 0.7rem;
          }
        }
      }
    }
    tbody {
      tr {
        height: 44px;
        td {
          z-index: 1;
          padding: 0 0.8rem;

          :not(:first-child) {
            width: 7%;
            min-width: 60px;
          }

          :nth-child(1) {
            width: 30px;
          }

          :nth-child(2) {
            width: 30%;
            min-width: 120px;
            font-size: 0.68rem;
          }

          :last-child {
            width: 20px !important;
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    margin-top: 0.675rem;
    max-height: calc(100vh - 300px);
    table tbody,
    table tr,
    table td {
      display: block;
      width: 100%;
    }

    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          margin-bottom: 12px;
          width: 100%;
          min-width: 100%;
          height: auto;
          padding: 1rem 0;
          border-left: 3px solid ${(props) => props.theme.colors.primary};

          td {
            padding-left: 50%;
            text-align: left;
            position: relative;
            padding-bottom: 6px;

            span {
              font-size: 12px;
            }
            &::before {
              content: attr(data-label) ':';
              position: absolute;
              left: 0;
              width: 50%;
              padding-left: 15px;
              text-align: left;
              font-size: 12px;
              color: ${(props) => props.theme.colors.typography.baseText};
              line-height: 1.2;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;
