import { useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';

const AudioWaveform = ({ audioUrl }) => {
  const waveformRef = useRef(null);

  useEffect(() => {
    // Cria uma instância do WaveSurfer
    const wavesurfer = WaveSurfer.create({
      container: waveformRef.current,
    });

    wavesurfer.load(audioUrl);

    wavesurfer.on('ready', () => {
      wavesurfer.play();
    });

    return () => {
      wavesurfer.destroy();
    };
  }, [audioUrl]);

  return <div ref={waveformRef} />;
};

export default AudioWaveform;
