import { useEffect, useState } from 'react';
import {
  ContainerPaginator,
  PageItem,
  LineMarque,
  SpanText,
  ProgressBar,
  CurrentTextPage,
  ContainerHeader,
} from './styled';
import { useTranslation } from 'react-i18next';

import { HiCheckCircle } from 'react-icons/hi';

function PaginatorFlow({ stageFlow, hasCreateCampaign }) {
  const { t } = useTranslation();

  function currentStage() {
    if (stageFlow === 1) {
      return '25%';
    } else if (stageFlow === 2) {
      return '50%';
    } else if (stageFlow === 3) {
      return '75%';
    } else if (stageFlow === 4) {
      return '100%';
    } else {
      return '0';
    }
  }

  const [statusProgress, setStatusProgress] = useState('0');

  const [textStatus] = useState([
    t('listLoader.steps.firstStep'),
    t('listLoader.steps.secondStep'),
    t('listLoader.steps.thirdStep'),
    t('listLoader.steps.fourthStep'),
    t('listLoader.steps.fifthStep'),
  ]);

  useEffect(() => {
    setStatusProgress(currentStage);
  }, [stageFlow]);

  return (
    <ContainerHeader>
      <ContainerPaginator>
        <PageItem isActive>
          <SpanText isActive>{stageFlow >= 1 ? <HiCheckCircle /> : 1}</SpanText>
        </PageItem>
        <PageItem isActive={stageFlow >= 1 && true}>
          <SpanText isActive={stageFlow >= 1 && true}>
            {stageFlow >= 2 ? <HiCheckCircle /> : 2}
          </SpanText>
        </PageItem>
        <PageItem isActive={stageFlow >= 2 && true}>
          <SpanText isActive={stageFlow >= 2 && true}>
            {stageFlow >= 3 ? <HiCheckCircle /> : 3}
          </SpanText>
        </PageItem>
        <PageItem isActive={stageFlow >= 3 && true}>
          <SpanText isActive={stageFlow >= 3 && true}>
            {stageFlow >= 4 ? <HiCheckCircle /> : 4}
          </SpanText>
        </PageItem>
        <PageItem isActive={stageFlow >= 4 && true}>
          <SpanText isActive={stageFlow >= 4 && true}>
            {stageFlow === 4 ? <HiCheckCircle /> : 5}
          </SpanText>
        </PageItem>
        <LineMarque>
          <ProgressBar statusProgress={statusProgress} />
        </LineMarque>
      </ContainerPaginator>
      <CurrentTextPage>
        {hasCreateCampaign
          ? t('listLoader.steps.createCampaign')
          : textStatus[stageFlow]}
      </CurrentTextPage>
    </ContainerHeader>
  );
}

export { PaginatorFlow };
