import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { getCampaigns } from '@/services/dialControl/campaigns/getCampaigns';
import { dataListCampaigns, dataUsedSequenceList } from '@/store/Campaigns';
import { createCampaign } from '@/services/dialControl/campaigns/createCampaign';
import { deleteCampaign } from '@/services/dialControl/campaigns/deleteCampaign';
import { updateCampaign } from '@/services/dialControl/campaigns/updateCampaign';
import { updateDispositionsCampaign } from '@/services/dialControl/campaigns/updateDispositionsCampaign';
import { getDetailCampaign } from '@/services/dialControl/campaigns/getDetailCampaign';

export const useCampaigns = (stageCampaign = 0) => {
  const { t } = useTranslation();
  const [, setDataCampaigns] = useAtom(dataListCampaigns);
  const [, setUsedSequenceList] = useAtom(dataUsedSequenceList);

  const { isLoading: loadingDataCampaigns, refetch: refetchCampaigns } =
    useQuery(
      'getCampaigns',
      async () => {
        return await getCampaigns();
      },
      {
        enabled: stageCampaign === 0,
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: (data) => {
          setUsedSequenceList(data.map((item) => item.sequencial_lista));
          setDataCampaigns(data);
        },
        onError: () =>
          errorToast(t('dialControl.campaigns.listCampaigns.error-getLists')),
      }
    );

  const { mutate: mutateCreateCampaign, isLoading: loadingCreateCampaign } =
    useMutation({
      mutationFn: async (dataCreateCampaign) => {
        return await createCampaign(dataCreateCampaign);
      },
      onSuccess: () => {
        refetchCampaigns();
      },
      onError: (error) => {
        const errorMessage = error.message || JSON.stringify(error);
        const toastMessage =
          errorMessage === 'This Campaign already exists.'
            ? t(
                'dialControl.campaigns.createCampaign.errosInputs.error-usedCampaignName'
              )
            : t(
                'dialControl.campaigns.createCampaign.errosInputs.msg-error-create'
              );
        return errorToast(toastMessage);
      },
    });

  const { mutate: mutateUpdateCampaign, isLoading: loadingUpdateCampaign } =
    useMutation({
      mutationFn: async (dataUpdateCampaign) => {
        return await updateCampaign(dataUpdateCampaign);
      },
      onError: (error) => {
        const errorMessage = error.message || JSON.stringify(error);
        const toastMessage =
          errorMessage === 'This Campaign already exists.'
            ? t(
                'dialControl.campaigns.createCampaign.errosInputs.error-usedCampaignName'
              )
            : t(
                'dialControl.campaigns.createCampaign.errosInputs.msg-error-updated'
              );
        return errorToast(toastMessage);
      },
    });

  const { mutate: mutateRemoveCampaign, isLoading: loadingRemoveCampaign } =
    useMutation({
      mutationFn: async (dataRemoveCampaign) => {
        return await deleteCampaign(dataRemoveCampaign);
      },
      onSuccess: () => {
        refetchCampaigns();
      },
      onError: () => {
        errorToast(
          t('dialControl.campaigns.createCampaign.errosInputs.msg-error-delete')
        );
      },
    });

  const {
    mutate: mutateSendDispositionsCampaign,
    isLoading: loadingSendDispositionsCampaign,
  } = useMutation({
    mutationFn: async (dataDispositionsCampaign) => {
      return await updateDispositionsCampaign(dataDispositionsCampaign);
    },
    onError: () => {
      errorToast(
        t('dialControl.campaigns.updateCampaigns.msg-error-updateCampaigns')
      );
    },
  });

  const { mutate: mutateDetailCampaign, isLoading: loadingDetailCampaign } =
    useMutation({
      mutationFn: async (dataCampaign) => {
        return await getDetailCampaign(dataCampaign);
      },
      onError: () => {
        errorToast(
          t('dialControl.campaigns.updateCampaigns.msg-error-getDetails')
        );
      },
    });

  return {
    loadingDataCampaigns,
    mutateRemoveCampaign,
    loadingRemoveCampaign,
    mutateCreateCampaign,
    loadingCreateCampaign,
    mutateUpdateCampaign,
    loadingUpdateCampaign,

    mutateSendDispositionsCampaign,
    loadingSendDispositionsCampaign,

    mutateDetailCampaign,
    loadingDetailCampaign,
  };
};
