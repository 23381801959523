import { useEffect, useId, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Switch } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { ConditionItem, ContentAddCondition, LabelSwitch } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { focusedFieldArea } from '@/store/FlowBuilder';
import { errorToast } from '@/components/FormComponents/Toast';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';
import { FlexContainer } from '@/components/layout/FlexContainer/styled';

const label = { inputProps: { 'aria-label': 'Size switch demo' } };

export const FieldConditions = ({ dataConditions }) => {
  const { t } = useTranslation();
  const {
    conditionsList,
    setConditionsList,
    conditionSelected,
    setConditionSelected,
    dataOptionsConditions,
  } = dataConditions;

  const dataValuesConditions = [
    {
      name: t('flowBuilder.flow.nodes.conditions.menu.label-condition-equals'),
      symbol: '=',
    },
    {
      name: t(
        'flowBuilder.flow.nodes.conditions.menu.label-condition-lessThan'
      ),
      symbol: '<',
    },
    {
      name: t(
        'flowBuilder.flow.nodes.conditions.menu.label-condition-lessOrEqual'
      ),
      symbol: '<=',
    },
    {
      name: t(
        'flowBuilder.flow.nodes.conditions.menu.label-condition-greaterThan'
      ),
      symbol: '>',
    },
    {
      name: t(
        'flowBuilder.flow.nodes.conditions.menu.label-condition-greaterOrEqual'
      ),
      symbol: '>=',
    },
    {
      name: t(
        'flowBuilder.flow.nodes.conditions.menu.label-condition-notEqual'
      ),
      symbol: '!=',
    },
    {
      name: t(
        'flowBuilder.flow.nodes.conditions.menu.label-condition-contains'
      ),
      symbol: '**',
    },
  ];

  const [, setFocusedArea] = useAtom(focusedFieldArea);

  const [conditionItem, setConditionItem] = useState('');
  const [conditionValue, setConditionValue] = useState('');
  const [conditionVar, setConditionVar] = useState('');

  const [typeValueVar, setTypeValueVar] = useState(false);
  const [, setValueConditionError] = useState('');
  const [valueItemListError, setValueItemListError] = useState('');

  useEffect(() => {
    typeValueVar ? setConditionValue('') : setConditionVar('');
  }, [typeValueVar]);

  useEffect(() => {
    return () => {
      setConditionItem('');
      setConditionValue('');
      setConditionSelected({});
    };
  }, []);

  const validateValueCondition = (value) => {
    if (!value.trim()) {
      setValueConditionError(
        t('flowBuilder.flow.nodes.conditions.menu.msg-value-required')
      );
    } else if (value.length > 30) {
      setValueConditionError(
        t('flowBuilder.flow.nodes.conditions.menu.msg-name-length', {
          length: '30',
        })
      );
    } else {
      setValueConditionError('');
    }
    setConditionValue(value);
  };

  const validateListCondition = (value) => {
    if (!value.trim()) {
      setValueItemListError(
        t('flowBuilder.flow.nodes.conditions.menu.msg-condition-required')
      );
    } else {
      setValueItemListError('');
    }
    setConditionItem(value);
  };

  const hasSelected = Object.keys(conditionSelected).length !== 0;

  const checkValueVar =
    (typeValueVar && conditionVar !== '') ||
    (!typeValueVar && conditionValue !== '');

  useEffect(() => {
    if (hasSelected) {
      setConditionValue(conditionSelected.value);
      setConditionItem(conditionSelected.condition);
    }
  }, [conditionSelected, dataConditions]);

  const handleNewCondition = () => {
    if (!conditionItem || conditionItem === '' || !checkValueVar) {
      setValueConditionError(
        t('flowBuilder.flow.nodes.conditions.menu.msg-value-required')
      );
      setValueItemListError(
        t('flowBuilder.flow.nodes.conditions.menu.msg-condition-required')
      );
      return;
    }

    const checkExistCondition = conditionsList.filter(
      (item) =>
        item.condition === conditionItem && item.value === conditionValue
    );

    if (checkExistCondition.length > 0) {
      return errorToast(
        t('flowBuilder.flow.nodes.conditions.menu.msg-condition-existing')
      );
    }

    const newCondition = {
      id: uuid(),
      condition: conditionItem,
      value: typeValueVar ? conditionVar : conditionValue,
    };

    setConditionsList((items) => [...items, newCondition]);
    setConditionItem('');
    setConditionValue('');
    setConditionSelected({});
  };

  const dataListOptions = [...dataOptionsConditions].filter(
    (item) => item.name !== 'CURRENT_TIME'
  );

  return (
    <ContentAddCondition>
      <h4>
        {t('flowBuilder.flow.nodes.conditions.menu.title-areaConditions')}
      </h4>
      <SelectComponent
        defaultValue='0'
        labelSelect={t(
          'flowBuilder.flow.nodes.conditions.menu.label-condition'
        )}
        value={conditionItem}
        onChange={(e) => validateListCondition(e.target.value)}
        error={Boolean(valueItemListError)}
      >
        <ItemSelect value={'0'} disabled>
          {t('flowBuilder.flow.nodes.conditions.menu.option-condition')}
        </ItemSelect>
        {dataValuesConditions.map((item, i) => (
          <ConditionItem key={i} value={item.symbol}>
            {item.name} <span>{item.symbol}</span>
          </ConditionItem>
        ))}
      </SelectComponent>

      {typeValueVar ? (
        <SelectComponent
          defaultValue='0'
          labelSelect={t(
            'flowBuilder.flow.nodes.conditions.menu.label-varsValue'
          )}
          value={conditionVar}
          onChange={(e) => setConditionVar(e.target.value)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
          error={Boolean(valueItemListError)}
        >
          <ItemSelect value={'0'} disabled>
            {t('flowBuilder.flow.nodes.conditions.menu.option-varsValue')}
          </ItemSelect>
          {dataListOptions.map((item, i) => (
            <ConditionItem key={i} value={item.name}>
              {item.name}
            </ConditionItem>
          ))}
        </SelectComponent>
      ) : (
        <InputComponent
          label={t('flowBuilder.flow.nodes.conditions.menu.label-textValue')}
          value={conditionValue}
          autoComplete='off'
          onChange={(e) => validateValueCondition(e.target.value)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
          className={'input__value_var'}
          error={Boolean(valueItemListError)}
        />
      )}

      <FlexContainer justify={'flex-end'} width={'100%'}>
        <FlexContainer
          direction={'row'}
          width={'100%'}
          justify={'space-between'}
          gap={'1rem'}
        >
          <div className='content__use_vars'>
            <Switch
              {...label}
              value={typeValueVar}
              onChange={(e) => setTypeValueVar(e.target.checked)}
              size='small'
              id={useId()}
            />
            <LabelSwitch typeValueVar={typeValueVar ? 1 : 0}>
              {t(
                'flowBuilder.flow.nodes.conditions.menu.label-typeValueCondition'
              )}
            </LabelSwitch>
          </div>
          <div>
            <Button
              type='button'
              typeText
              onClick={handleNewCondition}
              title={t('flowBuilder.flow.nodes.conditions.menu.btn-add')}
              disabled={conditionItem === '' || !checkValueVar}
            >
              {t('flowBuilder.flow.nodes.conditions.menu.btn-add')}
            </Button>
          </div>
        </FlexContainer>
      </FlexContainer>
    </ContentAddCondition>
  );
};
