import { hasSavedTemplateMailing } from '@/store/LoadList';
import { useAtom } from 'jotai';
import React from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
} from 'reactflow';
import { useTheme } from 'styled-components';

export default function SystemEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  selected,
}) {
  const theme = useTheme();
  const [, setSaveFlowMailing] = useAtom(hasSavedTemplateMailing);
  const { setEdges } = useReactFlow();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onEdgeClick = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
    setSaveFlowMailing(false);
  };

  return (
    <>
      <BaseEdge
        path={edgePath}
        style={{
          ...style,
          stroke: theme.colors.listNodes.bgSystemEdge,
          strokeWidth: selected ? 2 : 1,
        }}
      ></BaseEdge>

      {selected && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              pointerEvents: 'all',
            }}
            className='nodrag nopan'
          >
            <button className='edgebutton' onClick={onEdgeClick}>
              <span>x</span>
            </button>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}
