import styled from 'styled-components';

export const ContainerModalLogout = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
  padding: 1.8rem 1.2rem 1.2rem;

  .header_modal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.8rem;
    h2 {
      font-weight: 600;
      color: ${(props) => props.theme.colors.error};
      font-size: 1.2rem;
      padding-top: 3px;
    }

    svg {
      color: ${(props) => props.theme.colors.error};
      font-size: 1.3rem;
    }
    margin-bottom: 1rem;
  }

  .content_modal {
    p {
      color: #000000;
      font-size: 0.9rem;
    }
  }

  .footer_modal {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }
`;
