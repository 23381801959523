import * as yup from 'yup';
import { useAtom } from 'jotai';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import useFormControl from '@/hooks/useFormControl';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { user } from '@/store/AuthenticatorStore';
import { dataListClients } from '@/store/Clients';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { successToast } from '@/components/FormComponents/Toast';
import {
  FooterCreateGroup,
  FormCreateGroupUf,
  MainCreateGroup,
} from './styled';
import { ItemSelect } from '@/components/FormComponents/SelectInput/ItemSelect';

export const CreateGroupUf = ({ controlStage, dataReqCreate }) => {
  const { t } = useTranslation();
  const { mutateCreateGroupUF, loadingCreateGroupUF } = dataReqCreate;

  const [currentUser] = useAtom(user);
  const [listClients] = useAtom(dataListClients);

  const isVoicceLabsClient =
    currentUser.client_id === '29904593-681a-4ad6-97c8-e26483468b73';

  const renderText = (spell) => {
    return t(`dialControl.ufManager.create.${spell}`);
  };

  const handleBackGroup = () => {
    controlStage.handleClose();
  };

  const addUfManageSchema = yup.object().shape({
    description: yup.string().required(),
    client_id: yup.string().required(),
  });

  const { handleSubmit, control, errors, reset } =
    useFormControl(addUfManageSchema);

  const handleCreateGroupUf = (data) => {
    const dataCreate = {
      descricao: data.description,
      id_cliente: data.client_id,
    };
    mutateCreateGroupUF(dataCreate, {
      onSuccess: () => {
        successToast(renderText('msg-success-created'));
        handleBackGroup();
        reset();
      },
    });
  };

  return (
    <FormCreateGroupUf
      onSubmit={handleSubmit(handleCreateGroupUf)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingCreateGroupUF && <BackDropCircularLoading />}
      <MainCreateGroup>
        <TitleComponent textTitle='Add Group UF' iconTitle='addCampaign' />
        <DescriptionInformation textDescription={renderText('description')} />
        <Stack width={'100%'} direction={'column'} spacing={2}>
          <ControlSelect
            control={control}
            nameControl={'client_id'}
            defaultValue={currentUser.client_id}
            label={renderText('label-client')}
            error={Boolean(errors.client_id)}
            disabled={!isVoicceLabsClient}
          >
            <ItemSelect value={'0'} disabled>
              {t('flowBuilder.exportFlow.option-client-export')}
            </ItemSelect>
            {listClients.length > 0 &&
              listClients.map((item) => (
                <ItemSelect value={item.id} key={item.id}>
                  {item.commercial_name}
                </ItemSelect>
              ))}
          </ControlSelect>
          <ControlInput
            control={control}
            nameControl={'description'}
            label={renderText('label-description')}
            error={Boolean(errors.description)}
          />
        </Stack>
      </MainCreateGroup>
      <FooterCreateGroup>
        <Button typeText variant={'secondary'} onClick={handleBackGroup}>
          {renderText('btn-cancel')}
        </Button>
        <Button typeText type='submit'>
          {renderText('btn-create')}
        </Button>
      </FooterCreateGroup>
    </FormCreateGroupUf>
  );
};
