import styled from 'styled-components';
import { ContainerNodeWrapper, ContentNodeWrapper } from '../styled';

export const NodeTabulationContainer = styled(ContainerNodeWrapper)`
  background: #ff6924;

  .react-flow__handle {
    &::after {
      background: #ff6924;
    }
  }
  .react-flow__handle-right {
    right: -8px;
  }
`;

export const NodeContent = styled(ContentNodeWrapper)`
  width: 160px;

  .header {
    background: #ff6924;
  }
`;
