import { useId, useState } from 'react';
import { HomeConfigGlobalVars } from './HomeConfigGlobalVars';
import { ManagementGlobalVariables } from '@/components/pages/FlowBuilder/stages/Configs/GlobalVariables/ManagementGlobalVariables';

export const ConfigVars = ({ changeStateFlow }) => {
  const [stageConfigGlobalVars, setStageConfigGlobalVars] = useState(0);

  const controlStageNewFlow = {
    handleBackStage: () => changeStateFlow(0),
    handleNextStage: () => changeStateFlow(2),
  };

  const controlStageConfigGlobalVars = {
    toHomeGlobalVars: () => setStageConfigGlobalVars(0),
    toManagementGlobalVars: () => setStageConfigGlobalVars(1),
  };

  const flowConfigGlobalVars = [
    <HomeConfigGlobalVars
      key={useId()}
      controlStageNewFlow={controlStageNewFlow}
      handleToManagement={controlStageConfigGlobalVars.toManagementGlobalVars}
    />,
    <ManagementGlobalVariables
      key={useId()}
      controlStage={controlStageConfigGlobalVars.toHomeGlobalVars}
    />,
  ];

  return flowConfigGlobalVars[stageConfigGlobalVars];
};
