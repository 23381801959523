import { apiData } from '../api';

export async function getOutDialer(dataClose) {
  try {
    const { data } = await apiData.post('/agent/getOutDialer', {
      extension: dataClose.extension,
    });
    return data.data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
