import { errorToast } from '@/components/FormComponents/Toast';
import { getListsCountInListService } from '@/services/reports/countInList/getListsCountInListService';
import { dataListsByClient } from '@/store/DialingStore';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export const useListsByClient = () => {
  const { t } = useTranslation();
  const [listsByClient, setListsDialing] = useAtom(dataListsByClient);

  const { isLoading: loadingListsByClient } = useQuery(
    'getListsByClient',
    async () => {
      return await getListsCountInListService();
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setListsDialing(data);
      },
      onError: () => errorToast(t('reports.countInList.msg-error-getLists')),
    }
  );

  return { loadingListsByClient, listsByClient };
};
