import { apiData } from '../api';

export async function saveCurrentTemplate(dataUpdate, signal) {
  try {
    const data = await apiData.post('dialer/loadLists', dataUpdate, {
      signal,
    });
    return data.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
