import { ContainerConfigMailing } from './styled';
import { Stack } from '@mui/system';
import { DetailsTemplate } from '../../../components/DetailsFlow';
import { ConfigFlow } from '../../../components/ConfigFlow';
import { SaveTemplate } from '../../../components/SaveTemplateMailing';
import { HeadersCsvFlow } from '../../../components/HeadersCsvFlow';

export const NavigationListFlow = ({ dataNodes }) => {
  return (
    <ContainerConfigMailing>
      <Stack spacing={1}>
        <HeadersCsvFlow />
        <ConfigFlow dataNodes={dataNodes} />
        <DetailsTemplate />
        <SaveTemplate />
      </Stack>
    </ContainerConfigMailing>
  );
};
