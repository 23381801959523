import { AgentsMonitorContainer, ContentGrid, NoDataGrid } from './styled.js';

import { AgentMonitorGrid } from './AgentMonitorGrid';
import { AgentsList } from './AgentsList/index.jsx';
import { useTranslation } from 'react-i18next';
import { RiErrorWarningLine } from 'react-icons/ri';

export const AgentsMonitor = ({ dataList, ordination }) => {
  const { t } = useTranslation();
  return (
    <AgentsMonitorContainer
      id='container'
      ordination={ordination.ordinationType !== 'layoutList' ? 'row' : 'column'}
    >
      {ordination.ordinationType !== 'layoutList' ? (
        <ContentGrid>
          {dataList && dataList.length > 0 ? (
            dataList.map((agent) => {
              return <AgentMonitorGrid key={agent.id} itemAgent={agent} />;
            })
          ) : (
            <NoDataGrid>
              <RiErrorWarningLine />
              <span>{t('monitor.noDataMonitor')}</span>
            </NoDataGrid>
          )}
        </ContentGrid>
      ) : (
        <AgentsList data={dataList} />
      )}
    </AgentsMonitorContainer>
  );
};
