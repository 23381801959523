import { useId, useState } from 'react';
import { HomeScreensRouting } from './HomeScreensRouting';
import { ContainerScreensRouting } from './styled';
import { AddScreenRouting } from './AddScreenRouting';

export const ScreensRouting = ({ origin = false }) => {
  const [stageEditScreen, setStageEditScreen] = useState(0);

  const handleChange = (stage) => {
    setStageEditScreen(stage);
  };

  const flowEditScreens = [
    <HomeScreensRouting
      key={useId()}
      handleChange={handleChange}
      origin={origin}
    />,
    <AddScreenRouting
      key={useId()}
      handleChange={handleChange}
      origin={origin}
    />,
  ];

  return (
    <ContainerScreensRouting>
      {flowEditScreens[stageEditScreen]}
    </ContainerScreensRouting>
  );
};
