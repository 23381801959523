import styled from 'styled-components';

export const ContainerLoadList = styled.div`
  width: 100%;
  height: calc(100vh - 46px);
  padding: 0rem 2rem 2rem 2rem;
  position: relative;
  overflow: scroll;

  display: grid;
  grid-template-rows: 130px 1fr;
  gap: 1rem;

  @media (max-height: 400px) {
    height: calc(100vh - 60px);
  }
`;

export const ContentLoadingMailing = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PanelLoadList = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
`;

export const MainLoadList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ScheduleArea = styled.div``;

export const FooterLoadList = styled.div`
  display: flex;
  justify-content: flex-end;
`;
