import { toast } from 'react-toastify';
import { ToastContainer } from './styled';

export const errorToast = (messageError) =>
  toast.error(
    <ToastContainer>
      <span> {messageError}</span>
    </ToastContainer>,
    {
      autoClose: 2000,
      pauseOnHover: false,
      closeOnClick: true,
      className: 'toast',
    }
  );

export const warningToast = (messageWarning) =>
  toast.warning(
    <div className='toast_css'>
      <span>{messageWarning}</span>
    </div>,
    { autoClose: 2000, pauseOnHover: false, closeOnClick: true }
  );

export const successToast = (messageSuccess) =>
  toast.success(
    <div className='toast_css'>
      <span>{messageSuccess}</span>
    </div>,
    { autoClose: 2000, pauseOnHover: false, closeOnClick: true }
  );
