import { apiData } from '@/services/api';

export async function createCallOperators(dataCreate) {
  try {
    const { data } = await apiData.post('dialer/createOperator', dataCreate);
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
