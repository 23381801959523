import { Tooltip, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContainerWeekDays } from './styled';

export const WeekDaysSelector = ({
  selectedDays,
  setSelectedDays,
  errorSelectedDays = false,
  hasEditorScheduler = false,
  currentDay = null,
}) => {
  const { t } = useTranslation();
  const matches = useMediaQuery('(max-width:1440px)');

  const renderTxt = (word) =>
    t(`dialControl.schedulesList.createSchedule.schedulesDetails.${word}`);

  const daysOfWeek = [
    { title: 'sunday', numberDay: 0 },
    { title: 'monday', numberDay: 1 },
    { title: 'tuesday', numberDay: 2 },
    { title: 'wednesday', numberDay: 3 },
    { title: 'thursday', numberDay: 4 },
    { title: 'friday', numberDay: 5 },
    { title: 'saturday', numberDay: 6 },
  ];

  const handleDayClick = (day) => {
    if (hasEditorScheduler) {
      return setSelectedDays([day]);
    }
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  return (
    <ContainerWeekDays
      current={hasEditorScheduler && currentDay && +currentDay + 1}
    >
      <Tooltip title={renderTxt('tool-tip')} arrow placement='top'>
        <span>{renderTxt('label-days-week')}:</span>
      </Tooltip>
      <div className='content-days'>
        {daysOfWeek.map((day, index) => (
          <label
            key={index}
            title={day.title}
            className={
              selectedDays.includes(day.numberDay)
                ? 'weekday checkedDay'
                : !errorSelectedDays
                ? 'weekday'
                : 'weekday errorDaysWeek'
            }
          >
            <input
              type='checkbox'
              value={day.numberDay}
              name={day.title}
              id={day.title}
              className=''
              checked={selectedDays.includes(day.numberDay)}
              onChange={() => handleDayClick(day.numberDay)}
            />

            {matches
              ? t(
                  `dialControl.schedulesList.listSchedules.daysOfTheWeek.${day.title}`
                ).substring(0, 1)
              : t(
                  `dialControl.schedulesList.listSchedules.daysOfTheWeek.${day.title}`
                )}
          </label>
        ))}
      </div>
    </ContainerWeekDays>
  );
};
