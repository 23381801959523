import styled from 'styled-components';

export const ContainerListCampaigns = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  overflow: auto;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  user-select: none;
  padding: 3rem 2rem;
  @media (max-height: 450px) {
    height: 80%;
  }
`;
