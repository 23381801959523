import RoutesComponent from './routes';
import { Provider as ProviderJotai } from 'jotai';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import GlobalStyle from './styles/GlobalStyled';

import { BrowserRouter } from 'react-router-dom';
import { ThemeStyled } from './providers/ThemeStyled';

const App = () => {
  return (
    <ProviderJotai>
      <BrowserRouter>
        <ThemeStyled>
          <GlobalStyle />
          <RoutesComponent />
          <ToastContainer position='top-right' draggable />
        </ThemeStyled>
      </BrowserRouter>
    </ProviderJotai>
  );
};

export default App;
