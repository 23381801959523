import { apiData } from '../api';

export async function logOutSystem(dataStateCall) {
  const { extension } = dataStateCall;
  try {
    const { data } = await apiData.post('/agent/logOutSystem', {
      extension,
    });
    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
