import { TablePagination } from '@/components/Tables/TablePagination';
import { ContainerTableCallingEfforts } from './styled';
import useWindowDimensions from '@/hooks/useWidth';
import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const TableCallingEfforts = ({ dataTable }) => {
  const { t } = useTranslation();

  const renderText = (spell) => {
    return t(`reports.callingEfforts.listCallingEfforts.table.${spell}`);
  };

  const { height, width } = useWindowDimensions();
  const tableActiveRecordings = useRef(null);

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableActiveRecordings) {
      const tableMaxWidth = tableActiveRecordings.current.offsetHeight;
      const cellMinWidth = width < 500 ? 200 : 54;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, width]);

  const columnsCallingEfforts = [
    {
      Header: renderText('th-name'),
      accessor: 'name',
    },
    {
      Header: renderText('th-document'),
      accessor: 'cpf',
    },
    {
      Header: renderText('th-list'),
      accessor: 'list',
    },
    {
      Header: renderText('th-phone'),
      accessor: 'phone',
    },
    {
      Header: renderText('th-disposition'),
      accessor: 'disposition',
    },
    {
      Header: renderText('th-description'),
      accessor: 'description',
    },
    {
      Header: renderText('th-duration'),
      accessor: 'duration',
    },
    {
      Header: renderText('th-date'),
      accessor: 'dateCall',
    },
  ];

  return (
    <ContainerTableCallingEfforts
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      ref={tableActiveRecordings}
    >
      <TablePagination
        dataColumns={columnsCallingEfforts}
        dataTable={dataTable}
        textNoData={renderText('no-data')}
        rowsPerPageDisplay={heightTable}
      />
    </ContainerTableCallingEfforts>
  );
};
