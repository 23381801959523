import { apiData } from '../api';

export async function setControlQueue(dataQueue) {
  try {
    const { fila, status } = dataQueue.dataStatus;
    const { data } = await apiData.put(`dialer/updateQueueStatus/${fila}`, {
      state: status,
    });
    return data;
  } catch (err) {
    throw new Error(err);
  }
}
