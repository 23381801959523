import { apiData } from '../api';

export async function authSession(email, password) {
  try {
    const { data } = await apiData.post('/sessions', {
      email,
      password,
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
}
