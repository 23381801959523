import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const ContainerWavesurfer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    all: unset;

    svg {
      font-size: 2.4rem;
    }
  }
`;

export const PlayerTimes = styled(motion.div)`
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;

  button {
    all: unset;

    svg {
      font-size: 2.4rem;
    }
  }

  span {
    font-size: 0.7rem;
    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;

export const ButtonAudio = styled(motion.button)`
  ${(props) =>
    props.styledType === 'play' &&
    css`
      svg {
        color: ${(props) => props.theme.colors.primary} !important;
        font-size: 2rem !important;
      }
    `}
  color: ${(props) => props.theme.colors.typography.baseText}AA !important;
  svg {
    color: ${(props) => props.theme.colors.primary} !important;
    font-size: 2rem !important;
  }

  &:disabled {
    opacity: 0.3;
  }
`;
