import styled from 'styled-components';

export const ContainerConfig = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.4rem;
`;

export const HeaderConfig = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.65rem;

  button {
    background-color: gray !important;
  }

  .activeStage {
    background-color: ${(props) =>
      props.theme.colors.containers.primaryToDark} !important;
  }
`;

export const MainConfig = styled.main`
  width: 100%;
  height: 480px;
`;

export const ContentConfig = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.4rem;

  .titleConfig {
    h4 {
      font-size: 0.85rem;
      text-transform: uppercase;
      font-weight: 400;
      padding-top: 3px;
      color: ${(props) => props.theme.colors.typography.baseText}AA;
    }

    svg {
      font-size: 0.85rem;
      color: ${(props) => props.theme.colors.typography.baseTitle};
    }
  }
`;

export const FooterConfig = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;
