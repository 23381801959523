import * as yup from 'yup';
import useFormControl from '@/hooks/useFormControl';
import { motion } from 'framer-motion';

import { ContainerSearchCounts, FormSearchCounts } from './styled';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { ControlDataPicker } from '@/components/FormComponents/DataPickerComponent';
import { Stack } from '@mui/system';
import { SwitchComponent } from '@/components/FormComponents/SwitchComponent';
import { useEffect, useRef, useState } from 'react';
import { useCountInList } from '@/hooks/reports/mailingPenetration/useCountInList';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useAtom } from 'jotai';
import {
  dataCampaignsReports,
  dataConfigSelected,
  dataListsReports,
} from '@/store/Reports/countInListStore';
import { useTranslation } from 'react-i18next';
import { ControlAutoComplete } from '@/components/FormComponents/AutoCompleteSelect';
import { timeFormatToRequest } from '@/utils/formatsDateAndTime';
import { Alert } from '@mui/material';

export const SearchCounts = ({ changeFlow }) => {
  const { t } = useTranslation();
  const [, setConfigReportsCounts] = useAtom(dataConfigSelected);
  const divRef = useRef(null);
  const renderText = (spell) => t(`reports.countInList.${spell}`);

  const {
    loadingCampaignsReports,
    loadingListsReports,
    mutateListDataCountList,
    loadingListDataCountList,
    mutateCampaignDataCountList,
    loadingCampaignDataCountList,
  } = useCountInList();

  const [typeSearch, setTypeSearch] = useState(true);
  const [listReports] = useAtom(dataListsReports);
  const [campaignsReports] = useAtom(dataCampaignsReports);

  const [hasErrorFieldDate, setHasErrorFieldDate] = useState(false);

  const [filterDateFields, setFilterDateFields] = useState(false);

  const searchCountsInListSchema = yup.object().shape({
    initDate: yup
      .string()
      .test(
        'initDate-before-endDate',
        'Initial date must be before or equal to end date',
        function (value) {
          const { endDate } = this.parent;
          if (!value || !endDate) return true;
          return new Date(value) <= new Date(endDate);
        }
      ),
    endDate: yup
      .string()
      .test(
        'endDate-after-initDate',
        'End date must be after or equal to initial date',
        function (value) {
          const { initDate } = this.parent;
          if (!value || !initDate) return true;
          return new Date(value) >= new Date(initDate);
        }
      ),
    listSearch: yup.object(),
    campaignSearch: yup.object(),
  });

  const { handleSubmit, control, errors, reset, watch, resetField } =
    useFormControl(searchCountsInListSchema);

  const watchedFields = watch();

  const isEmptyCampaigns = watch('campaignSearch');
  const isEmptyList = watch('listSearch');
  const isEmptyDateInit = watch('initDate');
  const isEmptyDateEnd = watch('endDate');

  const checkDatesToSearch =
    isEmptyDateInit?.toString() === 'Invalid Date' ||
    isEmptyDateEnd?.toString() === 'Invalid Date';

  useEffect(() => {
    setHasErrorFieldDate(
      Boolean(errors.initDate) ||
        Boolean(errors.endDate) ||
        watchedFields.initDate > watchedFields.endDate ||
        checkDatesToSearch
    );
  }, [errors, watchedFields]);

  useEffect(() => {
    if (!hasErrorFieldDate && divRef.current) {
      divRef.current.style.display = 'none';
    } else if (hasErrorFieldDate && divRef.current) {
      divRef.current.style.display = 'block';
    }
  }, [hasErrorFieldDate]);

  const renderCampaignList = (idList) => {
    const filterSelectedCampaign = listReports.filter(
      (list) => list.lista === idList
    );

    if (filterSelectedCampaign.length > 0) {
      return filterSelectedCampaign[0].campanha;
    }
    return null;
  };

  const handleSearchCounts = (data) => {
    if (typeSearch) {
      const dataSearchAll = {
        listId: data.listSearch.value,
      };
      const dataSearchWithDate = {
        listId: data.listSearch.value,
        dtInicial: timeFormatToRequest(data.initDate),
        dtFinal: timeFormatToRequest(data.endDate),
      };

      const renderTypeSearch = filterDateFields
        ? dataSearchWithDate
        : dataSearchAll;

      return mutateListDataCountList(renderTypeSearch, {
        onSuccess: () => {
          setConfigReportsCounts({
            label: data.listSearch.label,
            dateInit: filterDateFields
              ? data.initDate
              : new Date().toISOString(),
            dateEnd: filterDateFields ? data.endDate : new Date().toISOString(),
            campaign: renderCampaignList(data.listSearch.value),
            list: data.listSearch.value,
            hasFilterByDate: filterDateFields,
          });
          changeFlow(1);
          reset();
        },
      });
    }

    const dataSearchAll = {
      campaign: data.campaignSearch.value,
    };

    const dataSearchWithDate = {
      campaign: data.campaignSearch.value,
      dtInicial: timeFormatToRequest(data.initDate),
      dtFinal: timeFormatToRequest(data.endDate),
    };
    const renderTypeSearch = filterDateFields
      ? dataSearchWithDate
      : dataSearchAll;

    return mutateCampaignDataCountList(renderTypeSearch, {
      onSuccess: () => {
        setConfigReportsCounts({
          label: data.campaignSearch.label,
          dateInit: filterDateFields ? data.initDate : new Date().toISOString(),
          dateEnd: filterDateFields ? data.endDate : new Date().toISOString(),
          hasFilterByDate: filterDateFields,
        });
        changeFlow(1);
        reset();
      },
    });
  };

  const handleChangeType = (value) => {
    if (value) {
      resetField('campaignSearch');
      return setTypeSearch(true);
    }
    resetField('listSearch');
    return setTypeSearch(false);
  };

  const orderListDec = listReports?.sort((a, b) => b.lista - a.lista);

  const renderOptionsLists = orderListDec?.map((item) => {
    return {
      label: `${item.lista} - ${item.descricao}`,
      value: item.lista,
    };
  });

  const renderOptionsCampaigns = campaignsReports?.map((item) => {
    return {
      label: item.campanha,
      value: item.campanha,
    };
  });

  const checkFieldToSearch = typeSearch
    ? isEmptyList
      ? true
      : false
    : isEmptyCampaigns
    ? true
    : false;

  const isLoading =
    loadingCampaignsReports ||
    loadingListsReports ||
    loadingListDataCountList ||
    loadingCampaignDataCountList;

  return (
    <ContainerSearchCounts>
      {isLoading && <BackDropCircularLoading />}
      <TitleComponent textTitle={renderText('title')} iconTitle='search' />

      <DescriptionInformation textDescription={renderText('description')} />
      <motion.div
        ref={divRef}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: hasErrorFieldDate ? 1 : 0, y: 0 }}
        onAnimationComplete={() => {
          if (!hasErrorFieldDate && divRef.current) {
            divRef.current.style.display = 'none';
          }
        }}
      >
        <Alert width={'100%'} severity='error'>
          Selecione as datas corretamente!
        </Alert>
      </motion.div>
      <FormSearchCounts onSubmit={handleSubmit(handleSearchCounts)}>
        <Stack
          direction={'column'}
          justifyContent={'flex-start'}
          spacing={4}
          height={'100%'}
        >
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            sx={{ paddingLeft: '4px' }}
          >
            <SwitchComponent
              value={typeSearch}
              onChange={handleChangeType}
              label={
                typeSearch
                  ? renderText('label-byList')
                  : renderText('label-byCampaign')
              }
            />

            <SwitchComponent
              value={filterDateFields}
              onChange={setFilterDateFields}
              label={renderText('label-byDate')}
            />
          </Stack>

          {typeSearch
            ? renderOptionsLists && (
                <ControlAutoComplete
                  control={control}
                  nameControl={'listSearch'}
                  label={renderText('label-lists')}
                  optionsList={renderOptionsLists}
                  error={Boolean(errors.listSearch)}
                />
              )
            : renderOptionsCampaigns && (
                <ControlAutoComplete
                  control={control}
                  nameControl={'campaignSearch'}
                  label={renderText('label-campaigns')}
                  optionsList={renderOptionsCampaigns}
                  error={Boolean(errors.campaignSearch)}
                />
              )}

          {filterDateFields && (
            <Stack direction={'row'} spacing={2}>
              <ControlDataPicker
                label={renderText('label-initDate')}
                control={control}
                nameControl={'initDate'}
                defaultValue={new Date()}
                error={hasErrorFieldDate}
                disableOpenPicker
                disabled={!filterDateFields}
              />

              <ControlDataPicker
                label={renderText('label-endDate')}
                control={control}
                nameControl={'endDate'}
                defaultValue={new Date()}
                error={hasErrorFieldDate}
                disableOpenPicker
                disabled={!filterDateFields}
              />
            </Stack>
          )}
        </Stack>

        <Stack direction={'row'} justifyContent={'flex-end'}>
          <Button
            typeText
            type='submit'
            disabled={
              !checkFieldToSearch || hasErrorFieldDate || checkDatesToSearch
            }
          >
            {renderText('btn-searchLins')}
          </Button>
        </Stack>
      </FormSearchCounts>
    </ContainerSearchCounts>
  );
};
