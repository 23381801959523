import { useId, useState } from 'react';
import { ContainerLineControl, MainLineControl } from './styled';
import { PanelSelectedLine } from './PanelSelectedLine';
import { CurrentLines } from './CurrentLines';
import { ReleaseLines } from './ReleaseLines';

export const SelectedLine = ({ controlStage }) => {
  const [stageUpdateLines, setStageUpdateLines] = useState(0);

  const controlStageSelectedLine = {
    currentStage: stageUpdateLines,
    previousStage: () =>
      setStageUpdateLines((stageUpdateLines) => stageUpdateLines - 1),
    nextStage: () =>
      setStageUpdateLines((stageUpdateLines) => stageUpdateLines + 1),
    toStage: (stage) => setStageUpdateLines(stage),
  };

  const flowControlLines = [
    <CurrentLines
      key={useId()}
      controlStage={controlStageSelectedLine}
      controlMainStage={controlStage}
    />,
    <ReleaseLines key={useId()} controlStage={controlStageSelectedLine} />,
  ];

  return (
    <ContainerLineControl>
      <PanelSelectedLine
        controlStageSelectedLine={controlStageSelectedLine}
        controlMainStage={controlStage}
      />
      <MainLineControl>{flowControlLines[stageUpdateLines]}</MainLineControl>
    </ContainerLineControl>
  );
};
