import styled from 'styled-components';
import { ContainerNodeWrapper, ContentNodeWrapper } from '../styled';

export const NodeConditionContainer = styled(ContainerNodeWrapper)`
  background: ${(props) => props.theme.colors.containers.colorSecondNode};
  min-height: 190px;
  position: relative;
`;

export const NodeContent = styled(ContentNodeWrapper)`
  min-width: 220px;

  .header {
    background: ${(props) => props.theme.colors.primary};
  }
`;
