import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerEditUserAgent = styled(motion.div)`
  width: 100%;
  height: auto;
  overflow-y: auto;
  padding: 2rem;

  @media (max-width: 1490px) {
    padding: 1rem 2rem;
  }
`;

export const FormEditUserAgent = styled(motion.form)`
  width: 100%;
  height: calc(100vh - 120px);
  display: grid;
  grid-template-rows: 30px 1fr 30px;

  .mainFormUsers {
    padding: 1rem 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.2rem;
  }

  .user_account__details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
  }

  .user_account__config {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
  }

  .container_radios__config {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
    min-height: 60px;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  .footerEditUser {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @media (max-height: 768px) {
      padding-bottom: 10px;
    }
  }
`;
