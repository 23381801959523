import { useEffect, useState } from 'react';
import { ContentNode, NodeFromCsvContainer } from './styled';
import { Handle, Position, useStore } from 'reactflow';

import { IoCheckmarkCircle, IoCloseCircle, IoWarning } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { Stack, Tooltip } from '@mui/material';
import { useAtom } from 'jotai';
import { dataTemplateMailing } from '@/store/LoadList';
import { FaCircle } from 'react-icons/fa6';

export const NodeFromCsv = ({ id, data, selected }) => {
  const { t } = useTranslation();
  const [isConnected, setIsConnected] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredContent, setIsHoveredContent] = useState(false);
  const [dataTemplates] = useAtom(dataTemplateMailing);

  const handleOpen = () => {
    setIsHovered(true);
  };

  const handleClose = () => {
    setIsHovered(false);
  };

  const handleContentOpen = () => {
    setIsHoveredContent(true);
  };

  const handleContentClose = () => {
    setIsHoveredContent(false);
  };

  const {
    totalEmptyValues,
    hasColumnInCsv,
    label,
    column,
    hasColumnFromSave,
    columnName,
  } = data;

  const edges = useStore((store) => store.edges);

  useEffect(() => {
    const isHandleConnected = edges.some((edge) => edge.source === id);
    setIsConnected(isHandleConnected);
  }, [edges, id]);

  const renderFlagColumn = () => {
    if (!label) {
      return <IoCloseCircle color='red' />;
    }

    if (hasColumnInCsv && totalEmptyValues === 0) {
      return <IoCheckmarkCircle color='green' />;
    }
    if (label !== 'column_NULL' && totalEmptyValues > 0) {
      return <IoWarning color='orange' />;
    }

    return <IoCloseCircle color='red' />;
  };

  const renderToolTipDetail = (totalErrors, label) => {
    if (label === 'column_NULL') {
      return t('listLoader.importMailing.flow.msg-tooltip-all');
    }

    if (!hasColumnInCsv) {
      return t('listLoader.importMailing.flow.msg-tooltip-not-found');
    }

    if (hasColumnInCsv && totalErrors === 0) {
      return;
    }

    return t('listLoader.importMailing.flow.msg-tooltip-node', {
      totalEmpty: totalErrors,
    });
  };

  const checkColumnNode = hasColumnFromSave ? 1 : 0;
  const renderStatusNode = !dataTemplates.metadata.hasImported
    ? 1
    : checkColumnNode;

  const renderDataContent = (preview = '', columnName = '', column = '') => {
    const renderTextPreview = (
      <span>
        {t('listLoader.importMailing.flow.label-preview')}:{' '}
        <strong>{preview}</strong>
      </span>
    );

    const renderTextColumnName =
      columnName !== '' ? (
        <span>
          {t('listLoader.importMailing.flow.label-nameColumn')}:{' '}
          <strong>{columnName}</strong>
        </span>
      ) : (
        t('listLoader.importMailing.flow.label-column-from-csv', {
          numberColumn: column,
        })
      );

    return (
      <Stack spacing={'0.5px'} padding={'1px 2px'}>
        {renderTextPreview}
        {renderTextColumnName}
      </Stack>
    );
  };

  return (
    <NodeFromCsvContainer selected={selected} connected={isConnected}>
      <ContentNode imported={renderStatusNode}>
        <Tooltip
          open={isHoveredContent}
          onClose={handleContentClose}
          onOpen={handleContentOpen}
          arrow
          placement='left'
          title={renderDataContent(label, columnName, column)}
        >
          <div
            className='mainContent'
            onMouseEnter={handleContentOpen}
            onMouseLeave={handleContentClose}
          >
            <h4>
              {label === 'column_NULL'
                ? t('listLoader.importMailing.flow.label-column-not-found')
                : label}
            </h4>
            {label !== 'column_NULL' ? (
              <div>
                <FaCircle />
                <span>
                  {columnName
                    ? columnName
                    : t('listLoader.importMailing.flow.label-column-from-csv', {
                        numberColumn: column,
                      })}
                </span>
              </div>
            ) : (
              <div>
                <FaCircle />
                <span className='not-found-column'>
                  {t('listLoader.importMailing.flow.label-column-from-csv', {
                    numberColumn: column,
                  })}
                </span>
              </div>
            )}
          </div>
        </Tooltip>
        <Tooltip
          open={isHovered}
          onClose={handleClose}
          onOpen={handleOpen}
          arrow
          placement='right'
          title={renderToolTipDetail(totalEmptyValues, label)}
        >
          <div
            className='indicatorConnect'
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
          >
            {renderFlagColumn()}
          </div>
        </Tooltip>
      </ContentNode>
      <Handle type='source' position={Position.Right} />
    </NodeFromCsvContainer>
  );
};
