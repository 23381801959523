import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { ContainerLegend, ContentLegend } from './styled';
import { CgArrowLongRightC } from 'react-icons/cg';
import { FiHelpCircle } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MdSubtitles, MdSubtitlesOff } from 'react-icons/md';
import { FaCircle, FaGear } from 'react-icons/fa6';
import { PiInfoFill } from 'react-icons/pi';
import {
  IoCheckmarkCircle,
  IoCloseCircle,
  IoSaveSharp,
  IoWarning,
} from 'react-icons/io5';
import { RiSideBarFill } from 'react-icons/ri';
import { AiOutlineFunction } from 'react-icons/ai';

export default function LegendListsPopover() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'legendListsPopover' : undefined;

  const dataRender = [
    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-usedHeader'
      ),
      icon: <MdSubtitles className='colorSvgLegend' />,
    },
    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-unusedHeader'
      ),
      icon: <MdSubtitlesOff className='colorSvgLegend' />,
    },
    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-config'
      ),
      icon: <FaGear className='colorSvgLegend' />,
    },
    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-detail'
      ),
      icon: <PiInfoFill className='colorSvgLegend' />,
    },
    {
      description: t('listLoader.importMailing.flow.legendFlowList.label-save'),
      icon: <IoSaveSharp className='colorSvgLegend' />,
    },
    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-filterColumn'
      ),
      icon: <AiOutlineFunction className='colorSvgLegend' />,
    },
    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-hasNodeInCsv'
      ),
      icon: <RiSideBarFill color='#000000' />,
    },
    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-doesNotExist'
      ),
      icon: <RiSideBarFill color='gray' />,
    },

    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-columnName'
      ),
      icon: (
        <FaCircle
          color='gray'
          size={'10px'}
          style={{ padding: '4px 0px 0px 3px', marginRight: '4px' }}
        />
      ),
    },

    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-errorNode'
      ),
      icon: <IoCloseCircle color='red' />,
    },
    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-noErrorsNode'
      ),
      icon: <IoCheckmarkCircle color='green' />,
    },
    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-warningNode'
      ),
      icon: <IoWarning color='orange' />,
    },

    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-edgeUser'
      ),
      icon: <CgArrowLongRightC color='rgb(34 37 42)' />,
    },
    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-edgeSystem'
      ),
      icon: <CgArrowLongRightC color='#5037e9' />,
    },
    {
      description: t(
        'listLoader.importMailing.flow.legendFlowList.label-edgeError'
      ),
      icon: <CgArrowLongRightC color='#FF4C43' />,
    },
  ];

  return (
    <ContainerLegend>
      <button
        aria-describedby={id}
        variant='contained'
        onClick={handleClick}
        title={t('listLoader.importMailing.flow.legendFlowList.title-legend')}
      >
        <FiHelpCircle />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: theme.colors.containers.colorCardSecondary,
          },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ContentLegend>
          <h2>
            {t('listLoader.importMailing.flow.legendFlowList.title-legend')}
          </h2>
          {dataRender &&
            dataRender.map((item, i) => (
              <div className={`item`} key={i}>
                {item.icon}
                <span>{item.description}</span>
              </div>
            ))}
        </ContentLegend>
      </Popover>
    </ContainerLegend>
  );
}
