import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { errorToast } from '@/components/FormComponents/Toast';
import { getDispositionsDialing } from '@/services/dialControl/dispositionsDialing/getDispositionsDialing';
import { getUraDispositionsDialing } from '@/services/dialControl/dispositionsDialing/getUraDispositionsDialing';
import {
  dataDispositions,
  stageDispositionsCreateFlow,
} from '@/store/FlowBuilder';

export const useDispositions = () => {
  const { t } = useTranslation();
  const [, setDispositions] = useAtom(dataDispositions);
  const [stageConfigDispositions] = useAtom(stageDispositionsCreateFlow);

  const {
    isLoading: loadingDispositions,
    refetch: refetchListDispositions,
    isFetching: isFetchingDispositions,
  } = useQuery(
    'dispositionsFlow',
    async () => {
      const [response1, response2] = await Promise.all([
        getDispositionsDialing(),
        getUraDispositionsDialing(),
      ]);
      const combined = [...response1, ...response2];
      const uniqueItems = combined.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.cod_lig === value.cod_lig)
      );
      return uniqueItems;
    },
    {
      enabled: stageConfigDispositions === 0,
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (data) => {
        const updatedDataRender = data.map((disposition) => ({
          ...disposition,
          name: disposition.cod_lig,
          value: disposition.cod_lig,
        }));

        setDispositions(updatedDataRender);
      },
      onError: () =>
        errorToast(
          t('flowBuilder.configs.dispositions.msg-error-getDispositions')
        ),
    }
  );

  const {
    mutate: mutateCreateDisposition,
    isLoading: loadingCreateDisposition,
  } = useMutation({
    mutationFn: async (dataCreateDispositions) => {
      return await console.log(dataCreateDispositions);
    },

    onSuccess: () => {
      refetchListDispositions();
    },
    onError: () => {
      errorToast(
        t('flowBuilder.configs.dispositions.msg-error-addedDispositions')
      );
    },
  });

  const {
    mutate: mutateUpdateDisposition,
    isLoading: loadingUpdateDisposition,
  } = useMutation({
    mutationFn: async (dataUpdateDispositions) => {
      return await console.log(dataUpdateDispositions);
    },

    onSuccess: () => {
      refetchListDispositions();
    },
    onError: () => {
      errorToast(
        t('flowBuilder.configs.dispositions.msg-error-addedDispositions')
      );
    },
  });

  const isLoadingManagement =
    loadingCreateDisposition ||
    loadingUpdateDisposition ||
    isFetchingDispositions;

  return {
    loadingDispositions,
    mutateCreateDisposition,
    loadingCreateDisposition,
    isFetchingDispositions,
    mutateUpdateDisposition,
    loadingUpdateDisposition,
    isLoadingManagement,
  };
};
