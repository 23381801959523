export async function getTrunks() {
  try {
    const data = [
      {
        id: 'fb1c0248-95fd-465e-b4ae-1620f91a1792',
        name: 'Toledo',
        value: 'toledo',
      },
      {
        id: 'eada2e0e-0f31-44fc-93e2-0648a683eecb',
        name: 'Flamboyant',
        value: 'flamboyant',
      },
      {
        id: 'f2a5795a-8e10-4be6-89a7-8e433af32583',
        name: 'PGMais',
        value: 'pgmais',
      },
      {
        id: '1cd4687b-ed86-4157-97a6-20401de109a0',
        name: 'JA Rezende ',
        value: 'ja rezende ',
      },
      {
        id: '5874ece3-b88e-4a1c-be9d-07b14862a51f',
        name: 'Younique',
        value: 'younique',
      },
      {
        id: '74132c23-3b63-49b3-aa2c-1b5fe9da3fb6',
        name: 'CDL',
        value: 'cdl',
      },
      {
        id: '57bae773-192d-430f-bb6b-443a2c298d8b',
        name: 'NegocieAgora',
        value: 'NegocieAgora',
      },
      {
        id: '86200e6d-9e06-426e-b6e3-e926aa17fabf',
        name: 'Servipa',
        value: 'servipa',
      },
    ];

    return new Promise((resolve) => {
      setTimeout(() => resolve(data), 500);
    });
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
