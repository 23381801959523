import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerDashboardPage = styled(motion.div)`
  width: 100%;
  height: 100%;
  max-height: 92%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.95rem;
  padding: 1.4rem 2rem;
  /* overflow: hidden; */
  overflow: auto;

  @media (max-width: 1550px) {
    padding: 1.4rem 2rem;
    gap: 1rem;
  }
`;

export const LoadScreen = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #36363633;
  cursor: wait;
  z-index: 100;
`;
