import { motion } from 'framer-motion';
import { FaArrowLeft } from 'react-icons/fa';
import { InputAdornment, Stack } from '@mui/material';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { BiSearch } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { ContentPanelAggregate } from './styled';
import { useAtom } from 'jotai';
import { dataSelectedTenants } from '@/store/TenantsStore';

export const PanelMatchTenants = ({ changeStage, dataFilter, isNewRow }) => {
  const { t } = useTranslation();
  const [tenantSelected, setTenantSelected] = useAtom(dataSelectedTenants);

  const { filterMatch, setFilterMatch } = dataFilter;

  const handleBackMainStage = () => {
    setTenantSelected(null);
    changeStage(0);
  };

  return (
    <ContentPanelAggregate>
      <Stack direction='column' spacing={3}>
        <Stack direction='row' spacing={2}>
          <motion.div
            whileTap={{ scale: 0.8 }}
            className='icon_handle_back'
            onClick={handleBackMainStage}
          >
            <FaArrowLeft size='14' />
          </motion.div>
          <div className='itemPanel'>
            <span>
              <strong>{tenantSelected?.name ?? '-'}</strong>
            </span>
          </div>
        </Stack>
        <InputComponent
          placeholder={t('tenants.addMatchTenant.placeHolder-filterMatch')}
          value={filterMatch}
          onChange={(e) => setFilterMatch(e.target.value)}
          disabled={isNewRow}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <BiSearch />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </ContentPanelAggregate>
  );
};
