import { useAtom } from 'jotai';
import { useEffect, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogComponent } from '@/components/DialogComponent';
import { Button } from '@/components/FormComponents/ButtonComponent';
import { ContainerConfig, HeaderConfig, MainConfig } from './styled';
import { templateSelected } from '@/store/FlowBuilder';
import { ConfigGlobalVars } from './stages/ConfigGlobalVars';
import { ConfigDispositions } from './stages/ConfigDispositions';
import { ConfigNlus } from './stages/ConfigNlus';
import { ConfigTrunks } from './stages/ConfigTrunks';

export const ConfigCurrentFlow = ({ open, close }) => {
  const { t } = useTranslation();
  const [template] = useAtom(templateSelected);
  const [stagesConfig, setStagesConfig] = useState(0);

  const { codeLigs, variablesGlobal, nlus, trunks } = template;

  const dataListNavigation = [
    {
      stage: 0,
      name: t('flowBuilder.configs.btn-globalVars'),
      isActive: stagesConfig === 0,
    },
    {
      stage: 1,
      name: t('flowBuilder.configs.btn-dispositions'),
      isActive: stagesConfig === 1,
    },
    {
      stage: 2,
      name: t('flowBuilder.configs.btn-endPoint'),
      isActive: stagesConfig === 2,
    },
    {
      stage: 3,
      name: t('flowBuilder.configs.btn-trunks'),
      isActive: stagesConfig === 3,
    },
  ];

  useEffect(() => {
    return () => {
      setStagesConfig(0);
    };
  }, []);

  const controlStage = {
    resetStage: () => setStagesConfig(0),
    toStage: (stage) => setStagesConfig(stage),
    closeConfig: () => {
      close(false);
      controlStage.resetStage();
    },
  };

  const flowConfig = [
    <ConfigGlobalVars
      key={useId()}
      dataGlobals={variablesGlobal ?? []}
      handleClose={controlStage.closeConfig}
    />,
    <ConfigDispositions
      key={useId()}
      dataCodeLigs={codeLigs ?? []}
      handleClose={controlStage.closeConfig}
    />,
    <ConfigNlus
      key={useId()}
      dataNlus={nlus ?? []}
      controlStage={controlStage}
    />,
    <ConfigTrunks
      key={useId()}
      dataTrunks={trunks ?? []}
      controlStage={controlStage}
    />,
  ];

  return (
    <DialogComponent
      open={open}
      onClose={() => close(false)}
      fullWidth={true}
      maxWidth={'lg'}
      variant='secondary'
    >
      <ContainerConfig>
        <HeaderConfig>
          {dataListNavigation.map((item) => {
            return (
              <Button
                key={item.stage}
                typeText
                className={item.isActive ? 'activeStage' : ''}
                onClick={() => controlStage.toStage(item.stage)}
              >
                {item.name}
              </Button>
            );
          })}
        </HeaderConfig>
        <MainConfig>{flowConfig[stagesConfig]}</MainConfig>
      </ContainerConfig>
    </DialogComponent>
  );
};
