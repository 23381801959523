import { selectedQueueMonitor } from '@/store/QueueMonitor';
import { renderDate } from '@/utils/formatsDateAndTime';
import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { HeaderCardDetail } from './styled';
import { IoChevronBack } from 'react-icons/io5';

export const CardHeaderQueues = () => {
  const { t } = useTranslation();
  const renderTime = renderDate(new Date());
  const [selectedQueue, setSelectedQueue] = useAtom(selectedQueueMonitor);

  const handleAllQueues = () => {
    setSelectedQueue(null);
  };

  return (
    <HeaderCardDetail>
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
        {selectedQueue ? (
          <Stack direction={'row'} alignItems={'center'} spacing={'3px'}>
            <button onClick={handleAllQueues}>
              <IoChevronBack />
            </button>
            <strong>{`${t('queueMonitor.table.th-queue')} ${
              selectedQueue.fila
            }`}</strong>
          </Stack>
        ) : (
          <strong>{t('queueMonitor.table.th-allQueue')}</strong>
        )}
        <span>{renderTime}</span>
      </Stack>
    </HeaderCardDetail>
  );
};
