import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StyledDataPicker } from './styled';
import { ptBR, enUS } from 'date-fns/locale';
import { Controller } from 'react-hook-form';

export const DataPickerComponent = ({ ...rest }) => {
  const getLang = window.localStorage.getItem('lang');

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={getLang === 'en' ? enUS : ptBR}
    >
      <StyledDataPicker {...rest} />
    </LocalizationProvider>
  );
};

export const ControlDataPicker = ({
  control,
  nameControl,
  defaultValue = null,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={nameControl}
      render={({ field: { onChange, value } }) => (
        <DataPickerComponent
          label={rest.label}
          value={value}
          onChange={onChange}
          {...rest}
        />
      )}
    />
  );
};
