import { Controller } from 'react-hook-form';
import { useId } from 'react';
import { FormControl, FormGroup, FormLabel } from '@mui/material';
import { CustomSwitch, StyledFormControl, StyledSwitch } from './styled';

export const SwitchComponent = ({
  value,
  onChange,
  label = '',
  disabled = false,
}) => {
  const handleChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <StyledFormControl
      ischecked={value ? 1 : 0}
      control={
        <StyledSwitch
          checked={value}
          onChange={handleChange}
          name={useId()}
          size='small'
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

export const SwitchControlled = ({
  control,
  nameControl,
  defaultValue = false,
  activeLabel = 'True',
  descriptionRadio = '',
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={nameControl}
      render={({ field: { onChange, value } }) => (
        <FormControl component='fieldset' variant='standard'>
          <FormLabel component='legend'>{descriptionRadio}</FormLabel>
          <FormGroup>
            <StyledFormControl
              ischecked={value ? 1 : 0}
              label={activeLabel}
              control={
                <CustomSwitch
                  checked={value}
                  onChange={onChange}
                  name={nameControl}
                  inputProps={{ 'aria-label': nameControl }}
                  size='small'
                />
              }
            />
          </FormGroup>
        </FormControl>
      )}
    />
  );
};
