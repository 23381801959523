import styled from 'styled-components';

export const ContainerChartPerHour = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  background: ${({ theme }) => theme.colors.containers.colorCard};
  padding: 20px;
  border-radius: 6px;
  box-shadow: ${(props) => props.theme.colors.containers.baseShadows};
  grid-column: 1 / 2;

  @media (max-width: 1550px) {
    height: 96%;
  }

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

export const HeaderChartServicePerHour = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.88rem;
    line-height: 120.69%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  span {
    font-style: normal;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 132.19%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;

export const ContentChartServicePerHour = styled.div`
  height: 90%;
  width: 100%;
  display: grid;
  justify-self: center;
  align-items: center;

  .apexcharts-legend-text {
    padding-top: 3px;
  }
`;
