import { InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContainerPanelActivityLog } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import smLogoVoice from '@/assets/imgs/icon-sm-voicce.svg';

import { AdvancedSearchLog } from './AdvancedSearchLog';

export const PanelActivityLog = ({
  dataSearch,
  mutateAdvancedSearch,
  refetchActivityLog,
  isLoading,
}) => {
  const { t } = useTranslation();

  const { fieldSearchLogs, setFieldSearchLogs } = dataSearch;

  return (
    <ContainerPanelActivityLog>
      <div className='fieldTypeSearch'>
        <InputComponent
          value={fieldSearchLogs}
          onChange={(e) => setFieldSearchLogs(e.target.value)}
          placeholder={t('activityLog.panel.placeholder-filter')}
          disabled={isLoading}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <img
                  src={smLogoVoice}
                  alt='voicce'
                  width={'20px'}
                  height={'20px'}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div className='fieldTypeDate'>
        <AdvancedSearchLog
          mutateAdvancedSearch={mutateAdvancedSearch}
          refetchActivityLog={refetchActivityLog}
        />
      </div>
    </ContainerPanelActivityLog>
  );
};
