import { useId } from 'react';
import { HomeListDispositionsConfig } from './HomeListDispositionsConfig';
import { ManageDispositions } from '@/components/pages/FlowBuilder/stages/Configs/Dispositions/ManageDispositions';
import { useAtom } from 'jotai';
import { stageDispositionsCreateFlow } from '@/store/FlowBuilder';

export const ConfigDispositions = ({ changeStateFlow }) => {
  const [stageConfigDispositions, setStageConfigDispositions] = useAtom(
    stageDispositionsCreateFlow
  );

  const controlStageNewFlow = {
    handleBackStage: () => changeStateFlow(1),
    handleNextStage: () => changeStateFlow(3),
  };

  const controlStageConfig = {
    toHomeDispositions: () => setStageConfigDispositions(0),
    toManagementDispositions: () => setStageConfigDispositions(1),
    currentState: stageConfigDispositions,
  };

  const flowConfigDispositions = [
    <HomeListDispositionsConfig
      key={useId()}
      controlStage={controlStageNewFlow}
      controlStageConfig={controlStageConfig}
    />,

    <ManageDispositions
      key={useId()}
      controlStage={controlStageConfig.toHomeDispositions}
    />,
  ];

  return flowConfigDispositions[stageConfigDispositions];
};
