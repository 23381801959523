import { useLayoutEffect, useRef, useState } from 'react';
import useWindowDimensions from '@/hooks/useWidth';
import { motion } from 'framer-motion';
import { TablePagination } from '@/components/Tables/TablePagination';
import { ContainerTableDDDs } from './styled';
import { IoCloseOutline } from 'react-icons/io5';
import { MdEdit } from 'react-icons/md';
import { ConfirmationComponent } from '@/components/layout/ConfirmationComponent';
import { dataSelectedDDDManager } from '@/store/DialingStore';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMappingRows } from '@/components/pages/dialControl/DDDManager/hooks/useMappingRows';
import { successToast } from '@/components/FormComponents/Toast';
import { dataListClients } from '@/store/Clients';
import { usePermissionsDDDManager } from '@/components/pages/dialControl/DDDManager/hooks/usePermissionsDDDManager';

export const TableListDDDs = ({
  dataTable,
  controlStage,
  mutateGetDataDDD,
  loadingDeleteGroupDDD,
  mutateDeleteGroupDDD,
}) => {
  const { t } = useTranslation();
  const { permissionsEdit, permissionsDelete } = usePermissionsDDDManager();

  const { height } = useWindowDimensions();
  const { mappingRows } = useMappingRows();
  const [listClients] = useAtom(dataListClients);

  const renderText = (spell) => {
    return t(`dialControl.dddManager.list.table.${spell}`);
  };

  const [selectedDDDGroup, setSelectedDDDManager] = useAtom(
    dataSelectedDDDManager
  );

  const [openConfirmationBox, setOpemConfirmationBox] = useState(false);

  const handleOpemConfirmationRemove = (rowCampaign) => {
    setSelectedDDDManager(rowCampaign);
    setOpemConfirmationBox(true);
  };

  const handleCloseConfirmationRemove = () => {
    setSelectedDDDManager(null);
    setOpemConfirmationBox(false);
  };

  const handleEditGroup = (dataRow) => {
    mutateGetDataDDD(dataRow.cod_ddd_bloq, {
      onSuccess: (data) => {
        const dataSelected = {
          name: dataRow.cod_ddd_bloq,
          description: dataRow.descricao,
          schedules: mappingRows(data),
        };
        setSelectedDDDManager(dataSelected);
        controlStage.toStage(1);
      },
    });
  };

  const handleDeleteGroup = () => {
    mutateDeleteGroupDDD(selectedDDDGroup.cod_ddd_bloq, {
      onSuccess: () => {
        successToast(t(`dialControl.dddManager.create.msg-success-delete`));
        handleCloseConfirmationRemove();
      },
    });
  };

  const searchClientGroup = (idCLient) => {
    if (idCLient) {
      const filterClient = listClients.filter((item) => item.id === idCLient);
      if (filterClient.length > 0) {
        return filterClient[0].commercial_name;
      }
      return idCLient;
    }
    return '-';
  };

  const tableDdd = useRef(null);

  const ColumnsGroups = [
    {
      Header: renderText('th-name'),
      accessor: 'cod_ddd_bloq',
    },
    {
      Header: renderText('th-description'),
      accessor: 'descricao',
    },
    {
      Header: renderText('th-createdAt'),
      accessor: 'id_cliente',
      Cell: ({ row }) => searchClientGroup(row.original.id_cliente),
    },
    {
      Header: renderText('th-action'),
      Cell: ({ row }) => (
        <div className='editRow'>
          <motion.button
            title={renderText('btn-action-remove')}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleOpemConfirmationRemove(row.original)}
            disabled={!permissionsDelete}
          >
            <IoCloseOutline />
          </motion.button>
          <motion.button
            title={renderText('btn-action-edit')}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleEditGroup(row.original)}
            disabled={!permissionsEdit}
          >
            <MdEdit />
          </motion.button>
        </div>
      ),
    },
  ];

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableDdd) {
      const tableMaxWidth = tableDdd.current.offsetHeight;
      const cellMinWidth = 55;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, tableDdd]);

  return (
    <ContainerTableDDDs ref={tableDdd}>
      <TablePagination
        dataColumns={ColumnsGroups}
        dataTable={dataTable}
        textNoData={renderText('noData')}
        rowsPerPageDisplay={heightTable}
      />

      <ConfirmationComponent
        open={openConfirmationBox}
        close={handleCloseConfirmationRemove}
        messageConfirm={renderText('msg-remove')}
        actionConfirm={handleDeleteGroup}
        isLoading={loadingDeleteGroupDDD}
      />
    </ContainerTableDDDs>
  );
};
